import { Box, capitalize, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { Messages } from '../localization/Messages';

type StripeCardPaymentProps = {
  planDetails?: SubscriptionPlanResponseType | null;
};
export function StripeCardPayment({ planDetails }: StripeCardPaymentProps) {
  const intl = useIntl();

  const defaultPayment = planDetails?.subscription?.default_payment_method;

  const {
    pm_type,
    card,
    us_bank_account,
    billing_details: { name },
  } = defaultPayment || InitializedPaymentMethod;

  const {
    brand = '',
    last4 = '',
    exp_year = 0,
    exp_month = 0,
  } = card || InitializedPaymentMethod.card;

  const {
    bank_name,
    last4: uslast4,
    account_type,
  } = us_bank_account || InitializedPaymentMethod.us_bank_account;

  const daysDue = planDetails?.subscription?.days_until_due;
  const daysDueSeconds =
    typeof daysDue === 'number'
      ? Math.floor(new Date().valueOf() / 1000) + daysDue * 24 * 60 * 60
      : null;
  const nextBillDueDate =
    (daysDueSeconds || planDetails?.subscription?.current_period_end || 0) *
    1000;

  return (
    <>
      {(card || us_bank_account) && (
        <CardPayment
          brand={brand || bank_name}
          last4={last4 || uslast4}
          exp_month={exp_month}
          exp_year={exp_year}
          account_type={account_type}
        />
      )}
      {pm_type === 'plaid' && (
        <PlaidPayment
          name={name || ''}
          brand={brand}
          expiredDate={intl.formatDate(nextBillDueDate)}
        />
      )}
    </>
  );
}

type PlaidPaymentProps = {
  brand: string;
  name: string;
  expiredDate: string;
  isDefault?: boolean;
};
export function PlaidPayment({
  brand,
  name,
  expiredDate,
  isDefault,
}: PlaidPaymentProps) {
  const intl = useIntl();
  return (
    <Box>
      <Typography
        sx={{
          mr: 1,
          fontSize: '0.875rem',
        }}
      >
        {intl.formatMessage(Messages.xPaymentAccount, {
          name: capitalize(brand),
        })}
      </Typography>
      <Typography sx={{ mx: 1, lineHeight: 1.5 }}>-</Typography>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '0.875rem',
        }}
      >
        {name || ''}
      </Typography>
      <PrimaryTag isPrimary={isDefault} />
    </Box>
  );
}

type CardPaymentProps = {
  brand: string;
  last4: string;
  exp_month?: number;
  exp_year?: number;
  isDefault?: boolean;
  account_type?: string;
};
export function CardPayment({
  brand,
  last4,
  exp_month,
  exp_year,
  isDefault,
  account_type,
}: CardPaymentProps) {
  const intl = useIntl();
  return (
    <Box className="boxlefthcenterv">
      {/* icon */}
      <Typography
        sx={{
          fontSize: '0.875rem',
          mr: 1,
        }}
      >
        {capitalize(brand)}
        {'   '}***{last4}
      </Typography>
      {!!exp_month && !!exp_year && (
        <>
          <Typography
            sx={{
              mr: 1,
              fontSize: '0.875rem',
            }}
          >
            {intl.formatMessage(Messages.endingIn)}
          </Typography>
          <Typography
            sx={{
              mr: 1,
              fontWeight: 600,
              fontSize: '0.75rem',
            }}
          >
            {exp_month}/{exp_year}
          </Typography>
        </>
      )}
      {account_type && (
        <>
          <Typography
            sx={{
              mr: 1,
              fontSize: '0.875rem',
            }}
          >
            {intl.formatMessage(Messages.accountType)}
          </Typography>
          <Typography
            sx={{
              mr: 1,
              fontWeight: 600,
              fontSize: '0.75rem',
            }}
          >
            {account_type}
          </Typography>
        </>
      )}
      <PrimaryTag isPrimary={isDefault} />
    </Box>
  );
}

type PrimaryTagProps = {
  isPrimary?: boolean;
};
export function PrimaryTag({ isPrimary }: PrimaryTagProps) {
  const theme = useTheme();
  const intl = useIntl();
  return isPrimary ? (
    <>
      <Box
        sx={{
          width: '0.125rem',
          height: '1.3125rem',
          bgcolor: theme.custom.primary4,
          borderRadius: '0.1875rem',
          mx: '1rem',
        }}
      ></Box>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '0.75rem',
          color: theme.custom.primary2,
        }}
      >
        {intl.formatMessage(Messages.primary)}
      </Typography>
    </>
  ) : (
    <></>
  );
}

export const InitializedPaymentMethod = {
  pm_type: '',
  card: { brand: '', last4: '', exp_year: 0, exp_month: 0 },
  billing_details: { name: '' },
  us_bank_account: {
    bank_name: '',
    last4: '',
    account_type: '',
  },
};
