import { defineMessages } from 'react-intl';
export const JoyrideMessages = defineMessages({
  ProductTour: { id: 'ProductTour', defaultMessage: 'Product Tour:' },
  HomepageStep0: {
    id: 'HomepageStep0',
    defaultMessage: 'Launch a branded campaign to attract creators.',
  },
  HomepageStep1: { id: 'HomepageStep1', defaultMessage: 'Creator discovery' },
  HomepageStep2: {
    id: 'HomepageStep2',
    defaultMessage: "Generate personalized video scripts tailored to each creator's unique style.",
  },
  HomepageStep3: {
    id: 'HomepageStep3',
    defaultMessage: 'Select a product category for video content',
  },
  HomepageStep4: {
    id: 'HomepageStep4',
    defaultMessage: 'Select an optional content type to generate script',
  },
  HomepageStep5: {
    id: 'HomepageStep5',
    defaultMessage:
      'Type in optional content idea to combine your creativity with ours for a new story',
  },
  HomepageStep6: {
    id: 'HomepageStep6',
    defaultMessage: 'Curate a list of preferred creators.',
  },
  HomepageStep7: {
    id: 'HomepageStep7',
    defaultMessage: 'Activate an AI agent for creator outreach via email.',
  },
  SkipTour: {
    id: 'SkipTour',
    defaultMessage: 'Skip Tour',
  },
  RestartTour: {
    id: 'RestartTour',
    defaultMessage: 'Restart Tour',
  },
  NextTip: {
    id: 'NextTip',
    defaultMessage: 'Next Tip',
  },
  EndTour: {
    id: 'EndTour',
    defaultMessage: 'End Tour',
  },
});
