import { Box, Container } from '@mui/material';
import RocketChat from '../features/rocketchat/RocketChat';
import { useBreakpointXs } from '../utils/useStyleHooks';

export default function MessagePage() {
  const isMobileView = useBreakpointXs();
  const height = isMobileView ? `calc(100vh - 72px)` : 'calc(100vh - 90px)';
  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          height,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            height,
            padding: '0 !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RocketChat />
        </Container>
      </Box>
    </Box>
  );
}
