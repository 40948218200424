import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CrossIcon } from '../assets/imgs/icons';
import { useGlobalStyles } from '../assets/styles/style';
import { Messages } from '../localization/Messages';
import { NOOP } from '../redux/benaApiResultHandler';
import { hideHtmlBodyScrollbar, showHtmlBodyScrollbar } from '../utils/common';
import { useBreakpointXl, useBreakpointXs } from '../utils/useStyleHooks';

type BenaConfirmationModalProps = {
  setNullData: fnBooleanToVoid;
  showMainWindowScrollbar?: boolean;
  title: string;
  message: string;
  onConfirm?: fnVoidToVoid;
  cancelButtonText?: string;
  confirmButtonText?: string;
  showDeletionStyle?: boolean;
};

export function BenaConfirmationModal({
  setNullData,
  showMainWindowScrollbar = true,
  title,
  message,
  onConfirm = NOOP,
  cancelButtonText,
  confirmButtonText,
}: BenaConfirmationModalProps) {
  const theme = useTheme();
  const intl = useIntl();
  const gc = useGlobalStyles();
  useEffect(() => {
    if (title) hideHtmlBodyScrollbar();
  });

  const handleClose = (refresh = false) => {
    setNullData(refresh);
    showMainWindowScrollbar && showHtmlBodyScrollbar();
  };

  return (
    <Box
      sx={{ zIndex: theme.zIndex.modal }}
      className={`boxcenterhv ${gc.screenCover}`}
    >
      <Box
        sx={{
          width: '37.5rem',
          bgcolor: theme.palette.base.white,
          maxHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
        className="border-radius-8px"
      >
        <Box
          sx={{
            width: '100%',
            my: '1.25rem',
            pb: '0.5rem',
            px: '1.625rem',
            borderBottom: `0.0625rem solid ${theme.custom.primary5}`,
          }}
          className="boxcenterhv"
        >
          <Typography fontSize="18px" fontWeight={'600'} color="base.black">
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          <IconButton onClick={() => handleClose()}>
            <CrossIcon
              stroke={theme.custom.black}
              sx={{
                width: '1rem',
                height: '1rem',
                mr: 1,
                mt: '0.125rem',
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            px: '1.625rem',
            minHeight: '6.25rem',
          }}
        >
          {message}
        </Box>
        <Box
          sx={{
            px: '1.625rem',
            width: '100%',
            mb: '1.5rem',
            borderTop: `0.0625rem solid ${theme.custom.primary5}`,
            pt: '1rem',
          }}
          className="boxcenterhv"
        >
          <Button
            sx={{ width: '11.625rem', height: '2.5rem' }}
            className={`border-radius-8px ${gc.buttonCancellation}`}
            onClick={async () => {
              await onConfirm();
              handleClose(true);
            }}
          >
            {cancelButtonText || intl.formatMessage(Messages.confirmLeave)}
          </Button>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Button
            sx={{
              width: '11.625rem',
              height: '2.5rem',
            }}
            className={`border-radius-8px ${gc.buttonDeletion}`}
            onClick={() => handleClose(false)}
          >
            {confirmButtonText || intl.formatMessage(Messages.stayOnThisPage)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export function BenaConfirmationOKCancelModal({
  setNullData,
  showMainWindowScrollbar = true,
  title,
  message,
  onConfirm = NOOP,
  cancelButtonText,
  confirmButtonText,
  showDeletionStyle,
}: BenaConfirmationModalProps) {
  const theme = useTheme();
  const intl = useIntl();
  const gc = useGlobalStyles();
  useEffect(() => {
    if (title) hideHtmlBodyScrollbar();
  });

  const handleClose = (refresh = false) => {
    setNullData(refresh);
    showMainWindowScrollbar && showHtmlBodyScrollbar();
  };

  const isMobileView = useBreakpointXs();

  return (
    <Box
      sx={{ zIndex: theme.zIndex.modal }}
      className={`boxcenterhv ${gc.screenCover}`}
    >
      <Box
        sx={{
          width: isMobileView ? 'calc(100vw - 32px)' : '37.5rem',
          bgcolor: theme.palette.base.white,
          maxHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
        className="border-radius-8px"
      >
        <Box
          sx={{
            width: '100%',
            my: '1.25rem',
            pb: '0.5rem',
            px: '1.625rem',
            borderBottom: `0.0625rem solid ${theme.custom.primary5}`,
          }}
          className="boxcenterhv"
        >
          <Typography fontSize="18px" fontWeight={'600'} color="base.black">
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          <IconButton onClick={() => handleClose()}>
            <CrossIcon
              stroke={theme.custom.black}
              sx={{
                width: '1rem',
                height: '1rem',
                mr: 1,
                mt: '0.125rem',
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            px: '1.625rem',
            minHeight: '6.25rem',
          }}
        >
          {message}
        </Box>
        <Box
          sx={{
            px: '1.625rem',
            width: '100%',
            mb: '1.5rem',
            borderTop: `0.0625rem solid ${theme.custom.primary5}`,
            pt: '1rem',
          }}
          className="boxcenterhv"
        >
          <Button
            sx={{ width: '11.625rem', height: '2.5rem' }}
            className={`border-radius-8px ${gc.buttonCancellation}`}
            onClick={() => handleClose(false)}
          >
            {cancelButtonText || intl.formatMessage(Messages.cancel)}
          </Button>
          <Box sx={{ flexGrow: 1, minWidth: '16px' }}></Box>
          <Button
            sx={{
              width: '11.625rem',
              height: '2.5rem',
            }}
            className={`border-radius-8px ${
              showDeletionStyle ? gc.buttonDeletion : gc.buttonPrimary
            }`}
            onClick={async () => {
              await onConfirm();
              handleClose(true);
            }}
          >
            {confirmButtonText || intl.formatMessage(Messages.Confirm)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
