import { Box, Link } from '@mui/material';
import { useThemeMode } from '../../hooks/theme';
import { staticURLBena2 } from '../../route/urls';
import LanguageSwitch from '../language/LanguageSwitch';

export default function LogoAndLanguage() {
  const themeMode = useThemeMode();
  return (
    <Box className={`boxcenterhv`} sx={{ width: '100%' }}>
      <Link href={staticURLBena2}>
        <img
          src={themeMode === 'light' ? '/logo.svg' : '/logo-white.svg'}
          alt="logo"
          tabIndex={0}
          style={{ height: '2rem', width: 'auto' }}
        />
      </Link>
      <Box sx={{ flexGrow: 1 }}></Box>
      <LanguageSwitch />
    </Box>
  );
}
