import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  Stack,
  Theme,
  Link,
  useTheme,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useMenus } from './menus';
import Button from '@mui/material/Button/Button';
import { capitalize } from 'lodash';
import { Messages } from '../../localization/Messages';
import { useIntl } from 'react-intl';
import {
  useLazyGetEmailUrlQuery
} from "../../redux/benaApi"
import {
  staticURLLogin,
  staticURLMyProfile,
  staticURLAccount,
  staticURLBena2,
  staticURLMessaging,
  staticURLNewCampaign
} from '../../route/urls';
import {
  useAuthUserAvatar,
  useAuthUserName,
  useIsRoleBrand,
  useIsRoleCreator,
  useBillingType,
  useLogout,
} from '../../hooks/account';
import { useEffect, useState, useMemo } from 'react';
import { UserAccountMessages } from '../account/Messages';
import { useNavigate } from 'react-router-dom';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import ThemeModeMenuItem from './ThemeModeMenuItem';
import { useThemeMode } from '../../hooks/theme';
import {
  ArrowDownThinIcon,
  LogoutIcon,
  User2Icon
} from '../../assets/imgs/icons';
import LanguageMenuItem from './LanguageMenuItem';
import { useGlobalStyles } from '../../assets/styles/style';
import RCUnreadMessagesCount, {
  useListenTIMMessageCountNotification
} from '../rocketchat/RCUnreadMesssagesCount';
import EmailUnseenCountIcon from '../webmail/emailUnseenCount';
import { UploadCreator } from "../../components/myCreators/uploadCreator";
import { CreatorMessages } from '../../localization/CreatorMessages';

import { SideMenu } from '../mobileNavbar/navbar';
import UserBlueIcon from '../../assets/imgs/userBlue.svg';
import LightTooltip from '../../components/LightTooltip';

const underLineWidth = '50%';

type HeaderProps = {
  lightLogo?: boolean;
  type?: 'creators' | 'brands';
};
const Header = ({ lightLogo, type }: HeaderProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const styles = useStyles();
  const themeMode = useThemeMode();
  const menus = useMenus();
  const UserName = useAuthUserName();
  const isPlanBilling = useBillingType();
  const UserAvatar = useAuthUserAvatar();
  const isBrand = useIsRoleBrand();
  const isCreator = useIsRoleCreator();
  const navigate = useNavigate();
  const [query, refetch] = useLazyGetEmailUrlQuery();
  const size = { width: '1.25rem', height: '1.25rem' };

  const menuItemMt = menus.find((menu) => menu.route === '') ? 2.25 : 0;
  const highlight = (route: string) =>
    window.location.pathname.startsWith(route || ' ')
      ? theme.palette.primary.main
      : theme.palette.grey[700];

  const hoverSx = useMemo(
    () => ({
      position: 'relative',
      ':hover': {
        cursor: 'pointer',
        color: theme.palette.primary[600],
      },
      ':hover:before': {
        width: underLineWidth,
        transition: 'all 0.1s ease-in-out',
      },
      ':hover:after': {
        width: underLineWidth,
        transition: 'all 0.1s ease-in-out',
      },
      ':before': {
        left: '50%',
        content: `''`,
        position: 'absolute',
        width: '0',
        bottom: '-0.8125rem',
        height: '0.125rem',
        bgcolor: theme.palette.primary[600],
        transition: 'all 0.2s ease-in-out',
      },
      ':after': {
        right: '50%',
        content: `''`,
        position: 'absolute',
        width: '0',
        bottom: '-0.8125rem',
        height: '0.125rem',
        bgcolor: theme.palette.primary[600],
        transition: 'all 0.2s ease-in-out',
      },
    }),
    [theme]
  );

  const LoginButtons = !UserName && (
    <>
      <Link href={staticURLAccount} underline="none">
        <Button
          variant="outlined"
          aria-label="sign up"
          className={`${gs.buttonNoOutlineWhite} border-radius-8px`}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {capitalize(intl.formatMessage(Messages.singUp))}
        </Button>
      </Link>
      <Link href={staticURLLogin}>
        <Button
          color="primary"
          variant="contained"
          aria-label="log in"
          className="border-radius-8px"
          sx={{ marginLeft: '1.5rem', whiteSpace: 'nowrap' }}
        >
          {capitalize(intl.formatMessage(Messages.logIn))}
        </Button>
      </Link>
    </>
  );

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [logoutHandler] = useLogout();
  const userState = (
    <>
      {UserName && (
        <Box sx={{ flexGrow: 0 }} className="boxcenterhv">
          {
            isBrand && (
              <>
                <LightTooltip title={
                  intl.formatMessage(Messages[ isPlanBilling !== "free" ? "viewEmail" : "upgradeSubscription" ])
                }
                  placement="bottom">
                  <Link href="#" className="step_email_script_check"
                    onClick={(e)=>{
                      if( isPlanBilling === "free" ){ return; }
                      e.preventDefault();
                      query({}).then((res)=>{
                        if(!res.data) return;
                        document.cookie = "webmail="+res.data?.data.cookie.webmail+"; path=/";
                        setTimeout(()=>{ window.location.href = res.data?.data.redirect as string; }, 200);
                      });
                    }}
                    sx={ isPlanBilling === "free" ? { opacity:"0.5", cursor:"not-allowed" } : {}}
                    >
                    <EmailUnseenCountIcon disabled={ isPlanBilling==="free" } />
                  </Link>
                </LightTooltip>
              </>
            )
          }
          <LightTooltip title={ intl.formatMessage(Messages.viewMessage) }
            placement="bottom">
            <Link href={staticURLMessaging} sx={{ margin: '0 16px 0 10px' }}>
              <RCUnreadMessagesCount />
            </Link>
          </LightTooltip>
          {isBrand && (
            <Link
              href={staticURLNewCampaign}
              sx={{ marginRight: '16px' }}
              className="step_create_new_campaign"
            >
              <Button
                variant="outlined"
                sx={{
                  minWidth: '145px', height: '40px', borderRadius: '8px !important', backgroundColor: 'transparent !important',
                  color: `${theme.palette.primary.main_white} !important`,
                  borderColor: `${theme.palette.primary["main50"]} !important`,
                  '&:hover': {
                    backgroundColor: `transparent !important`, color: `${theme.palette.primary.main_white} !important`,
                    borderColor: `${theme.palette.primary.main_white} !important`,
                  },
                  '&:disabled': {
                    backgroundColor: `transparent !important`, color: `${theme.palette.grey.disabled_text} !important`,
                    cursor: 'not-allowed',
                    '&>*': {
                      color: `${theme.palette.grey.disabled_text} !important`,
                    },
                  }
                }}
              >
                {intl.formatMessage(CreatorMessages.CreateCampaign)}
              </Button>
            </Link>
          )}
          <IconButton
            onClick={(e) => handleOpenUserMenu(e)}
            sx={{ p: 1 }}
            disableRipple
          >
            {!UserAvatar ? (
              <User2Icon stroke={theme.palette.grey.black_white} />
            ) : (
              <Avatar
                alt={UserName || UserName.slice(0, 1)}
                src={UserAvatar || '/imgs/user.svg'}
                variant="circular"
                sx={{ width: '1.5rem', height: '1.5rem' }}
              />
            )}
            <Stack direction="row" paddingTop="0.125rem" alignItems="center">
              <Typography sx={{ ml: 1, mr: 2 }} textTransform="uppercase">
                {UserName}
              </Typography>
              <ArrowDownThinIcon
                stroke={theme.palette.grey.black_white}
                sx={{ width: '1rem', height: '1rem' }}
                className={anchorElUser ? 'rotate180' : ''}
              />
            </Stack>
          </IconButton>
          <Menu
            sx={{
              mt: 5.625,
            }}
            MenuListProps={{
              sx: {
                width: '180px',
              },
            }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={() => {
                navigate(staticURLMyProfile);
                handleCloseUserMenu();
              }}
            >
              <ListItemIcon>
                <User2Icon stroke={theme.palette.grey.black_white} sx={size} />
              </ListItemIcon>
              <ListItemText>
                {capitalize(intl.formatMessage(UserAccountMessages.myProfile))}
              </ListItemText>
            </MenuItem>
            <LanguageMenuItem />
            <ThemeModeMenuItem />
            <MenuItem
              onClick={() => {
                logoutHandler();
                handleCloseUserMenu();
              }}
            >
              <ListItemIcon>
                <LogoutIcon stroke={theme.palette.grey.black_white} sx={size} />
              </ListItemIcon>
              <ListItemText>
                {capitalize(intl.formatMessage(Messages.logout))}
              </ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      )}
    </>
  );

  const isMobile = useBreakpointXs();

  useListenTIMMessageCountNotification(() => {
    return;
  });

  const [toggleMenu, setToggleMenu] = useState(false);

  const appBar = (
    <AppBar position={'static'} color="transparent" elevation={0}>
      <Toolbar
        disableGutters
        className={styles.toolbar}
        sx={{ margin: isMobile ? '0rem 2rem' : '0rem', position: 'relative'}}
      >
        <IconButton disableRipple>
          <Link href={staticURLBena2} underline="none">
            <img
              src={
                lightLogo
                  ? '/logo-white.svg'
                  : themeMode === 'light'
                  ? '/logo.svg'
                  : '/logo-white.svg'
              }
              alt="bena"
              className={styles.logo}
            ></img>
          </Link>
        </IconButton>
        <Box sx={{ width: '64px' }}></Box>
        <Stack direction={'row'} spacing="2rem" justifyContent={'center'}>
          {menus.map((menu) => (
            <Box key={menu.name} className={styles.menuWrapper}>
              <Link
                style={{
                  textDecoration: 'none',
                }}
                href={menu.route ? menu.route : '#'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  menu.route && navigate(menu.route);
                }}
              >
                <Box className="boxcenterhv">
                  <Box
                    sx={{
                      mt: menuItemMt,
                      color: highlight(menu.route),
                      ...hoverSx,
                    }}
                  >
                    <Typography className={styles.title}>
                      {menu.name}
                    </Typography>
                    <div className={`${styles.underline} boxcenterhv`}></div>
                  </Box>
                </Box>
              </Link>
            </Box>
          ))}
        </Stack>
        <Box sx={{ flexGrow: 1 }}></Box>
        {!UserName && <Button
          variant="outlined"
          className={`${gs.buttonNoOutlineWhite}`}
          sx={{
            whiteSpace: 'nowrap',fontSize: "15px", fontWeight: "600", position: isMobile ? 'absolute' : '',
            right: isMobile ? '-1rem' : '', top: isMobile ? '4.5rem' : '',
          }}
          onClick={() => {
            navigate( type === "brands" ? "/" : "/brands", {replace: true} )
          }}
        >
          {type === "brands" ? "For Creators" : "For Brands"}
        </Button>}
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ width: '16px' }}></Box>
        {LoginButtons}
        {userState}
      </Toolbar>
    </AppBar>
  );

  const mobileAppBar = (
    <AppBar position={'static'} color="transparent" elevation={0}>
      <Toolbar
        disableGutters
        className={styles.toolbar}
        sx={{ margin: isMobile ? '0rem 1rem' : '0rem' }}
      >
        {toggleMenu && (
          <SideMenu
            hide={() => {
              setToggleMenu(false);
            }}
          />
        )}
        <IconButton onClick={() => setToggleMenu(!toggleMenu)}>
          <img src={UserBlueIcon} alt="icon" />
        </IconButton>
        <Box sx={{ flex: 1 }}></Box>
        <IconButton disableRipple>
          <Link href={staticURLBena2} underline="none">
            <img
              src={themeMode === 'light' ? '/logo.svg' : '/logo-white.svg'}
              alt="bena"
              className={styles.logo}
            ></img>
          </Link>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
  return isMobile && isCreator ? mobileAppBar : appBar;
};
export default Header;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: { height: '1.375rem', width: 'auto' },
    toolbar: { flexGrow: 1, height: '5.5rem' },
    menuWrapper: {
      display: 'block',
      textAlign: 'center',
      alignSelf: 'stretch',
      ':hover > .tabcontent': {
        visibility: 'visible',
        position: 'absolute',
        opacity: '1',
        transition: 'all 0.3s ease-out',
      },
      fontWeight: 'bold',
    },
    title: {
      fontSize: '0.9375rem !important',
      fontWeight: `600 !important`,
      color: theme.palette.grey[700],
      '& :hover': {
        color: theme.palette.primary.main,
      },
    },
    underline: {
      position: 'absolute',
      width: '100%',
      bottom: '-0.4375rem',
    },
  })
);
