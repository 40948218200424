import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Link,
  Select,
  Stack,
  Typography,
  useTheme,
  capitalize,
} from '@mui/material';
import {
  SelectArrowDownGreyIcon,
  SelectArrowDownWhiteIcon,
  SelectArrowDownBlackIcon,
  CopyIcon,
  PenIcon,
  TrashThinIcon,
  CheckIcon,
  ArrowRightIcon,
} from '../../assets/imgs/icons';
import { useThemeMode } from '../../hooks/theme';
import { useState, useEffect, useMemo } from 'react';
import BrandCampaignDetails from './BrandCampaignDetails';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { useGlobalStyles } from '../../assets/styles/style';
import { BrandContext } from '../context/context';
import {
  useDeleteCampaignMutation,
  useGetCampaignsQuery,
  useCompleteCampaignMutation,
} from '../../redux/benaApi';
import {
  staticURLCampaignPublicUrl,
  staticURLCampaignsPage,
  staticURLNewCampaign,
} from '../../route/urls';
import { CampaignMessages } from '../../localization/CampaignMessages';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import Confirm from '../../components/Confirm';
import { Messages } from '../../localization/Messages';
import { useClipboard } from '../../hooks/clipboard';
import { showMessage } from '../../components/ShowMessage';
import LightTooltip from '../../components/LightTooltip';
import CampaignSOP from '../../features/sop/CampaignSOP';
import { ArrowBackRounded } from '@mui/icons-material';
import { replaceURLSearchParam } from '../../utils/common';

export default function BrandCampaignsPage() {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data: campaigns, refetch } = useGetCampaignsQuery({
    page: 1,
    size: 100,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const campaignOptions = campaigns?.data.items
    ? campaigns?.data.items.map((x) => ({
        name: x.title,
        value: x.title,
        status: x.status,
        id: x.id,
      }))
    : [];

  useEffect(() => {
    setSelectedCampaignId(
      searchParams.get('campaign_id') || campaignOptions[0]?.id || ''
    );
  }, [campaigns, searchParams]);

  const [selectedCampaignId, _setSelectedCampaignId] = useState(
    campaignOptions[0]?.id || ''
  );

  const setSelectedCampaignId = (id: string) => {
    navigate(
      `${staticURLCampaignsPage}${replaceURLSearchParam(
        window.location.search,
        'campaign_id',
        id
      )}`
    );
    _setSelectedCampaignId(id);
  };

  const [currentDisplayedCreatorId, setCurrentDisplayedCreatorId] =
    useState('');

  const handlePrevNextCampaign = (prev: boolean) => {
    const index = campaignOptions.findIndex((w) => w.id === selectedCampaignId);
    if (index < 0) return;
    const next = index + (prev ? -1 : 1);
    setSelectedCampaignId(
      campaignOptions[next]?.id || campaignOptions[index]?.id
    );
  };

  const editCampaign = () => {
    navigate(`${staticURLNewCampaign}?campaign_id=${selectedCampaignId}`);
  };

  const nextButtonDisabled = () => {
    const index = campaignOptions.findIndex((w) => w.id === selectedCampaignId);
    return index >= campaignOptions.length - 1;
  };
  const prevButtonDisabled = () => {
    const index = campaignOptions.findIndex((w) => w.id === selectedCampaignId);
    return index <= 0;
  };

  const [deleteCampaign] = useDeleteCampaignMutation();
  const handleDeleteCampaign = () => {
    MutationHandler({
      intl,
      action: async () => {
        return await deleteCampaign({ id: selectedCampaignId }).unwrap();
      },
      onSuccess: () => {
        const index = campaignOptions.findIndex((w) => w.id === selectedCampaignId);
        setSelectedCampaignId(campaignOptions[index+1]?.id || campaignOptions[index-1]?.id);
        refetch();
      },
    });
  };

  return (
    <>
      <BrandContext.Provider
        value={{
          setCurrentDisplayedCreatorId,
          currentDisplayedCreatorId,
          handlePrevNextCampaign,
        }}
      >
        {campaignOptions.length > 0 && (
          <Stack marginTop="54px">
            <CampaignsSelect
              selectedCampaignId={selectedCampaignId}
              setSelectedCampaignId={setSelectedCampaignId}
              options={campaignOptions}
              elId={'campaigns-list'}
              editCampaign={editCampaign}
              deleteCampaign={handleDeleteCampaign}
            />
          </Stack>
        )}
        {selectedCampaignId && (
          <>
            <Stack
              marginTop={'32px'}
              border={`1px solid ${theme.palette.neutral[600]}`}
              borderRadius={'8px 8px 0px 0px'}
              padding={'12px 20px'}
            >
              <Stack direction="row" alignItems={'center'}>
                <Button
                  sx={{
                    height: '32px',
                    fontWeight: '600',
                    fontSize: '14px',
                    padding: '8px 8px 8px 0px',
                  }}
                  variant="text"
                  className={gs.buttonOutlined}
                  onClick={() => handlePrevNextCampaign(true)}
                  disabled={prevButtonDisabled()}
                >
                  <ArrowBackRounded
                    htmlColor={theme.palette.base.black}
                    sx={{ width: '22px', height: '22px' }}
                  />
                  {intl.formatMessage(CreatorMessages.PrevCampaign)}
                </Button>
                <Box flexGrow={1}></Box>
                <Button
                  sx={{
                    height: '32px',
                    fontWeight: '600',
                    fontSize: '14px',
                    padding: '8px 0px 8px 8px',
                  }}
                  variant="text"
                  className={gs.buttonOutlined}
                  onClick={() => handlePrevNextCampaign(false)}
                  disabled={nextButtonDisabled()}
                >
                  {intl.formatMessage(CreatorMessages.NextCampaign)}
                  <ArrowBackRounded
                    htmlColor={theme.palette.base.black}
                    sx={{ width: '22px', height: '22px' }}
                    className="rotate180"
                  />
                </Button>
              </Stack>
            </Stack>
            <Stack
              border={`1px solid ${theme.palette.neutral[600]}`}
              borderTop="0px"
              borderRadius={'0px 0px 8px 8px'}
              padding={'12px 20px'}
            >
              <CampaignSOP
                campaignId={selectedCampaignId}
                campaignDetails={
                  <BrandCampaignDetails
                    campaignId={selectedCampaignId}
                    embedMode={true}
                  />
                }
              />
            </Stack>
          </>
        )}
        {campaignOptions.length <= 0 && (
          <Stack
            className="boxcenterhv"
            minHeight={'784px'}
            width="100%"
            border={`1px solid ${theme.palette.neutral[700]}`}
            borderRadius={'10px'}
            marginTop="54px"
            rowGap="18px"
          >
            <img
              src="/imgs/no-campaign-found.svg"
              alt="no campaign"
              width="276px"
              height="258px"
            />
            <Typography
              fontSize={'22px'}
              fontWeight="500"
              color={theme.palette.grey[700]}
            >
              {intl.formatMessage(Messages.SeemsLikeYouDont)}
            </Typography>
            <Link
              href={staticURLNewCampaign}
              sx={{ marginRight: '16px' }}
              className="step_create_new_campaign"
            >
              <Button
                className={gs.buttonPrimary}
                variant="outlined"
                sx={{ width: '187px', height: '48px' }}
              >
                {intl.formatMessage(Messages.GoAndCreateIt)}
                <ArrowRightIcon
                  sx={{ marginLeft: '8px', width: '19px', height: '19px' }}
                  stroke={theme.palette.base.white}
                />
              </Button>
            </Link>
          </Stack>
        )}
        <Box height="10rem"></Box>
      </BrandContext.Provider>
    </>
  );
}

type CampaignsSelectProp = {
  elId: string;
  selectedCampaignId: string;
  setSelectedCampaignId: fnStringToVoid;
  options: { value: string; name: string; status: string; id: string }[];
  className?: string;
  editCampaign: fnVoidToVoid;
  deleteCampaign: fnVoidToVoid;
};
export const CampaignsSelect = ({
  elId,
  selectedCampaignId,
  setSelectedCampaignId,
  options,
  className = '',
  editCampaign,
  deleteCampaign,
}: CampaignsSelectProp) => {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const { copy } = useClipboard();
  const isDarkMode = useThemeMode() === 'dark';
  const color = (status: string) => {
    switch (status) {
      case 'active':
        return theme.custom.active;
      case 'drafted':
        return theme.custom.drafted;
      case 'complete':
        return theme.custom.completed;
      default:
        return theme.palette.primary.main;
    }
  };
  const [open, setOpen] = useState(false);

  const deleteButtonShouldHide = useMemo(() => {
    const s = options.find((w) => w.id === selectedCampaignId)?.status;
    return s === undefined || !['drafted'].includes(s);
  }, [options, selectedCampaignId]);

  const showCompleteButton = useMemo(() => {
    const s = options.find((w) => w.id === selectedCampaignId)?.status;
    return ['active'].includes(s || '');
  }, [options, selectedCampaignId]);

  const showEditButton = useMemo(() => {
    const s = options.find((w) => w.id === selectedCampaignId)?.status;
    return ['active', 'drafted'].includes(s || '');
  }, [options, selectedCampaignId]);

  const [openComplete, setOpenComplete] = useState(false);
  const [patchExistingCampaign] = useCompleteCampaignMutation({});
  const completeCampaign = async () => {
    await MutationHandler({
      intl,
      action: async () => {
        return await patchExistingCampaign({
          campaign_id: selectedCampaignId,
          body: { status: 'completed' },
        }).unwrap();
      },
      onSuccess: () => {
        showMessage.success(intl.formatMessage(Messages.SuccessfullySaved));
        window.location.reload();
      },
    });
  };

  const copyUrl = useMemo(() => {
    return (
      <LightTooltip title={intl.formatMessage(Messages.CopyCampaignURL)}>
        <Link
          href={'#'}
          underline="hover"
          display={'inline-block'}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            copy(
              `${window.location.origin}${staticURLCampaignPublicUrl}?campaign_id=${selectedCampaignId}`
            );
          }}
          target="_blank"
          paddingLeft={'8px'}
        >
          <CopyIcon
            sx={{ width: '16px', height: '16px' }}
            stroke={theme.palette.grey[700]}
          />
        </Link>
      </LightTooltip>
    );
  }, [copy, intl, selectedCampaignId, theme.palette.grey]);

  return (
    <FormControl>
      <Stack
        direction="row"
        alignItems={'center'}
        position="relative"
        className={className}
        columnGap="16px"
      >
        <Select
          id={elId}
          value={selectedCampaignId}
          renderValue={(v) => {
            const { status, name } = options.find((w) => w.id === v) || {
              status: '',
              name: '',
            };
            return (
              <Typography
                fontSize={'22px'}
                fontWeight={500}
                color="grey.700"
                marginRight="16px"
                title={v}
              >
                {name.slice(0, 60)}
                {name.length > 60 && '...'}
                <Typography
                  marginLeft={'8px'}
                  fontWeight={500}
                  fontSize={'22px'}
                  color={color(status)}
                  component="span"
                >
                  {status && `(${capitalize(status)})`}
                </Typography>
              </Typography>
            );
          }}
          label=""
          onChange={(e) => {
            setSelectedCampaignId(e.target.value);
          }}
          IconComponent={
            selectedCampaignId
              ? isDarkMode
                ? SelectArrowDownWhiteIcon
                : SelectArrowDownBlackIcon
              : SelectArrowDownGreyIcon
          }
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: '0px',
            },
            '& .MuiSelect-select': {
              paddingLeft: '0px !important',
            },
            bgcolor: 'transparent',
            minWidth: '12rem',
            paddingLeft: 0,
            visibility: options.length > 0 ? 'visible' : 'hidden',
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          {options.map((v, index) => (
            <MenuItem value={v.id} key={index}>
              <Stack direction={'row'} flexWrap={'nowrap'} width="100%">
                <Typography fontSize={'14px'} fontWeight={400} color="grey.700">
                  {v.name}
                </Typography>
                <LightTooltip
                  title={intl.formatMessage(Messages.CopyCampaignURL)}
                >
                  <Link
                    href={'#'}
                    underline="hover"
                    display={'inline-block'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      copy(
                        `${window.location.origin}${staticURLCampaignPublicUrl}?campaign_id=${v.id}`
                      );
                    }}
                    target="_blank"
                    paddingLeft={'8px'}
                  >
                    <CopyIcon
                      sx={{ width: '12px', height: '12px' }}
                      stroke={theme.palette.grey[700]}
                    />
                  </Link>
                </LightTooltip>
                <Box flexGrow="1" component="span"></Box>
                <Typography
                  marginLeft={'16px'}
                  color={color(v.status)}
                  component="span"
                  minWidth={'80px'}
                  textAlign="center"
                >
                  {capitalize(v.status || '')}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
        {'  '}
        {copyUrl}
        <Box flexGrow="1"></Box>
        {showEditButton && (
          <LightTooltip
            title={intl.formatMessage(CampaignMessages.EditCampaign)}
          >
            <Button
              className={gs.buttonOutlined}
              sx={{
                visibility: options.length > 0 ? 'visible' : 'hidden',
                height: '40px',
                maxWidth: '40px',
                minWidth: '40px',
              }}
              onClick={editCampaign}
              variant="outlined"
            >
              <PenIcon
                fill={theme.palette.primary.main}
                viewBox="0 0 30 30"
                sx={{ width: 16, height: 16 }}
              />
            </Button>
          </LightTooltip>
        )}
        {!deleteButtonShouldHide && (
          <LightTooltip
            title={intl.formatMessage(CreatorMessages.DeleteCampaign)}
          >
            <Button
              className={gs.buttonDeletionOutlined}
              sx={{
                visibility: options.length > 0 ? 'visible' : 'hidden',
                height: '40px',
                maxWidth: '40px',
                minWidth: '40px',
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <TrashThinIcon sx={{ width: '20px', height: '20px' }} />
            </Button>
          </LightTooltip>
        )}
        {showCompleteButton && (
          <LightTooltip
            title={intl.formatMessage(CampaignMessages.MarkComplete)}
          >
            <Button
              className={gs.buttonOutlined}
              sx={{
                visibility: options.length > 0 ? 'visible' : 'hidden',
                height: '40px',
                maxWidth: '40px',
                minWidth: '40px',
              }}
              onClick={() => {
                setOpenComplete(true);
              }}
              variant="outlined"
            >
              <CheckIcon
                sx={{ width: '16px', height: '16px' }}
                stroke={theme.palette.primary.main}
              />
            </Button>
          </LightTooltip>
        )}
      </Stack>
      <Confirm
        text={intl.formatMessage(
          CampaignMessages.AreYouSureToDeleteTheCampaign
        )}
        handleConfirm={deleteCampaign}
        open={open}
        setOpen={setOpen}
      />
      <Confirm
        text={intl.formatMessage(
          CampaignMessages.AreYouSureToCompleteTheCampaign
        )}
        handleConfirm={completeCampaign}
        open={openComplete}
        setOpen={setOpenComplete}
      />
    </FormControl>
  );
};
