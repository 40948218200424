import React, { useEffect, useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Box, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import arrowExpand from '../../assets/imgs/downArrow.svg';
import deletePrimary3 from '../../assets/imgs/deletePrimary3.svg';
import { useGlobalStyles } from '../../assets/styles/style';
import { DropdownIcon, ItemSelectedIcon } from '../../assets/imgs/icons';

type SortbySelectProps = {
  label: string;
  data: DropdownMenuButtonProps;
  onMenuItemChange: (name: string, index: number) => void;
  selectHeight?: string;
  menuItemHeight?: string;
  deletable: boolean;
  widthRem?: number;
  nameFontStyle?: SxProps;
  // responsive width for xs
  xsWidthRem?: number;
  fullWidth?: boolean;
  resetFn?: fnVoidToVoid;
  noBorder?: boolean;
};

export default function SortbySelect({
  label,
  data,
  onMenuItemChange,
  selectHeight = '2.125rem',
  menuItemHeight = '2rem',
  deletable,
  widthRem = 15.875,
  nameFontStyle,
  xsWidthRem,
  fullWidth,
  resetFn,
  noBorder,
}: SortbySelectProps) {
  const theme = useTheme();
  const globalStyles = useGlobalStyles();
  const [open, setOpen] = useState('hidden');
  const [width, setWidth] = useState('5rem');
  // we use an additional element to measure the max width of all options
  const refBoxWidth = useRef<HTMLElement>(null);
  const dataSelected = Math.min(
    Math.max(-1, data.selected || 0),
    data.options.length - 1
  );
  const onClickMenuItem = (
    e: React.MouseEvent<HTMLElement>,
    itemName: string,
    itemIndex: number
  ) => {
    e.stopPropagation();
    setOpen('hidden');
    onMenuItemChange(itemName, itemIndex);
  };
  useEffect(() => {
    const w = refBoxWidth.current?.getBoundingClientRect().width || 80;
    const wrem = w / 16;
    setWidth(
      `${
        fullWidth
          ? '100%'
          : xsWidthRem
          ? Math.max(xsWidthRem, wrem)
          : widthRem
          ? widthRem > wrem
            ? widthRem
            : wrem
          : wrem
      }${fullWidth ? '' : 'rem'}`
    );
  }, []);
  const hideMenu = () => {
    setOpen('hidden');
  };
  useEffect(() => {
    window.addEventListener('click', hideMenu);
    return () => {
      window.removeEventListener('click', hideMenu);
    };
  }, []);

  const deletableItem = (name: string, label: string, expand: boolean) => {
    return deletable ? (
      <>
        <Box
          className="boxcenterhv"
          sx={{ justifyContent: 'flex-start', mr: 2 }}
        >
          <Typography
            sx={{ pr: 1, color: theme.custom.primary2 }}
            fontSize={'12px'}
            fontWeight="600"
          >
            {name}
          </Typography>
          <img
            src={arrowExpand}
            style={{
              width: '0.75rem',
              height: '0.75rem',
            }}
            className={`imgIcon8px ${expand ? 'rotate180' : ''}`}
            alt=""
          />
        </Box>
        <img src={deletePrimary3} className="imgIcon12px rotate90" alt="" />
      </>
    ) : (
      <>
        <Typography
          sx={{ color: theme.custom.black }}
          fontSize={'12px'}
          fontWeight="500"
        >
          {label}
        </Typography>
        <Typography
          sx={{
            px: label ? 1 : 0,
            marginRight: '0.5rem !important',
            ...(nameFontStyle || {}),
            color: theme.custom.primary2,
          }}
          fontSize={'12px'}
          fontWeight="500"
        >
          {name}
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        {resetFn && name && (
          <img
            src={deletePrimary3}
            className="imgIcon12px rotate90"
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              resetFn();
            }}
          />
        )}
        <Box sx={{ width: '0.5rem' }}></Box>
        <DropdownIcon
          sx={{ width: '0.875rem', height: '0.6125rem' }}
          className={`cursorHand ${expand ? '' : 'rotate180'}`}
        />
      </>
    );
  };
  return (
    <>
      <Box
        sx={{
          minWidth: width,
          maxWidth: width,
          position: 'relative',
          p: 0,
        }}
      >
        <MenuItem
          tabIndex={0}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: selectHeight,
            minHeight: { xs: selectHeight, md: 'auto' },
            border: noBorder
              ? '0px'
              : `0.0625rem solid ${theme.custom.primary4}`,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setOpen('visible');
          }}
          className="border-radius-8px"
        >
          {deletableItem(
            dataSelected === -1 ? '' : data.options[dataSelected].name,
            label,
            false
          )}
        </MenuItem>
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            visibility: open,
            width: width,
            zIndex: 10000,
          }}
          className="tagBoxShadowRightBottom border-radius-10px"
        >
          <MenuItem
            tabIndex={0}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              height: selectHeight,
              minHeight: { xs: selectHeight, md: 'auto' },
              border: `0.0625rem solid ${theme.custom.primary4}`,
              borderRadius:
                data.options.length <= 1
                  ? '0.5rem 0.5rem 0.5rem 0.5rem'
                  : '0.5rem 0.5rem 0 0',
            }}
            onClick={() => setOpen('hidden')}
          >
            {deletableItem(
              dataSelected === -1 ? '' : data.options[dataSelected].name,
              label,
              true
            )}
          </MenuItem>
          {data.options.map((option, index) => (
            <MenuItem
              tabIndex={0}
              key={option.name}
              selected={index === dataSelected || false}
              onClick={(e) => onClickMenuItem(e, option.name, index)}
              sx={{
                width: '100%',
                height: menuItemHeight,
                minHeight: { xs: selectHeight, md: 'auto' },
                border: `0.0625rem solid ${theme.custom.primary4}`,
                borderTop: '0',
                borderRadius:
                  data.options.length === index + 1
                    ? '0 0 0.5rem 0.5rem'
                    : '0 0 0 0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                {option.icon || <></>}
                <Typography
                  sx={{
                    color:
                      index === dataSelected
                        ? theme.custom.primary2
                        : theme.palette.base.black,
                    ml: option.icon ? 0.6 : 0,
                  }}
                  fontSize={'12px'}
                  fontWeight={500}
                >
                  {option.name}
                </Typography>
                {option.children}
                {index === dataSelected ? (
                  <>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <ItemSelectedIcon
                      sx={{
                        width: '0.75rem',
                        height: '0.75rem',
                        mt: '0.25rem',
                      }}
                      stroke={theme.palette.primary.main}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </MenuItem>
          ))}
        </Box>
      </Box>
      <Box
        ref={refBoxWidth}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          visibility: open,
        }}
      >
        <MenuItem
          tabIndex={-1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: selectHeight,
            minHeight: { xs: selectHeight, md: 'auto' },
            border: `0.0625rem solid ${theme.custom.primary4}`,
            visibility: 'hidden',
          }}
          className="border-radius-8px"
        >
          {deletableItem(
            data.options[
              data.options.reduce(
                (lastIndex, currentItem, index) =>
                  data.options[lastIndex].name.length > currentItem.name.length
                    ? lastIndex
                    : index,
                0
              )
            ].name,
            label,
            false
          )}
        </MenuItem>
      </Box>
    </>
  );
}
