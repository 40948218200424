import {
  Box,
  capitalize,
  IconButton,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ChangeEvent, Fragment, useContext, useMemo } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { CrossIcon, SendIcon } from '../../assets/imgs/icons';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoaderMessage,
} from '../../components/InfiniteScrollMessages';
import { LoadingButton } from '@mui/lab';
import { ArrowBackRounded } from '@mui/icons-material';
import { useGlobalStyles } from '../../assets/styles/style';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIsApiLoading } from '../../hooks/account';
import {
  useQueryApplicationHistoryMessages,
  useQueryCreatorAIGCScripts,
} from '../hooks/aigc';
import {
  CreatorAIScriptPanelContext,
  CreatorContext,
} from '../context/context';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { ConversationRenderer } from './utils';
import { mapActionTypeToMessageContentType } from '../robot/actions';
import { enterKeyPressed } from '../../utils/common';
import { Messages } from '../../localization/Messages';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { useAdminQueryConversationHistoryMessages } from '../../admin/hooks/scripts';
import { AdminContext } from '../../admin/context/AdminContextWrapper';

type CreatorRoleAIScriptRequestType = {
  campaignId?: string;
  scriptType?: CreatorScriptType;
  actionType?: CreatorRoleScriptActionType;
  actionData: any;
};

type CreatorAIScriptPanelProps = {
  scriptType: CreatorScriptType;
};
export default function CreatorAIScriptPanel({
  scriptType,
}: CreatorAIScriptPanelProps) {
  const adminContext = useContext(AdminContext);
  const { campaignIdForScriptTypeCampaign } = useContext(CreatorContext);
  const {
    dataArr,
    setDataArr,
    setResArr,
    queryFn,
    total,
    page,
    askChatEmail,
    askChatContent,
    askChatScript,
    askChatApplication,
    loading: answering,
    initChatBotScript,
    appendBotMsgFromUserAction,
  } = useQueryCreatorAIGCScripts();

  useEffect(() => {
    if (!adminContext.readMode) {
      initChatBotScript(scriptType);
    }
  }, []);

  const { historyMessages, originDataArr } =
    useQueryApplicationHistoryMessages(scriptType);

  useEffect(() => {
    if (historyMessages.length > 0) {
      setDataArr(historyMessages);
      setResArr(originDataArr.slice().reverse());
    }
  }, [historyMessages]);

  // admin query conversation history messages
  const {
    historyMessages: adminHistoryMessages,
    originDataArr: adminOriginDataArr,
  } = useAdminQueryConversationHistoryMessages(scriptType === 'campaign');

  useEffect(() => {
    if (adminHistoryMessages.length > 0) {
      setDataArr(adminHistoryMessages);
      setResArr(adminOriginDataArr.slice().reverse());
    }
  }, [adminHistoryMessages]);

  const handlePostGenerateScript = async (
    params: CreatorRoleAIScriptRequestType
  ) => {
    const msg_type = mapActionTypeToMessageContentType(params.actionType);
    const msg_text =
      params.actionData?.multiSelect ||
      params.actionData?.select ||
      params.actionData?.selectWithValue ||
      params.actionData?.input;
    const msg_label = params.actionData?.input;
    switch (scriptType) {
      case 'email':
        askChatEmail({ msg_type, msg_text, msg_label });
        return;
      case 'content':
        askChatContent({ msg_type, msg_text, msg_label });
        return;
      case 'script':
        askChatScript({
          campaign_id: campaignIdForScriptTypeCampaign,
          msg_type,
          msg_text,
          msg_label,
        });
        return;
      case 'campaign':
        askChatApplication({
          campaign_id: campaignIdForScriptTypeCampaign,
          msg_type,
          msg_text,
          msg_label,
        });
        return;
    }
  };

  const nextPage = () => {
    queryFn(page + 1);
  };
  const [error, setError] = useState(false);

  // handle user interaction from chat
  const { userActionData, resetUserActionData } = useContext(CreatorContext);
  useEffect(() => {
    const { data, type } = userActionData;
    (async () => {
      if (data?.input) {
        await handlePostGenerateScript({
          scriptType,
          actionType: type,
          actionData: data,
        });
        resetUserActionData();
      }
    })();
  }, [userActionData]);

  const isMobileView = useBreakpointXs();

  return (
    <CreatorAIScriptPanelContext.Provider
      value={{ appendBotMsgFromUserAction }}
    >
      <Stack marginTop={isMobileView ? '0px' : '48px'}>
        <ScriptsDialog
          stories={dataArr}
          submitHandler={handlePostGenerateScript}
          error={error}
          refresh={(refresh) => {
            refresh && queryFn();
          }}
          nextPage={nextPage}
          total={total}
          answering={answering}
        />
      </Stack>
    </CreatorAIScriptPanelContext.Provider>
  );
}

type ScriptsDialogProps = {
  stories: CreatorRoleScriptItemType[];
  submitHandler: (params: CreatorRoleAIScriptRequestType) => Promise<void>;
  error?: boolean;
  refresh: fnBooleanToVoid;
  total: number;
  nextPage: fnVoidToVoid;
  answering: boolean;
};
function ScriptsDialog({
  stories,
  submitHandler,
  error,
  refresh,
  total,
  nextPage,
  answering,
}: ScriptsDialogProps) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const apiLoading = useIsApiLoading();

  const [initState, setInitState] = useState(true);

  const [contentIdea, setContentIdea] = useState('');
  const stabsRef = useRef<HTMLDivElement>(null);

  const [submitting, setSubmitting] = useState(false);
  const isAbleToSubmit = () => {
    // return !!selectedContentCategory;
    return !!contentIdea;
  };

  const generateScriptPost = async () => {
    const input = contentIdea.trim();
    if (!input) {
      return;
    }
    setSubmitting(true);
    await submitHandler({
      actionData: { input },
    });
    setSubmitting(false);
    setContentIdea('');
  };

  useEffect(() => {
    // handle init state
    if (error === undefined) return;
    // handle if the submission succeed in the first time
    if (initState && !error) setInitState(false);
  }, [error]);

  const contentIdeaChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setContentIdea(text);
  };

  useEffect(() => {
    stabsRef.current?.scrollIntoView?.();
  }, [stories]);

  const disabled = submitting || !isAbleToSubmit();

  const context = useContext(CreatorContext);
  const scriptType = context.currentScriptType;
  const hidePanel = () => {
    context.setCurrentScriptTitle('');
  };

  const storiesPanel = useMemo(
    () => <ConversationRenderer items={stories} />,
    [stories]
  );

  const isMobileView = useBreakpointXs();

  const storiesPanelInfiniteScroll = useMemo(
    () => (
      <Box
        id="infinite-reverse-scroll-container-creator"
        sx={{
          height: `calc(100vh - ${isMobileView ? 144 : 320}px)`,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <InfiniteScroll
          dataLength={stories.length}
          next={nextPage}
          hasMore={total > stories.length}
          loader={<InfiniteScrollLoaderMessage />}
          endMessage={<InfiniteScrollEndMessage />}
          scrollableTarget="infinite-reverse-scroll-container-creator"
          inverse={true}
        >
          {storiesPanel}
          {answering && <InfiniteScrollLoaderMessage width={40} />}
          <Box ref={stabsRef}></Box>
        </InfiniteScroll>
      </Box>
    ),
    [stories, nextPage, total, storiesPanel, stabsRef, answering]
  );

  const readMode = useContext(AdminContext)?.readMode;

  return (
    <>
      <Stack
        flexGrow="1"
        height="100%"
        bgcolor={theme.palette.neutral[50]}
        borderRadius={'1rem'}
        position="relative"
        border={
          isMobileView ? '0px' : '1px solid ' + theme.palette.neutral[600]
        }
      >
        <Stack
          direction="row"
          spacing="1rem"
          bgcolor={theme.palette.base.white}
          padding="9px 24px"
          alignItems={'center'}
          borderRadius={'1rem 1rem 0rem 0rem'}
          borderBottom={
            isMobileView ? '0px' : '1px solid ' + theme.palette.neutral[600]
          }
          flexGrow={0}
        >
          <IconButton onClick={hidePanel} disableRipple sx={{ padding: 0 }}>
            <ArrowBackRounded
              htmlColor={theme.palette.base.black}
              sx={{ width: '22px', height: '22px' }}
            />
          </IconButton>
          <Typography fontSize={'1rem'}>
            {capitalize(
              scriptType === 'campaign'
                ? intl.formatMessage(CreatorMessages.CampaignApplicationHelper)
                : scriptType === 'email'
                ? intl.formatMessage(CreatorMessages.WritingEmailsHelper)
                : scriptType === 'script'
                ? intl.formatMessage(CreatorMessages.WritingScriptHelper)
                : scriptType === 'content'
                ? intl.formatMessage(CreatorMessages.WritingContentBriefHelper)
                : intl.formatMessage(CreatorMessages.WritingScriptHelper)
            )}
          </Typography>
        </Stack>
        <Box
          flexGrow={0}
          sx={{
            overflowY: 'auto',
            height: `calc(100vh - ${isMobileView ? 144 : 320}px)`,
          }}
          id="infinite-scroll-container"
          bgcolor={theme.palette.neutral[50]}
          padding="0px 0px 0px 16px"
        >
          {storiesPanelInfiniteScroll}
        </Box>
        <form>
          <Stack
            direction="column"
            spacing={'11px'}
            sx={{ mt: '0.25rem' }}
            padding="24px 12px"
            bgcolor={theme.palette.neutral['50_2']}
            borderRadius={isMobileView ? '0rem' : '0rem 0rem 1rem 1rem'}
            borderTop={'1px solid ' + theme.palette.neutral[600]}
          >
            <Stack
              direction="row"
              width="100%"
              alignItems={'center'}
              justifyContent="flex-end"
              borderRadius="6px"
            >
              <Box flexGrow="1">
                <TextField
                  inputRef={inputRef}
                  multiline
                  fullWidth
                  maxRows={4}
                  placeholder={intl.formatMessage(Messages.PressEnterToSend)}
                  value={contentIdea}
                  onChange={contentIdeaChangeHandler}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && e.ctrlKey) {
                      e.stopPropagation();
                      e.preventDefault();
                      setContentIdea((state) => {
                        return state + '\r\n';
                      });
                    } else {
                      enterKeyPressed(e, async () => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (apiLoading || submitting) return;
                        await generateScriptPost();
                      });
                    }
                  }}
                  inputProps={{
                    sx: {
                      backgroundColor: theme.palette.base.white_2,
                      padding: '9px 8px 0px 8px !important',
                      borderTop: '1px solid ' + theme.palette.neutral[600],
                      marginBottom: '5px',
                      borderRadius: '4px',
                    },
                  }}
                  sx={{
                    fontSize: '15px',
                    '& .MuiInputBase-root': {
                      alignItems: 'flex-end',
                    },
                  }}
                  disabled={readMode}
                />
              </Box>
              {contentIdea && !submitting && (
                <IconButton
                  onClick={() => {
                    setContentIdea('');
                  }}
                  sx={{ mr: '0.5rem' }}
                >
                  <CrossIcon
                    stroke={theme.palette.base.black}
                    sx={{
                      width: '0.5rem',
                      height: '0.5rem',
                    }}
                  />
                </IconButton>
              )}
              <LoadingButton
                disabled={disabled}
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (apiLoading || submitting) return;
                  await generateScriptPost();
                }}
                type="submit"
                disableRipple
                loading={submitting}
                sx={{
                  borderRadius: '6px',
                  height: '38px',
                  maxWidth: '50px !important',
                  paddingLeft: 0,
                  paddingRight: 0,
                  '& >.MuiButton-endIcon': {
                    margin: 0,
                  },
                }}
                className={submitting ? gs.buttonGenerate : gs.buttonPrimary}
                loadingPosition="end"
                endIcon={<SendIcon stroke={theme.palette.base.white} />}
              ></LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Stack>
      <Box flexGrow={1}></Box>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      fontSize: '22px',
      color: theme.custom.black,
    },
    title2: {
      fontWeight: 600,
      fontSize: '16px',
      color: theme.custom.black,
    },
    title3: {
      fontWeight: 600,
      fontSize: '14px',
      color: theme.custom.black,
    },
    subTitle: {
      fontWeight: 600,
      fontSize: '12px',
      color: theme.palette.grey.black_white,
    },
    section: {
      height: '3.875rem',
      backgroundColor: theme.custom.black2,
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      padding: '1rem 1.5rem',
    },
    textfield: {
      borderRadius: '0.5rem !important',
      overflow: 'hidden',
      backgroundColor: `${theme.palette.base.white_2} !important`,
      padding: '0px 4px 0px !important',
      border: '1px solid' + theme.palette.neutral[700],
    },
    content: {
      fontFamily: 'Outfit',
      fontSize: '1rem',
      fontWeight: 400,
    },
    performance: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    card: {
      padding: '1rem',
      borderRadius: '0.5rem',
    },
    panelTransparent: {
      padding: '1rem 1.5rem',
      borderRadius: '0.5rem',
      marginBottom: '1rem',
    },
    creatorMediaPhotoClass: {
      marginTop: '1.375rem',
    },
    iconSize: {
      width: '0.875rem',
      height: '0.875rem',
      marginLeft: '0.5rem',
      cursor: 'pointer',
    },
    iconSize16: {
      width: '1rem',
      height: '1rem',
      marginLeft: '1rem',
      cursor: 'pointer',
    },
    iconSizePerformance: {
      width: '1rem',
      height: '1rem',
    },
    iconSizeMusic: {
      width: '0.875rem',
      height: '0.875rem',
      marginLeft: '0.5rem',
      cursor: 'pointer',
    },
    time: {
      fontSize: '0.75rem',
      fontWeight: '400',
      color: theme.custom.gray,
    },
    videoPanel: {
      height: 'calc(100% - 60px)',
      width: '100%',
      backgroundColor: theme.custom.primary5,
      borderRadius: '0.5rem',
      marginTop: '1.25rem',
    },
    blue: {
      fontSize: '15px',
      fontWeight: 500,
      color: `${theme.palette.primary[700]} !important`,
    },
    text: {
      fontFamily: 'Outfit',
      fontSize: '13px',
      fontWeight: 400,
      color: theme.palette.grey.black_lightgrey,
    },
  })
);
