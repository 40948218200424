import { defineMessages } from 'react-intl';
export const UserAccountMessages = defineMessages({
  alreadyHaveAnAccount: {
    id: 'alreadyHaveAnAccount',
    defaultMessage: 'Already have an account?',
  },
  dontHaveAnAccount: {
    id: 'dontHaveAnAccount',
    defaultMessage: "Don't have an account?",
  },
  joinUs: {
    id: 'joinUs',
    defaultMessage: 'Choose who you want to be',
  },
  tellRole: {
    id: 'tellRole',
    defaultMessage: 'To begin this journey, tell us what your role is.',
  },
  findBrandsToCollaborate: {
    id: 'findBrandsToCollaborate',
    defaultMessage: 'Find brands to collaborate',
  },
  brands: {
    id: 'brands',
    defaultMessage: 'Brands',
  },
  findCreatorsToPromote: {
    id: 'findCreatorsToPromote',
    defaultMessage: 'Find creators to promote your products',
  },
  bossAddress: {
    id: 'bossAddredd',
    defaultMessage:
      'Bena integrates everything into one, refreshingly intuitive suite, allowing my team to accomplish much more in a fraction of the time.',
  },
  bossName: {
    id: 'bossName',
    defaultMessage: 'Vincent Lee',
  },
  bossTitle: {
    id: 'bossTitle',
    defaultMessage: 'CEO of Bena',
  },
  registerBrandAccount: {
    id: 'registerBrandAccount',
    defaultMessage: 'Brand Account Registration!',
  },
  BrandAccount: {
    id: 'BrandAccount',
    defaultMessage: 'Brand Account',
  },
  Registration: {
    id: 'Registration',
    defaultMessage: 'Registration!',
  },
  registerCreatorAccount: {
    id: 'registerCreatorAccount',
    defaultMessage: 'Creator Account Registration!',
  },
  yourFullname: {
    id: 'yourFullname',
    defaultMessage: 'Your fullname ',
  },
  yourBrandName: {
    id: 'yourBrandName',
    defaultMessage: 'Your Brand Name ',
  },
  emailAddress: {
    id: 'emailAddress',
    defaultMessage: 'Email address',
  },
  verificationCode: {
    id: 'verificationCode',
    defaultMessage: 'Verification code from email',
  },
  enterYourFullname: {
    id: 'enterYourFullname',
    defaultMessage: 'Enter your fullname',
  },
  enterYourBrandName: {
    id: 'enterYourBrandName',
    defaultMessage: 'Enter your brand name',
  },
  enterEmailAddress: {
    id: 'enterEmailAddress',
    defaultMessage: 'Enter email address',
  },
  enter6DigitalCode: {
    id: 'enter6DigitalCode',
    defaultMessage: '------',
  },
  sendCode: {
    id: 'sendCode',
    defaultMessage: 'Send Code',
  },
  sendCodeAgain: {
    id: 'sendCodeAgain',
    defaultMessage: 'Send Code Again',
  },
  textSecondInAbbr: {
    id: 'textSecondInAbbr',
    defaultMessage: 's',
  },
  resendCodeIn: {
    id: 'resendCodeIn',
    defaultMessage: 'Resend in',
  },
  didntGetACode: {
    id: 'didntGetACode',
    defaultMessage: `Didn’t get a code?`,
  },
  checkSpanFolder: {
    id: 'checkSpanFolder',
    defaultMessage: `Check email spam folder.`,
  },
  continueWithGoogle: {
    id: 'continueWithGoogle',
    defaultMessage: `Sign in with Google`,
  },
  signupWithTiktok: {
    id: 'signupWithTiktok',
    defaultMessage: `Sign Up with TikTok`,
  },
  signupWithGoogle: {
    id: 'signupWithGoogle',
    defaultMessage: `Sign Up with Google`,
  },
  signupWithEmail: {
    id: 'signupWithEmail',
    defaultMessage: `Sign Up with Email`,
  },
  createAccount: {
    id: 'createAccount',
    defaultMessage: `Create Account`,
  },
  alertCheckPolicyAndPrivacy: {
    id: 'alertCheckPolicyAndPrivacy',
    defaultMessage: `Bena Privacy Policy and Terms of Service SHOULD be checked to continue.`,
  },
  donotHaveAnAccount: {
    id: 'donotHaveAnAccount',
    defaultMessage: `New user?`,
  },
  nickName: {
    id: 'nickName',
    defaultMessage: `Nickname`,
  },
  enterNickName: {
    id: 'enterNickName',
    defaultMessage: `Enter nickname`,
  },
  userProfile: {
    id: 'userProfile',
    defaultMessage: `User Profile`,
  },
  mobileNumber: {
    id: 'mobileNumber',
    defaultMessage: `Phone Number`,
  },
  enterMobileNumber: {
    id: 'enterMobileNumber',
    defaultMessage: `Enter phone number`,
  },
  changeEmail: {
    id: 'changeEmail',
    defaultMessage: 'Change Email',
  },
  changePasswordEmail: {
    id: 'changePasswordEmail',
    defaultMessage: 'Email & Password',
  },
  registerSuccess: {
    id: 'registerSuccess',
    defaultMessage: `You've been successfully registered on Bena!`,
  },
  registerSuccessTip: {
    id: 'registerSuccessTip',
    defaultMessage: `We will provide you with the great experience of collaboration`,
  },
  startCollaboration: {
    id: 'startCollaboration',
    defaultMessage: `Start collaboration`,
  },
  brand: {
    id: 'brand',
    defaultMessage: `Brand`,
  },
  creator: {
    id: 'creator',
    defaultMessage: `Creator`,
  },
  myProfile: {
    id: 'myProfile',
    defaultMessage: `My profile`,
  },
  payment: {
    id: 'payment',
    defaultMessage: `Payment`,
  },
  shippingAddress: {
    id: 'shippingAddress',
    defaultMessage: `Shipping Address`,
  },
  socialMedia: {
    id: 'socialMedia',
    defaultMessage: `Social Media`,
  },
  profileEdit: {
    id: 'profileEdit',
    defaultMessage: `Profile Edit`,
  },
  otherContacts: {
    id: 'otherContacts',
    defaultMessage: `Other Contacts`,
  },
  profileBiography: {
    id: 'profileBiography',
    defaultMessage: 'Profile Biography',
  },
  onlyFreeItem: {
    id: 'onlyFreeItem',
    defaultMessage: 'Only Free Product',
  },
  birthDate: {
    id: 'birthDate',
    defaultMessage: 'Birthday',
  },
  contactEmail: {
    id: 'contactEmail',
    defaultMessage: 'Contact Email',
  },
  whatMakesAGoodProfile: {
    id: 'whatMakesAGoodProfile',
    defaultMessage: 'What makes a good profile?',
  },
  rate: {
    id: 'rate',
    defaultMessage: 'Rate',
  },
  linkYour: {
    id: 'linkYour',
    defaultMessage: 'Link your ',
  },
  phoneNumber: {
    id: 'phoneNumber',
    defaultMessage: 'Phone Number ',
  },
  website: {
    id: 'website',
    defaultMessage: 'website ',
  },
  enterWebsite: {
    id: 'enterWebsite',
    defaultMessage: 'Enter your website',
  },
  brandsThatYouLike: {
    id: 'brandsThatYouLike',
    defaultMessage: 'Your Favorite Brands',
  },
  state: {
    id: 'state',
    defaultMessage: 'State/Province',
  },
  enterState: {
    id: 'enterState',
    defaultMessage: 'Enter state/province',
  },
  city: {
    id: 'city',
    defaultMessage: 'City',
  },
  enterCity: {
    id: 'enterCity',
    defaultMessage: 'Enter your city',
  },
  mainInformation: {
    id: 'mainInformation',
    defaultMessage: 'Main Information',
  },
  describeYourself: {
    id: 'describeYourself',
    defaultMessage: 'Describe yourself',
  },
  uploadYourBrandLogo: {
    id: 'uploadYourBrandLogo',
    defaultMessage: 'Upload your brand logo',
  },
  whatMakesAGoodProfileTooltip: {
    id: 'whatMakesAGoodProfileTooltip',
    defaultMessage:
      'Stand out from the crowd and make more visible for brands with a complete profile: bio, contacts, profile picture, gender, birthday, starting price to work with brands, product interests, brand interests, and your social media accounts.',
  },
  whatMakesAGoodProfileTooltipForBrand: {
    id: 'whatMakesAGoodProfileTooltipForBrand',
    defaultMessage:
      'Stand out from the crowd and make more visible for creators with a complete profile: brand logo, main information, social media accounts, agent info, biography and your product categories.',
  },
  orRetry: {
    id: 'orRetry',
    defaultMessage: 'Or retry ',
  },
  officialSiteLink: {
    id: 'officialSiteLink',
    defaultMessage: 'Official site link',
  },
  amazonLink: {
    id: 'amazonLink',
    defaultMessage: 'Amazon Link',
  },
  enterAmazonLink: {
    id: 'enterAmazonLink',
    defaultMessage: 'Your brand’s amazon marketplace',
  },
  yourProduct: {
    id: 'yourProduct',
    defaultMessage: 'Your Product',
  },
  pickYourProductCategories: {
    id: 'pickYourProductCategories',
    defaultMessage: 'Pick your product categories',
  },
  regExpFormBrandNameTip:{
    id: 'regExpFormBrandNameTip',
    defaultMessage: 'Please use only English words or characters during registration.'
  },
  chooseMaximum5Categories: {
    id: 'chooseMaximum5Categories',
    defaultMessage: 'Choose maximum 5 categories ',
  },
  agentInfo: {
    id: 'agentInfo',
    defaultMessage: 'Agent Info',
  },
  agentFullName: {
    id: 'agentFullName',
    defaultMessage: 'Agent Full Name',
  },
  agentContactEmail: {
    id: 'agentContactEmail',
    defaultMessage: 'Agent Contact Email',
  },
  agentPhoneNumber: {
    id: 'agentPhoneNumber',
    defaultMessage: 'Agent Phone Number',
  },
  brandBiography: {
    id: 'brandBiography',
    defaultMessage: 'Brand Biography',
  },
  brandSpecialities: {
    id: 'brandSpecialities',
    defaultMessage: 'Brand Product Categories',
  },
  describeYourBrand: {
    id: 'describeYourBrand',
    defaultMessage: 'Describe your brand',
  },
  brandProfile: {
    id: 'brandProfile',
    defaultMessage: `Brand Profile`,
  },
  brandWebsite: {
    id: 'brandWebsite',
    defaultMessage: `Brand Website`,
  },
  enterBrandWebsite: {
    id: 'enterBrandWebsite',
    defaultMessage: `Enter brand Website`,
  },
  subscription: {
    id: 'subscription',
    defaultMessage: 'Subscription',
  },
  subscriptionPlan: {
    id: 'subscriptionPlan',
    defaultMessage: 'Subscription Plan',
  },
  yourCurrentSubscriptionPlan: {
    id: 'yourCurrentSubscriptionPlan',
    defaultMessage: 'Your Current Subscription Plan',
  },
  yourFreeTrialWillBeStoppedOn: {
    id: 'yourFreeTrialWillBeStoppedOn',
    defaultMessage: 'Your free trial will be stopped on',
  },
  yourFreeTrialHasBeenExpiredOn: {
    id: 'yourFreeTrialHasBeenExpiredOn',
    defaultMessage: 'Your Free trial has been expired on',
  },
  yourSubscriptionWillBeStoppedOn: {
    id: 'yourSubscriptionWillBeStoppedOn',
    defaultMessage: 'Your subscription will expire on',
  },
  changePlan: {
    id: 'changePlan',
    defaultMessage: 'Change Plan',
  },
  yourSubscriptionPlanHasBeedUpdated: {
    id: 'yourSubscriptionPlanHasBeedUpdated',
    defaultMessage: 'Your subscription plan has been updated.',
  },
  freeTrial: {
    id: 'freeTrial',
    defaultMessage: 'Free Trial',
  },
  perMonth: {
    id: 'perMonth',
    defaultMessage: 'per month',
  },
  perQuarter: {
    id: 'perQuarter',
    defaultMessage: 'per quarter',
  },
  perYear: {
    id: 'perYear',
    defaultMessage: 'per year',
  },
  starter: {
    id: 'starter',
    defaultMessage: 'Starter',
  },
  platinum: {
    id: 'platinum',
    defaultMessage: 'Platinum',
  },
  enterprise: {
    id: 'enterprise',
    defaultMessage: 'Enterprise',
  },
  growth: {
    id: 'growth',
    defaultMessage: 'Growth',
  },
  professional: {
    id: 'professional',
    defaultMessage: 'Professional',
  },
  yourNextBillWillBeDueOn: {
    id: 'yourNextBillWillBeDueOn',
    defaultMessage: 'Your next bill will be due on',
  },
  xPaymentAccount: {
    id: 'xPaymentAccount',
    defaultMessage: '{name} payment account',
  },
  endingIn: {
    id: 'endingIn',
    defaultMessage: 'ending in',
  },
  editPayment: {
    id: 'editPayment',
    defaultMessage: 'Edit Payment',
  },
  creditCard: {
    id: 'creditCard',
    defaultMessage: 'Credit Card',
  },
  bankAccount: {
    id: 'bankAccount',
    defaultMessage: 'Bank Account',
  },
  primary: {
    id: 'primary',
    defaultMessage: 'Primary',
  },
  usBankAccount: {
    id: 'usBankAccount',
    defaultMessage: 'US Bank Account',
  },
  accountType: {
    id: 'accountType',
    defaultMessage: 'account type',
  },
  per: {
    id: 'per',
    defaultMessage: 'per',
  },
  month: {
    id: 'month',
    defaultMessage: 'month',
  },
  quater: {
    id: 'quater',
    defaultMessage: 'quater',
  },
  year: {
    id: 'year',
    defaultMessage: 'year',
  },
  forceLogoutBySessionLimit: {
    id: 'forceLogoutBySessionLimit',
    defaultMessage:
      'WARNING: Only one active session is supported! You are being logged out because another session just signed in from a different location. If that is confirmed to be yourself, you are safe to ignore. Feel free to sign-in again from here to get back to the session. Otherwise, your account might have been compromised. Please consider reset your password immediately.',
  },
  creatorRegistrationViaEmail: {
    id: 'creatorRegistrationViaEmail',
    defaultMessage: 'Creator Registration via Email',
  },
  registerAndNext: {
    id: 'registerAndNext',
    defaultMessage: 'Register and Next',
  },
  ssoUnlinkConfirmation: {
    id: 'ssoUnlinkConfirmation',
    defaultMessage: 'Are you sure to unlink the account?',
  },
  personalInformation: {
    id: 'personalInformation',
    defaultMessage: 'Personal information',
  },
  back: {
    id: 'back',
    defaultMessage: 'Back',
  },
  showMessageSendCodeSuccess: {
    id: 'showMessageSendCodeSuccess',
    defaultMessage:
      'Verification code has been sent! If you are having trouble finding your code, please check your spam folder.',
  },
  Back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  forgotPassword: {
    id: 'forgotPassword',
    defaultMessage: 'Forgot Password?',
  },
  forgotPasswordHint: {
    id: 'forgotPasswordHint',
    defaultMessage: `Enter the email associated with your account and we'll send an email with instructions to restore your password.`,
  },
  Next: {
    id: 'Next',
    defaultMessage: 'Next',
  },
  password: {
    id: 'password',
    defaultMessage: `Password`,
  },
  enterAPassword: {
    id: 'enterAPassword',
    defaultMessage: 'Enter a password',
  },
  hide: {
    id: 'hide',
    defaultMessage: 'Hide',
  },
  show: {
    id: 'show',
    defaultMessage: 'Show',
  },
  loginText: {
    id: 'loginText',
    defaultMessage: `Log In`,
  },
  loginSuccess:{
    id: 'loginSuccess',
    defaultMessage: 'Log In Success!',
  },
  Or: {
    id: 'Or',
    defaultMessage: 'Or',
  },
  SignIn: {
    id: 'SignIn',
    defaultMessage: 'Log In',
  },
  createPassword: {
    id: 'createPassword',
    defaultMessage: 'Create password',
  },
  passwordDonotMatch: {
    id: 'passwordDonotMatch',
    defaultMessage: 'Passwords do not match',
  },
  repeatPassword: {
    id: 'repeatPassword',
    defaultMessage: 'Confirm new password',
  },
  repeatAPassword: {
    id: 'repeatAPassword',
    defaultMessage: 'Repeat a password',
  },
  registerSucceed: {
    id: 'registerSucceed',
    defaultMessage: 'Sign Up Succeed!',
  },
  passwordSettingRules: {
    id: 'passwordSettingRules',
    defaultMessage: 'Password minimum length is 8.',
  },
  register: {
    id: 'register',
    defaultMessage: 'Sign Up',
  },
  newPassword: {
    id: 'newPassword',
    defaultMessage: `New password`,
  },
  success: {
    id: 'success',
    defaultMessage: 'Success!',
  },
  resetPasswordSucceed: {
    id: 'resetPasswordSucceed',
    defaultMessage: 'Restore password succeed!',
  },
  resetPasswordText: {
    id: 'resetPasswordText',
    defaultMessage: 'Restore password?',
  },
  resetPassword: {
    id: 'resetPassword',
    defaultMessage: 'Restore password',
  },
  BenaPrivacyPolicy: {
    id: 'BenaPrivacyPolicy',
    defaultMessage: 'Bena Privacy Policy',
  },
  BenaTermsOfService: {
    id: 'BenaTermsOfService',
    defaultMessage: 'Bena Terms of Service',
  },
  DeleteAccount: {
    id: 'DeleteAccount',
    defaultMessage: 'Delete Account',
  },
  DeleteAccountHint: {
    id: 'DeleteAccountHint',
    defaultMessage:
      'Bena provides you the option to delete your account. It means that your account will be totally deleted including data such as campaigns, messages, personal informations and other data related to your account. Please make sure you are totally aware of the risk of doing this. If you are still going to delete the account, click the delete account button below.',
  },
  DeleteAccountConfirmation: {
    id: 'DeleteAccountConfirmation',
    defaultMessage:
      "Are you sure to delete this account? All data will be deleted and can't be restored.",
  },
});
