import {
  Autocomplete,
  Box,
  capitalize,
  Chip,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { DeleteIcon } from '../../assets/imgs/icons';
import BenaCheckbox from '../../components/BenaCheckbox';
import MultipleValuedSelection from '../../components/MultipleValuedSelection';
import { useIsAdminUser, useIsBenaUser } from '../../hooks/account';
import { useSelectDropDownArrow } from '../../hooks/component';
import { useFilterCountriesByName } from '../../hooks/countries';
import { useThrottle } from '../../hooks/throttle';
import { Messages } from '../../localization/Messages';
import { useAppDispatch, useTypedSelector } from '../../redux/store';
import {
  selectCreatorsFilter,
  updateCreatorsFilter,
} from './dynamicSearchSlice';

const TopicsAndKeywordsWidth = {
  width: {
    lg: '304px',
    xl: '304px',
    md: '100%',
    sm: '100%',
    xs: '100%',
  },
};
const LocationWidth = {
  width: {
    lg: '294px',
    xl: '294px',
    md: '100%',
    sm: '100%',
    xs: '100%',
  },
};
const AudienceGenderWidth = {
  width: {
    lg: '314px',
    xl: '314px',
    md: '100%',
    sm: '100%',
    xs: '100%',
  },
};
const AudienceAgeWidth = {
  width: {
    lg: '314px',
    xl: '314px',
    md: '100%',
    sm: '100%',
    xs: '100%',
  },
};

const CreatorsFilter = ({
  resetFilter,
  handleClose,
}: {
  resetFilter?: number;
  handleClose?: fnVoidToVoid;
}) => {
  const SelectArrow = useSelectDropDownArrow();
  const theme = useTheme();
  const intl = useIntl();
  const isAdmin = useIsAdminUser();
  const isBenaUser = useIsBenaUser();
  const creatorFilterRedux = useTypedSelector(selectCreatorsFilter);
  const [creatorFilter, setCreatorFilter] = useState<typeof initCreatorFilter>({
    ...initCreatorFilter,
    ...creatorFilterRedux,
  });
  const dispatch = useAppDispatch();

  const throttledFn = useThrottle(() => {
    dispatch(updateCreatorsFilter(creatorFilter));
  }, 1000);

  useEffect(() => {
    // prevent recursive update
    if (JSON.stringify(creatorFilterRedux) !== JSON.stringify(creatorFilter)) {
      throttledFn();
    }
  }, [creatorFilter]);

  const onSettingChange = (changes: Partial<CreatorsFilterT>) => {
    const newFilters = { ...creatorFilter, ...changes };
    setCreatorFilter(newFilters);
  };

  const handleMultiSelectChangeV2 =
    (field: keyof typeof creatorFilter) => (value: string) => {
      onSettingChange({
        [field]: ['topics', 'keywords'].includes(field)
          ? value === ''
            ? []
            : value.includes(',')
            ? value.split(',').filter((v) => v)
            : [value]
          : value,
      });
    };

  useEffect(() => {
    setCreatorFilter({ ...initCreatorFilter });
  }, [resetFilter]);

  useEffect(() => {
    setCreatorFilter({
      ...initCreatorFilter,
      ...creatorFilterRedux,
    });
  }, [creatorFilterRedux]);

  const {
    creatorFollowersOptions,
    creatorsGenderOptions,
    engagementOptions,
    audienceGenderOptions,
    audienceAgeOptions,
    lastPostOptions,
    favoritedOptions,
    ismoderatedOptions,
    creatorHasEmailOptions,
  } = getCreatorFilterOptions(intl);

  const topicsRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLInputElement>(null);
  const inputValuesRef = useRef({ topics: '', content: '' });

  const onKeyPress = (
    e: React.KeyboardEvent,
    key: keyof typeof inputValuesRef.current
  ) => {
    if (e.key === 'Enter') {
      const v = creatorFilter[key].slice();
      const newValue = inputValuesRef.current[key];
      if (!v.find((w) => w === newValue)) {
        setCreatorFilter({
          ...creatorFilter,
          [key]: [...v, newValue],
        });
      }
      (e.currentTarget as HTMLInputElement).value = '';
    }
  };
  const clearInputValues = (key: keyof typeof inputValuesRef.current) => {
    setCreatorFilter({ ...creatorFilter, [key]: [] });
    if (key === 'topics' && topicsRef.current) {
      topicsRef.current.value = '';
    }
    if (key === 'content' && contentRef.current) {
      contentRef.current.value = '';
    }
  };

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        '& > div': {
          minWidth: '13rem',
          rowGap: '0.125rem',
          columnGap: '0.125rem',
          flexGrow: 1,
        },
        '& > div > p': {
          fontSize: '0.9375rem',
        },
      }}
      direction="row"
      flexWrap={'wrap'}
      rowGap="0.75rem"
      columnGap={'0.5rem'}
      justifyContent="flex-start"
    >
      <Stack direction={'column'}>
        <Typography>
          {capitalize(intl.formatMessage(Messages.gender))}
        </Typography>
        <FormControl fullWidth>
          <Select
            id="creatorsGender"
            value={creatorFilter.creatorsGender}
            label=""
            onChange={(e) =>
              handleMultiSelectChangeV2('creatorsGender')(e.target.value)
            }
            MenuProps={{ disablePortal: true }}
            IconComponent={SelectArrow}
          >
            {creatorsGenderOptions.map((v) => (
              <MenuItem value={v.value} key={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction={'column'}>
        <Typography>
          {capitalize(intl.formatMessage(Messages.followerSize))}
        </Typography>
        <FormControl fullWidth>
          <Select
            id="creatorFollowers"
            value={creatorFilter.creatorFollowers}
            label=""
            onChange={(e) =>
              handleMultiSelectChangeV2('creatorFollowers')(e.target.value)
            }
            MenuProps={{ disablePortal: true }}
            IconComponent={SelectArrow}
          >
            {creatorFollowersOptions.map((v) => (
              <MenuItem value={v.value} key={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction={'column'}>
        <Typography>
          {capitalize(intl.formatMessage(Messages.engagement))}
        </Typography>
        <FormControl fullWidth>
          <Select
            id="engagement"
            value={creatorFilter.engagement}
            label=""
            onChange={(e) =>
              handleMultiSelectChangeV2('engagement')(e.target.value)
            }
            MenuProps={{ disablePortal: true }}
            IconComponent={SelectArrow}
          >
            {engagementOptions.map((v) => (
              <MenuItem value={v.value} key={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction={'column'}>
        <Typography>
          {capitalize(intl.formatMessage(Messages.lastPost))}
        </Typography>
        <FormControl fullWidth>
          <Select
            id="lastPost"
            value={creatorFilter.lastPost}
            label=""
            onChange={(e) =>
              setCreatorFilter({
                ...creatorFilter,
                lastPost: e.target.value,
              })
            }
            MenuProps={{ disablePortal: true }}
            IconComponent={SelectArrow}
          >
            {lastPostOptions.map((v) => (
              <MenuItem value={v.value} key={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction={'column'}>
        <Typography>
          {capitalize(intl.formatMessage(Messages.favorited))}
        </Typography>
        <FormControl fullWidth>
          <Select
            id="favorited"
            value={creatorFilter.favorited}
            label=""
            onChange={(e) =>
              setCreatorFilter({
                ...creatorFilter,
                favorited: e.target.value,
              })
            }
            MenuProps={{ disablePortal: true }}
            IconComponent={SelectArrow}
          >
            {favoritedOptions.map((v) => (
              <MenuItem value={v.value} key={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {isAdmin && (
        <Stack direction={'column'}>
          <Typography color="warning.500">
            {capitalize(intl.formatMessage(Messages.isModerated))}
          </Typography>
          <FormControl fullWidth>
            <Select
              id="ismoderated"
              value={creatorFilter.ismoderated}
              label=""
              onChange={(e) =>
                setCreatorFilter({
                  ...creatorFilter,
                  ismoderated: e.target.value,
                })
              }
              MenuProps={{ disablePortal: true }}
              IconComponent={SelectArrow}
            >
              {ismoderatedOptions.map((v) => (
                <MenuItem value={v.value} key={v.value}>
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )}
      <LocationMultipleSelection
        caption={capitalize(intl.formatMessage(Messages.influencerLocation))}
        value={creatorFilter.creatorLocations}
        onChange={(strs) =>
          setCreatorFilter({
            ...creatorFilter,
            creatorLocations: strs,
          })
        }
      />
      {/* <LocationMultipleSelection
        caption={capitalize(intl.formatMessage(Messages.audienceLocation))}
        value={creatorFilter.audienceLocations}
        onChange={(strs) =>
          setCreatorFilter({
            ...creatorFilter,
            audienceLocations: strs,
          })
        }
      /> */}
      <Stack direction={'column'} sx={TopicsAndKeywordsWidth}>
        <Typography>
          {capitalize(intl.formatMessage(Messages.topics))}
        </Typography>
        <Stack
          direction="row"
          flexWrap={'wrap'}
          alignItems="center"
          rowGap="0.25rem"
          columnGap={'0.5rem'}
          padding={(creatorFilter.topics.length ? '0.5' : '0') + 'rem 0.5rem'}
          borderRadius="0.5rem"
          sx={{ backgroundColor: theme.palette.base['25_2'] }}
        >
          {creatorFilter.topics.map((value, index) => (
            <Chip
              key={value + '' + index}
              label={`# ${value}`}
              sx={{ pr: 1 }}
              deleteIcon={
                <DeleteIcon
                  stroke={theme.palette.primary[600]}
                  sx={{ width: '0.5rem', height: '0.5rem' }}
                />
              }
              onDelete={() => {
                const newTopics = creatorFilter.topics.slice();
                newTopics.splice(index, 1);
                setCreatorFilter({
                  ...creatorFilter,
                  topics: [...newTopics],
                });
              }}
            />
          ))}
          <Input
            disableUnderline
            placeholder={capitalize(
              intl.formatMessage(Messages.typeAndPressEnter)
            )}
            onKeyUp={(e) => onKeyPress(e, 'topics')}
            onChange={(e) =>
              (inputValuesRef.current['topics'] = e.target.value)
            }
            inputRef={topicsRef}
            sx={{ flexGrow: 1 }}
          ></Input>
          <Box flexGrow={1}></Box>
          <IconButton disableRipple onClick={() => clearInputValues('topics')}>
            <Typography color="danger.400" sx={{ fontSize: '0.875rem' }}>
              {capitalize(intl.formatMessage(Messages.clear))}
            </Typography>
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction={'column'} sx={TopicsAndKeywordsWidth}>
        <Typography>
          {capitalize(intl.formatMessage(Messages.keywords))}
        </Typography>
        <Stack
          direction="row"
          flexWrap={'wrap'}
          alignItems="center"
          rowGap="0.25rem"
          columnGap={'0.5rem'}
          padding={(creatorFilter.content.length ? '0.5' : '0') + 'rem 0.5rem'}
          borderRadius="0.5rem"
          sx={{ backgroundColor: theme.palette.base['25_2'] }}
        >
          {creatorFilter.content.map((value, index) => (
            <Chip
              key={value + '' + index}
              label={`# ${value}`}
              sx={{ pr: 1 }}
              deleteIcon={
                <DeleteIcon
                  stroke={theme.palette.primary[600]}
                  sx={{ width: '0.5rem', height: '0.5rem' }}
                />
              }
              onDelete={() => {
                const newContent = creatorFilter.content.slice();
                newContent.splice(index, 1);
                setCreatorFilter({
                  ...creatorFilter,
                  content: [...newContent],
                });
              }}
            />
          ))}
          <Input
            disableUnderline
            placeholder={capitalize(
              intl.formatMessage(Messages.typeAndPressEnter)
            )}
            onKeyUp={(e) => onKeyPress(e, 'content')}
            onChange={(e) =>
              (inputValuesRef.current['content'] = e.target.value)
            }
            inputRef={contentRef}
            sx={{ flexGrow: 1 }}
          ></Input>
          <Box flexGrow={1}></Box>
          <IconButton disableRipple onClick={() => clearInputValues('content')}>
            <Typography color="danger.400" sx={{ fontSize: '0.875rem' }}>
              {capitalize(intl.formatMessage(Messages.clear))}
            </Typography>
          </IconButton>
        </Stack>
      </Stack>
      {/* <MultipleValuedSelection
        caption={capitalize(intl.formatMessage(Messages.audienceGender))}
        options={audienceGenderOptions.filter((v) => v.value !== 'all')}
        onChange={(values) => {
          if (
            JSON.stringify(values) !==
            JSON.stringify(creatorFilter.__audienceGenderMultiple)
          ) {
            setCreatorFilter({
              ...creatorFilter,
              __audienceGenderMultiple: values,
            });
          }
        }}
        initValues={creatorFilter.__audienceGenderMultiple}
        responsiveWidth={AudienceGenderWidth}
      />
      <MultipleValuedSelection
        caption={capitalize(intl.formatMessage(Messages.audienceAge))}
        options={audienceAgeOptions.filter((v) => v.value !== 'all')}
        onChange={(values) => {
          if (
            JSON.stringify(values) !==
            JSON.stringify(creatorFilter.__audienceAgeMultiple)
          ) {
            setCreatorFilter({
              ...creatorFilter,
              __audienceAgeMultiple: values,
            });
          }
        }}
        initValues={creatorFilter.__audienceAgeMultiple}
        responsiveWidth={AudienceAgeWidth}
      /> */}
      {isBenaUser && (
        <Stack direction={'column'}>
          <Typography color="warning.500">
            {capitalize(intl.formatMessage(Messages.CreatorHasEmail))}
          </Typography>
          <FormControl fullWidth>
            <Select
              id="creatorHasEmail"
              value={creatorFilter.creatorHasEmail}
              label=""
              onChange={(e) =>
                setCreatorFilter({
                  ...creatorFilter,
                  creatorHasEmail: e.target.value,
                })
              }
              MenuProps={{ disablePortal: true }}
              IconComponent={SelectArrow}
            >
              {creatorHasEmailOptions.map((v) => (
                <MenuItem value={v.value} key={v.value}>
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )}
      <Box sx={{ flexGrow: '4 !important' }}></Box>
    </Stack>
  );
};

type LocationMultipleSelectionProps = {
  caption: string;
  value: string[];
  onChange: (values: string[]) => void;
};
function LocationMultipleSelection({
  caption,
  value,
  onChange,
}: LocationMultipleSelectionProps) {
  const intl = useIntl();
  const theme = useTheme();
  const filterCountriesFn = useFilterCountriesByName();

  const filteredCountries = filterCountriesFn('');

  const SelectArrow = useSelectDropDownArrow();
  const arrow = <SelectArrow />;

  return (
    <Stack direction={'column'} flexGrow="1" sx={LocationWidth}>
      <Typography>{caption}</Typography>
      <Autocomplete
        id={caption}
        popupIcon={arrow}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: 'auto',
            minHeight: '3rem',
          },
        }}
        multiple
        disableCloseOnSelect
        options={filteredCountries}
        onChange={(e, newValue) =>
          onChange(typeof newValue === 'string' ? [newValue] : newValue)
        }
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props}>
            <BenaCheckbox checked={selected} />
            <Typography variant="caption">{option}</Typography>
          </MenuItem>
        )}
        value={value}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              sx={{ pr: 1 }}
              label={option}
              {...getTagProps({ index })}
              deleteIcon={
                <DeleteIcon
                  stroke={theme.palette.primary[600]}
                  sx={{ width: '0.5rem', height: '0.5rem' }}
                />
              }
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            label=""
            {...params}
            placeholder={capitalize(intl.formatMessage(Messages.choose))}
            sx={{
              '& input': {
                padding: '0 !important',
                margin: '0 !important',
                paddingLeft: 16,
              },
            }}
          />
        )}
        fullWidth
      />
    </Stack>
  );
}

export type CreatorsFilterT = {
  audienceGender: string;
  audienceLocations: string[];
  commenterLocations: string[];
  creatorLocations: string[];
  engagement: string;
  creatorType: string[];
  contentCategory: string[];
  avgContentViews: string;
  creatorFollowers: string;
  audienceAge: string;
  creatorsGender: string;
  creatorAge: string;
  creatorRace: string;
  hasEmail: string;
  lastPost: string;
  topics: string[];
  content: string[];
  favorited: string;
  ismoderated: string;
  creatorHasEmail: string;
  hasTiktokShop: string;
  _avgContentViewsCustom: number[];
  _creatorFollowersCustom: number[];
  _audienceAgeCustom: number[];
  _engagementCustom: number[];
  _audienceGenderCustom: CustomCategoryItemPercent;
  _creatorsGenderCustom: CustomCategoryItemPercent;
  __audienceAgeMultiple: LabelValueType[];
  __audienceGenderMultiple: LabelValueType[];
};
export const initCreatorFilter = {
  audienceGender: 'all',
  audienceLocations: [] as string[],
  commenterLocations: [] as string[],
  creatorLocations: [] as string[],
  engagement: 'all',
  creatorType: [] as string[],
  contentCategory: [] as string[],
  avgContentViews: 'all',
  creatorFollowers: 'all',
  audienceAge: 'all',
  creatorsGender: 'all',
  creatorAge: 'all',
  creatorRace: 'all',
  hasEmail: 'all',
  lastPost: 'all',
  topics: [] as string[],
  content: [] as string[],
  favorited: 'all',
  ismoderated: 'all',
  creatorHasEmail: 'all',
  hasTiktokShop: 'all',
  _avgContentViewsCustom: [200000, 500000],
  _creatorFollowersCustom: [0, 100000],
  _audienceAgeCustom: [18, 30],
  _engagementCustom: [0, 5],
  _audienceGenderCustom: { item: 'male', percent: 50 },
  _creatorsGenderCustom: { item: 'male', percent: 50 },
  __audienceAgeMultiple: [] as LabelValueType[],
  __audienceGenderMultiple: [] as LabelValueType[],
};

const OptionPercentageOptions = [
  { value: '0.1', label: '≥10%' },
  { value: '0.2', label: '≥20%' },
  { value: '0.3', label: '≥30%' },
  { value: '0.5', label: '≥50%' },
  { value: '0.8', label: '≥80%' },
];

export const getCreatorFilterOptions = (intl: IntlShape) => ({
  creatorTypeOptions: {
    values: ['option1', 'option2', 'option3', 'option4'],
    labels: {
      option1: 'Option 1',
      option2: 'Option 2',
      option3: 'Option 3',
      option4: 'Option 4',
    } as { [key: string]: string },
  },
  avgContentViewsRange: [0, 2000000],
  avgContentViewsOptions: [
    { value: '0-50000', label: '0-50K' },
    { value: '50000-200000', label: '50K-200K' },
    { value: '200000-1000000', label: '200K-1M' },
    { value: '1000000-100000000', label: '1M+' },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
  creatorFollowersRange: [0, 2000000000],
  creatorFollowersOptions: [
    { value: '10000-100000', label: '10K-100K' },
    { value: '100000-500000', label: '100K-500K' },
    { value: '500000-2000000', label: '500K-2M' },
    { value: '2000000-10000000', label: '2M+' },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
  audienceAgeRange: [0, 150],
  audienceAgeOptions: [
    { value: '1-17', label: '<18', options: OptionPercentageOptions },
    { value: '18-24', label: '18-24', options: OptionPercentageOptions },
    { value: '25-34', label: '25-34', options: OptionPercentageOptions },
    { value: '35-44', label: '35-44', options: OptionPercentageOptions },
    { value: '45-54', label: '45-54', options: OptionPercentageOptions },
    { value: '55-200', label: '55+', options: OptionPercentageOptions },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
  audienceGenderOptions: [
    {
      value: 'male',
      label: capitalize(intl.formatMessage(Messages.male)),
      options: OptionPercentageOptions,
    },
    {
      value: 'female',
      label: capitalize(intl.formatMessage(Messages.female)),
      options: OptionPercentageOptions,
    },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],

  engagementRange: [0, 100],
  engagementOptions: [
    { value: '0-0.05', label: '< 5%' },
    { value: '0.05-0.1', label: '5% - 10%' },
    { value: '0.1-0.15', label: '10% - 15%' },
    { value: '0.15-1', label: '> 15%' },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],

  creatorsGenderOptions: [
    { value: 'male', label: capitalize(intl.formatMessage(Messages.male)) },
    { value: 'female', label: capitalize(intl.formatMessage(Messages.female)) },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
  creatorAgeRange: [0, 150],
  creatorAgeOptions: [
    { value: '1-17', label: '<18' },
    { value: '18-24', label: '18-24' },
    { value: '25-34', label: '25-34' },
    { value: '35-44', label: '35-44' },
    { value: '45-54', label: '45-54' },
    { value: '55-200', label: '55+' },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
  lastPostOptions: [
    ...[7, 30, 60].map((v) => ({
      value: `${v}`,
      label: capitalize(
        intl.formatMessage(Messages.LastNDay, {
          value: v,
          pl: v > 1 ? 's' : '',
        })
      ),
    })),
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
  favoritedOptions: [
    { value: 'true', label: capitalize(intl.formatMessage(Messages.yes)) },
    { value: 'false', label: capitalize(intl.formatMessage(Messages.no)) },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
  ismoderatedOptions: [
    { value: 'true', label: capitalize(intl.formatMessage(Messages.yes)) },
    { value: 'false', label: capitalize(intl.formatMessage(Messages.no)) },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
  creatorHasEmailOptions: [
    { value: 'true', label: capitalize(intl.formatMessage(Messages.yes)) },
    { value: 'false', label: capitalize(intl.formatMessage(Messages.no)) },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
  hasTiktokShopOptions: [
    { value: 'yes', label: capitalize(intl.formatMessage(Messages.yes)) },
    { value: 'no', label: capitalize(intl.formatMessage(Messages.no)) },
    { value: 'all', label: capitalize(intl.formatMessage(Messages.any)) },
  ],
});

export default CreatorsFilter;
