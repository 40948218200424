import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  IconButton,
  ImageListItem,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Messages } from '../localization/Messages';
import {
  useAvatarUploadMutation,
  useDeleteCampaignFileDeleteMutation,
  useJobContractAttachmentFileDeleteMutation,
} from '../redux/benaApi';
import { MutationHandler } from '../redux/benaApiResultHandler';
import { useGlobalStyles } from '../assets/styles/style';
import {
  CrossLineIcon,
  DeleteIcon,
  FileIcon,
  PencilIcon,
  TrashIcon,
} from '../assets/imgs/icons';
import {
  UrlThumbnail,
  downloadFile,
  useUrlThumbnailStyles,
} from './UrlThumbnail';
import { BenaFileUploadButton } from './BenaFileUploadButton';
import { isMimeVideoOrAudio } from '../utils/mimetype';
import { BenaVideoPlayer } from './BenaVideoPlayer';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';
import {
  xmlHttpFileUploadCampaignCover,
  xmlHttpFileUploadMediaKit,
  xmlHttpJobContractFilePatch,
  xmlHttpJobContractFileUpload,
} from '../redux/xmlHttpFileupload';
import { useAuthToken } from '../hooks/account';
import { abbreviateLink } from '../utils/common';
import { BorderLinearProgressWithLabel } from './LinearProgressWithLabel';

type UploadResponseType = {
  data: FileUploadResponseType;
  uploadId: string;
} | null;

type FileUploaderStateType = {
  id: string;
  uploadedId: string;
  file: File | null;
  previewImage: string;
  progress: number;
  success: boolean;
  error: boolean;
  name?: string;
  content_type?: string;
  url?: string;
  size?: number;
};
type UploadHandler = (
  uploadId: string,
  file: File
) => Promise<UploadResponseType>;

type FileUploaderProps = {
  cols?: number;
  mediaType: string;
  onFilesUploadSuccess: (resArr: FileUploadResponseType[]) => void;
  onSingleFileDeleteSuccess: (id: string) => void;
  medias: FileUploadResponseType[];
  name?: string;
  url?: string;
  sizeLimit?: number;
};

type UploadSateType = { [key: string]: FileUploaderStateType };

export default function FileUploader({
  cols = 4,
  mediaType,
  onFilesUploadSuccess,
  onSingleFileDeleteSuccess,
  medias,
  sizeLimit,
}: FileUploaderProps) {
  const theme = useTheme();
  const intl = useIntl();
  const isAttachmentUpload = mediaType === 'attachment';
  const globalStyles = useGlobalStyles();
  const accessToken = useAuthToken();

  const [fileDelete] = useDeleteCampaignFileDeleteMutation();
  const uploadHandler: UploadHandler = async (uploadId, file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', file.name);
    formData.append('media_type', mediaType);
    let ret: UploadResponseType = null;
    await MutationHandler({
      intl,
      action: async () => {
        return await xmlHttpFileUploadCampaignCover(
          formData,
          (progress) => {
            statusNotifier(uploadId, null, progress.percentage, false);
          },
          accessToken || ''
        );
      },
      onSuccess: (data) => {
        statusNotifier(uploadId, data, 100, false);
        ret = { data, uploadId };
      },
      onError: () => {
        statusNotifier(uploadId, null, 99, true);
      },
      onException: () => {
        statusNotifier(uploadId, null, 99, true);
      },
    });
    return ret;
  };

  const [uploadState, setUploadState] = useState<UploadSateType>({});

  useEffect(() => {
    const obj = {} as UploadSateType;
    medias.forEach(
      (item) =>
        (obj[item.id] = {
          id: '',
          uploadedId: item.id,
          file: null,
          previewImage: item.file,
          progress: 100,
          success: true,
          error: false,
          name: item.name,
          content_type: item.content_type,
        })
    );
    setUploadState(obj);
  }, [medias]);

  const uploadFile = (files: FileList | File[]) => {
    const len = files.length;
    for (let i = 0; i < len; i++) {
      if (sizeLimit !== undefined && files[i].size > sizeLimit) {
        window.alert(
          `${intl.formatMessage(Messages.fileSizeOverLimit)} (${
            files[i].name
          }[${Math.floor(files[i].size / 1024 / 1024)}M>=${
            sizeLimit / 1024 / 1024
          }M])`
        );
        continue;
      }
      // random id to avoid index reference
      const uploadId = Math.random().toString(16).slice(2);
      uploadState[uploadId] = {
        id: uploadId,
        uploadedId: '',
        file: files[i],
        previewImage: URL.createObjectURL(files[i]),
        progress: 1,
        success: false,
        error: false,
      };
    }
    setUploadState({ ...uploadState });

    new Promise(() => {
      const keys = Object.keys(uploadState);
      const promiseArr: Promise<UploadResponseType>[] = [];
      for (let i = 0; i < keys.length; i++) {
        const item = uploadState[keys[i]];
        // progress 1 is the initial state to trigger upload
        if (item.progress === 1) {
          if (item.file) {
            promiseArr.push(uploadHandler(item.id, item.file));
          }
          // update the progress immediately to avoid upload again
          item.progress = 2;
          setUploadState({ ...uploadState });
        }
      }
      Promise.all(promiseArr).then((resArr) => {
        onFilesUploadSuccess(
          resArr
            .filter((item) => item !== null)
            .map((item) => item?.data) as FileUploadResponseType[]
        );
        const newState = { ...uploadState };
        resArr.forEach((item) => {
          if (item) {
            newState[item.uploadId].uploadedId = item.data.id;
            newState[item.uploadId].previewImage = item.data.file;
            newState[item.uploadId].name = item.data.name;
            newState[item.uploadId].content_type = item.data.content_type;
          }
        });
        setUploadState(newState);
      });
    });
  };

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) return;
    uploadFile(event.target.files);
  };

  const dropHandler = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const items = e.dataTransfer.items;
    if (items && items.length > 0) {
      const files = Array.from(items)
        .filter((item) => item.kind === 'file')
        .map((file) => file.getAsFile())
        .filter((f) => f);
      uploadFile(files as File[]);
    } else {
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        uploadFile(files);
      }
    }
  };

  // usecallback to sync with uploadState;
  const statusNotifier = (
    uploadId: string,
    data: FileUploadResponseType | null,
    progress: number,
    error: boolean
  ) => {
    uploadState[uploadId].progress = progress;
    uploadState[uploadId].error = error;
    setUploadState(() => ({ ...uploadState }));
  };

  const handleRemoveFile = (item: FileUploaderStateType) => {
    if (item.uploadedId) {
      MutationHandler({
        intl,
        action: async () => {
          return await fileDelete({
            media_file_ids: [item.uploadedId],
          }).unwrap();
        },
        onSuccess: () => {
          onSingleFileDeleteSuccess(item.uploadedId);
        },
      });
    }
    const obj = { ...uploadState };
    delete obj[item.id];
    setUploadState(obj);
  };

  const handleReupload = (uploadId: string) => {
    const item = uploadState[uploadId];
    item.progress = 1;
    item.error = false;
    setUploadState({ ...uploadState });
  };

  const size = {
    width: isAttachmentUpload ? '20rem' : '16rem',
    height: isAttachmentUpload ? '20rem' : '16rem',
  };
  const uploadInProgress = (progress: number) => progress > 0 && progress < 100;

  const uploadBoxStyle = useUrlThumbnailStyles();
  const mapArray = (arr: FileUploaderStateType[]) =>
    arr.map((item, index) => (
      <ImageListItem key={index}>
        <Box
          sx={{
            position: 'relative',
            ...size,
            ...(isAttachmentUpload ? { mb: 2 } : { mr: 2, mb: 2 }),
          }}
        >
          {!item.error && !uploadInProgress(item.progress) && (
            <>
              {isAttachmentUpload ? (
                <UrlThumbnail
                  url={item.previewImage}
                  name={item.name}
                  contentType={item.content_type}
                />
              ) : isMimeVideoOrAudio(item.content_type || '') ? (
                <BenaVideoPlayer
                  src={item.previewImage}
                  mediaType={item.content_type || ''}
                  width={`${parseInt(size.width) * 16}`}
                />
              ) : (
                <img
                  src={item.previewImage}
                  alt={`${index}`}
                  loading="lazy"
                  style={size}
                  className="imgObjectFit border-radius-8px"
                />
              )}
            </>
          )}
          {!item.error && !uploadInProgress(item.progress) && (
            <IconButton
              onClick={() => {
                handleRemoveFile(item);
              }}
              sx={{
                position: 'absolute',
                top: 1,
                left: 1,
                bgcolor: theme.palette.primary.main,
                mt: 1,
                ml: 1,
                p: 1,
              }}
              className={globalStyles.iconButtonWhiteBackground}
            >
              <DeleteIcon
                fill={theme.palette.primary.main}
                sx={{ width: '0.75rem', height: '0.75rem' }}
              />
            </IconButton>
          )}
          {(item.error || uploadInProgress(item.progress)) && (
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                bgcolor: theme.palette.text.disabled,
                ...size,
              }}
              className={`${
                isAttachmentUpload ? uploadBoxStyle.uploadBoxWithouSize : ''
              } boxcenterhv`}
            >
              {!item.error && uploadInProgress(item.progress) && (
                <CircularProgressWithLabel value={item.progress} />
              )}
              {item.error && (
                <>
                  <IconButton
                    title={intl.formatMessage(Messages.clickReUpload)}
                    onClick={() => handleReupload(item.id)}
                    className={globalStyles.iconButtonTransparent}
                  >
                    <RefreshIcon fontSize="large" htmlColor="white" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      handleRemoveFile(item);
                    }}
                    className={globalStyles.iconButtonTransparent}
                  >
                    <HighlightOffIcon fontSize="large" htmlColor="white" />
                  </IconButton>
                </>
              )}
            </Box>
          )}
        </Box>
      </ImageListItem>
    ));

  const btnUploadId = (Math.random() * 10000).toString(16).slice(10);

  const [className, setClassName] = useState('drag-drop-upload-normal');
  const highlightDropBox = () => {
    setClassName('drag-drop-upload-focus');
  };
  const deHighlightDropBox = () => {
    setClassName('drag-drop-upload-normal');
  };

  return (
    <Box
      className="boxcenterhv"
      sx={{
        flexWrap: 'wrap',
        width: '100%',
        minHeight: '156px',
        border: `1px dashed ${theme.palette.neutral[700]}`,
        padding: '16px',
      }}
    >
      <Stack direction="row" flexWrap="wrap" rowGap="16px" columnGap="16px">
        {mapArray(Object.keys(uploadState).map((key) => uploadState[key]))}
        <Box
          sx={(theme) => ({
            ...size,
            flexDirection: 'column',
          })}
          className={`boxcenterhv border-radius-8px`}
        >
          {!isAttachmentUpload && <Box sx={{ flexGrow: 1 }}></Box>}
          <Box>
            <label
              htmlFor={`btnUploadId${btnUploadId}`}
              title={''}
              onDragOver={(e) => {
                e.preventDefault();
                highlightDropBox();
              }}
              onDrop={(e) => {
                deHighlightDropBox();
                dropHandler(e);
              }}
              onDragLeave={() => deHighlightDropBox()}
            >
              <input
                multiple
                id={`btnUploadId${btnUploadId}`}
                name={`btnUploadId${btnUploadId}`}
                style={{ display: 'none' }}
                type="file"
                accept={
                  isAttachmentUpload
                    ? '*/*'
                    : ['product', 'visual'].includes(mediaType)
                    ? 'image/*,video/*'
                    : 'image/*'
                }
                onChange={selectFile}
                onClick={(e) => {
                  e.currentTarget.value = '';
                }}
              />
              <BenaFileUploadButton
                width={size.width}
                height={size.height}
                className={className}
              />
            </label>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
        </Box>
      </Stack>
    </Box>
  );
}

type FileUploaderSingleProps = {
  imgSize: number;
  initImg: string;
  circleShape: boolean;
  id: string;
  mediaType: string;
  onFilesUploadSuccess: (resArr: FileUploadResponseType[]) => void;
  onSingleFileDeleteSuccess: (id: string) => void;
  media: FileUploadResponseType;
  imageRadius?: string;
  disableUpload?: boolean;
  openGradientCover?: boolean;
  isAvatar?: boolean;
  // this is an ID used to identify different uploaders on a single page
  uploadButtonId?: string;
  imgHeight?: number;
  // size in byte
  sizeLimit: number;
  campaignId?: string;
  applicationId?: string;
  mediaKitUploadResponse?: (msg: MessageResponseType) => void;
};
export function FileUploaderSingle({
  imgSize = 10,
  initImg,
  circleShape = false,
  id,
  mediaType,
  onFilesUploadSuccess,
  onSingleFileDeleteSuccess,
  media,
  imageRadius,
  disableUpload = false,
  openGradientCover = true,
  isAvatar = true,
  uploadButtonId = 'uniqueId_on_one_page',
  imgHeight,
  sizeLimit,
  campaignId = '',
  applicationId = '',
  mediaKitUploadResponse,
}: FileUploaderSingleProps) {
  const theme = useTheme();
  const intl = useIntl();
  const globalStyles = useGlobalStyles();
  const accessToken = useAuthToken();

  const [upload] = useAvatarUploadMutation();
  const [fileDelete] = useDeleteCampaignFileDeleteMutation();
  const uploadHandler: UploadHandler = async (uploadId, file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', file.name);
    formData.append('media_type', mediaType);
    let ret: UploadResponseType = null;

    if (isAvatar) {
      await MutationHandler({
        intl,
        action: async () => {
          return await upload({ formData }).unwrap();
        },
        onSuccess: (data) => {
          const dataRet = {
            file: data,
            id: '##internal##randomid',
            media_type: '##internal##random_media_type',
          };
          statusNotifier(uploadId, dataRet, 100, false);
          ret = { data: dataRet, uploadId };
        },
        onError: () => {
          statusNotifier(uploadId, null, 99, true);
        },
        onException: () => {
          statusNotifier(uploadId, null, 99, true);
        },
      });
    } else if (mediaType === 'mediakit') {
      await MutationHandler({
        intl,
        action: async () => {
          const data = await xmlHttpFileUploadMediaKit(
            applicationId,
            formData,
            (progress) => {
              statusNotifier(uploadId, null, progress.percentage, false);
            },
            accessToken || ''
          );
          return Promise.resolve(data as APIResponse<MessageResponseType>);
        },
        onSuccess: (data) => {
          const dataRet = data.msg_data?.file_upload || {
            file: '',
            id: 'randomid###',
            media_type: mediaType,
          };
          statusNotifier(uploadId, dataRet, 100, false);
          ret = { data: dataRet, uploadId };
          mediaKitUploadResponse?.(data);
        },
        onError: () => {
          statusNotifier(uploadId, null, 99, true);
        },
        onException: () => {
          statusNotifier(uploadId, null, 99, true);
        },
      });
    } else {
      await MutationHandler({
        intl,
        action: async () => {
          const data = await xmlHttpFileUploadCampaignCover(
            formData,
            (progress) => {
              statusNotifier(uploadId, null, progress.percentage, false);
            },
            accessToken || ''
          );
          return Promise.resolve({
            status: data.status,
            data: data.data,
          } as APIResponse<FileUploadResponseType>);
        },
        onSuccess: (data) => {
          const dataRet = {
            file: data.file,
            id: data.id,
            media_type: data.media_type,
          };
          statusNotifier(uploadId, dataRet, 100, false);
          ret = { data: dataRet, uploadId };
        },
        onError: () => {
          statusNotifier(uploadId, null, 99, true);
        },
        onException: () => {
          statusNotifier(uploadId, null, 99, true);
        },
      });
    }
    return ret;
  };

  const defaultState = {
    id: '',
    uploadedId: '',
    file: null,
    previewImage: initImg,
    progress: 0,
    success: false,
    error: false,
  };
  const [uploadState, setUploadState] =
    useState<FileUploaderStateType>(defaultState);
  useEffect(() => {
    setUploadState(defaultState);
  }, [initImg]);

  useEffect(() => {
    setUploadState({
      id: '',
      uploadedId: media.id,
      file: uploadState.file,
      previewImage: media.file,
      progress: 100,
      success: true,
      error: false,
    });
  }, [media]);

  const triggerUpload = (upload: FileUploaderStateType) => {
    // TODO multiple file upload error handling during promise.then is no strong
    upload.file &&
      uploadHandler(upload.id, upload.file).then((res) => {
        if (res?.data?.id) {
          const data = res?.data as FileUploadResponseType;
          onFilesUploadSuccess([data]);
          upload.uploadedId = data.id;
          upload.previewImage = data.file;
          upload.name = data.name || '';
          upload.content_type = data.content_type || '';
          setUploadState({ ...upload });
        } else {
          statusNotifier(upload.id, null, 99, true);
        }
      });
  };

  const uploadFile = (file: File) => {
    if (sizeLimit !== undefined && file.size > sizeLimit) {
      window.alert(
        `${intl.formatMessage(Messages.fileSizeOverLimit)} (${Math.floor(
          file.size / 1024 / 1024
        )}>=${sizeLimit / 1024 / 1024}M)`
      );
      return;
    }
    const uploadId = Math.random().toString(16).slice(2);

    uploadState.file = file;
    uploadState.previewImage = URL.createObjectURL(file);
    uploadState.id = uploadId;
    uploadState.uploadedId = '';
    uploadState.progress = 1;
    uploadState.success = false;
    uploadState.error = false;

    triggerUpload(uploadState);
  };

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) return;

    const file = event.target.files[0];
    uploadFile(file);
  };

  const dropHandler = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    let file = null;
    const items = e.dataTransfer.items;
    if (items) {
      if (items.length > 0 && items[0].kind === 'file') {
        file = items[0].getAsFile();
      }
    } else {
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        file = files[0];
      }
    }
    if (file) {
      uploadFile(file);
    }
  };

  // usecallback to sync with uploadState;
  const statusNotifier = (
    uploadId: string,
    data: FileUploadResponseType | null,
    progress: number,
    error: boolean
  ) => {
    uploadState.progress = progress;
    uploadState.error = error;
    setUploadState(() => ({ ...uploadState }));
  };

  // if upload bigfile error, allow user to delte file.
  const handleRemoveFile = (item: FileUploaderStateType) => {
    if (item.uploadedId) {
      MutationHandler({
        intl,
        action: async () => {
          return await fileDelete({
            media_file_ids: [item.uploadedId],
          }).unwrap();
        },
        onSuccess: () => {
          onSingleFileDeleteSuccess(item.uploadedId);
        },
      });
    }
    setUploadState({ ...defaultState });
  };

  const handleReupload = (uploadId: string) => {
    uploadState.progress = 1;
    uploadState.error = false;
    setUploadState({ ...uploadState });
    triggerUpload(uploadState);
  };

  const uploadInProgress = (progress: number) => progress > 0 && progress < 100;

  const size = {
    width: `${imgSize}rem`,
    height: `${imgHeight || imgSize}rem`,
  };
  const borderRadius = {
    borderRadius: circleShape ? '50%' : `${imageRadius || '0'}`,
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const selectFileForButton = () => {
    inputRef?.current?.click();
  };

  const [className, setClassName] = useState('drag-drop-upload-normal');
  const highlightDropBox = () => {
    setClassName('drag-drop-upload-focus');
  };
  const deHighlightDropBox = () => {
    setClassName('drag-drop-upload-normal');
  };

  const smallSize = imgSize < 7;

  const [status, setStatus] = useState<LoadingType>('init');
  const processHandler = (type: LoadingType) => setStatus(type);
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        minHeight: '156px',
        border: `1px dashed ${theme.palette.neutral[700]}`,
        position: 'relative',
        padding: '16px',
      })}
      className="boxcenterhv"
    >
      {!disableUpload &&
        uploadState?.previewImage &&
        !uploadInProgress(uploadState.progress) && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              mt: smallSize ? '0.25rem' : '1rem',
              zIndex: 3,
            }}
          >
            <IconButton
              sx={{
                mx: smallSize ? 0.5 : 2,
                bgcolor: `${theme.palette.base.white} !important`,
              }}
              onClick={(e) => {
                selectFileForButton();
              }}
              className={`cursorHand ${
                smallSize ? 'imgIcon24px' : 'imgIcon40px'
              }`}
            >
              <PencilIcon
                sx={smallSize ? { width: '1rem', height: '1rem' } : {}}
              />
            </IconButton>
          </Box>
        )}
      <label
        htmlFor={
          !disableUpload
            ? `btn-upload${uploadButtonId}`
            : '/*upload from button click*/'
        }
        onDragOver={(e) => {
          e.preventDefault();
          highlightDropBox();
        }}
        onDrop={(e) => {
          deHighlightDropBox();
          dropHandler(e);
        }}
        onDragLeave={() => deHighlightDropBox()}
      >
        <input
          ref={inputRef}
          id={`btn-upload${uploadButtonId}`}
          name={`btn-upload${uploadButtonId}`}
          style={{ display: 'none' }}
          type="file"
          accept={
            ['mediakit'].includes(mediaType)
              ? 'image/*,application/pdf,.zip,.rar'
              : 'image/*'
          }
          onClick={(e) => {
            if (uploadInProgress(uploadState.progress) || uploadState.error) {
              e.preventDefault();
            } else {
              e.currentTarget.value = '';
            }
          }}
          onChange={selectFile}
        />

        {!uploadState.error &&
        !uploadInProgress(uploadState.progress) &&
        uploadState?.previewImage ? (
          (uploadState.file?.type || uploadState.content_type)?.startsWith(
            'image'
          ) ||
          (!uploadState.file?.type && !uploadState.content_type) ? (
            <img
              src={uploadState?.previewImage}
              alt={mediaType || ''}
              loading="lazy"
              style={{
                ...size,
                ...borderRadius,
                objectFit: isAvatar
                  ? 'contain'
                  : mediaType === 'logo'
                  ? 'contain'
                  : 'contain',
              }}
              className={`cursorHand ${className}`}
            />
          ) : (
            <Link
              href={'#'}
              onClick={(e) => {
                e.preventDefault();
                status !== 'downloading' &&
                  downloadFile(
                    uploadState.previewImage,
                    uploadState.file?.name || uploadState.name || 'File',
                    processHandler
                  );
              }}
            >
              {uploadState.file?.name || uploadState.name || 'File'}
            </Link>
          )
        ) : (
          !uploadState.error &&
          !uploadInProgress(uploadState.progress) && (
            <BenaFileUploadButton
              width={size.width}
              height={size.height}
              className={className}
            />
          )
        )}

        {openGradientCover && (uploadState?.previewImage || media.file) && (
          <Box
            sx={{
              ...size,
              ...borderRadius,
              flexDirection: 'column',
              position: 'absolute',
              left: 0,
              top: 0,
            }}
            className={`gradientCover ${className}`}
          ></Box>
        )}
        {(uploadState.error || uploadInProgress(uploadState.progress)) && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              bgcolor: theme.palette.text.disabled,
              ...borderRadius,
            }}
            className={`boxcenterhv  ${className}`}
          >
            {!uploadState.error && uploadInProgress(uploadState.progress) && (
              <CircularProgressWithLabel value={uploadState.progress} />
            )}
            {uploadState.error && (
              <>
                <IconButton
                  title={intl.formatMessage(Messages.clickReUpload)}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleReupload(uploadState.id);
                  }}
                  className={globalStyles.iconButtonTransparent}
                >
                  <RefreshIcon fontSize="large" htmlColor="white" />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleRemoveFile(uploadState);
                  }}
                  className={globalStyles.iconButtonTransparent}
                >
                  <HighlightOffIcon fontSize="large" htmlColor="white" />
                </IconButton>
              </>
            )}
          </Box>
        )}
      </label>
    </Box>
  );
}

/**
 * used for Creator's Campaign milestone work upload
 * this component allows to upload different types of files
 * and display files in different file format with icon
 * it uses a different UI from MultipleFileUploader
 */
export function CreatorJobContractUploadWorks({
  jobContractId,
  onFilesUploadSuccess,
  onSingleFileDeleteSuccess,
  medias,
  triggerUpload,
  isJobContractCompleted,
}: FileUploaderProps & {
  jobContractId: string;
  triggerUpload: number;
  isJobContractCompleted?: boolean;
}) {
  const intl = useIntl();
  const theme = useTheme();
  const urlThumbnailStyle = useUrlThumbnailStyles();
  const globalStyles = useGlobalStyles();
  const accessToken = useAuthToken() || '';
  const [fileDelete] = useJobContractAttachmentFileDeleteMutation();
  const uploadHandler: UploadHandler = async (uploadId, file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    let ret: UploadResponseType = null;
    await MutationHandler({
      intl,
      action: async () => {
        return await xmlHttpJobContractFileUpload(
          jobContractId,
          formData,
          (progress) => {
            statusNotifier(uploadId, null, progress.percentage, false);
          },
          accessToken || ''
        );
      },
      onSuccess: (data) => {
        statusNotifier(uploadId, data, 100, false);
        ret = { data, uploadId };
      },
      onError: () => {
        statusNotifier(uploadId, null, 99, true);
      },
      onException: () => {
        statusNotifier(uploadId, null, 99, true);
      },
    });
    return ret;
  };

  const [uploadState, setUploadState] = useState<UploadSateType>({});

  useEffect(() => {
    const obj = {} as UploadSateType;
    medias.forEach(
      (item) =>
        (obj[item.id] = {
          id: '',
          uploadedId: item.id,
          file: null,
          previewImage: item.file,
          progress: 100,
          success: true,
          error: false,
          name: item.name,
        })
    );
    setUploadState(obj);
  }, [medias]);

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) return;

    const files = event.target.files;
    const len = files.length;
    for (let i = 0; i < len; i++) {
      // random id to avoid index reference
      const uploadId = Math.random().toString(16).slice(2);
      uploadState[uploadId] = {
        id: uploadId,
        uploadedId: '',
        file: files[i],
        previewImage: URL.createObjectURL(files[i]),
        progress: 1,
        success: false,
        error: false,
        size: files[i].size / 1024,
      };
    }
    setUploadState({ ...uploadState });

    new Promise(() => {
      const keys = Object.keys(uploadState);
      const promiseArr: Promise<UploadResponseType>[] = [];
      for (let i = 0; i < keys.length; i++) {
        const item = uploadState[keys[i]];
        // progress 1 is the initial state to trigger upload
        if (item.progress === 1) {
          if (item.file) {
            promiseArr.push(uploadHandler(item.id, item.file));
          }
          // update the progress immediately to avoid upload again
          item.progress = 2;
          setUploadState({ ...uploadState });
        }
      }
      Promise.all(promiseArr).then((resArr) => {
        onFilesUploadSuccess(
          resArr
            .filter((item) => item !== null)
            .map((item) => item?.data) as FileUploadResponseType[]
        );
        const newState = { ...uploadState };
        resArr.forEach((item) => {
          if (item) {
            newState[item.uploadId].uploadedId = item.data.id;
            newState[item.uploadId].previewImage = item.data.file;
            newState[item.uploadId].name = item.data.name;
            newState[item.uploadId].content_type = item.data.content_type;
            newState[item.uploadId].size = item.data.size;
          }
        });
        setUploadState(newState);
      });
    });
  };

  // usecallback to sync with uploadState;
  const statusNotifier = (
    uploadId: string,
    data: FileUploadResponseType | null,
    progress: number,
    error: boolean
  ) => {
    uploadState[uploadId].progress = progress;
    uploadState[uploadId].error = error;
    setUploadState(() => ({ ...uploadState }));
  };

  const handleRemoveFile = (item: FileUploaderStateType) => {
    if (item.uploadedId) {
      MutationHandler({
        intl,
        action: async () => {
          return await fileDelete({
            file_id: item.uploadedId,
            jobcontract_id: jobContractId,
          }).unwrap();
        },
        onSuccess: () => {
          onSingleFileDeleteSuccess(item.uploadedId);
        },
      });
    }
    const obj = { ...uploadState };
    delete obj[item.id];
    setUploadState(obj);
  };

  const handleReupload = async (uploadId: string) => {
    const item = uploadState[uploadId];
    item.progress = 1;
    item.error = false;
    setUploadState({ ...uploadState });
    if (item.file) {
      const res = await uploadHandler(item.id, item.file);
      if (res?.data) {
        onFilesUploadSuccess([res.data]);
        item.uploadedId = res.data.id;
        item.previewImage = res.data.file;
        item.name = res.data.name;
        item.content_type = res.data.content_type;
        item.size = res.data.size;
        setUploadState({ ...uploadState });
      }
    }
  };

  const uploadInProgress = (progress: number) => progress > 0 && progress < 100;

  const mapArray = (arr: FileUploaderStateType[]) =>
    arr.map((item, index) => (
      <Box
        key={index}
        sx={{ position: 'relative', width: '100%', mb: 1 }}
        className={`boxcenterhv ${globalStyles.border2pxRadius8pxPadding8px}`}
      >
        <Box
          className="boxcenterhv"
          sx={{
            width: '2.5rem',
            height: '2.5rem',
            bgcolor: theme.custom.primary4,
            flexShrink: 0,
          }}
        >
          <FileIcon fill={theme.custom.primary2} />
        </Box>
        <Stack flexGrow={1} marginLeft="16px">
          <Stack direction="row">
            <Stack flexGrow={1}>
              <Link
                href={item.previewImage || ''}
                target="_blank"
                onClick={(e) => {
                  e.preventDefault();
                  downloadFile(item.previewImage, item.name);
                }}
                title={item.file?.name || item.name || ''}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  {abbreviateLink(item.file?.name || item.name || '')}
                </Typography>
              </Link>
              <Typography
                fontSize={'14px'}
                fontWeight="400"
                color="grey.50"
                lineHeight="16px"
              >
                {Math.ceil(item.size || 0)}KB
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box>
              {!isJobContractCompleted &&
                !item.error &&
                !uploadInProgress(item.progress) && (
                  <IconButton
                    onClick={() => {
                      handleRemoveFile(item);
                    }}
                  >
                    <TrashIcon sx={{ width: '1rem', height: '1rem' }} />
                  </IconButton>
                )}
              {(item.error || uploadInProgress(item.progress)) && (
                <>
                  {item.error && (
                    <>
                      <IconButton
                        title={intl.formatMessage(Messages.clickReUpload)}
                        onClick={() => handleReupload(item.id)}
                        className={globalStyles.iconButtonTransparent}
                      >
                        <RefreshIcon fontSize="medium" htmlColor="black" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          handleRemoveFile(item);
                        }}
                        sx={{ mr: 2 }}
                        className={globalStyles.iconButtonTransparent}
                      >
                        <CrossLineIcon
                          stroke={theme.custom.black}
                          sx={{ width: '1rem', height: '1rem' }}
                        />
                      </IconButton>
                    </>
                  )}
                </>
              )}
            </Box>
          </Stack>
          <BorderLinearProgressWithLabel value={item.progress} />
        </Stack>
      </Box>
    ));

  // simulate upload click via a random prop value from parent component
  const refUplaodButton = useRef<HTMLLabelElement>(null);
  useEffect(() => {
    if (triggerUpload !== 0) refUplaodButton.current?.click();
  }, [triggerUpload]);

  const sizeUploadBox = {
    width: '22rem !important',
    height: '16.625rem !important',
  };

  return (
    <Stack direction="column" className="boxlefthcenterv" spacing="0.5rem">
      {!isJobContractCompleted && (
        <Box
          className={urlThumbnailStyle.uploadBoxWithouSize}
          sx={{
            width: { xs: 'calc(100vw - 48px)', md: sizeUploadBox.width },
            height: sizeUploadBox.height,
          }}
        >
          <label htmlFor="btn-upload-multifiles" ref={refUplaodButton}>
            <input
              multiple
              id="btn-upload-multifiles"
              name="btn-upload-multifiles"
              style={{ display: 'none' }}
              type="file"
              accept="*"
              onChange={selectFile}
              onClick={(e) => {
                e.currentTarget.value = '';
              }}
            />
            <BenaFileUploadButton
              width={sizeUploadBox.width}
              height={sizeUploadBox.height}
            />
          </label>
        </Box>
      )}
      {!isJobContractCompleted && <Box sx={{ mb: '2rem' }}></Box>}
      {mapArray(Object.keys(uploadState).map((key) => uploadState[key]))}
    </Stack>
  );
}

/**
 * used for replace the attachment file
 */
export function CreatorJobContractPatchFile({
  jobContractId,
  fileId,
  onFilesUploadSuccess,
  triggerUpload,
  triggerReload,
  onProgress,
}: {
  jobContractId: string;
  fileId: string;
  triggerUpload: number;
  triggerReload: number;
  onProgress: fnNumberToVoid;
  onFilesUploadSuccess: (resArr: FileUploadResponseType[]) => void;
}) {
  const intl = useIntl();
  const accessToken = useAuthToken() || '';
  const [uploadFile, setUploadFile] = useState<{
    id: string;
    file: File | null;
  }>({ id: '', file: null });
  const uploadHandler: UploadHandler = async (uploadId, file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    setUploadFile({ id: uploadId, file });
    let ret: UploadResponseType = null;
    await MutationHandler({
      intl,
      action: async () => {
        return await xmlHttpJobContractFilePatch(
          jobContractId,
          fileId,
          formData,
          (progress) => {
            onProgress(progress.percentage);
          },
          accessToken || ''
        );
      },
      onSuccess: (data) => {
        onProgress(100);
        ret = { data, uploadId };
      },
      onError: () => {
        onProgress(99);
      },
      onException: () => {
        onProgress(99);
      },
    });
    return ret;
  };

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) return;

    const file = event.target.files[0];
    const uploadId = Math.random().toString(16).slice(2);
    uploadHandler(uploadId, file).then((res) => {
      res?.data && onFilesUploadSuccess([res.data]);
    });
  };

  const handleReupload = async () => {
    if (uploadFile.file) {
      const res = await uploadHandler(uploadFile.id, uploadFile.file);
      if (res?.data) {
        onFilesUploadSuccess([res.data]);
      }
    }
  };

  // simulate upload click via a random prop value from parent component
  const refUplaodButton = useRef<HTMLLabelElement>(null);
  useEffect(() => {
    if (triggerUpload !== 0) refUplaodButton.current?.click();
  }, [triggerUpload]);

  useEffect(() => {
    if (triggerReload !== 0) handleReupload();
  }, [triggerReload]);

  return (
    <Stack display="none">
      <label htmlFor={fileId} ref={refUplaodButton}>
        <input
          multiple
          id={fileId}
          name={fileId}
          style={{ display: 'none' }}
          type="file"
          accept="*"
          onChange={selectFile}
          onClick={(e) => {
            e.currentTarget.value = '';
          }}
        />
      </label>
    </Stack>
  );
}
