import {
  Box,
  Link,
  Stack,
  Typography,
  useTheme,
  Theme,
  Button,
  IconButton,
  OutlinedInput,
} from '@mui/material';
import ImageFilter from '../../components/ImageFilter';
import { staticURLMessaging, staticURLTiktok } from '../../route/urls';
import SortbySelect from '../../components/inputs/SortbySelect';
import { FormattedNumber, useIntl } from 'react-intl';
import {
  usePostDisplayDurationOptions,
  useShowOnlyOptions,
} from '../../config/options';
import { BenaModal } from '../../components/BenaModal';
import { createStyles, makeStyles } from '@mui/styles';
import { BenaSocialStatCard } from '../../components/BenaSocialStatCard';
import {
  UrlThumbnail,
  UrlThumbnailCompact,
} from '../../components/UrlThumbnail';
import { useGlobalStyles } from '../../assets/styles/style';
import { useEffect, useState } from 'react';
// import { ChangeCampaignMilestoneStatus } from '../CampaignProgress';
import { getPlatformSvgIcon } from '../../utils/getPlatformSvgIcon';
import { showMessage } from '../../components/ShowMessage';
import {
  CopyIcon,
  ChatThinIcon,
  DislikeIcon,
  MessageIcon,
  PencilIcon,
  QuestionMarkIcon,
  TiktokGreyIcon,
  HappyFaceIcon,
  User2Icon,
  File2Icon,
  WalletIcon,
} from '../../assets/imgs/icons';
import LightTooltip from '../../components/LightTooltip';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { Messages } from '../../localization/Messages';
import { LinkItUrl } from 'react-linkify-it';
import {
  usePatchCampaignMilestoneMutation,
  usePostMessageCreatorMutation,
} from '../../redux/benaApi';

type CreatorBasicInfoProps = {
  creator: CampaignApplicationCreatorDataType;
  userId: string;
  showSocialLinks?: boolean;
  handleClick?: fnVoidToVoid;
  showViewIcon?: boolean;
  showIMIcon?: boolean;
};
export function CreatorBasicInfo({
  creator,
  userId,
  showSocialLinks,
  handleClick,
  showViewIcon = false,
  showIMIcon = false,
}: CreatorBasicInfoProps) {
  const styles = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const iconSize = { width: '12px', height: '12px' };
  return (
    <Box className="boxlefthcenterv">
      <Link
        href="#"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleClick && handleClick();
        }}
        sx={{
          position: 'relative',
          cursor: handleClick ? 'pointer' : 'default',
        }}
      >
        <ImageFilter
          title={handleClick ? 'show creator milestones' : ''}
          src={creator?.picture || ''}
          alt=""
          className="imgIcon68px imgObjectFit"
          style={{
            flexShrink: 0,
            borderRadius: '4px',
          }}
          classWidthHeight="imgIcon68px"
        />
        {showViewIcon && (
          <Box className={styles.viewIcon}>
            <PencilIcon className={styles.copyIconSize} />
          </Box>
        )}
        {creator.geo && (
          <Box className={styles.countryIcon} title={creator.geo}>
            {/* creator.geo is in long format, not country code
            <img
              loading="lazy"
              style={{
                width: '1.25rem',
                height: '0.875rem',
                borderRadius: '0.125rem',
              }}
              src={`https://flagcdn.com/w20/${creator.location.toLowerCase()}.png 2x`}
              srcSet={`https://flagcdn.com/w40/${creator.location.toLowerCase()}.png`}
              alt=""
            /> */}
          </Box>
        )}
      </Link>
      <Stack direction={'column'} sx={{ ml: 2 }}>
        <Stack>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              mr: 1,
            }}
            color="grey.700"
          >
            <Link
              href={`${staticURLTiktok}@${(creator?.unique_id || '').replace(
                '@',
                ''
              )}`}
              target="_blank"
            >
              <Typography color="grey.700" component="span">
                @{creator?.unique_id}
              </Typography>
            </Link>
            {'  '}
            {showSocialLinks && <SocialLinks creator={creator} />}
          </Typography>
          <Typography
            fontSize={'12px'}
            fontWeight={500}
            color="grey.700"
            lineHeight={'14px'}
            maxWidth="80px"
            className="ellipsis"
            title={creator?.name || ''}
          >
            {creator?.name || ''}
          </Typography>
        </Stack>
        <Stack direction="row" columnGap={'6px'} alignItems={'center'}>
          <TiktokGreyIcon
            sx={{ width: '9px', height: '9px' }}
            fill={theme.palette.grey[50]}
          />
          <User2Icon stroke={theme.palette.grey[50]} sx={iconSize} />
          <Typography fontSize="13px" color="grey.400">
            {intl.formatNumber(creator.profile.followers, {
              notation: 'compact',
            })}
          </Typography>
          <HappyFaceIcon stroke={theme.palette.grey[50]} sx={iconSize} />
          <Typography fontSize="13px" color="grey.400">
            {intl.formatNumber(creator.profile.engagement_rate, {
              style: 'percent',
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Stack>
        {showIMIcon &&
          (userId ? (
            <Link
              href={`${staticURLMessaging}?id=${userId}`}
              target="_blank"
              title={intl.formatMessage(Messages.Messages)}
            >
              <Typography fontSize="14px" color="primary.main" fontWeight={600}>
                {intl.formatMessage(Messages.DirectMessage)}
              </Typography>
            </Link>
          ) : (
            <Typography fontSize="14px" color="grey.50" fontWeight={400}>
              {intl.formatMessage(Messages.PendingCreatorRespond)}
            </Typography>
          ))}
      </Stack>
    </Box>
  );
}

type HiredCreatorBasicInfoProps = {
  creator: CampaignApplicationCreatorDataType;
  userId: string;
  showSocialLinks?: boolean;
  handleClick?: fnVoidToVoid;
  showViewIcon?: boolean;
  showIMIcon?: boolean;
};
export function HiredCreatorBasicInfo({
  creator,
  userId,
  showSocialLinks,
  handleClick,
  showViewIcon = false,
  showIMIcon = false,
}: HiredCreatorBasicInfoProps) {
  const intl = useIntl();
  return (
    <Box className="boxlefthcenterv">
      <Stack direction={'column'} rowGap="6px">
        <Box className="boxlefthcenterv">
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              mr: 1,
            }}
            color="grey.700"
          >
            @{creator?.unique_id}
            {'  '}
            {showSocialLinks && <SocialLinks creator={creator} />}
          </Typography>
        </Box>
        <Stack
          direction="row"
          columnGap={'6px'}
          alignItems={'center'}
          marginBottom="8px"
        >
          <Link
            href={`${staticURLMessaging}?id=${userId}`}
            target="_blank"
            title={intl.formatMessage(Messages.DirectMessage)}
          >
            <ChatThinIcon sx={{ width: '9px', height: '9px' }} />
          </Link>
          <Link
            href={`${staticURLMessaging}?id=${userId}`}
            target="_blank"
            title={intl.formatMessage(Messages.ReadProposal)}
          >
            <File2Icon sx={{ width: '10px', height: '10px' }} />
          </Link>
          <Link
            href={`${staticURLMessaging}?id=${userId}`}
            target="_blank"
            title={intl.formatMessage(Messages.PaymentInformation)}
          >
            <WalletIcon sx={{ width: '12px', height: '10px' }} />
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}

// TODO update the data from backend
type SocialLinksProps = {
  creator: CampaignApplicationCreatorDataType;
  imgSize?: string;
};
export function SocialLinks({ creator, imgSize }: SocialLinksProps) {
  return (
    <>
      {/* {(creator?.platforms || [])
        .filter((platform) => platform.profile_link)
        .map((platform) => {
          return (
            <Link
              href={platform.profile_link}
              target="_blank"
              sx={{ mr: 1 }}
              key={platform.id}
            >
              <img
                className={imgSize || 'imgIcon16px'}
                src={getPlatformIcon(platform.id)}
                alt={''}
              />
            </Link>
          );
        })} */}
    </>
  );
}

type ShowOnlyPanelProps = {
  total: number;
  count: number;
  onSortChange: fnNumberToVoid;
  padding?: string;
  optionPostfix?: string;
};
export function ShowOnlyPanel({
  total,
  count,
  onSortChange,
  padding = '2rem 0rem 1rem',
  optionPostfix,
}: ShowOnlyPanelProps) {
  const intl = useIntl();
  const theme = useTheme();
  const options = useShowOnlyOptions();
  return (
    <Box
      className="boxlefthcenterv"
      sx={{ justifyContent: 'space-between', ...(padding && { p: padding }) }}
    >
      <Typography
        sx={{
          fontSize: '0.875rem',
          fontWeight: 600,
          color: theme.custom.gray,
          ml: '0.25rem',
        }}
      >
        {intl.formatMessage(Messages.NumberOfResults)}
      </Typography>
      <Typography
        fontWeight={600}
        sx={{ color: theme.custom.black, ml: 1, fontSize: '0.875rem' }}
      >
        <FormattedNumber value={total} notation="compact" />
      </Typography>
      <Box sx={{ flexGrow: 1 }}></Box>
      <SortbySelect
        label={intl.formatMessage(Messages.showOnly)}
        data={{
          options: options.map((v) => ({
            name: `${v}${optionPostfix ? ' ' + optionPostfix : ''}`,
            value: `${v}`,
          })),
          selected: options.findIndex((v) => v === count),
        }}
        onMenuItemChange={(name, index) => {
          onSortChange(options[index]);
        }}
        deletable={false}
      />
    </Box>
  );
}

export const combineSortToQueryString = (
  order: string,
  orderBy: string,
  query: string
) => {
  let queryObj = JSON.parse(query || '{}');
  queryObj = {
    ...queryObj,
    ...(orderBy ? { sortType: orderBy, sortOrder: order } : {}),
  };
  return JSON.stringify(queryObj);
};

type MilestonePostsProps = {
  milestone: CampaignMilestoneType;
  milestones: CampaignMilestoneType[];
};
export function MilestonePosts({ milestone, milestones }: MilestonePostsProps) {
  const intl = useIntl();
  const styles = useStyles();
  const theme = useTheme();
  let milestone_posts: CampaignMilestonePostType[] = [];
  milestones.forEach((milestone) => {
    milestone_posts = milestone_posts.concat(milestone.posts);
  });

  return (
    <Stack spacing="0.5rem">
      <Typography className={styles.caption}>
        {intl.formatMessage(Messages.contentPostLinks)} (
        {milestone_posts.length})
      </Typography>
      {milestone_posts.map((post, index) => {
        const svg = getPlatformSvgIcon(
          post.social_media_platform as SocialPlatformId
        );
        const { views, likes, shares, comments } = post.statistics || {};
        return (
          <Stack direction="column" key={index}>
            <Box className={styles.postbox}>
              <Box className="boxlefthcenterv" sx={{ gap: '0.5rem' }}>
                {svg &&
                  svg({
                    fill: theme.custom.black,
                    mask: theme.palette.base.white,
                    sx: { width: '0.875rem', height: '0.875rem' },
                  })}
                <LinkItUrl>{post.post_url}</LinkItUrl>
              </Box>
              {post.statistics && (
                <BenaSocialStatCard
                  views={views || 0}
                  likes={likes || 0}
                  comments={comments || 0}
                  shares={shares || 0}
                />
              )}
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
}

type LikeDislikeButtonsGroupProps = {
  like: boolean;
  dislike: boolean;
  likeClickHandler: fnVoidToVoid;
  dislikeClickHandler: fnVoidToVoid;
};
export function LikeDislikeButtonsGroup({
  like,
  dislike,
  likeClickHandler,
  dislikeClickHandler,
}: LikeDislikeButtonsGroupProps) {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <Stack direction={'row'} gap={'0rem 1rem'} sx={{ padding: '1rem 0rem' }}>
      <IconButton
        className={`hand boxcenterhv ${styles.highlightBox}`}
        onClick={likeClickHandler}
      >
        <DislikeIcon
          stroke={theme.palette.primary.main}
          fill={like ? theme.palette.primary.main : 'transparent'}
          className={`rotate180 ${styles.iconSize}`}
        />
      </IconButton>
      <IconButton
        className={`hand boxcenterhv ${styles.highlightBox}`}
        onClick={dislikeClickHandler}
      >
        <DislikeIcon
          className={`${styles.iconSize}`}
          fill={dislike ? theme.custom.lightRed : 'transparent'}
        />
      </IconButton>
    </Stack>
  );
}

type MilestoneAttachmentsrops = {
  milestone: CampaignMilestoneType;
  showApproval?: boolean;
  notifyRefresh?: fnBooleanToVoid;
  creatorId?: string;
};
export function MilestoneAttachments({
  milestone,
  showApproval,
  notifyRefresh,
  creatorId,
}: MilestoneAttachmentsrops) {
  const theme = useTheme();
  const intl = useIntl();
  const styles = useStyles();

  const [fileApprovalMap, setFileApprovalMap] = useState<FileApprovalType>({});

  const [patchMilestone] = usePatchCampaignMilestoneMutation({});
  const handleMilestoneApproval = async (milestoneId: string) => {
    if (Object.keys(fileApprovalMap).length <= 0) return;

    return !(await MutationHandler({
      intl,
      action: async () =>
        await patchMilestone({
          milestone_id: milestoneId,
          body: {
            file_approval: fileApprovalMap,
          },
        }).unwrap(),
    }));
  };

  useEffect(() => {
    handleMilestoneApproval(milestone.id);
    // every time patch the milestone, notify parent component for the potential refresh
    notifyRefresh?.(true);
  }, [fileApprovalMap, milestone]);

  const fileApprovalMapKeys = Object.keys(fileApprovalMap);
  const hasDislike =
    fileApprovalMapKeys.length > 0 &&
    fileApprovalMapKeys.some((v) => !fileApprovalMap[v]);
  const allLiked =
    fileApprovalMapKeys.length === milestone.files.length &&
    fileApprovalMapKeys.every((v) => !!fileApprovalMap[v]);
  const showMessgeInput = hasDislike || allLiked;

  const approvalButtons = (fileId: string, like?: boolean) => {
    return (
      <LikeDislikeButtonsGroup
        like={like === undefined ? false : like}
        dislike={like === undefined ? false : !like}
        likeClickHandler={() => {
          setFileApprovalMap({
            ...milestone.file_approval,
            ...fileApprovalMap,
            [fileId]: true,
          });
          return;
        }}
        dislikeClickHandler={() => {
          setFileApprovalMap({
            ...milestone.file_approval,
            ...fileApprovalMap,
            [fileId]: false,
          });
          return;
        }}
      />
    );
  };

  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [messageCreator] = usePostMessageCreatorMutation({});
  const handleSendMessage = async () => {
    if (!creatorId) return;

    setSubmitting(true);
    const ret = await MutationHandler({
      intl,
      action: async () =>
        await messageCreator({
          creator_id: creatorId,
          msg: message,
        }).unwrap(),
      onSuccess: () => {
        showMessage.success(
          intl.formatMessage(Messages.messageHasBeenSentSuccessfully)
        );
      },
    });
    setSubmitting(false);
    return !ret;
  };

  return (
    <Stack spacing="0.5rem">
      <Typography className={styles.caption}>
        {intl.formatMessage(Messages.draftAttachments)} (
        {milestone.files.length})
      </Typography>
      <Stack direction="row" gap="1rem 1rem" flexWrap={'wrap'}>
        {milestone.files.map((file, index) => (
          <Stack alignItems={'center'} key={file.id}>
            <UrlThumbnail
              key={index}
              url={file.file}
              name={file.name}
              contentType={file.content_type}
            />
            {showApproval && approvalButtons(file.id, fileApprovalMap[file.id])}
          </Stack>
        ))}
      </Stack>
      {showApproval && showMessgeInput && creatorId && (
        <>
          <OutlinedInput
            className={'border-radius-6px'}
            placeholder={
              hasDislike
                ? intl.formatMessage(
                    Messages.milestoneAttachmentRequireChangeMessageHint
                  )
                : allLiked
                ? intl.formatMessage(
                    Messages.milestoneAttachmentSuggestPostDateMessageHint
                  )
                : ''
            }
            multiline={true}
            rows={2}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMessage(event.target.value);
            }}
            value={message}
            endAdornment={
              <IconButton
                sx={{ ml: 4 }}
                onClick={handleSendMessage}
                disabled={submitting || message === ''}
              >
                <MessageIcon
                  {...(message === ''
                    ? { stroke: theme.palette.text.disabled }
                    : {})}
                />
              </IconButton>
            }
          />
        </>
      )}
    </Stack>
  );
}
export function MilestoneAttachmentsCompact({
  milestone,
}: MilestoneAttachmentsrops) {
  const intl = useIntl();
  const styles = useStyles();
  return (
    <Stack spacing="0.5rem">
      <Typography className={styles.caption}>
        {intl.formatMessage(Messages.draftAttachments)} (
        {milestone.files.length})
      </Typography>
      <Stack direction="row" gap="1rem 1rem" flexWrap={'wrap'}>
        {milestone.files.map((file, index) => (
          <UrlThumbnailCompact url={file.file} name={file.name} key={index} />
        ))}
      </Stack>
    </Stack>
  );
}

type ShowCreatorWorkProps = {
  campaignProgress: GetCampaignJobContractsReponseType;
  handleClose: fnBooleanToVoid;
  showPosts?: boolean;
  showAttachments?: boolean;
};
export function ShowCreatorWork({
  campaignProgress,
  handleClose,
  showPosts,
  showAttachments,
}: ShowCreatorWorkProps) {
  return <></>;
  // const intl = useIntl();
  // const styles = useStyles();
  // const gc = useGlobalStyles();
  // const milestone = campaignProgress.milestones.find(
  //   (v) => v.id === campaignProgress.milestone_meta.current_milestone
  // );
  // const contractFinished = campaignProgress.status === 'finished';
  // const [notifyRefresh, setNotifyRefresh] = useState(false);
  // const content = (
  //   <Stack direction="column" spacing="0.5rem" sx={{ width: '100%' }}>
  //     {milestone && showPosts && (
  //       <MilestonePosts
  //         milestone={milestone}
  //         milestones={campaignProgress.milestones}
  //       />
  //     )}
  //     {milestone && showAttachments && (
  //       <MilestoneAttachments
  //         milestone={milestone}
  //         showApproval={!contractFinished}
  //         creatorId={campaignProgress.creator_id}
  //         notifyRefresh={setNotifyRefresh}
  //       />
  //     )}
  //   </Stack>
  // );
  // const actionButtons = (
  //   <Box className={`${styles.buttonContainer} boxcenterhv`}>
  //     {!contractFinished && (
  //       <>
  //         <Button
  //           className={`border-radius-8px ${gc.buttonCancellation} ${styles.cancel}`}
  //           onClick={() => handleClose(notifyRefresh)}
  //         >
  //           {intl.formatMessage(Messages.cancel)}
  //         </Button>
  //         <Box sx={{ flexGrow: 1 }}></Box>
  //       </>
  //     )}
  //   </Box>
  // );
  // const [showChangedMilestoneStatus, setShowChangedMilestoneStatus] =
  //   useState(false);
  // return (
  //   <>
  //     {!!showChangedMilestoneStatus && (
  //       <></>
  //       // <ChangeCampaignMilestoneStatus
  //       //   campaignId={campaignProgress.campaign_id}
  //       //   setNullData={(refresh: boolean) => {
  //       //     setShowChangedMilestoneStatus(false);
  //       //     refresh && handleClose(true);
  //       //   }}
  //       //   newStatus={'completed'}
  //       //   creatorId={campaignProgress.creator_id}
  //       //   milestoneId={campaignProgress.milestone_meta.current_milestone}
  //       // />
  //     )}
  //     <BenaModal
  //       title={intl.formatMessage(Messages.creatorsWork)}
  //       handleClose={() => handleClose(notifyRefresh)}
  //       children={content}
  //       actionButton={actionButtons}
  //       width="34.25rem"
  //     />
  //   </>
  // );
}

type ShowProposalType = {
  handleClose: fnBooleanToVoid;
  data: CampaignApplicationItemType;
};
export function ShowProposal({ handleClose, data }: ShowProposalType) {
  const {
    proposal,
    links,
    post_display_duration,
    promotion_in_bio,
    right_usage,
    bio_price,
    message_to_brand,
  } = data;
  const intl = useIntl();
  const theme = useTheme();
  const gc = useGlobalStyles();
  const styles = useStyles();
  const options = usePostDisplayDurationOptions();
  const copyToClipboard = (str: string) => {
    navigator.clipboard.writeText(str);
    showMessage.info(intl.formatMessage(Messages.copied));
  };
  const copyButton = (str: string) => (
    <IconButton
      onClick={() => {
        copyToClipboard(str);
      }}
    >
      <CopyIcon className={styles.copyIconSize} />
    </IconButton>
  );
  const tooltip = (tip: string) => (
    <LightTooltip title={tip}>
      <Box component={'span'}>
        <QuestionMarkIcon
          className={styles.copyIconSize}
          fill={theme.custom.black}
        />
      </Box>
    </LightTooltip>
  );
  const items = [
    {
      caption: intl.formatMessage(Messages.thProposal),
      value: proposal || '-',
      clipboard: proposal,
      tooltip: 'Your content proposal on how to best promote this product.',
    },
    {
      caption: intl
        .formatMessage(Messages.creatorsMessageToYou)
        .replace(':', ''),
      value: message_to_brand || '-',
      clipboard: message_to_brand,
    },
    {
      caption: intl.formatMessage(Messages.productLinks),
      value:
        links?.map((v, index) => (
          <Stack
            direction="row"
            sx={{ marginLeft: '-0.25rem' }}
            key={v + index}
          >
            <LinkItUrl>{v}</LinkItUrl>
            <Box sx={{ flexGrow: 1 }}></Box>
            {copyButton(v)}
          </Stack>
        )) || '-',
    },
    {
      caption: intl.formatMessage(Messages.postDisplayDurationQuestion),
      value: post_display_duration ? (
        <Typography className={styles.contentProposalText}>
          {post_display_duration}
        </Typography>
      ) : (
        '-'
      ),
    },
    {
      caption: intl.formatMessage(Messages.promotionInBioLabel),
      value:
        promotion_in_bio === undefined
          ? '-'
          : intl.formatMessage(promotion_in_bio ? Messages.yes : Messages.no),
    },
    {
      caption: intl.formatMessage(Messages.rightUsageLabel),
      value:
        right_usage === undefined ? (
          '-'
        ) : (
          <>
            {intl.formatMessage(right_usage ? Messages.yes : Messages.no)}{' '}
            {right_usage && bio_price ? (
              <>
                {intl.formatMessage(Messages.itWillCost)}
                <Typography
                  fontSize="16px"
                  fontWeight="600"
                  sx={{ color: theme.custom.darkGreen }}
                  component={'span'}
                >
                  {' $' + bio_price}
                </Typography>
              </>
            ) : (
              ''
            )}
          </>
        ),
      tooltip: 'Brand reuses this content for ads and repost purposes.',
    },
  ];
  const children = (
    <Stack
      gap="1rem 0rem"
      sx={{ width: '100%', maxHeight: '80vh', overflowY: 'auto' }}
    >
      {items.map((item, index) => (
        <Stack key={`${item.caption}${index}`}>
          <Stack justifyContent={'center'} direction="row">
            <Typography fontSize="14px" fontWeight="500" color="base.black">
              {item.caption} {item.tooltip && tooltip(item.tooltip)}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            {item.clipboard !== undefined &&
              !!item.clipboard &&
              copyButton(item.clipboard)}
          </Stack>
          <Box className={styles.contentProposalText}>{item.value}</Box>
        </Stack>
      ))}
    </Stack>
  );
  return (
    <BenaModal
      title={intl.formatMessage(Messages.thProposal)}
      handleClose={() => {
        handleClose(false);
      }}
      children={children}
      actionButton={
        <Box sx={{ p: 2 }}>
          <Button
            className={`border-radius-8px ${gc.buttonOutlinedGrey}`}
            sx={{ width: '8.375rem' }}
            onClick={() => handleClose(false)}
          >
            {intl.formatMessage(Messages.close)}
          </Button>
        </Box>
      }
      width={'36.25rem'}
    />
  );
}

// type ChangeCampaignMilestoneStatusProps = {
//   milestoneId: string;
//   creatorId: string;
//   campaignId: string;
//   setNullData: fnBooleanToVoid;
//   showMainWindowScrollbar?: boolean;
//   newStatus: CampaignMilestoneStatusType;
// };

// export function ChangeCampaignMilestoneStatus({
//   milestoneId,
//   creatorId,
//   campaignId,
//   setNullData,
//   showMainWindowScrollbar = true,
//   newStatus,
// }: ChangeCampaignMilestoneStatusProps) {
//   const theme = useTheme();
//   const intl = useIntl();
//   const gc = useGlobalStyles();
//   useEffect(() => {
//     if (newStatus) hideHtmlBodyScrollbar();
//   });

//   const handleClose = (refresh = false) => {
//     setNullData(refresh);
//     showMainWindowScrollbar && showHtmlBodyScrollbar();
//   };

//   const [submitting, setSubmitting] = useState(false);

//   const initTitle =
//     newStatus === 'completed'
//       ? intl.formatMessage(Messages.markMilestoneAsComplete)
//       : intl.formatMessage(Messages.changeMilestoneStatus);
//   const [editCampaignMilestone] = usePatchCampaignMilestoneMutation();

//   const handleChangeCampaignMilestoneStatus = async () => {
//     if (['completed', 'closed'].includes(newStatus)) {
//       setSubmitting(true);
//       const ret = await MutationHandler({
//         intl,
//         action: async () =>
//           await editCampaignMilestone({
//             milestone_id: milestoneId,
//             body: {
//               creator_id: creatorId,
//               campaign_id: campaignId,
//               status: newStatus,
//             },
//           }).unwrap(),
//         onException: (code) => setErrorCode(String(code)),
//         onError: (code) => setErrorCode(String(code)),
//         showExceptionError: false,
//       });
//       setSubmitting(false);
//       return !ret;
//     }
//     return false;
//   };

//   const [errorCode, setErrorCode] = useState('');

//   return (
//     <Box
//       sx={{ zIndex: theme.zIndex.modal }}
//       className={`boxcenterhv ${gc.screenCoverZindex1100}`}
//     >
//       <Box
//         sx={{
//           width: '37.5rem',
//           minHeight: '14rem',
//           bgcolor: theme.palette.background.paper,
//           maxHeight: '100vh',
//           display: 'flex',
//           flexDirection: 'column',
//         }}
//         className="border-radius-8px"
//       >
//         <Box
//           sx={{
//             width: '100%',
//             my: '1.25rem',
//             pb: '0.5rem',
//             px: '1.625rem',
//             borderBottom: `0.0625rem solid ${theme.custom.primary5}`,
//           }}
//           className="boxcenterhv"
//         >
//           <Typography className={gc.font18px600Black}>{initTitle}</Typography>
//           <Box sx={{ flexGrow: 1 }}></Box>
//           <IconButton onClick={() => handleClose()}>
//             <CrossIcon
//               stroke={theme.custom.black}
//               sx={{
//                 width: '1rem',
//                 height: '1rem',
//                 mr: 1,
//                 mt: '0.125rem',
//               }}
//             />
//           </IconButton>
//         </Box>
//         {errorCode === '4032' && (
//           <>
//             <Box sx={{ px: '1.625rem', mb: 2 }}>
//               <Alert variant="filled" severity="error">
//                 {errorCode === '4032'
//                   ? intl.formatMessage(ErrorMessages.responseCode4032)
//                   : ''}
//               </Alert>
//             </Box>
//           </>
//         )}
//         <Box sx={{ flexGrow: 1 }}>
//           <Typography
//             sx={{
//               fontSize: '1rem',
//               fontFamily: 'ProximaNova',
//               color: theme.custom.gray,
//               px: '1.625rem',
//             }}
//           >
//             {newStatus === 'completed'
//               ? intl.formatMessage(Messages.completeMilestoneDescription)
//               : newStatus === 'closed'
//               ? intl.formatMessage(Messages.closeMilestoneDescription)
//               : intl.formatMessage(
//                   Messages.theAbilityToChangeMilestoneWillNoLongerFunction
//                 )}
//           </Typography>
//         </Box>
//         <Box
//           sx={{
//             px: '1.625rem',
//             width: '100%',
//             mb: '1.5rem',
//             borderTop: `0.0625rem solid ${theme.custom.primary5}`,
//             pt: '1rem',
//           }}
//           className="boxcenterhv"
//         >
//           <Button
//             sx={{
//               width: '11.625rem',
//               height: '2.5rem',
//             }}
//             className={`border-radius-8px ${gc.buttonCancellation}`}
//             onClick={() => handleClose(false)}
//           >
//             {intl.formatMessage(Messages.cancel)}
//           </Button>
//           <Box sx={{ flexGrow: 1 }}></Box>
//           <Button
//             sx={{ width: '11.625rem', height: '2.5rem' }}
//             className={`border-radius-8px ${gc.buttonPrimary}`}
//             onClick={async () => {
//               const ret = await handleChangeCampaignMilestoneStatus();
//               ret && handleClose(true);
//             }}
//             disabled={submitting}
//           >
//             {intl.formatMessage(
//               errorCode === '4032' ? Messages.retry : Messages.ok
//             )}
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      marginBottom: '1.5rem',
      paddingTop: '1rem',
      width: '100%',
    },
    cancel: {
      minWidth: '8.25rem',
      height: '2.5rem',
    },
    caption: {
      fontWeight: 500,
      fontSize: '0.875rem',
    },
    postbox: {
      background: theme.palette.base.white,
      borderRadius: '0.5rem',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '1rem',
    },
    contentProposalText: {
      fontSize: '1rem',
      fontWeight: '400',
      fontFamily: 'ProximaNova',
    },
    copyIconSize: {
      width: '0.875rem',
      height: '0.875rem',
    },
    viewIcon: {
      position: 'absolute',
      left: '0rem',
      top: '0rem',
      width: '0.875rem',
      height: '0.875rem',
      borderRadius: '50%',
      backgroundColor: theme.palette.base.white,
    },
    countryIcon: {
      position: 'absolute',
      right: '-0.25rem',
      top: '2.25rem',
      width: '1rem',
      height: '1rem',
    },
    iconSize: {
      width: '1rem',
      height: '1rem',
    },
    iconSizeSmall: {
      width: '0.9375rem',
      height: '0.9375rem',
    },
    highlightBox: {
      borderRadius: '50%',
      backgroundColor: theme.custom.primary4,
      width: '1.5rem',
      height: '1.5rem',
    },
  })
);
