import { useRef } from 'react';

export function useThrottle(cb: fnVoidToVoid, msWait: number) {
  const throttleTimer = useRef<{
    timer: NodeJS.Timeout | null;
    wait: number;
    lastTimestamp: number;
  }>({ timer: null, wait: msWait, lastTimestamp: 0 }).current;

  const throttledFn = () => {
    if (Date.now() - throttleTimer.lastTimestamp < throttleTimer.wait) {
      if (throttleTimer.timer) {
        clearTimeout(throttleTimer.timer);
      }
      throttleTimer.timer = setTimeout(() => {
        cb();
      }, throttleTimer.wait);
      throttleTimer.lastTimestamp = Date.now();
    } else {
      throttleTimer.timer = setTimeout(() => {
        cb();
      }, throttleTimer.wait);
      throttleTimer.lastTimestamp = Date.now();
    }
  };
  return throttledFn;
}
