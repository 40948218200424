import { IntlShape } from 'react-intl';
import { showMessage } from '../components/ShowMessage';
import { Messages } from '../localization/Messages';
import ErrorMessages, {
  ApiResponseCodePrex,
  SubscriptionLimitErrorCodeList,
} from './benaApiResponseCode';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NOOP = () => {};

function formatErrorCodeMessageId(status_code: number, ui_error_code = '') {
  return `${ApiResponseCodePrex}${
    // status_code === 4014 ? ui_error_code || status_code : status_code
    ui_error_code || status_code
  }`;
}

export function ExtractErrorResponseMessage(
  error: unknown,
  intl: IntlShape,
  ui_error_code = ''
) {
  let code = (error as APIErrorResponse)?.data?.status?.status_code;
  if (!code) {
    // parse error code from xmlhttprequest.responseText
    try {
      const json = JSON.parse(`${error}`);
      code = (json as any)?.status?.status_code;
    } catch (e) {}
  }
  return code
    ? intl.formatMessage({ id: formatErrorCodeMessageId(code, ui_error_code) })
    : String(error);
}

type LazyQueryResultHandlerProps<T> = {
  intl: IntlShape;
  result: QueryResultType<T>;
  onSuccess?: (data: T) => void;
  onError?: (code?: number) => void;
  onException?: () => void;
  uiErrorCode?: string;
};

export function LazyQueryResultHandler<T = EmptyObjectType>({
  intl,
  result,
  onSuccess = NOOP,
  onError = NOOP,
  onException = NOOP,
  uiErrorCode,
}: LazyQueryResultHandlerProps<T>) {
  if (result.isSuccess) {
    console.assert(
      !!result.data?.data && !!result.data?.status?.status_code,
      'response.data.data and response.data.stauts.status_code should not be null, check with backend.'
    );

    const {
      status: { status_code },
      data,
    } = result.data || { status: { status_code: undefined }, data: undefined };

    if (status_code !== 1000) {
      showMessage.error(
        status_code
          ? intl.formatMessage({
              id: formatErrorCodeMessageId(status_code, uiErrorCode),
            })
          : intl.formatMessage(Messages.unknowError)
      );
      onError(status_code);
    } else if (data !== undefined) {
      onSuccess(data);
    }
  }

  if (result.isError) {
    showMessage.error(
      ExtractErrorResponseMessage(result.error, intl, uiErrorCode) ||
        intl.formatMessage(Messages.unknowError)
    );
    onException();
  }
}

type MutationResultHandlerProps<T> = {
  intl: IntlShape;
  action: () => Promise<APIResponse<T>>;
  onSuccess?: (data: T) => void;
  onError?: (code?: number) => void;
  onException?: (code?: number) => void;
  showExceptionError?: boolean;
  uiErrorCode?: string;
};

/**
 * @param param0
 * @param successMessage
 * @returns true for error occurred; false for success;
 */
export async function MutationHandler<T = EmptyObjectType>(
  {
    intl,
    action,
    onSuccess = NOOP,
    onError = NOOP,
    onException = NOOP,
    showExceptionError = true,
    uiErrorCode = '',
  }: MutationResultHandlerProps<T>,
  successMessage: MessageType | null = null,
  onSetMsg?: boolean
) {
  try {
    const res = await action();
    if (!res?.status?.status_code) {
      throw new Error('Response is invalid.');
    }
    const {
      status: { status_code },
      data,
    } = res;

    if (status_code !== 1000 && status_code !== 200) {
      showExceptionError &&
        // subscription limit error shows in modal, skip error message
        !SubscriptionLimitErrorCodeList.includes(status_code) &&
        showMessage.error(
          onSetMsg ?
            res.status.message :
            intl.formatMessage({
              id: formatErrorCodeMessageId(status_code, uiErrorCode),
            })
        );
      onError(status_code);
      return true;
    } else {
      if( successMessage ){
        showMessage.success(intl.formatMessage(successMessage));
      }else if( onSetMsg ){
        showMessage.success( res.status.message )
      };
      onSuccess(data);
      return false;
    }
    
  } catch (error) {
    const {
      error: _error,
      status,
      data,
    } = (error as {
      error: string;
      status: string;
      data: { status: { status_code: number; message: string } };
    }) || {};

    if (_error && status) {
      let message = '';
      if (status === 'FETCH_ERROR') {
        message = intl.formatMessage(ErrorMessages.uiErrorCode8001);
        message += ' ';
      }
      showMessage.error(`${message}${_error}`);
      onError();
    } else if(data && onSetMsg){
      data.status.status_code === 4009 && showMessage.error(data.status.message)
    }else {
      showExceptionError &&
        // subscription limit error shows in modal, skip error message
        !SubscriptionLimitErrorCodeList.includes(data?.status?.status_code) &&
        showMessage.error(
          ExtractErrorResponseMessage(error, intl, uiErrorCode) ||
            intl.formatMessage(Messages.unknowError)
        );
      onException((error as APIErrorResponse)?.data?.status?.status_code);
    }
    return true;
  }
}
