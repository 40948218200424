import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AIGCDialogContext } from '../context/context';
import { AIGCPanel } from '../features/aigc/CreativeStoryDialog';
import { useGetCreatorData } from '../hooks/creators';
import { NOOP } from '../redux/benaApiResultHandler';

export default function AIGC() {
  const { id } = useParams();
  const { creator, setCreator } = useGetCreatorData(id);
  const [prevUrl, setPrevUrl] = useState('');

  return creator ? (
    <AIGCDialogContext.Provider
      value={{
        creator,
        creatorData: null,
        reset: () => setCreator(null),
        favoritedCreators: [],
        notifyFavorite: NOOP,
        prevUrl,
        setPrevUrl,
        notifyCreator: NOOP,
      }}
    >
      <AIGCPanel />{' '}
    </AIGCDialogContext.Provider>
  ) : (
    <></>
  );
}
