import { Fragment } from 'react';

export function LinkItMatchFn(match: string, key: number) {
  return (
    <Fragment key={key}>
      <a
        href={
          match.toLowerCase().startsWith('http') ? match : 'http://' + match
        }
        key={key}
      >
        {match.length > 40
          ? match.slice(0, 20) + '...' + match.slice(-20)
          : match}
      </a>
      <br />
    </Fragment>
  );
}
