import { useIntl } from 'react-intl';
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
  Link,
} from '@mui/material';
import { useGlobalStyles } from '../../assets/styles/style';
import { UserAccountMessages } from './Messages';
import {
  MAXEMAILADDRESSLENGTH,
  numberFilter,
  ValidateRules,
  VERIFICATIONCODECOUNTDOWNTIMER,
} from '../../utils/common';
import {
  useLazyValidateEmailVerificationQuery,
  useSendEmailVerificationCodeMutation,
} from '../../redux/benaApi';
import SendCodeButton from '../../components/SendCodeButton';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../../redux/benaApiResultHandler';
import LogoAndLanguage from './LogoAndLanguage';
import { staticURLLogin } from '../../route/urls';
import { useNavigate } from 'react-router-dom';

type RegisterAccountProps = {
  nextStep: (user: RegisterRequestParams) => void;
  registerBrandData: BrandAccountType | null;
  setTemporaryRegisterBrandData: (data: BrandAccountType) => void;
  role: RegisterRoleType;
};

export default function RegisterAccount({
  nextStep,
  registerBrandData,
  setTemporaryRegisterBrandData,
  role,
}: RegisterAccountProps) {
  const theme = useTheme();
  const intl = useIntl();
  const globalStyles = useGlobalStyles();
  const navigate = useNavigate();

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputDataType,
    key: string
  ) => {
    let str = event.target.value.slice(0, data.maxLength);
    if( data.regExp ){
      let regExpTest = /^[A-Za-z0-9.]*$/;
      if( !regExpTest.test(str) ){
        setRegExpError(true);
      }else if(regExpError){
        setRegExpError(false)
      }
    }
    if (data.inputValueType === 'verificationCode') {
      str = numberFilter(str);
    }
    
    setFormData({
      ...formData,
      [key]: {
        ...data,
        content: str,
        length: event.target.value.length,
        error: !ValidateRules.validInputString(
          str,
          data.maxLength,
          data.inputValueType
        ),
      },
    });
    setTemporaryRegisterBrandData({
      yourFullName: formData.yourFullName,
      emailAddress: formData.emailAddress,
      verificationCode: { ...formData.verificationCode, content: '' },
    });
  };

  const [regExpError, setRegExpError] = useState(false);
  
  // regExp: /^[A-Za-z0-9.]*$/,
  // regExpErrorText: "You can use letters, numbers and English periods"

  const [formData, setFormData] = useState<BrandAccountType>(
    registerBrandData || {
      yourFullName: {
        content: '',
        maxLength: 80,
        error: false,
        inputValueType: 'text',
        regExp: true,
      },
      emailAddress: {
        content: '',
        maxLength: MAXEMAILADDRESSLENGTH,
        error: false,
        inputValueType: 'email',
      },
      verificationCode: {
        content: '',
        maxLength: 6,
        error: false,
        inputValueType: 'verificationCode',
      },
    }
  );

  const refFullName = useRef<HTMLElement>(null);
  const refEmailAddress = useRef<HTMLElement>(null);
  const refVerificationCode = useRef<HTMLElement>(null);
  useEffect(() => {
    refFullName.current?.focus();
  }, []);

  const inputList = [
    {
      item: formData.yourFullName,
      message:
        role === 'brand'
          ? UserAccountMessages.yourBrandName
          : UserAccountMessages.yourFullname,
      name: 'yourFullName',
      refEl: refFullName,
      placeHolder:
        role === 'brand'
          ? UserAccountMessages.enterYourBrandName
          : UserAccountMessages.enterYourFullname,
    },
    {
      item: formData.emailAddress,
      message: UserAccountMessages.emailAddress,
      name: 'emailAddress',
      refEl: refEmailAddress,
      placeHolder: UserAccountMessages.enterEmailAddress,
    },
    {
      item: formData.verificationCode,
      message: UserAccountMessages.verificationCode,
      name: 'verificationCode',
      refEl: refVerificationCode,
      placeHolder: UserAccountMessages.enter6DigitalCode,
      children: (
        <Box sx={{ mb: '0.875rem' }}>
          <Typography
            variant="subtitle1"
            fontFamily="Outfit"
            sx={{
              color: theme.custom.gray,
              position: 'relative',
            }}
            component="span"
          >
            {intl.formatMessage(UserAccountMessages.didntGetACode)}
            <Typography
              sx={{ mx: '0.25rem', color: theme.custom.gray }}
              fontFamily="Outfit"
              component="span"
            >
              {intl.formatMessage(UserAccountMessages.checkSpanFolder)}
            </Typography>
            <Typography
              sx={{ mx: '0.25rem', color: theme.custom.gray }}
              fontFamily="Outfit"
              component="span"
            >
              {intl.formatMessage(UserAccountMessages.orRetry)}
            </Typography>
            <Typography sx={{ color: theme.custom.gray }} component="span">
              {intl.formatMessage(UserAccountMessages.sendCode)}
            </Typography>
            {'.'}
          </Typography>
        </Box>
      ),
    },
  ] as Array<FormInputType>;

  const isValidForSave = function (
    inputs: typeof inputList,
    saveFormData = true
  ): boolean {
    for (let i = 0; i < inputs.length; i++) {
      const { item: input, refEl: ref, name: key } = inputs[i];
      if( input.regExp ){
        let regExpTest = /^[A-Za-z0-9.]*$/;
        if( !regExpTest.test(input.content) ){
          return false;
        }
      }
      if (
        !ValidateRules.validInputString(
          input.content,
          input.maxLength,
          input.inputValueType
        )
      ) {
        if (saveFormData) {
          setFormData({
            ...formData,
            [key]: { ...input, error: true },
          });
          ref.current?.focus();
        }
        return false;
      }
    }

    return saveFormData ? true : !submitting;
  };
  const [submitting, setSubmitting] = useState(false);

  const [sendEmailVerificationCode, sendCodeResult] =
    useSendEmailVerificationCodeMutation();

  const handleGetVerificationCode = async () => {
    if (isValidForSave(inputList.slice(0, 2))) {
      setSubmitting(true);
      setEmailExist(false);
      await MutationHandler(
        {
          intl,
          action: async () =>
            await sendEmailVerificationCode({
              email: formData.emailAddress.content,
              name: formData.yourFullName.content,
              language: 'en',
              existing_user: false,
            }).unwrap(),
          onException: (statusCode) => {
            statusCode === 4009 && setEmailExist(true);
          },
          onError: () => {
            setCountDown(0);
          },
        },
        UserAccountMessages.showMessageSendCodeSuccess,
        true
      );
      setCountDown(VERIFICATIONCODECOUNTDOWNTIMER);
    }
  };

  const [triggerVerifyCode, result] = useLazyValidateEmailVerificationQuery({});
  useEffect(() => {
    LazyQueryResultHandler<ValidateEmailVerificationResponseType>({
      intl,
      result: result as QueryResultType<ValidateEmailVerificationResponseType>,
      onSuccess: (data) => {
        nextStep({
          name: formData.yourFullName.content,
          email: formData.emailAddress.content,
          token: data.token,
          password: '',
          role,
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]); // only result change here

  const handleContinue = async () => {
    
    if (isValidForSave(inputList)) {
      setSubmitting(true);
      await triggerVerifyCode({
        email: formData.emailAddress.content,
        code: formData.verificationCode.content,
        // name: formData.yourFullName.content,
      });
      setSubmitting(false);
    }
  };

  const [countDown, setCountDown] = useState<number>(0);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countDown > 0) {
      timer = setTimeout(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [countDown]);

  // wait 10 seconds after click send verification code
  useEffect(() => {
    countDown <= 50 && setSubmitting(false);
  }, [countDown, setSubmitting]);

  const [emailExist, setEmailExist] = useState(false);
  // if email exists after get code verification, stop countdown
  useEffect(() => {
    if (emailExist) {
      setCountDown(0);
      setSubmitting(false);
    }
  }, [emailExist]);

  return (
    <Box>
      <LogoAndLanguage />
      <Box className="form-width" sx={{ mt: '2.5rem' }}>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
          <Typography
            component="span"
            color="primary.main"
            sx={{ fontSize: '1.5rem', fontWeight: 700 }}
          >
            {role === 'brand'
              ? intl.formatMessage(UserAccountMessages.BrandAccount)
              : ''}
          </Typography>{' '}
          {intl.formatMessage(
            role === 'brand'
              ? UserAccountMessages.Registration
              : UserAccountMessages.registerCreatorAccount
          )}
        </Typography>
        <Box
          className={'boxcenterhv'}
          sx={{ justifyContent: 'flex-start', mt: '0.5rem' }}
        >
          <Typography variant="subtitle1">
            {intl.formatMessage(UserAccountMessages.alreadyHaveAnAccount)}
          </Typography>
          <Link href={staticURLLogin} underline={'none'}>
            <Typography
              color={theme.palette.primary.main}
              variant="subtitle1"
              sx={{ ml: 1 }}
              className={'hand'}
            >
              {intl.formatMessage(UserAccountMessages.SignIn)}
            </Typography>
          </Link>
        </Box>
        <Box sx={{ height: '2rem' }}></Box>
        <form>
          {inputList.map((data) => {
            const { item, message, name, refEl, placeHolder, children } = data;
            return (
              <Box
                key={message.id}
                className={'boxcenterhv'}
                sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Typography
                  sx={{
                    mb: 1.75,
                    fontSize: '0.625rem',
                    fontWeight: 600,
                  }}
                  component="div"
                  className={'boxcenterhv'}
                >
                  {intl.formatMessage(message).toUpperCase()}
                  <Typography
                    sx={{
                      color: theme.palette.danger[400],
                      ml: '0.25rem',
                      fontSize: '0.75rem',
                      alignSelf: 'stretch',
                    }}
                  >
                    *
                  </Typography>
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    placeholder={intl.formatMessage(placeHolder)}
                    inputRef={refEl}
                    sx={{
                      mb: children ? 2 : 3,
                      ...(name === 'verificationCode'
                        ? {
                            '&>input': {
                              letterSpacing: '0.25rem',
                              fontFamily: 'Outfit',
                              fontSize: '0.875rem',
                              fontWeight: 'bold',
                            },
                          }
                        : {}),
                    }}
                    className={`border-radius-6px input-size `}
                    id={message.id}
                    value={item.content}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleTextInputChange(event, item, name);
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={(theme) => ({
                          fontSize: theme.typography.caption,
                        })}
                      >
                        {name === 'emailAddress' ? (
                          <SendCodeButton
                            countDown={countDown}
                            onClick={() =>
                              !submitting && handleGetVerificationCode()
                            }
                            message={UserAccountMessages.sendCode}
                            countDownMessage={UserAccountMessages.resendCodeIn}
                            timeUnit={UserAccountMessages.textSecondInAbbr}
                            isSending={sendCodeResult.isLoading}
                          />
                        ) : (
                          ''
                        )}
                      </InputAdornment>
                    }
                    error={item.error}
                    fullWidth
                  />
                  {
                    item.regExp && regExpError && (
                      <Typography sx={{
                          color: theme.palette.danger[400], fontSize: '0.75rem',
                          position: 'absolute', bottom: '0.375rem', left: '0', whiteSpace: 'nowrap'
                        }} >
                        {intl.formatMessage(UserAccountMessages.regExpFormBrandNameTip)}
                      </Typography>
                    )
                  }
                  {children}
                </FormControl>
              </Box>
            );
          })}
          <Box>
            <Button
              type="submit"
              className={`border-radius-6px button-size`}
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                handleContinue();
              }}
              disabled={!isValidForSave(inputList, false)}
              sx={{ mt: '1rem' }}
            >
              {intl.formatMessage(UserAccountMessages.Next)}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
