import {
  Avatar,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useBreakpointXs } from '../../utils/useStyleHooks';

type ContentHelperCardProps = {
  title: string;
  subTitle: string;
  buttonText: string;
  image: string;
  messages: string[];
  avatar: string;
  columnGap?: string;
  buttonClass: string;
  msgBoxBgcolor: string;
  clickHandler?: fnStringToVoid;
};
export default function ContentHelperCard({
  title,
  subTitle,
  buttonText,
  image,
  messages,
  avatar,
  columnGap = '32px',
  buttonClass,
  msgBoxBgcolor,
  clickHandler,
}: ContentHelperCardProps) {
  const theme = useTheme();
  const isMobileView = useBreakpointXs();
  return (
    <Stack
      width={isMobileView ? '100%' : '33%'}
      borderRadius={'10px'}
      padding={'16px'}
      border={`1px solid ${theme.palette.neutral[600]}`}
      sx={{
        '&:hover': {
          border: `1px solid ${theme.palette.grey[700]}`,
        },
        cursor: 'pointer',
      }}
      onClick={() => clickHandler?.(title)}
    >
      <Stack direction="row" columnGap={columnGap}>
        <Box bgcolor={theme.palette.neutral['0_0']} borderRadius={'16px'}>
          <img src={image} alt="" />
        </Box>
        <Stack flexGrow={1}>
          <Typography fontWeight={500} fontSize={'16px'} color="grey.700">
            {title}
          </Typography>
          <Typography fontSize={'16px'} color="grey.50">
            {subTitle}
          </Typography>
          <Box flexGrow={1}></Box>
          <Button
            variant="contained"
            className={buttonClass}
            sx={{ minHeight: '40px' }}
            onClick={() => clickHandler?.(title)}
          >
            {buttonText}
          </Button>
        </Stack>
      </Stack>
      {!isMobileView && (
        <>
          <Divider
            sx={{
              borderColor: theme.palette.neutral[600],
              my: '1rem',
            }}
          />
          <Stack rowGap={'8px'}>
            {messages.map((w, index) => (
              <Stack
                direction="row"
                key={index}
                alignItems={'flex-start'}
                justifyContent={'flex-end'}
                columnGap={'8px'}
              >
                <Box
                  boxShadow="0px 0px 6px rgba(0, 0, 0, 0.05)"
                  borderRadius="10px 2px 10px 10px"
                  padding="24px 20px"
                  sx={{ bgcolor: msgBoxBgcolor }}
                >
                  <Typography
                    color={theme.palette.base['white_3']}
                    fontSize={'15px'}
                    fontWeight={500}
                  >
                    {w}
                  </Typography>
                </Box>
                <Avatar src={avatar} sx={{ width: '40px', height: '40px' }} />
              </Stack>
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
}
