import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../../redux/store';

export type AdminSliceType = {
  selectedCreator: CreatorData | null;
};

const initialState: AdminSliceType = {
  selectedCreator: null,
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    adminSetSelectedCreator(
      state: AdminSliceType,
      action: PayloadAction<CreatorData | null>
    ) {
      state.selectedCreator = action.payload;
    },
  },
});

export const { adminSetSelectedCreator } = slice.actions;

export const selectAdmin = createSelector(
  [(state: RootState) => state.admin],
  (state) => state
);

export default slice.reducer;
