import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Stack, useTheme } from '@mui/material';
import AIScriptPanel from './AIScriptPanel';
import { CreatorCardLeftPanel } from './CreatorCardLeftPanel';
import PageLayoutWrapper from '../../pages/PageLayoutWrapper';
import { TransitionUp } from '../../components/Transitions';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { replaceCurrentUrl } from '../../utils/common';
import { useContext } from 'react';
import { AIGCDialogContext } from '../../context/context';

export function AIGCPanel() {
  const theme = useTheme();
  const isMobile = useBreakpointXs();

  return (
    <Stack
      direction="row"
      border={'1px solid ' + theme.palette.neutral[600]}
      borderRadius="16px"
      className="shadowXSM"
      margin={isMobile ? '2rem' : '3rem 0rem 0rem 0rem'}
    >
      {!isMobile && <CreatorCardLeftPanel />}
      <Stack
        bgcolor={theme.palette.neutral[25]}
        flexGrow="1"
        borderRadius={isMobile ? '16px' : '0 16px 16px 0'}
      >
        <AIScriptPanel />
      </Stack>
    </Stack>
  );
}

export default function CreativeStoryDialog() {
  const theme = useTheme();
  const { creatorData, setPrevUrl } = useContext(AIGCDialogContext);
  const creatorId = creatorData?.id;
  const [open, setOpen] = React.useState(!!creatorData);
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    setOpen(!!creatorId);
    
  }, [creatorId]);

  // update url without page reload
  React.useEffect(() => {
    if (creatorId) {
      setPrevUrl(window.location.href.replace(window.location.origin, ''));
      replaceCurrentUrl(
        'AIGC for Creator',
        window.location.origin + '/creators/' + creatorId
      );
    }
  }, [creatorId]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
      sx={{
        zIndex: theme.zIndex.modal,
        '& .MuiDialog-container > .MuiPaper-root': {
          backgroundColor: theme.palette.neutral[0],
          backgroundImage: 'none !important',
        },
      }}
    >
      <PageLayoutWrapper showHeader={true}>
        <AIGCPanel />
      </PageLayoutWrapper>
    </Dialog>
  );
}
