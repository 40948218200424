import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  capitalize,
  IconButton,
  Stack,
  useTheme,
  Box,
  Link,
  CardActionArea
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from "@mui/styles"
import { usePlatformsIconGrey } from '../../config/platforms';
import { Messages } from '../../localization/Messages';
import { useIntl } from 'react-intl';
import { StarRating } from '../../components/StyledRating';
import { useContext, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  useDeleteFavoriteCreatorsMutation,
  usePostFavoriteCreatorsMutation,
} from '../../redux/benaApi';
import {
  HeartIcon,
  FollowerPlusIcon,
  Eye3Icon,
  AverageErIcon,
  SponsoredBrandIcon,
  ShortListDelIcon,
  ShoppingIcon,
} from '../../assets/imgs/icons';
import { useDispatch } from 'react-redux';
import { setSelectedCreatorId } from '../aigc/AIGCSlice';
import CreatorModeration from '../admin/CreatorModeration';
import { getUrlBySocialPlatformId } from '../../route/urls';
import { useIsAdminUser } from '../../hooks/account';
import { calcEngagementRate } from '../../utils/common';
import { AIGCDialogContext } from '../../context/context';
import LightTooltip from '../../components/LightTooltip';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import BrandLabel from './brandLabel';
import BrandLabelList from './brandLabelList';
import CreatorCheckList from '../modules/creatorCheckList';
import IconCounts from "../modules/iconCounts";

type CreatorCardProps = {
  creator: CreatorData;
  handleAddToCampaign: (creator: CreatorData) => void;
  deleteCreateData?: ({}:SubassemblyCallbackType) => void;
};
const iconSize = { width: '1.125rem', height: '1.125rem' };
export function CreatorCard({
  creator,
  handleAddToCampaign,
  deleteCreateData
}: CreatorCardProps) {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const PlatformsIconGrey = usePlatformsIconGrey();
  const { favoritedCreators, notifyFavorite, setPrevUrl } =
    useContext(AIGCDialogContext);
  const dispatch = useDispatch();
  let hrefCode = "";
  if(window.location.href.includes("my-creators/userlist")){
    hrefCode = atob( window.location.search.slice(1) )
  }
  const [deleteAdmined, setDeleteAdmined] = useState<string>('');
  const {
    picture,
    name,
    unique_id,
    profile,
    profile_type,
    creativity_index,
    sponsored_brand,
    uid,
    shopping_cart
  } = creator;
  
  const starRating = creativity_index
    ? Math.min(Math.max(1, creativity_index), 3)
    : undefined;
  const {
    followers: _followers,
    engagement_rate: _engagementRate,
    engagements: _engagements,
    average_er: _averageEr,
    avg_views: _avgViews,
  } = profile;
  const followers = intl.formatNumber(_followers, {
    notation: 'compact',
  });
  const avg_views = intl.formatNumber(_avgViews || 0, {
    notation: 'compact',
  });
  // const sponsored_brand = ['Nike','Adidas','Puma','Reebok','under armour','new balance',
  //   'asics','converse','vans','fila','jordan','yeezy','balenciaga','gucci','louis vuitton',
  //   'prada','versace','dior','chanel','hermes','fendi','burberry','givenchy','valentino',
  //   'off-white','vetements','rick owens'];
  const sponsoredList = sponsored_brand.slice(0,2);
  const sponsoredCount = sponsored_brand.length - 2;
  
  const engagementRate = calcEngagementRate(_averageEr || _engagementRate);

  const [favorited, setFavorited] = useState<number | undefined>(
    creator.favorite ? 1 : undefined
  );
  useEffect(() => {
    const f = favoritedCreators.find((v) => v.id === creator?.id)?.favorite;
    if (f !== undefined) {
      setFavorited(f ? 1 : 0);
    }
  }, [favoritedCreators, creator]);
  const [postFavoriteCreator] = usePostFavoriteCreatorsMutation({});
  const [deleteFavoriteCreator] = useDeleteFavoriteCreatorsMutation({});
  const handleFavorite = async () => {
    const id = creator.id;
    await MutationHandler({
      intl,
      action: async () => {
        return favorited ? await deleteFavoriteCreator({ creator_id: id }).unwrap() : await postFavoriteCreator({ creator_id: id }).unwrap();
      },
      onSuccess: () => {
        const f = !favorited ? 1 : undefined;
        setFavorited(f);
        notifyFavorite({ id: creator?.id || '', favorite: !!f });
      },
    });
  };
  const handleDelete = async () => {
    function callbackAdmined(){
      setDeleteAdmined("admined-gradually_hide");
    }
    deleteCreateData && deleteCreateData({
      id: creator.id,
      uid: creator.uid,
      name: creator.name,
      callback: callbackAdmined
    });
  }

  // dispatch creator to aigcslice to show creator aigc dialog
  const showAIGC = () => {
    setPrevUrl(window.location.href.replace(window.location.origin, ''));
    dispatch(setSelectedCreatorId(creator.id));
  };

  const isAdmin = useIsAdminUser();
  const at = profile_type.toLowerCase() === 'youtube' ? '' : '@';
  const urluid =
    profile_type.toLowerCase() === 'youtube' ? uid || unique_id : unique_id;

  const [open, setOpen] = useState<boolean>(false);
  const [shortLocal, setShortLocal] = useState<string[]>([...creator.shortlist || []]);

  useEffect(()=>{
    setShortLocal(creator.shortlist || []);
  },[creator.shortlist]);

  const inviteToCampaign = useMemo(
    () => (
      <Box
        sx={{
          width: '100%', height: '100%', p: '1rem', position: 'absolute', left: 0, top: 0,
          pointerEvents: 'none',
          background: "linear-gradient(180deg, rgba(22, 35, 45, 0.65) 0%, rgba(255, 255, 255, 0) 40%)",
          backgroundBlendMode: 'multiply',
        }}
        className="boxcenterhv border-radius-7px-top" //hoverCover
      >
        {/* <Box
          sx={{ bgcolor: theme.palette.base.white, borderRadius: '8px', height: '40px', width: '150px', color: theme.palette.primary.main, fontSize: '14px', fontWeight: '600'}}
          display={creator.email ? '' : 'none'}
        >
          <Link
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleAddToCampaign(creator); setOpen(true); }}
            sx={{color: theme.palette.primary.main, fontSize: '14px', fontWeight: '600', width: '100%', height: '100%'}}
            className={'boxcenterhv'}
            underline="none"
          >
            {intl.formatMessage(Messages.InviteToCampaign)}
            {intl.formatMessage(Messages.AddToList)}
          </Link>
        </Box>
        <Box
          sx={{bgcolor: theme.palette.base.white,borderRadius: '8px',height: '40px',width: '150px',color: theme.palette.primary.main,fontSize: '14px',fontWeight: '600'}}
          mt={'8px'}
        >
          <Link
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); showAIGC(); }}
            sx={{ color: theme.palette.primary.main, fontSize: '14px', fontWeight: '600', width: '100%', height: '100%' }}
            className={'boxcenterhv'}
            underline="none"
          >
            {intl.formatMessage(Messages.ScriptWriter)}
          </Link>
        </Box> */}
      </Box>
    ),
    [
      // creator, handleAddToCampaign, intl, theme.palette.base.white, theme.palette.gradient.overlayColor,
      // theme.palette.primary.contrastText, theme.palette.primary.main, showAIGC
    ]
  );

  const [labelState, setLabelState] = useState<boolean>(false);

  const card = useMemo(
    () => (
      <Card
        sx={{
          maxWidth: 254,
          minWidth: 168,
          border: '1px solid ' + theme.palette.neutral[600],
          borderRadius: '10px',
          position: 'relative',
          cursor: 'pointer',
          backgroundColor: theme.palette.base.white,
          '&:hover': {
            border: `1px solid ${theme.palette.grey[700]}`,
          },
        }}
        elevation={0}
        className={ `shadowXSM step_generate_script ${deleteAdmined}` }
      >
        <Stack
          direction={'row'}
          sx={{
            position: 'absolute',
            width: '100%',
            padding: '1rem',
            zIndex: theme.zIndex.fab,
            cursor: 'auto',
          }}
          justifyContent="space-between"
        >
          <LightTooltip
            title={capitalize(intl.formatMessage(Messages.creativityScore))}
          >
            <Box component={'span'}>
              <StarRating defaultValue={starRating} max={3} readOnly />
            </Box>
          </LightTooltip>
          {hrefCode ? (
            <IconButton
              disableRipple
              onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleDelete(); }}
              className={classes.IconButtonRight}
            >
              <ShortListDelIcon sx={{width:14,height:17}} />
            </IconButton>
          ) : (
            <IconButton
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                // handleFavorite();
                setOpen(true);
              }}
              className={`step_shortlists_panel ${classes.IconButtonRight}`}
            >
              {shortLocal.length ? (
                <HeartIcon sx={iconSize} />
              ) : (
                <HeartIcon sx={iconSize} fill={'white'} />
              )}
            </IconButton>

          )}
        </Stack>
        <CardActionArea
          onClick={() => showAIGC()}
          sx={{ position: 'relative' }}
          // ':hover>.hoverCover': { visibility: 'visible' },
        >
          <CardMedia component="img" height="224" image={picture} alt={name} />
          {inviteToCampaign}
        </CardActionArea>
        { open && 
          <CreatorCheckList
            uid={ creator.uid as string }
            shortLocal={shortLocal}
            setShortLocal={setShortLocal}
            setOpen={setOpen}
             />}
        <CardContent sx={{ paddingBottom: '9px !important', cursor: 'auto' }}>
          <Stack direction="row" alignItems="center" gap="8px">
            <Typography fontSize={'16px'} fontWeight={500} component="div">
              {name}
            </Typography>
            {shopping_cart && (
              <LightTooltip title={intl.formatMessage(Messages.shopEnabled)} >
                <Box height="17px">
                  <ShoppingIcon sx={{width: 16,height: 17,fill:"#694CE6 !important"}} />
                </Box>
              </LightTooltip>
            )}
            
          </Stack>
          <Link
            href={`${getUrlBySocialPlatformId(profile_type)}${at}${urluid}`}
            underline="none"
            onClick={(e) => {
              e.stopPropagation();
            }}
            target="_blank"
          >
            <Typography variant="body2" className="boxlefthcenterv">
              {
                PlatformsIconGrey[
                  profile_type.toLowerCase() as keyof typeof PlatformsIconGrey
                ]
              }
              &nbsp;&nbsp;
              <Typography component={'span'} color="grey.50">
                @{unique_id}
              </Typography>
            </Typography>
          </Link>
          <Box
            sx={{
              height: '2px',
              backgroundColor: theme.palette.neutral[600],
              marginTop: '6px',
              marginBottom: '11px',
            }}
          ></Box>
          <Stack direction="row" justifyContent={'space-between'}>
            <IconCounts icon={
              <FollowerPlusIcon sx={{width: 14, height:15 }} fill={theme.palette.base["black"]} />
            } count={followers} tips={ intl.formatMessage(Messages.followers) } />
            {
              avg_views !== "0" && <IconCounts icon={
                <Eye3Icon sx={{width: 15,height:12, fill: "none" }} stroke={theme.palette.base["black"]} />
              } count={avg_views} tips={ intl.formatMessage(Messages.avgViews) } />
            }
            <IconCounts icon={
              <AverageErIcon sx={{width: 16,height:14 }} fill={theme.palette.base["black"]} />
            } count={engagementRate} tips={intl.formatMessage(Messages.engagementRate)} />
            {/* <Box flexGrow="1" minWidth="0.25rem"></Box> */}
          </Stack>
          {sponsoredList.length > 0 && <Stack sx={{ marginTop: "0.375rem" }}>
            <LightTooltip title={intl.formatMessage(Messages.sponsoredBrand)} >
              <Box className={classes.iconBoxStyle} >
                <SponsoredBrandIcon sx={{width: 14,height:15, fill: "none" }} stroke={theme.palette.base["black"]} />
              </Box>
            </LightTooltip>
            <Stack direction="row" gap="10px" marginTop="5px">
              { sponsoredList.map((brand, index) =>
                <BrandLabel key={index} brand={brand} color={theme.palette.base.black} bgColor={theme.palette.neutral["300"]} />
              ) }
              { sponsoredCount > 0 && (
                <BrandLabel brand={'+ '+ sponsoredCount} color={theme.palette.neutral["200"]} 
                  bgColor={theme.palette.grey["400"]} onClick={()=>setLabelState(true)} />
                )
              }
            </Stack>
          </Stack>}
          {labelState && <BrandLabelList brandList={sponsored_brand} closeLabelBox={()=>setLabelState(false)} />}
        </CardContent>
        {isAdmin && <CreatorModeration creator={creator} />}
      </Card>
    ),
    [
      creator, favorited, theme, at, urluid, notifyFavorite, 
      open, shortLocal, deleteAdmined, labelState
    ]
  );
  return card;
};

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    iconBoxStyle: {
      width: '16px',
      height: '16px',
      lineHeight: '16px',
      textAlign: 'center',
      marginBottom: "2px"
    },
    sponsoredBrand: {
      height: "22px",
      padding: "2px 8px",
      borderRadius: "4px",
      fontSize: "13px",
      lineHeight: "18px",
    },
    IconButtonRight: {
      padding: '0px',
      '&:hover path': {
        fill: theme.palette.danger[400],
      },
    }
  })
);
