import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles, createStyles } from '@mui/styles';
import {
  IconButton,
  Link,
  Stack,
  Theme,
  Typography,
  capitalize,
  useTheme,
} from '@mui/material';
import {
  ArrowDownThinIcon,
  ChatThinIcon,
  File2Icon,
  SortDownIcon,
  SortUpIcon,
  WalletIcon,
} from '../../assets/imgs/icons';
import BenaPagination, {
  BenaRefreshPanel,
} from '../../components/BenaPagination';
import { InfiniteScrollLoaderMessage } from '../../components/InfiniteScrollMessages';
import Color from 'color';
import {
  combineSortToQueryString,
  ShowCreatorWork,
  ShowOnlyPanel,
} from './utils';
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { SOPSearch } from './search/SOPSearch';
// import { CampaignProgressCreatorPanel } from '../CampaignProgressCreator';
import IconMenuButton from '../../components/IconMenuButton';
import { usePatchJobContractShippingStatusOptions } from '../../config/options';
import { usePatchJobContractMutation } from '../../redux/benaApi';
import { Messages } from '../../localization/Messages';
import { ArrowBackRounded } from '@mui/icons-material';
import { useHiredSteps, useStepColors } from './config';
import { MutationHandler, NOOP } from '../../redux/benaApiResultHandler';
import { EnterTrackingNumber } from './EnterTrackingNumber';
import { ReviewCreator } from './ReviewCreator';
import { UploadCampaignWork } from '../../creator/components/UploadCampaignWork';
import LightTooltip from '../../components/LightTooltip';
import { partLinksString } from './EnterPostLink';
import { staticURLMessaging, staticURLTiktok } from '../../route/urls';
import ApplicationViewPage from '../../creator/pages/ApplicationViewPage';
import { EnterPaymentInfo } from './EnterPaymentInfo';
import HireCreator from './HireCreator';
import DialogPage from '../../creator/pages/DialogPage';
import ArticleIcon from '@mui/icons-material/Article';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export const StepNames = [
  {
    stepName: 'product_selection',
  },
  { stepName: 'prepayment' },
  {
    stepName: 'shipping_status',
  },
  { stepName: 'production' },
  {
    stepName: 'draft_submission',
  },
  { stepName: 'post_link' },
  { stepName: 'payment' },
  { stepName: 'review' },
];

const SortByKeys = [
  { key: 'name' },
  { key: 'shipping_status_updated_at' },
  { key: 'current_milestone' },
  { key: 'current_payout' },
  { key: 'total_price' },
  { key: 'last_file_upload_timestamp' },
  { key: 'draft_approved' },
  { key: 'last_link_post_timestamp' },
  { key: 'contract_status' },
];

const useHeadCellsData = () => {
  const theme = useTheme();
  const intl = useIntl();
  return [
    {
      key: SortByKeys[0].key,
      name: intl.formatMessage(Messages.thCreator),
      width: '10rem',
    },
    {
      key: SortByKeys[1].key,
      name: intl.formatMessage(Messages.thProductionSelection),
      width: '9rem',
      sort: true,
      color: theme.palette.primary.main,
      dropdown: false,
      align: 'center',
    },
    {
      key: SortByKeys[2].key,
      name: intl.formatMessage(Messages.thPrepayment),
      width: '6rem',
      sort: false,
    },
    {
      key: SortByKeys[3].key,
      name: intl.formatMessage(Messages.thShippingStatus),
      width: '8rem',
      sort: true,
    },
    {
      key: SortByKeys[4].key,
      name: intl.formatMessage(Messages.thProduction),
      width: '8rem',
      sort: true,
    },
    {
      key: SortByKeys[5].key,
      name: intl.formatMessage(Messages.thDraftSubmission),
      width: '9rem',
      sort: true,
      color: theme.palette.primary.main,
      align: 'center',
    },
    {
      key: SortByKeys[6].key,
      name: intl.formatMessage(Messages.thPostLink),
      width: '6rem',
      sort: false,
      dropdown: false,
      align: 'center',
    },
    {
      key: SortByKeys[7].key,
      name: intl.formatMessage(Messages.thPayment),
      width: '6rem',
      sort: true,
      color: theme.palette.primary.main,
      align: 'center',
    },
    {
      key: SortByKeys[8].key,
      name: intl.formatMessage(Messages.thReview),
      width: '7.5rem',
      sort: false,
      dropdown: false,
      align: 'center',
    },
  ] as {
    key: string;
    name: string;
    width: string;
    color?: string;
    sort?: boolean;
    dropdown?: boolean;
    align?: 'center' | 'left' | 'right';
  }[];
};

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
}: EnhancedTableProps) {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const headCells = useHeadCellsData();
  const getColor = (cell: { key: string }, o: Order) => {
    if (cell.key === orderBy) {
      return order === o ? theme.custom.lightRed : '';
    }
    return '';
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((cell, index) => (
          <TableCell
            key={cell.name}
            align="left"
            padding="none"
            sx={{ minWidth: cell.width, maxWidth: cell.width }}
          >
            <Typography
              className={`${cell.sort && 'hand'} ${classes.headerCell}`}
              onClick={(e) => cell.sort && onRequestSort(e, cell.key)}
              textAlign={cell.align || 'left'}
              title={cell.sort ? intl.formatMessage(Messages.ClickToSort) : ''}
            >
              {cell.name}{' '}
              {cell.sort && cell.key === orderBy && (
                <Link href="#">
                  {order === 'asc' ? (
                    <SortDownIcon className={classes.sortIconSize} />
                  ) : (
                    <SortUpIcon className={classes.sortIconSize} />
                  )}
                </Link>
              )}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type RowDataType = { key: string; children: ReactNode | string };

function allFilesApproved(filesApproval?: FileApprovalType) {
  if (filesApproval) {
    const arr = Object.keys(filesApproval);
    return arr.length > 0 && arr.every((w) => filesApproval[w] === true);
  }
  return false;
}

type StepButtonProps = {
  name: string;
  color: string;
  bgcolor: string;
  showArrow: boolean;
  disabled: boolean;
  onClick?: fnVoidToVoid;
  menuButton?: ReactElement;
  finished: boolean;
  tooltip?: ReactNode;
  icon?: ReactNode;
};
function StepButton({
  name,
  color,
  bgcolor,
  showArrow,
  disabled,
  onClick,
  menuButton,
  finished,
  tooltip,
  icon,
}: StepButtonProps) {
  const theme = useTheme();
  const button = (
    <Stack direction="row" alignItems={'center'} columnGap="4px">
      <IconButton
        onClick={onClick}
        sx={{ padding: '0px !important' }}
        disabled={tooltip ? false : finished || disabled}
        disableRipple
      >
        <Typography fontSize="13px" fontWeight="500" sx={{ color }}>
          {name}
        </Typography>
      </IconButton>
      {icon}
    </Stack>
  );
  return (
    <Stack
      direction="row"
      justifyContent={'space-around'}
      alignItems={'center'}
    >
      {name && (
        <Stack
          sx={{ bgcolor, padding: '2px 8px' }}
          component="span"
          borderRadius="4px"
          className="ellipsis"
          direction="row"
          alignItems={'center'}
        >
          {menuButton ||
            (tooltip ? (
              <LightTooltip
                title={
                  <Box
                    sx={{
                      '& a': {
                        color: 'white',
                      },
                    }}
                  >
                    {tooltip}
                  </Box>
                }
              >
                {button}
              </LightTooltip>
            ) : (
              button
            ))}
        </Stack>
      )}
      {!name && <Typography fontWeight="700">-</Typography>}
      {showArrow && (
        <ArrowBackRounded
          htmlColor={
            disabled ? theme.palette.grey[25] : theme.palette.base.black
          }
          sx={{ width: '16px', height: '16px' }}
          className="rotate180"
        />
      )}
    </Stack>
  );
}

const enableNextStepAfterDraftSubmission = (
  list: JobContractAttachmentType[]
) => {
  const rejectedCount = list.filter((w) => w.status === 'rejected').length;
  const total = list.length;
  return total > rejectedCount && rejectedCount > 0;
};

const ALLSTEPSFINISHED = 1000000;
function getCurrentStep(contract: CampaignJobContractItemType): number {
  if (contract.status === 'finished') return ALLSTEPSFINISHED;

  for (let i = 0; i < StepNames.length; i++) {
    if (StepNames[i].stepName === 'draft_submission') {
      const files = contract.drafts || [];
      if (files.length > 0 && files.every((w) => w.status === 'accepted')) {
        continue;
      } else {
        return i;
      }
    } else if (
      StepNames[i].stepName === 'post_link' &&
      contract.post_link !== null
    ) {
      // in case we have post_link entered by creator, go to the next step
      const post_link_submitted = !!contract.post_link;
      if (post_link_submitted) {
        continue;
      } else {
        return i;
      }
    } else if (
      // skip step
      contract[StepNames[i]?.stepName as keyof typeof contract] === null
    ) {
      continue;
    } else if (
      !['done', 'paid'].includes(
        contract[StepNames[i]?.stepName as keyof typeof contract]
      )
    ) {
      return i;
    }
  }
  return ALLSTEPSFINISHED;
}

type CampaignProgressTableViewProps = {
  data?: CampaignJobContractItemType[];
  total: number;
  loadPage: (page: number, size: number, queryParams?: string) => void;
  loading: boolean;
  isActiveCampaign: boolean;
};
export default function CampaignProgressTableView({
  data,
  total,
  loadPage,
  loading,
  isActiveCampaign,
}: CampaignProgressTableViewProps) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [queryString, setQueryString] = useState('');
  const headers = useHeadCellsData();
  const shippingStatusOptions = usePatchJobContractShippingStatusOptions();
  const steps = useHiredSteps();
  const colors = useStepColors();
  const productionStates = useMemo(
    () =>
      Object.entries(steps.production.state).map((w) => ({
        ...w[1],
        value: w[0],
      })),
    [steps.production.state]
  );

  const [_patchJobcontract] = usePatchJobContractMutation({});
  const patchJobcontract = async (data: any) => {
    let succeed = false;
    await MutationHandler({
      intl,
      action: async () => {
        return await _patchJobcontract(data).unwrap();
      },
      onSuccess: () => {
        handleChangePage(page);
        succeed = true;
      },
    });
    return succeed;
  };

  const genOptions = useCallback(
    (stepName: string) => {
      const ret =
        stepName === 'shipping_status'
          ? {
              menuOptions: shippingStatusOptions.map((v) => ({
                name: v.label,
              })),
              options: shippingStatusOptions,
            }
          : stepName === 'production'
          ? {
              menuOptions: productionStates.map((w) => ({ name: w.name })),
              options: productionStates,
            }
          : {
              menuOptions: [],
              options: [],
            };
      return ret;
    },
    [productionStates, shippingStatusOptions]
  );

  const getDraftSubmssionState = useCallback(
    (list: JobContractAttachmentType[]) => {
      const state = steps.draft_submission.state;
      const rejectedCount = list.filter((w) => w.status === 'rejected').length;
      const total = list.length;
      return total > 0 && list.every((w) => w.status === 'accepted')
        ? state.approved
        : rejectedCount > 0 && rejectedCount < total
        ? {
            ...state.rejected,
            name: `${state.rejected.name} ${rejectedCount}/${total}`,
          }
        : total === 0
        ? state.no_draft_yet
        : state.pending_approval;
    },
    [steps]
  );

  const skipStepIfUndefined = (v: any, defaultState: string) => {
    return v === undefined || v === null ? '' : v ? v : defaultState;
  };

  const rows = (data || []).map((item) => {
    const draftOffer = item.status === 'drafted';
    // const currentStep = isActiveCampaign
    //   ? ALLSTEPSFINISHED
    //   : getCurrentStep(item);
    const currentStep = getCurrentStep(item);
    const items = [
      {
        stepName: 'product_selection',
        state: skipStepIfUndefined(item['product_selection'], 'enter'),
      },
      {
        stepName: 'prepayment',
        state: skipStepIfUndefined(item['prepayment'], 'enter'),
      },
      {
        stepName: 'shipping_status',
        state: skipStepIfUndefined(
          item.shipping_status,
          shippingStatusOptions.find((w) => w.value === item.shipping_status)
            ?.label || 'select_state'
        ),
      },
      {
        stepName: 'production',
        state: skipStepIfUndefined(item['production'], 'select_state'),
      },
      {
        stepName: 'draft_submission',
        // TODO add draft_submission state from drafts
        state: 'select_state',
      },
      {
        stepName: 'post_link',
        state:
          item['post_link'] === null
            ? ''
            : !item['post_link'] || item['post_link'] === '[]'
            ? 'check'
            : 'done',
      },
      {
        stepName: 'payment',
        state: skipStepIfUndefined(item['payment'], 'enter'),
      },
      {
        stepName: 'review',
        state: skipStepIfUndefined(item['review'], 'give'),
      },
    ];

    const draftSubmissionStepState = getDraftSubmssionState(item.drafts || []);
    const allowActionIfHaveApprovedDraft = (stepName: string) => {
      return (
        [
          // TODO do we need to enable the next steps with partial approval?
          /* 'post_link', 'payment' */
          '',
        ].includes(stepName) &&
        enableNextStepAfterDraftSubmission(item.drafts || [])
      );
    };
    const getItemProps = (i: number) => {
      const stepName = items[i]?.stepName;
      const name =
        typeof items[i].state === 'string'
          ? items[i].state
          : stepName === 'review'
          ? intl.formatMessage(Messages.Done)
          : '';
      const stepItem =
        stepName === 'draft_submission'
          ? draftSubmissionStepState
          : steps[stepName]?.state[items[i].state as any] || {
              name: capitalize(name),
              ...colors.done,
            };
      const shipingInfo =
        stepName === 'shipping_status' && !!item.shipping_info?.tracking_no
          ? `${
              item.shipping_info?.shipping_supplier
                ? item.shipping_info?.shipping_supplier + ' '
                : ''
            }${item.shipping_info?.tracking_no || ''}`
          : undefined;
      const shippingIcon = shipingInfo ? (
        <LightTooltip title={shipingInfo}>
          <Typography
            color={stepItem?.color}
            lineHeight={'14px'}
            component="span"
            className="boxcenterhv"
          >
            {item.shipping_info?.tracking_no && (
              <LocalShippingIcon
                htmlColor={stepItem.color}
                sx={{ width: '16px', height: '16px' }}
              />
            )}
          </Typography>
        </LightTooltip>
      ) : (
        <></>
      );
      return {
        icon: shippingIcon,
        ...(stepItem || {}),
        showArrow: i < items.length - 1 && !!items[i + 1]?.state,
        ...(i > currentStep && currentStep >= 0
          ? {
              ...colors.disabled,
              disabled: allowActionIfHaveApprovedDraft(stepName) ? false : true,
            }
          : {}),
        ...(currentStep === ALLSTEPSFINISHED || i < currentStep
          ? { finished: true }
          : {}),
        ...(stepName === 'draft_submission' && i < currentStep
          ? {
              finished: false,
              disabled: false,
            }
          : {}),
        ...(draftOffer ? { disabled: true } : {}),
        onClick: ['product_selection', 'prepayment', 'payment'].includes(
          stepName
        )
          ? () =>
              patchJobcontract({
                contract_id: item.id,
                body: {
                  [stepName]: ['prepayment', 'payment'].includes(stepName)
                    ? 'paid'
                    : 'done',
                },
              })
          : stepName === 'review'
          ? () => setReviewContractId(item.id)
          : stepName === 'draft_submission'
          ? () => setContractId(item.id)
          : NOOP,
        tooltip:
          stepName === 'post_link' && i <= currentStep ? (
            item.post_link ? (
              <Stack>
                {partLinksString(item.post_link || '').map((w, index) => (
                  <Link href={w.link} key={`${w.link}${index}`}>
                    {w.description}
                  </Link>
                ))}
              </Stack>
            ) : (
              intl.formatMessage(Messages.NoLinkPostedYet)
            )
          ) : undefined,
        menuButton:
          i === currentStep &&
          ['shipping_status', 'production'].includes(stepName) ? (
            <IconMenuButton
              icon={
                stepName === 'shipping_status' ? (
                  <Stack>
                    <Typography color={stepItem?.color}>
                      {stepItem?.name}
                    </Typography>
                    {shippingIcon}
                  </Stack>
                ) : (
                  <Typography color={stepItem?.color}>
                    {stepItem?.name}
                  </Typography>
                )
              }
              data={{
                options: genOptions(stepName).menuOptions,
              }}
              onMenuItemChange={(index) => {
                if (
                  genOptions(stepName).options[index].value === 'trackingno'
                ) {
                  setTrackingContractId(item.id);
                } else if (index >= 0) {
                  patchJobcontract({
                    contract_id: item.id,
                    body: {
                      [stepName]: genOptions(stepName).options[index].value,
                    },
                  });
                }
              }}
            />
          ) : undefined,
      };
    };

    return [
      {
        key: SortByKeys[0].key,
        children: (
          <Stack>
            <Link
              href={`${staticURLTiktok}@${(
                item.creator?.unique_id || ''
              ).replace('@', '')}`}
              target="_blank"
            >
              <Typography fontSize={'14px'} fontWeight={500} color="grey.700">
                @{item.creator?.unique_id || ''}
              </Typography>
            </Link>
            <Typography
              fontSize={'12px'}
              fontWeight={500}
              color="grey.700"
              lineHeight={'14px'}
              maxWidth="80px"
              className="ellipsis"
              title={item.creator?.name || ''}
            >
              {item.creator?.name || ''}
            </Typography>
            <Stack direction="row" columnGap="8px" marginTop="4px">
              <Link
                href={`${staticURLMessaging}?id=${item.creator.user_id || ''}`}
                target="_blank"
              >
                <ChatThinIcon
                  stroke={theme.palette.primary[400]}
                  sx={{ width: '16px', height: '16px' }}
                />
              </Link>
              <LightTooltip title={intl.formatMessage(Messages.ReadProposal)}>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setApplicationId(item.campaign_application_id);
                  }}
                >
                  <File2Icon
                    fill={theme.palette.primary[400]}
                    sx={{ width: '16px', height: '16px' }}
                  />
                </Link>
              </LightTooltip>
              <LightTooltip
                title={intl.formatMessage(Messages.PaymentInformation)}
              >
                <Link
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setPaymentContractId(item.id);
                  }}
                >
                  <WalletIcon
                    stroke={theme.palette.primary[400]}
                    sx={{ width: '16px', height: '16px' }}
                  />
                </Link>
              </LightTooltip>
              <LightTooltip title={intl.formatMessage(Messages.ViewOffer)}>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    // TODO view offer and edit offer if creator not yet accepted
                    setCurrentJobcontract(item);
                  }}
                >
                  <ArticleIcon
                    htmlColor={theme.palette.primary[400]}
                    sx={{ width: '16px', height: '16px' }}
                  />
                </Link>
              </LightTooltip>
            </Stack>
          </Stack>
        ),
      },
      ...[1, 2, 3, 4, 5, 6, 7, 8].map((no) => ({
        key: SortByKeys[no].key,
        children: <StepButton {...getItemProps(no - 1)} />,
      })),
    ] as RowDataType[];
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    orderByValue: string
  ) => {
    const isAsc = orderBy === orderByValue && order === 'desc';
    const orderValue = isAsc ? 'asc' : 'desc';
    setOrder(orderValue);
    setOrderBy(orderByValue);
    const qs = combineSortToQueryString(orderValue, orderByValue, queryString);
    loadPage(page, rowsPerPage, qs);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    const qs = combineSortToQueryString(order, orderBy, queryString);
    loadPage(Math.max(1, newPage), rowsPerPage, qs);
  };

  const handleChangeRowsPerPage = (count: number) => {
    setRowsPerPage(count);
    setPage(1);
    const qs = combineSortToQueryString(order, orderBy, queryString);
    loadPage(1, count, qs);
  };

  const handleSearch = (qs: string) => {
    setQueryString(qs);
    // always query from the first page
    setPage(1);
    const qstr = combineSortToQueryString(order, orderBy, qs);
    loadPage(1, rowsPerPage, qstr);
    return;
  };

  const [showPostsOrAttachments, setShowPostsOrAttachments] = useState<
    'post' | 'attachment' | ''
  >('');
  const [currentItem, setCurrentItem] =
    useState<GetCampaignJobContractsReponseType | null>(null);
  const [showPanel, setShowPanel] = useState(false);

  // tracking modal
  const [trackingContractId, setTrackingContractId] = useState('');
  const trackingNo =
    data?.find((w) => w.id === trackingContractId)?.shipping_info
      ?.tracking_no || '';

  const shippingSupplier =
    data?.find((w) => w.id === trackingContractId)?.shipping_info
      ?.shipping_supplier || '';

  // review data
  const [reviewContractId, setReviewContractId] = useState('');
  const reviewData = data?.find((w) => w.id === reviewContractId)?.review;

  // show contract drafts
  const [contractId, setContractId] = useState('');

  // show application details
  const [applicationId, setApplicationId] = useState('');

  // payment info
  const [paymentContractId, setPaymentContractId] = useState('');
  const accountInfo =
    data?.find((w) => w.id === paymentContractId)?.payment_info?.account || '';

  // view offer
  const [currentJobcontract, setCurrentJobcontract] =
    useState<CampaignJobContractItemType | null>(null);

  return (
    <Box>
      {currentItem && showPostsOrAttachments && (
        <ShowCreatorWork
          campaignProgress={currentItem}
          handleClose={(refresh) => {
            setShowPostsOrAttachments('');
            setCurrentItem(null);
            refresh && handleChangePage(page);
            return;
          }}
          showPosts={showPostsOrAttachments === 'post'}
          showAttachments={showPostsOrAttachments === 'attachment'}
        />
      )}
      <SOPSearch
        handleSearch={handleSearch}
        filterType="campaign-progress"
        searchInputPlaceholder={intl.formatMessage(Messages.CreatorSearchHint)}
      />
      <BenaRefreshPanel onRefresh={() => handleChangePage(page)} />
      <TableContainer className={classes.tableContainer}>
        {loading && (
          <Box className={`boxcenterhv ${classes.loading}`}>
            <InfiniteScrollLoaderMessage />
          </Box>
        )}
        <Table aria-labelledby="campaign progress" size={'medium'}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  hover
                  onClick={(event) => {
                    return; /* handleClick(event, row.name) */
                  }}
                  tabIndex={-1}
                  key={`${index}_${row}`}
                >
                  {row.map((col, index) => {
                    const { width, color, dropdown, align } = headers[index];
                    return (
                      <TableCell
                        key={col.key}
                        padding="none"
                        align={align || 'left'}
                        sx={{
                          maxWidth: width,
                          color: color || theme.custom.black2,
                        }}
                        className={classes.tabelCell}
                      >
                        {col.children}
                        {dropdown && (
                          <ArrowDownThinIcon
                            stroke={color}
                            sx={{
                              width: '0.875rem',
                              height: '0.5625rem',
                              ml: '0.25rem',
                            }}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!rows.length && <Box sx={{ height: '2rem' }}></Box>}
      </TableContainer>
      <BenaPagination
        count={Math.ceil(total / rowsPerPage)}
        currentPage={page}
        onChange={handleChangePage}
        loading={loading}
      />
      <EnterTrackingNumber
        initSupplier={shippingSupplier}
        initText={trackingNo}
        initOpen={!!trackingContractId}
        handlePatch={async (text: string, supplier: string) => {
          return await patchJobcontract({
            contract_id: trackingContractId,
            body: {
              shipping_info: {
                tracking_no: text,
                shipping_supplier: supplier,
              },
            },
          });
        }}
        isValidForSave={(text: string) => true}
        onCancel={() => setTrackingContractId('')}
      />
      <ReviewCreator
        initReview={
          typeof reviewData === 'object' && reviewData
            ? (reviewData as ReviewType)
            : undefined
        }
        initOpen={!!reviewContractId}
        handlePatch={async (data) => {
          return await patchJobcontract({
            contract_id: reviewContractId,
            body: {
              review: data,
              status: 'finished',
              finished_reason: 'reviewed by brand',
            },
          });
        }}
        onCancel={() => setReviewContractId('')}
      />
      <UploadCampaignWork
        jobContractId={contractId}
        open={!!contractId}
        setClose={() => {
          setContractId('');
          handleChangePage(page);
        }}
      />
      {applicationId && (
        <ApplicationViewPage
          show={!!applicationId}
          hideDialog={(submitted: boolean) => {
            setApplicationId('');
          }}
          applicationId={applicationId}
        />
      )}
      <EnterPaymentInfo
        initText={accountInfo}
        initOpen={!!paymentContractId}
        handlePatch={async (text: string) => {
          return await patchJobcontract({
            contract_id: paymentContractId,
            body: {
              payment_info: {
                account: text,
              },
            },
          });
        }}
        isValidForSave={(text: string) => !!text}
        onCancel={() => setPaymentContractId('')}
      />
      {currentJobcontract && (
        <DialogPage
          initOpen={!!currentJobcontract}
          children={
            <HireCreator
              creatorId={currentJobcontract.creator_id}
              campaignId={currentJobcontract.campaign_id}
              applicationId={currentJobcontract.campaign_application_id}
              onClose={() => {
                setCurrentJobcontract(null);
                loadPage(page, rowsPerPage);
              }}
              jobcontractId={currentJobcontract.id}
            />
          }
        />
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCell: {
      fontSize: '0.875rem',
      fontWeight: '400',
      color: theme.palette.grey[700],
      lineHeight: '1.5rem',
      marginBottom: '1rem',
    },
    tableContainer: {
      padding: '1rem 1.5rem',
      borderRadius: '0.5rem',
      backgroundColor: theme.palette.base.white,
      position: 'relative',
      border: `1px solid ${theme.custom.primary5}`,
    },
    sortIconSize: {
      width: '0.75rem',
      height: '0.75rem',
    },
    tabelCell: {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.125rem',
      padding: '1.5rem 0rem',
    },
    loading: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundColor: Color(theme.palette.base.white).fade(0.2).toString(),
      zIndex: 900,
    },
    centerA: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  })
);
