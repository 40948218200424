import { Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useBreakpointXs } from '../utils/useStyleHooks';

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

type PdfPreviewProps = {
  file: string;
};
export default function PdfPreview({ file }: PdfPreviewProps) {
  const theme = useTheme();
  const [numPages, setNumPages] = useState<number | null>(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const isMobile = useBreakpointXs();

  return (
    <Box border={`1px dotted ${theme.palette.neutral[600]}`} padding={'16px'}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<img src="/loading.gif" alt="PDF file is loading" />}
        options={options}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={isMobile ? 300 : undefined}
          />
        ))}
      </Document>
    </Box>
  );
}
