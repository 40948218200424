import {
  IconButton,
  useTheme,
  Stack,
  Avatar,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  FavoriteOutlinedIcon,
  RefreshIcon,
  TrashIcon,
} from '../../assets/imgs/icons';
import LightTooltip from '../../components/LightTooltip';
import LoadingDotsSvg from '../../components/LoadingDotsSvg';
import { useAuthUserAvatar, useIsApiLoading } from '../../hooks/account';
import { usePatchAIGCScript } from '../../hooks/aigc';
import { useThemeMode } from '../../hooks/theme';
import { Messages } from '../../localization/Messages';

type AIGCFavoriteIconButtonProps = {
  story: CreatorVideoScriptResponseType;
};
export function AIGCFavoriteIconButton({ story }: AIGCFavoriteIconButtonProps) {
  const intl = useIntl();
  const loading = useIsApiLoading();
  const theme = useTheme();
  const { handleFavorite, likeFavorite } = usePatchAIGCScript(story);
  return (
    <LightTooltip
      title={intl.formatMessage(Messages.TooltipFavoriteThisScript)}
    >
      <IconButton onClick={handleFavorite} disabled={loading}>
        <FavoriteOutlinedIcon
          sx={{ width: '1rem', height: '1rem' }}
          fill={
            likeFavorite?.favorite ? theme.palette.warning[500] : 'transparent'
          }
          stroke={theme.palette.warning[500]}
          fade={false}
        />
      </IconButton>
    </LightTooltip>
  );
}

type AIGCDeleteButtonProps = {
  story: CreatorVideoScriptResponseType;
  onStoryDelete: fnStringToVoid;
};
export function AIGCDeleteButton({
  story,
  onStoryDelete,
}: AIGCDeleteButtonProps) {
  const intl = useIntl();
  const theme = useTheme();
  const loading = useIsApiLoading();
  return (
    <LightTooltip title={intl.formatMessage(Messages.TooltipDeleteThisScript)}>
      <IconButton
        onClick={() => {
          onStoryDelete(story.id);
        }}
        disabled={loading}
      >
        <TrashIcon
          sx={{ width: '1rem', height: '1rem' }}
          stroke={theme.palette.danger[700]}
        />
      </IconButton>
    </LightTooltip>
  );
}

type StoryAvatarPanelProps = {
  story: CreatorVideoScriptResponseType;
  showRefreshButton: boolean;
  onStoryDelete: fnStringToVoid;
  generateScriptPost?: () => Promise<void>;
};
export function StoryAvatarPanel({
  story,
  showRefreshButton,
  onStoryDelete,
  generateScriptPost
}: StoryAvatarPanelProps) {
  const isDarkMode = useThemeMode() === 'dark';
  const intl = useIntl();
  const theme = useTheme();
  const loading = useIsApiLoading();
  const [currentGenerating, setCurrentGenerating] = useState<'all' | ''>('');
  useEffect(() => {
    setCurrentGenerating('');
  }, [story]);

  return (
    <Stack
      direction="row"
      padding="1rem 2.5rem 0rem 0.5rem"
      alignItems={'center'}
      spacing={'0.5rem'}
    >
      <Avatar
        src={isDarkMode ? '/logo-round-white.svg' : '/logo-round.svg'}
        sx={{
          marginTop: '0.5rem',
          background: theme.palette.primary[25],
        }}
        imgProps={{
          sx: {
            objectFit: 'contain',
            width: '16px',
            height: '16px',
          },
        }}
      />
      <Typography fontSize={'0.9375rem'} color="primary.950" fontWeight={500}>
        {intl.formatMessage(Messages.BenaAI)}
      </Typography>
      <Box flexGrow={1}></Box>
      <AIGCFavoriteIconButton story={story} />

      <AIGCDeleteButton story={story} onStoryDelete={onStoryDelete} />
      {showRefreshButton && (
        <LightTooltip
          title={intl.formatMessage(Messages.TooltipRegenerateWholeScript)}
        >
          <IconButton
            onClick={() => {
              generateScriptPost?.();
              setCurrentGenerating('all');
            }}
            disabled={loading}
          >
            {loading && currentGenerating === 'all' ? (
              <LoadingDotsSvg
                animate={true}
                color={theme.palette.primary.main}
                width={16}
              />
            ) : (
              <RefreshIcon sx={{ width: '1rem', height: '1rem' }} />
            )}
          </IconButton>
        </LightTooltip>
      )}
    </Stack>
  );
}

type ScriptQueryInfoPanelProps = {
  story: CreatorVideoScriptResponseType;
};
export function ScriptQueryInfoPanel({ story }: ScriptQueryInfoPanelProps) {
  const { product_category, content_type, content_idea } = story;
  const intl = useIntl();
  const theme = useTheme();
  const avatar = useAuthUserAvatar();
  const isDarkMode = useThemeMode() === 'dark';
  return (
    <Stack paddingRight={'24px'}>
      <Stack alignItems={'flex-end'}>
        <Stack direction="row" alignItems={'center'} paddingTop="1rem">
          <Typography
            fontSize={'0.9375rem'}
            color="primary.950"
            fontWeight={500}
          >
            {intl.formatMessage(Messages.You)} &nbsp;&nbsp;
          </Typography>
          <Box
            sx={{
              padding: '12px',
              flexShrink: 0,
              flexGrow: 0,
              background: theme.palette.primary[25],
              borderRadius: '50%',
            }}
            className="boxcenterhv"
          >
            <Avatar
              src={
                avatar ||
                (isDarkMode ? '/imgs/user-white.svg' : '/imgs/user.svg')
              }
              sx={{
                width: '16px',
                height: '16px',
              }}
            ></Avatar>
          </Box>
        </Stack>
      </Stack>
      <Box
        bgcolor={theme.palette.primary[800]}
        borderRadius="10px 4px 10px 10px"
        margin="4px 32px 16px 54px"
      >
        <Stack padding="20px 24px">
          <Stack direction={'row'} spacing="4rem">
            <Stack>
              <Typography color="primary.50" fontWeight={500} fontSize="15px">
                {intl.formatMessage(Messages.ProductCategory)}
              </Typography>
              <Typography color="primary.200" fontSize="13px">
                {product_category}
              </Typography>
            </Stack>
            <Stack>
              <Typography color="primary.50" fontWeight={500} fontSize="15px">
                {intl.formatMessage(Messages.ContentType)}
              </Typography>
              <Typography color="primary.200" fontSize="13px">
                {content_type}
              </Typography>
            </Stack>
          </Stack>
          <Divider
            sx={{
              my: 1,
              borderColor: theme.palette.primary['700_2'],
            }}
          />
          <Stack>
            <Typography color="primary.50" fontWeight={500} fontSize="15px">
              {intl.formatMessage(Messages.ContentDescription)}
            </Typography>
            <Typography color="primary.200" fontSize="13px">
              {content_idea}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
