import { ArrowBackRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import CampaignCardHorizontal from '../components/CampaignCardHorizontal';
import { Fragment, useEffect, useState } from 'react';
import TitledPanel from '../components/TitledPanel';
import HoverableImage from '../components/HoverableImage';
import FlexSpaceAroundLeftAligned from '../../components/FlexSpaceBetweenLeftAligned';
import {
  useGetCampaignDetailQuery,
  usePatchJobContractMutation,
} from '../../redux/benaApi';
import moment from 'moment';
import { Messages } from '../../localization/Messages';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { useGetJobContract } from '../../hooks/jobcontract';
import { StepNames } from '../../features/sop/CampaignProgressTableView';
import { capitalize } from 'lodash';
import { useGlobalStyles } from '../../assets/styles/style';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { showMessage } from '../../components/ShowMessage';
import { DeclineOfferConfirmation } from './DeclineOfferConfirmation';

type CreatorCampaignOfferDetailsProps = {
  campaignId: string;
  jobContract?: CampaignJobContractItemType | null;
  onClose: fnVoidToVoid;
  onAcceptOfferSucceed?: fnVoidToVoid;
};
export default function CreatorCampaignOfferDetails({
  campaignId,
  jobContract,
  onClose,
  onAcceptOfferSucceed,
}: CreatorCampaignOfferDetailsProps) {
  const theme = useTheme();
  const intl = useIntl();
  const gs = useGlobalStyles();
  const isMobileView = useBreakpointXs();
  const [patchJobcontract] = usePatchJobContractMutation();

  const [open, setOpen] = useState(false);
  const { data: campaign, refetch } = useGetCampaignDetailQuery({
    campaign_id: campaignId,
  });
  const campaignData = campaign?.data;

  useEffect(() => {
    refetch();
  }, [refetch]);

  const {
    data: jobcontractData,
    refetch: refreshJobcontract,
    loading: jobcontractLoading,
  } = useGetJobContract(jobContract?.id || '');

  useEffect(() => {
    refreshJobcontract(jobContract?.id || '');
  }, [jobContract]);

  if (!campaignData || !jobcontractData) return null;

  const campaignInfo = {
    platformIds: campaignData.platforms as SocialPlatformId[],
    launchedOn: moment(campaignData.start_date).format(`M/DD/YY`),
    logo: campaignData.brand_logo,
    title: campaignData.title,
    image: campaignData.cover_img,
    brandInfo: campaignData.brand_intro,
    pageUrl: campaignData.brand_link,
    campaignPeriod: `${moment(campaignData.start_date).format(
      `M/DD/YY`
    )} - ${moment(campaignData.end_date).format(`M/DD/YY`)}`,
    budget: `$${campaignData.budget_amount}`,
    budget_max: campaignData.budget_amount_max
      ? `$${campaignData.budget_amount_max}`
      : undefined,
    freeProduct: jobcontractData.budget_mode === 'product_exchange_only',
    commission: jobcontractData.commission ? 'yes' : 'no',
    commissionPercent: jobcontractData.commission_percent,
    products: campaignData.products || [],
  };

  const contentDirections = campaignData.content_direction;
  const caption = campaignData.captions;
  const videoDuration = campaignData.video_duration;
  const hashtags = campaignData.hashtags.join(', ');
  const otherAccounts = campaignData.tag_accounts.join(', ');

  const patchOffer = async (status: 'rejected' | 'accepted', reason = '') => {
    await MutationHandler({
      intl,
      action: async () => {
        return await patchJobcontract({
          contract_id: jobContract?.id || '',
          body: {
            status: status,
            ...(reason === '' ? {} : { decline_reason: reason }),
          },
        }).unwrap();
      },
      onSuccess: () => {
        showMessage.success(intl.formatMessage(Messages.successfullySaved));
        if (status === 'rejected') {
          onClose();
        }
        if (status === 'accepted') {
          onAcceptOfferSucceed?.();
        }
      },
    });
  };

  const applyButton = (height = '') => (
    <Stack
      direction={isMobileView ? 'column' : 'row'}
      columnGap="8px"
      rowGap="8px"
    >
      <Button
        sx={{
          height: height || '32px',
          fontWeight: '600',
          fontSize: '14px',
          padding: '8px',
        }}
        variant="contained"
        className={gs.buttonDeletion}
        onClick={() => setOpen(true)}
      >
        {intl.formatMessage(Messages.Decline)}
      </Button>
      <Button
        sx={{
          height: height || '32px',
          fontWeight: '600',
          fontSize: '14px',
          padding: '8px',
        }}
        variant="contained"
        className={gs.buttonPrimary}
        onClick={() => patchOffer('accepted')}
      >
        {intl.formatMessage(Messages.Accept)}
      </Button>
    </Stack>
  );

  const steps = (
    <Stack
      direction="row"
      columnGap="16px"
      rowGap="8px"
      flexWrap="wrap"
      alignItems="center"
    >
      {StepNames.filter((w) => {
        const value = jobcontractData[w.stepName];
        return !(
          [undefined, null].includes(value) && w.stepName !== 'draft_submission'
        );
      }).map((w, index) => {
        return (
          <Fragment key={w.stepName}>
            {index > 0 && (
              <ArrowBackRounded
                htmlColor={theme.palette.base.black}
                sx={{ width: '16px', height: '16px' }}
                className="rotate180"
              />
            )}
            <Typography
              color="grey.700"
              fontSize={'14px'}
              fontWeight={'500'}
              bgcolor="neutral.300"
              padding="4px 12px"
            >
              {w.stepName
                .split('_')
                .map((w) => capitalize(w))
                .join(' ')}
            </Typography>
          </Fragment>
        );
      })}
    </Stack>
  );

  return (
    <>
      <Stack
        marginTop={isMobileView ? '0px' : '48px'}
        border={
          isMobileView ? '0px' : `1px solid ${theme.palette.neutral[600]}`
        }
        borderRadius={'8px 8px 0px 0px'}
        padding={'12px 20px'}
      >
        <Stack direction="row" alignItems={'center'}>
          <IconButton onClick={onClose} disableRipple>
            <ArrowBackRounded
              htmlColor={theme.palette.base.black}
              sx={{ width: '22px', height: '22px' }}
            />
            <Typography fontSize={16} fontWeight={500} color="grey.700">
              {intl.formatMessage(CreatorMessages.GoBack)}
            </Typography>
          </IconButton>
          <Box flexGrow={1}></Box>
          {applyButton()}
        </Stack>
      </Stack>
      <Stack
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderTop="0px"
        borderRadius={'0px 0px 8px 8px'}
        padding={isMobileView ? '16px' : '20px 24px'}
      >
        <Stack rowGap={'27px'}>
          <CampaignCardHorizontal {...campaignInfo} />

          <TitledPanel
            title={intl.formatMessage(Messages.MilestonesForThisCampaign)}
            children={steps}
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.ContentDirection)}
            children={
              <Typography
                sx={{ whiteSpace: 'pre-line', lineHeight: '26px' }}
                color="grey.400"
                fontSize={'14px'}
                fontWeight={400}
              >
                {contentDirections}
              </Typography>
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Caption)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {caption}
              </Typography>
            }
          />
          <Stack
            direction="row"
            columnGap={'128px'}
            rowGap={'16px'}
            flexWrap={'wrap'}
            alignItems={'flex-start'}
          >
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.VideoDurations)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {videoDuration} second{videoDuration > 1 ? 's' : ''}
                </Typography>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.Hashtags)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {hashtags || '-'}
                </Typography>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.OtherAccounts)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {otherAccounts || '-'}
                </Typography>
              }
            />
          </Stack>
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Files)}
            children={
              <Stack
                direction="row"
                flexWrap={'wrap'}
                rowGap="16px"
                columnGap={'16px'}
              >
                {(campaignData.visual_medias || []).length > 0 ? (
                  <>
                    {(campaignData.visual_medias || []).map((w: any) => (
                      <HoverableImage
                        fileLink={w.file}
                        fileName={w.name}
                        fileType={w.content_type}
                        key={w.id}
                        width={isMobileView ? '163px' : '224px'}
                        height={isMobileView ? '163px' : '224px'}
                      />
                    ))}
                    <FlexSpaceAroundLeftAligned
                      width={isMobileView ? '163px' : '224px'}
                    />
                  </>
                ) : (
                  <Typography
                    fontWeight={400}
                    color="grey.400"
                    fontSize={'14px'}
                  >
                    {intl.formatMessage(Messages.NoFileFound)}
                  </Typography>
                )}
              </Stack>
            }
          />
        </Stack>
        <Box height="16px"></Box>
        {isMobileView && applyButton('48px')}
      </Stack>
      <Box height="2rem"></Box>
      <DeclineOfferConfirmation
        initText={''}
        initOpen={open}
        handlePatch={async (text: string) => {
          await patchOffer('rejected', text);
        }}
        isValidForSave={(text: string) => !!text}
        onCancel={() => setOpen(false)}
      />
    </>
  );
}
