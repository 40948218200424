import { Box, LinearProgress } from '@mui/material';
import { useTypedSelector } from '../redux/store';

export function BenaLoadingInProgress() {
  const loadingInProgress = useTypedSelector(
    (state) => state.auth
  ).loadingInProgress;

  return loadingInProgress ? (
    <Box
      sx={{
        width: '100%',
        height: '2px',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 2147481000,
      }}
    >
      <LinearProgress sx={{ height: '2px' }} color="info" />
    </Box>
  ) : (
    <></>
  );
}
