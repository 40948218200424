import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  staticURLCreatorJobs,
  staticURLMessaging,
  staticURLMyProfile,
  staticURLRoot,
} from '../../route/urls';
import { useIntl } from 'react-intl';
import {
  useIsRoleBrand,
  useIsRoleCreator,
  useLogout,
} from '../../hooks/account';
import {
  Stack,
  Badge,
  List,
  ListItemButton,
  ListItemText,
  Theme,
} from '@mui/material';
import {
  Home2Icon,
  JobsIcon,
  ChatThinIcon,
  Setting2Icon,
  User2Icon,
} from '../../assets/imgs/icons';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { useGlobalStyles } from '../../assets/styles/style';
import { Messages } from '../../localization/Messages';
import { useTypedSelector } from '../../redux/store';
import { UserAccountMessages } from '../account/Messages';
import { selectAuth } from '../../features/account/AuthSlice';
import { createStyles, makeStyles } from '@mui/styles';
import { IMContext } from '../../context/context';
import { useListenTIMMessageCountNotification } from '../rocketchat/RCUnreadMesssagesCount';

const paths = [
  staticURLRoot,
  staticURLCreatorJobs,
  staticURLMessaging,
  staticURLMyProfile,
];

function MobileAppBarBottom() {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useMainbarStyles();
  const navigate = useNavigate();

  const imContext = React.useContext(IMContext);
  useListenTIMMessageCountNotification((num) => {
    imContext.setUnreadCount(num);
  });
  const unreadMesageCount =
    imContext.unreadCount || (window as any).rc_unread_message_count;

  const selectedIndex = paths.findIndex(
    (v) => window.location.pathname.split('?')?.[0] === v
  );
  const highlight = (index: number) =>
    selectedIndex === index
      ? { stroke: theme.palette.primary.main }
      : { stroke: theme.palette.grey[50] };
  const items = [
    {
      icon: <Home2Icon {...highlight(0)} />,
      caption: intl.formatMessage(Messages.Home),
      left: '0%',
      count: 0,
    },
    {
      icon: <JobsIcon {...highlight(1)} />,
      caption: intl.formatMessage(Messages.Jobs),
      left: '20%',
      count: 0,
    },
    {
      icon: (
        <ChatThinIcon
          {...highlight(2)}
          sx={{
            width: '26px',
            height: '20px',
            marginTop: '2px',
            marginBottom: '2px',
          }}
        />
      ),
      caption: intl.formatMessage(Messages.Messages),
      left: '40%',
      count: unreadMesageCount,
    },
    {
      icon: <Setting2Icon {...highlight(3)} />,
      caption: intl.formatMessage(Messages.Settings),
      left: '60%',
      count: 0,
    },
  ];
  return (
    <AppBar
      position={'fixed'}
      color="default"
      className={classes.mobileAppBarBottom}
      sx={{
        boxShadow: 0,
        display: 'block',
      }}
    >
      <Toolbar
        className="boxcenterhv"
        sx={{ justifyContent: 'space-around', height: '100%', padding: 0 }}
      >
        {items.map((item, index) => (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            key={item.caption}
            onClick={() => navigate(paths[index])}
            sx={{
              width: 100 / items.length + '%',
              height: '100%',
              position: 'relative',
              background:
                selectedIndex === index
                  ? `linear-gradient(180deg, ${theme.palette.primary.main15} 16.67%, ${theme.palette.base.white} 100%);`
                  : theme.palette.base.white,
              borderTop:
                selectedIndex === index
                  ? `2px solid ${theme.palette.primary.main}`
                  : `2px solid ${theme.palette.base.white}`,
            }}
          >
            {item.count ? (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  margin: '12px 0px 4px 0px',
                }}
              >
                <Badge
                  badgeContent={item.count}
                  color="error"
                  className={classes.badgeBorder}
                >
                  {item.icon}
                </Badge>
              </Box>
            ) : (
              <IconButton
                size="large"
                color="inherit"
                sx={{ padding: '12px 0px 4px 0px !important' }}
              >
                {item.icon}
              </IconButton>
            )}
            <p
              className={classes.badgeTitle}
              style={{
                color:
                  selectedIndex === index
                    ? theme.palette.primary.main
                    : theme.custom.black,
                cursor: 'pointer',
                textAlign: 'center',
              }}
            >
              {item.caption}
            </p>
          </Stack>
        ))}
      </Toolbar>
    </AppBar>
  );
}

type SideMenuProps = {
  hide: fnVoidToVoid;
};
export function SideMenu({ hide }: SideMenuProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gc = useGlobalStyles();
  const classes = useMainbarStyles();
  const navigate = useNavigate();
  const user = useTypedSelector((state) => selectAuth(state)).user;
  const links = [
    {
      text: intl.formatMessage(Messages.Home),
      link: staticURLRoot,
    },
    {
      text: intl.formatMessage(UserAccountMessages.userProfile),
      link: staticURLMyProfile,
    },
    {
      text: intl.formatMessage(Messages.Jobs),
      link: staticURLCreatorJobs,
    },
    {
      text: intl.formatMessage(Messages.Messages),
      link: staticURLMessaging,
    },
  ];

  const [handleLogout] = useLogout();

  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        zIndex: theme.zIndex.modal,
        padding: '0 !important',
      }}
      className={`${gc.screenCover}`}
      onClick={hide}
    >
      <Box className={classes.sideMenuContainer}>
        <List component="nav">
          {user && (
            <Box
              sx={{
                padding: '0px 16px 2px',
                position: 'relative',
                borderBottom: `1px solid ${theme.custom.primary4}`,
              }}
              className="boxlefthcenterv "
            >
              {user.avatar ? (
                <Avatar
                  alt={user.name}
                  src={user.avatar || '/imgs/user.svg'}
                  sx={{ mr: '0.5rem' }}
                />
              ) : (
                <User2Icon
                  stroke={theme.palette.grey.black_white}
                  sx={{
                    mr: '0.5rem',
                    width: '32px',
                    height: '32px',
                  }}
                />
              )}
              <ListItemText
                primary={user.name || ''}
                primaryTypographyProps={{
                  className: classes.sideMenuContainerMenuAvatarName,
                }}
                secondary={(user.country_code || 'us').toUpperCase()}
              />
            </Box>
          )}
          <Box sx={{ height: '1rem' }}></Box>
          {links.map((link) => {
            return (
              <ListItemButton
                onClick={() => navigate(link.link)}
                key={link.text}
              >
                <ListItemText
                  primary={
                    <Typography className={classes.sideMenuContainerMenuText}>
                      {link.text}
                    </Typography>
                  }
                  sx={{ ml: 2 }}
                />
              </ListItemButton>
            );
          })}
          <ListItemButton onClick={()=>handleLogout()}>
            <ListItemText
              primary={
                <Typography className={classes.logout}>
                  {intl.formatMessage(Messages.logoutMenu)}
                </Typography>
              }
              sx={{ ml: 2 }}
            />
          </ListItemButton>
        </List>

        <Box sx={{ flexGrow: 1 }}></Box>
      </Box>
    </Box>
  );
}

type PageMobileNavigationBarAndTitleBarProps = {
  showTop?: boolean;
};
export function PageMobileNavigationBarAndTitleBar({
  showTop = true,
}: PageMobileNavigationBarAndTitleBarProps) {
  const isUserCreator = useIsRoleCreator();
  const isUserBrand = useIsRoleBrand();

  // do not use mobile view for brand user
  const isMobileView =
    useBreakpointXs() && ((!isUserBrand && !isUserCreator) || isUserCreator);

  return isMobileView ? <MobileAppBarBottom /> : <></>;
}

export const useMainbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '0.5rem',
      width: '118px',
      height: '40px',
      fontSize: '0.875rem',
    },
    badgeBorder: {
      '& > span': {
        border: '2px solid white',
      },
    },
    badgeTitle: {
      marginTop: '3px',
      marginBottom: '13px',
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: '500',
    },
    bar: {
      width: '100%',
      height: '0.3125rem',
      backgroundColor: theme.custom.black,
      borderRadius: '6.25rem',
      position: 'absolute',
      bottom: '0.3125rem',
    },
    mobileAppBarBottom: {
      top: 'auto',
      height: '4rem',
      bottom: 0,
      zIndex: 5,
      backgroundColor: theme.palette.base.white,
      px: '1rem',
    },
    sideMenuContainer: {
      width: '15.625rem',
      backgroundColor: theme.palette.base.white,
      maxHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    sideMenuContainerMenuText: {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.5625rem',
    },
    sideMenuContainerMenuAvatarName: {
      fontSize: '0.875rem',
      fontWeight: '600',
      lineHeight: '2rem',
    },
    logout: {
      fontSize: '0.875rem',
      fontWeight: '600',
      lineHeight: '1.375rem',
      color: theme.custom.lightRed,
    },
  })
);
