import {
  Box,
  Button,
  IconButton,
  OutlinedInput,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGlobalStyles } from '../assets/styles/style';
import closeSvg from '../assets/imgs/close.svg';
import { InputErrorMessage } from './InputErrorMessage';
import { usePostMessageCreatorMutation } from '../redux/benaApi';
import { MutationHandler } from '../redux/benaApiResultHandler';
import { showMessage } from './ShowMessage';
import { Messages } from '../localization/Messages';

type SendMessageModalProps = {
  fromId: string;
  toId: string;
  setNullData: fnBooleanToVoid;
  initTitle: string;
  showMainWindowScrollbar?: boolean;
};

export function SendMessageModal({
  fromId,
  toId,
  setNullData,
  initTitle,
  showMainWindowScrollbar = true,
}: SendMessageModalProps) {
  const theme = useTheme();
  const intl = useIntl();
  const gc = useGlobalStyles();
  const handleClose = (refresh = false) => {
    setNullData(refresh);
  };

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  const [messageCreator] = usePostMessageCreatorMutation({});
  const handleSendMessage = async () => {
    setSubmitting(true);
    const ret = await MutationHandler({
      intl,
      action: async () =>
        await messageCreator({
          creator_id: toId,
          msg: message,
        }).unwrap(),
      onSuccess: () => {
        showMessage.success(
          intl.formatMessage(Messages.messageHasBeenSentSuccessfully)
        );
      },
    });
    setSubmitting(false);
    return !ret;
  };

  const msgRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    msgRef.current?.focus();
  }, []);

  return (
    <Box
      sx={{ zIndex: theme.zIndex.modal }}
      className={`boxcenterhv ${gc.screenCover}`}
    >
      <Box
        sx={{
          width: '37.5rem',
          minHeight: '20rem',
          bgcolor: theme.palette.base.white,
          maxHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
        className="border-radius-8px"
      >
        <Box
          sx={{
            width: '100%',
            my: '1.25rem',
            pb: '0.5rem',
            px: '1.625rem',
            borderBottom: `0.0625rem solid ${theme.custom.primary5}`,
          }}
          className="boxcenterhv"
        >
          <Typography className={gc.font18px600Black}>{initTitle}</Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          <IconButton onClick={() => handleClose()}>
            <img
              src={closeSvg}
              alt=""
              style={{
                width: '1rem',
                height: '1rem',
                margin: 0,
                padding: 0,
                float: 'right',
              }}
              className="cursorHand"
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: '100%',
            px: '1.625rem',
          }}
        >
          <OutlinedInput
            inputRef={msgRef}
            placeholder={intl.formatMessage(Messages.enterMessage)}
            className={`border-radius-6px`}
            value={message}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const message = event.target.value;
              setMessage(message);
              setError(!message);
            }}
            error={error}
            multiline={true}
            rows={6}
            sx={{ width: '100%' }}
          />
          <Box sx={{ height: '2rem' }}>
            <InputErrorMessage error={error} inputValueType={'text'} />
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box
          sx={{
            px: '1.625rem',
            width: '100%',
            mb: '1.5rem',
            borderTop: `0.0625rem solid ${theme.custom.primary5}`,
            pt: '1rem',
          }}
          className="boxcenterhv"
        >
          <Button
            sx={{ width: '8.25rem', height: '2.5rem' }}
            className={`border-radius-8px`}
            onClick={async () => {
              const succeed = await handleSendMessage();
              succeed && handleClose(false);
            }}
            disabled={submitting || !message || error}
          >
            {intl.formatMessage(Messages.send)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
