import { Box, useTheme } from '@mui/material';
import LoadingDotsSvg from './LoadingDotsSvg';

type LoadingCoverProps = {
  loading: boolean;
};
export default function LoadingCover({ loading }: LoadingCoverProps) {
  const theme = useTheme();
  return loading ? (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.7)',
      }}
      position="absolute"
      zIndex={1}
      className="boxcenterhv"
    >
      <LoadingDotsSvg
        color={theme.palette.primary.main}
        width={24}
        animate={true}
      />
    </Box>
  ) : (
    <></>
  );
}
