import { Button, useTheme } from '@mui/material';
import Color from 'color';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { useDispatch } from 'react-redux';
import { usePatchUserMutation } from '../../redux/benaApi';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { useTypedSelector } from '../../redux/store';
import { selectAuth, setUser } from '../account/AuthSlice';
import { setSelectedCreatorId } from '../aigc/AIGCSlice';
import { useJoyrideBrandHomepageSteps, JoyrideTooltip } from './JoyrideTooltip';

type BenaJoyRideProps = {
  loaded: boolean;
  demoCreatorId: string;
};
export default function BenaJoyRide({
  loaded,
  demoCreatorId,
}: BenaJoyRideProps) {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => selectAuth(state)).user;
  const JoyrideBrandHomepageSteps = useJoyrideBrandHomepageSteps();

  const showAIGC = () => {
    demoCreatorId && dispatch(setSelectedCreatorId(demoCreatorId));
  };
  const hideAIGC = () => {
    (
      document.querySelector('.button_hide_aigc_panel') as HTMLButtonElement
    )?.click();
  };

  const [patchUser] = usePatchUserMutation({});
  const handleCompleteTour = async () => {
    await MutationHandler({
      intl,
      action: async () => {
        return await patchUser({
          tour_complete: true,
        }).unwrap();
      },
      onSuccess: (data) => {
        dispatch(setUser(data));
      },
    });
  };
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
  const [joyrideRun, setJoyrideSRun] = useState(true);
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (action === ACTIONS.START) {
      hideAIGC();
    } else if (
      [`${EVENTS.STEP_AFTER}`, `${EVENTS.TARGET_NOT_FOUND}`].includes(type)
    ) {
      if (joyrideStepIndex === 4) {
        showAIGC();
        setTimeout(() => {
          setJoyrideStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        }, 1000);
      } else {
        setJoyrideStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      }
    } else if ([`${STATUS.FINISHED}`, `${STATUS.SKIPPED}`].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.6
      setJoyrideSRun(false);
      handleCompleteTour();
      hideAIGC();
    }
  };

  return !user?.tour_complete && loaded ? ( // 
    <>
      <Joyride
        steps={JoyrideBrandHomepageSteps}
        run={joyrideRun} // 
        continuous={true}
        disableScrolling={true}
        hideBackButton={true}
        hideCloseButton={true}
        showSkipButton={true}
        tooltipComponent={JoyrideTooltip}
        callback={handleJoyrideCallback}
        stepIndex={joyrideStepIndex}
        styles={{
          options: {
            overlayColor: Color(theme.custom.black).fade(0.3).toString(),
            backgroundColor: Color(theme.custom.black).fade(0.9).toString(),
            zIndex: 65535,
            arrowColor: theme.palette.base.white,
          },
        }}
      />
      <Button
        id="ReactJoyrideResetButton"
        sx={{ visibility: 'hidden', width: 0, height: 0 }}
        onClick={() => {
          setJoyrideSRun(false);
          setTimeout(() => {
            setJoyrideStepIndex(0);
            setJoyrideSRun(true);
          }, 300);
        }}
      ></Button>
    </>
  ) : (
    <></>
  );
}
