import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { ArrowRightIcon, LanguageIcon } from '../../assets/imgs/icons';
import { useLanguage } from '../../hooks/language';
import { countryLanguages } from '../language/countries';
import { setLang } from '../language/LanguageSlice';

export default function LanguageMenuItem() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const lang = useLanguage();

  const handleClick = () => {
    let index = countryLanguages.findIndex((w) => w.lang === lang);
    index = index === countryLanguages.length - 1 ? 0 : index + 1;
    index >= 0 && dispatch(setLang({ languageIndex: index }));
  };
  const arrow = (
    <ArrowRightIcon
      stroke={theme.palette.grey.black_white}
      sx={{ width: '1rem', height: '1rem' }}
    />
  );
  const size = { width: '1.25rem', height: '1.25rem' };

  const currentLangIndex = countryLanguages.findIndex((w) => w.lang === lang);
  const nextLangIndex =
    currentLangIndex >= countryLanguages.length - 1 ? 0 : currentLangIndex + 1;

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <LanguageIcon stroke={theme.palette.grey.black_white} sx={size} />
      </ListItemIcon>
      <ListItemText>
        <Stack component="span" alignItems={'center'} direction="row">
          {countryLanguages[currentLangIndex].langName}&nbsp;&nbsp;{arrow}{' '}
          &nbsp;&nbsp;
          <Typography color={theme.palette.grey[25]}>
            {countryLanguages[nextLangIndex].langName}&nbsp;&nbsp;
          </Typography>
        </Stack>
      </ListItemText>
    </MenuItem>
  );
}
