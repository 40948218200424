import {
  Box,
  Button,
  capitalize,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CheckIcon,
  EmailRedirectionIcon,
  ScriptsIcon,
  DMIcon
} from '../../assets/imgs/icons';
import { useIntl, IntlShape } from 'react-intl';
import { Messages } from '../../localization/Messages';
import { Fragment, useEffect, useState } from 'react';
import { useGlobalStyles } from '../../assets/styles/style';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import PlanTypeSelection from '../../components/PlanTypeSelection';
import STabs from '../../components/STabs';
import {
  useGetUserStripePlan,
  useOpenStripePortal,
  useSubscribePlan,
} from '../../hooks/stripe';
import { useLanguage } from '../../hooks/language';
import { staticURLMyProfile } from '../../route/urls';
import { StripeCardPayment } from '../../components/StripeCardPayment';
import { DateFormatOptions } from '../../utils/common';
import { useTypedSelector } from '../../redux/store';
import { selectAuth } from './AuthSlice';
import {
  useGetUserShopifyPlan,
  useIsShopifyUser,
  useShopifyCancelSubscription,
  useShopifySubscription,
} from '../../hooks/shopify';
import Confirm from '../../components/Confirm';

type TimeUnitType = 'perYear' | 'perMonth';
const PlanOptions:{
  id: string;
  name: string;
  planType: string; // 'plugin' | 'starter' | 'professional'
  price: string;
  unitLabel: TimeUnitType;
  unit: 'month' | 'year';
  features: {
    desc: string[][];
  };
  email: number;
  script: number;
  dm: number;
  creditsLabel: 'creditsPerMonth' | 'creditsPerYear';
}[] = [
  
  {
    id: 'plugin_monthly',
    name: 'Bena Plugin Plan',
    planType: 'plugin',
    price: '49.99',
    unitLabel: 'perMonth',
    unit: 'month',
    features: {
      desc:[ ["Plugin:","Full access to creators stats on TikTok"] ]
    },
    email: 0,
    script: 20,
    dm: 0,
    creditsLabel: 'creditsPerMonth',
  },
  {
    id: 'plugin_annually',
    name: 'Bena Plugin Plan',
    planType: 'plugin',
    price: '42.4',
    unitLabel: 'perYear',
    unit: 'year',
    features: {
      desc:[ ["Plugin:","Full access to creators stats on TikTok"] ]
    },
    email: 0,
    script: 20,
    dm: 0,
    creditsLabel: 'creditsPerYear',
  },
  {
    id: 'starter_monthly',
    name: 'Bena Starter Plan',
    planType: 'starter',
    price: '199',
    unitLabel: 'perMonth',
    unit: 'month',
    features: {
      desc:[
        ["Plugin:","Full access to creators stats on TikTok"],
        ["Influencer Campaign:","Generate up to ","3,000"," personalized emails."],
        ["Content Creation:","Access to ","200"," generations of personalized video scripts, content ideas, and hashtags."]
      ]
    },
    email: 3000,
    script: 200,
    dm: 0,
    creditsLabel: 'creditsPerMonth',
  },
  {
    id: 'starter_annually',
    name: 'Bena Starter Plan',
    planType: 'starter',
    price: '169.1',
    unitLabel: 'perYear',
    unit: 'year',
    features: {
      desc:[
        ["Plugin:","Full access to creators stats on TikTok"],
        ["Influencer Campaign:","Generate up to ","36,000"," personalized emails."],
        ["Content Creation:","Access to ","2,400"," generations of personalized video scripts, content ideas, and hashtags."]
      ]
    },
    email: 36000,
    script: 2400,
    dm: 0,
    creditsLabel: 'creditsPerYear',
  },
  {
    id: 'professional_monthly',
    name: 'Bena Pro Plan',
    planType: 'professional',
    price: '399',
    unitLabel: 'perMonth',
    unit: 'month',
    features: {
      desc:[
        ["Plugin:","Full access to creators stats on TikTok"],
        ["Influencer Campaign:","Generate up to ","7,000"," personalized emails."],
        ["Content Creation:","Access to ","450"," generations of personalized video scripts, content ideas, and hashtags."],
        ["DM AI Reply:","Generate up to with ","5,000"," DM reply, ensuring personalized interaction at scale."]
      ]
    },
    email: 7000,
    script: 450,
    dm: 5000,
    creditsLabel: 'creditsPerMonth',
  },
  {
    id: 'professional_annually',
    name: 'Bena Pro Plan',
    planType: 'professional',
    price: '339.1',
    unitLabel: 'perYear',
    unit: 'year',
    features: {
      desc:[
        ["Plugin:","Full access to creators stats on TikTok"],
        ["Influencer Campaign:","Generate up to ","84,000"," personalized emails."],
        ["Content Creation:","Access to ","5,400"," generations of personalized video scripts, content ideas, and hashtags."],
        ["DM AI Reply:","Generate up to with ","60,000"," DM reply, ensuring personalized interaction at scale."]
      ]
    },
    email: 84000,
    script: 5400,
    dm: 60000,
    creditsLabel: 'creditsPerYear',
  },
];

function updatePlanOptions(
  details: PlanDetailsType,
  options: typeof PlanOptions
) {
  options.forEach((w) => {
    const option = details[w.id as keyof PlanDetailsType];
    if (option) {
      if(w.unit === 'year') {
        w.price = Math.round(option.price / 1.2) / 10 + ""
      }else{
        w.price = `${option.price}`;
      }
    }
  });
}

function useCredits(plan: SubscriptionPlanResponseType | null, intl:IntlShape) {
  const theme = useTheme();
  let billingDemo = {DM: { quota: 0 }, email: { quota: 0 }, script: { quota: 0 }}
  const user = useTypedSelector((state) => selectAuth(state)).user;
  const billing = user?.billing || billingDemo;

  const styleIcon = { width: '32px', height: '32px', color:"transparent" }

  return [
    {
      icon: <EmailRedirectionIcon stroke={theme.palette.base[50]} sx={styleIcon} />,
      name: intl.formatMessage(Messages.remainingEmails),
      value: billing.email.quota
    },
    {
      icon: <ScriptsIcon stroke={theme.palette.base[50]} sx={styleIcon} />,
      name: intl.formatMessage(Messages.remainingScript),
      value: billing.script.quota
    },
    {
      icon: <DMIcon fill={theme.palette.base[50]} sx={{ width: '36px', height: '36px', color:"transparent" }} />,
      name: intl.formatMessage(Messages.remainingDM),
      value: billing.DM.quota
    },
  ];
}

export function Billing() {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const lang = useLanguage();
  const isMobileView = useBreakpointXs();
  const isShopifyUser = useIsShopifyUser();
  const { cancelSubscription: cancelShopifySubscription, loading: cancelling } =
    useShopifyCancelSubscription();

  const [plan1Unit, setPlan1Unit] = useState('month');
  const [plan2Unit, setPlan2Unit] = useState('month');
  const [plan3Unit, setPlan3Unit] = useState('month');
  const displayPlans = [
    PlanOptions[plan1Unit === 'month' ? 0 : 1],
    PlanOptions[plan2Unit === 'month' ? 2 : 3],
    PlanOptions[plan3Unit === 'month' ? 4 : 5],
  ];
  
  const stripePlan = useGetUserStripePlan();
  const shopifyPlan = useGetUserShopifyPlan();
  const { currentPlanName, nextBillDueDate, getPlan, plan } = isShopifyUser
    ? shopifyPlan
    : stripePlan;
  
  useEffect(() => {
    const unit = PlanOptions.find((w) => w.planType === currentPlanName)?.unit || 'month';
    let planNames = currentPlanName || '';
    
    if (planNames ==='plugin') {
      setPlan1Unit(unit);
    } else if (planNames === 'starter') {
      setPlan2Unit(unit);
    } else if (planNames === 'professional') {
      setPlan3Unit(unit);
    }
  }, [currentPlanName]);

  const UnitOptions = [
    { label: intl.formatMessage(Messages.monthly), value: 'month' },
    { label: intl.formatMessage(Messages.yearly), value: 'year' },
  ];

  const isFreePlan = currentPlanName === 'free';
  const cancelledAt = plan?.subscription?.cancel_at
    ? intl.formatDate(plan.subscription.cancel_at * 1000, DateFormatOptions)
    : undefined;
  const { subscribe: _subscribe } = useSubscribePlan();
  const { openPortal: _openPortal } = useOpenStripePortal();
  const { subscribeShopify: _subscribeShopify } = useShopifySubscription();
  useEffect(() => {
    getPlan({});
  }, []);

  useEffect(() => {
    if (plan?.plan_details) {
      updatePlanOptions(plan.plan_details, PlanOptions);
    }
  }, [plan]);

  const params = {
    success_redirect_path: staticURLMyProfile,
    cancel_redirect_path: staticURLMyProfile,
    locale: lang,
  };
  const generatePlanId = (planIndex: number) => {
    const index =
      planIndex === 0
        ? plan1Unit === 'month'
          ? 0
          : 1
        : planIndex === 1
        ? plan2Unit === 'month'
          ? 2
          : 3
        : planIndex === 2
        ? plan2Unit === 'month'
          ? 4
          : 5
        : -1;
    return PlanOptions[index]?.id || '';
  };
  const subscribe = (planIndex: number) => {
    const id = generatePlanId(planIndex);
    id &&
      _subscribe({
        ...params,
        subscription_key: id,
      });
  };
  const openPortal = () => {
    _openPortal({
      return_path: params.success_redirect_path,
      locale: params.locale,
    });
  };

  const manageSubscriptionButtonClickHandler = () => {
    isShopifyUser ? setOpen(true) : openPortal();
  };

  const subscribeShopify = (planIndex: number) => {
    const id = generatePlanId(planIndex);
    _subscribeShopify({
      subscription_key: id,
    });
  };

  const billingDetails = {
    name: capitalize(intl.formatMessage(Messages.billingDetails)),
    children: (
      <Stack rowGap={'0.25rem'}>
        {!isShopifyUser && (
          <>
            <Typography fontWeight={700} fontSize="15px" color="base.black" mb="0.75rem">
              {capitalize(intl.formatMessage(Messages.paymentMethod))}:
            </Typography>
            <StripeCardPayment planDetails={plan} />
          </>
        )}
        <Typography fontWeight={700} fontSize="15px" color="base.black" m="1.5rem 0 0.75rem 0">
          {capitalize(intl.formatMessage(Messages.billingCycle))}:
        </Typography>
        <Typography fontWeight={500} color="grey.700" fontSize="16px">
          {capitalize(intl.formatMessage(Messages.renewOn))}{' '}
          {intl.formatDate(nextBillDueDate, DateFormatOptions)}
        </Typography>
        {cancelledAt && (
          <Typography
            fontWeight={500}
            fontSize="15px"
            color="danger.500"
            marginTop={'1rem'}
          >
            {capitalize(intl.formatMessage(Messages.cancelledOn))}:{' '}
            {cancelledAt}
          </Typography>
        )}
      </Stack>
    ),
  };
  const features = (plan: typeof PlanOptions[0]) => {
    const desc = plan.features.desc;
    return {
      name: capitalize(intl.formatMessage(Messages.features)),
      children: (
        <Stack rowGap={'0.25rem'}>
          {
            desc.map((textArr, index) => {
              return (
                <Box key={index} sx={{
                  padding: "0 0 1.5rem 0",
                  "&:last-child": { padding: "0"}
                }} >
                  <Box fontWeight={700}>{textArr[0]}</Box>
                  <Stack direction="row" mt="0.75rem">
                    <CheckIcon stroke={theme.palette.primary.main}
                      sx={{ width: '1rem', height: '1rem', m: '0.25rem 0 0 0.25rem' }}
                    />
                    <Box ml="0.75rem" lineHeight="24px" sx={{ "& span": { fontSize: "16px" } }}>
                      <Typography component="span" fontWeight="400">{textArr[1]}</Typography>
                      <Typography component="span" fontWeight="700">{textArr[2]}</Typography>
                      <Typography component="span" fontWeight="400">{textArr[3]}</Typography>
                    </Box>
                  </Stack>
                </Box>
              )
            })
          }
        </Stack>
      ),
    }
  }
  const border = (index: number) =>
    isMobileView ? index === 0 ? '0.5rem 0.5rem 0 0' : '0 0 0.5rem 0.5rem'
      : index === 2 ? '0rem 0.5rem 0.5rem 0rem' : '0.5rem 0 0 0.5rem';

  const credits = useCredits(plan, intl);

  const subscribeButton = (index: number) => (
    <Button
      className={gs.buttonPrimary}
      onClick={() =>
        isShopifyUser ? subscribeShopify(index) : subscribe(index)
      }
    >
      {capitalize(intl.formatMessage(Messages.subscribe))}
    </Button>
  );

  const [open, setOpen] = useState(false);

  return (
    <Stack>
      <Confirm
        text={intl.formatMessage(Messages.AreYouSureToCancel)}
        handleConfirm={cancelShopifySubscription}
        open={open}
        setOpen={setOpen}
      />
      <Typography fontWeight={500} fontSize="1.5rem">
        {capitalize(intl.formatMessage(Messages.billing))}
      </Typography>
      <Box height="1rem"></Box>
      <Grid
        gridTemplateRows={isMobileView ? 'auto auto auto' : '292px auto'}
        gridTemplateColumns={isMobileView ? 'auto' : 'repeat(2, 1fr)'}
        gridTemplateAreas={
          isMobileView
            ? "'plans' 'credits' 'deleteAccount'"
            : "'plans credits' 'plans deleteAccount'"
        }
        display={'grid'}
        rowGap="1rem"
        columnGap={'1rem'}
      >
        <Stack
          gridArea="plans"
          direction={isMobileView ? 'column' : 'row'}
          border={'1px solid ' + theme.palette.neutral[600]}
          borderRadius="0.5rem"
          className="shadowXSM"
        >
          {displayPlans.map((plan, index) => (
            <Fragment key={`${plan.name}${index}`}>
              <Stack
                rowGap={'0.75rem'}
                padding="1.5rem 1.5rem 1rem"
                bgcolor={theme.palette.base.white}
                borderRadius={border(index)}
                overflow='hidden'
                position="relative"
                flexGrow={0}
                flexShrink={0}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '33.3%',
                    lg: '33.3%',
                    xl: '33.3%',
                  },
                }}
              >
                {
                  plan.unit === 'year' && (
                    <Typography sx={{
                      position: 'absolute', top: '0', right: '0', padding: "4px 12px",
                      textAlign: 'center', borderRadius: '0 0 0 1rem',
                      bgcolor: theme.palette.action.bgPrimary
                    }}>
                      <Typography component="span" color="primary.main_white" fontSize='18px' fontWeight='700'>
                        -15% OFF
                      </Typography>
                    </Typography>
                  )
                }
                <Stack
                  direction="row"
                  marginBottom={'0.5rem'}
                  justifyContent="center"
                >
                  <Stack marginLeft="1rem" justifyContent={'center'}>
                    <Box className="boxcenterhv" marginBottom={'32px'}>
                      <Typography
                        fontWeight="700"
                        textAlign="center"
                        component="span"
                        fontSize="22px"
                        lineHeight={'32px'}
                      >
                        {capitalize(plan.name)}
                      </Typography>
                    </Box>
                    <Stack direction="row" textAlign="center" alignItems="flex-end"
                      justifyContent="center">
                      <Typography sx={{ lineHeight:"42px", color:"grey.900", fontSize:"34px", fontWeight: "700" }}
                        component="span">
                        ${plan.price}
                      </Typography>
                      <Typography sx={{ lineHeight:"30px", color:"grey.900", fontSize:"22px", fontWeight: "600" }}
                        component="span">
                        &nbsp;/&nbsp;{intl.formatMessage(Messages.perMonth)}
                      </Typography>
                    </Stack>
                    {/* { plan.unit ==="year" && 
                      (
                        <Stack direction="row" textAlign="center" alignItems="flex-end" justifyContent="center"
                          mt="10px">
                          <Typography sx={{ lineHeight:"42px", color:"grey.25", fontSize:"22px", fontWeight: "700" }}
                            component="span">
                            ${plan.price}&nbsp;/&nbsp;{intl.formatMessage(Messages.perYear)}
                          </Typography>
                        </Stack>
                      )
                    } */}
                    <Box marginBottom="32px"></Box>
                    <PlanTypeSelection
                      options={UnitOptions}
                      selected={index === 0 ? plan1Unit : index === 1 ? plan2Unit : plan3Unit }
                      onChange={(unit) => {
                        if (index === 0) { setPlan1Unit(unit); }
                        if (index === 1) { setPlan2Unit(unit); }
                        if (index === 2) { setPlan3Unit(unit); }
                      }}
                    />
                  </Stack>
                </Stack>
                <STabs
                  tabs={ plan.planType === currentPlanName ? [billingDetails, features(plan)] : [features(plan)] }
                  tabTitleFontSize="0.875rem"
                  selectedIndex={0}
                  tabTitlePaddingBottom={'0rem'}
                  tabTitlePaddingTop={'0rem'}
                />

                <Box flexGrow={1}></Box>
                {plan.planType === currentPlanName ? (
                  <Box
                    className={`boxcenterhv`}
                    sx={{
                      borderRadius: '8px',
                      backgroundColor: theme.palette.success[50],
                      color: theme.palette.success[600],
                    }}
                    minHeight="40px"
                  >
                    {intl.formatMessage(Messages.SelectedPlan)}
                  </Box>
                ) : isFreePlan || isShopifyUser ? (
                  subscribeButton(index)
                ) : (
                  <Box minHeight="40px"></Box>
                )}
              </Stack>
              {index < displayPlans.length - 1 && (
                <Box
                  {...(isMobileView ? { height: '1px' } : { width: '1px' })}
                  bgcolor={theme.palette.neutral[600]}
                ></Box>
              )}
            </Fragment>
          ))}
        </Stack>
        <Stack
          gridArea="credits"
          direction={'column'}
          border={'1px solid ' + theme.palette.neutral[600]}
          borderRadius="0.5rem"
          className="shadowXSM"
          bgcolor={theme.palette.base.white}
        >
          {credits.map((credit, index) => (
            <Stack
              direction="row"
              key={`${credit.name} ${index}`}
              padding="1rem"
              borderBottom={'1px solid ' + theme.palette.neutral[600]}
            >
              <Stack
                sx={{ width: '4rem', height: '4rem' }}
                justifyContent="center"
                alignItems={'center'}
                bgcolor="neutral.600"
                marginRight={'1rem'}
                borderRadius="0.5rem"
              >
                {credit.icon}
              </Stack>
              <Stack direction={'column'}>
                <Typography color="grey.50" fontWeight={500}>
                  {credit.name}
                </Typography>
                <Typography
                  color="grey.700"
                  fontWeight={600}
                  fontSize="22px"
                  lineHeight={'26px'}
                >
                  {intl.formatNumber(credit.value)}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Stack
          gridArea="deleteAccount"
          direction={'column'}
          border={'1px solid ' + theme.palette.neutral[600]}
          borderRadius="0.5rem"
          className="shadowXSM"
          bgcolor={theme.palette.base.white}
        >
          <Typography
            padding="1rem"
            borderBottom={'1px solid ' + theme.palette.neutral[600]}
            color="grey.700"
            fontWeight={500}
            fontSize="18px"
          >
            {intl.formatMessage(Messages.ManageYourSubscription)}
          </Typography>
          <Typography padding="1rem">
            {intl.formatMessage(
              isFreePlan
                ? Messages.YouDontHaveAnActive
                : isShopifyUser
                ? Messages.CancelCurrentSubscription
                : Messages.VisitThisLinkTo
            )}
          </Typography>
          <Stack
            flexGrow={1}
            justifyContent="flex-end"
            padding="1rem"
            paddingTop="0"
          >
            <Button
              className={gs.buttonPrimary}
              onClick={manageSubscriptionButtonClickHandler}
              disabled={isFreePlan || cancelling}
            >
              {intl.formatMessage(
                isShopifyUser
                  ? Messages.CancelSubscription
                  : Messages.ManageSubscription
              )}
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Stack>
  );
}
