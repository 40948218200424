import { useEffect } from 'react';
import { useLanguage } from '../../hooks/language';

function useScript(src: string) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = false;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
}

function useMeta() {
  const metas = [
    {
      name: 'appleid-signin-client-id',
      content: 'com.bena.signin',
    },
    {
      name: 'appleid-signin-scope',
      content: 'name email',
    },
    {
      name: 'appleid-signin-redirect-uri',
      content: window.location.origin + '/api/v1/auth/sso/apple',
      // 'https://eb80-97-113-102-172.ngrok-free.app' + '/api/v1/auth/sso/apple',
    },
    {
      name: 'appleid-signin-state',
      content: 'bena-apple-sso',
    },
    {
      name: 'appleid-signin-nonce',
      content: '##BENA##',
    },
    {
      name: 'appleid-signin-use-popup',
      content: 'false',
    },
  ];
  useEffect(() => {
    const metaEl1 = document.createElement('meta');
    const metaEl2 = document.createElement('meta');
    const metaEl3 = document.createElement('meta');
    const metaEl4 = document.createElement('meta');
    const metaEl5 = document.createElement('meta');
    const metaEl6 = document.createElement('meta');
    [metaEl1, metaEl2, metaEl3, metaEl4, metaEl5, metaEl6].forEach(
      (metaEl, index) => {
        const meta = metas[index];
        metaEl.name = meta.name;
        metaEl.content = meta.content;
        document.head.appendChild(metaEl);
      }
    );

    return () => {
      document.head.removeChild(metaEl1);
      document.head.removeChild(metaEl2);
      document.head.removeChild(metaEl3);
      document.head.removeChild(metaEl4);
      document.head.removeChild(metaEl5);
      document.head.removeChild(metaEl6);
    };
  }, []);
}

export default function AppleSSOButton() {
  useMeta();

  const src =
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  const lang = useLanguage();
  // const langForApple = lang === 'zh-hans' ? 'zh_Hans' : 'en_US';
  const langForApple = 'en_US';
  useScript(src.replace('en_US', langForApple));

  return (
    <div
      style={{
        height: '48px',
        backgroundColor: 'white',
        borderRadius: '8px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid black',
      }}
    >
      <div
        id="appleid-signin"
        style={{ height: '38px' }}
        data-border="false"
        data-border-radius="0"
        data-type="sign-in"
        data-color="white"
        data-width="100%"
        data-height="100%"
        data-logo-size="small"
      ></div>
    </div>
  );
}
