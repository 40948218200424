import { IntlShape } from 'react-intl';
import { CountriesMessages } from '../localization/CountriesMessages';
import { useMemo } from 'react';

export type CountryCode = typeof countryCodes[number];
export const countryCodes = [
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'KH',
  'CM',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'CI',
  'HR',
  'CU',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'AN',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'SH',
  'KN',
  'LC',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'CS',
  'SC',
  'SL',
  'SG',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
  'Southeast Asia',
];

export const getCountryNames = (intl: IntlShape) => ({
  GB: intl.formatMessage(CountriesMessages['United Kingdom']),
  US: intl.formatMessage(CountriesMessages['United States']),
  AU: intl.formatMessage(CountriesMessages['Australia']),
  CA: intl.formatMessage(CountriesMessages['Canada']),
  FR: intl.formatMessage(CountriesMessages['France']),
  KR: intl.formatMessage(CountriesMessages['Korea, Republic of']),
  MY: intl.formatMessage(CountriesMessages['Malaysia']),
  SG: intl.formatMessage(CountriesMessages['Singapore']),
  ES: intl.formatMessage(CountriesMessages['Spain']),
  DE: intl.formatMessage(CountriesMessages['Germany']),
  AF: intl.formatMessage(CountriesMessages['Afghanistan']),
  AX: intl.formatMessage(CountriesMessages['Åland Islands']),
  AL: intl.formatMessage(CountriesMessages['Albania']),
  DZ: intl.formatMessage(CountriesMessages['Algeria']),
  AS: intl.formatMessage(CountriesMessages['American Samoa']),
  AD: intl.formatMessage(CountriesMessages['AndorrA']),
  AO: intl.formatMessage(CountriesMessages['Angola']),
  AI: intl.formatMessage(CountriesMessages['Anguilla']),
  AQ: intl.formatMessage(CountriesMessages['Antarctica']),
  AG: intl.formatMessage(CountriesMessages['Antigua and Barbuda']),
  AR: intl.formatMessage(CountriesMessages['Argentina']),
  AM: intl.formatMessage(CountriesMessages['Armenia']),
  AW: intl.formatMessage(CountriesMessages['Aruba']),
  AT: intl.formatMessage(CountriesMessages['Austria']),
  AZ: intl.formatMessage(CountriesMessages['Azerbaijan']),
  BS: intl.formatMessage(CountriesMessages['Bahamas']),
  BH: intl.formatMessage(CountriesMessages['Bahrain']),
  BD: intl.formatMessage(CountriesMessages['Bangladesh']),
  BB: intl.formatMessage(CountriesMessages['Barbados']),
  BY: intl.formatMessage(CountriesMessages['Belarus']),
  BE: intl.formatMessage(CountriesMessages['Belgium']),
  BZ: intl.formatMessage(CountriesMessages['Belize']),
  BJ: intl.formatMessage(CountriesMessages['Benin']),
  BM: intl.formatMessage(CountriesMessages['Bermuda']),
  BT: intl.formatMessage(CountriesMessages['Bhutan']),
  BO: intl.formatMessage(CountriesMessages['Bolivia']),
  BA: intl.formatMessage(CountriesMessages['Bosnia and Herzegovina']),
  BW: intl.formatMessage(CountriesMessages['Botswana']),
  BV: intl.formatMessage(CountriesMessages['Bouvet Island']),
  BR: intl.formatMessage(CountriesMessages['Brazil']),
  IO: intl.formatMessage(CountriesMessages['British Indian Ocean Territory']),
  BN: intl.formatMessage(CountriesMessages['Brunei Darussalam']),
  BG: intl.formatMessage(CountriesMessages['Bulgaria']),
  BF: intl.formatMessage(CountriesMessages['Burkina Faso']),
  BI: intl.formatMessage(CountriesMessages['Burundi']),
  KH: intl.formatMessage(CountriesMessages['Cambodia']),
  CM: intl.formatMessage(CountriesMessages['Cameroon']),
  CV: intl.formatMessage(CountriesMessages['Cape Verde']),
  KY: intl.formatMessage(CountriesMessages['Cayman Islands']),
  CF: intl.formatMessage(CountriesMessages['Central African Republic']),
  TD: intl.formatMessage(CountriesMessages['Chad']),
  CL: intl.formatMessage(CountriesMessages['Chile']),
  CN: intl.formatMessage(CountriesMessages['China']),
  CX: intl.formatMessage(CountriesMessages['Christmas Island']),
  CC: intl.formatMessage(CountriesMessages['Cocos (Keeling) Islands']),
  CO: intl.formatMessage(CountriesMessages['Colombia']),
  KM: intl.formatMessage(CountriesMessages['Comoros']),
  CG: intl.formatMessage(CountriesMessages['Congo']),
  CD: intl.formatMessage(
    CountriesMessages['Congo, The Democratic Republic of the']
  ),
  CK: intl.formatMessage(CountriesMessages['Cook Islands']),
  CR: intl.formatMessage(CountriesMessages['Costa Rica']),
  CI: intl.formatMessage(CountriesMessages["Cote D'Ivoire"]),
  HR: intl.formatMessage(CountriesMessages['Croatia']),
  CU: intl.formatMessage(CountriesMessages['Cuba']),
  CY: intl.formatMessage(CountriesMessages['Cyprus']),
  CZ: intl.formatMessage(CountriesMessages['Czech Republic']),
  DK: intl.formatMessage(CountriesMessages['Denmark']),
  DJ: intl.formatMessage(CountriesMessages['Djibouti']),
  DM: intl.formatMessage(CountriesMessages['Dominica']),
  DO: intl.formatMessage(CountriesMessages['Dominican Republic']),
  EC: intl.formatMessage(CountriesMessages['Ecuador']),
  EG: intl.formatMessage(CountriesMessages['Egypt']),
  SV: intl.formatMessage(CountriesMessages['El Salvador']),
  GQ: intl.formatMessage(CountriesMessages['Equatorial Guinea']),
  ER: intl.formatMessage(CountriesMessages['Eritrea']),
  EE: intl.formatMessage(CountriesMessages['Estonia']),
  ET: intl.formatMessage(CountriesMessages['Ethiopia']),
  FK: intl.formatMessage(CountriesMessages['Falkland Islands (Malvinas)']),
  FO: intl.formatMessage(CountriesMessages['Faroe Islands']),
  FJ: intl.formatMessage(CountriesMessages['Fiji']),
  FI: intl.formatMessage(CountriesMessages['Finland']),
  GF: intl.formatMessage(CountriesMessages['French Guiana']),
  PF: intl.formatMessage(CountriesMessages['French Polynesia']),
  TF: intl.formatMessage(CountriesMessages['French Southern Territories']),
  GA: intl.formatMessage(CountriesMessages['Gabon']),
  GM: intl.formatMessage(CountriesMessages['Gambia']),
  GE: intl.formatMessage(CountriesMessages['Georgia']),
  GH: intl.formatMessage(CountriesMessages['Ghana']),
  GI: intl.formatMessage(CountriesMessages['Gibraltar']),
  GR: intl.formatMessage(CountriesMessages['Greece']),
  GL: intl.formatMessage(CountriesMessages['Greenland']),
  GD: intl.formatMessage(CountriesMessages['Grenada']),
  GP: intl.formatMessage(CountriesMessages['Guadeloupe']),
  GU: intl.formatMessage(CountriesMessages['Guam']),
  GT: intl.formatMessage(CountriesMessages['Guatemala']),
  GG: intl.formatMessage(CountriesMessages['Guernsey']),
  GN: intl.formatMessage(CountriesMessages['Guinea']),
  GW: intl.formatMessage(CountriesMessages['Guinea-Bissau']),
  GY: intl.formatMessage(CountriesMessages['Guyana']),
  HT: intl.formatMessage(CountriesMessages['Haiti']),
  HM: intl.formatMessage(
    CountriesMessages['Heard Island and Mcdonald Islands']
  ),
  VA: intl.formatMessage(CountriesMessages['Holy See (Vatican City State)']),
  HN: intl.formatMessage(CountriesMessages['Honduras']),
  HU: intl.formatMessage(CountriesMessages['Hungary']),
  IS: intl.formatMessage(CountriesMessages['Iceland']),
  IN: intl.formatMessage(CountriesMessages['India']),
  ID: intl.formatMessage(CountriesMessages['Indonesia']),
  IR: intl.formatMessage(CountriesMessages['Iran, Islamic Republic Of']),
  IQ: intl.formatMessage(CountriesMessages['Iraq']),
  IE: intl.formatMessage(CountriesMessages['Ireland']),
  IM: intl.formatMessage(CountriesMessages['Isle of Man']),
  IL: intl.formatMessage(CountriesMessages['Israel']),
  IT: intl.formatMessage(CountriesMessages['Italy']),
  JM: intl.formatMessage(CountriesMessages['Jamaica']),
  JP: intl.formatMessage(CountriesMessages['Japan']),
  JE: intl.formatMessage(CountriesMessages['Jersey']),
  JO: intl.formatMessage(CountriesMessages['Jordan']),
  KZ: intl.formatMessage(CountriesMessages['Kazakhstan']),
  KE: intl.formatMessage(CountriesMessages['Kenya']),
  KI: intl.formatMessage(CountriesMessages['Kiribati']),
  KP: intl.formatMessage(
    CountriesMessages["Korea, Democratic People'S Republic of"]
  ),
  KW: intl.formatMessage(CountriesMessages['Kuwait']),
  KG: intl.formatMessage(CountriesMessages['Kyrgyzstan']),
  LA: intl.formatMessage(CountriesMessages["Lao People'S Democratic Republic"]),
  LV: intl.formatMessage(CountriesMessages['Latvia']),
  LB: intl.formatMessage(CountriesMessages['Lebanon']),
  LS: intl.formatMessage(CountriesMessages['Lesotho']),
  LR: intl.formatMessage(CountriesMessages['Liberia']),
  LY: intl.formatMessage(CountriesMessages['Libyan Arab Jamahiriya']),
  LI: intl.formatMessage(CountriesMessages['Liechtenstein']),
  LT: intl.formatMessage(CountriesMessages['Lithuania']),
  LU: intl.formatMessage(CountriesMessages['Luxembourg']),
  MK: intl.formatMessage(
    CountriesMessages['Macedonia, The Former Yugoslav Republic of']
  ),
  MG: intl.formatMessage(CountriesMessages['Madagascar']),
  MW: intl.formatMessage(CountriesMessages['Malawi']),
  MV: intl.formatMessage(CountriesMessages['Maldives']),
  ML: intl.formatMessage(CountriesMessages['Mali']),
  MT: intl.formatMessage(CountriesMessages['Malta']),
  MH: intl.formatMessage(CountriesMessages['Marshall Islands']),
  MQ: intl.formatMessage(CountriesMessages['Martinique']),
  MR: intl.formatMessage(CountriesMessages['Mauritania']),
  MU: intl.formatMessage(CountriesMessages['Mauritius']),
  YT: intl.formatMessage(CountriesMessages['Mayotte']),
  MX: intl.formatMessage(CountriesMessages['Mexico']),
  FM: intl.formatMessage(CountriesMessages['Micronesia, Federated States of']),
  MD: intl.formatMessage(CountriesMessages['Moldova, Republic of']),
  MC: intl.formatMessage(CountriesMessages['Monaco']),
  MN: intl.formatMessage(CountriesMessages['Mongolia']),
  MS: intl.formatMessage(CountriesMessages['Montserrat']),
  MA: intl.formatMessage(CountriesMessages['Morocco']),
  MZ: intl.formatMessage(CountriesMessages['Mozambique']),
  MM: intl.formatMessage(CountriesMessages['Myanmar']),
  NA: intl.formatMessage(CountriesMessages['Namibia']),
  NR: intl.formatMessage(CountriesMessages['Nauru']),
  NP: intl.formatMessage(CountriesMessages['Nepal']),
  NL: intl.formatMessage(CountriesMessages['Netherlands']),
  AN: intl.formatMessage(CountriesMessages['Netherlands Antilles']),
  NC: intl.formatMessage(CountriesMessages['New Caledonia']),
  NZ: intl.formatMessage(CountriesMessages['New Zealand']),
  NI: intl.formatMessage(CountriesMessages['Nicaragua']),
  NE: intl.formatMessage(CountriesMessages['Niger']),
  NG: intl.formatMessage(CountriesMessages['Nigeria']),
  NU: intl.formatMessage(CountriesMessages['Niue']),
  NF: intl.formatMessage(CountriesMessages['Norfolk Island']),
  MP: intl.formatMessage(CountriesMessages['Northern Mariana Islands']),
  NO: intl.formatMessage(CountriesMessages['Norway']),
  OM: intl.formatMessage(CountriesMessages['Oman']),
  PK: intl.formatMessage(CountriesMessages['Pakistan']),
  PW: intl.formatMessage(CountriesMessages['Palau']),
  PS: intl.formatMessage(CountriesMessages['Palestinian Territory, Occupied']),
  PA: intl.formatMessage(CountriesMessages['Panama']),
  PG: intl.formatMessage(CountriesMessages['Papua New Guinea']),
  PY: intl.formatMessage(CountriesMessages['Paraguay']),
  PE: intl.formatMessage(CountriesMessages['Peru']),
  PH: intl.formatMessage(CountriesMessages['Philippines']),
  PN: intl.formatMessage(CountriesMessages['Pitcairn']),
  PL: intl.formatMessage(CountriesMessages['Poland']),
  PT: intl.formatMessage(CountriesMessages['Portugal']),
  PR: intl.formatMessage(CountriesMessages['Puerto Rico']),
  QA: intl.formatMessage(CountriesMessages['Qatar']),
  RE: intl.formatMessage(CountriesMessages['Reunion']),
  RO: intl.formatMessage(CountriesMessages['Romania']),
  RU: intl.formatMessage(CountriesMessages['Russia']),
  RW: intl.formatMessage(CountriesMessages['RWANDA']),
  SH: intl.formatMessage(CountriesMessages['Saint Helena']),
  KN: intl.formatMessage(CountriesMessages['Saint Kitts and Nevis']),
  LC: intl.formatMessage(CountriesMessages['Saint Lucia']),
  PM: intl.formatMessage(CountriesMessages['Saint Pierre and Miquelon']),
  VC: intl.formatMessage(CountriesMessages['Saint Vincent and the Grenadines']),
  WS: intl.formatMessage(CountriesMessages['Samoa']),
  SM: intl.formatMessage(CountriesMessages['San Marino']),
  ST: intl.formatMessage(CountriesMessages['Sao Tome and Principe']),
  SA: intl.formatMessage(CountriesMessages['Saudi Arabia']),
  SN: intl.formatMessage(CountriesMessages['Senegal']),
  CS: intl.formatMessage(CountriesMessages['Serbia and Montenegro']),
  SC: intl.formatMessage(CountriesMessages['Seychelles']),
  SL: intl.formatMessage(CountriesMessages['Sierra Leone']),
  SK: intl.formatMessage(CountriesMessages['Slovakia']),
  SI: intl.formatMessage(CountriesMessages['Slovenia']),
  SB: intl.formatMessage(CountriesMessages['Solomon Islands']),
  SO: intl.formatMessage(CountriesMessages['Somalia']),
  ZA: intl.formatMessage(CountriesMessages['South Africa']),
  GS: intl.formatMessage(
    CountriesMessages['South Georgia and the South Sandwich Islands']
  ),
  LK: intl.formatMessage(CountriesMessages['Sri Lanka']),
  SD: intl.formatMessage(CountriesMessages['Sudan']),
  SR: intl.formatMessage(CountriesMessages['Suriname']),
  SJ: intl.formatMessage(CountriesMessages['Svalbard and Jan Mayen']),
  SZ: intl.formatMessage(CountriesMessages['Swaziland']),
  SE: intl.formatMessage(CountriesMessages['Sweden']),
  CH: intl.formatMessage(CountriesMessages['Switzerland']),
  SY: intl.formatMessage(CountriesMessages['Syrian Arab Republic']),
  TJ: intl.formatMessage(CountriesMessages['Tajikistan']),
  TZ: intl.formatMessage(CountriesMessages['Tanzania, United Republic of']),
  TH: intl.formatMessage(CountriesMessages['Thailand']),
  TL: intl.formatMessage(CountriesMessages['Timor-Leste']),
  TG: intl.formatMessage(CountriesMessages['Togo']),
  TK: intl.formatMessage(CountriesMessages['Tokelau']),
  TO: intl.formatMessage(CountriesMessages['Tonga']),
  TT: intl.formatMessage(CountriesMessages['Trinidad and Tobago']),
  TN: intl.formatMessage(CountriesMessages['Tunisia']),
  TR: intl.formatMessage(CountriesMessages['Turkey']),
  TM: intl.formatMessage(CountriesMessages['Turkmenistan']),
  TC: intl.formatMessage(CountriesMessages['Turks and Caicos Islands']),
  TV: intl.formatMessage(CountriesMessages['Tuvalu']),
  UG: intl.formatMessage(CountriesMessages['Uganda']),
  UA: intl.formatMessage(CountriesMessages['Ukraine']),
  AE: intl.formatMessage(CountriesMessages['United Arab Emirates']),
  UM: intl.formatMessage(
    CountriesMessages['United States Minor Outlying Islands']
  ),
  UY: intl.formatMessage(CountriesMessages['Uruguay']),
  UZ: intl.formatMessage(CountriesMessages['Uzbekistan']),
  VU: intl.formatMessage(CountriesMessages['Vanuatu']),
  VE: intl.formatMessage(CountriesMessages['Venezuela']),
  VN: intl.formatMessage(CountriesMessages['Viet Nam']),
  VG: intl.formatMessage(CountriesMessages['Virgin Islands, British']),
  VI: intl.formatMessage(CountriesMessages['Virgin Islands, U.S.']),
  WF: intl.formatMessage(CountriesMessages['Wallis and Futuna']),
  EH: intl.formatMessage(CountriesMessages['Western Sahara']),
  YE: intl.formatMessage(CountriesMessages['Yemen']),
  ZM: intl.formatMessage(CountriesMessages['Zambia']),
  ZW: intl.formatMessage(CountriesMessages['Zimbabwe']),
  'Southeast Asia': intl.formatMessage(CountriesMessages['SoutheastAsia']),
  RS: intl.formatMessage(CountriesMessages['The Republic of Serbia']),
});

// IMPORTANT pls keep the same array item sequence as countryCodes items'.
export const countryLanguages: Array<{
  code: string;
  lang: string;
  langName: string;
  langStripe: string;
}> = [
  { code: 'CN', lang: 'zh-hans', langName: '中文', langStripe: 'zh' },
  { code: 'US', lang: 'en', langName: 'EN', langStripe: 'en' },
];

export interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countriesTelephoneCode = (intl: IntlShape) => [
  {
    code: 'AD',
    label: String(getCountryNames(intl)['AD'] || ''),
    phone: '376',
  },
  {
    code: 'AE',
    label: String(getCountryNames(intl)['AE'] || ''),
    phone: '971',
  },
  { code: 'AF', label: String(getCountryNames(intl)['AF'] || ''), phone: '93' },
  {
    code: 'AG',
    label: String(getCountryNames(intl)['AG'] || ''),
    phone: '1-268',
  },
  {
    code: 'AI',
    label: String(getCountryNames(intl)['AI'] || ''),
    phone: '1-264',
  },
  {
    code: 'AL',
    label: String(getCountryNames(intl)['AL'] || ''),
    phone: '355',
  },
  {
    code: 'AM',
    label: String(getCountryNames(intl)['AM'] || ''),
    phone: '374',
  },
  {
    code: 'AO',
    label: String(getCountryNames(intl)['AO'] || ''),
    phone: '244',
  },
  {
    code: 'AQ',
    label: String(getCountryNames(intl)['AQ'] || ''),
    phone: '672',
  },
  { code: 'AR', label: String(getCountryNames(intl)['AR'] || ''), phone: '54' },
  {
    code: 'AS',
    label: String(getCountryNames(intl)['AS'] || ''),
    phone: '1-684',
  },
  { code: 'AT', label: String(getCountryNames(intl)['AT'] || ''), phone: '43' },
  {
    code: 'AU',
    label: String(getCountryNames(intl)['AU'] || ''),
    phone: '61',
    suggested: true,
  },
  {
    code: 'AW',
    label: String(getCountryNames(intl)['AW'] || ''),
    phone: '297',
  },
  {
    code: 'AX',
    label: String(getCountryNames(intl)['AX'] || ''),
    phone: '358',
  },
  {
    code: 'AZ',
    label: String(getCountryNames(intl)['AZ'] || ''),
    phone: '994',
  },
  {
    code: 'BA',
    label: String(getCountryNames(intl)['BA'] || ''),
    phone: '387',
  },
  {
    code: 'BB',
    label: String(getCountryNames(intl)['BB'] || ''),
    phone: '1-246',
  },
  {
    code: 'BD',
    label: String(getCountryNames(intl)['BD'] || ''),
    phone: '880',
  },
  { code: 'BE', label: String(getCountryNames(intl)['BE'] || ''), phone: '32' },
  {
    code: 'BF',
    label: String(getCountryNames(intl)['BF'] || ''),
    phone: '226',
  },
  {
    code: 'BG',
    label: String(getCountryNames(intl)['BG'] || ''),
    phone: '359',
  },
  {
    code: 'BH',
    label: String(getCountryNames(intl)['BH'] || ''),
    phone: '973',
  },
  {
    code: 'BI',
    label: String(getCountryNames(intl)['BI'] || ''),
    phone: '257',
  },
  {
    code: 'BJ',
    label: String(getCountryNames(intl)['BJ'] || ''),
    phone: '229',
  },
  {
    code: 'BM',
    label: String(getCountryNames(intl)['BM'] || ''),
    phone: '1-441',
  },
  {
    code: 'BN',
    label: String(getCountryNames(intl)['BN'] || ''),
    phone: '673',
  },
  {
    code: 'BO',
    label: String(getCountryNames(intl)['BO'] || ''),
    phone: '591',
  },
  { code: 'BR', label: String(getCountryNames(intl)['BR'] || ''), phone: '55' },
  {
    code: 'BS',
    label: String(getCountryNames(intl)['BS'] || ''),
    phone: '1-242',
  },
  {
    code: 'BT',
    label: String(getCountryNames(intl)['BT'] || ''),
    phone: '975',
  },
  { code: 'BV', label: String(getCountryNames(intl)['BV'] || ''), phone: '47' },
  {
    code: 'BW',
    label: String(getCountryNames(intl)['BW'] || ''),
    phone: '267',
  },
  {
    code: 'BY',
    label: String(getCountryNames(intl)['BY'] || ''),
    phone: '375',
  },
  {
    code: 'BZ',
    label: String(getCountryNames(intl)['BZ'] || ''),
    phone: '501',
  },
  {
    code: 'CA',
    label: String(getCountryNames(intl)['CA'] || ''),
    phone: '1',
    suggested: true,
  },
  {
    code: 'CC',
    label: String(getCountryNames(intl)['CC'] || ''),
    phone: '61',
  },
  {
    code: 'CD',
    label: String(getCountryNames(intl)['CD'] || ''),
    phone: '243',
  },
  {
    code: 'CF',
    label: String(getCountryNames(intl)['CF'] || ''),
    phone: '236',
  },
  {
    code: 'CG',
    label: String(getCountryNames(intl)['CG'] || ''),
    phone: '242',
  },
  { code: 'CH', label: String(getCountryNames(intl)['CH'] || ''), phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  {
    code: 'CK',
    label: String(getCountryNames(intl)['CK'] || ''),
    phone: '682',
  },
  { code: 'CL', label: String(getCountryNames(intl)['CL'] || ''), phone: '56' },
  {
    code: 'CM',
    label: String(getCountryNames(intl)['CM'] || ''),
    phone: '237',
  },
  { code: 'CN', label: String(getCountryNames(intl)['CN'] || ''), phone: '86' },
  { code: 'CO', label: String(getCountryNames(intl)['CO'] || ''), phone: '57' },
  {
    code: 'CR',
    label: String(getCountryNames(intl)['CR'] || ''),
    phone: '506',
  },
  { code: 'CU', label: String(getCountryNames(intl)['CU'] || ''), phone: '53' },
  {
    code: 'CV',
    label: String(getCountryNames(intl)['CV'] || ''),
    phone: '238',
  },
  { code: 'CX', label: String(getCountryNames(intl)['CX'] || ''), phone: '61' },
  {
    code: 'CY',
    label: String(getCountryNames(intl)['CY'] || ''),
    phone: '357',
  },
  {
    code: 'CZ',
    label: String(getCountryNames(intl)['CZ'] || ''),
    phone: '420',
  },
  {
    code: 'DE',
    label: String(getCountryNames(intl)['DE'] || ''),
    phone: '49',
    suggested: true,
  },
  {
    code: 'DJ',
    label: String(getCountryNames(intl)['DJ'] || ''),
    phone: '253',
  },
  { code: 'DK', label: String(getCountryNames(intl)['DK'] || ''), phone: '45' },
  {
    code: 'DM',
    label: String(getCountryNames(intl)['DM'] || ''),
    phone: '1-767',
  },
  {
    code: 'DO',
    label: String(getCountryNames(intl)['DO'] || ''),
    phone: '1-809',
  },
  {
    code: 'DZ',
    label: String(getCountryNames(intl)['DZ'] || ''),
    phone: '213',
  },
  {
    code: 'EC',
    label: String(getCountryNames(intl)['EC'] || ''),
    phone: '593',
  },
  {
    code: 'EE',
    label: String(getCountryNames(intl)['EE'] || ''),
    phone: '372',
  },
  { code: 'EG', label: String(getCountryNames(intl)['EG'] || ''), phone: '20' },
  {
    code: 'EH',
    label: String(getCountryNames(intl)['EH'] || ''),
    phone: '212',
  },
  {
    code: 'ER',
    label: String(getCountryNames(intl)['ER'] || ''),
    phone: '291',
  },
  { code: 'ES', label: String(getCountryNames(intl)['ES'] || ''), phone: '34' },
  {
    code: 'ET',
    label: String(getCountryNames(intl)['ET'] || ''),
    phone: '251',
  },
  {
    code: 'FI',
    label: String(getCountryNames(intl)['FI'] || ''),
    phone: '358',
  },
  {
    code: 'FJ',
    label: String(getCountryNames(intl)['FJ'] || ''),
    phone: '679',
  },
  {
    code: 'FK',
    label: String(getCountryNames(intl)['FK'] || ''),
    phone: '500',
  },
  {
    code: 'FM',
    label: String(getCountryNames(intl)['FM'] || ''),
    phone: '691',
  },
  {
    code: 'FO',
    label: String(getCountryNames(intl)['FO'] || ''),
    phone: '298',
  },
  {
    code: 'FR',
    label: String(getCountryNames(intl)['FR'] || ''),
    phone: '33',
    suggested: true,
  },
  {
    code: 'GA',
    label: String(getCountryNames(intl)['GA'] || ''),
    phone: '241',
  },
  { code: 'GB', label: String(getCountryNames(intl)['GB'] || ''), phone: '44' },
  {
    code: 'GD',
    label: String(getCountryNames(intl)['GD'] || ''),
    phone: '1-473',
  },
  {
    code: 'GE',
    label: String(getCountryNames(intl)['GE'] || ''),
    phone: '995',
  },
  {
    code: 'GF',
    label: String(getCountryNames(intl)['GF'] || ''),
    phone: '594',
  },
  { code: 'GG', label: String(getCountryNames(intl)['GG'] || ''), phone: '44' },
  {
    code: 'GH',
    label: String(getCountryNames(intl)['GH'] || ''),
    phone: '233',
  },
  {
    code: 'GI',
    label: String(getCountryNames(intl)['GI'] || ''),
    phone: '350',
  },
  {
    code: 'GL',
    label: String(getCountryNames(intl)['GL'] || ''),
    phone: '299',
  },
  {
    code: 'GM',
    label: String(getCountryNames(intl)['GM'] || ''),
    phone: '220',
  },
  {
    code: 'GN',
    label: String(getCountryNames(intl)['GN'] || ''),
    phone: '224',
  },
  {
    code: 'GP',
    label: String(getCountryNames(intl)['GP'] || ''),
    phone: '590',
  },
  {
    code: 'GQ',
    label: String(getCountryNames(intl)['GQ'] || ''),
    phone: '240',
  },
  { code: 'GR', label: String(getCountryNames(intl)['GR'] || ''), phone: '30' },
  {
    code: 'GS',
    label: String(getCountryNames(intl)['GS'] || ''),
    phone: '500',
  },
  {
    code: 'GT',
    label: String(getCountryNames(intl)['GT'] || ''),
    phone: '502',
  },
  {
    code: 'GU',
    label: String(getCountryNames(intl)['GU'] || ''),
    phone: '1-671',
  },
  {
    code: 'GW',
    label: String(getCountryNames(intl)['GW'] || ''),
    phone: '245',
  },
  {
    code: 'GY',
    label: String(getCountryNames(intl)['GY'] || ''),
    phone: '592',
  },
  {
    code: 'HM',
    label: String(getCountryNames(intl)['HM'] || ''),
    phone: '672',
  },
  {
    code: 'HN',
    label: String(getCountryNames(intl)['HN'] || ''),
    phone: '504',
  },
  {
    code: 'HR',
    label: String(getCountryNames(intl)['HR'] || ''),
    phone: '385',
  },
  {
    code: 'HT',
    label: String(getCountryNames(intl)['HT'] || ''),
    phone: '509',
  },
  { code: 'HU', label: String(getCountryNames(intl)['HU'] || ''), phone: '36' },
  { code: 'ID', label: String(getCountryNames(intl)['ID'] || ''), phone: '62' },
  {
    code: 'IE',
    label: String(getCountryNames(intl)['IE'] || ''),
    phone: '353',
  },
  {
    code: 'IL',
    label: String(getCountryNames(intl)['IL'] || ''),
    phone: '972',
  },
  { code: 'IM', label: String(getCountryNames(intl)['IM'] || ''), phone: '44' },
  { code: 'IN', label: String(getCountryNames(intl)['IN'] || ''), phone: '91' },
  {
    code: 'IO',
    label: String(getCountryNames(intl)['IO'] || ''),
    phone: '246',
  },
  {
    code: 'IQ',
    label: String(getCountryNames(intl)['IQ'] || ''),
    phone: '964',
  },
  {
    code: 'IR',
    label: String(getCountryNames(intl)['IR'] || ''),
    phone: '98',
  },
  {
    code: 'IS',
    label: String(getCountryNames(intl)['IS'] || ''),
    phone: '354',
  },
  { code: 'IT', label: String(getCountryNames(intl)['IT'] || ''), phone: '39' },
  { code: 'JE', label: String(getCountryNames(intl)['JE'] || ''), phone: '44' },
  {
    code: 'JM',
    label: String(getCountryNames(intl)['JM'] || ''),
    phone: '1-876',
  },
  {
    code: 'JO',
    label: String(getCountryNames(intl)['JO'] || ''),
    phone: '962',
  },
  {
    code: 'JP',
    label: String(getCountryNames(intl)['JP'] || ''),
    phone: '81',
    suggested: true,
  },
  {
    code: 'KE',
    label: String(getCountryNames(intl)['KE'] || ''),
    phone: '254',
  },
  {
    code: 'KG',
    label: String(getCountryNames(intl)['KG'] || ''),
    phone: '996',
  },
  {
    code: 'KH',
    label: String(getCountryNames(intl)['KH'] || ''),
    phone: '855',
  },
  {
    code: 'KI',
    label: String(getCountryNames(intl)['KI'] || ''),
    phone: '686',
  },
  {
    code: 'KM',
    label: String(getCountryNames(intl)['KM'] || ''),
    phone: '269',
  },
  {
    code: 'KN',
    label: String(getCountryNames(intl)['KN'] || ''),
    phone: '1-869',
  },
  {
    code: 'KP',
    label: String(getCountryNames(intl)['KP'] || ''),
    phone: '850',
  },
  { code: 'KR', label: String(getCountryNames(intl)['KR'] || ''), phone: '82' },
  {
    code: 'KW',
    label: String(getCountryNames(intl)['KW'] || ''),
    phone: '965',
  },
  {
    code: 'KY',
    label: String(getCountryNames(intl)['KY'] || ''),
    phone: '1-345',
  },
  { code: 'KZ', label: String(getCountryNames(intl)['KZ'] || ''), phone: '7' },
  {
    code: 'LA',
    label: String(getCountryNames(intl)['LA'] || ''),
    phone: '856',
  },
  {
    code: 'LB',
    label: String(getCountryNames(intl)['LB'] || ''),
    phone: '961',
  },
  {
    code: 'LC',
    label: String(getCountryNames(intl)['LC'] || ''),
    phone: '1-758',
  },
  {
    code: 'LI',
    label: String(getCountryNames(intl)['LI'] || ''),
    phone: '423',
  },
  { code: 'LK', label: String(getCountryNames(intl)['LK'] || ''), phone: '94' },
  {
    code: 'LR',
    label: String(getCountryNames(intl)['LR'] || ''),
    phone: '231',
  },
  {
    code: 'LS',
    label: String(getCountryNames(intl)['LS'] || ''),
    phone: '266',
  },
  {
    code: 'LT',
    label: String(getCountryNames(intl)['LT'] || ''),
    phone: '370',
  },
  {
    code: 'LU',
    label: String(getCountryNames(intl)['LU'] || ''),
    phone: '352',
  },
  {
    code: 'LV',
    label: String(getCountryNames(intl)['LV'] || ''),
    phone: '371',
  },
  {
    code: 'LY',
    label: String(getCountryNames(intl)['LY'] || ''),
    phone: '218',
  },
  {
    code: 'MA',
    label: String(getCountryNames(intl)['MA'] || ''),
    phone: '212',
  },
  {
    code: 'MC',
    label: String(getCountryNames(intl)['MC'] || ''),
    phone: '377',
  },
  {
    code: 'MD',
    label: String(getCountryNames(intl)['MD'] || ''),
    phone: '373',
  },
  {
    code: 'MG',
    label: String(getCountryNames(intl)['MG'] || ''),
    phone: '261',
  },
  {
    code: 'MH',
    label: String(getCountryNames(intl)['MH'] || ''),
    phone: '692',
  },
  {
    code: 'MK',
    label: String(getCountryNames(intl)['MK'] || ''),
    phone: '389',
  },
  {
    code: 'ML',
    label: String(getCountryNames(intl)['ML'] || ''),
    phone: '223',
  },
  { code: 'MM', label: String(getCountryNames(intl)['MM'] || ''), phone: '95' },
  {
    code: 'MN',
    label: String(getCountryNames(intl)['MN'] || ''),
    phone: '976',
  },
  {
    code: 'MP',
    label: String(getCountryNames(intl)['MP'] || ''),
    phone: '1-670',
  },
  {
    code: 'MQ',
    label: String(getCountryNames(intl)['MQ'] || ''),
    phone: '596',
  },
  {
    code: 'MR',
    label: String(getCountryNames(intl)['MR'] || ''),
    phone: '222',
  },
  {
    code: 'MS',
    label: String(getCountryNames(intl)['MS'] || ''),
    phone: '1-664',
  },
  {
    code: 'MT',
    label: String(getCountryNames(intl)['MT'] || ''),
    phone: '356',
  },
  {
    code: 'MU',
    label: String(getCountryNames(intl)['MU'] || ''),
    phone: '230',
  },
  {
    code: 'MV',
    label: String(getCountryNames(intl)['MV'] || ''),
    phone: '960',
  },
  {
    code: 'MW',
    label: String(getCountryNames(intl)['MW'] || ''),
    phone: '265',
  },
  { code: 'MX', label: String(getCountryNames(intl)['MX'] || ''), phone: '52' },
  { code: 'MY', label: String(getCountryNames(intl)['MY'] || ''), phone: '60' },
  {
    code: 'MZ',
    label: String(getCountryNames(intl)['MZ'] || ''),
    phone: '258',
  },
  {
    code: 'NA',
    label: String(getCountryNames(intl)['NA'] || ''),
    phone: '264',
  },
  {
    code: 'NC',
    label: String(getCountryNames(intl)['NC'] || ''),
    phone: '687',
  },
  {
    code: 'NE',
    label: String(getCountryNames(intl)['NE'] || ''),
    phone: '227',
  },
  {
    code: 'NF',
    label: String(getCountryNames(intl)['NF'] || ''),
    phone: '672',
  },
  {
    code: 'NG',
    label: String(getCountryNames(intl)['NG'] || ''),
    phone: '234',
  },
  {
    code: 'NI',
    label: String(getCountryNames(intl)['NI'] || ''),
    phone: '505',
  },
  { code: 'NL', label: String(getCountryNames(intl)['NL'] || ''), phone: '31' },
  { code: 'NO', label: String(getCountryNames(intl)['NO'] || ''), phone: '47' },
  {
    code: 'NP',
    label: String(getCountryNames(intl)['NP'] || ''),
    phone: '977',
  },
  {
    code: 'NR',
    label: String(getCountryNames(intl)['NR'] || ''),
    phone: '674',
  },
  {
    code: 'NU',
    label: String(getCountryNames(intl)['NU'] || ''),
    phone: '683',
  },
  { code: 'NZ', label: String(getCountryNames(intl)['NZ'] || ''), phone: '64' },
  {
    code: 'OM',
    label: String(getCountryNames(intl)['OM'] || ''),
    phone: '968',
  },
  {
    code: 'PA',
    label: String(getCountryNames(intl)['PA'] || ''),
    phone: '507',
  },
  { code: 'PE', label: String(getCountryNames(intl)['PE'] || ''), phone: '51' },
  {
    code: 'PF',
    label: String(getCountryNames(intl)['PF'] || ''),
    phone: '689',
  },
  {
    code: 'PG',
    label: String(getCountryNames(intl)['PG'] || ''),
    phone: '675',
  },
  { code: 'PH', label: String(getCountryNames(intl)['PH'] || ''), phone: '63' },
  { code: 'PK', label: String(getCountryNames(intl)['PK'] || ''), phone: '92' },
  { code: 'PL', label: String(getCountryNames(intl)['PL'] || ''), phone: '48' },
  {
    code: 'PM',
    label: String(getCountryNames(intl)['PM'] || ''),
    phone: '508',
  },
  {
    code: 'PN',
    label: String(getCountryNames(intl)['PN'] || ''),
    phone: '870',
  },
  { code: 'PR', label: String(getCountryNames(intl)['PR'] || ''), phone: '1' },
  {
    code: 'PS',
    label: String(getCountryNames(intl)['PS'] || ''),
    phone: '970',
  },
  {
    code: 'PT',
    label: String(getCountryNames(intl)['PT'] || ''),
    phone: '351',
  },
  {
    code: 'PW',
    label: String(getCountryNames(intl)['PW'] || ''),
    phone: '680',
  },
  {
    code: 'PY',
    label: String(getCountryNames(intl)['PY'] || ''),
    phone: '595',
  },
  {
    code: 'QA',
    label: String(getCountryNames(intl)['QA'] || ''),
    phone: '974',
  },
  {
    code: 'RE',
    label: String(getCountryNames(intl)['RE'] || ''),
    phone: '262',
  },
  { code: 'RO', label: String(getCountryNames(intl)['RO'] || ''), phone: '40' },
  { code: 'RU', label: String(getCountryNames(intl)['RU'] || ''), phone: '7' },
  {
    code: 'RW',
    label: String(getCountryNames(intl)['RW'] || ''),
    phone: '250',
  },
  {
    code: 'SA',
    label: String(getCountryNames(intl)['SA'] || ''),
    phone: '966',
  },
  {
    code: 'SB',
    label: String(getCountryNames(intl)['SB'] || ''),
    phone: '677',
  },
  {
    code: 'SC',
    label: String(getCountryNames(intl)['SC'] || ''),
    phone: '248',
  },
  {
    code: 'SD',
    label: String(getCountryNames(intl)['SD'] || ''),
    phone: '249',
  },
  { code: 'SE', label: String(getCountryNames(intl)['SE'] || ''), phone: '46' },
  { code: 'SG', label: String(getCountryNames(intl)['SG'] || ''), phone: '65' },
  {
    code: 'SH',
    label: String(getCountryNames(intl)['SH'] || ''),
    phone: '290',
  },
  {
    code: 'SI',
    label: String(getCountryNames(intl)['SI'] || ''),
    phone: '386',
  },
  {
    code: 'SJ',
    label: String(getCountryNames(intl)['SJ'] || ''),
    phone: '47',
  },
  {
    code: 'SK',
    label: String(getCountryNames(intl)['SK'] || ''),
    phone: '421',
  },
  {
    code: 'SL',
    label: String(getCountryNames(intl)['SL'] || ''),
    phone: '232',
  },
  {
    code: 'SM',
    label: String(getCountryNames(intl)['SM'] || ''),
    phone: '378',
  },
  {
    code: 'SN',
    label: String(getCountryNames(intl)['SN'] || ''),
    phone: '221',
  },
  {
    code: 'SO',
    label: String(getCountryNames(intl)['SO'] || ''),
    phone: '252',
  },
  {
    code: 'SR',
    label: String(getCountryNames(intl)['SR'] || ''),
    phone: '597',
  },
  {
    code: 'SV',
    label: String(getCountryNames(intl)['SV'] || ''),
    phone: '503',
  },
  {
    code: 'SY',
    label: String(getCountryNames(intl)['SY'] || ''),
    phone: '963',
  },
  {
    code: 'SZ',
    label: String(getCountryNames(intl)['SZ'] || ''),
    phone: '268',
  },
  {
    code: 'TC',
    label: String(getCountryNames(intl)['TC'] || ''),
    phone: '1-649',
  },
  {
    code: 'TD',
    label: String(getCountryNames(intl)['TD'] || ''),
    phone: '235',
  },
  {
    code: 'TF',
    label: String(getCountryNames(intl)['TF'] || ''),
    phone: '262',
  },
  {
    code: 'TG',
    label: String(getCountryNames(intl)['TG'] || ''),
    phone: '228',
  },
  { code: 'TH', label: String(getCountryNames(intl)['TH'] || ''), phone: '66' },
  {
    code: 'TJ',
    label: String(getCountryNames(intl)['TJ'] || ''),
    phone: '992',
  },
  {
    code: 'TK',
    label: String(getCountryNames(intl)['TK'] || ''),
    phone: '690',
  },
  {
    code: 'TL',
    label: String(getCountryNames(intl)['TL'] || ''),
    phone: '670',
  },
  {
    code: 'TM',
    label: String(getCountryNames(intl)['TM'] || ''),
    phone: '993',
  },
  {
    code: 'TN',
    label: String(getCountryNames(intl)['TN'] || ''),
    phone: '216',
  },
  {
    code: 'TO',
    label: String(getCountryNames(intl)['TO'] || ''),
    phone: '676',
  },
  { code: 'TR', label: String(getCountryNames(intl)['TR'] || ''), phone: '90' },
  {
    code: 'TT',
    label: String(getCountryNames(intl)['TT'] || ''),
    phone: '1-868',
  },
  {
    code: 'TV',
    label: String(getCountryNames(intl)['TV'] || ''),
    phone: '688',
  },
  {
    code: 'TZ',
    label: String(getCountryNames(intl)['TZ'] || ''),
    phone: '255',
  },
  {
    code: 'UA',
    label: String(getCountryNames(intl)['UA'] || ''),
    phone: '380',
  },
  {
    code: 'UG',
    label: String(getCountryNames(intl)['UG'] || ''),
    phone: '256',
  },
  {
    code: 'US',
    label: String(getCountryNames(intl)['US'] || ''),
    phone: '1',
    suggested: true,
  },
  {
    code: 'UY',
    label: String(getCountryNames(intl)['UY'] || ''),
    phone: '598',
  },
  {
    code: 'UZ',
    label: String(getCountryNames(intl)['UZ'] || ''),
    phone: '998',
  },
  {
    code: 'VA',
    label: String(getCountryNames(intl)['VA'] || ''),
    phone: '379',
  },
  {
    code: 'VC',
    label: String(getCountryNames(intl)['VC'] || ''),
    phone: '1-784',
  },
  { code: 'VE', label: String(getCountryNames(intl)['VE'] || ''), phone: '58' },
  {
    code: 'VG',
    label: String(getCountryNames(intl)['VG'] || ''),
    phone: '1-284',
  },
  {
    code: 'VI',
    label: String(getCountryNames(intl)['VI'] || ''),
    phone: '1-340',
  },
  { code: 'VN', label: String(getCountryNames(intl)['VN'] || ''), phone: '84' },
  {
    code: 'VU',
    label: String(getCountryNames(intl)['VU'] || ''),
    phone: '678',
  },
  {
    code: 'WF',
    label: String(getCountryNames(intl)['WF'] || ''),
    phone: '681',
  },
  {
    code: 'WS',
    label: String(getCountryNames(intl)['WS'] || ''),
    phone: '685',
  },
  {
    code: 'YE',
    label: String(getCountryNames(intl)['YE'] || ''),
    phone: '967',
  },
  {
    code: 'YT',
    label: String(getCountryNames(intl)['YT'] || ''),
    phone: '262',
  },
  { code: 'ZA', label: String(getCountryNames(intl)['ZA'] || ''), phone: '27' },
  {
    code: 'ZM',
    label: String(getCountryNames(intl)['ZM'] || ''),
    phone: '260',
  },
  {
    code: 'ZW',
    label: String(getCountryNames(intl)['ZW'] || ''),
    phone: '263',
  },
  {
    code: 'RS',
    label: String(getCountryNames(intl)['RS'] || ''),
    phone: '381',
  },
];

// country code map to country english name for GeoLocation
export const CountryCodeName = {
  Other: 'Other',
  AF: 'Afghanistan',
  AX: 'Åland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'AndorrA',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, The Democratic Republic of the',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote D'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and Mcdonald Islands',
  VA: 'Holy See (Vatican City State',
  HN: 'Honduras',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic Of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea, Democratic People'S Republic of",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People'S Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MK: 'Macedonia, The Former Yugoslav Republic of',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of',
  MD: 'Moldova, Republic of',
  MC: 'Monaco',
  MN: 'Mongolia',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'RWANDA',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  CS: 'Serbia and Montenegro',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  RS: 'The Republic of Serbia',
} as { [key: CountryCode]: string };

export function useCountryNameCodeMap() {
  return useMemo(() => {
    const keys = Object.keys(CountryCodeName);
    const ret = {} as { [key: string]: string };
    keys.forEach((w) => {
      const name = CountryCodeName[w];
      if (name) {
        ret[name] = w;
      }
    });
    return ret;
  }, []);
}

export const CreatorCountryCode = [
  'AU',
  'CA',
  'FR',
  'DE',
  'Southeast Asia',
  'ES',
  'GB',
  'US',
];
