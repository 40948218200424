import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {
  AuthSliceType,
  logout,
  setAccessToken,
  setLoadingInProgress,
  setSubscriptionPlanLimitErrorCode,
  setUIErrorCode,
} from '../features/account/AuthSlice';
import { staticURLLogin } from '../route/urls';
import { envResolverAPIURL } from '../env';
import { SubscriptionLimitErrorCode } from './benaApiResponseCode';
import { encodeObjectToURLSearchParams } from '../utils/common';
import { wkwebviewLogin, wkwebviewLogout } from './wkwebviewBridge';

// add data type based on redux root state if necessary
type ReduxStateType = {
  auth: AuthSliceType;
};

const _baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxStateType).auth.access;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

function handleFetchError(api: BaseQueryApi, response: unknown): boolean {
  const res = response as { error?: { error: string; status: string } };
  if (res?.error?.status === 'FETCH_ERROR') {
    api.dispatch(setUIErrorCode('8001#' + res?.error?.error));
    return true;
  } else {
    api.dispatch(setUIErrorCode(null));
  }
  return false;
}

async function forceLogoutWithThrow(api: BaseQueryApi, url: string) {
  api.dispatch(logout());
  api.dispatch(setLoadingInProgress(false));
  window.location.href = url;
  // force to stop the next steps
  throw new Error(
    'force logout, this exception is to end the next steps, no need to handle it.'
  );
}

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseQuery = _baseQuery(envResolverAPIURL());
  api.dispatch(setLoadingInProgress(true));
  const { refresh, access_token_expires_at, refresh_token_expires_at, user } = (
    api.getState() as ReduxStateType
  ).auth;

  let expired = refresh_token_expires_at * 1000;
  if (expired > 0 && expired < Date.now()) {
    // if refresh token error, force logout
    await forceLogoutWithThrow(api, staticURLLogin);
  }

  // check if access_token_expires_at expires and update access token
  expired = access_token_expires_at * 1000;
  if (expired > 0 && expired < Date.now()) {
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh-token/',
        body: { refresh },
        method: 'POST',
      },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      const res = (refreshResult.data as APIResponse<RefreshTokenResponseType>)
        .data;
      api.dispatch(
        setAccessToken({
          access: res?.access || '',
          access_token_expires_at: res?.access_token_expires_at || 0,
        })
      );
      wkwebviewLogin(res?.access || '', user?.id);
    } else {
      // if refresh token error, force logout
      wkwebviewLogout();
      await forceLogoutWithThrow(api, staticURLLogin);
    }
  }

  let result = await baseQuery(args, api, extraOptions);
  handleFetchError(api, result);

  // logout if status_code 4038
  const status_code = ((result.data || result.error?.data) as APIResponseNoData)
    ?.status?.status_code;
  if (status_code === 4038) {
    await forceLogoutWithThrow(
      api,
      staticURLLogin + '?logout_session_limit=true'
    );
  }

  if (
    refresh &&
    ((result.error && result.error.status === 401) || status_code === 4002)
  ) {
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh-token/',
        body: { refresh },
        method: 'POST',
      },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      const res = (refreshResult.data as APIResponse<RefreshTokenResponseType>)
        .data;
      api.dispatch(
        setAccessToken({
          access: res?.access || '',
          access_token_expires_at: res?.access_token_expires_at || 0,
        })
      );
      result = await baseQuery(args, api, extraOptions);
      handleFetchError(api, result);
    } else {
      await forceLogoutWithThrow(api, staticURLLogin);
    }
  }
  api.dispatch(setLoadingInProgress(false));

  // add subscription plan limit checking
  const limit = ((result.data || result.error?.data) as APIResponseNoData)?.data
    ?.quota;
  if (SubscriptionLimitErrorCode.includes(status_code)) {
    api.dispatch(
      setSubscriptionPlanLimitErrorCode(
        `${status_code}#${limit === undefined || limit === null ? '' : limit}`
      )
    );
  } else {
    api.dispatch(setSubscriptionPlanLimitErrorCode(null));
  }

  return result;
};

export const benaApi = createApi({
  reducerPath: 'benaApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 0,
  // Renaming Rules:
  // "use/useLazy" + "Object value" + "Query/Mutation"
  endpoints: (builder) => ({
    getCreators: builder.query<
      APIResponse<CreatorsResponseType>,
      SearchQueryParams
    >({
      query: ({ page, limit, queryParams }) => {
        return `creators?page=${page}&size=${limit}${queryParams}`;
      },
    }),
    getCreatorsInList: builder.query<
      APIResponse<CreatorsResponseType>,
      SearchQueryParams
    >({
      query: ({ page, limit, queryParams }) => {
        return `creators-in-list?page=${page}&size=${limit}${queryParams}`;
      },
    }),
    getCreatorShortlist: builder.query<APIResponse<shortListResponseType>,SearchQueryParams>(
      {
      query: ({ page, limit, queryParams }) => {
        return `creator-shortlist?page=${page}&size=${limit}${queryParams}`;
      },
    }),
    getCreator: builder.query<
      APIResponse<CreatorDataDetails>,
      CreatorRequestType
    >({
      query: ({ id }) => {
        return `creators/${id}`;
      },
    }),
    // auth api
    login: builder.mutation<APIResponse<LoginResponseType>, LoginRequestParams>(
      {
        query: (params) => ({
          url: `auth/login/`,
          method: 'POST',
          body: params,
        }),
      }
    ),
    logout: builder.mutation<APIResponseNoData, LogoutRequestParams>({
      query: (params) => ({
        url: `auth/logout`,
        method: 'POST',
        body: params,
      }),
    }),
    register: builder.mutation<
      APIResponse<RegisterResponseType>,
      RegisterRequestParams
    >({
      query: (params) => ({
        url: `auth/register/`,
        method: 'POST',
        body: params,
      }),
    }),
    patchUser: builder.mutation<
      APIResponse<UserResponseType>,
      PatchUserRequestType
    >({
      query: (params) => ({
        url: `user`,
        method: 'PATCH',
        body: params,
      }),
    }),
    sendEmailVerificationCode: builder.mutation<
      APIResponseNoData,
      SendEmailVerificationRequestParams
    >({
      query: (params) => ({
        url: `send-email-verification`,
        method: 'POST',
        body: params,
      }),
    }),
    updatePassword: builder.mutation<
      APIResponseNoData,
      UpdatePasswordRequestParams
    >({
      query: (params) => ({
        url: `update-password`,
        method: 'PUT',
        body: params,
      }),
    }),
    resetPassword: builder.mutation<
      APIResponseNoData,
      ResetPasswordRequestParams
    >({
      query: (params) => ({
        url: `reset-password`,
        method: 'PUT',
        body: params,
      }),
    }),
    refreshToken: builder.mutation<
      APIResponse<RefreshTokenResponseType>,
      RefreshTokenRequestParams
    >({
      query: (params) => ({
        url: `auth/refresh-token/`,
        method: 'POST',
        body: params,
      }),
    }),
    validateEmailVerification: builder.query<
      APIResponse<ValidateEmailVerificationResponseType>,
      ValidateEmailVerificationRequestParams
    >({
      query: (params) => ({
        url: `validate-email-verification?${encodeObjectToURLSearchParams(
          params
        )}`,
        method: 'GET',
      }),
    }),
    getUser: builder.query<APIResponse<UserResponseType>, GetUserRequestType>({
      query: (params) => ({
        url: `user`,
        method: 'GET',
        ...(params.access && {
          headers: {
            Authorization: `Bearer ${params.access}`,
          },
        }),
      }),
    }),
    patchEmail: builder.mutation<APIResponse<UserResponseType>, PatchEmailType>(
      {
        query: (params) => ({
          url: `user/email`,
          method: 'PATCH',
          body: params,
        }),
      }
    ),
    postFavoriteCreators: builder.mutation<
      APIResponse<UserResponseType>,
      FavoriteRequestType
    >({
      query: (params) => ({
        url: `favorite-creators`,
        method: 'POST',
        body: params,
      }),
    }),
    deleteFavoriteCreators: builder.mutation<
      APIResponse<UserResponseType>,
      FavoriteRequestType
    >({
      query: (params) => ({
        url: `favorite-creators/${params.creator_id}`,
        method: 'DELETE',
      }),
    }),
    postCreatorVideoScript: builder.mutation<
      APIResponse<CreatorVideoScriptResponseType>,
      CreatorVideoScriptRequestType
    >({
      query: (params) => ({
        url: '/creator-video-script',
        method: 'POST',
        body: params,
      }),
    }),
    patchAIGCVideo: builder.mutation<
      APIResponse<CreatorVideoScriptResponseType>,
      PatchAIGCVideoRequestType
    >({
      query: (params) => ({
        url: `/aigc-videos/${params.id}`,
        method: 'PATCH',
        body: params.body,
      }),
    }),
    deleteAIGCVideo: builder.mutation<
      APIResponse<APIResponseNoData>,
      { id: string }
    >({
      query: (params) => ({
        url: `/aigc-videos/${params.id}`,
        method: 'DELETE',
      }),
    }),
    queryAIGCVideos: builder.query<
      APIResponse<QueryAIGCVideosResponseType>,
      QueryAIGCVideosRequestType
    >({
      query: (params) => ({
        url: `/aigc-videos/creators/${params.creator_id}?page=${
          params.page
        }&size=${params.size}${
          params.filters
            ? '&filters=' + encodeURIComponent(JSON.stringify(params.filters))
            : ''
        }`,
        method: 'GET',
      }),
    }),
    postRegenerateVideoScript: builder.mutation<
      APIResponse<CreatorVideoScriptResponseType>,
      RegenerateVideoScriptRequestType
    >({
      query: ({ id, body }) => ({
        url: `/aigc-videos/${id}/regenerate`,
        method: 'POST',
        body,
      }),
    }),

    //stripe
    postStripePortal: builder.mutation<APIResponse<string>, any>({
      query: (params) => ({
        url: 'payments/stripe/portal',
        method: 'POST',
        body: params,
      }),
    }),
    postStripeSubscribe: builder.mutation<APIResponse<string>, any>({
      query: (params) => ({
        url: 'payments/stripe/checkout/subscribe',
        method: 'POST',
        body: params,
      }),
    }),
    getPlan: builder.query<APIResponse<SubscriptionPlanResponseType>, any>({
      query: () => ({
        url: 'payments/stripe/plan',
        method: 'GET',
      }),
    }),
    deleteSubscription: builder.mutation<APIResponse<string[]>, any>({
      query: () => ({
        url: '/payments/stripe/subscription',
        method: 'DELETE',
      }),
    }),
    getAIGCTips: builder.query<APIResponse<string[]>, AIGCTipsRequestParams>({
      query: (params) => ({
        url: `/aigc/tips?${encodeObjectToURLSearchParams(params)}`,
        method: 'GET',
      }),
    }),
    postShopifySubscribe: builder.mutation<APIResponse<string>, any>({
      query: (params) => ({
        url: 'payments/shopify/subscribe',
        method: 'POST',
        body: params,
      }),
    }),
    getShopifyPlan: builder.query<
      APIResponse<SubscriptionPlanResponseType>,
      any
    >({
      query: (params) => ({
        url: 'payments/shopify/plan',
        method: 'GET',
      }),
    }),
    deleteShopifyCancelSubscription: builder.mutation<
      APIResponse<APIResponseNoData>,
      any
    >({
      query: (params) => ({
        url: 'payments/shopify/cancel-subscription',
        method: 'DELETE',
      }),
    }),
    getCampaigns: builder.query<
      APIResponse<CampaignsResponseType>,
      PaginationRequestType & { seed?: string }
    >({
      query: (params) => ({
        url: `campaigns?page=${params.page}&size=${params.size}&seed=${params.seed}`,
        method: 'GET',
      }),
    }),
    getCampaignDetail: builder.query<APIResponse<CampaignResponseType>, any>({
      query: (params) => ({
        url: `campaigns/${params.campaign_id}`,
        method: 'GET',
      }),
    }),
    getCampaignApplications: builder.query<
      APIResponse<CampaignApplicationsResponseType>,
      PaginationRequestType & { campaign_id: string; filters?: any }
    >({
      query: (params) => ({
        url: `campaign-applications/${params.campaign_id}?page=${
          params.page
        }&size=${params.size}&filters=${params.filters || ''}`,
        method: 'GET',
      }),
    }),
    getCampaignApplicationsAndInvitations: builder.query<
      APIResponse<CampaignApplicationsResponseType>,
      PaginationRequestType & { campaign_id: string; filters?: any }
    >({
      query: (params) => ({
        url: `campaign-proposals/${params.campaign_id}?page=${
          params.page
        }&size=${params.size}&filters=${params.filters || ''}`,
        method: 'GET',
      }),
    }),
    getCampaignCreatorApplication: builder.query<
      APIResponse<CampaignApplicationItemType>,
      any
    >({
      query: (params) => ({
        url: `campaign-applications/${params.campaign_id}/${params.creator_id}`,
        method: 'GET',
      }),
    }),
    // with media kit info
    getApplication: builder.query<
      APIResponse<CampaignApplicationItemType>,
      { application_id: string }
    >({
      query: (params) => ({
        url: `applications/${params.application_id}`,
        method: 'GET',
      }),
    }),
    patchCampaignApplication: builder.mutation<
      APIResponse<CampaignApplicationItemType>,
      PatchCampaignApplicationRequestType
    >({
      query: (params) => ({
        url: `applications/${params.application_id}`,
        method: 'PATCH',
        body: {
          ...params,
        },
      }),
    }),
    // robot chat api
    postChatbotChatEmail: builder.mutation<
      APIResponse<ChatbotMessageEmailResponseType>,
      ChatbotMessageEmailRequestType
    >({
      query: (params) => ({
        url: 'chat/email',
        method: 'POST',
        body: params,
      }),
    }),
    postChatbotChatContent: builder.mutation<
      APIResponse<ChatbotMessageContentResponseType>,
      ChatbotMessageContentRequestType
    >({
      query: (params) => ({
        url: 'chat/content',
        method: 'POST',
        body: params,
      }),
    }),
    postChatbotChatScript: builder.mutation<
      APIResponse<ChatbotMessageScriptResponseType>,
      ChatbotMessageScriptRequestType
    >({
      query: (params) => ({
        url: 'chat/script',
        method: 'POST',
        body: params,
      }),
    }),
    postChatbotChatApplication: builder.mutation<
      APIResponse<ChatbotMessageApplicationResponseType>,
      ChatbotMessageApplicationRequestType
    >({
      query: (params) => ({
        url: 'chat/application',
        method: 'POST',
        body: params,
      }),
    }),
    getChatbotChatApplicationHistoryMessages: builder.query<
      APIResponse<ChatbotMessageApplicationHistoryMessagesResponseType>,
      ChatbotMessageApplicationHistoryMessagesRequestType
    >({
      query: (params) => ({
        url: 'chat/application/' + params.campaign_id,
        method: 'GET',
      }),
    }),
    fileDelete: builder.mutation<
      APIResponse<FileUploadResponseType>,
      CampaignFileDeleteRequestParams
    >({
      query: (params) => ({
        url: `campaigns/${params.id}/attachment`,
        method: 'DELETE',
        body: params.body,
      }),
    }),
    // campaign
    postCampaign: builder.mutation<
      APIResponse<CampaignResponseType>,
      PostCampaignRequestType
    >({
      query: (params) => ({
        url: `campaigns`,
        method: 'POST',
        body: params,
      }),
    }),
    patchCampaign: builder.mutation<
      APIResponse<CampaignResponseType>,
      PatchCampaignRequestType
    >({
      query: (params) => ({
        url: `campaigns/${params.campaign_id}`,
        method: 'PATCH',
        body: params.body,
      }),
    }),
    completeCampaign: builder.mutation<
      APIResponse<CampaignResponseType>,
      CompleteCampaignRequestType
    >({
      query: (params) => ({
        url: `campaigns/${params.campaign_id}`,
        method: 'PATCH',
        body: params.body,
      }),
    }),
    deleteCampaign: builder.mutation<
      APIResponse<APIResponseNoData>,
      { id: string }
    >({
      query: (params) => ({
        url: `campaigns/${params.id}`,
        method: 'DELETE',
      }),
    }),
    postCampaignMediaUpload: builder.mutation<
      APIResponse<PostCampaignMediaUploadResponseType>,
      PostCampaignRequestType
    >({
      query: (params) => ({
        url: `campaigns`,
        method: 'POST',
        body: params,
      }),
    }),
    deleteCampaignFileDelete: builder.mutation<
      APIResponse<FileUploadResponseType>,
      FileDeleteRequestParams
    >({
      query: (params) => ({
        url: `campaigns/media`,
        method: 'DELETE',
        body: params,
      }),
    }),
    avatarUpload: builder.mutation<
      APIResponse<AvatarUploadResponseType>,
      FileUploadRequestType
    >({
      query: (params) => ({
        url: `user/avatar`,
        method: 'POST',
        body: params.formData,
      }),
    }),
    getRocketchatUserToken: builder.query<
      APIResponse<GetRocketchatUserTokenResponseType>,
      GetRocketchatUserTokenRequestType
    >({
      query: (params) => ({
        url: `user/rocketchat-user-token`,
        method: 'GET',
      }),
    }),
    getRocketchatRoomId: builder.query<
      APIResponse<GetRocketchatRoomIdResponseType>,
      GetRocketchatRoomIdRequestType
    >({
      query: (params) => ({
        url: `user/rocketchat-room-id?to_account=${params.to_account}`,
        method: 'GET',
      }),
    }),
    getRocketchatUserUnreadMessagesCount: builder.query<
      APIResponse<GetRocketchatUserUnreadMessagesCountResponseType>,
      any
    >({
      query: (params) => ({
        url: `user/rocketchat-unread-count`,
        method: 'GET',
      }),
    }),
    postAIGCCampaignContentIdea: builder.mutation<
      APIResponse<PostAIGCCampaignContentIdeaResponseType>,
      PostAIGCCampaignContentIdeaRequestType
    >({
      query: (params) => ({
        url: `/aigc-campaign-content`,
        method: 'POST',
        body: params,
      }),
    }),
    patchCampaignMilestone: builder.mutation<
      APIResponseNoData,
      PatchCampaignMilestoneRequestType
    >({
      query: (params) => ({
        url: `milestones/${params.milestone_id}`,
        method: 'PATCH',
        body: params.body,
      }),
    }),
    postMessageCreator: builder.mutation<
      APIResponse<APIResponseNoData>,
      MessageCreatorRequestType
    >({
      query: (params) => ({
        url: `message-creator`,
        method: 'POST',
        body: params,
      }),
    }),
    getJobContract: builder.query<
      APIResponse<CampaignJobContractItemType>,
      { contract_id: string }
    >({
      query: (params) => ({
        url: `job-contract/${params.contract_id}`,
        method: 'GET',
      }),
    }),
    patchJobContract: builder.mutation<
      APIResponse<APIResponseNoData>,
      JobContractPatchRequestType
    >({
      query: (params) => ({
        url: `job-contract/${params.contract_id}`,
        method: 'PATCH',
        body: params.body,
      }),
    }),
    deleteJobContract: builder.mutation<
      APIResponse<APIResponseNoData>,
      JobContractPatchRequestType
    >({
      query: (params) => ({
        url: `job-contract/${params.contract_id}`,
        method: 'DELETE',
      }),
    }),
    postJobContract: builder.mutation<
      APIResponse<APIResponseNoData>,
      JobContractPostRequestType
    >({
      query: (params) => ({
        url: `job-contract`,
        method: 'POST',
        body: params,
      }),
    }),
    patchJobContractDraft: builder.mutation<
      APIResponse<APIResponseNoData>,
      { draft_id: string; body: { status?: string | null; comment?: string } }
    >({
      query: (params) => ({
        url: `job-contract/drafts/${params.draft_id}`,
        method: 'PATCH',
        body: params.body,
      }),
    }),
    getCampaignJobContracts: builder.query<
      APIResponse<GetCampaignJobContractsReponseType>,
      GetJobContractsRequestType
    >({
      query: (params) => ({
        url: `job-contracts/${params.campaign_id}?page=${params.page}&size=${
          params.size
        }&filters=${params.filters || ''}`,
        method: 'GET',
      }),
    }),
    patchCampaignApplications: builder.mutation<
      APIResponse<CampaignsResponseType>,
      PatchCampaignApplicationsRequestType
    >({
      query: (params) => ({
        url: `applications/${params.campaign_application_id}`,
        body: params,
        method: 'PATCH',
      }),
    }),
    getCampaignCreatorLatestApplication: builder.query<
      APIResponse<CampaignApplicationItemType>,
      GetCampaignCreatorProgressRequestType
    >({
      query: (params) => ({
        url: `campaign-applications/${params.campaign_id}/${params.creator_id}`,
        method: 'GET',
      }),
    }),
    getCampaignApplicationProposalsDeclined: builder.query<
      APIResponse<CampaignApplicationItemType>,
      GetCampaignApplicationProposalsRequestType
    >({
      query: (params) => ({
        url: `campaign-proposals/rejected/${params.campaign_id}?page=${params.page}&size=${params.size}&status=${params.status}`,
        method: 'GET',
      }),
    }),
    getCampaign: builder.query<
      APIResponse<CampaignResponseType>,
      CampaignRequestParams
    >({
      query: (params) => ({
        url: `campaigns/${params.id}`,
        method: 'GET',
      }),
    }),
    patchCreatorsMediaKit: builder.mutation<
      APIResponse<APIResponseNoData>,
      any
    >({
      query: (params) => ({
        url: `creators-media-kit`,
        method: 'PATCH',
        body: params,
      }),
    }),
    deleteUser: builder.mutation<APIResponse<UserResponseType>, any>({
      query: () => ({
        url: `user`,
        method: 'DELETE',
      }),
    }),
    // campaign invitation
    postCampaignInvitation: builder.mutation<
      APIResponse<APIResponseNoData>,
      CampaignInvitationPostRequestType
    >({
      query: (params) => ({
        url: 'campaign-invitations',
        method: 'POST',
        body: params,
      }),
    }),
    patchCampaignInvitation: builder.mutation<
      APIResponse<APIResponseNoData>,
      { invitation_id: string; body: { status: 'rejected' } }
    >({
      query: (params) => ({
        url: `campaign-invitations/${params.invitation_id}`,
        method: 'PATCH',
        body: params.body,
      }),
    }),
    deleteCampaignInvitation: builder.mutation<
      APIResponse<APIResponseNoData>,
      { invitation_id: string }
    >({
      query: (params) => ({
        url: `campaign-invitations/${params.invitation_id}`,
        method: 'DELETE',
      }),
    }),
    jobContractAttachmentFileDelete: builder.mutation<
      APIResponse<APIResponseNoData>,
      { file_id: string; jobcontract_id: string }
    >({
      query: (params) => ({
        url: `job-contract/${params.jobcontract_id}/files?file_id=${params.file_id}`,
        method: 'DELETE',
      }),
    }),
    getCreatorJobContracts: builder.query<
      APIResponse<GetCreatorJobContractsResponseType>,
      GetCreatorJobContractsRequestType
    >({
      query: (params) => ({
        url: `creator-job-contracts?tab=${params.tab}&page=${params.page}&size=${params.size}`,
        method: 'GET',
      }),
    }),
    getCreatorInvitations: builder.query<
      APIResponse<GetCreatorInvitationsResponseType>,
      { creator_id: string }
    >({
      query: (params) => ({
        url: `creator-invitations/${params.creator_id}`,
        method: 'GET',
      }),
    }),
    postCreatorShort: builder.mutation<APIResponse<ShortContentType>,{name: string}>({
      query: (params) => ({url: `creator-shortlist`,method: 'POST',body: params}),
    }),
    patchModifyShortName: builder.mutation<APIResponse<ShortContentType>,modifyShortNameRequestParams>({
      query: (params) => ({ url: `creator-shortlist`, method: 'PATCH', body: params})
    }),
    
    deleteShortList: builder.mutation<APIResponse<{}>,{id:string}>({
      query: (params) => ({url: `creator-shortlist`,method: 'DELETE',body: params}),
    }),
    postAddShortList: builder.mutation<APIResponse<{}>,{uid?: string, shortlists: string[] }>({
      query: (params) => ({url: `creator-shortlist/1`,method: 'POST',body: params}),
    }),
    postShortListBatch: builder.mutation<APIResponse<APIResponseNoData>,ShortlistBatchType>({
      query: (params) => ({url: `creator-shortlist-batch`,method: 'POST',body: params}),
    }),
    getUserShortList: builder.query<APIResponse<CreatorsResponseType>,SearchQueryParams>({
      query: ({ page, limit, queryParams }) => {
        return `creator-shortlist/${queryParams}?page=${page}&size=${limit}`;
      },
    }),
    deleteUserShort: builder.mutation<APIResponse<{}>,{ uid:string, queryParams:string }>({
      query: ({uid, queryParams}) => ({url: `creator-shortlist/${queryParams}`,method: 'DELETE',body: {uid} }),
    }),
    getEmailUrl: builder.query<APIResponse<APIEmailUrlsType>,{}>({
      query: ({}) => (`email/`),
    }),
    getCampaignEmailStatistics: builder.query<APIResponse<EmailCampaignStatistics>,{campaignId: string}>({
      query: ({campaignId}) => (`email-statistics?campaign=${campaignId}`)
    }),
    shortlistImport: builder.mutation<APIResponse<{message?: string}>,{ file: FormData }>({
      query: (params) => ({url: `shortlist/import`,method: 'POST',body: params.file }),
    }),
  }),
});
// /api/v1/creator-shortlist/{pk}
export const {
  useGetCreatorsQuery,
  useGetCreatorsInListQuery,
  useLazyGetCreatorsQuery,
  useGetCreatorShortlistQuery,
  useLazyGetCreatorQuery,
  useLazyValidateEmailVerificationQuery,
  useSendEmailVerificationCodeMutation,
  useLazyGetUserQuery,
  useLoginMutation,
  useRegisterMutation,
  usePatchUserMutation,
  usePatchEmailMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  usePostFavoriteCreatorsMutation,
  useDeleteFavoriteCreatorsMutation,
  useLazyQueryAIGCVideosQuery,
  usePatchAIGCVideoMutation,
  usePostCreatorVideoScriptMutation,
  useDeleteAIGCVideoMutation,
  usePostRegenerateVideoScriptMutation,
  usePostStripePortalMutation,
  usePostStripeSubscribeMutation,
  usePostShopifySubscribeMutation,
  useLazyGetShopifyPlanQuery,
  useDeleteShopifyCancelSubscriptionMutation,
  useLazyGetPlanQuery,
  useDeleteSubscriptionMutation,
  useLazyGetAIGCTipsQuery,
  useGetCampaignsQuery,
  useGetCampaignDetailQuery,
  useLazyGetCampaignDetailQuery,
  useGetCampaignApplicationsQuery,
  useGetCampaignCreatorApplicationQuery,
  usePostChatbotChatEmailMutation,
  usePostChatbotChatContentMutation,
  usePostChatbotChatScriptMutation,
  usePostChatbotChatApplicationMutation,
  useLazyGetChatbotChatApplicationHistoryMessagesQuery,
  useFileDeleteMutation,
  usePostCampaignMutation,
  useDeleteCampaignFileDeleteMutation,
  useAvatarUploadMutation,
  usePatchCampaignMutation,
  useLazyGetRocketchatUserTokenQuery,
  useLazyGetRocketchatRoomIdQuery,
  useLazyGetRocketchatUserUnreadMessagesCountQuery,
  useDeleteCampaignMutation,
  usePostAIGCCampaignContentIdeaMutation,
  usePatchCampaignApplicationMutation,
  useCompleteCampaignMutation,
  useGetApplicationQuery,
  usePatchCampaignMilestoneMutation,
  usePostMessageCreatorMutation,
  usePatchJobContractMutation,
  usePatchCampaignApplicationsMutation,
  useLazyGetCampaignCreatorLatestApplicationQuery,
  useLazyGetCampaignApplicationsQuery,
  useLazyGetCampaignQuery,
  usePatchCreatorsMediaKitMutation,
  useDeleteUserMutation,
  usePostCampaignInvitationMutation,
  useGetCampaignApplicationsAndInvitationsQuery,
  useLazyGetCampaignApplicationsAndInvitationsQuery,
  usePostJobContractMutation,
  useLazyGetCampaignJobContractsQuery,
  useJobContractAttachmentFileDeleteMutation,
  useLazyGetJobContractQuery,
  usePatchJobContractDraftMutation,
  useLazyGetCreatorJobContractsQuery,
  useDeleteJobContractMutation,
  useDeleteCampaignInvitationMutation,
  useLazyGetCampaignApplicationProposalsDeclinedQuery,
  usePatchCampaignInvitationMutation,
  useLazyGetCreatorInvitationsQuery,
  usePostCreatorShortMutation,
  usePatchModifyShortNameMutation,
  useDeleteShortListMutation,
  usePostAddShortListMutation,
  usePostShortListBatchMutation,
  useGetUserShortListQuery,
  useDeleteUserShortMutation,
  useLazyGetEmailUrlQuery,
  useGetCampaignEmailStatisticsQuery,
  useShortlistImportMutation,
} = benaApi;
