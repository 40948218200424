import { useMediaQuery, useTheme } from '@mui/material';

export function useBreakpointXs() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
}
export function useBreakpointIpad() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  return !xs && md;
}
export function useBreakpointXl() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('xl'));
}
export function useBreakpointLg() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('lg'));
}
export function useBreakpointMd() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
}
export function useBreakpointSm() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
}

export function isMobileBrowser() {
  return navigator.userAgent.toLowerCase().match(/mobile/i);
}
