import {
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Box,
  capitalize,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
  ArrowDownThinIcon,
  TiktokColorIcon,
  TiktokWhiteIcon,
  YoutubeColorIcon,
  YoutubeWhiteIcon,
} from '../assets/imgs/icons';
import { useIntl } from 'react-intl';
import { Messages } from '../localization/Messages';

const IconSize = { width: '1rem', height: '1rem' };

type PlatformType = 'youtube' | 'tiktok' | 'all';
export const useIcons = () => {
  const intl = useIntl();
  const all = capitalize(intl.formatMessage(Messages.all));
  return {
    tiktok: {
      color: <TiktokColorIcon sx={IconSize} />,
      white: <TiktokWhiteIcon sx={IconSize} />,
    },
    youtube: {
      color: <YoutubeColorIcon sx={IconSize} />,
      white: <YoutubeWhiteIcon sx={IconSize} />,
    },
    all: {
      color: <Typography>{all}</Typography>,
      white: <Typography>{all}</Typography>,
    },
  };
};

type PlatformSelectionButtonProps = {
  onChange: fnStringToVoid;
  platform: PlatformType;
};
export default function PlatformSelectionButton({
  onChange,
  platform,
}: PlatformSelectionButtonProps) {
  const theme = useTheme();
  const classes = useStyles();
  const Icons = useIcons();
  const refIconButton = useRef<HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(refIconButton.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectd, setSelected] = useState<PlatformType>('tiktok');
  useEffect(() => setSelected(platform), [platform]);
  const handleSelect = (v: PlatformType) => {
    setSelected(v);
    onChange(v);
    handleClose();
  };
  return (
    <>
      <Box ref={refIconButton} className="boxlefthcenterv">
        <IconButton
          disableRipple
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ flexGrow: 0, flexShrink: 0 }}
          className={classes.button}
        >
          {Icons[selectd].color}
        </IconButton>
        <IconButton
          disableRipple
          onClick={handleClick}
          sx={{ paddingRight: '0rem !important' }}
        >
          <ArrowDownThinIcon
            className={`${classes.arrow} ${!open ? '' : 'rotate180'}`}
          />
        </IconButton>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            display: 'flex',
            padding: '0.5rem',
            backgroundColor: theme.palette.base.white,
          },
        }}
        classes={{
          paper: classes.popover,
        }}
      >
        {Object.keys(Icons).map((key) => {
          const v = key as PlatformType;
          return (
            <MenuItem
              onClick={() => handleSelect(v)}
              key={v}
              className={classes.icon}
              disableRipple
              disableTouchRipple
              sx={{
                border: '0 !important',
                borderTop: `0 !important`,
                borderBottom: `0 !important`,
              }}
            >
              {Icons[v]['color']}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      borderRadius: '1rem !important',
      boxShadow: 'none !important',
      border: '1px solid' + theme.palette.neutral[700],
      marginTop: '1rem',
      backgroundColor: `${theme.palette.base.white} !important`,
    },
    icon: {
      width: '2.5rem',
      height: '2.5rem',
      padding: '0 !important',
      justifyContent: 'center !important',
      borderRadius: '0.5rem !important',
    },
    button: {
      width: '2.5rem',
      height: '2.5rem',
      backgroundColor: `${theme.palette.neutral[200]} !important`,
      borderRadius: '0.5rem !important',
    },
    arrow: {
      width: '0.75rem !important',
      height: '0.75rem !important',
      '& path': { stroke: theme.palette.grey[50] },
    },
  })
);
