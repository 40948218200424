import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Color from 'color';

export const useInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    inlineMenuItem: { display: 'inline-block', margin: theme.spacing(0.5) },
  })
);

/**
 * global style will be overrided by customTheme
 * specify the !important to make it with first priority.
 */
export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenCover: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: '100vw',
      minHeight: '100vh',
      backgroundColor: theme.palette.action.disabledBackground,
      // fix issue that screenCover hides tooltip
      zIndex: '1000 !important',
      [theme.breakpoints.up('lg')]: {
        padding: '0rem 0rem',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '1rem 1rem',
      },
      [theme.breakpoints.down('md')]: {
        padding: '0rem 0rem',
      },
    },
    screenCoverZindex1100: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: '100vw',
      minHeight: '100vh',
      backgroundColor: theme.palette.action.disabledBackground,
      zIndex: '1100',
      [theme.breakpoints.up('lg')]: {
        padding: '0rem 0rem',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '1rem 1rem',
      },
      [theme.breakpoints.down('md')]: {
        padding: '0rem 0rem',
      },
    },
    screenFixedRightPanel: {
      float: 'right',
      mt: 0,
      mr: 0,
      width: '100%',
      maxWidth: '50rem',
      height: '100vh',
      backgroundColor: theme.palette.base.white,
      display: 'flex',
      flexDirection: 'column',
    },
    screenFixedRightPanelCreatorProfile: {
      float: 'right',
      mt: 0,
      mr: 0,
      [theme.breakpoints.up('md')]: {
        width: theme.breakpoints.values.xl,
        maxWidth: theme.breakpoints.values.xl,
      },
      [theme.breakpoints.down('xs')]: {
        width: theme.breakpoints.values.xs,
        maxWidth: theme.breakpoints.values.xs,
      },

      height: '100vh',
      backgroundColor: theme.palette.base.white,
      display: 'flex',
      flexDirection: 'column',
    },
    borderBottom: {
      borderBottom: `0.0625rem solid ${theme.palette.neutral[600]}`,
    },
    font14px600: {
      fontWeight: '600 !important',
      fontSize: '0.875rem !important',
    },
    buttonPrimary: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.primary.main)
          .fade(0.1)
          .toString()} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
      },
      '&:disabled': {
        backgroundColor: `${theme.palette.grey.disabled_bg} !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonDarkPrimary: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.grey[600]} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.grey[600])
          .fade(0.1)
          .toString()} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
      },
      '&:disabled': {
        backgroundColor: `${theme.palette.grey.disabled_bg} !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonGreen: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.success[600]} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.success[600])
          .fade(0.1)
          .toString()} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
      },
      '&:disabled': {
        backgroundColor: `${theme.palette.grey.disabled_bg} !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonWhite: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.base.white} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.base.white)
          .fade(0.1)
          .toString()} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
      },
      '&:disabled': {
        backgroundColor: `${theme.palette.grey.disabled_bg} !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonOutlined: {
      borderRadius: '8px !important',
      backgroundColor: 'transparent !important',
      color: `${theme.palette.primary.main} !important`,
      '&:hover': {
        backgroundColor: `transparent !important`,
        color: `${theme.palette.primary.main} !important`,
      },
      '&:disabled': {
        backgroundColor: `transparent !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'not-allowed',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonOutlinedGrey: {
      borderRadius: '8px !important',
      backgroundColor: 'transparent !important',
      borderColor: `${theme.palette.neutral[700]} !important`,
      color: `${theme.palette.base.black} !important`,
      '&:hover': {
        backgroundColor: `transparent !important`,
        borderColor: `${theme.palette.neutral[600]} !important`,
      },
      '&:disabled': {
        backgroundColor: `transparent !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonOutlinedBlack: {
      borderRadius: '8px !important',
      backgroundColor: 'transparent !important',
      color: `${theme.palette.base.black} !important`,
      '&:hover': {
        backgroundColor: `transparent !important`,
        color: `${theme.palette.base.black} !important`,
      },
      '&:disabled': {
        backgroundColor: `transparent !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonOutlinedWhite: {
      border: '1px solid' + theme.palette.base.white,
      borderRadius: '8px !important',
      backgroundColor: 'transparent !important',
      color: `${theme.palette.base.white} !important`,
      '&:hover': {
        backgroundColor: `transparent !important`,
        color: `${theme.palette.base.white} !important`,
        border: `1px solid ${Color(theme.palette.base.white)
          .darken(0.1)
          .toString()} !important`,
      },
      '&:disabled': {
        backgroundColor: `transparent !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonNoOutlineWhite: {
      border: '0px',
      borderRadius: '8px !important',
      backgroundColor: 'transparent !important',
      color: `${theme.palette.base.white} !important`,
      '&:hover': {
        backgroundColor: `transparent !important`,
        color: `${theme.palette.base.white} !important`,
        border: `0px !important`,
      },
      '&:disabled': {
        backgroundColor: `transparent !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonWhitePrimary: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.base.white} !important`,
      color: `${theme.palette.primary.main} !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.base.white)
          .fade(0.1)
          .toString()} !important`,
        color: `${theme.palette.primary.main} !important`,
      },
      '&:disabled': {
        backgroundColor: `${theme.palette.grey.disabled_bg} !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonGenerate: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.primary.main)
          .fade(0.1)
          .toString()} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
      },
    },
    buttonDeletion: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.danger[400]}  !important`,
      color: `${theme.palette.base.white} !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.danger[400])
          .fade(0.1)
          .toString()} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
      },
      '&:disabled': {
        backgroundColor: `${Color(theme.palette.danger[400])
          .fade(0.8)
          .toString()} !important`,
        color: `${theme.palette.primary.contrastText} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonDeletionOutlined: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.base.white} !important`,
      color: `${theme.palette.danger[400]}  !important`,
      border: `1px solid ${theme.palette.danger[400]}  !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.base.white)
          .darken(0.01)
          .toString()} !important`,
        color: `${theme.palette.danger[400]}  !important`,
      },
      '&:disabled': {
        backgroundColor: `${Color(theme.palette.base.white)
          .fade(0.01)
          .toString()} !important`,
        color: `${theme.palette.danger[400]} !important`,
        cursor: 'auto',
      },
    },
    buttonCancellation: {
      backgroundColor: `${theme.palette.base.white} !important`,
      color: `${theme.palette.primary.main}  !important`,
      border: `0.1875rem solid ${theme.palette.primary.main}  !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.base.white)
          .darken(0.1)
          .toString()} !important`,
        color: `${theme.palette.primary.main}  !important`,
      },
      '&:disabled': {
        border: `0.1875rem solid ${Color(theme.palette.grey.disabled_bg)
          .fade(0.3)
          .toString()}  !important`,
        backgroundColor: `${Color(theme.palette.grey.disabled_bg)
          .fade(0.3)
          .toString()} !important`,
        color: `${theme.palette.grey.disabled_text} !important`,
      },
    },
    buttonSelected: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.success[50]}  !important`,
      color: `${theme.palette.success[600]} !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.success[50])
          .fade(0.3)
          .toString()} !important`,
        color: `${theme.palette.success[600]} !important`,
      },
      '&:disabled': {
        backgroundColor: `${Color(theme.palette.success[50])
          .fade(0.8)
          .toString()} !important`,
        color: `${theme.palette.success[600]} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonLightGreen: {
      borderRadius: '8px !important',
      backgroundColor: `${theme.palette.secondary[600]}  !important`,
      color: `${theme.palette.secondary[950]} !important`,
      '&:hover': {
        backgroundColor: `${Color(theme.palette.secondary[600])
          .fade(0.3)
          .toString()} !important`,
        color: `${theme.palette.secondary[950]} !important`,
      },
      '&:disabled': {
        backgroundColor: `${Color(theme.palette.secondary[600])
          .fade(0.8)
          .toString()} !important`,
        color: `${theme.palette.secondary[950]} !important`,
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    buttonInputAdornment: {
      backgroundColor: theme.palette.base.white,
      color: theme.palette.grey['900'],
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.base.white,
        color: theme.palette.grey['900'],
      },
      '&:disabled': {
        backgroundColor: theme.palette.base.white,
        color: theme.palette.grey['300'],
        cursor: 'auto',
        '&>*': {
          color: `${theme.palette.grey.disabled_text} !important`,
        },
      },
    },
    iconButtonWhiteBackground: {
      backgroundColor: '#fff !important',
      '&:hover': {
        backgroundColor: '#fff !important',
      },
      '&:disabled': {
        backgroundColor: '#fff !important',
      },
    },
    iconButtonTransparent: {
      backgroundColor: 'transparent !important',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '&:disabled': {
        backgroundColor: 'transparent !important',
      },
    },
    border2pxRadius8pxPadding24px: {
      borderRadius: '0.5rem',
      border: `0.0625rem solid ${theme.custom.primary4}`,
      padding: '1.5rem',
    },
    font12px500Black: {
      fontWeight: 500,
      fontSize: '0.75rem',
      color: theme.custom.black,
    },
    font12px600Primary2: {
      color: theme.custom.primary2,
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    font14px600Primary: {
      fontWeight: '600 !important',
      fontSize: '0.875rem !important',
      color: `${theme.palette.primary.main} !important`,
    },
    font18px600Black: {
      color: `${theme.custom.black} !important`,
      fontSize: '1.125rem !important',
      fontWeight: '600 !important',
    },
    border2pxRadius8pxPadding8px: {
      borderRadius: '0.5rem',
      border: `0.0625rem solid ${theme.custom.primary4}`,
      padding: '0.5rem',
    },
  })
);
