import { Box, Stack, Typography, useTheme, Theme } from '@mui/material';
import { FormattedNumber, useIntl } from 'react-intl';
import { createStyles, makeStyles } from '@mui/styles';
import {
  CommentSquareIcon,
  Eye2Icon,
  LikeIcon,
  ShareIcon,
} from '../assets/imgs/icons';
import { Messages } from '../localization/Messages';

type BenaSocialStatCardProps = {
  views: number;
  likes: number;
  comments: number;
  shares: number;
};
export function BenaSocialStatCard({
  views,
  likes,
  comments,
  shares,
}: BenaSocialStatCardProps) {
  const styles = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  return (
    <Box className="boxlefthcenterv" sx={{ gap: '0rem 1rem' }}>
      <Stack direction={'row'} gap={'0rem 0.25rem'} alignItems="center">
        <Eye2Icon
          stroke={theme.custom.black}
          className={styles.iconSize}
          title={intl.formatMessage(Messages.views)}
        />
        <Typography
          className={styles.number}
          title={intl.formatMessage(Messages.views)}
        >
          <FormattedNumber
            value={views}
            notation="compact"
            maximumSignificantDigits={3}
          />
        </Typography>
      </Stack>{' '}
      <Stack direction={'row'} gap={'0rem 0.25rem'} alignItems="center">
        <LikeIcon
          stroke={theme.custom.black}
          className={styles.iconSize}
          title={intl.formatMessage(Messages.likes)}
        />
        <Typography
          className={styles.number}
          title={intl.formatMessage(Messages.likes)}
        >
          <FormattedNumber
            value={likes}
            notation="compact"
            maximumSignificantDigits={3}
          />
        </Typography>
      </Stack>{' '}
      <Stack direction={'row'} gap={'0rem 0.25rem'} alignItems="center">
        <CommentSquareIcon
          stroke={theme.custom.black}
          sx={{ width: '0.8125rem', height: '0.8125rem' }}
          title={intl.formatMessage(Messages.comments)}
        />
        <Typography
          className={styles.number}
          title={intl.formatMessage(Messages.comments)}
        >
          <FormattedNumber
            value={comments}
            notation="compact"
            maximumSignificantDigits={3}
          />
        </Typography>{' '}
      </Stack>{' '}
      <Stack direction={'row'} gap={'0rem 0.25rem'} alignItems="center">
        <ShareIcon
          stroke={theme.custom.black}
          className={styles.iconSize}
          title={intl.formatMessage(Messages.shares)}
        />
        <Typography
          className={styles.number}
          title={intl.formatMessage(Messages.shares)}
        >
          <FormattedNumber
            value={shares}
            notation="compact"
            maximumSignificantDigits={3}
          />
        </Typography>
      </Stack>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconSize: {
      width: '1rem',
      height: '1rem',
    },
    number: {
      fontSize: '0.875rem',
      fontWeight: '500',
    },
  })
);
