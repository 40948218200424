import { Rating, RatingProps, styled } from '@mui/material';
import {
  FavoriteIcon,
  FavoriteOutlinedIcon,
  HeartIcon,
} from '../assets/imgs/icons';

const iconSize = { width: '1rem', height: '1rem' };

export const HeartRating = styled((props: RatingProps) => (
  <Rating
    {...props}
    icon={<HeartIcon sx={iconSize} />}
    emptyIcon={<HeartIcon sx={iconSize} fill={'white'} />}
  />
))(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.danger[400],
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.danger[400],
  },
}));

export const StarRating = styled((props: RatingProps & { fade?: boolean }) => (
  <Rating
    {...props}
    icon={<FavoriteIcon sx={iconSize} />}
    emptyIcon={<FavoriteOutlinedIcon sx={iconSize} fade={props.fade} />}
  />
))(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.secondary[600],
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.secondary[600],
  },
}));
