import { defineMessages } from 'react-intl';
export const OptionsMessages = defineMessages({
  BeautyCreator: { id: 'BeautyCreator', defaultMessage: 'Beauty creator' },
  Seeding: { id: 'Seeding', defaultMessage: 'Seeding' },
  Sponsorship: { id: 'Sponsorship', defaultMessage: 'Sponsorship' },
  DailyRoutine: { id: 'DailyRoutine', defaultMessage: 'Daily routine' },
  UGCCreator: { id: 'UGCCreator', defaultMessage: 'UGC creator' },
  Celebrity: { id: 'Celebrity', defaultMessage: 'Celebrity' },
  FashionCreator: { id: 'FashionCreator', defaultMessage: 'Fashion creator' },
  Model: { id: 'Model', defaultMessage: 'Model' },
  ImitationMakeup: {
    id: 'ImitationMakeup',
    defaultMessage: 'Imitation makeup',
  },
  FemaleEntrepreneur: {
    id: 'FemaleEntrepreneur',
    defaultMessage: 'Female entrepreneur',
  },
  SkinAndHairCare: {
    id: 'SkinAndHairCare',
    defaultMessage: 'Skin and hair care',
  },
  ExperienceSharing: {
    id: 'ExperienceSharing',
    defaultMessage: 'Experience sharing',
  },
  LGBT: { id: 'LGBT', defaultMessage: 'LGBT' },
  ContentConsistency: {
    id: 'ContentConsistency',
    defaultMessage: 'Content consistency',
  },
  EmotionalResonance: {
    id: 'EmotionalResonance',
    defaultMessage: 'Emotional resonance',
  },
  MultisceneSwitching: {
    id: 'MultisceneSwitching',
    defaultMessage: 'Multi-scene switching',
  },
  TopProfilesMentioning: {
    id: 'TopProfilesMentioning',
    defaultMessage: 'Top profiles mentioning',
  },
  TrendyHashtags: { id: 'TrendyHashtags', defaultMessage: 'Trendy hashtags' },
  PopularMusic: { id: 'PopularMusic', defaultMessage: 'Popular music' },
  MultirolePlay: { id: 'MultirolePlay', defaultMessage: 'Multi-role play' },
  MulticameraShooting: {
    id: 'MulticameraShooting',
    defaultMessage: 'Multi-camera shooting',
  },
  ShootingSkills: { id: 'ShootingSkills', defaultMessage: 'Shooting skills' },
  HotTopic: { id: 'HotTopic', defaultMessage: 'Hot topic' },
  ShortSeries: { id: 'ShortSeries', defaultMessage: 'Short series' },
  Confrontation: { id: 'Confrontation', defaultMessage: 'Confrontation' },
  ChallengeSticker: {
    id: 'ChallengeSticker',
    defaultMessage: 'Challenge sticker',
  },
  Explanation: { id: 'Explanation', defaultMessage: 'Explanation' },
  RichEmotionality: {
    id: 'RichEmotionality',
    defaultMessage: 'Rich emotionality',
  },
  FunnySubtitles: { id: 'FunnySubtitles', defaultMessage: 'Funny subtitles' },
  MultipleAngles: { id: 'MultipleAngles', defaultMessage: 'Multiple angles' },
  FollowersInteraction: {
    id: 'FollowersInteraction',
    defaultMessage: "Followers' interaction",
  },
  RichExpressions: {
    id: 'RichExpressions',
    defaultMessage: 'Rich expressions',
  },
  LipSync: { id: 'LipSync', defaultMessage: 'Lip sync' },
  Greenscreen: { id: 'Greenscreen', defaultMessage: 'Greenscreen' },
  Filter: { id: 'Filter', defaultMessage: 'Filter' },
  SeveralActors: { id: 'SeveralActors', defaultMessage: 'Several actors' },
  FabulousMakeup: { id: 'FabulousMakeup', defaultMessage: 'Fabulous makeup' },
  Transformation: { id: 'Transformation', defaultMessage: 'Transformation' },
  Cosplay: { id: 'Cosplay', defaultMessage: 'Cosplay' },
  TryOnAndDisplay: {
    id: 'TryOnAndDisplay',
    defaultMessage: 'Try on and display',
  },
  FaceCloseup: { id: 'FaceCloseup', defaultMessage: 'Face close-up' },
  Storytelling: { id: 'Storytelling', defaultMessage: 'Storytelling' },
  Comedy: { id: 'Comedy', defaultMessage: 'Comedy' },
  Drama: { id: 'Drama', defaultMessage: 'Drama' },
  Dance: { id: 'Dance', defaultMessage: 'Dance' },
  InspiredTutorial: {
    id: 'InspiredTutorial',
    defaultMessage: 'Inspired tutorial',
  },
  TheClearTopic: { id: 'TheClearTopic', defaultMessage: 'The clear topic' },
  Crazy: { id: 'Crazy', defaultMessage: 'Crazy' },
  EditingSkill: { id: 'EditingSkill', defaultMessage: 'Editing skill' },
  Narrative: { id: 'Narrative', defaultMessage: 'Narrative' },
  Vlogging: { id: 'Vlogging', defaultMessage: 'Vlogging' },
  Expressions: { id: 'Expressions', defaultMessage: 'Expressions' },
  InteractionsWithKids: {
    id: 'InteractionsWithKids',
    defaultMessage: 'Interactions with kids',
  },
  InteractionsWithPets: {
    id: 'InteractionsWithPets',
    defaultMessage: 'Interactions with pets',
  },
  Challenge: { id: 'Challenge', defaultMessage: 'Challenge' },
  ShootingAndEditing: {
    id: 'ShootingAndEditing',
    defaultMessage: 'Shooting and editing',
  },
  Tutorial: { id: 'Tutorial', defaultMessage: 'Tutorial' },
  Series: { id: 'Series', defaultMessage: 'Series' },
  Destressing: { id: 'Destressing', defaultMessage: 'Destressing' },
  Lifestyle: { id: 'Lifestyle', defaultMessage: 'Lifestyle' },
  ASMR: { id: 'ASMR', defaultMessage: 'ASMR' },
  Duet: { id: 'Duet', defaultMessage: 'Duet' },
  Activewear: { id: 'Activewear', defaultMessage: 'Activewear' },
  Unboxing: { id: 'Unboxing', defaultMessage: 'Unboxing' },
  DIY: { id: 'DIY', defaultMessage: 'DIY' },
  Painting: { id: 'Painting', defaultMessage: 'Painting' },
  Singer: { id: 'Singer', defaultMessage: 'Singer' },
  Plussize: { id: 'Plussize', defaultMessage: 'Plussize' },
  Hairstylist: { id: 'Hairstylist', defaultMessage: 'Hairstylist' },
  Sing: { id: 'Sing', defaultMessage: 'Sing' },
  Gym: { id: 'Gym', defaultMessage: 'Gym' },
  Cooking: { id: 'Cooking', defaultMessage: 'Cooking' },
  Shoes: { id: 'Shoes', defaultMessage: 'Shoes' },
  Couples: { id: 'Couples', defaultMessage: 'Couples' },
  Family: { id: 'Family', defaultMessage: 'Family' },
  Musician: { id: 'Musician', defaultMessage: 'Musician' },
  Couple: { id: 'Couple', defaultMessage: 'Couple' },
  Gamer: { id: 'Gamer', defaultMessage: 'Gamer' },
  Athlete: { id: 'Athlete', defaultMessage: 'Athlete' },
});
