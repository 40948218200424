import { ReactNode, useState } from 'react';
import { CreatorContext } from './context';

const DefaultUserActionData = {
  type: '',
  data: undefined,
} as ActionDataType;

type CreatorContextWrapperProps = {
  children: ReactNode;
};
export default function CreatorContextWrapper({
  children,
}: CreatorContextWrapperProps) {
  // context params
  const [context, setContext] = useState<{
    currentDisplayedCampaignId: string;
    currentScriptType: CreatorScriptType;
  }>({
    currentDisplayedCampaignId: '',
    currentScriptType: '',
  });

  const setCurrentDisplayedCampaignId = (id: string) => {
    setContext((state) => ({ ...state, currentDisplayedCampaignId: id }));
  };
  const setCurrentScriptTitle = (scriptType: CreatorScriptType) => {
    setContext((state) => ({ ...state, currentScriptType: scriptType }));
  }; // user action data stored in context
  const [userActionData, setUserActionData] = useState<ActionDataType>(
    DefaultUserActionData
  );
  const triggerUserAction = (type: CreatorRoleScriptActionType, data: any) => {
    setUserActionData({ type, data });
  };
  const resetUserActionData = () => {
    setUserActionData(DefaultUserActionData);
  };
  const [campaignIdForScriptTypeCampaign, setCampaignIdForScriptTypeCampaign] =
    useState('');

  return (
    <CreatorContext.Provider
      value={{
        ...context,
        setCurrentDisplayedCampaignId,
        setCurrentScriptTitle,
        triggerUserAction,
        userActionData,
        resetUserActionData,
        campaignIdForScriptTypeCampaign,
        setCampaignIdForScriptTypeCampaign,
      }}
    >
      {children}
    </CreatorContext.Provider>
  );
}
