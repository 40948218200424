import { useIntl } from 'react-intl';
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material';
import { UserAccountMessages } from './Messages';
import { ValidateRules } from '../../utils/common';
import BenaTermsAndPrivacy from '../../components/BenaTermsAndPrivacy';
import CollapsibleAlertPanel from '../../components/CollapsibleAlertPanel';
import GoBack from './GoBack';
import { useRegisterMutation } from '../../redux/benaApi';
import { useNavigate } from 'react-router-dom';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { useGlobalStyles } from '../../assets/styles/style';
import { useAppDispatch } from '../../redux/store';
import { setLogin } from './AuthSlice';
import LogoAndLanguage from './LogoAndLanguage';
import { showMessage } from '../../components/ShowMessage';

type RegisterAccountPasswordProps = {
  role: RegisterRoleType;
  nextStep?: fnVoidToVoid;
};
export default function RegisterAccountPassword({
  role,
  nextStep,
}: RegisterAccountPasswordProps) {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  const globalStyles = useGlobalStyles();
  const dispatch = useAppDispatch();

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputDataType,
    key: string
  ) => {
    const str = event.target.value.slice(0, data.maxLength);
    setFormData({
      ...formData,
      // cancel the red error hightlight when input change
      createPassword: { ...formData.createPassword, error: false },
      repeatPassword: { ...formData.repeatPassword, error: false },
      [key]: {
        ...data,
        content: str,
        length: event.target.value.length,
        error: !ValidateRules.validInputString(
          str,
          data.maxLength,
          data.inputValueType
        ),
      },
    });
  };

  const [formData, setFormData] = useState<AccountPasswordType>({
    createPassword: {
      content: '',
      maxLength: 80,
      error: false,
      showPassword: false,
      inputValueType: 'password',
    },
    repeatPassword: {
      content: '',
      maxLength: 80,
      error: false,
      showPassword: false,
      inputValueType: 'password',
    },
  });

  const [openAlertTermsAndPolicy, setOpenAlertTermsAndPolicy] = useState(false);

  const togglePasswordVisibility = (
    key: string,
    data: PasswordInputDataType
  ) => {
    setFormData({
      ...formData,
      createPassword: {
        ...formData.createPassword,
        showPassword: !data.showPassword,
      },
      repeatPassword: {
        ...formData.repeatPassword,
        showPassword: !data.showPassword,
      },
    });
  };

  const [checkedTermsAndPolicy, setCheckedTermsAndPolicy] = useState(false);

  const refCreatePassword = useRef<HTMLElement>(null);
  const refRepeatPassword = useRef<HTMLElement>(null);
  const refTermsAndPolicy = useRef<HTMLInputElement>(null);
  useEffect(() => {
    refCreatePassword.current?.focus();
  }, []);

  const inputList = [
    {
      item: formData.createPassword,
      message: UserAccountMessages.createPassword,
      name: 'createPassword',
      refEl: refCreatePassword,
      placeHolder: UserAccountMessages.enterAPassword,
    },
    {
      item: formData.repeatPassword,
      message: UserAccountMessages.repeatPassword,
      name: 'repeatPassword',
      refEl: refRepeatPassword,
      placeHolder: UserAccountMessages.repeatAPassword,
    },
  ] as Array<FormInputType>;

  const checkSamePassword = (saveFormData = true) => {
    if (
      !ValidateRules.samePasswords(
        formData.createPassword.content,
        formData.repeatPassword.content
      )
    ) {
      saveFormData &&
        setFormData({
          ...formData,
          createPassword: { ...formData.createPassword, error: true },
          repeatPassword: { ...formData.repeatPassword, error: true },
        });
      return false;
    }
    return true;
  };

  const isValidForSave = function (saveFormData = true): boolean {
    for (let i = 0; i < inputList.length; i++) {
      const { item: input, refEl: ref, name: key } = inputList[i];
      if (
        !ValidateRules.validInputString(
          input.content,
          input.maxLength,
          input.inputValueType
        )
      ) {
        if (saveFormData) {
          setFormData({
            ...formData,
            [key]: { ...input, error: true },
          });
          ref.current?.focus();
        }
        return false;
      }
    }
    if (!checkSamePassword(saveFormData)) {
      saveFormData && refCreatePassword.current?.focus();
      return false;
    }

    if (!checkedTermsAndPolicy) {
      if (saveFormData) {
        refTermsAndPolicy.current?.focus();
        setOpenAlertTermsAndPolicy(true);
      }
      return false;
    }

    return saveFormData ? true : !submitting;
  };
  const [submitting, setSubmitting] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const [register] = useRegisterMutation();

  const handleContinue = async () => {
    if (isValidForSave()) {
      const params: RegisterRequestParams = {
        name: urlParams.get('name') || '',
        email: urlParams.get('email') || '',
        token: urlParams.get('token') || '',
        password: formData.createPassword.content,
        role: (urlParams.get('role') || 'creator') as RegisterRoleType,
      };
      setSubmitting(true);
      (await MutationHandler(
        {
          intl,
          action: async () => await register(params).unwrap(),
          onSuccess: async (data) => {
            dispatch(setLogin(data));
            let localState = localStorage.getItem('plugin:state');
            let localRedirect = sessionStorage.getItem('plugin:redirect');
            if (localState && window.opener) {
              localStorage.removeItem('plugin:state');
              window.opener.postMessage( { type: 'login', data }, "*" );
              window.close();
            }else if(localRedirect){
              sessionStorage.removeItem('plugin:redirect');
              window.postMessage( { type: 'bena-login', data }, "*" );
              if(window.opener){
                window.close();
              }else{
                setTimeout(() => { window.location.href = localRedirect as string; }, 200);
              }
            }else{
              nextStep && nextStep();
            }
          },
          onError: (error) => {
            console.log(error);
          },
        },
        UserAccountMessages.registerSucceed
      )) && setSubmitting(false);
    }
  };

  return (
    <Box>
      <LogoAndLanguage />
      <GoBack />
      <Box sx={{ mt: '1rem' }} className="form-width">
        <Typography sx={{ fontSize: '1.5rem', mb: 4, fontWeight: 700 }}>
          {intl.formatMessage(
            role === 'brand'
              ? UserAccountMessages.registerBrandAccount
              : UserAccountMessages.registerCreatorAccount
          )}
        </Typography>
        <form>
          {inputList.map((data) => {
            const { item, message, name, refEl, placeHolder } = data;
            return (
              <Box
                key={message.id}
                className={'boxcenterhv'}
                sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 1.75,
                    color: theme.palette.grey[800],
                    fontSize: '0.625rem',
                    fontWeight: 600,
                  }}
                  component="div"
                  className={'boxcenterhv'}
                >
                  {intl.formatMessage(message).toUpperCase()}
                  <Typography
                    sx={{
                      color: theme.palette.danger[400],
                      ml: '0.25rem',
                      fontSize: '0.75rem',
                      alignSelf: 'stretch',
                    }}
                  >
                    *
                  </Typography>
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    placeholder={intl.formatMessage(placeHolder)}
                    inputRef={refEl}
                    type={item.showPassword ? 'text' : 'password'}
                    className={`border-radius-6px input-size`}
                    id={message.id}
                    value={item.content}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleTextInputChange(event, item, name);
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={(theme) => ({ fontSize: theme.typography.caption })}
                      >
                        <IconButton
                          onClick={() => togglePasswordVisibility(name, item)}
                          disableRipple
                        >
                          {item.showPassword ? (
                            <Typography variant="subtitle1" color="primary">
                              {intl.formatMessage(UserAccountMessages.hide)}
                            </Typography>
                          ) : (
                            <Typography variant="subtitle1" color="primary">
                              {intl.formatMessage(UserAccountMessages.show)}
                            </Typography>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={item.error}
                    fullWidth
                  />
                </FormControl>
              </Box>
            );
          })}
          <Box sx={{ mb: 2 }}>
            {!ValidateRules.samePasswords(
              formData.createPassword.content,
              formData.repeatPassword.content
            ) && (
              <Box color={theme.palette.danger[400]}>
                <img
                  src={'/imgs/passswordsMismatch.svg'}
                  style={{ marginRight: theme.spacing(2) }}
                  alt="alert"
                />
                <Typography component="span">
                  {intl.formatMessage(UserAccountMessages.passwordDonotMatch)}
                  {'.  '}
                  {intl.formatMessage(UserAccountMessages.passwordSettingRules)}
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={{ mb: 4.5, ml: 1 }}>
            <CollapsibleAlertPanel
              open={openAlertTermsAndPolicy}
              message={intl.formatMessage(
                UserAccountMessages.alertCheckPolicyAndPrivacy
              )}
              handleClose={(close: boolean) => {
                setOpenAlertTermsAndPolicy(close);
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  inputRef={refTermsAndPolicy}
                  checked={checkedTermsAndPolicy}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCheckedTermsAndPolicy(e.target.checked)
                  }
                />
              }
              label={
                <Box sx={{ width: '21.875rem' }}>
                  <BenaTermsAndPrivacy
                    fnShowPrivacyPolicy={() => navigate('../privacy')}
                    fnShowTermsOfService={() => navigate('../terms')}
                  />
                </Box>
              }
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              type="submit"
              className={`border-radius-6px button-size`}
              onClick={(e) => {
                e.preventDefault();
                handleContinue();
              }}
              disabled={!isValidForSave(false)}
            >
              {intl.formatMessage(UserAccountMessages.register)}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
