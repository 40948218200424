import {
  SelectArrowDownBlackIcon,
  SelectArrowDownWhiteIcon,
} from '../assets/imgs/icons';
import { useThemeMode } from './theme';

export function useSelectDropDownArrow() {
  const isDarkMode = useThemeMode() === 'dark';
  const SelectArrow = isDarkMode
    ? SelectArrowDownWhiteIcon
    : SelectArrowDownBlackIcon;

  return SelectArrow;
}
