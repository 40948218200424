import { Box } from '@mui/material';
import { capitalize } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getShortList } from '../features/creators/shortListSlice';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoaderMessage,
} from '../components/InfiniteScrollMessages';
import ScrollToTopButton from '../components/ScrollToTopButton';
import STabs from '../components/STabs';
import {
  selectCreatorQuery,
  setFilterType,
} from '../features/search/dynamicSearchSlice';
import SearchResults from '../features/search/SearchResults';
import { Messages } from '../localization/Messages';
import {
  useGetCreatorsQuery,
  useGetCreatorsInListQuery
} from '../redux/benaApi';
import { useTypedSelector, persistor } from '../redux/store';
import useInfiniteScroll from '../utils/useInfiniteScroll';
import { selectAIGC, setSelectedCreatorId } from '../features/aigc/AIGCSlice';
import { ModerationDialog } from '../features/admin/CreatorModeration';
import CreativeStoryDialog from '../features/aigc/CreativeStoryDialog';
import { useGetCreatorData } from '../hooks/creators';
import { selectAuth } from '../features/account/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { staticURLLogin } from '../route/urls';
import { AIGCDialogContext, ModeratedItemsContext } from '../context/context';
import BenaJoyRide from '../features/joyride/BenaJoyRide';
import {
  useStabsFindTabIndexByQueryStringTab,
  useUpdateURLQueryString,
} from '../utils/common';
import { useIsBenaUser } from '../hooks/account';
import MansonryView from '../components/layout/MansonryView';

export default function Homepage() {
  const navigate = useNavigate();
  const queryParams = useTypedSelector(selectCreatorQuery);
  
  const {
    combinedData: creatorDataArr,
    setCombinedData: setCreatorDataArr,
    remoteTotal,
    hasMore,
    readMore,
    refresh,
    isFetching,
    isLoading,
  } = useInfiniteScroll<CreatorData>(useGetCreatorsQuery, {
    limit: 12,
    queryParams,
  });

  useEffect(() => {
    refresh();
  }, [queryParams, refresh]);
  
  // Batch updates favorites
  const updateBatchFavorites = useCallback((list: string[])=>{
    let newArr = [...creatorDataArr];
    newArr.forEach((item)=>{
      item.shortlist = [...new Set([...list, ...item.shortlist || []])]
    });
    
    setCreatorDataArr(newArr);
  },[creatorDataArr, setCreatorDataArr])

  const [currentTab, setCurrentTab] = useState(0);
  const tabsTitleName = ['creators', 'favorites'];
  // update tab index from tab qs of url
  const tabIndexFromURL = useStabsFindTabIndexByQueryStringTab(tabsTitleName);
  useEffect(() => {
    setCurrentTab(tabIndexFromURL);
  }, [tabIndexFromURL]);

  const dispatch = useDispatch();
  
  const handleInfiniteScrollNext = useCallback(() => {
    readMore();
  }, [readMore]);
  useEffect(() => {
    dispatch(getShortList());
    dispatch(setFilterType('creator'));
    dispatch(setSelectedCreatorId(''));
  }, []);

  const creatorId = useTypedSelector((state) =>
    selectAIGC(state)
  ).selectedCreatorId;

  const {
    creator,
    setCreator,
    refresh: refreshCreatorData,
  } = useGetCreatorData(creatorId);
  useEffect(() => {
    creatorId && refreshCreatorData();
  }, [creatorId]);

  // redirect user to login page if not
  const user = useTypedSelector((state) => selectAuth(state)).user;
  useEffect(() => {
    if (!user) {
      navigate(staticURLLogin);
    }
  }, [user]);

  // moderation results per session
  const [moderatedItems, setModeratedItems] = useState<ModeratedItemsType>({});
  const [deletedItems, setDeletedItems] = useState<ModeratedItemsType>({});
  const updateModerated = (id: string) => {
    setModeratedItems({ ...moderatedItems, [id]: true });
  };
  const notifyDelete = (id: string) => {
    setDeletedItems({ ...deletedItems, [id]: true });
  };

  // used for favorite between aigc left panel and creator card
  const [favoritedCreators, setFavoritedCreators] = useState<
    FavoritedCreator[]
  >([]);
  const setFavorite = ({ id, favorite }: FavoritedCreator) => {
    const index = favoritedCreators.findIndex((v) => v.id === id);
    if (index >= 0) {
      const newFavorited = [
        ...favoritedCreators.slice(0, index),
        { id, favorite },
        ...favoritedCreators.slice(index + 1),
      ];
      setFavoritedCreators(newFavorited);
    } else {
      setFavoritedCreators([...favoritedCreators, { id, favorite }]);
    }
  };

  // sync data from favorited tab
  const [favoritedCreatorData, setFavoritedCreatorData] = useState<
    CreatorData[]
  >([]);

  // sync data from suggest list
  const [suggestCreatorData, setSuggestCreatorData] = useState<CreatorData[]>(
    []
  );

  const [prevUrl, setPrevUrl] = useState('');

  return (
    <ModeratedItemsContext.Provider
      value={{
        items: moderatedItems,
        deleted: deletedItems,
        setItem: updateModerated,
        notifyDelete,
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Background />
        <AIGCDialogContext.Provider
          value={{
            creator,
            creatorData: creatorDataArr
              .concat(favoritedCreatorData)
              .concat(suggestCreatorData)
              .find((v) => v.id === creatorId),
            favoritedCreators,
            notifyFavorite: setFavorite,
            reset: () => {
              setCreator(null);
            },
            prevUrl,
            setPrevUrl,
            notifyCreator: (creator) => {
              setSuggestCreatorData([creator]);
            },
          }}
        >
          <SearchResults
            isFetchingOrLoading={isFetching || isLoading}
            refreshResults={refresh}
            dataLength={creatorDataArr.length}
            // trigger search only for creators tab (tabindex=0)
            hasMore={currentTab <= 0 && hasMore}
            handleInfiniteScrollNext={handleInfiniteScrollNext}
            updateBatchFavorites={updateBatchFavorites}
            cardView={
              <HomepageTabs
                creators={creatorDataArr}
                total={remoteTotal}
                onTabChanged={setCurrentTab}
                nofityFavoritedCreatorData={setFavoritedCreatorData}
              />
            }
          />
          <BenaJoyRide
            loaded={creatorDataArr.length >= 1}
            demoCreatorId={creatorDataArr[0]?.id}
          />
          <ScrollToTopButton />
          <ModerationDialog />
          <CreativeStoryDialog />
        </AIGCDialogContext.Provider>
      </Box>
    </ModeratedItemsContext.Provider>
  );
}

function Background() {
  return (
    <>
      {[
        { src: '/imgs/bg01.svg', left: '-30px', top: '10px', right: 'auto' },
        { src: '/imgs/bg02.svg', left: '-30px', top: '160px', right: 'auto' },
        { src: '/imgs/bg03.svg', left: 'auto', top: '120px', right: '-30px' },
      ].map(({ src, left, top, right }) => (
        <img
          key={src}
          src={src}
          style={{ position: 'absolute', left, top, right }}
          alt=""
        />
      ))}
    </>
  );
}


function dedupFn(item: CreatorData, arr: CreatorData[]) {
  return !!arr.find((w) => w.id === item.id);
}

type FavoritedCreatorsTabProps = {
  onTotalChange: (total: number, data: CreatorData[]) => void;
};
function FavoritedCreatorsTab({ onTotalChange }: FavoritedCreatorsTabProps) {
  const queryParams = '&filters={"favorite":true}';
  const {
    combinedData: creatorDataArr,
    remoteTotal,
    hasMore,
    readMore,
    refresh,
    isLoading,
    isFetching,
  } = useInfiniteScroll<CreatorData>(
    // useGetCreatorsQuery,
    useGetCreatorsInListQuery,
    {
      limit: 12,
      queryParams,
    },
    dedupFn
  );

  useEffect(() => {
    refresh();
  }, [queryParams, refresh]);

  const handleInfiniteScrollNext = useCallback(() => {
    readMore();
  }, [readMore]);

  useEffect(() => {
    onTotalChange(remoteTotal, creatorDataArr);
  }, [creatorDataArr, remoteTotal]);

  return (
    <InfiniteScroll
      dataLength={creatorDataArr.length}
      next={handleInfiniteScrollNext}
      hasMore={hasMore}
      loader={<InfiniteScrollLoaderMessage visible={isFetching || isLoading} />}
      endMessage={<InfiniteScrollEndMessage />}
      scrollableTarget="infinite-scroll-masonry-favorite"
      scrollThreshold={0.6}
    >
      <Box sx={{}}>
        <MansonryView
          creators={creatorDataArr}
          total={remoteTotal}
          loading={isLoading || isFetching}
          scrollElId={'infinite-scroll-masonry-favorite'}
        />
      </Box>
    </InfiniteScroll>
  );
}

type HomepageTabsProps = {
  creators: CreatorData[];
  total: number;
  onTabChanged: fnNumberToVoid;
  nofityFavoritedCreatorData: (data: CreatorData[]) => void;
};
function HomepageTabs({
  creators,
  total,
  onTabChanged,
  nofityFavoritedCreatorData,
}: HomepageTabsProps) {
  const intl = useIntl();
  const [favoritedCreatorsTotal, setFavoritedCreatorsTotal] = useState(0);
  const tabsTitleName = ['creators', 'favorites'];
  const tabIndexFromURL = useStabsFindTabIndexByQueryStringTab(tabsTitleName);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  useEffect(() => {
    tabIndexFromURL >= 0 && setSelectedTabIndex(tabIndexFromURL);
  }, [tabIndexFromURL]);

  const _handleTabChange = useUpdateURLQueryString('tab');
  const handleTabChange = (index: number) => {
    _handleTabChange(tabsTitleName[index]);
    onTabChanged(index);
  };

  const onTotalChange = (total: number, data: CreatorData[]) => {
    setFavoritedCreatorsTotal(total);
    nofityFavoritedCreatorData(data);
  };

  const isBenaUser = useIsBenaUser();
  

  return (
    <STabs
      tabs={[
        {
          name: `${capitalize(intl.formatMessage(Messages.creators))}${
            isBenaUser ? ` (${total})` : ''
          }`,
          children: (
            <MansonryView creators={creators} total={total} loading={false} />
          ),
        },
        {
          name: `${capitalize(intl.formatMessage(Messages.favorites))} ${
            favoritedCreatorsTotal ? `(${favoritedCreatorsTotal})` : ''
          }`,
          children: <FavoritedCreatorsTab onTotalChange={onTotalChange} />,
        },
      ]}
      tabTitleFontSize="0.9375rem"
      selectedIndex={selectedTabIndex}
      tabTitlePaddingBottom={'0rem'}
      justifyContentCenter={true}
      onTabChange={(tab) => handleTabChange(tab)}
    />
  );
}
