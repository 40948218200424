import {
  memo
} from 'react';
import { Box, Typography } from '@mui/material';
import LightTooltip from '../../components/LightTooltip';
import { createStyles, makeStyles } from "@mui/styles"
import { Theme } from '@mui/material/styles';

function IconCounts({
  icon, count, tips
}:{
  icon: React.ReactNode; count: string | number; tips: string
}) {
  const classes = useStyles();
  return (
    <Box>
      <LightTooltip title={tips} >
        <Box className={classes.iconBoxStyle}>
          {icon}
        </Box>
      </LightTooltip>
      <Typography component="div" color="grey.50" lineHeight="20px" textAlign="center" >
        {count}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    iconBoxStyle: {
      width: '16px',
      height: '16px',
      lineHeight: '16px',
      textAlign: 'center',
      marginBottom: "2px"
    }
  })
);

export default memo(IconCounts);