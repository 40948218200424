import { useContext, useEffect, useState } from 'react';
import {
  useLazyGetChatbotChatApplicationHistoryMessagesQuery,
  usePostChatbotChatApplicationMutation,
  usePostChatbotChatContentMutation,
  usePostChatbotChatEmailMutation,
  usePostChatbotChatScriptMutation,
} from '../../redux/benaApi';
import { useIntl } from 'react-intl';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../../redux/benaApiResultHandler';
import { CreatorContext } from '../context/context';

function genConversationId() {
  return Math.random().toString(36).slice(2);
}

// init chatbot messages
const InitChatBotMessages = {
  // video script generation
  script: {
    conversation_id: '', // random uuid generated by frontend when the page is loaded
    msg_id: 'init_bot_msg',
    msg_type: 'suggestion',
    msg_text:
      "Hello, I'm an AI created by Bena, and I'm here to help you with writing a video script! \n\nTo get started, please tell me what you would like your video to be about.",
    msg_data: {
      suggestions: [
        'Write a script that will provide a sneak peek of the upcoming human hair wig and create a sense of anticipation and excitement for with a clear and compelling call-to-action.',
        'Write a video script that will showcase my unique and personal experiences with Nike sneaker and encourage my followers to share their opinions',
      ],
    },
  },
  // email generation
  email: {
    conversation_id: '', // random uuid generated by frontend when the page is loaded
    msg_id: 'init_bot_msg',
    msg_type: 'suggestion',
    msg_text:
      "Hello, I'm an AI created by Bena, and I'm here to help you with writing an email! \n\nTo get started, please tell me a little bit about yourself, who you're writing the email to, and what the subject of the email is.",
    msg_data: {
      suggestions: [
        'What should I pay attention to when writing an email?',
        'If I want to write a personal proposal to a brand, is there any template that I can follow?',
        'Please emphasize my story telling style in 4-5 sentences.',
      ],
    },
  },
  // content idea generation
  content: {
    conversation_id: '', // random uuid generated by frontend when the page is loaded
    msg_id: 'init_bot_msg',
    msg_type: 'suggestion',
    msg_text:
      "Hello, I'm an AI created by Bena, and I'm here to help you brainstorm some content ideas! \n\nTo get started, please tell me what kind of content you are interested in making.",
    msg_data: {
      suggestions: [
        'Create a fresh and engaging idea to promote a wig under 30 secs.',
        'What are some video titles that would go well with a video featuring an electric bicycle race?',
        'Please generate some video ideas to promote yoga pants.',
      ],
    },
  },
};

export function genMsg({
  reqDate,
  type,
  text,
  action,
  images,
  actionOptions,
  conversation_id,
  msg_data,
}: {
  reqDate: string;
  type: ScriptItemContentType;
  text: string;
  action?: string;
  images?: string[];
  actionOptions?: ChatbotOptionType[];
  conversation_id: string;
  msg_data?: {
    suggestions?: string[];
    links?: { label: string; url: string }[];
  };
}) {
  return {
    conversation_id: conversation_id,
    created_at: reqDate,
    content: {
      type: type,
      text,
      action: (action || '') as CreatorRoleScriptActionType,
      images: images || [],
      actionOptions: actionOptions || [],
      msg_data,
    },
  };
}

export function convertToReadableMessage(msg: ChatbotMessageValueType) {
  if (typeof msg === 'string' || typeof msg === 'number') {
    return `${msg}`;
  } else if (Array.isArray(msg)) {
    return msg.join(',');
  } else if (typeof msg === 'object' && msg.start && msg.end) {
    return `${msg.start} - ${msg.end}`;
  } else {
    return JSON.stringify(msg);
  }
}

export function useQueryCreatorAIGCScripts() {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const [emailQ] = usePostChatbotChatEmailMutation({});
  const [contentQ] = usePostChatbotChatContentMutation({});
  const [scriptQ] = usePostChatbotChatScriptMutation({});
  const [applicationQ] = usePostChatbotChatApplicationMutation({});
  const [dataArr, setDataArr] = useState<CreatorRoleScriptItemType[]>([]);
  const [resArr, setResArr] = useState<any>([]);
  const [initConversationId, setInitConversationId] = useState('');
  const getConversationId = () => {
    return dataArr[0]?.conversation_id || initConversationId;
  };

  const preRequest = (request: any) => {
    setLoading(true);
    const reqDate = new Date().toLocaleDateString();
    const newRequest = {
      ...request,
      // remove msg_label
      msg_label: undefined,
      prev_bot_msg: resArr.slice(-1).length === 0 ? null : resArr.slice(-1)[0],
    };
    const reqMsg = genMsg({
      reqDate,
      type: 'user',
      text: request.msg_label || convertToReadableMessage(request.msg_text),
      conversation_id: getConversationId(),
    });
    return [newRequest, reqMsg];
  };

  const postRequest = (data: any, reqMsg: any) => {
    const resMsg = genMsg({
      reqDate: new Date().toLocaleDateString(),
      type: 'robot',
      text: convertToReadableMessage(data.msg_text),
      action: data.msg_type,
      images: data.msg_type === 'images' ? data.msg_data?.urls || [] : [],
      actionOptions: data.options,
      conversation_id: getConversationId(),
      msg_data: {
        ...(data.msg_data || {}),
        suggestions: data.msg_data?.suggestions || [],
        links: data.msg_data?.links || [],
      },
    });
    setDataArr((arr) => [...arr, resMsg]);
    setResArr((arr: any) => [...arr, data]);
  };

  const askChatEmail = async (request: ChatbotMessageEmailRequestType) => {
    const [newRequest, reqMsg] = preRequest(request);
    await MutationHandler({
      intl,
      action: async () => {
        setDataArr((arr) => [...arr, reqMsg]);
        return await emailQ(newRequest).unwrap();
      },
      onSuccess: (data) => {
        postRequest(data, reqMsg);
      },
    });

    setLoading(false);
  };

  const askChatContent = async (request: ChatbotMessageContentRequestType) => {
    const [newRequest, reqMsg] = preRequest(request);
    await MutationHandler({
      intl,
      action: async () => {
        setDataArr((arr) => [...arr, reqMsg]);
        return await contentQ(newRequest).unwrap();
      },
      onSuccess: (data) => {
        postRequest(data, reqMsg);
      },
    });

    setLoading(false);
  };

  const askChatScript = async (request: ChatbotMessageScriptRequestType) => {
    const [newRequest, reqMsg] = preRequest(request);
    await MutationHandler({
      intl,
      action: async () => {
        setDataArr((arr) => [...arr, reqMsg]);
        return await scriptQ(newRequest).unwrap();
      },
      onSuccess: (data) => {
        postRequest(data, reqMsg);
      },
    });

    setLoading(false);
  };

  const askChatApplication = async (
    request: ChatbotMessageScriptRequestType
  ) => {
    const [newRequest, reqMsg] = preRequest(request);
    await MutationHandler({
      intl,
      action: async () => {
        setDataArr((arr) => [...arr, reqMsg]);
        return await applicationQ(newRequest).unwrap();
      },
      onSuccess: (data) => {
        postRequest(data, reqMsg);
      },
    });

    setLoading(false);
  };

  // TODO query history messages
  const queryFn = (page?: number) => {
    return;
  };

  const page = 1;
  const total = 0;

  const initChatBotScript = (scriptType: string) => {
    const id = genConversationId();
    setInitConversationId(id);
    const initMsg =
      InitChatBotMessages[scriptType as keyof typeof InitChatBotMessages];
    if (initMsg) {
      const msg = genMsg({
        reqDate: new Date().toLocaleDateString(),
        type: 'robot',
        text: convertToReadableMessage(initMsg.msg_text),
        action: initMsg.msg_type,
        images: [],
        actionOptions: [],
        conversation_id: id,
        msg_data: initMsg.msg_data,
      });
      setDataArr([msg]);
      setResArr([{ ...initMsg, conversation_id: id }]);
    }
  };

  const appendBotMsgFromUserAction = (msg: MessageResponseType) => {
    const newMsg = genMsg({
      reqDate: new Date().toLocaleDateString(),
      type: 'robot',
      text: convertToReadableMessage(msg.msg_text),
      action: msg.msg_type,
      images: msg.msg_type === 'images' ? msg.msg_data?.urls || [] : [],
      actionOptions: msg.options || [],
      conversation_id: getConversationId(),
      msg_data: msg.msg_data,
    });
    setDataArr((state) => [...state, newMsg]);
    setResArr((state: any) => [...state, msg]);
  };

  return {
    dataArr,
    loading,
    queryFn,
    setDataArr,
    setResArr,
    page,
    total,
    askChatEmail,
    askChatContent,
    askChatScript,
    askChatApplication,
    initChatBotScript,
    appendBotMsgFromUserAction,
  };
}

export function useQueryApplicationHistoryMessages(type: CreatorScriptType) {
  const intl = useIntl();
  const { campaignIdForScriptTypeCampaign } = useContext(CreatorContext);
  const [dataArr, setDataArr] = useState<CreatorRoleScriptItemType[]>([]);
  const [originDataArr, setOriginDataArr] = useState<any[]>([]);
  const [queryHistory, queryHistoryResult] =
    useLazyGetChatbotChatApplicationHistoryMessagesQuery({});

  useEffect(() => {
    if (type === 'campaign') {
      queryHistory({ campaign_id: campaignIdForScriptTypeCampaign });
    }
  }, []);

  useEffect(() => {
    if (queryHistoryResult.isSuccess) {
      LazyQueryResultHandler({
        intl,
        result: queryHistoryResult,
        onSuccess: (data) => {
          const arr =
            data.messages?.map((w) =>
              genMsg({
                reqDate: new Date().toLocaleDateString(),
                type: w.sender ? 'user' : 'robot',
                text: convertToReadableMessage(w.msg_text),
                action: w.msg_type,
                images: [],
                actionOptions: undefined,
                conversation_id: w.msg_id,
                msg_data: {
                  ...w.msg_data,
                  suggestions: w.msg_data?.suggestions || [],
                  links: w.msg_data?.links || [],
                },
              })
            ) || [];
          setDataArr(arr.reverse());
          setOriginDataArr(data.messages || []);
        },
      });
    }
  }, [queryHistoryResult]);

  return { historyMessages: dataArr, originDataArr };
}
