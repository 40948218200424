import { defineMessages } from 'react-intl';

import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
type BenaTermsAndPrivacyProps = {
  fnShowPrivacyPolicy: fnVoidToVoid;
  fnShowTermsOfService: fnVoidToVoid;
};

export default function BenaTermsAndPrivacy({
  fnShowPrivacyPolicy,
  fnShowTermsOfService,
}: BenaTermsAndPrivacyProps) {
  const theme = useTheme();
  const intl = useIntl();
  const space = ' ';
  return (
    <Box>
      <Typography sx={{ color: theme.custom.gray }} component="span">
        {intl.formatMessage(Messages.BenaTermsAndPrivacy1)}
        {space}
        <Typography color="primary.main" component="span">
          <a
            href="https://www.bena.live/privacy.html"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage(Messages.BenaPrivacyPolicy)}
          </a>
        </Typography>
        {space}
        {intl.formatMessage(Messages.BenaTermsAndPrivacy2)}
        {space}
        <Typography color="primary.main" component="span">
          <a
            href="https://www.bena.live/terms.html"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage(Messages.BenaTermsOfService)}
          </a>
        </Typography>
        {space}
        {intl.formatMessage(Messages.BenaTermsAndPrivacy3)}
      </Typography>
    </Box>
  );
}
const Messages = defineMessages({
  BenaTermsOfService: {
    id: 'BenaTermsOfService',
    defaultMessage: 'Bena Terms of Service',
  },
  BenaPrivacyPolicy: {
    id: 'BenaPrivacyPolicy',
    defaultMessage: 'Bena Privacy Policy',
  },
  BenaTermsAndPrivacy1: {
    id: 'BenaTermsAndPrivacy1',
    defaultMessage: `By clicking here, you agree to the `,
  },
  BenaTermsAndPrivacy2: {
    id: 'BenaTermsAndPrivacy2',
    defaultMessage: ` and acknowledge you have read the `,
  },
  BenaTermsAndPrivacy3: {
    id: 'BenaTermsAndPrivacy3',
    defaultMessage: ` to learn how we collect, use and share your data.`,
  },
});
