import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useGlobalStyles } from '../../assets/styles/style';
import { useDeleteUserMutation } from '../../redux/benaApi';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { useLogout } from '../../hooks/account';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { BenaConfirmationOKCancelModal } from '../../components/BenaConfirmationModal';
import { UserAccountMessages } from './Messages';

export default function DeleteAccount() {
  const theme = useTheme();
  const intl = useIntl();
  const gs = useGlobalStyles();
  const isMobileView = useBreakpointXs();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteUser] = useDeleteUserMutation({});
  const [logoutHandler] = useLogout();
  const handleDelete = async () => {
    await MutationHandler({
      intl,
      action: async () => {
        return await deleteUser({}).unwrap();
      },
      onSuccess: () => {
        // logout if user is deleted
        logoutHandler();
      },
    });
  };

  return (
    <Box sx={{ ml: { md: 5, xs: 0 }, pt: 1, mb: 6 }}>
      {showConfirmation && (
        <BenaConfirmationOKCancelModal
          setNullData={() => {
            setShowConfirmation(false);
            return;
          }}
          title={intl.formatMessage(UserAccountMessages.DeleteAccount)}
          message={intl.formatMessage(
            UserAccountMessages.DeleteAccountConfirmation
          )}
          onConfirm={async () => {
            await handleDelete();
          }}
          showDeletionStyle={true}
        />
      )}
      <Stack rowGap="2rem" sx={{ paddingTop: isMobileView ? '1rem' : '0rem' }}>
        <Typography color={theme.custom.gray}>
          {intl.formatMessage(UserAccountMessages.DeleteAccountHint)}
        </Typography>
        <Button
          className={gs.buttonDeletion}
          sx={{ borderRadius: '6px' }}
          onClick={() => {
            setShowConfirmation(true);
          }}
        >
          {intl.formatMessage(UserAccountMessages.DeleteAccount)}
        </Button>
      </Stack>
    </Box>
  );
}
