import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Typography,
  capitalize,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  BenaSearchIcon,
  CrossLineIcon,
  EraserIcon,
} from '../../../assets/imgs/icons';
import { useGlobalStyles } from '../../../assets/styles/style';
import { skipObjectValue } from '../../../utils/common';
import { useIntl } from 'react-intl';
import { Messages } from '../../../localization/Messages';
import { CampaignProgressFilter } from './CampaignProgressFilter';
import { IncomingProposalsFilter } from './IncomingProposalsFilter';

type SOPSearchProps = {
  searchInputPlaceholder?: string;
  handleSearch: fnStringToVoid;
  filterType: 'invited-creators' | 'incoming-proposal' | 'campaign-progress';
};
export function SOPSearch({
  searchInputPlaceholder = '',
  handleSearch,
  filterType,
}: SOPSearchProps) {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const gc = useGlobalStyles();

  const [clearFilter, setClearFilter] = useState(false);

  const [searchTerms, setSearchTerms] = useState('');
  // not necessary to refresh ui, save data only
  const refFilterQuery = useRef('');

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      submitSearch();
    }
  };
  const submitSearch = () => {
    let fq = refFilterQuery.current ? JSON.parse(refFilterQuery.current) : null;
    fq = skipObjectValue({ ...fq, searchTerms }, '');
    const qs = Object.entries(fq).length > 0 && JSON.stringify(fq);
    handleSearch(qs || '');
  };

  const handleFilterChange = (query: string) => {
    refFilterQuery.current = query;
    submitSearch();
  };

  useEffect(() => {
    // trigger submit after clear searchTerms
    if (!searchTerms || clearFilter) submitSearch();
  }, [searchTerms, clearFilter]);

  const buttonDisabled =
    !searchTerms &&
    (!refFilterQuery.current || refFilterQuery.current === '{}');

  const ClearAllElement = (
    <Button
      disableRipple
      onClick={(e) => {
        refFilterQuery.current = '';
        setSearchTerms('');
        setClearFilter(true);
        // reset clearFilter back to false
        setTimeout(() => {
          setClearFilter(false);
        }, 500);
      }}
      className={`border-radius-6px ${gc.buttonDeletion} ${classes.clearAll}`}
      disabled={buttonDisabled}
    >
      <Typography sx={{ fontSize: '0.75rem', mr: 1, fontWeight: 500 }}>
        {capitalize(intl.formatMessage(Messages.clearAll))}
      </Typography>
      <EraserIcon
        stroke={theme.palette.primary.contrastText}
        fill={'transparent'}
        sx={{ width: '0.875rem', height: '0.875rem' }}
      />
    </Button>
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.boxwrapper}>
        <Paper className={`border-radius-8px ${classes.box}`} elevation={0}>
          <InputBase
            className={`border-radius-8px ${classes.input}`}
            placeholder={searchInputPlaceholder}
            inputProps={{ 'aria-label': 'search' }}
            value={searchTerms}
            onKeyDown={onKeyPress}
            onBlur={submitSearch}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTerms(e.target.value);
            }}
            startAdornment={<BenaSearchIcon className={classes.searchIcon} />}
            endAdornment={
              !searchTerms ? (
                <></>
              ) : (
                <IconButton
                  onClick={() => {
                    setSearchTerms('');
                  }}
                  className={`${classes.clearButton} ${gc.iconButtonTransparent}`}
                >
                  <CrossLineIcon
                    stroke={theme.custom.primary2}
                    className={classes.clearIcon}
                  />
                </IconButton>
              )
            }
          />
        </Paper>
        <Box sx={{ width: '0.5rem' }}></Box>
        {/* {ClearAllElement} */}
      </Box>
      {/* {filterType === 'campaign-progress' && (
        <CampaignProgressFilter
          onFilterChange={handleFilterChange}
          clearFilter={clearFilter}
        />
      )}
      {filterType === 'incoming-proposal' && (
        <IncomingProposalsFilter
          onFilterChange={handleFilterChange}
          clearFilter={clearFilter}
        />
      )} */}
      <Divider className={classes.divider} />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '0.75rem 1rem',
    },
    boxwrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      columnGap: '8px',
    },
    box: {
      border: `0.0625rem solid ${theme.custom.primary4}`,
      display: 'flex',
      alignItems: 'center',
      // width: '100%',
      height: '2.5rem',
    },
    input: {
      flex: 1,
      border: 0,
      fontSize: '1rem',
      minWidth: '400px',
    },
    clearButton: {
      marginRight: '1rem',
      marginLeft: '0.5rem',
    },
    clearIcon: {
      width: '0.75rem',
      height: '0.75rem',
    },
    searchIcon: {
      marginRight: '0.25rem',
      marginLeft: '0.5rem',
      padding: '0rem 0.25rem',
    },
    divider: {
      borderColor: theme.custom.primary4,
    },
    clearAll: {
      ml: 1,
      pl: 1.5,
      height: '2.25rem',
      borderRadius: '0',
      width: '6rem',
    },
  })
);
