import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import ContentHelperCard from '../components/ContentHelperCard';
import { useGlobalStyles } from '../../assets/styles/style';
import CampaignCard from '../components/CampaignCard';
import FlexSpaceAroundLeftAligned from '../../components/FlexSpaceBetweenLeftAligned';
import CreatorCampaignDetails from './CreatorCampaignDetails';
import { CreatorContext, DefaultUserActionData } from '../context/context';
import { useState, useEffect } from 'react';
import DialogPage from './DialogPage';
import CreatorAIScriptPanel from './CreatorAIScriptPanel';
import { useGetCampaignsQuery } from '../../redux/benaApi';
import moment from 'moment';
import { useTypedSelector } from '../../redux/store';
import { selectAuth } from '../../features/account/AuthSlice';
import { staticURLRegisterCreatorProfile } from '../../route/urls';
import { useNavigate } from 'react-router-dom';
import { Messages } from '../../localization/Messages';
import { useGetUserProfile } from '../../hooks/account';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { PageMobileNavigationBarAndTitleBar } from '../../features/mobileNavbar/navbar';
import { showMessage } from '../../components/ShowMessage';

function useCardsInfo() {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const userAvatar =
    useTypedSelector((state) => selectAuth(state)).user?.avatar ||
    '/imgs/creator/creatorAvatar.png';

  const cards = [
    {
      title: intl.formatMessage(CreatorMessages.WritingEmailsHelper),
      subTitle: intl.formatMessage(CreatorMessages.TryQuestions),
      buttonText: intl.formatMessage(CreatorMessages.TryItOut),
      image: '/imgs/creator/contentHelpersImage01.svg',
      avatar: userAvatar,
      messages: [
        'What should I pay attention to when writing an email?',
        'If I want to write a personal proposal to a brand, is there any template that I can follow?',
        'Please emphasize my story telling style in 4-5 sentences.',
      ],
      msgBoxBgcolor: theme.palette.primary[800],
      buttonClass: gs.buttonPrimary,
    },
    {
      title: intl.formatMessage(CreatorMessages.WritingContentBriefHelper),
      subTitle: intl.formatMessage(CreatorMessages.TryQuestions),
      buttonText: intl.formatMessage(CreatorMessages.TryItOut),
      image: '/imgs/creator/contentHelpersImage02.svg',
      avatar: userAvatar,
      messages: [
        'Create a fresh and engaging idea to promote a wig under 30 secs.',
        'What are some video titles that would go well with a video featuring an electric bicycle race?',
        'Please generate some video ideas to promote yoga pants.',
      ],
      columnGap: '4px',
      msgBoxBgcolor: theme.palette.grey['900_1'],
      buttonClass: gs.buttonDarkPrimary,
    },
    {
      title: intl.formatMessage(CreatorMessages.WritingScriptHelper),
      subTitle: intl.formatMessage(CreatorMessages.TryQuestions),
      buttonText: intl.formatMessage(CreatorMessages.TryItOut),
      image: '/imgs/creator/contentHelpersImage03.svg',
      avatar: userAvatar,
      messages: [
        'Write a script that will provide a sneak peek of the upcoming human hair wig and create a sense of anticipation and excitement for with a clear and compelling call-to-action.',
        'Write a video script that will showcase my unique and personal experiences with Nike sneaker and encourage my followers to share their opinions',
      ],
      msgBoxBgcolor: theme.palette.success[900],
      buttonClass: gs.buttonGreen,
    },
  ];
  return cards;
}

export default function CreatorHomepage() {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const navigate = useNavigate();
  const cards = useCardsInfo();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const size = 100;
  const {
    data: campaigns,
    refetch,
    isFetching,
    isLoading,
  } = useGetCampaignsQuery({ page, size });

  const [campaignsArr, setCampaignsArr] = useState<CampaignResponseType[]>([]);
  useEffect(() => {
    const items = campaigns?.data?.items || [];
    const filtered = items.filter(
      (w) => !campaignsArr.find((x) => x.id === w.id)
    );
    setCampaignsArr((state) => [...state, ...filtered]);
    typeof campaigns?.data.total === 'number' &&
      setTotal(campaigns?.data.total);
  }, [campaigns]);

  // const campaignsArr = campaigns?.data?.items || [];

  useEffect(() => {
    refetch();
  }, [refetch]);

  const [context, setContext] = useState<{
    currentDisplayedCampaignId: string;
    currentScriptType: CreatorScriptType;
  }>({
    currentDisplayedCampaignId: '',
    currentScriptType: '',
  });
  const campaignId = context.currentDisplayedCampaignId;
  const scriptType = context.currentScriptType;

  const setCurrentDisplayedCampaignId = (id: string) => {
    setContext((state) => ({ ...state, currentDisplayedCampaignId: id }));
  };
  const setCurrentScriptTitle = (scriptType: CreatorScriptType) => {
    setContext((state) => ({ ...state, currentScriptType: scriptType }));
  };

  const showDialog = !!scriptType || !!campaignId;

  // user action data stored in context
  const [userActionData, setUserActionData] = useState<ActionDataType>(
    DefaultUserActionData
  );
  const triggerUserAction = (type: CreatorRoleScriptActionType, data: any) => {
    setUserActionData({ type, data });
  };
  const resetUserActionData = () => {
    setUserActionData(DefaultUserActionData);
  };

  const user = useTypedSelector((state) => selectAuth(state)).user;
  useEffect(() => {
    if (!user?.email) {
      navigate(staticURLRegisterCreatorProfile);
    }
  }, []);

  const [campaignIdForScriptTypeCampaign, setCampaignIdForScriptTypeCampaign] =
    useState('');

  // trigger campaign apply from public_campaign page
  const campaignIdFromUrl = new URLSearchParams(window.location.search).get(
    'applied_campaign_id'
  );
  useEffect(() => {
    if (
      campaignIdFromUrl &&
      campaignsArr.find((w) => w.id === campaignIdFromUrl)
    ) {
      setCurrentDisplayedCampaignId('');
      setCampaignIdForScriptTypeCampaign(campaignIdFromUrl);
      setCurrentScriptTitle('campaign');
    } else if (campaignIdFromUrl && !isFetching && !isLoading) {
      showMessage.error(intl.formatMessage(Messages.SpecifiedCampaignNotFound));
    }
  }, [campaignsArr, campaignIdFromUrl]);

  // trigger campaign detail from tiktok verify
  const detailCampaignId = new URLSearchParams(window.location.search).get(
    'campaign_id'
  );
  useEffect(() => {
    if (
      detailCampaignId &&
      campaignsArr.find((w) => w.id === detailCampaignId)
    ) {
      setCurrentDisplayedCampaignId(detailCampaignId);
      setCampaignIdForScriptTypeCampaign('');
      setCurrentScriptTitle('');
    }
  }, [campaigns]);

  // after tiktok sso verified, the tiktok_verified flag needs to be updated.
  useGetUserProfile();
  const isMobileView = useBreakpointXs();

  return (
    <Box>
      <CreatorContext.Provider
        value={{
          ...context,
          setCurrentDisplayedCampaignId,
          setCurrentScriptTitle,
          triggerUserAction,
          userActionData,
          resetUserActionData,
          campaignIdForScriptTypeCampaign,
          setCampaignIdForScriptTypeCampaign,
        }}
      >
        <Typography
          fontWeight={500}
          fontSize={'24px'}
          color="grey.700"
          marginTop="40px"
          marginBottom="24px"
        >
          {intl.formatMessage(CreatorMessages.ContentHelper)}
        </Typography>
        <Stack
          direction={'row'}
          columnGap={'24px'}
          rowGap={'24px'}
          {...(isMobileView ? { flexWrap: 'wrap' } : {})}
        >
          <ContentHelperCard
            {...cards[0]}
            clickHandler={() => setCurrentScriptTitle('email')}
          />
          <ContentHelperCard
            {...cards[1]}
            clickHandler={() => setCurrentScriptTitle('content')}
          />
          <ContentHelperCard
            {...cards[2]}
            clickHandler={() => setCurrentScriptTitle('script')}
          />
        </Stack>
        <Typography
          fontWeight={500}
          fontSize={'24px'}
          color="grey.700"
          marginTop="40px"
          marginBottom="24px"
        >
          {intl.formatMessage(CreatorMessages.Opportunities)}
        </Typography>
        <Stack
          direction="row"
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          rowGap="24px"
        >
          {campaignsArr.map(
            (
              { id, start_date, title, cover_img, brand_logo, platforms },
              index
            ) => {
              return (
                <CampaignCard
                  campaignId={id}
                  platformIds={platforms as SocialPlatformId[]}
                  launchedOn={moment(start_date).format(`M/DD/YY`)}
                  title={title}
                  logo={brand_logo || '/imgs/default_logo.png'}
                  image={cover_img || ''}
                  key={id}
                />
              );
            }
          )}
          {campaignsArr.length <= 0 ? (
            <Stack
              className="boxcenterhv"
              minHeight={'784px'}
              width="100%"
              border={`1px solid ${theme.palette.neutral[600]}`}
              borderRadius={'10px'}
              rowGap="18px"
            >
              <Box
                bgcolor={theme.palette.neutral['0_0']}
                borderRadius={'96px'}
                padding="48px"
              >
                <img
                  src="/imgs/no-campaign-found.svg"
                  alt="no campaign"
                  width="276px"
                  height="258px"
                />
              </Box>
              <Typography
                fontSize={'22px'}
                fontWeight="500"
                color={theme.palette.grey[700]}
                textAlign={'center'}
              >
                {intl.formatMessage(Messages.SeemsLikeItDoesntHave)}
              </Typography>
            </Stack>
          ) : (
            <FlexSpaceAroundLeftAligned width={'390px'} />
          )}
        </Stack>
        {total > campaignsArr.length && (
          <Stack justifyContent={'flex-end'} marginTop="8px">
            <Button
              onClick={() => {
                setPage((page) => page + 1);
              }}
              className={gs.buttonSelected}
              disabled={isFetching || isLoading}
            >
              {intl.formatMessage(Messages.Loadmore)}
            </Button>
          </Stack>
        )}
        <Box marginTop={'64px'}>&nbsp;</Box>
        {showDialog && (
          <DialogPage
            initOpen={showDialog}
            children={
              campaignId ? (
                <CreatorCampaignDetails
                  campaignId={campaignId}
                  notAllowViewApplication={true}
                />
              ) : scriptType ? (
                <CreatorAIScriptPanel scriptType={scriptType} />
              ) : (
                <></>
              )
            }
            showHeader={!isMobileView}
            showMobileViewPadding={false}
          />
        )}
      </CreatorContext.Provider>
      <PageMobileNavigationBarAndTitleBar />
    </Box>
  );
}
