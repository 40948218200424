import {
  Box,
  Card,
  Stack,
  useTheme,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Theme, styled } from '@mui/material/styles';
import { createStyles, makeStyles } from "@mui/styles"
import { useCallback, useEffect, useMemo,useState } from 'react';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import {
  usePostCreatorShortMutation,
  usePatchModifyShortNameMutation,
  useDeleteShortListMutation
} from '../../redux/benaApi';
import {
  PenEditingIcon, DeleteShortIcon, UploadFiles,
  CheckMarkIcon, CrossMarkIcon
} from '../../assets/imgs/icons';
import { useNavigate } from 'react-router-dom';

import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";
type CreatorsProps = {
  creator: ShortContentType,
  ModificationArray: (action: string, data: ShortContentType) => void;
}
type VDomTypes = {
  preventDefault: () => void;
  stopPropagation: () => void;
}

const iconSize = { width: 14, height: 15};
const iconSizeDel = { width: 14, height: 18};
const iconSizeCheck = { width: 16, height: 11};
const iconSizeCross = { width: 15, height: 14};

const BenaTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary['400_1'],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary['400_1'],
    borderRadius: '0.5rem',
    padding: '0.25rem 0.5rem',
    fontSize: '0.875rem',
  },
}));
export default function MyCreatorsCard({
  creator,
  ModificationArray
}: CreatorsProps) {
  const [creatorObj, setCreatorObj] = useState<ShortContentType>(creator);
  const theme = useTheme();
  const classes = useStyles();
  const [isEditShortName, setIsEditShortName] = useState<string>('none');
  const [deleteAdmined, setDeleteAdmined] = useState<string>('');
  const creatorsList = creatorObj.creators;
  const intl = useIntl();
  const naivgate = useNavigate();
  
  const iconBtnClick = (e: React.MouseEvent<HTMLButtonElement> | VDomTypes,state:string) => {
    e.preventDefault();
    e.stopPropagation();
    if(state === 'upload-creator') {
      let str = creatorObj.id + "===" + creatorObj.name;
      let base64encoded = btoa(str);
      naivgate(`/my-creators/upload-creator?${base64encoded}`);
    }else{
      setIsEditShortName(state);
    }
  }
  
  const NameOperateBox = function () {
    useEffect(() => {
      if(creatorObj.new_data) {
        const VDom:VDomTypes = {
          preventDefault: () => {},
          stopPropagation: () => {}
        }
        iconBtnClick(VDom,'edit')
      }
    })
    return (
      <Stack direction="row" marginBottom={'34px'} justifyContent="space-between">
        <Typography fontSize={'16px'} fontWeight="500" lineHeight='24px' component="div" flexGrow="1" className='ellipsis'>
          {creatorObj.name}
        </Typography>
        <Box flexGrow="1" minWidth="2.75rem"></Box>
        <BenaTooltip title={intl.formatMessage(Messages.uploadCreator)}>
          <IconButton size="small" onClick={(e) => iconBtnClick(e,'upload-creator')} className={classes.iconOperate}>
            <UploadFiles sx={{ width:"20px", height: "20px" }} />
          </IconButton>
        </BenaTooltip>
        <Box minWidth="0.5rem"></Box>
        <BenaTooltip title={intl.formatMessage(Messages.Edit)}>
          <IconButton size="small" onClick={(e) => iconBtnClick(e,'edit')} className={classes.iconOperate}>
            <PenEditingIcon sx={iconSize} />
          </IconButton>
        </BenaTooltip>
        <Box minWidth="0.5rem"></Box>
        <BenaTooltip title={intl.formatMessage(Messages.Delete)}>
          <IconButton size="small" onClick={(e) => iconBtnClick(e,'delete')} className={classes.iconOperate}>
            <DeleteShortIcon sx={iconSizeDel} />
          </IconButton>
        </BenaTooltip>
      </Stack>
    );
  };
  const [createShortList] = usePostCreatorShortMutation();
  const [_modifyShortName] = usePatchModifyShortNameMutation();
  const [_deleteShortList] = useDeleteShortListMutation();

  const deleteShortListApi = async () => {
    setDeleteAdmined("admined-gradually_hide");
    setTimeout(() => {
      ModificationArray("delete",{...creatorObj});
    }, 300);
    await MutationHandler({
      intl,
      action: async () => {
        return await _deleteShortList({id: creatorObj.id}).unwrap();
      },
      onSuccess: (data) => {

      },
    })
  };
  
  const createListApi = useCallback( async (name:string) => {
    await MutationHandler({
      intl,
      action: async () =>
        await createShortList({name}).unwrap(),
      onSuccess: (data) => {
        let new_objs = {...data,creators:[]};
        ModificationArray("create",new_objs);
      }
    })
  },[]);
  const modifyNameApi = useCallback(async (name:string) => {
    setCreatorObj({...creatorObj, name});
    await MutationHandler({
      intl,
      action: async () =>{
        return await _modifyShortName({name, id: creatorObj.id}).unwrap();
      },
      onSuccess: (data) => {
        
      },
    })
  },[]);

  const EditNameBox =  function () {
    useEffect(() => {
      if(isEditShortName === 'edit') {
        const input = document.querySelector('input');
        input?.focus();
      }
    },[]);
    const [reductionText,setReductionText] = useState<string>(creatorObj.name);
    const checkModifyText = function(){
      if(creatorObj.new_data){
        createListApi(reductionText);
      }else{
        modifyNameApi(reductionText);
      }
      setCreatorObj({...creatorObj, name: reductionText,new_data: false});
      setIsEditShortName('none');
    }
    const onKeyPress = function(event: React.KeyboardEvent<HTMLInputElement>){
      if(event.key === 'Enter'){
        checkModifyText();
      }
    }
    return (
      <Stack direction="row" marginBottom={'34px'} justifyContent="space-between"
        onClick={(e)=>{
          e.preventDefault();
          e.stopPropagation();
        }}>
        <Box sx={{ flexGrow: 1 }} className='ellipsis'>
          <Typography fontSize={'16px'} fontWeight="500" lineHeight='24px' className='ellipsis'
            visibility="hidden" sx={{
              pointerEvents: 'none',
              visibility: 'hidden',
              position: 'absolute',
            }}>
            {creatorObj.name}
          </Typography>
          <input type="text" placeholder='Name This List'
            defaultValue={creatorObj.name}
            style={{ border: 'none',outline: 'none', fontSize: '16px', fontWeight: '500', lineHeight: '24px',
              width: '100%',padding: 0, color: theme.palette.grey[700], backgroundColor: "transparent",
              letterSpacing: '-0.1px'
              }}
            onChange={(e)=>setReductionText(e.target.value)}
            onKeyDown={(event)=> onKeyPress(event)}
            onBlur={()=>{
              setTimeout(() => setIsEditShortName('none'), 200);
            }}
          />
        </Box>
        <Box flexGrow="1" minWidth="2.75rem"></Box>
        <IconButton size="small" onClick={() => checkModifyText()} className={classes.iconOperate}>
          <CheckMarkIcon sx={iconSizeCheck} />
        </IconButton>
        <Box minWidth="0.5rem"></Box>
        <IconButton size="small" onClick={(e) => iconBtnClick(e,'none')} className={classes.iconOperate}>
          <CrossMarkIcon sx={iconSizeCross} />
        </IconButton>
      </Stack>
    );
  };

  const DelMaskBox = function(){
    return (
      <Stack sx={{
        position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
        backgroundColor: theme.palette.neutral[0], zIndex: 1, padding: '18px 24px 20px',
      }} onClick={(e)=>{
        e.preventDefault();
        e.stopPropagation();
      }}>
        <Typography fontSize={'18px'} fontWeight="500" lineHeight='28px' component="div" height="62px">
          {intl.formatMessage(Messages.deleteShortList)} "{ creatorObj.name }"?
        </Typography>
        <Box flexGrow="1"></Box>
        <Stack direction="row" justifyContent="flex-end">
          <button onClick={(e) => iconBtnClick(e,'none')} className={classes.btnCustom}
            style={{
              backgroundColor: "transparent",
              color: theme.palette.grey[700],
              borderColor: theme.palette.neutral["700_1"]
            }}>
            {intl.formatMessage(Messages.CancelCapitalization)}
          </button>
          <Box minWidth="1.75rem"></Box>
          <button color='error' onClick={() => deleteShortListApi()} className={classes.btnCustom}
            style={{
              backgroundColor: theme.palette.danger[600],
              color: theme.palette.base.white2white
            }}>
            {intl.formatMessage(Messages.Delete)}
          </button>
        </Stack>
      </Stack>
    );
  };


  const ImgListBox = useMemo(()=>{
    const creators = creatorsList;
    let imgSize = 0;
    if(creatorObj.creators_count > 6) {
      imgSize = creatorObj.creators_count - 6;
    }
    function handleImgError(e: React.SyntheticEvent<HTMLImageElement, Event>) {
      e.currentTarget.src = "/imgs/user.svg";
    };

    const styleOcrat = {
      position: 'relative',
      display: 'inline-block',
      marginRight: '-0.75rem',
      width: '2.25rem',
      height: '2.25rem',
      border: "0.125rem solid " + theme.palette.neutral[0],
      borderRadius: '50%',
      overflow: 'hidden'
    };
    return (
      <Stack direction="row" sx={{
        height: '2.25rem',
      }}>
        {creators.map((item, index) => (
          <Box key={index} sx={{
            backgroundColor: theme.palette.neutral[0],
            ...styleOcrat
          }}>
            <img src={item.pic}
              onError={(e) => {handleImgError(e)}} 
              style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }} />
          </Box>
        ))}
        {imgSize > 0 && 
          <Box sx={{
            backgroundColor: "rgb(176,255,146)",
            ...styleOcrat
          }}>
            <Typography fontSize="14px" fontWeight="600" lineHeight="2rem" textAlign="center"
              color="rgb(53,76,44)">
              +{imgSize}
            </Typography>
          </Box>
        }
      </Stack>
    );
  },[creatorsList]);

  const card = useMemo(()=>(
    <Card
      sx={{
        maxWidth: 390, minWidth: 168, height: "8.25rem",
        border: '1px solid ' + theme.palette.neutral[600],
        borderRadius: '10px', position: 'relative', cursor: 'pointer', padding: '18px 24px 16px',
        backgroundColor: theme.palette.base.white,
        '&:hover': {
          borderColor: theme.palette.grey[700],
        },
        boxShadow: creatorObj.new_data ? '0 0 10px rgba(0,255,0,0.3)' : '',
      }} className={deleteAdmined}
        onClick={()=>{
          let str = creatorObj.id + "===" + creatorObj.name;
          let base64encoded = btoa(str);
          naivgate(`/my-creators/userlist?${base64encoded}`);
        }}>
      <Stack>
        {isEditShortName!=='edit' && <NameOperateBox /> }
        {isEditShortName==='edit' && <EditNameBox /> }
        {isEditShortName==='delete' && <DelMaskBox /> }
        {ImgListBox}
      </Stack>
    </Card>
  ), [creatorObj,theme, isEditShortName, deleteAdmined])
  return card;
};

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    iconOperate: {
      padding: '0',
      color: theme.palette.neutral[600],
      width: '1.5rem',
      height: '1.5rem',
      '&:hover': {
        opacity: '0.8',
        color: theme.palette.grey[700],
      }
    },
    btnCustom: {
      color: theme.palette.neutral[600],
      padding: '0.5rem 0.75rem',
      height: '2rem',
      borderRadius: '0.5rem',
      border: '1px solid transparent',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8'
      }
    }
  })
);