import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  useLazyQueryAIGCVideosQuery,
  usePatchAIGCVideoMutation,
} from '../redux/benaApi';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../redux/benaApiResultHandler';
import { useTypedSelector } from '../redux/store';
import { addLikeFavorite, selectAIGC } from '../features/aigc/AIGCSlice';
import { useDispatch } from 'react-redux';

function genLikeFavorites(story: CreatorVideoScriptResponseType) {
  const { id, like, dislike, favorite } = story;
  return { id, like, dislike, favorite };
}
export function usePatchAIGCScript(story: CreatorVideoScriptResponseType) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const likeFavorite = useTypedSelector((state) =>
    selectAIGC(state)
  ).likeFavorite;

  const updateFromStory = (newStory: CreatorVideoScriptResponseType) => {
    const newLikeFavorites = genLikeFavorites(newStory);
    dispatch(addLikeFavorite(newLikeFavorites));
  };
  useEffect(() => {
    updateFromStory(story);
  }, [story]);
  const [submitting, setSubmitting] = useState(false);
  const [patchAIGCVideo] = usePatchAIGCVideoMutation({});
  const _handlePatch = (
    id: string,
    favorite?: boolean,
    like?: boolean,
    dislike?: boolean
  ) => {
    if (submitting) return;

    setSubmitting(true);
    const body = {
      ...(favorite === undefined ? {} : { favorite }),
      ...(like === undefined ? {} : { like }),
      ...(dislike === undefined ? {} : { dislike }),
    };
    MutationHandler({
      intl,
      action: async () => {
        return await patchAIGCVideo({
          id,
          body,
        }).unwrap();
      },
      onSuccess: (data) => {
        updateFromStory(data);
      },
    });
    setSubmitting(false);
  };
  const handleFavorite = () => {
    const b = likeFavorite.find((v) => v.id === story.id)?.favorite;
    _handlePatch(story.id, !b);
  };
  const handleLike = () => {
    const b = likeFavorite.find((v) => v.id === story.id)?.like;
    _handlePatch(story.id, undefined, !b);
  };
  const handleDislike = () => {
    const b = likeFavorite.find((v) => v.id === story.id)?.dislike;
    _handlePatch(story.id, undefined, undefined, !b);
  };

  return {
    handleFavorite,
    handleLike,
    handleDislike,
    likeFavorite: likeFavorite.find((w) => w.id === story.id),
  };
}

export function useQueryAIGCScripts(favorite?: boolean) {
  const intl = useIntl();
  const [query, queryResult] = useLazyQueryAIGCVideosQuery({});
  const [dataArr, setDataArr] = useState<CreatorVideoScriptResponseType[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const creatorId = useTypedSelector((state) =>
    selectAIGC(state)
  ).selectedCreatorId;
  const mediasQuery = {
    page,
    size: 6,
    filters: {
      ...(favorite ? { favorite } : {}),
      // add randomid to avoid the result cache
      randomid: Math.random().toString(36).slice(6),
    },
    creator_id: creatorId || '',
  };

  const queryFn = async (_page?: number) => {
    if (creatorId) {
      query({ ...mediasQuery, page: _page || page });
    }
  };

  useEffect(() => {
    LazyQueryResultHandler<QueryAIGCVideosResponseType>({
      intl,
      result: queryResult as QueryResultType<QueryAIGCVideosResponseType>,
      onSuccess: (resdata) => {
        const { page, total, items } = resdata;
        setPage(page);
        setTotal(total);
        let newArr = [...dataArr];
        items.forEach((v) => {
          const index = newArr.findIndex((w) => w.id === v.id);
          if (index < 0) {
            newArr.push(v);
          } else {
            newArr = [...newArr.slice(0, index), v, ...newArr.slice(index + 1)];
          }
        });
        setDataArr(newArr);
      },
    });
  }, [queryResult]);

  useEffect(() => {
    queryFn(1);
  }, [creatorId]);

  const loading = queryResult.isFetching || queryResult.isLoading;

  return { dataArr, loading, queryFn, setDataArr, page, total };
}
