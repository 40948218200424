import { Stack, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import TitledPanel from './TitledPanel';

type TextTagProps = {
  text: string;
};
export function TextTag({ text }: TextTagProps) {
  const theme = useTheme();
  return (
    <Typography
      component="span"
      sx={{
        padding: '4px 12px',
        borderRadius: '4px',
        backgroundColor: theme.palette.primary[25],
      }}
      color="primary.600"
      fontSize={'14px'}
      fontWeight={500}
    >
      {text}
    </Typography>
  );
}

type CreatorRequirementsProps = {
  tags: string[];
};
export default function CreatorRequirements({
  tags,
}: CreatorRequirementsProps) {
  const intl = useIntl();
  return (
    <TitledPanel
      title={intl.formatMessage(CreatorMessages.CreatorRequirement)}
      children={
        <Stack direction="row" columnGap="12px" rowGap="12px" flexWrap={'wrap'}>
          {tags.map((tag, index) => (
            <TextTag text={tag} key={index} />
          ))}
          {tags.length <= 0 && '-'}
        </Stack>
      }
    />
  );
}
