import { Box, Stack, Typography } from '@mui/material';
import { useState, useRef, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { LoadingButton } from '@mui/lab';
import { MinCheckMarkIcon } from '../../assets/imgs/icons';
import { useTheme } from '@mui/material/styles';
import {
  usePostAddShortListMutation,
  usePostShortListBatchMutation
} from "../../redux/benaApi";
import { createShort } from "../creators/shortListSlice"
import { RootState } from '../../redux/store';

// },[open])setShortLocal shortLocal
type CreatorCheckListProps = {
  shortLocal: string[];
  setShortLocal: (shortLocal: string[]) => void;
  uid: string;
  setOpen: (open: boolean) => void;
  posTop?: string;
  posLeft?: string;
  posTransform?: string;
  posRight?: string;
  queryParams?: string;
  updateBatchFavorites?: (list: string[]) => void;
}

function CreatorCheckList({
  shortLocal,
  setShortLocal,
  uid,
  setOpen,
  queryParams,
  updateBatchFavorites,
  posTop = "50%",
  posLeft = "50%",
  posRight = "auto",
  posTransform = "translate(-50%, -50%)"
}:CreatorCheckListProps){
  const intl = useIntl();
  const theme = useTheme();
  const listRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const [searchList, setSearchList] = useState<{ id: string; name: string }[]>([]);
  const [checkList, setCheckList] = useState<string[]>([...shortLocal]);
  let timeOutId = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();

  const dataHubList = useSelector((state: RootState) => state.shortList.shortList); // all shortlist data
  // 
  const anyArrValue = useRef<string[]>([]);

  const prevScrollPosition = useRef<number>(0);
  const prevInputValue = useRef<string>('');
  const isNewAddto = useRef<boolean>(false);

  const [postAddShortList] = usePostAddShortListMutation({});
  const [postShortListBatch] = usePostShortListBatchMutation({});

  useEffect(() => {
    inputChange({target:{value: prevInputValue.current}} as React.ChangeEvent<HTMLInputElement>);
    if(isNewAddto.current){
      anyArrValue.current = [...checkList, dataHubList[0].id ];
      setCheckList( anyArrValue.current );
      submitCheckList();
    }
  }, [dataHubList]);
  useEffect(() => {
    searchInputRef.current?.focus();
    if (listRef.current) {
      listRef.current.scrollTo(0, prevScrollPosition.current);
    }
    if (prevInputValue.current) {
      searchInputRef.current!.value = prevInputValue.current;
    }
    return () => {
      clearTimeout(timeOutId.current as NodeJS.Timeout);
    }
  }, []);
  const closeDrop = () => {
    clearTimeout(timeOutId.current as NodeJS.Timeout);
    timeOutId.current = setTimeout(() => {
      if( document.activeElement !== searchInputRef.current ){
        setOpen(false);
        if(btnLoading){
          setBtnLoading(false);
          prevInputValue.current = '';
        }
      };
    }, 200);
  };

  const stateReload = () => {
    if(!isNewAddto.current){
      searchInputRef.current?.blur()
    }else{
      isNewAddto.current = false;
      setBtnLoading(false);
    }
  };
  
  const submitCheckList = async ()=>{
    setBtnLoading(true);
    searchInputRef.current?.focus();
    if(searchList.length === 0 && 
      searchInputRef.current?.value && 
      isNewAddto.current === false
    ){
      isNewAddto.current = true;
      dispatch( createShort( searchInputRef.current.value ) );
      return;
    }
    await MutationHandler({
      intl,
      action: async () => {
        if(uid === "all"){
          let str = queryParams as string;
          let filt = str.split("&").filter(item=> item.startsWith("filters="))[0];
          let jsonObj = JSON.parse( decodeURIComponent( filt.replace("filters=","") ) );
          return postShortListBatch({
            shortlists: checkList,
            filters: jsonObj
          }).unwrap()
        }else{
          return postAddShortList({ uid, shortlists: checkList }).unwrap();
        }
      },
      onSuccess: (res) => {
        if(anyArrValue.current.length > 0){
          setShortLocal([...anyArrValue.current]);
          anyArrValue.current = [];
        }else{
          setShortLocal([...checkList]);
        }
        stateReload();
        updateBatchFavorites && updateBatchFavorites(checkList);
        // uid === "all" && showMessage.success(intl.formatMessage(Messages.addedToList));
      },
      onError: (err) => {
        stateReload();
      },
    },null, true);
  };
  const handleScroll = () => {
    prevScrollPosition.current = listRef.current?.scrollTop || 0;
  };
  // search
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
    const newList = [...dataHubList];
    const value = e.target.value;
    prevInputValue.current = value;
    if(value){
      setSearchList(
        newList.filter((item)=>item.name.includes(value))
      );
    }else{
      setSearchList(newList);
    }
  };
  const searchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>)=>{
    if(e.key === "Enter" && searchList.length === 0){
      if(searchInputRef.current?.value){
        dispatch( createShort( searchInputRef.current.value ) )
      }
    }
  }
  return (
    <Stack direction="column" sx={{
      width: '208px', position: 'absolute', zIndex: 1060, borderRadius: '8px', left: posLeft, right: posRight,
      top: posTop, transform: posTransform, overflow:"hidden", backgroundColor: theme.palette.neutral["0"],
      boxShadow: '0px 25px 50px -12px #19152840'
    }} component="span">
      <Typography component="span"
        sx={{
          borderBottom: "1px solid #DDDBE6", display:"block",
          padding: '0 1rem', lineHeight: '1.25rem',
        }}>
        <input type="text"
          onChange={(e)=>inputChange(e)}
          onClick={(e)=>e.stopPropagation()}
          onBlur={closeDrop}
          onKeyDown={(e)=>searchKeyDown(e)}
          ref={searchInputRef}
          style={{
            background:"transparent",border:"none",width:"100%",outline:"none", fontSize: "14px",
            color: theme.palette.base.black, fontWeight: 500, padding: "0.625rem 0"
          }}
          placeholder={intl.formatMessage(Messages.searchOrCreateList)}
          />
      </Typography>
      { searchList.length === 0 && 
        <Typography component="span"
          sx={{ padding: '1rem', lineHeight: '1.25rem', color: theme.palette.grey["400"], display: "block"}}
          >
          {intl.formatMessage(Messages.ThereIsEnterCreate)}
        </Typography>
      }
      <Box sx={{ maxHeight: '200px', overflowY: 'auto', cursor:"pointer",display: "block"}}
        ref={listRef} onScroll={handleScroll} component="span">
        {searchList.map((item) => {
          return (
            <Stack direction="row" key={item.id} sx={{
              padding: '0.625rem 1rem', lineHeight: '1.25rem', justifyContent: 'space-between', alignItems: 'center',
              backgroundColor: checkList.includes(item.id) ? theme.palette.primary["25"] : "", fontSize: "14px",
              '&:hover': {
                backgroundColor: theme.palette.primary["25"]
              }
            }} onClick={(e)=>{
              e.stopPropagation();
              e.preventDefault();
              if(checkList.includes(item.id)){
                setCheckList(checkList.filter((id)=>id!==item.id));
              }else{
                setCheckList([...checkList,item.id]);
              }
              searchInputRef.current?.focus();
            }} component="span">
              <Typography component="span">{item.name}</Typography>
              {checkList.includes(item.id) && <MinCheckMarkIcon sx={{width: 14, height: 10}} />}
            </Stack>
          )
        })}
      </Box>
      <LoadingButton variant="contained" sx={{
        width: '100%' 
      }} loading={btnLoading} onClick={(e)=>{
        e.stopPropagation();
        e.preventDefault();
        submitCheckList();
      }}>
        {/* Messages.createNewCreator */}
        {intl.formatMessage(Messages.AddToList)} ({checkList.length})
      </LoadingButton>
    </Stack>
  )
}

export default memo(CreatorCheckList);