export default function ignoreConsole() {
  // replace console.* to disable logs in production
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {
      return;
    };
    console.error = () => {
      return;
    };
    console.debug = () => {
      return;
    };
  }
}
