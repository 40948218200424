import {
  Box,
  Card,
  useTheme,
  Stack,
  Typography
} from "@mui/material";
import { Masonry } from "@mui/lab";
import {
  useGetCreatorShortlistQuery
} from '../../redux/benaApi';
import { Theme } from "@mui/material/styles";
import { AddShortIcon } from "../../assets/imgs/icons";
import { createStyles, makeStyles } from "@mui/styles";
import { useBreakpointXs } from "../../utils/useStyleHooks";
import { Messages } from "../../localization/Messages";
import { useIntl } from "react-intl";
import MyCreatorsCard from "./myCreatorsCard";
import { useCallback } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import useInfiniteScroll from '../../utils/useInfiniteScroll';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoaderMessage,
} from '../InfiniteScrollMessages';
const iconSize = { width: 14, height: 14};
function MyCreatorsList() {
  const queryParams = "";
  const {
    combinedData: creatorDataArr,
    setCombinedData: setCreatorDataArr,
    hasMore,
    readMore,
    isFetching,
    isLoading,
  } = useInfiniteScroll<ShortContentType>(useGetCreatorShortlistQuery, {
    limit: 12,
    queryParams
  });
  
  const handleInfiniteScrollNext = useCallback(() => {
    readMore();
  }, [readMore]);
  
  const isMobileView = useBreakpointXs();
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  function newShortList(){
    let newObj ={
      "id": new Date().getTime()+"",
      "created_at": "",
      "updated_at": "",
      "name": "",
      "user": "",
      "creators_count": 0,
      "creators": [],
      "new_data": true
    }
    let newModalList = [...creatorDataArr];
    newModalList.unshift(newObj);
    setCreatorDataArr(newModalList);
  };
  const ModificationArray = (type:string,args:ShortContentType)=>{
    let newModalList = [...creatorDataArr];
    if(type === "create"){
      newModalList.splice( 0, 1, args);
    }else if(type === "delete"){
      newModalList = newModalList.filter((item)=> item.id !== args.id );
    };
    setCreatorDataArr(newModalList);
  }
  return (
    
    <InfiniteScroll
      dataLength={creatorDataArr.length}
      next={handleInfiniteScrollNext}
      hasMore={hasMore}
      loader={
        <InfiniteScrollLoaderMessage visible={isFetching || isLoading} />
      }
      endMessage={<InfiniteScrollEndMessage />}
      scrollableTarget="infinite-scroll-masonry"
    >
      <Box
        className={classes.creatorsList}>
        <Masonry columns={isMobileView?1: 3} spacing={2.5}>
          <Card
            sx={{
              maxWidth: 390,
              minWidth: 168,
              height: "8.25rem",
              border: '2px dashed ' + theme.palette.neutral[600],
              borderRadius: '10px',
              cursor: 'pointer',
              backgroundColor: theme.palette.base.white,
              padding: '21px',
              '&:hover': {
                borderColor: theme.palette.grey[700],
              },
            }} onClick={()=>newShortList()}>
            <Stack alignItems="center">
              <Box sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                border: '2px dashed ' + theme.palette.neutral[600],
                lineHeight: '40px',
                textAlign: 'center',
              }}>
                <AddShortIcon stroke={theme.palette.base.black} sx={iconSize} />
              </Box>
              <Typography fontSize="20px" fontWeight="500" lineHeight="1.75rem" textAlign="center"
                marginTop="1rem">
                {intl.formatMessage(Messages.createNewCreator)}
              </Typography>
            </Stack>
          </Card>
          {
            creatorDataArr.map((item, index) => (
              <MyCreatorsCard key={item.id}
                creator={item}
                ModificationArray={ ModificationArray }
              />
            ))
          }
        </Masonry>
      </Box>
    </InfiniteScroll>
  )
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    creatorsList: {
      marginTop: `2rem`,
      display: `flex`,
    }
  })
);


export default MyCreatorsList;