import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {
  AuthSliceType,
  setLoadingInProgress,
} from '../features/account/AuthSlice';
import { envResolverAdminAPIURL } from '../env';

// add data type based on redux root state if necessary
type ReduxStateType = {
  auth: AuthSliceType;
};

const _baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxStateType).auth.access;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseQueryFn = _baseQuery(envResolverAdminAPIURL());
  api.dispatch(setLoadingInProgress(true));
  const result = await baseQueryFn(args, api, extraOptions);
  api.dispatch(setLoadingInProgress(false));
  return result;
};

export const benaAdminApi = createApi({
  reducerPath: 'benaAdminApi',
  baseQuery: baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    adminPatchCreator: builder.mutation<
      APIResponse<CreatorDataDetails>,
      AdminPatchCreatorRequestType
    >({
      query: (params) => ({
        url: `/creators/${params.id}`,
        method: 'PATCH',
        body: params.body,
      }),
    }),
    adminDeleteCreator: builder.mutation<
      APIResponse<APIResponseNoData>,
      AdminDeleteCreatorRequestType
    >({
      query: (params) => ({
        url: `/creators/${params.id}`,
        method: 'DELETE',
      }),
    }),
    adminApplications: builder.query<
      APIResponse<AdminApplicationsResponseType>,
      AdminApplicationsRequestType
    >({
      query: (params) => ({
        url: `/applications?page=${params.page}&size=${params.size}${
          params.filters ? `&filters=${params.filters}` : ''
        }`,
        method: 'GET',
      }),
    }),
    adminConversations: builder.query<
      APIResponse<AdminConversationsResponseType>,
      AdminConversationsRequestType
    >({
      query: (params) => ({
        url: `/conversations?page=${params.page}&size=${params.size}${
          params.filters ? `&filters=${params.filters}` : ''
        }`,
        method: 'GET',
      }),
    }),
    adminConversationChatHistory: builder.query<
      APIResponse<AdminConversationChatHistoryResponseType>,
      { conversation_id: string }
    >({
      query: (params) => ({
        url: `/conversations/${params.conversation_id}`,
        method: 'GET',
      }),
    }),
    adminApplicationsChatHistory: builder.query<
      APIResponse<AdminConversationChatHistoryResponseType>,
      { application_id: string }
    >({
      query: (params) => ({
        url: `/applications/chat/${params.application_id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useAdminPatchCreatorMutation,
  useAdminDeleteCreatorMutation,
  useLazyAdminApplicationsQuery,
  useLazyAdminConversationsQuery,
  useLazyAdminConversationChatHistoryQuery,
  useLazyAdminApplicationsChatHistoryQuery,
} = benaAdminApi;
