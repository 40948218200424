import {
  Box,
  LinearProgress,
  Typography,
  LinearProgressProps,
  styled,
} from '@mui/material';

export default function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          fontSize="14px"
          fontWeight="500"
          color="grey.700"
          textAlign={'center'}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const BorderLinearProgressWithLabel = styled(LinearProgressWithLabel)(
  ({ theme }) => ({
    height: 8,
    borderRadius: 4,
  })
);
