import { logout, selectAuth, setUser } from '../features/account/AuthSlice';
import { useTypedSelector } from '../redux/store';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../redux/benaApiResultHandler';
import { useLazyGetUserQuery, useLogoutMutation } from '../redux/benaApi';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { staticURLLogin } from '../route/urls';
import { useEffect } from 'react';
import { envResolverAPIURL } from '../env';
import { deleteCookie } from '../utils/common';
import { wkwebviewLogout } from '../redux/wkwebviewBridge';

export function useAuthToken() {
  const { access } = useTypedSelector((state) => selectAuth(state));
  return access;
}

export function useAuthUserName() {
  const { user } = useTypedSelector((state) => selectAuth(state));
  return user?.name;
}

export function useAuthUserId() {
  const { user } = useTypedSelector((state) => selectAuth(state));
  return user?.id;
}

export function useAuthUserAvatar() {
  const { user } = useTypedSelector((state) => selectAuth(state));
  return user?.avatar;
}
export function useBillingType(){
  const { user } = useTypedSelector((state) => selectAuth(state));
  let plan = user?.billing_type || "free";
  let maxPlan = user?.is_superuser || false;
  if(maxPlan){ return "max_plan"; }
  return ["plugin_monthly","plugin_monthly","free"].includes(plan) ? "free" : "paid";
}

export function useLogout() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [triggerLogout] = useLogoutMutation();
  const { access, refresh } = useTypedSelector((state) => selectAuth(state));
  const logoutClear = (url: string | undefined) => {
    setTimeout(async () => {
      try {
        // clear rocket chat rc_token & rc_uid
        deleteCookie('rc_token');
        deleteCookie('rc_uid');
      } catch (e) {}

      dispatch(logout());
      navigate(url || staticURLLogin);
    });
  };
  const logoutHandler = async (url?: string) => {
    await MutationHandler({
      intl,
      action: async () => {
        return await triggerLogout({
          access: access || '',
          refresh: refresh || '',
        }).unwrap();
      },
    });
    logoutClear(url);
    wkwebviewLogout();
  };
  return [logoutHandler];
}

export function useGetUserProfile() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { access, user } = useTypedSelector((state) => selectAuth(state));
  const [getUser, getUserResult] = useLazyGetUserQuery({});
  useEffect(() => {
    access && getUser({ access });
  }, []);
  useEffect(() => {
    LazyQueryResultHandler<UserResponseType>({
      intl,
      result: getUserResult as QueryResultType<UserResponseType>,
      onSuccess: (resdata) => {
        dispatch(setUser(resdata));
      },
    });
  }, [getUserResult]);
  return user;
}

export function useIsAdminUser() {
  const user = useTypedSelector((state) => selectAuth(state)).user;
  return !!user?.admin;
}

export function useIsBenaUser() {
  const user = useTypedSelector((state) => selectAuth(state)).user;
  return !!user?.email?.endsWith('@bena.live');
}

export function useIsApiLoading() {
  return useTypedSelector((state) => selectAuth(state)).loadingInProgress > 0;
}

export function useIsRoleCreator() {
  const { user } = useTypedSelector((state) => selectAuth(state));
  return user?.role === 'creator';
}

export function useIsRoleBrand() {
  const { user } = useTypedSelector((state) => selectAuth(state));
  return user?.role === 'brand';
}

export function useSocialAccountVerification() {
  const auth = useTypedSelector((state) => selectAuth(state));
  return (platform: SocialPlatformId, redirect_url: string) => {
    window.location.href = `${envResolverAPIURL()}auth/sso-verify?platform=${platform}&redirect_url=${redirect_url}&access_token=${
      auth.access
    }`;
  };
}

export function useUserTiktokVerified() {
  const auth = useTypedSelector((state) => selectAuth(state));
  return !!auth.user?.tiktok_verified;
}
