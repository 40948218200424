import { useEffect } from 'react';
import { showMessage } from '../components/ShowMessage';
import ErrorMessages, { ApiResponseCodePrex } from './benaApiResponseCode';
import { useIntl } from 'react-intl';

type APIError = {
  status: number;
  data: APIResponseNoData;
};
export function useApiErrorHandler(error?: unknown) {
  const intl = useIntl();
  useEffect(() => {
    if (error) {
      // error handling
      const { status, data } = error as APIError;
      const status_code = data?.status?.status_code;
      let message = (ErrorMessages as any)[
        `${ApiResponseCodePrex}${status_code}`
      ];
      if (message) {
        message = intl.formatMessage(message);
      } else if (status && data) {
        message = (data as APIResponseNoData)?.status.message;
      }
      if (message) {
        showMessage.error(message);
      }
    }
  }, [error]);
}
