import { InputBase } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
type FormattedInputProps = {
  initValue: number;
  handleChange: fnNumberToVoid;
  textAlign?: 'start' | 'end';
  forceUpdate: number;
};

export default function FormattedInput({
  initValue,
  handleChange,
  textAlign = 'start',
  forceUpdate,
}: FormattedInputProps) {
  const intl = useIntl();
  const [value, setValue] = useState(initValue);
  useEffect(() => {
    setValue(initValue);
  }, [initValue, forceUpdate]);
  const [editing, setEditing] = useState(false);
  const renderValue = () => {
    if (editing) return value;
    return intl.formatNumber(value, {
      notation: 'compact',
    });
  };

  return (
    <InputBase
      value={renderValue()}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(parseInt(event.target.value) || 0);
      }}
      onFocus={() => {
        setEditing(true);
      }}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        handleChange(parseInt(event.target.value) || 0);
        setEditing(false);
      }}
      sx={(theme) => ({
        px: 1,
        width: '5rem',
        borderRadius: '0.5rem',
        border: `0.0625rem solid ${theme.custom.primary4}`,
        '&>input': { textAlign: textAlign },
        fontSize: '12px',
        fontWeight: 600,
        color: theme.palette.base.black,
      })}
    />
  );
}
