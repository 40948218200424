import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../../redux/store';

export type AuthSliceType = LoginResponseType & {
  subscriptionPlanLimitErrorCode: string | null;
  hasJoinedCampaign: boolean;
  // progress indicator for data loading from server
  loadingInProgress: number;
  // ui error code
  uiErrorCode: string | null;
  // ui modal layer depth for safari, instead of z-index
  // start from 0, increase one step with 1 for overlay layers
  uiModalLayerDepth: number;
  // theme mode light/dark
  themeMode: ThemeModeType;
};

const initialState: AuthSliceType = {
  user: null,
  access: null,
  refresh: null,
  access_token_expires_at: 0,
  refresh_token_expires_at: 0,
  subscriptionPlanLimitErrorCode: null,
  hasJoinedCampaign: false,
  loadingInProgress: 0,
  uiErrorCode: null,
  uiModalLayerDepth: 0,
  themeMode: 'light',
};

const slice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setLogin(state: AuthSliceType, action: PayloadAction<LoginResponseType>) {
      state.user = action.payload.user;
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      state.access_token_expires_at = action.payload.access_token_expires_at;
      state.refresh_token_expires_at = action.payload.refresh_token_expires_at;
    },
    setRegister(
      state: AuthSliceType,
      action: PayloadAction<RegisterResponseType>
    ) {
      state.user = action.payload.user;
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      state.access_token_expires_at = action.payload.access_token_expires_at;
      state.refresh_token_expires_at = action.payload.refresh_token_expires_at;
    },
    logout(state: AuthSliceType) {
      state.user = null;
      state.access = null;
      state.refresh = null;
      state.access_token_expires_at = 0;
      state.refresh_token_expires_at = 0;
    },
    setAccessToken(
      state: AuthSliceType,
      action: PayloadAction<{
        access: typeof initialState.access;
        access_token_expires_at: number;
      }>
    ) {
      state.access = action.payload.access;
      state.access_token_expires_at = action.payload.access_token_expires_at;
    },
    setAvatar(state: AuthSliceType, action: PayloadAction<string>) {
      if (state.user) {
        state.user.avatar = action.payload;
      }
    },
    setBrandLogo(state: AuthSliceType, action: PayloadAction<string>) {
      if (state.user) {
        if (state.user.brand?.logo) {
          state.user.brand.logo = action.payload;
        } else if (!state.user.brand) {
          state.user.brand = { logo: action.payload };
        }
        // update brand user's avatar too
        state.user.avatar = action.payload;
      }
    },
    setUser(
      state: AuthSliceType,
      action: PayloadAction<typeof initialState.user>
    ) {
      state.user = action.payload;
    },
    setCreator(state: AuthSliceType, action: PayloadAction<CreatorData>) {
      if (state.user) {
        state.user.creator = action.payload;
      }
    },
    setBrand(state: AuthSliceType, action: PayloadAction<UserBrandType>) {
      if (state.user) {
        state.user.brand = action.payload;
      }
    },
    setSubscriptionPlanLimitErrorCode(
      state: AuthSliceType,
      action: PayloadAction<string | null>
    ) {
      state.subscriptionPlanLimitErrorCode = action.payload;
    },
    setHasJoinedCampaign(state: AuthSliceType, action: PayloadAction<boolean>) {
      state.hasJoinedCampaign = action.payload;
    },
    setLoadingInProgress(state: AuthSliceType, action: PayloadAction<boolean>) {
      state.loadingInProgress = action.payload ? 1 : 0;
    },
    setUIErrorCode(state: AuthSliceType, action: PayloadAction<string | null>) {
      state.uiErrorCode = action.payload;
    },
    setUIModalLayerDepth(state: AuthSliceType, action: PayloadAction<number>) {
      state.uiModalLayerDepth = action.payload;
    },
    setThemeMode(state: AuthSliceType, action: PayloadAction<ThemeModeType>) {
      state.themeMode = action.payload;
    },
  },
});

export const {
  setLogin,
  setRegister,
  logout,
  setAccessToken,
  setAvatar,
  setBrandLogo,
  setUser,
  setCreator,
  setBrand,
  setSubscriptionPlanLimitErrorCode,
  setHasJoinedCampaign,
  setLoadingInProgress,
  setUIErrorCode,
  setUIModalLayerDepth,
  setThemeMode,
} = slice.actions;

export const selectAuth = createSelector(
  [(state: RootState) => state.auth],
  (state) => state
);

export default slice.reducer;
