const envAdminApiBase = {
  local: process.env.REACT_APP_ADMIN_API_URL_LOCAL as string,
  prod: 'https://www.bena.social/admin-api/v1/',
  staging: 'https://www.tensormake.com/admin-api/v1/',
  test: '/admin-api/v1/'
};
export const envResolverAdminAPIURL = () => {
  const env = deployedEnv();
  switch (env) {
    case 'prod':
    case 'staging':
    case 'test':
      return envAdminApiBase[env];
    default:
      return envAdminApiBase['local'];
  }
};

const envApiBase = {
  local: process.env.REACT_APP_API_URL_LOCAL as string,
  prod: 'https://www.bena.social/api/v1/',
  staging: 'https://www.tensormake.com/api/v1/',
  test: '/api/v1/'
};

export const deployedEnv = () => {
  switch (window.location.hostname) {
    case 'www.bena.social':
    case 'bena.social':
      return 'prod';
    case 'www.tensormake.com':
      return 'staging';
    case "35.206.240.104":
    case "bena.agency":
    case "127.0.0.1":
    case "20.3.244.42":
      return "test";
    default:
      return 'local';
  }
};

export const envResolverAPIURL = () => {
  const env = deployedEnv();
  switch (env) {
    case 'prod':
    case 'staging':
    case 'test':
      return envApiBase[env];
    default:
      return envApiBase['local'];
  }
};

export const profileSampleRate = () => {
  switch (deployedEnv()) {
    case 'prod':
    case 'staging':
    case 'test':
      return 50;
    default:
      return 0;
  }
};

export const isEnvProduction = () => {
  return deployedEnv() === 'prod' ? true : false;
};

export const envResolverMainbarMenuItem = (name: string) => {
  switch (deployedEnv()) {
    case 'prod':
      return name;
    default:
      return name;
  }
};

// url for rocket chat server
// note: please configure the urls to meta in index.html as well to keep consistency, or it may cause safari iframe loading issue
// <meta http-equiv="Content-Security-Policy" content="frame-src https://www.bena.social/rchat https://www.tensormake.com/rchat http://localhost:3000/rchat">
export function getChatSiteUrl() {
  const env = deployedEnv();
  switch (env) {
    case 'prod':
      return 'https://www.bena.social/rchat';
    case 'staging':
      return 'https://www.tensormake.com/rchat';
    default:
      // return 'http://localhost:3000/rchat';
      return 'https://api.bena.fun/rchat';
  }
}

export function getChatSiteWebSocketUrl() {
  const env = deployedEnv();
  switch (env) {
    case 'prod':
      return 'wss://www.bena.social/rchat/websocket';
    case 'staging':
      return 'wss://www.tensormake.com/rchat/websocket';
    default:
      return 'wss://www.tensormake.com/rchat/websocket';
  }
}
