import { defineMessages } from 'react-intl';

/**
 * intl message item naming convension:
 *  - exactly match id with the default message
 *  - if the id is too long, concat the starting words in camel style to keep it unique
 *  - maximum id length is 20
 *  - keep all words without captialization
 */
export const Messages = defineMessages({
  days: {
    id: 'days',
    defaultMessage: 'days',
  },
  at: {
    id: 'at',
    defaultMessage: 'at',
  },
  unknowError: {
    id: 'unknowError',
    defaultMessage: 'unknown error occured!',
  },
  home: {
    id: 'home',
    defaultMessage: 'home',
  },
  ourProducts: {
    id: 'ourProducts',
    defaultMessage: 'our products',
  },
  resources: {
    id: 'resources',
    defaultMessage: 'resources',
  },
  contacts: {
    id: 'contacts',
    defaultMessage: 'contacts',
  },
  singUp: {
    id: 'signUp',
    defaultMessage: 'sign up',
  },
  logIn: {
    id: 'logIn',
    defaultMessage: 'log in',
  },
  upgradeSubscription:{ id: 'upgradeSubscription', defaultMessage: 'Upgrade your subscription for more' },
  viewMessage: { id: 'viewMessage', defaultMessage: 'View message' },
  viewEmail: { id: 'viewEmail', defaultMessage: 'View email' },
  uploadCreator: { id: 'uploadCreator', defaultMessage: 'Upload creator' },
  search: {
    id: 'search',
    defaultMessage: 'search',
  },
  creators: {
    id: 'creators',
    defaultMessage: 'creators',
  },
  favorites: {
    id: 'favorites',
    defaultMessage: 'favorites',
  },
  searchYourDesiredCreators: {
    id: 'searchYourDesiredCreators',
    defaultMessage: 'search your desired creators',
  },
  followers: {
    id: 'followers',
    defaultMessage: 'Followers',
  },
  engagement: {
    id: 'engagement',
    defaultMessage: 'Engagement',
  },
  contentEngagementRate: {
    id: 'contentEngagementRate',
    defaultMessage: 'Content Engagement Rate',
  },
  engagementRate: {
    id: 'engagementRate',
    defaultMessage: 'Engagement Rate',
  },
  advancedCreatorDiscovery: {
    id: 'advancedCreatorDiscovery',
    defaultMessage: 'advanced creator discovery',
  },
  clearAll: {
    id: 'clearAll',
    defaultMessage: 'clear all',
  },
  searchCreatorsWith: {
    id: 'searchCreatorsWith',
    defaultMessage:
      'search creators with keywords, or start with “@“ to find by name or id',
  },
  searchByName: {
    id: 'searchByName',
    defaultMessage: 'search by name',
  },
  all: {
    id: 'all',
    defaultMessage: 'all',
  },
  male: {
    id: 'male',
    defaultMessage: 'male',
  },
  female: {
    id: 'female',
    defaultMessage: 'female',
  },
  clear: {
    id: 'clear',
    defaultMessage: 'clear',
  },
  influencerLocation: {
    id: 'influencerLocation',
    defaultMessage: 'influencer location',
  },
  gender: {
    id: 'gender',
    defaultMessage: 'gender',
  },
  followerSize: {
    id: 'followerSize',
    defaultMessage: 'follower size',
  },
  lastPost: {
    id: 'lastPost',
    defaultMessage: 'last post',
  },
  audienceLocation: {
    id: 'audienceLocation',
    defaultMessage: 'audience location',
  },
  audienceGender: {
    id: 'audienceGender',
    defaultMessage: 'audience gender',
  },
  creatorsGender: {
    id: 'creatorsGender',
    defaultMessage: "Creator's Gender",
  },
  audienceAge: {
    id: 'audienceAge',
    defaultMessage: 'audience age',
  },
  topics: {
    id: 'topics',
    defaultMessage: 'topics',
  },
  content: {
    id: 'content',
    defaultMessage: 'content',
  },
  userSearch: {
    id: 'userSearch',
    defaultMessage: 'user search',
  },
  creatorSearch: {
    id: 'creatorSearch',
    defaultMessage: 'creator search',
  },
  any: {
    id: 'any',
    defaultMessage: 'any',
  },
  choose: {
    id: 'choose',
    defaultMessage: 'choose',
  },
  LastNDay: {
    id: 'LastNDay',
    defaultMessage: 'last {value} day{pl}',
  },
  type: {
    id: 'type',
    defaultMessage: 'type',
  },
  typeAndPressEnter: {
    id: 'typeAndPressEnter',
    defaultMessage: 'type and press enter',
  },
  EnhancedYourRelationship: {
    id: 'EnhancedYourRelationship',
    defaultMessage: 'Enhance your relationship by connecting directly through.',
  },
  TakeAPieceOf: {
    id: 'TakeAPieceOf',
    defaultMessage:
      'Take a piece of content and rewrite it to make it more interesting!',
  },
  NoDataToShow: {
    id: 'NoDataToShow',
    defaultMessage: 'No data to show',
  },
  Back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  Next: {
    id: 'Next',
    defaultMessage: 'Next',
  },
  logout: {
    id: 'logout',
    defaultMessage: 'log out',
  },
  yes: {
    id: 'yes',
    defaultMessage: 'yes',
  },
  no: {
    id: 'no',
    defaultMessage: 'no',
  },
  favorited: {
    id: 'favorited',
    defaultMessage: 'favorited',
  },
  isModerated: {
    id: 'isModerated',
    defaultMessage: 'is moderated',
  },
  contentType: {
    id: 'contentType',
    defaultMessage: 'content type:',
  },
  contentCategory: {
    id: 'contentCategory',
    defaultMessage: 'content Category:',
  },
  generate: {
    id: 'generate',
    defaultMessage: 'generate',
  },
  copied: {
    id: 'copied',
    defaultMessage: 'Copied',
  },
  seconds: {
    id: 'seconds',
    defaultMessage: 'seconds',
  },
  contentStyleAndCharacteristics: {
    id: 'contentStyleAndCharacteristics',
    defaultMessage: 'content styles and characteristics',
  },
  contentStyles: {
    id: 'contentStyles',
    defaultMessage: 'content styles',
  },
  ContentStyles: {
    id: 'ContentStyles',
    defaultMessage: 'Content Styles',
  },
  characteristics: {
    id: 'characteristics',
    defaultMessage: 'characteristics',
  },
  creativeStory: {
    id: 'creativeStory',
    defaultMessage: 'creative story',
  },
  AIScriptWriter: {
    id: 'AIScriptWriter',
    defaultMessage: 'AI Script Writer',
  },
  createAStory: {
    id: 'createAStory',
    defaultMessage: 'create a story',
  },
  videoCaption: {
    id: 'videoCaption',
    defaultMessage: 'video caption',
  },
  videoScript: {
    id: 'videoScript',
    defaultMessage: 'video script',
  },
  forecastedViews: {
    id: 'forecastedViews',
    defaultMessage: 'forecasted views',
  },
  recommendedVideoLength: {
    id: 'recommendedVideoLength',
    defaultMessage: 'recommended video length',
  },
  backgroundMusic: {
    id: 'backgroundMusic',
    defaultMessage: 'background music',
  },
  describeYourContent: {
    id: 'describeYourContent',
    defaultMessage: 'describe your content',
  },
  showMore: {
    id: 'showMore',
    defaultMessage: 'show more',
  },
  BenaAI: {
    id: 'BenaAI',
    defaultMessage: 'Bena AI',
  },
  ProductCategory: {
    id: 'ProductCategory',
    defaultMessage: 'Product Category',
  },
  ContentType: {
    id: 'ContentType',
    defaultMessage: 'Content Type',
  },
  ContentDescription: {
    id: 'ContentDescription',
    defaultMessage: 'Content Description',
  },
  views: {
    id: 'views',
    defaultMessage: 'views',
  },
  likes: {
    id: 'likes',
    defaultMessage: 'likes',
  },
  comments: {
    id: 'comments',
    defaultMessage: 'comments',
  },
  shares: {
    id: 'shares',
    defaultMessage: 'shares',
  },
  MyProfile: {
    id: 'MyProfile',
    defaultMessage: 'My Profile',
  },
  MyProfileHint: {
    id: 'MyProfileHint',
    defaultMessage: 'View your personal information and make possible changes.',
  },
  changePassword: {
    id: 'changePassword',
    defaultMessage: 'change password',
  },
  billing: {
    id: 'billing',
    defaultMessage: 'billing',
  },
  availableCredits: {
    id: 'availableCredits',
    defaultMessage: 'Available credits',
  },
  keywords: {
    id: 'keywords',
    defaultMessage: 'keywords',
  },
  save: {
    id: 'save',
    defaultMessage: 'save',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'cancel',
  },
  CancelAndQuestionMark: {
    id: 'CancelAndQuestionMark',
    defaultMessage: 'Cancel?',
  },
  close: {
    id: 'close',
    defaultMessage: 'close',
  },
  posts: {
    id: 'posts',
    defaultMessage: 'posts',
  },
  likesAndComments: {
    id: 'likesAndComments',
    defaultMessage: 'likes & comments',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'loading...',
  },
  features: {
    id: 'features',
    defaultMessage: 'features',
  },
  billingDetails: {
    id: 'billingDetails',
    defaultMessage: 'billing details',
  },
  currentUser: { id: 'currentUser', defaultMessage: 'Current user'},
  logInPlugin: { id: 'logInPlugin', defaultMessage: 'Login to plugin'},
  switchAccounts: { id: 'switchAccounts', defaultMessage: 'Switch Accounts'},
  paymentMethod: {
    id: 'paymentMethod',
    defaultMessage: 'payment method',
  },
  billingCycle: {
    id: 'billingCycle',
    defaultMessage: 'billing cycle',
  },
  renewOn: {
    id: 'renewOn',
    defaultMessage: 'renew on',
  },
  endingIn: {
    id: 'endingIn',
    defaultMessage: 'ending in',
  },
  primary: {
    id: 'primary',
    defaultMessage: 'Primary',
  },
  accountType: {
    id: 'accountType',
    defaultMessage: 'account type',
  },
  xPaymentAccount: {
    id: 'xPaymentAccount',
    defaultMessage: '{name} payment account',
  },
  cancelledOn: {
    id: 'cancelledOn',
    defaultMessage: 'cancelled on',
  },
  UpgradeSubscriptionPlan: {
    id: 'UpgradeSubscriptionPlan',
    defaultMessage: 'Upgrade Subscription Page',
  },
  SelectedPlan: {
    id: 'SelectedPlan',
    defaultMessage: 'Selected Plan',
  },
  subscribe: {
    id: 'subscribe',
    defaultMessage: 'subscribe',
  },
  ManageYourSubscription: {
    id: 'ManageYourSubscription',
    defaultMessage: 'Manage your subscription',
  },
  VisitThisLinkTo: {
    id: 'VisitThisLinkTo',
    defaultMessage:
      'Visit this link to update your payment info, change your plan, or cancel your subscription.',
  },
  YouDontHaveAnActive: {
    id: 'YouDontHaveAnActive',
    defaultMessage: "You don't have an active subscription plan.",
  },
  CancelCurrentSubscription: {
    id: 'CancelCurrentSubscription',
    defaultMessage:
      'Cancel your current subscription plan at any time by clicking the button below.',
  },
  ManageSubscription: {
    id: 'ManageSubscription',
    defaultMessage: 'Manage Subscription',
  },
  TooltipRegenerateWholeScript: {
    id: 'TooltipRegenerateWholeScript',
    defaultMessage: 'Regenerate whole script',
  },
  TooltipRegenerateCaption: {
    id: 'TooltipRegenerateCaption',
    defaultMessage: 'Regenerate the caption',
  },
  TooltipRegenerateScript: {
    id: 'TooltipRegenerateScript',
    defaultMessage: 'Regenerate the script',
  },
  TooltipRegenerateBackgroundMusic: {
    id: 'TooltipRegenerateBackgroundMusic',
    defaultMessage: 'Regenerate BGM',
  },
  TooltipFavoriteThisScript: {
    id: 'TooltipFavoriteThisScript',
    defaultMessage: 'Favorite this script',
  },
  TooltipDeleteThisScript: {
    id: 'TooltipDeleteThisScript',
    defaultMessage: 'Delete this script',
  },
  showMessageSendCodeSuccess: {
    id: 'showMessageSendCodeSuccess',
    defaultMessage: 'Verification code has been sent!',
  },
  SuccessfullySaved: {
    id: 'SuccessfullySaved',
    defaultMessage: 'Successfully saved!',
  },
  SuccessfullyRejected: {
    id: 'SuccessfullyRejected',
    defaultMessage: 'Successfully rejected!',
  },
  change: {
    id: 'change',
    defaultMessage: 'change',
  },
  English: {
    id: 'English',
    defaultMessage: 'English',
  },
  Chinese: {
    id: 'Chinese',
    defaultMessage: '中文',
  },
  DarkMode: {
    id: 'DarkMode',
    defaultMessage: 'Dark mode',
  },
  LightMode: {
    id: 'LightMode',
    defaultMessage: 'Light mode',
  },
  Dark: {
    id: 'Dark',
    defaultMessage: 'Dark',
  },
  White: {
    id: 'White',
    defaultMessage: 'White',
  },
  Default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  FollowOSTheme: {
    id: 'FollowOSTheme',
    defaultMessage: 'Follow OS Theme',
  },
  ClickToSwitchTheme: {
    id: 'ClickToSwitchTheme',
    defaultMessage: 'Click to switch theme mode',
  },
  You: {
    id: 'You',
    defaultMessage: 'You',
  },
  remainingEmails: { id: 'remainingEmails', defaultMessage: 'Email' },
  remainingScript: { id: 'remainingScript', defaultMessage: 'Script' },
  remainingDM: {
    id: 'remainingDM',
    defaultMessage: 'DM',
  },
  perMonth: {
    id: 'perMonth',
    defaultMessage: 'per month',
  },
  perYear: {
    id: 'perYear',
    defaultMessage: 'per year',
  },
  monthly: {
    id: 'monthly',
    defaultMessage: 'monthly',
  },
  yearly: {
    id: 'yearly',
    defaultMessage: 'yearly',
  },
  creditsPerYear: {
    id: 'creditsPerYear',
    defaultMessage: 'credits per year',
  },
  creditsPerMonth: {
    id: 'creditsPerMonth',
    defaultMessage: 'credits per month',
  },
  creativityScore: {
    id: 'creativityScore',
    defaultMessage: 'creativity score',
  },
  CancelSubscriptionSucceed: {
    id: 'CancelSubscriptionSucceed',
    defaultMessage: 'Cancel subscription plan succeed!',
  },
  CancelSubscription: {
    id: 'CancelSubscription',
    defaultMessage: 'Cancel subscription',
  },
  AreYouSureToCancel: {
    id: 'AreYouSureToCancel',
    defaultMessage: 'Are you sure to cancel the subscription?',
  },
  Confirm: {
    id: 'Confirm',
    defaultMessage: 'Confirm',
  },
  SeeAll: {
    id: 'SeeAll',
    defaultMessage: 'See All',
  },
  Copyright: {
    id: 'Copyright',
    defaultMessage: '© 2023 Bena. All Rights Reserved.',
  },
  PrivacyPolicy: {
    id: 'PrivacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  TermsAndConditions: {
    id: 'TermsAndConditions',
    defaultMessage: 'Terms & Conditions',
  },
  continueWithTiktok: {
    id: 'continueWithTiktok',
    defaultMessage: `Sign in with TikTok`,
  },
  emailAddress: {
    id: 'emailAddress',
    defaultMessage: 'Email address',
  },
  enterEmailAddress: {
    id: 'enterEmailAddress',
    defaultMessage: 'Enter email address',
  },
  verificationCode: {
    id: 'verificationCode',
    defaultMessage: 'Verification code from email',
  },
  enterYourFullname: {
    id: 'enterYourFullname',
    defaultMessage: 'Enter your fullname',
  },
  enter6DigitalCode: {
    id: 'enter6DigitalCode',
    defaultMessage: '------',
  },
  sendCode: {
    id: 'sendCode',
    defaultMessage: 'Send Code',
  },
  resendCodeIn: {
    id: 'resendCodeIn',
    defaultMessage: 'Resend in',
  },
  emailDraft: { id: 'emailDraft', defaultMessage: 'Draft' },
  emailResend: { id: 'emailResend', defaultMessage: 'Resend' },
  autoSend: { id: 'autoSend', defaultMessage: 'Auto Send' },
  totalEmails: { id: 'totalEmails', defaultMessage: 'Total Emails' },
  textSecondInAbbr: {
    id: 'textSecondInAbbr',
    defaultMessage: 's',
  },
  checkSpanFolder: {
    id: 'checkSpanFolder',
    defaultMessage: `Check email spam folder.`,
  },
  didntGetACode: {
    id: 'didntGetACode',
    defaultMessage: `Didn’t get a code?`,
  },
  successfullySaved: {
    id: 'successfullySaved',
    defaultMessage: 'Successfully saved!',
  },
  successfullySubmitted: {
    id: 'successfullySubmitted',
    defaultMessage: 'Successfully submitted!',
  },
  registerSuccess: {
    id: 'registerSuccess',
    defaultMessage: `You've been successfully registered on Bena!`,
  },
  registerSuccessTip: {
    id: 'registerSuccessTip',
    defaultMessage: `We will provide you with the great experience of collaboration`,
  },
  startCollaboration: {
    id: 'startCollaboration',
    defaultMessage: `Start collaboration`,
  },
  download: {
    id: 'download',
    defaultMessage: 'Download',
  },
  Fail: {
    id: 'Fail',
    defaultMessage: 'Fail',
  },
  fileSizeOverLimit: {
    id: 'fileSizeOverLimit',
    defaultMessage: 'File size over limit',
  },
  clickReUpload: {
    id: 'clickReUpload',
    defaultMessage: 'Click to upload again.',
  },
  startDate: {
    id: 'startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'endDate',
    defaultMessage: 'End Date',
  },
  chooseStartEndDate: {
    id: 'chooseStartEndDate',
    defaultMessage: 'Choose your start & end date',
  },
  chooseDate: {
    id: 'chooseDate',
    defaultMessage: 'Choose a date',
  },
  Search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  Custom: {
    id: 'Custom',
    defaultMessage: 'Custom',
  },
  pleaseSelectTodayOrAPastDate: {
    id: 'pleaseSelectTodayOrAPastDate',
    defaultMessage: 'Please select today or a past date',
  },
  Percent: {
    id: 'Percent',
    defaultMessage: 'Percent',
  },
  SelectAll: {
    id: 'Select All',
    defaultMessage: 'Select All',
  },
  creatorFollowers: {
    id: 'creatorFollowers',
    defaultMessage: 'Creator Followers',
  },
  avgContentViews: {
    id: 'avgContentViews',
    defaultMessage: 'Content Average Views',
  },
  followersAge: {
    id: 'followersAge',
    defaultMessage: "Followers' Age",
  },
  followersGender: {
    id: 'followersGender',
    defaultMessage: "Followers' Gender",
  },
  followersLocations: {
    id: 'followersLocations',
    defaultMessage: "Followers' Region",
  },
  creatorLocations: {
    id: 'creatorLocations',
    defaultMessage: "Creator's Region",
  },
  Campaigns: {
    id: 'Campaigns',
    defaultMessage: 'Campaigns',
  },
  NoFileFound: {
    id: 'NoFileFound',
    defaultMessage: 'No file found',
  },
  pageNotFound: {
    id: 'pageNotFound',
    defaultMessage: 'Oops! We are sorry the page was not found...',
  },
  homepage: {
    id: 'homepage',
    defaultMessage: 'Back to homepage',
  },
  pageNotFoundHint: {
    id: 'pageNotFoundHint',
    defaultMessage:
      'The page you are looking for might have been removed, had its name changed or is temporarily unavailable.',
  },
  alert: {
    id: 'alert',
    defaultMessage: 'Alert',
  },
  confirmLeave: {
    id: 'confirmLeave',
    defaultMessage: 'Confirm and Leave',
  },
  alertMessageLeaveCampaignEditing: {
    id: 'alertMessageLeaveCampaignEditing',
    defaultMessage:
      'You are going to leave this page, please make sure you have saved the data.',
  },
  stayOnThisPage: {
    id: 'stayOnThisPage',
    defaultMessage: 'Stay on This Page',
  },
  regenerate: {
    id: 'regenerate',
    defaultMessage: 'Regenerate',
  },
  ReloadUnsavedNewCampaignData: {
    id: 'ReloadUnsavedNewCampaignData',
    defaultMessage:
      'You have unsaved campaign creation since your last edit, do you want to reload? Click yes to use, no to delete.',
  },
  CopyCampaignURL: {
    id: 'CopyCampaignURL',
    defaultMessage: 'Copy campaign URL',
  },
  noInfoToShow: {
    id: 'noInfoToShow',
    defaultMessage: 'No info to show',
  },
  dataIsNotAvailableAtThisTime: {
    id: 'dataIsNotAvailableAtThisTime',
    defaultMessage: 'Data is not available at this time',
  },
  Edit: {
    id: 'Edit',
    defaultMessage: 'Edit',
  },
  EditData: {
    id: 'EditData',
    defaultMessage: 'Edit Data',
  },
  Submit: {
    id: 'Submit',
    defaultMessage: 'Submit',
  },
  SubmitApplication: {
    id: 'SubmitApplication',
    defaultMessage: 'Submit Application',
  },
  optionForever: {
    id: 'optionForever',
    defaultMessage: 'Forever',
  },
  optionOneWeek: {
    id: 'optionOneWeek',
    defaultMessage: '1 Week',
  },
  optionTwoWeeks: {
    id: 'optionTwoWeeks',
    defaultMessage: '2 Weeks',
  },
  optionOneMonth: {
    id: 'optionOneMonth',
    defaultMessage: '1 Month',
  },
  optionThreeMonths: {
    id: 'optionThreeMonths',
    defaultMessage: '3 Months',
  },
  optionSixMonths: {
    id: 'optionSixMonths',
    defaultMessage: '6 Months',
  },
  optionOneYear: {
    id: 'optionOneYear',
    defaultMessage: '1 Year',
  },
  Messages: {
    id: 'Messages',
    defaultMessage: 'Messages',
  },
  NumberOfResults: {
    id: 'NumberOfResults',
    defaultMessage: 'Results:',
  },
  showOnly: {
    id: 'showOnly',
    defaultMessage: 'Show only:',
  },
  contentPostLinks: {
    id: 'contentPostLinks',
    defaultMessage: 'Content post links',
  },
  messageHasBeenSentSuccessfully: {
    id: 'messageHasBeenSentSuccessfully',
    defaultMessage: 'Message has been sent successfully!',
  },
  draftAttachments: {
    id: 'draftAttachments',
    defaultMessage: 'Draft attachments',
  },
  milestoneAttachmentRequireChangeMessageHint: {
    id: 'milestoneAttachmentRequireChangeMessageHint',
    defaultMessage:
      'Please write down a message to request changes to the draft...',
  },
  milestoneAttachmentSuggestPostDateMessageHint: {
    id: 'milestoneAttachmentSuggestPostDateMessageHint',
    defaultMessage:
      'Seems you are good with the result, send a message to the creator and tell them the date to post...',
  },
  creatorsWork: {
    id: 'creatorsWork',
    defaultMessage: "Creator's Work",
  },
  thProposal: {
    id: 'thProposal',
    defaultMessage: 'Proposal',
  },
  creatorsMessageToYou: {
    id: 'creatorsMessageToYou',
    defaultMessage: "Creator's message to you:",
  },
  productLinks: {
    id: 'productLinks',
    defaultMessage: 'Product Links',
  },
  postDisplayDurationQuestion: {
    id: 'postDisplayDurationQuestion',
    defaultMessage:
      'How long the brand can keep the content on your social account?',
  },
  nDays: {
    id: 'nDays',
    defaultMessage: '{days} day{pl}',
  },
  promotionInBioLabel: {
    id: 'promotionInBioLabel',
    defaultMessage: 'Are you okay with including the promotion in your bio?',
  },
  rightUsageLabel: {
    id: 'rightUsageLabel',
    defaultMessage: 'Does the brand have usage rights?',
  },
  itWillCost: {
    id: 'itWillCost',
    defaultMessage: 'It will cost',
  },
  markMilestoneAsComplete: {
    id: 'markMilestoneAsComplete',
    defaultMessage: 'Mark it as complete?',
  },
  markMilestoneAsClosed: {
    id: 'markMilestoneAsClosed',
    defaultMessage: 'Mark it as closed',
  },
  changeMilestoneStatus: {
    id: 'changeMilestoneStatus',
    defaultMessage: 'Change milestone status',
  },
  paginationBack: {
    id: 'paginationBack',
    defaultMessage: 'Prev',
  },
  paginationNext: {
    id: 'paginationNext',
    defaultMessage: 'Next',
  },
  shippingStatusPRE_TRANSIT: {
    id: 'shippingStatusPRE_TRANSIT',
    defaultMessage: 'Pre transit',
  },
  shippingStatusTRANSIT: {
    id: 'shippingStatusTRANSIT',
    defaultMessage: 'Transit',
  },
  shippingStatusDELIVERED: {
    id: 'shippingStatusDELIVERED',
    defaultMessage: 'Delivered',
  },
  shippingStatusRETURNED: {
    id: 'shippingStatusRETURNED',
    defaultMessage: 'Returned',
  },
  shippingStatusFAILURE: {
    id: 'shippingStatusFAILURE',
    defaultMessage: 'Failure',
  },
  shippingStatusUNKNOWN: {
    id: 'shippingStatusUNKNOWN',
    defaultMessage: 'Unknown',
  },
  jobContractStatusDrafted: {
    id: 'jobContractStatusDrafted',
    defaultMessage: 'Drafted',
  },
  jobContractStatusOffered: {
    id: 'jobContractStatusOffered',
    defaultMessage: 'Offered',
  },
  jobContractStatusAccepted: {
    id: 'jobContractStatusAccepted',
    defaultMessage: 'Accepted',
  },
  jobContractStatusRejected: {
    id: 'jobContractStatusRejected',
    defaultMessage: 'Rejected',
  },
  jobContractStatusFinisheds: {
    id: 'jobContractStatusFinished',
    defaultMessage: 'Finished',
  },
  thName: {
    id: 'thName',
    defaultMessage: 'Name',
  },
  thCreator: {
    id: 'thCreator',
    defaultMessage: 'Creator',
  },
  thMessages: {
    id: 'thMessages',
    defaultMessage: 'Messages',
  },
  thProposalStatus: {
    id: 'thProposalStatus',
    defaultMessage: 'Status',
  },
  thShippingStatus: {
    id: 'thShippingStatus',
    defaultMessage: 'Shipping Status',
  },
  thCurrentMilestone: {
    id: 'thCurrentMilestone',
    defaultMessage: 'Current Milestone',
  },
  thDraftAttachment: {
    id: 'thDraftAttachment',
    defaultMessage: 'Draft Attachment',
  },
  thDraftApproved: {
    id: 'thDraftApproved',
    defaultMessage: 'Draft Approved',
  },
  thContentLink: {
    id: 'thContentLink',
    defaultMessage: 'Content Link',
  },
  thCurrentPayout: {
    id: 'thCurrentPayout',
    defaultMessage: 'Current Payout',
  },
  totalPrice: {
    id: 'totalPrice',
    defaultMessage: 'Total Price',
  },
  thContractStatus: {
    id: 'thContractStatus',
    defaultMessage: 'Contract Status',
  },
  viewAttachment: {
    id: 'viewAttachment',
    defaultMessage: 'View attachment',
  },
  noDraftYet: {
    id: 'noDraftYet',
    defaultMessage: 'No draft yet',
  },
  draftApproved: {
    id: 'draftApproved',
    defaultMessage: 'Approved',
  },
  noDraft: {
    id: 'noDraft',
    defaultMessage: 'No draft',
  },
  checkLinks: {
    id: 'checkLinks',
    defaultMessage: 'Check links',
  },
  pendingApproval: {
    id: 'pendingApproval',
    defaultMessage: 'Pending Approval',
  },
  Creators: {
    id: 'Creators',
    defaultMessage: 'Creators',
  },
  thMessagesSent: {
    id: 'thMessagesSent',
    defaultMessage: 'Messages Sent',
  },
  thLastMessage: {
    id: 'thLastMessage',
    defaultMessage: 'Last Message',
  },
  thMessageStatus: {
    id: 'thMessageStatus',
    defaultMessage: 'Message Status',
  },
  thStatus: {
    id: 'thStatus',
    defaultMessage: 'Status',
  },
  thAlert: {
    id: 'thAlert',
    defaultMessage: 'Alert',
  },
  thTurnaroundTime: {
    id: 'thTurnaroundTime',
    defaultMessage: 'Turnaround Time',
  },
  thPrice: {
    id: 'thPrice',
    defaultMessage: 'Price',
  },
  thActions: {
    id: 'thActions',
    defaultMessage: 'Actions',
  },
  leaveAMessage: {
    id: 'leaveAMessage',
    defaultMessage: '留言',
  },
  hire: {
    id: 'Campaign.hire',
    defaultMessage: 'Hire',
  },
  reject: {
    id: 'reject',
    defaultMessage: 'Reject',
  },
  youToReply: {
    id: 'youToReply',
    defaultMessage: 'You to reply',
  },
  creatorToReply: {
    id: 'creatorToReply',
    defaultMessage: 'Creator to reply',
  },
  read: {
    id: 'read',
    defaultMessage: 'Read',
  },
  action: {
    id: 'action',
    defaultMessage: 'Action',
  },
  Negotiable: {
    id: 'Negotiable',
    defaultMessage: 'Negotiable',
  },
  confirmation: {
    id: 'confirmation',
    defaultMessage: 'Confirmation',
  },
  rejectApplicationConfirmation: {
    id: 'rejectApplicationConfirmation',
    defaultMessage: 'Are you sure that you want to reject the application?',
  },
  n1day: {
    id: 'n1day',
    defaultMessage: '1 day',
  },
  n2days: {
    id: 'n2days',
    defaultMessage: '2 days',
  },
  n5days: {
    id: 'n5days',
    defaultMessage: '5 days',
  },
  n10days: {
    id: 'n10days',
    defaultMessage: '10 days',
  },
  n10updays: {
    id: 'n10updays',
    defaultMessage: '10 days before',
  },
  All: {
    id: 'All',
    defaultMessage: 'All',
  },
  toreplyBrand: {
    id: 'toreplyBrand',
    defaultMessage: 'Brand',
  },
  toreplyCreator: {
    id: 'toreplyCreator',
    defaultMessage: 'Creator',
  },
  shippingInProgress: {
    id: 'shippingInProgress',
    defaultMessage: 'In progress',
  },
  shippingDelivered: {
    id: 'shippingDelivered',
    defaultMessage: 'Delivered',
  },
  milestoneStatusOpen: {
    id: 'milestoneStatusOpen',
    defaultMessage: 'Open',
  },
  milestoneStatusPending: {
    id: 'milestoneStatusPending',
    defaultMessage: 'Pending',
  },
  milestoneStatusCompleted: {
    id: 'milestoneStatusCompleted',
    defaultMessage: 'Completed',
  },
  milestoneStatusClosed: {
    id: 'milestoneStatusClosed',
    defaultMessage: 'Closed',
  },
  milestoneInProgress: {
    id: 'milestoneInProgress',
    defaultMessage: 'In progress',
  },
  paymentRequiredYes: {
    id: 'paymentRequiredYes',
    defaultMessage: 'Yes',
  },
  paymentRequiredNo: {
    id: 'paymentRequiredNo',
    defaultMessage: 'No',
  },
  contractStatusAccepted: {
    id: 'contractStatusAccepted',
    defaultMessage: 'Accepted',
  },
  contractStatusOffered: {
    id: 'contractStatusOffered',
    defaultMessage: 'Offered',
  },
  contractStatusFinished: {
    id: 'contractStatusFinished',
    defaultMessage: 'Finished',
  },
  requirePayment: {
    id: 'requirePayment',
    defaultMessage: 'Payment required',
  },
  contentPosted: {
    id: 'contentPosted',
    defaultMessage: 'Content Posted',
  },
  milestoneStatus: {
    id: 'milestoneStatus',
    defaultMessage: 'Milestone Status',
  },
  payoutAmount: {
    id: 'payoutAmount',
    defaultMessage: 'Payout Amount',
  },
  enterMessage: {
    id: 'enterMessage',
    defaultMessage: 'Type your message',
  },
  send: {
    id: 'send',
    defaultMessage: 'Send',
  },
  campaignProgress: {
    id: 'campaignProgress',
    defaultMessage: 'Campaign progress',
  },
  incomingProposals: {
    id: 'incomingProposals',
    defaultMessage: 'Incoming proposals',
  },
  campaignInfo: {
    id: 'campaignInfo',
    defaultMessage: 'Campaign Info',
  },
  Hired: {
    id: 'Hired',
    defaultMessage: 'Hired',
  },
  Proposals: {
    id: 'Proposals',
    defaultMessage: 'Proposals',
  },
  DeclinedProposals: {
    id: 'DeclinedProposals',
    defaultMessage: 'Declined Proposals',
  },
  Campaign: {
    id: 'Campaign',
    defaultMessage: 'Campaign',
  },
  SeemsLikeYouDont: {
    id: 'SeemsLikeYouDont',
    defaultMessage: 'Seems like you don’t have any campaigns yet!',
  },
  SeemsLikeItDoesntHave: {
    id: 'SeemsLikeItDoesntHave',
    defaultMessage: 'Seems like it doesn’t have any opportunities yet!',
  },
  SeemsLikeItDoesntHaveHiredJob: {
    id: 'SeemsLikeItDoesntHaveHiredJob',
    defaultMessage: 'Seems like it doesn’t have any hired job yet!',
  },
  GoAndCreateIt: {
    id: 'GoAndCreateIt',
    defaultMessage: 'Go and create it!',
  },
  PressEnterToSend: {
    id: 'PressEnterToSend',
    defaultMessage: 'Press ENTER to send',
  },
  SendOffer: {
    id: 'SendOffer',
    defaultMessage: 'Send Offer',
  },
  CollabrationProcessConfiguration: {
    id: 'CollabrationProcessConfiguration',
    defaultMessage: 'Collabration process configuration',
  },
  ClickToSort: {
    id: 'ClickToSort',
    defaultMessage: 'Click to sort',
  },
  DirectMessage: {
    id: 'DirectMessage',
    defaultMessage: 'Direct Message',
  },
  thProductionSelection: {
    id: 'thProductionSelection',
    defaultMessage: 'Product Selection',
  },
  thPrepayment: {
    id: 'thPrepayment',
    defaultMessage: 'Prepayment',
  },
  thProduction: {
    id: 'thProduction',
    defaultMessage: 'Production',
  },
  thDraftSubmission: {
    id: 'thDraftSubmission',
    defaultMessage: 'Draft Submission',
  },
  thPostLink: {
    id: 'thPostLink',
    defaultMessage: 'Post Link',
  },
  thPayment: {
    id: 'thPayment',
    defaultMessage: 'Payment',
  },
  thReview: {
    id: 'thReview',
    defaultMessage: 'Review',
  },
  PaymentInformation: {
    id: 'PaymentInformation',
    defaultMessage: 'Payment Information',
  },
  ReadProposal: {
    id: 'ReadProposal',
    defaultMessage: 'Read Proposal',
  },
  Loadmore: {
    id: 'Loadmore',
    defaultMessage: 'Load More',
  },
  Add: {
    id: 'Add',
    defaultMessage: 'Add',
  },
  BrandName: {
    id: 'BrandName',
    defaultMessage: 'Brand name ',
  },
  enterLinkUrl: { id: 'enterLinkUrl', defaultMessage: 'Please enter the link url' },
  enterLinkDescription: { id: 'enterLinkDescription', defaultMessage: 'Please enter the link description' },
  enterValidLinkUrl: { id: 'enterValidLinkUrl', defaultMessage: 'Please enter a valid link url' },
  LinkToTheBestVideo: {
    id: 'LinkToTheBestVideo',
    defaultMessage: 'Link to the best video',
  },
  PromotedBrands: {
    id: 'PromotedBrands',
    defaultMessage: 'Promoted Brands',
  },
  ContentStyle: {
    id: 'ContentStyle',
    defaultMessage: 'Content Style',
  },
  AvgContentViewsAndEngagement: {
    id: 'AvgContentViewsAndEngagement',
    defaultMessage: 'Avg Content Views and Engagement',
  },
  CreatorStatistics: {
    id: 'CreatorStatistics',
    defaultMessage: 'Creator Statistics',
  },
  AudienceDemographics: {
    id: 'AudienceDemographics',
    defaultMessage: 'Audience Demographics',
  },
  FollowerDemographics: {
    id: 'FollowerDemographics',
    defaultMessage: 'Follower Demographics',
  },
  View: {
    id: 'View',
    defaultMessage: 'View',
  },
  PleaseEnterAValueBetween1And100: {
    id: 'PleaseEnterAValueBetween1And100',
    defaultMessage: 'Please enter a value between 0 and 100.',
  },
  Payout: {
    id: 'Payout',
    defaultMessage: 'Payout',
  },
  PerCreator: {
    id: 'PerCreator',
    defaultMessage: 'Per Creator',
  },
  AffiliateRate: {
    id: 'AffiliateRate',
    defaultMessage: 'Affiliate Rate',
  },
  SalesCommission: {
    id: 'SalesCommission',
    defaultMessage: 'Sales Commission',
  },
  Home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  Jobs: {
    id: 'Jobs',
    defaultMessage: 'Jobs',
  },
  Settings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  logoutMenu: {
    id: 'logoutMenu',
    defaultMessage: 'Log Out',
  },
  ChangeEmailHint: {
    id: 'ChangeEmailHint',
    defaultMessage: 'You can change your email in "My profile".',
  },
  ClickTheNumbersToEdit: {
    id: 'ClickTheNumbersToEdit',
    defaultMessage: 'Click the numbers to edit',
  },
  InviteToCampaign: {
    id: 'InviteToCampaign',
    defaultMessage: 'Invite To Campaign',
  },
  AddCreatorToCampaign: {
    id: 'AddCreatorToCampaign',
    defaultMessage: 'Add Creator To Campaign',
  },
  MediaKitLink: {
    id: 'MediaKitLink',
    defaultMessage: 'Media Kit Link',
  },
  CreatorTryLoginWith: {
    id: 'CreatorTryLoginWith',
    defaultMessage: 'Creator? Try login with',
  },
  StartedOn: {
    id: 'StartedOn',
    defaultMessage: 'Started on:',
  },
  Loading: {
    id: 'Loading',
    defaultMessage: 'Loading',
  },
  InviteNToCampaign: {
    id: 'InviteNToCampaign',
    defaultMessage: 'Invite to {number} campaign{s}',
  },
  EnterTrackingNoIf: {
    id: 'EnterTrackingNoIf',
    defaultMessage: 'Enter tracking number if you have one',
  },
  EnterTrackingNumber: {
    id: 'EnterTrackingNumber',
    defaultMessage: 'Enter tracking number',
  },
  EnterPaymentInfo: {
    id: 'EnterPaymentInfo',
    defaultMessage: 'Enter payment info',
  },
  Done: {
    id: 'Done',
    defaultMessage: 'Done',
  },
  Enter: {
    id: 'Enter',
    defaultMessage: 'Enter',
  },
  Paid: {
    id: 'Paid',
    defaultMessage: 'Paid',
  },
  SelectState: {
    id: 'SelectState',
    defaultMessage: 'Select State',
  },
  PersonalIssues: {
    id: 'PersonalIssues',
    defaultMessage: 'Personal Issues',
  },
  NotYet: {
    id: 'NotYet',
    defaultMessage: 'NotYet',
  },
  Shooting: {
    id: 'Shooting',
    defaultMessage: 'Shooting',
  },
  PendingApproval: {
    id: 'PendingApproval',
    defaultMessage: 'Pending Approval',
  },
  NoDraftYet: {
    id: 'NoDraftYet',
    defaultMessage: 'No Draft Yet',
  },
  Rejected: {
    id: 'Rejected',
    defaultMessage: 'Rejected',
  },
  Approved: {
    id: 'Approved',
    defaultMessage: 'Approved',
  },
  Check: {
    id: 'Check',
    defaultMessage: 'Check',
  },
  Give: {
    id: 'Give',
    defaultMessage: 'Give',
  },
  ReviewThisCreator: {
    id: 'ReviewThisCreator',
    defaultMessage: 'Review this creator',
  },
  Cooperation: {
    id: 'Cooperation',
    defaultMessage: 'Cooperation',
  },
  QualityOfWork: {
    id: 'QualityOfWork',
    defaultMessage: 'Quality of Work',
  },
  AdherenceToSchedule: {
    id: 'AdherenceToSchedule',
    defaultMessage: 'Adherence to Schedule',
  },
  ShareYourExperience: {
    id: 'ShareYourExperience',
    defaultMessage: 'Share your experience with this creator (Optional)',
  },
  ChooseSupplier: {
    id: 'ChooseSupplier',
    defaultMessage: 'Choose a supplier',
  },
  YourJobs: {
    id: 'YourJobs',
    defaultMessage: 'Your Jobs',
  },
  Close: {
    id: 'Close',
    defaultMessage: 'Close',
  },
  DraftAsAFile: {
    id: 'DraftAsAFile',
    defaultMessage: 'Draft as a file (maximum 5 files)',
  },
  PleaseExplainWhat: {
    id: 'PleaseExplainWhat',
    defaultMessage: 'Please explain what’s wrong',
  },
  BrandsComplaint: {
    id: 'BrandsComplaint',
    defaultMessage: 'Brand’s complain',
  },
  ApproveFile: {
    id: 'ApproveFile',
    defaultMessage: 'Approve file',
  },
  RejectFile: {
    id: 'RejectFile',
    defaultMessage: 'Reject file',
  },
  LinkToTheDraft: {
    id: 'LinkToTheDraft',
    defaultMessage: 'Link to the draft on your social media',
  },
  SubmitPostLinks: {
    id: 'SubmitPostLinks',
    defaultMessage: 'Submit post links',
  },
  SubmitWorks: {
    id: 'SubmitWorks',
    defaultMessage: 'Submit works',
  },
  ViewOffer: {
    id: 'ViewOffer',
    defaultMessage: 'View Offer',
  },
  ViewInvitation: {
    id: 'ViewInvitation',
    defaultMessage: 'View Invitation',
  },
  BrandChecking: {
    id: 'BrandChecking',
    defaultMessage: 'Brand Checking',
  },
  DeleteOffer: {
    id: 'DeleteOffer',
    defaultMessage: 'Delete Offer',
  },
  SuccessfullyDeleted: {
    id: 'SuccessfullyDeleted',
    defaultMessage: 'Successfully Deleted',
  },
  MilestonesForThisCampaign: {
    id: 'MilestonesForThisCampaign',
    defaultMessage: 'Milestones for this campaign',
  },
  Decline: {
    id: 'Decline',
    defaultMessage: 'Decline',
  },
  Accept: {
    id: 'Accept',
    defaultMessage: 'Accept',
  },
  RejectTheOffer: {
    id: 'RejectTheOffer',
    defaultMessage: 'Rejct the offer?',
  },
  ReasonOfReject: {
    id: 'ReasonOfReject',
    defaultMessage: 'Reason of reject (Optional)',
  },
  StartMakingTheContent: {
    id: 'StartMakingTheContent',
    defaultMessage: 'Start making the content',
  },
  ResubmitTheDraft: {
    id: 'ResubmitTheDraft',
    defaultMessage: 'Resubmit the draft',
  },
  CheckSubmittedDraft: {
    id: 'CheckSubmittedDraft',
    defaultMessage: 'Check submitted draft',
  },
  StartTheWork: {
    id: 'StartTheWork',
    defaultMessage: 'Start the work',
  },
  SubmitYourWork: {
    id: 'SubmitYourWork',
    defaultMessage: 'Submit your work',
  },
  Submitted: {
    id: 'Submitted',
    defaultMessage: 'Submitted',
  },
  DraftApproved: {
    id: 'DraftApproved',
    defaultMessage: 'Draft approved',
  },
  DraftRejected: {
    id: 'DraftRejected',
    defaultMessage: 'Draft rejected',
  },
  Refresh: {
    id: 'Refresh',
    defaultMessage: 'Refresh',
  },
  ClickToCancelThisInvitation: {
    id: 'ClickToCancelThisInvitation',
    defaultMessage: 'Click to cancel this inviation',
  },
  NoDraftFound: {
    id: 'NoDraftFound',
    defaultMessage: 'No draft found',
  },
  DraftsApprovalNoteForBrand: {
    id: 'DraftsApprovalNoteForBrand',
    defaultMessage:
      'Attention: current step will be automatically finished once all drafts are approved! Please check with the Creator to finish drafts uploading.',
  },
  SpecifiedCampaignNotFound: {
    id: 'SpecifiedCampaignNotFound',
    defaultMessage:
      'It seems you have already applied for this campaign, check the Jobs page for more details.',
  },
  CheckReview: {
    id: 'CheckReview',
    defaultMessage: 'Check Review',
  },
  NoLinkPostedYet: {
    id: 'NoLinkPostedYet',
    defaultMessage: 'No link posted yet',
  },
  PostedLinkNoN: {
    id: 'PostedLinkNoN',
    defaultMessage: 'Posted Link No.{n}',
  },
  PostLinkUrl: {
    id: 'PostLinkUrl',
    defaultMessage: 'Post link URL',
  },
  PostLinkDescription: {
    id: 'PostLinkDescription',
    defaultMessage: 'Post link description',
  },
  PendingCreatorRespond: {
    id: 'PendingCreatorRespond',
    defaultMessage: 'Pending Creator Respond',
  },
  ResendOffer: {
    id: 'ResendOffer',
    defaultMessage: 'Resend Offer',
  },
  AreYouSureToRejectTheInvitation: {
    id: 'AreYouSureToRejectTheInvitation',
    defaultMessage: 'Are you sure to reject the invitation?',
  },
  MoreProductLinks: {
    id: 'MoreProductLinks',
    defaultMessage: 'More Product Links',
  },
  LinkDescription: {
    id: 'LinkDescription',
    defaultMessage: 'Link description',
  },
  LinkURL: {
    id: 'LinkURL',
    defaultMessage: 'Link URL',
  },
  EditBrandName: {
    id: 'EditBrandName',
    defaultMessage: 'Edit Brand Name',
  },
  EditName: {
    id: 'EditName',
    defaultMessage: 'Edit Name',
  },
  EditPhoneNumber: {
    id: 'EditPhoneNumber',
    defaultMessage: 'Edit Phone Number',
  },
  CreatorHasEmail: {
    id: 'CreatorHasEmail',
    defaultMessage: 'Creator has Email',
  },
  ScriptWriter: {
    id: 'ScriptWriter',
    defaultMessage: 'Script Writer',
  },
  CreatorSearchHint: {
    id: 'CreatorSearchHint',
    defaultMessage: 'Please enter TikTok account id / username',
  },
  HasTikTokShop: {
    id: 'HasTikTokShop',
    defaultMessage: 'Joined TikTok Shop Affiliate',
  },
  MyCreators:{
    id: 'MyCreators',
    defaultMessage: 'My Creators',
  },
  createNewCreator:{
    id: 'createNewCreator',
    defaultMessage: 'Create new list',
  },
  deleteShortList:{
    id: 'deleteShortList',
    defaultMessage: 'Are you sure you want delete: "12abc"?',
  },
  CancelCapitalization:{
    id: 'CancelCapitalization',
    defaultMessage: 'Cancel',
  },
  Delete:{
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  Warning:{
    id: 'Warning',
    defaultMessage: 'Warning',
  },
  searchOrCreateList:{
    id: 'searchOrCreateList',
    defaultMessage: 'Search or create a list',
  },
  ThereIsEnterCreate:{ id: 'ThereIsEnterCreate', defaultMessage: 'There is no such list, press "Enter" to create it' },
  packageDescription1: { id: 'packageDescription7', defaultMessage: 'Apply to as many campaigns as you want.' },
  packageDescription2: { id: 'packageDescription8', defaultMessage: 'Unlimited access to Bena AI generated content such as the script and email writers.' },
  packageDescription3: { id: 'packageDescription9', defaultMessage: 'Directly communicate with brands, exchange documents, and track your campaign progress.' },
  packageDescription4: { id: 'packageDescriptionA', defaultMessage: 'Priority customer support.' },
  AddToList:{
    id: 'AddToList',
    defaultMessage: 'Add To List',
  },
  // Worked with brands
  WorkedWithBrands: {
    id: 'WorkedWithBrands',
    defaultMessage: 'Worked with brands',
  },
  avgViews: {
    id: 'avgViews',
    defaultMessage: 'Avg views',
  },
  sponsoredBrand:{
    id: 'sponsoredBrand',
    defaultMessage: 'Sponsored Brand',
  },
  shopEnabled:{
    id: 'shopEnabled',
    defaultMessage: 'TikTok Shop Enabled',
  },
  ContinueToUse: { id: 'ContinueToUse', defaultMessage: 'Continue to use Bena' },
  submitUninstall: { id: 'submitUninstall', defaultMessage: 'Submit and uninstall' },
  uninstallPrompt1: { id: 'uninstallPrompt1', defaultMessage: 'We are sorry to see you leave' },
  uninstallPrompt2: { id: 'uninstallPrompt2', defaultMessage: 'Bena has been uninstalled' },
  uninstallPrompt3: { id: 'uninstallPrompt3', defaultMessage: 'We will make every effort to solve your problem.' },
  uninstallPrompt4: { id: 'uninstallPrompt4', defaultMessage: 'We will read and reply to each email separately, usually providing a solution!' },
  uninstallPrompt5: { id: 'uninstallPrompt5', defaultMessage: "It's ready soon! Just one step away" },
  uninstallPrompt6: { id: 'uninstallPrompt6', defaultMessage: 'Can you tell us why you uninstalled it?' },
  uninstallPrompt7: { id: 'uninstallPrompt7', defaultMessage: 'Bena always blocks me or makes it difficult to use' },
  uninstallPrompt8: { id: 'uninstallPrompt8', defaultMessage: 'Bena makes my computer slow down' },
  uninstallPrompt9: { id: 'uninstallPrompt9', defaultMessage: "I don't know how to use Bena" },
  uninstallPromptA: { id: 'uninstallPromptA', defaultMessage: "Bena's query count is not enough" },
  uninstallPromptB: { id: 'uninstallPromptB', defaultMessage: "Bena's subscription cost is high" },
  uninstallPromptC: { id: 'uninstallPromptC', defaultMessage: 'I have other AI plugins or found better tools' },
});
