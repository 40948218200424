import {
  capitalize,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  SelectArrowDownGreyIcon,
  CrossIcon,
  SelectArrowDownWhiteIcon,
  SelectArrowDownBlackIcon,
} from '../assets/imgs/icons';
import { useThemeMode } from '../hooks/theme';
import InputRequired from './InputRequired';

type TitledSelectProp = {
  id: string;
  title: string;
  value: string;
  setValue: fnStringToVoid;
  options: { value: string; name: string }[];
  required?: boolean;
  clearable?: boolean;
  className?: string;
};
export const TitledSelect = ({
  id,
  title,
  value,
  setValue,
  options,
  required = false,
  clearable = false,
  className = '',
}: TitledSelectProp) => {
  const theme = useTheme();
  const isDarkMode = useThemeMode() === 'dark';
  return (
    <FormControl>
      <Stack
        direction="row"
        alignItems={'center'}
        position="relative"
        className={className}
      >
        {!value && (
          <Typography
            color="grey.50"
            sx={{
              marginTop: '0px',
              position: 'absolute',
              left: 0,
              top: '13px',
            }}
          >
            {capitalize(title)}
            {required && (
              <>
                &nbsp;
                <InputRequired />
              </>
            )}
          </Typography>
        )}
        <Select
          id={id}
          value={value}
          renderValue={(v) => (
            <Typography>
              {v}
              {required && (
                <>
                  &nbsp;
                  <InputRequired />
                </>
              )}
            </Typography>
          )}
          label=""
          onChange={(e) => {
            setValue(e.target.value);
          }}
          IconComponent={
            value
              ? isDarkMode
                ? SelectArrowDownWhiteIcon
                : SelectArrowDownBlackIcon
              : SelectArrowDownGreyIcon
          }
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: '0px',
            },
            '& .MuiSelect-select': {
              paddingLeft: '0px !important',
            },
            bgcolor: 'transparent',
            minWidth: '12rem',
            paddingLeft: 0,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
        >
          {options.map((v) => (
            <MenuItem value={v.value} key={v.value}>
              {v.name}
            </MenuItem>
          ))}
        </Select>
        {clearable && value && (
          <Stack alignItems={'center'} justifyContent="center">
            <IconButton onClick={() => setValue('')} sx={{ ml: '0.125rem' }}>
              <CrossIcon
                stroke={theme.palette.base.black}
                sx={{ width: '0.5rem', height: '0.5rem' }}
              />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </FormControl>
  );
};
