import { useTypedSelector } from '../redux/store';
import { selectAuth } from '../features/account/AuthSlice';

export function useThemeMode() {
  let themeMode = useTypedSelector((state) => selectAuth(state)).themeMode;
  // os is for backward compatible
  if (['default', 'os'].includes(themeMode)) {
    // follow os mode
    const themeMedia = window.matchMedia('(prefers-color-scheme: light)');
    themeMode = themeMedia.matches ? 'light' : 'dark';
  }

  return themeMode === 'light' ? 'light' : 'dark';
}
