// this is to sync the data to ios app via wkwebview

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function wkwebviewPostMessage(json: any) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (window as any).webkit?.messageHandlers?.BENA_APP;
    handler?.postMessage(json);
    console.log(json, handler);
  } catch (err) {
    console.log('wkwebviewPostMessage', err);
  }
}

// for login and token refresh
export function wkwebviewLogin(token: string, userid?: string | null) {
  const json = { action: 'login', token, id: userid || '' };
  wkwebviewPostMessage(json);
}

export function wkwebviewLogout() {
  const json = { action: 'logout' };
  wkwebviewPostMessage(json);
}
