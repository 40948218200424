import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import LoadingDotsSvg from '../components/LoadingDotsSvg';

export function InfiniteScrollEndMessage() {
  return (
    <Box className="boxcenterhv" sx={{ p: 2 }}>
      <Typography variant="caption">
        {/* TODO add load data end msg if needs */}
      </Typography>
    </Box>
  );
}

export function InfiniteScrollLoaderMessage({
  width = 75,
  p = 2,
  visible = true,
}: {
  width?: number;
  p?: number;
  visible?: boolean;
}) {
  const muiTheme = useTheme();
  return (
    <Box
      className="boxcenterhv"
      sx={{ p, visibility: visible ? 'visible' : 'hidden' }}
    >
      <LoadingDotsSvg
        animate={true}
        color={muiTheme.palette.primary.main}
        width={width}
      />
    </Box>
  );
}
