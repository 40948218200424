import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { getCountryNames } from '../utils/countries';

export function useFilterCountriesByName() {
  const intl = useIntl();
  const filteredFn = useCallback(
    (search: string) => {
      const countries = getCountryNames(intl);
      const ret = Object.keys(countries)
        .map((v) => countries[v as keyof typeof countries])
        .filter((w) => w.toLowerCase().startsWith(search.toLowerCase()));
      return ret;
    },
    [intl]
  );
  return filteredFn;
}
