import {
  IconButton,
  useTheme,
  Stack,
  Avatar,
  Typography,
  Box,
  Link,
} from '@mui/material';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { FavoriteOutlinedIcon } from '../../assets/imgs/icons';
import LightTooltip from '../../components/LightTooltip';
import { useAuthUserAvatar, useIsApiLoading } from '../../hooks/account';
import { usePatchAIGCScript } from '../../hooks/aigc';
import { useThemeMode } from '../../hooks/theme';
import { Messages } from '../../localization/Messages';
import { CampaignApplicationActions } from '../robot/actions';
import { LinkItUrl } from 'react-linkify-it';
import {
  CreatorAIScriptPanelContext,
  CreatorContext,
} from '../context/context';
import { RobotIsAnswering } from '../robot/utils';
import ApplicationViewPage from './ApplicationViewPage';
import { useBreakpointXs } from '../../utils/useStyleHooks';

const STEPREG = /^Step [0-9]+\/[0-9]+$/;

function HighlightStep(v: string) {
  const text = v.trim();
  if (STEPREG.test(text)) {
    return (
      <Typography
        fontSize={'15px'}
        fontWeight={500}
        color="primary.700"
        component="span"
      >
        {text}
      </Typography>
    );
  } else {
    return v;
  }
}

const convertToHTML = (str: string) => {
  let arr = str.split('\n');
  for (let i = arr.length - 1; i >= 1; i--) {
    if (arr[i] === '') {
      arr = arr.slice(0, i);
    } else {
      break;
    }
  }
  return (
    <>
      {arr.map((v, index) => (
        <Fragment key={`${index}${v}`}>
          {HighlightStep(v)}
          <br />
        </Fragment>
      ))}
    </>
  );
};

type AIGCFavoriteIconButtonProps = {
  story: CreatorVideoScriptResponseType;
};
export function AIGCFavoriteIconButton({ story }: AIGCFavoriteIconButtonProps) {
  const intl = useIntl();
  const loading = useIsApiLoading();
  const theme = useTheme();
  const { handleFavorite, likeFavorite } = usePatchAIGCScript(story);
  return (
    <LightTooltip
      title={intl.formatMessage(Messages.TooltipFavoriteThisScript)}
    >
      <IconButton onClick={handleFavorite} disabled={loading}>
        <FavoriteOutlinedIcon
          sx={{ width: '1rem', height: '1rem' }}
          fill={
            likeFavorite?.favorite ? theme.palette.warning[500] : 'transparent'
          }
          stroke={theme.palette.warning[500]}
          fade={false}
        />
      </IconButton>
    </LightTooltip>
  );
}

export function StoryAvatarPanel() {
  const isDarkMode = useThemeMode() === 'dark';
  const intl = useIntl();
  const theme = useTheme();
  const isMobileView = useBreakpointXs();

  return (
    <Stack
      direction="row"
      padding={
        isMobileView ? '1rem 2.5rem 0.625rem 0rem' : '1rem 2.5rem 0rem 0.5rem'
      }
      alignItems={'center'}
      spacing={'0.5rem'}
    >
      {!isMobileView && (
        <Avatar
          src={isDarkMode ? '/logo-round-white.svg' : '/logo-round.svg'}
          sx={{
            marginTop: '0.5rem',
            background: theme.palette.primary[25],
          }}
          imgProps={{
            sx: {
              objectFit: 'contain',
              width: '16px',
              height: '16px',
            },
          }}
        />
      )}
      <Typography fontSize={'0.9375rem'} color="primary.950" fontWeight={500}>
        {intl.formatMessage(Messages.BenaAI)}
      </Typography>
      <Box flexGrow={1}></Box>
    </Stack>
  );
}

const InitFileData = {
  id: '',
  file: '',
  media_type: '',
};

type ScriptContentPanelProps = {
  text: string;
  action: CreatorRoleScriptActionType;
  images: string[];
  actionOptions?: ChatbotOptionType[];
  msg_data?: MsgDataType;
  typing: boolean;
};
export function ScriptContentPanel({
  text,
  action,
  images,
  actionOptions,
  msg_data,
  typing,
}: ScriptContentPanelProps) {
  const theme = useTheme();
  const intl = useIntl();
  const context = useContext(CreatorContext);
  const { appendBotMsgFromUserAction } = useContext(
    CreatorAIScriptPanelContext
  );
  // TODO move to context, right now it is for demo only
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { triggerUserAction } = useContext(CreatorContext);
  const [showDialogId, setShowDialogId] = useState('');
  const [file, setFile] = useState<FileUploadResponseType>(InitFileData);

  const handleHideDialog = useCallback(
    (submitted: boolean) => {
      setShowDialogId('');
      // hide script panel and display campaign detail page
      if (submitted) {
        context.setCurrentDisplayedCampaignId(
          context.campaignIdForScriptTypeCampaign
        );
        context.setCampaignIdForScriptTypeCampaign('');
        context.setCurrentScriptTitle('');
      }
    },
    [context]
  );
  const isMobileView = useBreakpointXs();

  return useMemo(
    () => (
      <>
        <StoryAvatarPanel />
        <Stack marginRight={isMobileView ? '16px' : '54px'}>
          <Box
            bgcolor={theme.palette.base.white}
            borderRadius="2px 10px 10px 10px"
            border={`1px solid ${theme.palette.neutral['25_2']}`}
            margin={isMobileView ? '0px auto 16px 0px' : '-2px auto 16px 54px'}
            className="shadowXSM"
            padding={isMobileView ? '12px 16px' : '20px 20px'}
            lineHeight="26px"
          >
            <LinkItUrl>{convertToHTML(text)}</LinkItUrl>
            {!typing && (
              <>
                <Box
                  marginTop={
                    CampaignApplicationActions[action] ? '1rem' : '0rem'
                  }
                >
                  {CampaignApplicationActions[action]?.({
                    actionType: action,
                    intl,
                    theme,
                    ...(action === 'multiple_selection_questions' ||
                    action === 'suggestion'
                      ? { items: selectedItems, setItems: setSelectedItems }
                      : {}),
                    triggerUserAction,
                    actionOptions,
                    msg_data,
                    file,
                    setFile,
                    appendBotMsgFromUserAction,
                  })}
                </Box>
                <Stack direction="row" flexWrap={'wrap'}>
                  {(images || [])
                    .filter((w) => w)
                    .map((x, index) => {
                      return (
                        <img
                          src={x}
                          key={index}
                          alt=""
                          style={{ maxWidth: '90%' }}
                        />
                      );
                    })}
                </Stack>
                <Stack
                  direction="row"
                  flexWrap={'wrap'}
                  rowGap="8px"
                  columnGap="8px"
                >
                  {msg_data?.links?.map((x, index) => (
                    <Link
                      key={`${index}_${x.url}`}
                      href={x.url || '#'}
                      target="_blank"
                      sx={{ padding: '4px' }}
                      onClick={(e) => {
                        // TODO update the label name
                        if (x.label === 'Home') {
                          e.stopPropagation();
                          e.preventDefault();
                          setShowDialogId(
                            x.url?.split('/')?.slice(-1)?.[0] || ''
                          );
                        }
                      }}
                    >
                      {x.label === 'Home'
                        ? 'View Application to Submit'
                        : x.label || ''}
                    </Link>
                  ))}
                  {msg_data?.links && !!showDialogId && (
                    <ApplicationViewPage
                      show={!!showDialogId}
                      hideDialog={handleHideDialog}
                      applicationId={showDialogId}
                    />
                  )}
                </Stack>
              </>
            )}
          </Box>
        </Stack>
      </>
    ),
    [
      text,
      action,
      images,
      actionOptions,
      msg_data,
      selectedItems,
      triggerUserAction,
      setSelectedItems,
      theme,
      intl,
      showDialogId,
      handleHideDialog,
      typing,
      file,
      setFile,
      appendBotMsgFromUserAction,
    ]
  );
}

type ScriptQueryInfoPanelProps = {
  story: CreatorRoleScriptItemType;
};
export function ScriptQueryInfoPanel({ story }: ScriptQueryInfoPanelProps) {
  const {
    content: { text },
  } = story;
  const intl = useIntl();
  const theme = useTheme();
  const avatar = useAuthUserAvatar();
  const isDarkMode = useThemeMode() === 'dark';
  const isMobileView = useBreakpointXs();
  return (
    <Stack paddingRight={isMobileView ? '16px' : '24px'}>
      <Stack alignItems={'flex-end'}>
        <Stack
          direction="row"
          alignItems={'center'}
          paddingTop="1rem"
          width={isMobileView ? 'calc(100% - 32px)' : 'calc(100% - 54px)'}
        >
          <Box flexGrow={1}></Box>
          <Typography
            fontSize={'0.9375rem'}
            color="primary.950"
            fontWeight={500}
          >
            {intl.formatMessage(Messages.You)}
            {!isMobileView && <>&nbsp;&nbsp;</>}
          </Typography>
          {!isMobileView && (
            <Box
              sx={{
                flexShrink: 0,
                flexGrow: 0,
                background: theme.palette.primary[25],
                borderRadius: '50%',
              }}
              className="boxcenterhv"
            >
              <Avatar
                src={
                  avatar ||
                  (isDarkMode ? '/imgs/user-white.svg' : '/imgs/user.svg')
                }
                sx={{
                  width: '32px',
                  height: '32px',
                }}
              ></Avatar>
            </Box>
          )}
        </Stack>
      </Stack>
      <Stack marginLeft={isMobileView ? '16px' : '54px'}>
        <Box
          borderRadius="10px 2px 10px 10px"
          margin={isMobileView ? '4px 0px 16px auto' : '4px 32px 16px auto'}
          bgcolor={theme.palette.primary[800]}
        >
          <Stack
            padding="20px 24px"
            sx={{
              '& a': {
                color: 'white',
              },
            }}
          >
            <LinkItUrl>
              <Typography
                color={theme.palette.base.white2white}
                fontSize={'16px'}
                fontWeight="400"
                lineHeight="26px"
                sx={{
                  wordBreak: 'break-all',
                }}
              >
                {convertToHTML(text)}
              </Typography>
            </LinkItUrl>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}

type ConversationRendererProps = {
  items: CreatorRoleScriptItemType[];
};
export function ConversationRenderer({ items }: ConversationRendererProps) {
  let lastItem = items.slice(-1)?.[0] as CreatorRoleScriptItemType | undefined;
  if (lastItem?.content?.type !== 'robot') {
    lastItem = items.slice(-2, -1)?.[0];
  }
  if (lastItem?.content?.type !== 'robot') {
    lastItem = undefined;
  }
  const lastScript = lastItem?.content?.text || '';
  const { script, typingScript } = useTypingAnimation(lastScript);
  useEffect(() => {
    typingScript();
  }, [items]);

  return (
    <>
      <DisplayScriptHelperImage />
      {items.map((item, index) => {
        const { type, text, action, images, actionOptions, msg_data } =
          item.content;
        let ret = <></>;
        const lastItem =
          items.length - 1 === index && text !== RobotIsAnswering.content.text;

        ret = msg_data?.hidden ? (
          <></>
        ) : type === 'user' ? (
          <ScriptQueryInfoPanel story={item} />
        ) : type === 'robot' ? (
          <>
            <ScriptContentPanel
              text={lastItem ? script : text}
              action={action}
              images={images}
              actionOptions={actionOptions}
              msg_data={msg_data}
              typing={lastItem && text !== script}
            />
          </>
        ) : (
          <></>
        );

        return <Fragment key={`${index}${item.created_at}`}>{ret}</Fragment>;
      })}
    </>
  );
}

type TimerOrNullType = NodeJS.Timeout | null;
export function useTypingAnimation(fullScript: string, sep = ' ') {
  const position = useRef(0);
  const [script, setScript] = useState('');
  const [timer, _setTimer] = useState<TimerOrNullType>(null);
  useEffect(() => {
    if (fullScript === RobotIsAnswering.content.text) {
      setScript(fullScript);
    }
    position.current = 0;
    timer && clearTimeout(timer);
  }, [fullScript]);

  const ratio = Math.ceil(Math.max(10, fullScript.length) / 70);
  const setTimer = (t: TimerOrNullType) =>
    _setTimer((w) => {
      w && clearTimeout(w);
      return t;
    });

  const typingScript = () => {
    const t = setTimeout(() => {
      if (!fullScript) return;
      let next = fullScript.slice(position.current).indexOf(sep);
      next =
        next < 0 || next > 5 * ratio
          ? 5 * ratio
          : next === 0
          ? 2 * ratio
          : next;
      if (fullScript.length > position.current) {
        position.current += next;
        setScript(fullScript.slice(0, position.current));
        const tn = setTimeout(() => {
          typingScript();
        }, 50);
        setTimer(tn);
      }
    }, 50);
    setTimer(t);
  };
  useEffect(() => {
    return () => {
      timer && clearTimeout(timer);
    };
  }, [timer]);
  return { script, typingScript };
}
export function DisplayScriptHelperImage() {
  const context = useContext(CreatorContext);
  const scriptType = context.currentScriptType;
  const src =
    scriptType === 'email'
      ? '/imgs/creator/contentHelpersImage01.svg'
      : scriptType === 'script'
      ? '/imgs/creator/contentHelpersImage03.svg'
      : scriptType === 'content'
      ? '/imgs/creator/contentHelpersImage02.svg'
      : scriptType === 'campaign'
      ? ''
      : '';
  return (
    <Stack className="boxcenterhv">
      <Box
        className="boxcenterhv"
        padding="32px"
        bgcolor={'base.white2white'}
        borderRadius={'50%'}
      >
        {src && <img src={src} alt={scriptType} width="248px" />}
      </Box>
    </Stack>
  );
}
