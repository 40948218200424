import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  useDeleteSubscriptionMutation,
  useLazyGetPlanQuery,
  usePostStripePortalMutation,
  usePostStripeSubscribeMutation,
} from '../redux/benaApi';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../redux/benaApiResultHandler';

export function useGetUserStripePlan() {
  const intl = useIntl();
  const [plan, setPlan] = useState<SubscriptionPlanResponseType | null>(null);
  const [getPlan, result] = useLazyGetPlanQuery({});
  useEffect(() => {
    LazyQueryResultHandler<SubscriptionPlanResponseType>({
      intl,
      result: result as QueryResultType<SubscriptionPlanResponseType>,
      onSuccess: (resdata) => {
        setPlan(resdata || null);
      },
    });
  }, [result]);

  const daysDue = plan?.subscription?.days_until_due;
  const daysDueSeconds =
    typeof daysDue === 'number'
      ? Math.floor(new Date().valueOf() / 1000) + daysDue * 24 * 60 * 60
      : null;
  const nextBillDueDate =
    (daysDueSeconds || plan?.subscription?.current_period_end || 0) * 1000;

  const defaultPaymentMethod = plan?.subscription?.default_payment_method;

  return {
    getPlan,
    currentPlanName: plan?.plan,
    nextBillDueDate,
    plan,
    defaultPaymentMethod,
  };
}

export function useSubscribePlan() {
  const intl = useIntl();
  const [sub, result] = usePostStripeSubscribeMutation({});

  const subscribe = async (data: any) => {
    await MutationHandler({
      intl,
      action: async () => {
        return await sub(data).unwrap();
      },
    });
  };

  useEffect(() => {
    if (result.isSuccess) {
      // result.data.data is the redirect url
      window.location.href = result.data.data;
    }
  }, [result]);
  return { subscribe };
}

export function useOpenStripePortal() {
  const intl = useIntl();
  const [open, result] = usePostStripePortalMutation({});

  const openPortal = async (data: { return_path: string; locale: string }) => {
    const { locale } = data;
    const dataConvert = {
      ...data,
      locale: locale === 'zh-hans' ? 'zh' : locale,
    };
    await MutationHandler({
      intl,
      action: async () => {
        return await open(dataConvert).unwrap();
      },
    });
  };
  useEffect(() => {
    if (result.isSuccess) {
      // result.data.data is the redirect url
      window.location.href = result.data.data;
    }
  }, [result]);
  return { openPortal };
}

export function useCancelPlan() {
  const intl = useIntl();
  const [cancel, result] = useDeleteSubscriptionMutation({});
  const cancelPlan = async (data: any) => {
    await MutationHandler({
      intl,
      action: async () => {
        return await cancel(data).unwrap();
      },
    });
  };
  useEffect(() => {
    if (result.isSuccess) {
      window.location.reload();
    }
  }, [result]);
  return { cancelPlan };
}
