import { PasswordOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  capitalize,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  AddIcon,
  CrossIcon,
  LinkArrowIcon,
  User2Icon,
} from '../../assets/imgs/icons';
import {
  staticURLChangeEmail,
  staticURLForgotPassword,
} from '../../route/urls';
import { useGetUserProfile, useIsRoleCreator } from '../../hooks/account';
import { useIntl } from 'react-intl';
import { Messages } from '../../localization/Messages';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { UserAccountMessages } from './Messages';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { FileUploaderSingle } from '../../components/FileUploader';
import { useDispatch } from 'react-redux';
import { setAvatar, selectAuth, setUser } from './AuthSlice';
import { getSelectableSvgIcon } from '../../utils/getPlatformSvgIcon';
import { useTypedSelector } from '../../redux/store';
import DeleteAccount from './DeleteAccount';
import { useGlobalStyles } from '../../assets/styles/style';
import { useEffect, useState } from 'react';
import { usePatchUserMutation } from '../../redux/benaApi';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { showMessage } from '../../components/ShowMessage';
import { TransitionUp } from '../../components/Transitions';

export function UserInfo() {
  const intl = useIntl();
  const theme = useTheme();
  const iconSize = { width: '1.25rem', height: '1.25rem' };
  const userProfile = useGetUserProfile();
  const isMobileView = useBreakpointXs();
  const dispatch = useDispatch();

  const isCreator = useIsRoleCreator();
  const userSvg = '/imgs/user.svg';
  const onFilesUploadSuccess = (res: FileUploadResponseType[]) => {
    dispatch(setAvatar(res[0].file));
  };

  const onSingleFileDeleteSuccess = (id: string) => {
    dispatch(setAvatar(userSvg));
  };

  const divider = (
    <Divider
      sx={{
        borderColor: theme.palette.grey.lightGrey_darkGrey,
        mt: '1.5rem',
        mb: '1rem',
        width: '100%',
      }}
    />
  );

  // TODO update backend to return creator social account info
  // const user = useTypedSelector((state) => selectAuth(state)).user;
  // const creator = user?.creator;
  return (
    <Stack>
      <Typography fontWeight={500} fontSize="1.5rem">
        {intl.formatMessage(Messages.MyProfile)}
      </Typography>
      <Typography color="grey.400">
        {intl.formatMessage(Messages.MyProfileHint)}
      </Typography>
      <Box height="1rem"></Box>
      <Stack
        direction={isMobileView ? 'column' : 'row'}
        padding="1.5rem"
        border={
          isMobileView ? '0px' : '1px solid ' + theme.palette.neutral[600]
        }
        borderRadius="0.5rem"
        rowGap={isMobileView ? '1rem' : '0.5rem'}
        columnGap="2rem"
        className="shadowXSM"
        bgcolor={theme.palette.base.white}
        alignItems={'center'}
      >
        <Box sx={{ width: '9.75rem' }}>
          <FileUploaderSingle
            initImg={userProfile?.avatar || userSvg}
            media={{
              id: '',
              file: userProfile?.avatar || userSvg,
              media_type: '',
            }}
            id={String(userProfile?.id || '')}
            circleShape={false}
            onFilesUploadSuccess={onFilesUploadSuccess}
            onSingleFileDeleteSuccess={onSingleFileDeleteSuccess}
            imgSize={6.75}
            mediaType={'visual'}
            imageRadius="0.5rem"
            sizeLimit={1024 * 1024 * 10}
            openGradientCover={false}
          />
        </Box>

        <Stack
          flexWrap={'wrap'}
          direction="row"
          columnGap="1rem"
          rowGap="1rem"
          justifyContent={'center'}
        >
          <Stack direction="row" alignItems={'center'} spacing="0.25rem">
            {userProfile?.avatar ? (
              <Avatar src={userProfile.avatar} sx={iconSize}></Avatar>
            ) : (
              <User2Icon stroke={theme.palette.grey.black_white} />
            )}
            <Typography fontSize={'1rePatchUserEditButtonm'}>
              {userProfile?.name}
            </Typography>
            <PatchUserEditButton
              fieldName={'name'}
              initText={userProfile?.name || ''}
              title={intl.formatMessage(Messages.EditName)}
            />
          </Stack>
          <Stack direction="row" alignItems={'center'} spacing="2rem">
            <Stack direction="row" alignItems={'center'} spacing="0.25rem">
              <PhoneIcon sx={iconSize} htmlColor={theme.palette.grey[50]} />
              <Typography fontSize={'1rem'}>
                {userProfile?.phone || '-'}
              </Typography>
              <PatchUserEditButton
                fieldName={'phone'}
                initText={userProfile?.phone || ''}
                title={intl.formatMessage(Messages.EditPhoneNumber)}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} alignItems="center" spacing={'0.25rem'}>
            <Typography fontSize={'1rem'}>{userProfile?.email}</Typography>
          </Stack>
        </Stack>
        {/* {!isBrandUser && user && creator && (
          <LinkSocialMedias user={user} creator={creator} />
        )} */}
        <Box flexGrow="1"></Box>
        <Stack
          direction="row"
          flexWrap={'wrap'}
          alignItems={'center'}
          columnGap="1rem"
          justifyContent={isMobileView ? 'center' : 'flex-start'}
        >
          <Link underline="none" href={staticURLForgotPassword}>
            <Typography fontSize={'1rem'}>
              {capitalize(intl.formatMessage(Messages.changePassword))}
              <IconButton>
                <PasswordOutlined
                  sx={iconSize}
                  htmlColor={theme.palette.base.dark2white}
                />
              </IconButton>
            </Typography>
          </Link>
          <Link underline="none" href={staticURLChangeEmail}>
            <Typography fontSize={'1rem'}>
              {capitalize(intl.formatMessage(UserAccountMessages.changeEmail))}
              <IconButton>
                <MarkEmailReadIcon
                  sx={iconSize}
                  htmlColor={theme.palette.base.dark2white}
                />
              </IconButton>
            </Typography>
          </Link>
        </Stack>
      </Stack>
      {isCreator && (
        <>
          {divider}
          <Box
            className="boxcenterhv"
            sx={{
              width: '100%',
              mb: 3,
              flexDirection: { md: 'row', xs: 'column' },
              display: { md: 'none', xs: 'block' },
            }}
          >
            <DeleteAccount />
          </Box>
        </>
      )}
    </Stack>
  );
}

type LinkSocialMediasProps = {
  user: UserResponseType;
  creator?: CreatorData;
  onClick?: fnVoidToVoid;
};
export function LinkSocialMedias({
  user,
  creator,
  onClick,
}: LinkSocialMediasProps) {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <>
      {['tiktok', 'instagram', 'twitter', 'youtube'].map((name) => {
        const value = user && user[`${name}_display_id` as keyof typeof user];
        return (
          <Box
            className="boxlefthcenterv cursorHand"
            key={name}
            sx={{ '&>*': { mr: '0.625rem' }, mb: 2 }}
            onClick={onClick}
          >
            {getSelectableSvgIcon(
              theme,
              name as SocialPlatformId,
              !!value,
              '2rem'
            )}
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontWeight: 600,
                ...(value ? {} : { color: theme.palette.primary.main }),
              }}
            >
              {value ||
                `${intl.formatMessage(
                  UserAccountMessages.linkYour
                )} ${capitalize(name)}`}
            </Typography>
            {value ? (
              <LinkArrowIcon
                stroke={theme.palette.primary.main}
                fill={theme.palette.primary.contrastText}
                sx={{ width: '1rem', height: '1rem' }}
              />
            ) : (
              <AddIcon sx={{ width: '1rem', height: '1rem' }} />
            )}
          </Box>
        );
      })}
    </>
  );
}

type PatchUserEditButtonProps = {
  fieldName: 'name' | 'phone';
  initText: string;
  title?: string;
};

export function PatchUserEditButton({
  fieldName,
  initText,
  title,
}: PatchUserEditButtonProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const isMobileView = useBreakpointXs();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  useEffect(() => {
    setText(initText);
  }, [initText]);

  const [patch] = usePatchUserMutation();
  const isValidForSave = () => {
    return !!text;
  };

  const patchUser = async (newText = '') => {
    if (!fieldName || !isValidForSave()) return;
    setLoading(true);
    await MutationHandler({
      intl,
      action: async () => {
        return await patch({
          [fieldName]: newText || text,
        }).unwrap();
      },
      onSuccess: (resdata) => {
        dispatch(setUser(resdata));
        showMessage.success(intl.formatMessage(Messages.SuccessfullySaved));
        setOpen(false);
      },
    });
    setLoading(false);
  };

  const rows = initText?.length > 60 ? 12 : 4;

  return (
    <>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
        size="small"
        disableRipple
        title={title || ''}
      >
        <Typography fontSize={'14px'} fontWeight="600" color="primary.main">
          {intl.formatMessage(Messages.Edit)}
        </Typography>
      </IconButton>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={TransitionUp}
          sx={{
            zIndex: theme.zIndex.modal,
            '& .MuiDialog-container > .MuiPaper-root': {
              backgroundColor: theme.palette.neutral[0],
              backgroundImage: 'none !important',
            },
            '& .MuiDialog-paper': {
              ...(isMobileView && { marginLeft: '16px', marginRight: '16px' }),
            },
          }}
        >
          <DialogTitle display={'flex'}>
            <Typography fontWeight="600" fontSize="16px" marginTop="8px">
              {intl.formatMessage(Messages.EditData)}
            </Typography>
            <Box flexGrow={1}></Box>
            <IconButton onClick={() => setOpen(false)}>
              <CrossIcon
                stroke={theme.palette.base.black}
                sx={{ width: '10px', height: '10px' }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {['name', 'phone'].includes(fieldName) ? (
              <Stack rowGap={'32px'} minWidth="300px">
                <textarea
                  rows={rows}
                  style={{
                    border: '1px solid ' + theme.palette.neutral[700],
                    fontSize: '14px',
                    fontFamily: 'Outfit',
                    padding: '4px',
                  }}
                  defaultValue={text}
                  autoFocus
                  onChange={(e) => setText(e.target.value)}
                ></textarea>
                <Stack rowGap={'8px'}>
                  <Button
                    className={gs.buttonPrimary}
                    onClick={() => patchUser()}
                    disabled={loading}
                  >
                    {intl.formatMessage(Messages.Confirm)}
                  </Button>
                  <Button
                    className={`border-radius-8px ${gs.buttonCancellation}`}
                    onClick={() => setOpen(false)}
                    disabled={loading}
                  >
                    {intl.formatMessage(Messages.cancel)}
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <></>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
