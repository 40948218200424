import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import PageLayoutWrapper from '../../pages/PageLayoutWrapper';
import { TransitionUp } from '../../components/Transitions';
import { useTheme } from '@mui/material';

type DialogPageProps = {
  children: React.ReactElement;
  initOpen: boolean;
  showHeader?: boolean;
  showMobileViewPadding?: boolean;
};
export default function DialogPage({
  children,
  initOpen,
  showHeader = true,
  showMobileViewPadding = true,
}: DialogPageProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(initOpen);
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    setOpen(!!initOpen);
  }, [initOpen]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
      sx={{
        zIndex: theme.zIndex.modal,
        '& .MuiDialog-container > .MuiPaper-root': {
          backgroundColor: theme.palette.neutral[0],
          backgroundImage: 'none !important',
        },
      }}
      disableEscapeKeyDown
    >
      <PageLayoutWrapper
        showHeader={showHeader}
        showMobileViewPadding={showMobileViewPadding}
      >
        {children}
      </PageLayoutWrapper>
    </Dialog>
  );
}
