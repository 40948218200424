import { Box, Typography, Divider } from '@mui/material';
import GoBack from '../../features/account/GoBack';
import { useIntl } from 'react-intl';
import { UserAccountMessages } from './Messages';

export default function PrivacyPolicy() {
  const intl = useIntl();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
      }}
    >
      <GoBack />
      <Typography sx={{ mt: 2, mb: 1, fontSize: '1.5rem' }}>
        {intl.formatMessage(UserAccountMessages.BenaPrivacyPolicy)}
      </Typography>
      <Divider sx={{ width: '100%' }} />
      <Box
        sx={{
          p: 1,
          pl: 0,
          mt: 2,
          flexGrow: 1,
        }}
      >
        <h3 className="block__title">{`(Last updated On March 30, 2024)`}</h3>
        <div />

        <p className="block__text">{`Bena and its affiliated companies are committed to respecting your privacy in compliance with all applicable laws and regulations. This Privacy Policy describes how your personal information is collected, used, and shared when you visit https://www.bena.social (and/or any other sites operated by our group of companies, referred to as the “Sites”) or where we otherwise process your data.`}</p>

        <div />
        <h3 className="block__title">WEBSITE USERS</h3>
        <p className="block__text">{`When you use the Sites, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Sites, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Sites, and information about how you interact with the Sites. We refer to this automatically collected information as “Device Information.”`}</p>
        <p className="block__text">{`We collect Device Information using the following technologies:`}</p>
        <p className="block__text">{` – "Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier.`}</p>
        <p className="block__text">{` – "Log files" track actions occurring on the Sites, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.`}</p>
        <p className="block__text">{` – "Web beacons", "tags", and "pixels" are electronic files used to record information about how you browse the Sites.`}</p>

        <div />
        <h3 className="block__title">
          HOW DO WE USE YOUR PERSONAL INFORMATION?
        </h3>
        <p className="block__text">{`To communicate with you; to respond to your questions or inquiries and, in line with the preferences you have shared with us, provide you with information relating to our platforms or services.`}</p>
        <p className="block__text">{`We use the Device Information that we collect to help us improve and optimize our Sites (for example, by generating analytics about how our users browse and interact with the Sites, and to assess the success of our marketing and advertising campaigns).`}</p>
        <p className="block__text">{`Cookies also enable us to recognize you as a returning visitor and optimize your browsing experience, e.g. by remembering your settings or information entered into forms, or returning you to pages you previously visited.`}</p>
        <p className="block__text">{`If you decline or delete cookies, our Sites will still function, however your browsing experience may be less optimized.`}</p>
        <p className="block__text">{`All data collected on our public-facing Sites will never be used to identify individual users or to match it with further data on an individual user (this does not apply to logged-in users within our non-public portals).`}</p>

        <div />
        <h3 className="block__title">SHARING YOUR PERSONAL INFORMATION</h3>
        <p className="block__text">{`We may share your Personal Information with third parties to help us process it and communicate with you, as further described below. For example, we use Google Analytics to help us understand how our users use the Sites — you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/`}</p>

        <div />
        <h3 className="block__title">DO NOT TRACK</h3>
        <p className="block__text">{`Please note that we do not alter our Sites’ data collection and use practices when we see a Do Not Track signal from your browser.`}</p>

        <div />
        <h3 className="block__title">DATA SHARING</h3>
        <p className="block__text">{`We may provide data to other companies in our corporate group, technical service providers (for purposes such as customer support, analytics, email delivery, or database management), professional advisers (such as payment processors and security, insurance, legal, and accounting service providers) inside and outside the European Union (with Iceland, Liechtenstein and Norway, the “EEA”), Switzerland and the UK, that require access to data in order to assist us in providing our services. In particular, we work with reputable external data processors in order to provide our platforms and services, including for the purposes of storage, analysis, development, testing etc., and who are not permitted to use any data processed on our behalf for any other purpose.`}</p>
        <p className="block__text">{`In each case we have in place safeguards that enable transfers from the EEA, Switzerland or the UK to happen in a way that ensures that data is handled in accordance with similar standards to those applicable in those territories. These include: a decision from the relevant authorities indicating that the place of receipt has adequate data protection laws; and standard contract clauses approved by the relevant authorities for other international transfers. A copy of these safeguards may be made available if we receive a valid request.`}</p>
        <p className="block__text">{`The data contained in our platforms is made available to our customers, who may also process it in accordance with their own privacy policies and practices.`}</p>
        <p className="block__text">{`Where data (which may include Personal Data) is provided to us by one of our customers we enable such customer’s authorized users to access, update and process the data provided by that customer.`}</p>
        <p className="block__text">{`In connection with a business transaction (or potential business transaction) such as an investment, pestiture, acquisition, merger, consolidation, reorganization or sale of assets, or in the event of bankruptcy or dissolution, we may transfer or otherwise share some or all of our business or assets, which may include Personal Data, with the relevant investor or acquirer, their advisers, and where applicable any relevant regulatory authorities.`}</p>
        <p className="block__text">{`Finally, if any authority, police force or regulator validly requested data from us, then we would normally comply with such request without having to notify you.`}</p>

        <div />
        <h3 className="block__title">DATA RETENTION PERIODS</h3>
        <p className="block__text">{`We retain the data only as long as necessary for the purposes described in this Policy. When we no longer need to use the data for such purposes (or for us to comply with our legal or regulatory obligations), then we will either remove it from our systems or delete all personally identifying elements so that the data cannot be traced back to you or any other identifiable person.`}</p>

        <div />
        <h3 className="block__title">YOUR RIGHTS</h3>
        <p className="block__text">{`Individuals located in EEA, Switzerland or the UK have various legalrights in relation to their data including:`}</p>
        <p className="block__text">{` - To ask for access to it;`}</p>
        <p className="block__text">{` - To ask for errors in it to be corrected;`}</p>
        <p className="block__text">{` - To ask for it to be erased (the “right to be forgotten”);`}</p>
        <p className="block__text">{` - If not erased, then to ask us to restrict its processing;`}</p>
        <p className="block__text">{` - To object to its processing for specific purposes;`}</p>
        <p className="block__text">{` - To ask for a copy to take to another service provider.`}</p>
        <div />
        <p className="block__text">{`California consumers also have legal rights in relation to their Personal Data including:`}</p>
        <p className="block__text">{` - To know what Personal Data has been collected about you;`}</p>
        <p className="block__text">{` - To ask to have it deleted;`}</p>
        <p className="block__text">{` - To opt out of sales of that Personal Data (please see the next Section);`}</p>
        <p className="block__text">{` - Not to be discriminated against for exercising any of your rights under California privacy legislation (for example we would not deny you service, charge you a different price, or provide you a different quality of service fordoing so).`}</p>
        <p className="block__text">{`Where we process any data based on consent that you have given in the past, then you are always allowed to withdraw your consent at any time (though the processing that took place before withdrawal will still be legal).`}</p>
        <p className="block__text">{`Please note that in some circumstances we may not be able to fully comply with a request, e.g. where are required to retain data in order to comply with legal, accounting, tax, or record-keeping obligations.`}</p>
        <p className="block__text">{`Where you make a request, please understand that we may need to take reasonable steps to verify your identity or authority to make the request, and confirm the personal information relates to you.`}</p>
        <p className="block__text">{`We are always available through the contacts at the end of this Policy to help resolve any issues or doubts you may have in relation to processing of your Personal Data. If you believe that your rights have not been respected at any time then you also have a right to complain to the Data Protection Supervisory Authority in your country to ask them for a resolution.`}</p>

        <div />
        <h3 className="block__title">MINORS</h3>
        <p className="block__text">{`The Sites and our platforms and services are not intended for children and we do not intend to process children’s data. If we learn that we have collected a child’s personal information without the consent of the child’s parent or guardian, we will delete it. Please always contact us with any concerns.`}</p>

        <div />
        <h3 className="block__title">CHANGES</h3>
        <p className="block__text">{`We may update this privacy policy from time to time in order to reflect, for example, changes to our business or technology or for other operational, legal or regulatory reasons. We recommend checking back to this page for the most current information.`}</p>

        <div />
        <h3 className="block__title">CONTACT US</h3>
        <p className="block__text">{`For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@bena.live`}</p>
        <div />
      </Box>
      <Box sx={{ minHeight: '35%', flexGrow: 1 }}></Box>
    </Box>
  );
}
