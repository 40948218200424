import { useIntl } from 'react-intl';
import { showMessage } from '../components/ShowMessage';
import { Messages } from '../localization/Messages';

export const useClipboard = () => {
  const intl = useIntl();
  const copy = (str: string) => {
    navigator.clipboard.writeText(str);
    showMessage.success(intl.formatMessage(Messages.copied));
  };
  return { copy };
};
