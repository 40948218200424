import { defineMessages } from 'react-intl';
export const CreatorMessages = defineMessages({
  ContentHelper: { id: 'ContentHelper', defaultMessage: 'Content Helpers' },
  WritingEmailsHelper: {
    id: 'WritingEmailsHelper',
    defaultMessage: 'Writing Emails Helper',
  },
  WritingContentBriefHelper: {
    id: 'WritingContentBriefHelper',
    defaultMessage: 'Writing Content Brief Helper',
  },
  WritingScriptHelper: {
    id: 'WritingScriptHelper',
    defaultMessage: 'Writing Script Helper',
  },
  CampaignApplicationHelper: {
    id: 'CampaignApplicationHelper',
    defaultMessage: 'Campaign Application Helper',
  },
  TryQuestions: { id: 'TryQuestions', defaultMessage: 'Try questions:' },
  TryItOut: { id: 'TryItOut', defaultMessage: 'Try it out!' },
  Opportunities: { id: 'Opportunities', defaultMessage: 'Opportunities' },
  LaunchedOn: { id: 'LaunchedOn', defaultMessage: 'Launched On:' },
  ApplyToCampaign: {
    id: 'ApplyToCampaign',
    defaultMessage: 'Apply campaign',
  },
  LoginToApplyToCampaign: {
    id: 'LoginToApplyToCampaign',
    defaultMessage: 'Login to Apply',
  },
  WithdrawApplication: {
    id: 'WithdrawApplication',
    defaultMessage: 'Withdraw',
  },
  ViewApplication: {
    id: 'ViewApplication',
    defaultMessage: 'View Application',
  },
  VerifyTiktok: {
    id: 'VerifyTiktok',
    defaultMessage: 'Verify TikTok',
  },
  LinkTiktokToApply: {
    id: 'LinkTiktokToApply',
    defaultMessage: 'Link TikTok to Apply',
  },
  AreYouSureToWithdrawApplication: {
    id: 'AreYouSureToWithdrawApplication',
    defaultMessage: 'Are you sure to withdraw the application?',
  },
  ViewDetails: { id: 'ViewDetails', defaultMessage: 'View details' },
  GoBack: { id: 'GoBack', defaultMessage: 'Go back' },
  uploadTo: { id: 'uploadTo', defaultMessage: 'Upload to' },
  uploadSuccess: { id: 'uploadSuccess', defaultMessage: 'Upload success' },
  xlsxFileTips: { id: 'xlsxFileTips', defaultMessage: 'Please upload the creator table to be added, in the format: xlsx/xls' },
  xlsxErrorTips: { id: 'xlsxErrorTips', defaultMessage: 'Please upload a valid file format(.xls .xlsx)' },
  downLoadTemplate: { id: 'downLoadTemplate', defaultMessage: 'Download template' },
  BrandIntro: { id: 'BrandIntro', defaultMessage: 'Brand Intro' },
  BrandProductPage: {
    id: 'BrandProductPage',
    defaultMessage: 'Brand/Product Page',
  },
  BudgetFor: {
    id: 'BudgetFor',
    defaultMessage: 'Budget or Product Exchange Only',
  },
  BudgetColon: { id: 'BudgetColon', defaultMessage: 'Budget:' },
  CampaignPeriod: { id: 'CampaignPeriod', defaultMessage: 'Campaign Period' },
  FreeProduct: { id: 'FreeProduct', defaultMessage: 'Free product' },
  CreatorRequirement: {
    id: 'CreatorRequirement',
    defaultMessage: 'Creator Requirements',
  },
  ContentDirection: {
    id: 'ContentDirection',
    defaultMessage: 'Content Directions',
  },
  Caption: {
    id: 'Caption',
    defaultMessage: 'Caption',
  },
  VideoDurations: {
    id: 'VideoDurations',
    defaultMessage: 'Video Durations',
  },
  Hashtags: {
    id: 'Hashtags',
    defaultMessage: 'Hashtags',
  },
  OtherAccounts: {
    id: 'OtherAccounts',
    defaultMessage: 'Mention other Accounts in the video caption?',
  },
  Files: {
    id: 'Files',
    defaultMessage: 'Files',
  },
  postDisplayDurationLabel: {
    id: 'postDisplayDurationLabel',
    defaultMessage: 'Can the brand keep the content on your social account?',
  },
  promotionInBioLabel: {
    id: 'promotionInBioLabel',
    defaultMessage: 'Are you okay with including the promotion in your bio?',
  },
  rightUsageLabel: {
    id: 'rightUsageLabel',
    defaultMessage: 'Does the brand have usage rights?',
  },
  optionForever: {
    id: 'optionForever',
    defaultMessage: 'Forever',
  },
  optionOneWeek: {
    id: 'optionOneWeek',
    defaultMessage: '1 Week',
  },
  optionTwoWeeks: {
    id: 'optionTwoWeeks',
    defaultMessage: '2 Weeks',
  },
  optionOneMonth: {
    id: 'optionOneMonth',
    defaultMessage: '1 Month',
  },
  optionThreeMonths: {
    id: 'optionThreeMonths',
    defaultMessage: '3 Months',
  },
  optionSixMonths: {
    id: 'optionSixMonths',
    defaultMessage: '6 Months',
  },
  optionOneYear: {
    id: 'optionOneYear',
    defaultMessage: '1 Year',
  },
  Agree: {
    id: 'Agree',
    defaultMessage: 'Agree',
  },
  NotAgree: {
    id: 'NotAgree',
    defaultMessage: 'Not agree',
  },
  NoCampaignFound: {
    id: 'NoCampaignFound',
    defaultMessage: 'No Campaign Found',
  },
  DeleteCampaign: {
    id: 'DeleteCampaign',
    defaultMessage: 'Delete campaign',
  },
  CreateCampaign: {
    id: 'CreateCampaign',
    defaultMessage: 'Create campaign',
  },
  PrevCampaign: {
    id: 'PrevCampaign',
    defaultMessage: 'Previous campaign',
  },
  NextCampaign: {
    id: 'NextCampaign',
    defaultMessage: 'Next campaign',
  },
  TalkingToNCreators: {
    id: 'TalkingToNCreators',
    defaultMessage: 'Talking to [{n}] Creators',
  },
  EmailOutreachStats: { id: 'EmailOutreachStats', defaultMessage: 'Email Outreach Stats' },
  GotoMessage: {
    id: 'GotoMessage',
    defaultMessage: 'Go to Message',
  },
  ContentIdea: {
    id: 'ContentIdea',
    defaultMessage: 'Content Idea',
  },
  MediaKit: {
    id: 'MediaKit',
    defaultMessage: 'Media Kit',
  },
  MessagesToBrand: {
    id: 'MessagesToBrand',
    defaultMessage: 'Messages to brand',
  },
  Followers: {
    id: 'Followers',
    defaultMessage: 'Followers',
  },
  Engagement: {
    id: 'Engagement',
    defaultMessage: 'Engagement',
  },
  Price: {
    id: 'Price',
    defaultMessage: 'Price',
  },
  DateToDeliver: {
    id: 'DateToDeliver',
    defaultMessage: 'Date to Deliver',
  },
  HowLongToKeep: {
    id: 'HowLongToKeep',
    defaultMessage: 'How long to keep the link',
  },
  CanBrandHas: {
    id: 'CanBrandHas',
    defaultMessage: 'Can Brand has the rights to reuse content?',
  },
  PriceIs: {
    id: 'PriceIs',
    defaultMessage: 'my price is ',
  },
  EnterPriceHint: {
    id: 'EnterPriceHint',
    defaultMessage: '(Enter the price below and press enter to submit)',
  },
  AreYouSureToSubmitApplication: {
    id: 'AreYouSureToSubmitApplication',
    defaultMessage: 'Are you sure to submit the application?',
  },
  PickYourDesiredContentStyle: {
    id: 'PickYourDesiredContentStyle',
    defaultMessage: 'Pick your desired content style',
  },
  YouHaveAppliedThisCampaign: {
    id: 'YouHaveAppliedThisCampaign',
    defaultMessage:
      'You already have an application for this campaign. Check Jobs page to see more details.',
  },
});
