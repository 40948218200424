import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { benaApi } from './benaApi';
import authReducer from '../features/account/AuthSlice';
import LanguageReducer from '../features/language/LanguageSlice';
import dynamicSearchReducer from '../features/search/dynamicSearchSlice';
import MessagingSlice from '../features/rocketchat/MessagesSlice';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AIGCReducer from '../features/aigc/AIGCSlice';
import ShortListSlice from '../features/creators/shortListSlice';
import AdminSlice from '../features/admin/AdminSlice';
import { benaAdminApi } from './benaAdminApi';
import CampaignSlice from '../creator/campaign/CampaignSlice';

const rootReducer = combineReducers({
  [benaApi.reducerPath]: benaApi.reducer,
  [benaAdminApi.reducerPath]: benaAdminApi.reducer,
  Language: LanguageReducer,
  auth: authReducer,
  dynamicSearch: dynamicSearchReducer,
  aigc: AIGCReducer,
  shortList: ShortListSlice,
  admin: AdminSlice,
  messaging: MessagingSlice,
  campaign: CampaignSlice,
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 1,
    storage: storage,
    blacklist: [
      'dynamicSearch',
      'aigc',
      'admin',
      'messaging',
      benaApi.reducerPath,
      benaAdminApi.reducerPath,
    ],
  },
  rootReducer
);

const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      benaApi.middleware,
      benaAdminApi.middleware,
      createStateSyncMiddleware({
        predicate: (action) => {
          return [
            'Auth/setLogin',
            'Auth/logout',
            'Auth/setAccessToken',
            'Auth/setAvatar',
            'Auth/setBrandLogo',
            'Auth/setUser',
            'Auth/setCreator',
            'Auth/setBrand',
            'Auth/setThemeMode',
          ].includes(action.type);
        },
      }),
    ]),
});

initMessageListener(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const persistor = persistStore(store);
export { store };
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
