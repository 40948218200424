import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { TransitionUp } from '../../components/Transitions';
import { ArrowRightIcon, CrossIcon } from '../../assets/imgs/icons';
import { useIntl } from 'react-intl';
import { Messages } from '../../localization/Messages';
import { useGlobalStyles } from '../../assets/styles/style';
import { CreatorBasicInfoCard } from '../../creator/pages/BrandCampaignDetails';
import { useGetCampaigns } from '../../creator/hooks/campaign';
import moment from 'moment';
import BenaCheckbox from '../../components/BenaCheckbox';
import { useEffect, useMemo, useState } from 'react';
import Loading from '../../components/Loading';
import { usePostCampaignInvitationMutation } from '../../redux/benaApi';
import { MutationHandler, NOOP } from '../../redux/benaApiResultHandler';
import { staticURLNewCampaign } from '../../route/urls';
import { useGetCreatorInvitations } from '../../hooks/creators';

type ModalAddToCampaignsProps = {
  onClose: fnVoidToVoid;
  open: boolean;
  creator: CreatorData;
};

export default function ModalAddToCampaigns({
  onClose,
  open,
  creator,
}: ModalAddToCampaignsProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const header = (
    <DialogTitle display={'flex'}>
      <Typography fontWeight="600" fontSize="16px" marginTop="8px">
        {intl.formatMessage(Messages.AddCreatorToCampaign)}
      </Typography>
      <Box flexGrow={1}></Box>
      <IconButton onClick={onClose}>
        <CrossIcon
          stroke={theme.palette.base.black}
          sx={{ width: '10px', height: '10px' }}
        />
      </IconButton>
    </DialogTitle>
  );
  const {
    campaigns,
    loading,
    refetch: refetchCampaigns,
  } = useGetCampaigns(creator.id);
  const [checkStatus, setCheckStatus] = useState<
    { id: string; checked: boolean }[]
  >([]);

  // get creator's invitations and applications
  const { invitations, refetch: refetchInvitations } = useGetCreatorInvitations(
    creator.id
  );

  const invitedNumber = checkStatus.filter((w) => w.checked).length;

  useEffect(() => {
    if (campaigns.length > 0) {
      setCheckStatus(campaigns.map((w) => ({ id: w.id, checked: false })));
    }
  }, [campaigns]);

  const handleChange = (id: string, checked: boolean) => {
    setCheckStatus((state) => {
      const s = [...state];
      const index = s.findIndex((w) => w.id === id);
      if (index >= 0) {
        s[index].checked = !checked;
      }
      return [...s];
    });
  };

  const [invitationResults, setInvitationResults] = useState<{
    [key: string]: boolean;
  }>({});
  const [inviteToCampaign, result] = usePostCampaignInvitationMutation({});
  const addCreatorToCampaigns = async () => {
    let ret = false;
    for (let i = 0; i < checkStatus.length; i++) {
      const { checked, id } = checkStatus[i];
      if (checked) {
        ret = await MutationHandler({
          intl,
          action: async () => {
            return await inviteToCampaign({
              creator_id: creator.id,
              campaign_id: id || '',
              status: 'invited',
            }).unwrap();
          },
          onSuccess: () => {
            setInvitationResults((obj) => ({ ...obj, [id]: true }));
          },
        });
      }
    }
    await refetchCampaigns();
    await refetchInvitations();

    return !ret;
  };

  const disabled = invitedNumber <= 0 || result.isLoading;
  const hasNoCampaign = campaigns.length <= 0;

  const campaignsList = useMemo(
    () =>
      campaigns
        .filter((w) => w.status === 'active')
        .map((campaign) => {
          const { title, start_date, id } = campaign;
          const checked =
            checkStatus.find((w) => w.id === id)?.checked || false;
          const invitedCampaign = invitations?.find(
            (w) => w.campaign_id === campaign.id
          );
          const handledApplication = [
            'applied',
            'accepted',
            'finished',
          ].includes(invitedCampaign?.application?.status || '');
          const invitable = !invitedCampaign?.invitation && !handledApplication;
          const status = handledApplication
            ? `application ${invitedCampaign?.application?.status}`
            : `invitation ${invitedCampaign?.invitation?.status}`;
          return (
            <Stack
              direction="row"
              sx={{
                borderRadius: '8px',
                border: '2px solid ' + theme.palette.neutral[600],
                padding: '16px',
                '&:hover p': {
                  color: theme.palette.primary.main,
                },
                '&:hover': {
                  border: '2px solid ' + theme.palette.primary.main,
                },
              }}
              columnGap="8px"
              alignSelf={'stretch'}
              alignItems={'flex-start'}
              key={campaign.id}
              onClick={() => invitable && handleChange(id, checked)}
            >
              <Stack>
                <Typography
                  fontSize={'16px'}
                  fontWeight="500"
                  color="grey.700"
                  className="ellipsis"
                  maxWidth="400px"
                >
                  {title}
                </Typography>
                <Typography fontSize={'14px'} fontWeight="400" color="grey.50">
                  {intl.formatMessage(Messages.StartedOn)}{' '}
                  {start_date && moment(start_date).format(`M/DD/YY`)}
                </Typography>
              </Stack>
              <Box flexGrow={1}>
                {invitable ? (
                  <></>
                ) : (
                  <Typography
                    color="success.600"
                    fontSize="16px"
                    fontWeight="500"
                  >
                    {status}
                  </Typography>
                )}
              </Box>
              <BenaCheckbox
                checked={checked}
                onChange={(e) => {
                  e.stopPropagation();
                  handleChange(id, checked);
                }}
                disabled={!invitable}
              />
              <Typography color="success.600" marginTop="6px">
                {invitationResults[campaign.id] ? 'Invited' : ''}
              </Typography>
            </Stack>
          );
        }),
    [
      campaigns,
      checkStatus,
      intl,
      invitationResults,
      invitations,
      theme.palette.neutral,
      theme.palette.primary.main,
    ]
  );

  const content = (
    <DialogContent>
      <Divider
        sx={{
          borderColor: theme.palette.neutral[600],
          marginTop: '8px',
          marginBottom: '24px',
        }}
      />
      <CreatorBasicInfoCard
        key={''}
        id={creator.id}
        viewCreator={() => {
          return;
        }}
        image={creator.picture}
        username={creator.unique_id}
        followers={creator.profile?.followers || 0}
        userId={creator.user_id || ''}
        displayName={creator.name || ''}
        fullWidth={true}
        imgMaxWidth="96px"
        hoverable={false}
      />
      <Divider
        sx={{
          borderColor: theme.palette.neutral[600],
          marginTop: '24px',
        }}
      />
      <Stack
        rowGap="10px"
        maxHeight="300px"
        sx={{ overflowY: 'auto' }}
        alignItems={'center'}
        marginTop="24px"
      >
        {loading && <Loading />}
        {!loading && campaignsList}
      </Stack>
      <Divider
        sx={{
          borderColor: theme.palette.neutral[600],
          marginTop: '24px',
        }}
      />
    </DialogContent>
  );

  const noCampaigns = hasNoCampaign && !loading && (
    <Stack
      className="boxcenterhv"
      width="100%"
      borderRadius={'10px'}
      marginTop="54px"
      marginBottom="54px"
      rowGap="18px"
    >
      <img
        src="/imgs/no-campaign-found.svg"
        alt="no campaign"
        width="276px"
        height="258px"
      />
      <Typography
        fontSize={'18px'}
        fontWeight="500"
        color={theme.palette.grey[700]}
      >
        {intl.formatMessage(Messages.SeemsLikeYouDont)}
      </Typography>
    </Stack>
  );

  return (
    <Dialog
      fullScreen={false}
      open={open}
      TransitionComponent={TransitionUp}
      sx={{
        zIndex: theme.zIndex.modal,
        '& .MuiDialog-container > .MuiPaper-root': {
          backgroundColor: theme.palette.neutral[0],
          backgroundImage: 'none !important',
        },
      }}
      disableEscapeKeyDown
    >
      {header}
      {content}
      {noCampaigns}
      <DialogActions sx={{ padding: '2px 24px 24px 24px' }}>
        <Button
          onClick={onClose}
          className={gs.buttonOutlinedGrey}
          sx={{ height: '40px', minWidth: '248px' }}
          variant="outlined"
        >
          {intl.formatMessage(Messages.cancel)}
        </Button>
        <Box flexGrow={1} minWidth="16px"></Box>
        {hasNoCampaign && (
          <Link
            href={staticURLNewCampaign}
            sx={{ marginRight: '16px' }}
            className="step_create_new_campaign"
            target="_blank"
          >
            <Button
              onClick={addCreatorToCampaigns}
              className={gs.buttonPrimary}
              sx={{ height: '40px', minWidth: '248px' }}
              variant="contained"
            >
              {intl.formatMessage(Messages.GoAndCreateIt)}
              <ArrowRightIcon
                sx={{ marginLeft: '8px', width: '19px', height: '19px' }}
                stroke={theme.palette.base.white}
              />
            </Button>
          </Link>
        )}
        {!hasNoCampaign && (
          <Button
            onClick={addCreatorToCampaigns}
            className={gs.buttonDeletion}
            sx={{ height: '40px', minWidth: '248px' }}
            disabled={disabled}
          >
            {intl.formatMessage(Messages.InviteNToCampaign, {
              number: invitedNumber,
              s: invitedNumber > 1 ? 's' : '',
            })}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
