import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../../redux/store';

export type MessagingSliceType = {
  showNavigationBar: boolean;
  unreadMessagesCount: number;
};

const initialState: MessagingSliceType = {
  showNavigationBar: true,
  unreadMessagesCount: 0,
};

const slice = createSlice({
  name: 'Messaging',
  initialState,
  reducers: {
    setShowNavigationBar(
      state: MessagingSliceType,
      action: PayloadAction<boolean>
    ) {
      state.showNavigationBar = action.payload;
    },
    setUnreadMessagesCount(
      state: MessagingSliceType,
      action: PayloadAction<number>
    ) {
      state.unreadMessagesCount = action.payload;
    },
  },
});

export const { setShowNavigationBar, setUnreadMessagesCount } = slice.actions;

export const selectMessaging = createSelector(
  [(state: RootState) => state.messaging],
  (state) => state
);

export default slice.reducer;
