import { useLazyGetJobContractQuery } from '../redux/benaApi';
import { useEffect } from 'react';

export function useGetJobContract(contractId: string) {
  const [_fetch, result] = useLazyGetJobContractQuery();
  const refetch = (id: string) => {
    id && _fetch({ contract_id: id });
  };
  useEffect(() => {
    refetch(contractId);
  }, []);
  return {
    refetch,
    data: result.data?.data,
    loading: result.isFetching || result.isLoading,
  };
}
