import { Box, IconButton, useTheme } from '@mui/material';
import { useGlobalStyles } from '../assets/styles/style';
import closeSvg from '../assets/imgs/close.svg';

type BenaModalProps = {
  title: React.ReactNode | string;
  handleClose: fnVoidToVoid;
  children: React.ReactNode;
  actionButton: React.ReactNode;
  width: string;
  showCloseIcon?: boolean;
  showTitleBar?: boolean;
  zIndex?: number;
};
export function BenaModal({
  title,
  handleClose,
  children,
  actionButton,
  width,
  showCloseIcon = true,
  showTitleBar = true,
  zIndex = 0,
}: BenaModalProps) {
  const theme = useTheme();
  const gc = useGlobalStyles();
  return (
    <Box
      sx={{ zIndex: theme.zIndex.modal + (zIndex || 0) }}
      className={`boxcenterhv ${gc.screenCover}`}
    >
      <Box
        sx={{
          width,
          minHeight: showTitleBar ? '20rem' : '10rem',
          bgcolor: theme.palette.base.white,
          maxHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
        className="border-radius-8px"
      >
        {showTitleBar && (
          <Box
            sx={{
              width: '100%',
              my: '1.25rem',
              pb: '0.5rem',
              px: '1.625rem',
              borderBottom: `0.0625rem solid ${theme.custom.primary5}`,
            }}
            className="boxcenterhv"
          >
            <Box fontSize="18px" fontWeight="600" color="base.black">
              {title}
            </Box>
            <Box sx={{ flexGrow: 1 }}></Box>
            {showCloseIcon && (
              <IconButton onClick={() => handleClose()} disableRipple>
                <img
                  src={closeSvg}
                  alt=""
                  style={{
                    width: '1rem',
                    height: '1rem',
                    margin: 0,
                    padding: 0,
                    float: 'right',
                  }}
                  className="cursorHand"
                />
              </IconButton>
            )}
          </Box>
        )}
        <Box
          sx={{
            p: '2rem',
            pt: 0,
            alignItems: 'flex-start',
          }}
          className="boxlefthcenterv border-radius-6px"
        >
          {children}
        </Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box
          sx={{
            px: '1.625rem',
            width: '100%',
            borderTop: `0.0625rem solid ${theme.custom.primary5}`,
          }}
          className="boxcenterhv"
        >
          {actionButton}
        </Box>
      </Box>
    </Box>
  );
}
