import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { TransitionUp } from './Transitions';
import { useIntl } from 'react-intl';
import { Messages } from '../localization/Messages';
import { useGlobalStyles } from '../assets/styles/style';
import { CrossIcon } from '../assets/imgs/icons';
import { useBreakpointXs } from '../utils/useStyleHooks';

type ConfirmProps = {
  title?: string;
  text: string;
  handleConfirm: fnVoidToVoid;
  open: boolean;
  setOpen: fnBooleanToVoid;
  minWidth?: string;
};
export default function Confirm({
  title,
  text,
  handleConfirm,
  open,
  setOpen,
  minWidth = '367px',
}: ConfirmProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const handleClose = () => {
    setOpen(false);
  };
  const _title = title || intl.formatMessage(Messages.Confirm);
  const isMobileView = useBreakpointXs();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
      sx={{ zIndex: theme.zIndex.modal }}
      maxWidth={'lg'}
    >
      <DialogTitle display={'flex'}>
        <Typography fontWeight="600" fontSize="16px" marginTop="8px">
          {_title}
        </Typography>
        <Box flexGrow={1}></Box>
        <IconButton onClick={() => setOpen(false)}>
          <CrossIcon
            stroke={theme.palette.base.black}
            sx={{ width: '10px', height: '10px' }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider
          sx={{
            borderColor: theme.palette.neutral[600],
            marginTop: '8px',
            marginBottom: '24px',
          }}
        />
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{
            minWidth: isMobileView ? '100%' : minWidth,
            color: theme.palette.grey[50],
          }}
        >
          {text}
        </DialogContentText>
        <Divider
          sx={{
            borderColor: theme.palette.neutral[600],
            marginTop: '24px',
          }}
        />
      </DialogContent>
      <DialogActions sx={{ padding: '2px 24px 24px 24px' }}>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          className={gs.buttonOutlinedGrey}
          sx={{ width: '151px', height: '40px' }}
          variant="outlined"
        >
          {intl.formatMessage(Messages.cancel)}
        </Button>
        <Box flexGrow={1}></Box>
        <Button
          onClick={() => {
            setOpen(false);
            handleConfirm();
          }}
          className={gs.buttonDeletion}
          sx={{ width: '151px', height: '40px' }}
        >
          {intl.formatMessage(Messages.Confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
