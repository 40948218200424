import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled, Theme, useTheme } from '@mui/material/styles';
import LightTooltip from './LightTooltip';

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      flexGrow={value !== index ? 0 : 1}
      display={'flex'}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box flexGrow={1}>{children}</Box>}
    </Box>
  );
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  [key: string]: any;
}

const genCSS = (theme: Theme, containerJustifyContent: string) => ({
  '& .MuiTabs-flexContainer': {
    justifyContent: containerJustifyContent,
    display: 'flex',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary[600],
    height: '0.125rem',
    borderRadius: '0.125rem 0.125rem 0.125rem 0.125rem',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '2.5rem',
    width: '100%',
    backgroundColor: theme.palette.primary[600],
  },
});

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => genCSS(theme, 'flex-start'));

const StyledTabsSpaceBetween = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => genCSS(theme, 'space-between'));

const StyledTabsCenter = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => genCSS(theme, 'center'));

interface StyledTabProps {
  label: string | React.ReactNode;
  first: boolean;
  last: boolean;
  space?: number;
  spaceBetween: boolean;
}
const StyledTab = styled(
  ({
    first,
    last,
    space = 1,
    spaceBetween = false,
    ...props
  }: StyledTabProps) => (
    <Tab
      disableRipple
      {...props}
      sx={(theme) => ({
        py: Math.min(0.25 * space, 0.5),
        px: 0,
        mr: spaceBetween && last ? 0 : 0.5 * space,
        minWidth: '1rem',
      })}
    />
  )
)(({ theme }) => ({
  textTransform: 'none',
  '&': {
    color: theme.palette.base[100],
    fontWeight: 500,
  },
  '&.Mui-selected': {
    fontWeight: 600,
    color: theme.palette.primary[600],
  },
}));

function a11yProps(index: number) {
  return { id: `tab-${index}` };
}

export default function STabs({
  tabs,
  selectedIndex,
  spaceBetween = false,
  onTabChange = () => {
    return;
  },
  tabTitleFontSize,
  tabContentMarginTop = '1rem',
  tooltipOnQuestionmark = false,
  tabTitlePaddingBottom = '0rem',
  tabTitlePaddingTop = '0rem',
  sticky = false,
  stickyTop = '0rem',
  stickyPaddingTop = '0rem',
  stickyBackgroundColor = '',
  justifyContentCenter = false,
}: STabsProps) {
  const theme = useTheme();
  const [value, setValue] = React.useState(selectedIndex || 0);
  React.useEffect(() => {
    setValue(selectedIndex || 0);
  }, [selectedIndex]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onTabChange && onTabChange(newValue);
  };
  
  const renderTabs = tabs.map((tab, index) => (
    <StyledTab
      space={spaceBetween ? 1 : 6}
      spaceBetween={spaceBetween}
      first={index === 0}
      last={index === tabs.length - 1}
      key={index}
      sx={{ position: 'relative' }}
      label={
        tooltipOnQuestionmark ? (
          <Box
            className="boxcenterhv"
            sx={{
              fontSize: tabTitleFontSize || '0.9375rem',
              mb: spaceBetween ? '0rem' : '0.375rem',
              position: 'relative',
              pb: tabTitlePaddingBottom,
              pt: tabTitlePaddingTop,
            }}
          >
            {tab.nameElement || tab.name}
          </Box>
        ) : (
          <LightTooltip
            title={tab.tooltip?.htmlTooltip || tab.tooltip?.content || ''}
            placement="top"
          >
            <Box
              className="boxcenterhv"
              sx={{
                fontSize: tabTitleFontSize || '0.9375rem',
                mb: spaceBetween ? '0rem' : '0.375rem',
                pb: tabTitlePaddingBottom,
                pt: tabTitlePaddingTop,
              }}
            >
              {tab.nameElement || tab.name}
            </Box>
          </LightTooltip>
        )
      }
      {...a11yProps(index)}
    />
  ));

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.neutral[50],
          mb: tabContentMarginTop || '1rem',
          ...(sticky
            ? {
                position: 'sticky',
                top: stickyTop,
                paddingTop: stickyPaddingTop,
                backgroundColor:
                  stickyBackgroundColor || theme.palette.neutral[25],
                zIndex: 1,
              }
            : {}),
        }}
      >
        {justifyContentCenter && (
          <StyledTabsCenter
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            {renderTabs}
          </StyledTabsCenter>
        )}
        {!justifyContentCenter && !spaceBetween && (
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            {renderTabs}
          </StyledTabs>
        )}
        {!justifyContentCenter && spaceBetween && (
          <StyledTabsSpaceBetween
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            {renderTabs}
          </StyledTabsSpaceBetween>
        )}
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.children}
        </TabPanel>
      ))}
    </Box>
  );
}
