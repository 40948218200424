import { Box, IconButton, useTheme } from '@mui/material';
import { Suspense, useContext, useEffect, useRef } from 'react';
import { BenaBadge } from '../../components/BenaBadge';
import { useLazyGetRocketchatUserUnreadMessagesCountQuery } from '../../redux/benaApi';
import { ChatThinIcon } from '../../assets/imgs/icons';
import { throttle } from 'lodash';
import { IMContext } from '../../context/context';

const WINDOW_MESSAGE_RCIM_NEW_MESSAGE_COUNT = 'RCIM-message-count';
export const WINDOW_MESSAGE_RCIM_NEW_MESSAGE_NEW = 'RCIM-message-new';

const isUserLoggedIn = () => {
  // read login status from localstorage
  try {
    const json = window.localStorage.getItem('persist:root');
    return !!JSON.parse(JSON.parse(json || '').auth).access;
  } catch (e) {
    return false;
  }
};

function useQueryMsgCount(messageLoaded: fnNumberToVoid) {
  const [query] = useLazyGetRocketchatUserUnreadMessagesCountQuery({});
  return async () => {
    // only update when page is visible
    if (document.visibilityState === 'visible') {
      try {
        if (isUserLoggedIn()) {
          const count = (await query({}).unwrap())?.data?.unread_num;
          typeof count === 'number' && count >= 0 && messageLoaded(count);
        }
      } catch (e) {
        //ignore error
      }
    }
  };
}

type CountProps = {
  messageLoaded: fnNumberToVoid;
};
function Count({ messageLoaded }: CountProps) {
  const fn = useQueryMsgCount(messageLoaded);
  const throttleFn = useRef(throttle(fn, 2000));
  useEffect(() => {
    // handle the window message from rocketchat realtime api
    const onNewMessageNotification = ({
      data,
    }: WindowMessageCountNotificationDataType) => {
      if (data?.type === WINDOW_MESSAGE_RCIM_NEW_MESSAGE_NEW) {
        throttleFn.current();
      }
    };
    window.addEventListener('message', onNewMessageNotification);

    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        throttleFn.current();
      }
    };
    window.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener('message', onNewMessageNotification);
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [throttleFn]);
  return <></>;
}

type RCStoredCountProps = { setMessageCount: (num: number) => void };
export function RCStoredCount({ setMessageCount }: RCStoredCountProps) {
  const context = useContext(IMContext);
  useEffect(() => {
    window.postMessage({
      type: WINDOW_MESSAGE_RCIM_NEW_MESSAGE_COUNT,
      count: context.unreadCount,
    });
  }, []);

  return (
    <Suspense fallback={<></>}>
      <Count
        messageLoaded={(num: number) => {
          window.postMessage({
            type: WINDOW_MESSAGE_RCIM_NEW_MESSAGE_COUNT,
            count: num,
          });
          setMessageCount(num);
        }}
      />
    </Suspense>
  );
};

type RCUnreadMessagesCountProps = {
  showIMDisabledHint?: boolean;
};
export default function RCUnreadMessagesCount({
  showIMDisabledHint = false,
}: RCUnreadMessagesCountProps) {
  const context = useContext(IMContext);
  const unreadMesageCount = context.unreadCount;
  const theme = useTheme();
  useListenTIMMessageCountNotification(context.setUnreadCount);

  return (
    <Box component="span" position={'relative'}>
      <BenaBadge badgeContent={unreadMesageCount > 99 ? '99+' : unreadMesageCount} >
        <IconButton sx={{ p: 1 }}>
          <ChatThinIcon sx={{ width: '18px', height: '18px' }} stroke={theme.palette.base.main2white} />
        </IconButton>
      </BenaBadge>
    </Box>
  );
}

export type WindowMessageCountNotificationDataType = {
  data: {
    type: string;
    count: number;
  };
};

export function useListenTIMMessageCountNotification(
  setUnreadMesageCount: fnNumberToVoid
) {
  useEffect(() => {
    const onMessageCountNotification = ({
      data,
    }: WindowMessageCountNotificationDataType) => {
      if (data?.type === WINDOW_MESSAGE_RCIM_NEW_MESSAGE_COUNT) {
        setUnreadMesageCount(data?.count || 0);
        (window as any).rc_unread_message_count = data?.count || 0;
      }
    };
    window.addEventListener('message', onMessageCountNotification);
    return () =>
      window.removeEventListener('message', onMessageCountNotification);
  }, []);
}
