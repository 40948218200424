import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Typography } from '@mui/material';

export default function IconMenuButton({
  icon,
  data,
  onMenuItemChange = (num: number) => {
    return;
  },
  ...otherProps
}: AnyObject & {
  icon: React.ReactNode;
  data: DropdownMenuButtonProps;
  onMenuItemChange?: fnNumberToVoid;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (index: number) => {
    if (index >= 0) {
      onMenuItemChange(index);
    }
    setAnchorEl(null);
  };

  const ImgIcon = ({ src }: AnyObject) =>
    typeof src === 'string' ? (
      <img alt="" src={src} style={{ height: '1rem' }} />
    ) : (
      <Box className="boxcenterhv">{src}</Box>
    );

  return (
    <Box
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box onClick={handleClick} className="boxcenterhv" {...otherProps}>
        {icon}
      </Box>
      {data.options.length <= 0 ? (
        ''
      ) : (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClick={() => handleClose(-1)}
          sx={{
            borderRadius: 0,
            transform: `${anchorEl && 'translateY(0.125rem)'}`,
          }}
        >
          {data.options.map((option, index) => (
            <MenuItem
              onClick={() => handleClose(index)}
              disableRipple
              key={option.name}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                {!!option.icon && <ImgIcon src={option.icon} />}
                <Typography
                  variant="caption"
                  sx={{
                    ml: option.icon ? 0.6 : 0,
                  }}
                >
                  {option.name}
                </Typography>
                {option.children}
              </Box>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
}
