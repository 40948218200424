import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TransitionUp } from '../../components/Transitions';
import { Messages } from '../../localization/Messages';
import ErrorMessages, {
  SubscriptionStripeErrorCode,
  UIErrorCode,
} from '../../redux/benaApiResponseCode';
import { useTypedSelector } from '../../redux/store';
import { staticURLMyProfile } from '../../route/urls';
import { setUIErrorCode } from '../account/AuthSlice';

// TODO update subscript
export default function ErrorMsg() {
  const dispatch = useDispatch();

  const { subscriptionPlanLimitErrorCode, uiErrorCode } = useTypedSelector(
    (state) => state.auth
  );

  let errorCode = SubscriptionStripeErrorCode?.includes(
    parseInt(subscriptionPlanLimitErrorCode || '')
  )
    ? parseInt(subscriptionPlanLimitErrorCode || '0')
    : 0;
  if (!errorCode) {
    errorCode = UIErrorCode?.includes(parseInt(uiErrorCode || '0'))
      ? parseInt(uiErrorCode || '0')
      : 0;
  }
  useEffect(() => {
    dispatch(setUIErrorCode(''));
  }, [errorCode, dispatch, setUIErrorCode]);

  return subscriptionPlanLimitErrorCode?.startsWith('4060') ? (
    <CreditsRunoutAlert />
  ) : (
    <></>
  );
}

function CreditsRunoutAlert() {
  const intl = useIntl();
  const navigate = useNavigate();
  const clickHandler = () => navigate(staticURLMyProfile);
  const [open, setOpen] = useState(true);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      TransitionComponent={TransitionUp}
    >
      <DialogTitle>{'License alert'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {intl.formatMessage(ErrorMessages.responseCode4060)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={clickHandler}>
          {intl.formatMessage(Messages.UpgradeSubscriptionPlan)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
