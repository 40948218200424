import { UploadFiles } from "../../assets/imgs/icons";
import {
  IconButton,
  Box,
  useTheme,
  Stack,
  Button,
  Typography
} from "@mui/material";
import { BenaFileUploadButton } from "../BenaFileUploadButton";
import { useGlobalStyles } from "../../assets/styles/style"
import TitledPanel from "../../creator/campaign/utils"
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBackRounded } from "@mui/icons-material";
import { DownloadIcon } from "../../assets/imgs/icons";
import { useIntl } from "react-intl";
import { CreatorMessages } from '../../localization/CreatorMessages';
import { showMessage } from "../ShowMessage";
import { UrlThumbnail, hrefToDownload } from "../UrlThumbnail";
import { useShortlistImportMutation } from "../../redux/benaApi";
import CircularProgress from '@mui/material/CircularProgress';
import { MutationHandler } from "../../redux/benaApiResultHandler";

export function UploadCreator({}) {
  return (
    <IconButton>
      <UploadFiles sx={{ width:"20px", height: "20px" }} />
    </IconButton>
  )
};

function UploadFileBox({
  uploadFile,
}:{
  uploadFile: (file: File) => void
}) {
  const theme = useTheme();

  const btnUploadId = useRef( (Math.random() * 10000).toString(16).slice(10) );
  const size = { width: '20rem', height: '20rem' };
  const [className, setClassName] = useState('drag-drop-upload-normal');

  const highlightDropBox = () => { setClassName('drag-drop-upload-focus'); };
  const deHighlightDropBox = () => { setClassName('drag-drop-upload-normal'); };
  const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      uploadFile(file);
    }
  }
  const dropHandler = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const items = e.dataTransfer.items;
    if (items && items.length > 0) {
      const files = Array.from(items)
        .filter((item) => item.kind === 'file')
        .map((file) => file.getAsFile())
        .filter((f) => f);
      uploadFile(files[0] as File);
    } else {
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        uploadFile(files[0]);
      }
    }
  };

  return (
    <label htmlFor={`btnUploadId-${btnUploadId.current}`} title={''}
      onDragOver={(e) => { e.preventDefault(); highlightDropBox(); }}
      onDrop={(e) => { deHighlightDropBox(); dropHandler(e); }}
      onDragLeave={ () => deHighlightDropBox() }
    >
      <Stack direction="row" flexWrap="wrap" gap="16px" justifyContent="center" alignItems="center"
        sx={{
          cursor: 'pointer', borderRadius: '0.5rem', border: '1px dashed ' + theme.palette.neutral["700_1"],
          "&:hover": { boxShadow: "0rem 0rem 0.625rem #5b3de3;" }
        }}
        className={className} >
          <input multiple type="file" accept=".xls,.xlsx" onChange={selectFile}
            id={`btnUploadId-${btnUploadId.current}`}
            name={`btnUploadId-${btnUploadId.current}`}
            style={{ display: 'none' }}
            onClick={(e) => { e.currentTarget.value = ''; }}
          />
          <BenaFileUploadButton width={size.width} height={size.height} />
      </Stack>
    </label>
  )
}

export default function UploadFormCreator(){
  let base64encoded = window.location.search.slice(1);
  let str = atob(base64encoded);
  const nameAndId = useRef( str.split("===") );
  
  const [ upload ] = useShortlistImportMutation();

  const navigate = useNavigate();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const intl = useIntl();
  const [xlsxFile, setXlsxFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [tips, setTips] = useState<string[]>([]);

  const downloadButton = useMemo(()=>(
    <Button className={gs.buttonPrimary} onClick={()=>{
      hrefToDownload("/importTemplate.xlsx");
    }}>
      <DownloadIcon sx={{ width: '16px', height: '16px' }} stroke="#FFF" />
      &nbsp;{intl.formatMessage(CreatorMessages.downLoadTemplate)}
    </Button>
  ),[gs]);
  
  const uploadFile = async (file: File) => {
    let name = file.name;
    let regex = /(.xls|.xlsx)$/;
    if (!regex.test( name.toLowerCase() )) {
      showMessage.error( intl.formatMessage(CreatorMessages.xlsxErrorTips) );
      return;
    };
    setFileUrl( URL.createObjectURL(file) );
    setXlsxFile(file);
    setLoading(true);
    var formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('shortlist_id', nameAndId.current[0] );
    
    await MutationHandler({
      intl,
      action: async () => {
        return await upload({ file: formData }).unwrap();
      },
      onSuccess: (data) => {
        let tipTxt = data.message?.split('\n') || [];
        setTips( tipTxt );
        setLoading(false);
      },
      onError: () => { setLoading(false); setXlsxFile(null); },
      onException: () => { setLoading(false); setXlsxFile(null); }
    }, CreatorMessages.uploadSuccess, true);
  };

  const iconSx = { width: '22px', height: '22px', marginLeft: '-8px' };
  return (
    <Box mt="2rem" borderRadius="0.5rem" border={ "1px solid " + theme.palette.neutral["600"] }>
      <Stack direction="row" p={'12px 20px'} justifyContent="space-between"
        borderBottom={ "1px solid " + theme.palette.neutral["600"] }>
        <Button
          sx={{ height: '32px', fontWeight: '600', fontSize: '18px', padding: '8px' }}
          variant="text" className={gs.buttonOutlinedBlack}
          onClick={() => { navigate(-1); }}
        >
          <ArrowBackRounded htmlColor={theme.palette.base.black} sx={ iconSx } />
          &nbsp;&nbsp;
          {intl.formatMessage(CreatorMessages.uploadTo)} : {nameAndId.current[1]}
        </Button>
        <Box flexGrow="1"></Box>
      </Stack>
      <Box p={"20px 24px"}>
        <TitledPanel title={intl.formatMessage(CreatorMessages.xlsxFileTips)}
          actionElement={ downloadButton } gap="12px">
          {
            xlsxFile ? (
              <Stack direction="row" flexWrap="wrap" gap="16px" justifyContent="center" alignItems="center"
                sx={{
                  borderRadius: '0.5rem', border: '1px dashed ' + theme.palette.neutral["700_1"],
                  position: 'relative'
                }} >
                <Box sx={{width: "20rem", height:"20rem"}}>
                  <UrlThumbnail url={ fileUrl } name={ xlsxFile.name } isCustomDownload={true}
                    onDelete={()=>{
                      setXlsxFile(null);
                      setFileUrl("");
                      setTips([]);
                    }} />
                </Box>
                { tips.length > 0 && (
                  <Stack direction="column" gap="8px" justifyContent="center" sx={{height:"20rem"}}>
                    { tips.map( (tip, index) => <Typography key={index} component="p" >{tip}</Typography> ) }
                  </Stack>
                )}
                { loading && (
                  <Stack sx={{
                    position: 'absolute', width: '100%', height: '100%', top: 0, left: 0,
                    alignItems: 'center', justifyContent: 'center',
                    bgcolor: 'rgba(0,0,0,0.5)', borderRadius: '0.5rem'
                  }}>
                    <CircularProgress color="primary" />
                  </Stack>
                )}
              </Stack>
            ) : (
              <UploadFileBox uploadFile={uploadFile} />
            )
          }
        </TitledPanel>
      </Box>
    </Box>
  )
}