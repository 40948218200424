import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles, createStyles } from '@mui/styles';
import {
  IconButton,
  Link,
  Stack,
  Theme,
  Typography,
  capitalize,
  useTheme,
} from '@mui/material';
import {
  CheckThinIcon,
  CrossIcon,
  SortDownIcon,
  SortUpIcon,
} from '../../assets/imgs/icons';
import BenaPagination, {
  BenaRefreshPanel,
} from '../../components/BenaPagination';
import { InfiniteScrollLoaderMessage } from '../../components/InfiniteScrollMessages';
import Color from 'color';
import {
  combineSortToQueryString,
  CreatorBasicInfo,
  ShowOnlyPanel,
  ShowProposal,
} from './utils';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SOPSearch } from './search/SOPSearch';
import {
  replaceURLSearchParam,
  useTimePastFormatIntl,
} from '../../utils/common';
import {
  useDeleteCampaignInvitationMutation,
  useLazyGetCampaignCreatorLatestApplicationQuery,
  usePatchCampaignApplicationsMutation,
} from '../../redux/benaApi';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { SendMessageModal } from '../../components/SendMessageModal';
import { useTypedSelector } from '../../redux/store';
import { selectAuth } from '../account/AuthSlice';
import { useGlobalStyles } from '../../assets/styles/style';
import { Messages } from '../../localization/Messages';
import DialogPage from '../../creator/pages/DialogPage';
import HireCreator from './HireCreator';
import LightTooltip from '../../components/LightTooltip';
import ApplicationViewPage from '../../creator/pages/ApplicationViewPage';
import { showMessage } from '../../components/ShowMessage';
import Confirm from '../../components/Confirm';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DeclineApplicationConfirmation } from '../../creator/pages/DeclineApplicationConfirmation';

function mapStatus(status: string) {
  switch (status) {
    case 'applied':
      return 'proposed';
    default:
      return status;
  }
}

const SortByKeys = [
  { key: 'name' },
  { key: 'last_message' },
  { key: 'message_status' },
  { key: 'proposal' },
  { key: 'turnaround_time' },
  { key: 'price' },
  { key: 'action' },
];

const useHeadCellsData = () => {
  const theme = useTheme();
  const intl = useIntl();
  return [
    {
      key: SortByKeys[0].key,
      name: intl.formatMessage(Messages.thCreator),
      width: '17.75rem',
    },
    {
      key: SortByKeys[1].key,
      name: intl.formatMessage(Messages.thMessages),
      width: '6rem',
      sort: false,
    },
    {
      key: SortByKeys[2].key,
      name: intl.formatMessage(Messages.thProposalStatus),
      width: '6rem',
      sort: false,
    },
    {
      key: SortByKeys[3].key,
      name: intl.formatMessage(Messages.thProposal),
      width: '6rem',
      sort: false,
      color: theme.palette.primary.main,
    },
    {
      key: SortByKeys[4].key,
      name: intl.formatMessage(Messages.thTurnaroundTime),
      width: '14rem',
      sort: true,
    },
    {
      key: SortByKeys[5].key,
      name: `${intl.formatMessage(Messages.thPrice)}($)`,
      width: '14rem',
      sort: true,
    },
    {
      key: SortByKeys[6].key,
      name: intl.formatMessage(Messages.thActions),
      width: '6rem',
      sort: false,
      dropdown: false,
    },
  ];
};

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
}: EnhancedTableProps) {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const headCells = useHeadCellsData();
  const getColor = (cell: { key: string }, o: Order) => {
    if (cell.key === orderBy) {
      return order === o ? theme.custom.lightRed : '';
    }
    return '';
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((cell, index) => (
          <TableCell
            key={cell.name}
            align="left"
            padding="none"
            sx={{ minWidth: cell.width, maxWidth: cell.width }}
          >
            <Typography
              className={`${cell.sort && 'hand'} ${classes.headerCell}`}
              onClick={(e) => cell.sort && onRequestSort(e, cell.key)}
              title={cell.sort ? intl.formatMessage(Messages.ClickToSort) : ''}
              sx={{
                paddingLeft: index === 0 ? '16px' : '0px',
              }}
            >
              {cell.name}{' '}
              {cell.sort && cell.key === orderBy && (
                <Link href="#">
                  {order === 'asc' ? (
                    <SortDownIcon className={classes.sortIconSize} />
                  ) : (
                    <SortUpIcon className={classes.sortIconSize} />
                  )}
                </Link>
              )}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type RowDataType = { key: string; children: ReactNode | string };

type CampaignIncomingProposalsTableViewProps = {
  data?: CampaignApplicationItemType[];
  total: number;
  loadPage: (page: number, size: number, queryParams?: string) => void;
  loading: boolean;
  campaignId: string;
  isActiveCampaign: boolean;
};
export default function CampaignIncomingProposalsTableView({
  data,
  total,
  loadPage,
  loading,
  campaignId,
  isActiveCampaign,
}: CampaignIncomingProposalsTableViewProps) {
  const theme = useTheme();
  const intl = useIntl();
  const gc = useGlobalStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [queryString, setQueryString] = useState('');
  const headers = useHeadCellsData();
  const fnTimePastFormatIntl = useTimePastFormatIntl();
  const [deleteInvitation] = useDeleteCampaignInvitationMutation();

  const handleDeleteInvitation = async (invitation_id: string) => {
    await MutationHandler({
      intl,
      action: async () => {
        return await deleteInvitation({
          invitation_id,
        }).unwrap();
      },
      onSuccess: (data) => {
        showMessage.success(intl.formatMessage(Messages.SuccessfullyDeleted));
        // TODO check qs prop
        loadPage(page, rowsPerPage);
      },
    });
  };

  const rows = (data || []).map((item) => {
    return [
      {
        key: SortByKeys[0].key,
        children: (
          <Box paddingLeft="16px">
            <CreatorBasicInfo
              creator={item.creator}
              showSocialLinks={true}
              showIMIcon={true}
              userId={item.creator?.user_id || ''}
            />
          </Box>
        ),
      },
      {
        key: SortByKeys[1].key,
        children: `${item.msg_count}`,
      },
      {
        key: SortByKeys[2].key,
        children: item.job_contract?.status
          ? capitalize(item.job_contract?.status)
          : item.status
          ? capitalize(mapStatus(item.status))
          : '-',
      },
      {
        key: SortByKeys[3].key,
        children:
          item.proposal || item.message_to_brand ? (
            <IconButton
              onClick={() => {
                setApplicationId(item.id);
              }}
              disableRipple
              sx={{
                padding: '0px',
              }}
            >
              <Typography className={gc.font14px600Primary}>
                {intl.formatMessage(Messages.read)}
              </Typography>
            </IconButton>
          ) : (
            '-'
          ),
      },
      {
        key: SortByKeys[4].key,
        children: item.turnaround_time_days ? (
          <LightTooltip title={item.turnaround_time_days}>
            <Box component="span">{item.turnaround_time_days}</Box>
          </LightTooltip>
        ) : (
          '-'
        ),
      },
      {
        key: SortByKeys[5].key,
        children: item.price ? (
          <LightTooltip title={item.price}>
            <Box component="span">{item.price}</Box>
          </LightTooltip>
        ) : (
          '-'
        ),
      },
      {
        key: SortByKeys[6].key,
        children: !isActiveCampaign ? (
          '-'
        ) : // show for application rejected only
        item.status === 'rejected' && item.application_id ? (
          <IconButton
            disableRipple
            onClick={() => {
              setCurrentItemForHireCreator(item);
            }}
          >
            <Typography fontSize={'14px'} fontWeight={600} color="primary.main">
              {capitalize(intl.formatMessage(Messages.hire))}
            </Typography>
          </IconButton>
        ) : item.status === 'invited' ? (
          <LightTooltip
            title={intl.formatMessage(Messages.ClickToCancelThisInvitation)}
          >
            <IconButton
              disableRipple
              onClick={() => {
                handleDeleteInvitation(item.id);
              }}
            >
              <Typography
                fontSize={'14px'}
                fontWeight={600}
                color="primary.main"
              >
                {intl.formatMessage(Messages.CancelAndQuestionMark)}
              </Typography>
            </IconButton>
          </LightTooltip>
        ) : item.status === 'applied' ? (
          <Stack direction="row" columnGap="8px">
            <LightTooltip title={intl.formatMessage(Messages.hire)}>
              <IconButton
                onClick={() => {
                  console.log(item);
                  setCurrentItem(item);
                  setShowDialogOffer(true);
                }}
                disableRipple
              >
                <CheckThinIcon sx={{ width: '18px', height: '13px' }} />
              </IconButton>
            </LightTooltip>
            <LightTooltip title={intl.formatMessage(Messages.reject)}>
              <IconButton
                onClick={() => {
                  setCurrentItem(item);
                  setShowReject(true);
                }}
                disableRipple
              >
                <CrossIcon
                  stroke={theme.custom.lightRed}
                  sx={{ width: '13px', height: '13px' }}
                />
              </IconButton>
            </LightTooltip>
          </Stack>
        ) : item.job_contract_id ? (
          <LightTooltip title={intl.formatMessage(Messages.ViewOffer)}>
            <Link
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setCurrentItem(item);
                setShowDialogOffer(true);
              }}
              margin="0px 8px"
            >
              <ArticleIcon
                htmlColor={theme.palette.primary[400]}
                sx={{ width: '16px', height: '16px' }}
              />
            </Link>
          </LightTooltip>
        ) : (
          <></>
        ),
      },
    ] as RowDataType[];
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    orderByValue: string
  ) => {
    const isAsc = orderBy === orderByValue && order === 'desc';
    const orderValue = isAsc ? 'asc' : 'desc';
    setOrder(orderValue);
    setOrderBy(orderByValue);
    const qs = combineSortToQueryString(orderValue, orderByValue, queryString);
    loadPage(page, rowsPerPage, qs);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    const qs = combineSortToQueryString(order, orderBy, queryString);
    loadPage(Math.max(1, newPage), rowsPerPage, qs);
  };

  const handleChangeRowsPerPage = (count: number) => {
    setRowsPerPage(count);
    setPage(1);
    const qs = combineSortToQueryString(order, orderBy, queryString);
    loadPage(1, count, qs);
  };

  const handleSearch = (qs: string) => {
    setQueryString(qs);
    // always query from the first page
    setPage(1);
    const qstr = combineSortToQueryString(order, orderBy, qs);
    loadPage(1, rowsPerPage, qstr);
    return;
  };

  const [rejectApplication] = usePatchCampaignApplicationsMutation({});
  const [showReject, setShowReject] = useState(false);
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const [showProposal, setShowProposal] = useState(false);
  const [currentItem, setCurrentItem] =
    useState<CampaignApplicationItemType | null>(null);

  const userId = useTypedSelector((state) => selectAuth(state)).user?.id;

  // handle creator_id param in url to display proposal
  const [queryCampaignApplicatioins] =
    useLazyGetCampaignCreatorLatestApplicationQuery({});
  useEffect(() => {
    (async () => {
      const creator_id =
        new URLSearchParams(window.location.search).get('creator_id') || '';
      if (creator_id && campaignId) {
        const ret = await queryCampaignApplicatioins({
          campaign_id: campaignId,
          creator_id,
        }).unwrap();
        const item = ret?.data;
        if (item?.creator_id) {
          setCurrentItem(item);
          setShowProposal(true);
        }
      }
    })();
  }, []);

  const [showDialogOffer, setShowDialogOffer] = useState(false);

  // show application details
  const [applicationId, setApplicationId] = useState('');

  // view offer
  const [currentItemForHireCreator, setCurrentItemForHireCreator] =
    useState<CampaignApplicationItemType | null>(null);

  // view application from url search
  const [searchParams] = useSearchParams();
  const applicationIdFromUrl = searchParams.get('application_id');
  useEffect(() => {
    if (applicationIdFromUrl) {
      setApplicationId(applicationIdFromUrl);
    }
  }, [applicationIdFromUrl]);

  return (
    <Box>
      {showProposal && currentItem && (
        <ShowProposal
          handleClose={(refresh) => {
            setCurrentItem(null);
            setShowProposal(false);
          }}
          data={currentItem}
        />
      )}
      {showReject && currentItem && (
        <DeclineApplicationConfirmation
          initText={''}
          initOpen={showReject}
          handlePatch={async (text: string) => {
            await MutationHandler({
              intl,
              action: async () => {
                return await rejectApplication({
                  creator_id: currentItem.creator_id,
                  campaign_id: currentItem.campaign_id,
                  status: 'rejected',
                  campaign_application_id: currentItem.id,
                  withdraw_reason: text,
                }).unwrap();
              },
              onSuccess: () => {
                setShowReject(false);
                handleChangePage(page);
              },
            });
          }}
          isValidForSave={(text: string) => {
            return true;
          }}
          onCancel={() => setShowReject(false)}
        />
      )}
      {showSendMessageModal && currentItem && userId && (
        <SendMessageModal
          fromId={userId || ''}
          toId={currentItem.creator_id}
          setNullData={() => {
            setShowSendMessageModal(false);
            setCurrentItem(null);
          }}
          initTitle={intl.formatMessage(Messages.leaveAMessage)}
        />
      )}
      <SOPSearch
        handleSearch={handleSearch}
        filterType="incoming-proposal"
        searchInputPlaceholder={intl.formatMessage(Messages.CreatorSearchHint)}
      />
      {/* <ShowOnlyPanel
        total={total}
        count={rowsPerPage}
        onSortChange={handleChangeRowsPerPage}
        optionPostfix={intl.formatMessage(Messages.Creators)}
      /> */}
      <BenaRefreshPanel onRefresh={() => handleChangePage(page)} />
      <TableContainer className={classes.tableContainer}>
        {loading && (
          <Box className={`boxcenterhv ${classes.loading}`}>
            <InfiniteScrollLoaderMessage />
          </Box>
        )}
        <Table aria-labelledby="campaign progress" size={'medium'}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  hover
                  onClick={(event) => {
                    return; /* handleClick(event, row.name) */
                  }}
                  tabIndex={-1}
                  key={`${index}_${row}`}
                >
                  {row.map((col, index) => {
                    const { width, color } = headers[index];
                    return (
                      <TableCell
                        key={col.key}
                        padding="none"
                        align="left"
                        sx={{
                          maxWidth: width,
                          color: color || theme.custom.black2,
                          verticalAlign: 'top',
                        }}
                        className={`${classes.tabelCell} ellipsis`}
                      >
                        {col.children}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!rows.length && <Box sx={{ height: '2rem' }}></Box>}
      </TableContainer>
      <BenaPagination
        count={Math.ceil(total / rowsPerPage)}
        currentPage={page}
        onChange={handleChangePage}
        loading={loading}
      />
      {showDialogOffer && currentItem?.creator?.id && currentItem?.campaign_id && (
        <DialogPage
          initOpen={showDialogOffer}
          children={
            <HireCreator
              creatorId={currentItem.creator.id}
              campaignId={currentItem.campaign_id}
              applicationId={currentItem.id}
              onClose={() => {
                setShowDialogOffer(false);
                loadPage(page, rowsPerPage);
              }}
              jobcontractId={currentItem.job_contract_id}
            />
          }
        />
      )}
      {applicationId && (
        <ApplicationViewPage
          show={!!applicationId}
          hideDialog={(submitted: boolean) => {
            setApplicationId('');
            navigate(
              window.location.pathname +
                replaceURLSearchParam(
                  window.location.search,
                  'application_id',
                  ''
                )
            );
          }}
          applicationId={applicationId}
        />
      )}
      {currentItemForHireCreator &&
        currentItemForHireCreator.creator?.id &&
        currentItemForHireCreator.campaign_id && (
          <DialogPage
            initOpen={!!currentItemForHireCreator}
            children={
              <HireCreator
                creatorId={currentItemForHireCreator.creator?.id}
                campaignId={currentItemForHireCreator.campaign_id}
                applicationId={currentItemForHireCreator.id}
                onClose={() => {
                  setCurrentItemForHireCreator(null);
                  loadPage(page, rowsPerPage);
                }}
                jobcontractId={currentItemForHireCreator.job_contract_id}
              />
            }
          />
        )}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCell: {
      fontSize: '0.875rem',
      fontWeight: '400',
      color: theme.palette.grey[700],
      lineHeight: '1.5rem',
      // textTransform: 'uppercase',
      marginBottom: '1rem',
    },
    tableContainer: {
      padding: '1rem 1.5rem',
      borderRadius: '0.5rem',
      backgroundColor: theme.palette.base.white,
      position: 'relative',
      border: `1px solid ${theme.palette.neutral[600]}`,
    },
    sortIconSize: {
      width: '0.875rem',
      height: '0.875rem',
    },
    tabelCell: {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.125rem',
      padding: '1rem 0rem',
    },
    loading: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundColor: Color(theme.palette.base.white).fade(0.2).toString(),
      zIndex: 900,
    },
  })
);
