import { Suspense, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Container } from '@mui/material';
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useSearchParams,
} from 'react-router-dom';
import RegisterAccount from '../features/account/RegisterAccount';
import RegisterAccountPassword from '../features/account/RegisterAccountPassword';
import Login from '../features/account/Login';
import ForgotPassword from '../features/account/ForgotPassword';
import ResetPassword from '../features/account/ResetPassword';
import { encodeObjectToURLSearchParams } from '../utils/common';
import PrivacyPolicy from '../features/account/PrivacyPolicy';
import TermsOfService from '../features/account/TermsOfService';
import Color from 'color';
import ChangeEmail from '../features/account/ChangeEmail';
import { useThemeMode } from '../hooks/theme';
// import RegisterBrandProfile from '../features/account/RegisterBrandProfile';
import RegisterCreatorProfile from '../features/account/RegisterCreatorProfile';
import { useIsRoleCreator } from '../hooks/account';
import ProtectedRoute from '../route/ProtectedRoute';
import JoinUs from '../features/account/JoinUs';
import { staticURLRoot } from '../route/urls';

export default function Account() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [registerBrandData, setRegisterBrandData] =
    useState<BrandAccountType | null>(null);
  const [forgotPasswordData, setForgotPasswordData] =
    useState<ForgotPasswordType | null>(null);
  const [registerCreatorProfileLastStep, setRegisterCreatorProfileLastStep] =
    useState(false);

  const isCreator = useIsRoleCreator();

  // TODO change image when move step
  const [registerProfileStep, setRegisterProfileStep] = useState('');
  const notifyRegisterBrandProfileCurrentStep = (step: number) => {
    setRegisterProfileStep(`brand${step}`);
  };

  const getImgByStep = () => {
    switch (registerProfileStep) {
      case 'brand0':
        return '/imgs/account/highest_audience_engagement_rate_on_tiktok.png';
      case 'brand1':
        return '/imgs/account/the_top_3_influencer_categories.png';
      case 'brand2':
        return '/imgs/account/most_mentioned_brands_on_tiktok.png';
      case 'brand3':
        return '/imgs/account/influencer_marketing_success.png';
      default:
        return '';
    }
  };

  // hide login form if login from tiktok
  const [searchParams] = useSearchParams();
  const accessParam = searchParams.get('access');
  const refreshParam = searchParams.get('refresh');
  const isTiktokLogin = accessParam && refreshParam;

  const idDarkMode = useThemeMode() === 'dark';
  const imgMode = (imgLight: string, imgDark: string) => {
    return idDarkMode ? imgDark : imgLight;
  };

  return (
    <Box
      className={'boxcenterhv'}
      sx={{ visibility: isTiktokLogin ? 'hidden' : 'visible' }}
    >
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          minHeight: '100vh',
          m: 0,
          p: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            minHeight: '100vh',
            flexGrow: 1,
            potition: 'relative',
            flexDirection: 'column',
            [theme.breakpoints.down('md')]: { px: '0' },
            [theme.breakpoints.up('md')]: { px: '0.5rem' },
            pt: '2rem',
            alignSelf: 'flex-start',
            backgroundColor: Color(theme.palette.neutral[25])
              .fade(0.7)
              .toString(),
            justifyContent: 'flex-start',
          }}
          className="boxcenterhv"
        >
          <Box
            sx={{
              height: '100%',
              [theme.breakpoints.down('md')]: { width: '100%' },
              [theme.breakpoints.up('md')]: { width: '27rem' },
              p: '1rem',
            }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <JoinUs
                    registerCreator={() => {
                      navigate('registerCreator');
                    }}
                    registerBrand={() => {
                      navigate('registerBrand');
                    }}
                  />
                }
              />
              <Route
                path="/registerCreator"
                element={
                  <RegisterAccount
                    registerBrandData={registerBrandData}
                    setTemporaryRegisterBrandData={setRegisterBrandData}
                    nextStep={(user: RegisterRequestParams) =>
                      navigate(
                        `registerCreatorPassword?${encodeObjectToURLSearchParams(
                          user
                        )}`
                      )
                    }
                    role={'creator'}
                  />
                }
              />
              <Route
                path="/registerBrand"
                element={
                  <RegisterAccount
                    registerBrandData={registerBrandData}
                    setTemporaryRegisterBrandData={setRegisterBrandData}
                    nextStep={(user: RegisterRequestParams) =>
                      navigate(
                        `registerBrandPassword?${encodeObjectToURLSearchParams(
                          user
                        )}`
                      )
                    }
                    role={'brand'}
                  />
                }
              />
              <Route
                path="/registerBrandPassword"
                element={
                  <RegisterAccountPassword
                    role={'brand'}
                    nextStep={() => {
                      navigate(staticURLRoot);
                    }}
                  />
                }
              />
              <Route
                path="/registerCreatorPassword"
                element={
                  <RegisterAccountPassword
                    role={'creator'}
                    nextStep={() => {
                      navigate(`registerCreatorProfile`);
                    }}
                  />
                }
              />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/forgotPassword"
                element={
                  <ForgotPassword
                    forgotPasswordData={forgotPasswordData}
                    setTemporaryForgotPasswordData={setForgotPasswordData}
                    nextStep={(data: ResetPasswordRequestParams) =>
                      navigate(
                        `resetPassword?${encodeObjectToURLSearchParams(data)}`
                      )
                    }
                  />
                }
              />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/changeemail" element={<ChangeEmail />} />
              {isCreator && (
                <>
                  <Route
                    path="/registerCreatorProfile"
                    element={
                      <ProtectedRoute
                        path="/registerCreatorProfile"
                        element={
                          <Suspense fallback={<CircularProgress size={8} />}>
                            <RegisterCreatorProfile />
                          </Suspense>
                        }
                      />
                    }
                  />
                </>
              )}
              <Route
                path="*"
                element={<Navigate to="/login" replace={true} />}
              />
            </Routes>
          </Box>
        </Box>
        <Box
          sx={{
            height: '100vh',
            [theme.breakpoints.down('md')]: { width: '0%' },
            [theme.breakpoints.up('md')]: { width: '50%' },
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            position: "sticky",
            top: 0,
            backgroundImage: registerCreatorProfileLastStep
              ? `url(${`/imgs/account/registerSuccess.png`})`
              : ['/login'].some((v) => window.location.href.indexOf(v) >= 0)
              ? imgMode(
                  `url(${`/imgs/account/coverLogin.png`})`,
                  `url(${`/imgs/account/coverLoginDark.png`})`
                )
              : ['/registerCreatorProfile'].some(
                  (v) => window.location.href.indexOf(v) >= 0
                )
              ? getImgByStep()
                ? `url(${getImgByStep()})`
                : `url(${`/imgs/account/registrationDetail.png`})`
              : ['/registerBrandProfile'].some(
                  (v) => window.location.href.indexOf(v) >= 0
                )
              ? getImgByStep()
                ? `url(${getImgByStep()})`
                : `url(${`/imgs/account/brand1.png`})`
              : ['/forgotPassword', '/resetPassword'].some(
                  (v) => window.location.href.indexOf(v) >= 0
                )
              ? `url(${`/imgs/account/coverCompete.png`})`
              : [
                  '/registerBrandPassword',
                  '/registerCreatorPassword',
                  '/privacy',
                  '/terms',
                ].some((v) => window.location.href.indexOf(v) >= 0)
              ? imgMode(
                  `url(${`/imgs/account/registrationPassword.png`})`,
                  `url(${`/imgs/account/registrationPasswordDark.png`})`
                )
              : ['/registerCreator'].some(
                  (v) => window.location.href.indexOf(v) >= 0
                )
              ? `url(${`/imgs/account/registration.png`})`
              : ['/registerBrand'].some(
                  (v) => window.location.href.indexOf(v) >= 0
                )
              ? imgMode(
                  `url(${`/imgs/account/registrationBrand.png`})`,
                  `url(${`/imgs/account/registrationBrandDark.png`})`
                )
              : ['/changeemail'].some(
                  (v) => window.location.href.indexOf(v) >= 0
                )
              ? `url(${`/imgs/account/registration.png`})`
              : `url(${`/imgs/account/registration.png`})`,
          }}
        ></Box>
      </Container>
    </Box>
  );
}
