import DialogPage from './DialogPage';
import { CreatorMediaKit } from './CreatorProposalDetailsUtils';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowBackRounded } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { useGlobalStyles } from '../../assets/styles/style';
import { Messages } from '../../localization/Messages';
import CreatorCardHorizontal from '../components/CreatorCardHorizontal';
import TitledPanel from '../components/TitledPanel';
import { ApplicationContext } from '../context/context';
import {
  useGetApplicationQuery,
  usePatchCampaignApplicationMutation,
} from '../../redux/benaApi';
import { Validator } from '../../utils/common';
import { PatchApplicationEditButton } from './PatchApplicationEditButton';
import { useParams } from 'react-router-dom';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { showMessage } from '../../components/ShowMessage';
import Confirm from '../../components/Confirm';
import { Fragment, useState } from 'react';
import { downloadFile } from '../../components/UrlThumbnail';
import PageLayoutWrapper from '../../pages/PageLayoutWrapper';
import { isAttachment, isPdfFile } from '../../utils/mimetype';
import PdfPreview from '../../components/PdfPreview';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { LinkItUrl } from 'react-linkify-it';
import { useTypedSelector } from '../../redux/store';
import { selectAuth } from '../../features/account/AuthSlice';

type ApplicationViewPageProps = {
  show?: boolean;
  hideDialog?: fnBooleanToVoid;
  applicationId?: string;
};
export default function ApplicationViewPage({
  show = true,
  hideDialog,
  applicationId,
}: ApplicationViewPageProps) {
  const theme = useTheme();
  const intl = useIntl();
  const gs = useGlobalStyles();
  const isMobileView = useBreakpointXs();
  const currentLoginUserId = useTypedSelector(
    (state) => selectAuth(state).user
  )?.id;

  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [patchApplication] = usePatchCampaignApplicationMutation({});

  const { data, refetch } = useGetApplicationQuery({
    application_id: applicationId || id || '',
  });
  if (!data) return <></>;

  const applicationData = data.data;

  const contentDirections = applicationData.proposal || '-';
  const campaignId = applicationData.campaign_id || '';
  const userId = applicationData.user_id || '';

  const campaignInfo = {
    title: applicationData.creator?.name || '-',
    image: applicationData.creator?.picture || '-',
    messageToBrand: applicationData.message_to_brand || '-',
    budget: Validator.isNumber(applicationData.price)
      ? '$' + applicationData.price
      : applicationData.price,
    username: applicationData.creator?.unique_id,
    followers: applicationData.creator?.profile?.followers || 0,
    engagement_rate: applicationData.creator?.profile?.engagement_rate || 0,
    right_to_use: applicationData.right_usage || '-',
    post_display_duration: applicationData.post_display_duration || '-',
    turnaround_time_days: applicationData.turnaround_time_days || '-',
  };

  const applied = applicationData.status === 'applied';
  const submitApplication = async () => {
    const application_id = applicationData.id || '';
    MutationHandler({
      intl,
      action: async () => {
        return await patchApplication({
          application_id,
          status: 'applied',
        }).unwrap();
      },
      onSuccess: () => {
        showMessage.success(intl.formatMessage(Messages.successfullySubmitted));
        hideDialog?.(true);
      },
    });
  };

  const withdrawApplication = async () => {
    const application_id = applicationData.id || '';
    MutationHandler({
      intl,
      action: async () => {
        return await patchApplication({
          application_id,
          status: 'withdrawn',
        }).unwrap();
      },
      onSuccess: () => {
        hideDialog?.(true);
      },
    });
  };

  // if undefined, show media kit
  const showMediaKit = !!applicationData.generate_media_kit;

  const editMode = applicationData?.user_id === currentLoginUserId;

  return (
    <DialogPage
      initOpen={show}
      showHeader={false}
      showMobileViewPadding={false}
      children={
        <PageLayoutWrapper showHeader={!isMobileView}>
          <Stack marginTop={isMobileView ? '0px' : '48px'}>
            <Stack
              direction="row"
              alignItems={'center'}
              padding={
                isMobileView ? '0px 0px 12px 0px' : '12px 20px 12px 20px'
              }
              border={
                isMobileView ? '0px' : `1px solid ${theme.palette.neutral[600]}`
              }
              borderRadius="8px 8px 0px 0px"
            >
              <IconButton onClick={() => hideDialog?.(false)} disableRipple>
                <ArrowBackRounded
                  htmlColor={theme.palette.base.black}
                  sx={{ width: '22px', height: '22px' }}
                />
                <Typography fontSize={16} fontWeight={500} color="grey.700">
                  {intl.formatMessage(CreatorMessages.GoBack)}
                </Typography>
              </IconButton>
              <Box flexGrow={1}></Box>
              {editMode && (
                <Button
                  className={gs.buttonPrimary}
                  variant="contained"
                  onClick={() => setOpen(true)}
                >
                  {intl.formatMessage(
                    applied
                      ? CreatorMessages.WithdrawApplication
                      : Messages.SubmitApplication
                  )}
                </Button>
              )}
            </Stack>
            <ApplicationContext.Provider
              value={{
                userId: userId,
                campaignId,
                applicationId: applicationData.id || '',
                applicationCreatorId: applicationData.user_id || '',
                notifyRefresh: () => {
                  refetch();
                },
              }}
            >
              <Stack
                border={
                  isMobileView
                    ? '0px'
                    : `1px solid ${theme.palette.neutral[600]}`
                }
                borderTop="0px"
                borderRadius={'0px 0px 8px 8px'}
                padding={isMobileView ? '0px' : '20px 24px'}
              >
                <Stack rowGap={'27px'}>
                  <CreatorCardHorizontal {...campaignInfo} />
                  <TitledPanel
                    title={intl.formatMessage(CreatorMessages.ContentIdea)}
                    children={
                      <Typography
                        color="grey.400"
                        fontSize={'14px'}
                        fontWeight={400}
                      >
                        {contentDirections}
                      </Typography>
                    }
                    action={
                      <PatchApplicationEditButton
                        fieldName="proposal"
                        initText={contentDirections}
                      />
                    }
                  />
                  {((applicationData.custom_media_kit || []).length > 0 ||
                    showMediaKit ||
                    applicationData.media_kit_link) && (
                    <TitledPanel
                      title={intl.formatMessage(CreatorMessages.MediaKit)}
                      children={
                        <Stack
                          direction="row"
                          flexWrap={'wrap'}
                          rowGap="16px"
                          columnGap="16px"
                        >
                          {(applicationData.custom_media_kit || []).map(
                            (media, index) => {
                              const attachment = isAttachment(media.name);
                              const isPdf = isPdfFile(media.name);
                              if (isPdf && media?.file) {
                                return (
                                  <Fragment key={index}>
                                    <PdfPreview file={media?.file} />
                                  </Fragment>
                                );
                              }
                              return (
                                <img
                                  key={index}
                                  src={
                                    !attachment ? media?.file : '/file-icon.svg'
                                  }
                                  alt={media.name || 'midia kit'}
                                  title={media.name || 'midia kit'}
                                  style={{
                                    cursor: 'pointer',
                                    ...(attachment
                                      ? { width: '40px' }
                                      : {
                                          maxWidth: isMobileView
                                            ? '98%'
                                            : '49%',
                                          minWidth: '200px',
                                        }),
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    media?.file &&
                                      downloadFile(
                                        media.file,
                                        media.name,
                                        () => {
                                          return;
                                        }
                                      );
                                  }}
                                />
                              );
                            }
                          )}
                          {showMediaKit && <CreatorMediaKit />}
                          {(applicationData.custom_media_kit || []).length <=
                            0 &&
                            !showMediaKit &&
                            applicationData.media_kit_link && (
                              <Stack
                                direction="row"
                                flexWrap={'wrap'}
                                alignItems={'center'}
                              >
                                <LinkItUrl>
                                  <Typography
                                    color="grey.400"
                                    fontSize={'14px'}
                                    fontWeight={400}
                                  >
                                    <Typography
                                      sx={{ mr: '8px' }}
                                      component="span"
                                      fontSize="13px"
                                    >
                                      {intl.formatMessage(
                                        Messages.MediaKitLink
                                      )}
                                    </Typography>
                                    {applicationData.media_kit_link}
                                  </Typography>
                                  {editMode && (
                                    <PatchApplicationEditButton
                                      fieldName="media_kit_link"
                                      initText={applicationData.media_kit_link}
                                    />
                                  )}
                                </LinkItUrl>
                              </Stack>
                            )}
                        </Stack>
                      }
                    />
                  )}
                </Stack>
              </Stack>
            </ApplicationContext.Provider>
            <Box height="32px"></Box>
            <Confirm
              text={intl.formatMessage(
                applied
                  ? CreatorMessages.AreYouSureToWithdrawApplication
                  : CreatorMessages.AreYouSureToSubmitApplication
              )}
              handleConfirm={applied ? withdrawApplication : submitApplication}
              open={open}
              setOpen={setOpen}
            />
          </Stack>
        </PageLayoutWrapper>
      }
    />
  );
}
