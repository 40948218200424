import { Rating, RatingProps, useTheme } from '@mui/material';
import { RatingIcon } from '../assets/imgs/icons';

export default function BenaRating(props: RatingProps) {
  const theme = useTheme();
  const size = { width: '15px', height: '15px' };
  return (
    <Rating
      icon={<RatingIcon sx={{ ...size }} />}
      emptyIcon={
        <RatingIcon fill={theme.palette.neutral[700]} sx={{ ...size }} />
      }
      {...props}
    />
  );
}
