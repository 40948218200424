import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Messages } from "../../localization/Messages";
import {
  useGetUserShortListQuery,
  useDeleteUserShortMutation,
} from "../../redux/benaApi";
import { MutationHandler } from '../../redux/benaApiResultHandler';
import InfiniteScroll from 'react-infinite-scroll-component';
import useInfiniteScroll from '../../utils/useInfiniteScroll';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from "@mui/styles"
import CreativeStoryDialog from "../../features/aigc/CreativeStoryDialog";

import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoaderMessage,
} from '../InfiniteScrollMessages';
import {
  LeftArrowIcon
} from "../../assets/imgs/icons";
import {
  Box,
  Stack,
  Typography,
  Container,
  useTheme,
} from "@mui/material";
import { useBreakpointXl, useBreakpointXs } from "../../utils/useStyleHooks";
import MansonryView from "../layout/MansonryView";
import {
  useTypedSelector
} from "../../redux/store";
import { selectAIGC, setSelectedCreatorId } from '../../features/aigc/AIGCSlice';
import {
  setFilterType,
} from '../../features/search/dynamicSearchSlice';
import { useGetCreatorData } from '../../hooks/creators';
import { AIGCDialogContext } from '../../context/context';

export default function CreatorsUserList(){
  let base64encoded = window.location.search.slice(1);
  let str = atob(base64encoded);
  const nameAndId = useRef( str.split("===") );
  const theme = useTheme();
  
  const dispatch = useDispatch();
  const queryParams = nameAndId.current[0];
  const sizeXl = useBreakpointXl();
  const isMobileView = useBreakpointXs();
  const intl = useIntl();
  const classes = useStyles();
  const [deleteUserShort] = useDeleteUserShortMutation({});
  const {
    combinedData: creatorDataArr,
    setCombinedData: setCreatorDataArr,
    hasMore,
    readMore,
    isFetching,
    isLoading,
  } = useInfiniteScroll<CreatorData>(useGetUserShortListQuery, {
    limit: 12,
    queryParams
  });
  useEffect(()=>{
    dispatch(setFilterType('creator'));
    dispatch(setSelectedCreatorId(''));
  },[])
  const handleInfiniteScrollNext = useCallback(() => {
    readMore();
  }, [readMore]);
  
  // used for favorite between aigc left panel and creator card
  const [favoritedCreators, setFavoritedCreators] = useState<
    FavoritedCreator[]
  >([]);
  // sync data from suggest list
  const [suggestCreatorData, setSuggestCreatorData] = useState<CreatorData[]>(
    []
  );
  const setFavorite = ({ id, favorite }: FavoritedCreator) => {
    const index = favoritedCreators.findIndex((v) => v.id === id);
    if (index >= 0) {
      const newFavorited = [
        ...favoritedCreators.slice(0, index),
        { id, favorite },
        ...favoritedCreators.slice(index + 1),
      ];
      setFavoritedCreators(newFavorited);
    } else {
      setFavoritedCreators([...favoritedCreators, { id, favorite }]);
    }
  };

  const creatorId = useTypedSelector((state) =>
    selectAIGC(state)
  ).selectedCreatorId;

  const {
    creator,
    setCreator,
    refresh: refreshCreatorData,
  } = useGetCreatorData(creatorId);
  useEffect(() => {
    creatorId && refreshCreatorData();
  }, [creatorId]);

  // Open the inquiry pop-up
  const deleteCreateData = async (data:SubassemblyCallbackType) => {
    await MutationHandler({
      intl,
      action: async () => 
        await deleteUserShort({ uid: data.uid as string, queryParams }).unwrap(),
      onSuccess: () => {
        data.callback && data.callback(); // disappear animation callback function;
        setTimeout(() => {
          setCreatorDataArr((prev) => prev.filter((item) => item.id !== data.id));
        }, 300);
      },
    });
  };
  const [prevUrl, setPrevUrl] = useState('');
  return (
    <AIGCDialogContext.Provider
      value={{
        creator,
        creatorData: creatorDataArr.find((v) => v.id === creatorId),
        favoritedCreators,
        notifyFavorite: setFavorite,
        reset: () => {
          setCreator(null);
        },
        prevUrl,
        setPrevUrl,
        notifyCreator: (creator) => {
          setSuggestCreatorData([creator]);
        }
      }}>
      <Box>
        <Container maxWidth="xl" sx={{ mt: { xs: 2, md: 4 } }}>
          <Stack direction="row"
            spacing="2.5rem"
            sx={{ padding: isMobileView ? '0' : sizeXl ? '1rem' : '0' }}
          >
            <Stack direction="row" alignItems="center" onClick={()=>{
              window.history.back();
            }} sx={{
              "&:hover": {
                cursor: "pointer",
                color: theme.palette.primary.main
              }
            }}>
              <LeftArrowIcon sx={{width: 16, height: 14}} stroke="currentColor" />
              <Typography fontWeight={500} fontSize="1.5rem" marginLeft="0.5rem" sx={{
                "&:hover": {
                  color: theme.palette.primary.main
                }
              }}>
                {nameAndId.current[1]}
              </Typography>
            </Stack>
            <Box sx={{ flexGrow: 1 }} ></Box>
          </Stack>
          <Box sx={{
            marginTop: `2rem`
          }}>
            <InfiniteScroll
              dataLength={creatorDataArr.length}
              next={handleInfiniteScrollNext}
              hasMore={hasMore}
              loader={
                <InfiniteScrollLoaderMessage visible={isFetching || isLoading} />
              }
              endMessage={<InfiniteScrollEndMessage />}
              scrollableTarget="infinite-scroll-masonry"
            >
              <MansonryView 
                creators={creatorDataArr}
                total={2}
                loading={isLoading || isFetching}
                deleteCreateData={ deleteCreateData }
                scrollElId={'infinite-scroll-masonry-favorite'} />
            </InfiniteScroll>
          </Box>
          <Box height="10rem"></Box>
        </Container>
      </Box>
      <CreativeStoryDialog />
    </AIGCDialogContext.Provider>
    
  )
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    btnCustom: {
      color: theme.palette.neutral[600],
      padding: '0.5rem 0.75rem',
      height: '2rem',
      borderRadius: '0.5rem',
      border: '1px solid transparent',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8'
      }
    }
  })
);