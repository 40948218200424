import { Box, IconButton, Theme, Typography, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Color from 'color';
import { useIntl } from 'react-intl';
import { ArrowIcon } from '../assets/imgs/icons';
import { useGlobalStyles } from '../assets/styles/style';
import { Messages } from '../localization/Messages';
import { spaceKeyPressed } from '../utils/common';

type BenaStepperProps = {
  totalSteps: number;
  currentStep: number;
  additionalStep?: boolean;
  onStepClick: fnNumberToVoid;
};
export default function BenaStepper({
  totalSteps,
  currentStep,
  additionalStep = false,
  onStepClick,
}: BenaStepperProps) {
  const theme = useTheme();
  const style = useBenaStepperStyles();
  const indicatorWidth = `calc(${
    currentStep * 2
  }*((100% - (${totalSteps} * 2rem))/(2 * ${totalSteps - 1})) +  ${
    currentStep + 1
  } * 2rem)`;
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box className="boxcenterhv" sx={{ justifyContent: 'space-between' }}>
        {Math.random()
          .toString()
          .slice(0, totalSteps)
          .split('')
          .map((_, index) => {
            return (
              <Box
                key={`${_}${index}`}
                className={`boxcenterhv imgIcon32px border-radius-16px ${
                  additionalStep
                    ? style.pastStep
                    : currentStep === index
                    ? style.currentStep
                    : index < currentStep
                    ? style.pastStep
                    : style.futureStep
                }`}
                tabIndex={0}
                onClick={() => onStepClick(index)}
                onKeyDown={(e) => {
                  spaceKeyPressed(e, () => {
                    onStepClick(index);
                  });
                }}
              >
                <Typography sx={{ fontSize: '0.875rem' }} color="base.white">
                  {index + 1}
                </Typography>
              </Box>
            );
          })}
      </Box>
      <Box sx={{ position: 'relative', width: '100%', mt: '0.5rem' }}>
        <Box
          sx={{
            width: '100%',
            height: '0.125rem',
            mt: '0.125rem',
            bgcolor: theme.custom.primary4,
          }}
          className="border-radius-1px"
        ></Box>
        <Box
          sx={{
            width: indicatorWidth,
            height: '0.25rem',
            bgcolor: theme.palette.primary.main,
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          className="border-radius-2px"
        ></Box>
      </Box>
    </Box>
  );
}

export const useBenaStepperStyles = makeStyles((theme: Theme) =>
  createStyles({
    currentStep: {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      border: `0.0625rem solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.contrastText,
      '& > p': {
        fontWeight: 700,
      },
    },
    pastStep: {
      borderRadius: '50%',
      border: `0`,
      backgroundColor: theme.custom.darkGreen,
      color: theme.palette.primary.contrastText,
      '& > p': {
        fontWeight: 700,
      },
    },
    futureStep: {
      borderRadius: '50%',
      border: `0`,
      backgroundColor: theme.custom.primary3,
      color: theme.palette.primary.contrastText,
      '& > p': {
        fontWeight: 700,
      },
    },
  })
);

type BenaNewCampaignStepperProps = {
  totalSteps: number;
  currentStep: number;
  additionalStep?: boolean;
  onStepClick: fnNumberToVoid;
  stepLabels: string[];
  allCompleted?: boolean;
};
// this component is for new campaign creation
export function BenaNewCampaignStepper({
  totalSteps,
  currentStep,
  additionalStep = false,
  stepLabels = [],
  onStepClick,
  allCompleted = false,
}: BenaNewCampaignStepperProps) {
  const theme = useTheme();
  const style = useBenaStepperStyles();
  const indicatorWidth = `calc(${
    (currentStep + 1) * 2
  }*((100% - (${totalSteps} * 12rem))/(2 * ${totalSteps})) +  ${
    currentStep + 1
  } * 12rem)`;
  const indicatorFinishedWidth = `calc(${
    currentStep * 2
  }*((100% - (${totalSteps} * 12rem))/(2 * ${totalSteps})) +  ${currentStep} * 12rem)`;
  const onPastStepClick = (index: number) => {
    if (currentStep > index) {
      onStepClick(index);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        border: `0.0625rem solid ${theme.custom.primary4}`,
        pt: '1rem',
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
      }}
    >
      <Box className="boxcenterhv" sx={{ justifyContent: 'space-around' }}>
        {Math.random()
          .toString()
          .slice(0, totalSteps)
          .split('')
          .map((_, index) => {
            return (
              <Box
                key={`${_}${index}`}
                className="boxcenterhv"
                sx={{ flexDirection: 'column', width: '12rem' }}
              >
                <Box
                  className={`boxcenterhv imgIcon28px border-radius-16px ${
                    currentStep > index ? 'cursorHand' : ''
                  } ${
                    additionalStep
                      ? style.pastStep
                      : currentStep === index
                      ? style.currentStep
                      : index < currentStep
                      ? style.pastStep
                      : style.futureStep
                  }`}
                  tabIndex={0}
                  onClick={() => onPastStepClick(index)}
                  onKeyDown={(e) => {
                    spaceKeyPressed(e, () => {
                      onPastStepClick(index);
                    });
                  }}
                >
                  <Typography sx={{ fontSize: '0.875rem' }}>
                    {index + 1}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    textAlign: 'center',
                    mt: 1.5,
                    color:
                      currentStep === index
                        ? theme.palette.primary.main
                        : index < currentStep
                        ? theme.custom.darkGreen
                        : theme.custom.primary3,
                    cursor: currentStep > index ? 'pointer' : '',
                  }}
                  onClick={() => onPastStepClick(index)}
                  onKeyDown={(e) => {
                    spaceKeyPressed(e, () => {
                      onPastStepClick(index);
                    });
                  }}
                >
                  {stepLabels[index] || ''}
                </Typography>
              </Box>
            );
          })}
      </Box>
      <Box sx={{ position: 'relative', width: '100%', mt: '0.75rem' }}>
        <Box
          sx={{
            width: '100%',
            height: '0.185rem',
            mt: '0.125rem',
            bgcolor: theme.custom.primary4,
          }}
          className="border-radius-1px"
        ></Box>
        <Box
          sx={{
            width: indicatorWidth,
            height: '0.1875rem',
            bgcolor: theme.palette.primary.main,
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          className="border-radius-4px"
        ></Box>
        <Box
          sx={{
            width: indicatorFinishedWidth,
            height: '0.1875rem',
            bgcolor: theme.custom.darkGreen,
            position: 'absolute',
            left: 0,
            top: 0,
          }}
          className="border-radius-4px"
        ></Box>
      </Box>
    </Box>
  );
}

export type BackNextActionButtonsProps = {
  totalSteps: number;
  currentStep: number;
  onNextClick: fnVoidToVoid;
  nextDisabled?: boolean;
  onBackClick: fnNumberToVoid;
};
// this component is used to add to each step's component
export function BackNextActionButtons({
  currentStep,
  totalSteps,
  onNextClick,
  onBackClick,
  nextDisabled,
}: BackNextActionButtonsProps) {
  const intl = useIntl();
  const theme = useTheme();
  const globalStyles = useGlobalStyles();

  const backIconButtonEnabled = currentStep > 0;
  const activeBack = backIconButtonEnabled
    ? theme.palette.primary.main
    : Color(theme.palette.primary.main).fade(0.6).toString();
  const nextIconButtonEnabled = currentStep < totalSteps && !nextDisabled;
  const activeNext = nextIconButtonEnabled
    ? theme.palette.primary.main
    : Color(theme.palette.primary.main).fade(0.6).toString();

  return (
    <Box sx={{ width: '100%', mb: '1.5rem' }} className="boxcenterhv">
      <IconButton
        disabled={!backIconButtonEnabled}
        onClick={() => onBackClick(currentStep - 1)}
      >
        <Box className="boxcenterhv" sx={{ color: activeBack }}>
          <ArrowIcon
            stroke={activeBack}
            sx={{ width: '0.5rem', height: '0.5rem', mr: 1 }}
          />
          <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
            {intl.formatMessage(Messages.Back)}
          </Typography>
        </Box>
      </IconButton>
      <Box sx={{ flexGrow: 1 }}></Box>
      <IconButton disabled={!nextIconButtonEnabled} onClick={onNextClick}>
        <Box className="boxcenterhv" sx={{ color: activeNext }}>
          <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
            {intl.formatMessage(Messages.Next)}
          </Typography>
          <ArrowIcon
            stroke={activeNext}
            className="rotate180"
            sx={{ width: '0.5rem', height: '0.5rem', ml: 1 }}
          />
        </Box>
      </IconButton>
    </Box>
  );
}
