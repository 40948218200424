import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { Box, Button, Stack, Theme, Typography, useTheme } from '@mui/material';
import { Messages } from '../localization/Messages';
import { useIntl } from 'react-intl';
import { ArrowBackRounded } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useGlobalStyles } from '../assets/styles/style';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

type BenaPaginationProps = {
  onChange: (newPage: number) => void;
  count: number;
  currentPage: number;
  loading: boolean;
};
export default function BenaPagination({
  onChange,
  count,
  currentPage,
  loading,
}: BenaPaginationProps) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const { items } = usePagination({
    count,
    page: currentPage,
    siblingCount: 10,
  });

  return (
    <nav className={classes.nav}>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button
                type="button"
                style={{
                  fontWeight: selected ? 'bold' : undefined,
                  color: selected ? theme.custom.lightRed : 'inherit',
                }}
                {...item}
                onClick={() => {
                  !item.disabled &&
                    !selected &&
                    page !== null &&
                    onChange(page);
                }}
                className={` ${selected ? '' : 'hand'} ${classes.page}`}
              >
                {page}
              </button>
            );
          } else {
            const disabled = item.disabled ? classes.disabled : '';
            children = (
              <button
                type="button"
                {...item}
                onClick={() => {
                  if (page === null) return;

                  const p = Math.max(Math.min(count, page), 1);
                  !item.disabled && onChange(p);
                }}
                className={`${classes.prevnext} ${disabled}`}
                disabled={selected}
              >
                {type === 'previous' ? (
                  <Box className="boxcenterhv">
                    <ArrowBackRounded
                      htmlColor={
                        item.disabled || loading
                          ? 'text.disabled'
                          : 'base.black'
                      }
                      sx={{ width: '22px', height: '22px' }}
                    />
                    {intl.formatMessage(Messages.paginationBack)}
                  </Box>
                ) : type === 'next' ? (
                  <Box className="boxcenterhv">
                    {intl.formatMessage(Messages.paginationNext)}
                    <ArrowBackRounded
                      htmlColor={
                        item.disabled || loading
                          ? 'text.disabled'
                          : 'base.black'
                      }
                      sx={{ width: '22px', height: '22px' }}
                      className="rotate180"
                    />
                  </Box>
                ) : (
                  type
                )}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
}

type BenaRefreshPanelProps = {
  onRefresh: fnVoidToVoid;
  padding?: string;
};
export function BenaRefreshPanel({
  onRefresh,
  padding = '',
}: BenaRefreshPanelProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  return (
    <Stack
      width="100%"
      flexDirection={'row'}
      flexWrap={'wrap'}
      marginBottom="4px"
    >
      <Button
        onClick={onRefresh}
        disableRipple
        sx={{
          padding: padding || '8px 0px 8px 0px !important',
          width: '100%',
        }}
        className={gs.buttonSelected}
      >
        <RefreshIcon
          htmlColor={theme.palette.primary.main}
          sx={{ width: '16px', height: '16px', marginRight: '8px' }}
        />
        <Typography fontSize="14px" color="primary.main">
          {intl.formatMessage(Messages.Refresh)}{' '}
        </Typography>
      </Button>
    </Stack>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nav: {
      padding: '1.25rem 0rem',
      display: 'flex',
      justifyContent: 'center',
    },
    page: {
      fontSize: '0.875rem',
      fontWeight: '600',
      color: theme.custom.black2,
      lineHeight: '1.125rem',
      border: '0rem',
      backgroundColor: 'transparent',
      padding: '0rem 0.625rem',
    },
    prevnext: {
      fontSize: '0.875rem',
      fontWeight: '600',
      color: theme.palette.primary.main,
      lineHeight: '1.125rem',
      border: '0rem',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    disabled: {
      color: theme.palette.text.disabled,
      cursor: 'default',
    },
    icon: {
      width: '0.875rem',
      height: '0.5625rem',
      margin: '0rem 0.5rem',
    },
  })
);
