import {
  Box,
  Button,
  IconButton,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { formatDate, spaceKeyPressed } from '../utils/common';
import { useTheme } from '@mui/material';
import { ArrowIcon, CrossIcon, DatePickerIcon } from '../assets/imgs/icons';
import { useGlobalStyles } from '../assets/styles/style';
import { Messages } from '../localization/Messages';
import { useIntl } from 'react-intl';

enum DateChangeType {
  prevYear = 'prevYear',
  prevMonth = 'prevMonth',
  nextMonth = 'nextMonth',
  nextYear = 'nextYear',
}

type HandleDateChange = (changeType: DateChangeType) => void;
const ONEDAY = 24 * 60 * 60 * 1000;
const Format = (d: Date, firstOfMonth = false) =>
  `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${
    firstOfMonth ? '01' : d.getDate()
  }`;

const getDateListOfMonth = (date: Date) => {
  const firstd = new Date(Format(date, true));
  const sunday = new Date(firstd.valueOf() - firstd.getDay() * ONEDAY);
  const month = [] as Array<number>;
  const dates = [] as Array<Date>;
  for (let i = 0; i < 42; i++) {
    const d = new Date(sunday.valueOf() + i * ONEDAY);
    month.push(d.getDate());
    dates.push(d);
  }
  return { month, dates };
};

const isSameDate = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

const DateArray = (
  theme: Theme,
  date: Date,
  toLeft: boolean,
  handler: HandleDateChange,
  selectedDate: Date | undefined,
  chooseDateHandler: ChooseDateHandler,
  selectedDateRange: DateRange,
  buttonBgColor: string,
  buttonColor: string,
  allowDateBeforeToday = false
) => {
  const { month, dates } = getDateListOfMonth(date);
  const startIndex = month.slice(0, 7).findIndex((val) => val === 1);
  const endIndex =
    month.slice(28, month.length).findIndex((val) => val === 1) + 28;
  const today = moment();
  const yesterdayMidnight = new Date(today.year(), today.month(), today.date());
  const months = moment.months();
  const currentDisplayMonth = date.getMonth();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: toLeft ? 'row' : 'row-reverse',
          py: 2,
        }}
      >
        <Box
          sx={{ flexGrow: 0, justifyContent: 'flex-start', mx: '8px' }}
          className="boxcenterhv"
        >
          <Button
            sx={{
              fontSize: 'h6',
              fontWeight: '700',
              cursor: 'pointer',
              bgcolor: buttonBgColor,
              color: theme.palette.grey[700],
              ':hover': {
                bgcolor: buttonBgColor,
                color: theme.palette.grey[700],
              },
              borderRadius: '8px',
              borderColor: theme.palette.neutral[700],
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handler(
                toLeft ? DateChangeType.prevYear : DateChangeType.nextYear
              );
            }}
            variant="outlined"
          >
            {toLeft ? '<<' : '>>'}
          </Button>
        </Box>
        <Box sx={{ flexGrow: 0, justifyContent: 'center', mx: '8px' }}>
          <Button
            sx={{
              fontSize: 'h6',
              fontWeight: '700',
              cursor: 'pointer',
              bgcolor: buttonBgColor,
              color: theme.palette.grey[700],
              ':hover': {
                bgcolor: buttonBgColor,
                color: theme.palette.grey[700],
              },
              borderRadius: '8px',
              borderColor: theme.palette.neutral[700],
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handler(
                toLeft ? DateChangeType.prevMonth : DateChangeType.nextMonth
              );
            }}
            variant="outlined"
          >
            {toLeft ? '<' : '>'}
          </Button>
        </Box>
        <Box
          sx={{
            flexGrow: 2,
            justifyContent: 'center',
            fontWeight: 600,
            fontSize: '14px',
          }}
          className="boxcenterhv"
        >
          {months && months[date.getMonth()]?.toUpperCase()}
        </Box>
        <Box
          sx={{
            flexGrow: 4,
            justifyContent: toLeft ? 'flex-start' : 'flex-end',
            fontWeight: 600,
            fontSize: '14px',
          }}
          className="boxcenterhv"
        >
          {date.getFullYear()}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-around',
          borderBottom: `0.06125rem solid${theme.palette.grey['300']}`,
          marginBottom: '16px',
        })}
      >
        {[0, 1, 2, 3, 4, 5, 6].map((no) => (
          <Box
            sx={(theme) => ({
              width: '2rem',
              height: '2rem',
              display: 'flex',
              flexDirection: 'row',
              fontWeight: 500,
              fontSize: '14px',
            })}
            className="boxcenterhv"
            key={no}
          >
            {moment.weekdaysShort()[no].slice(0, 2)}
          </Box>
        ))}
      </Box>
      {[0, 1, 2, 3, 4, 5]
        .map((val) => val * 7)
        .map((val) => {
          return (
            <Box
              sx={{ display: 'flex', justifyContent: 'space-around' }}
              key={val}
            >
              {[0, 1, 2, 3, 4, 5, 6].map((no) => (
                <Box
                  key={no}
                  sx={(theme) => {
                    const withinMonth =
                      val + no >= startIndex && val + no < endIndex;
                    const isToday = today.isSame(
                      moment(dates[val + no]),
                      'day'
                    );
                    return {
                      width: '2rem',
                      height: '2rem',
                      color: withinMonth
                        ? theme.palette.base.black
                        : theme.palette.grey[600],
                      border:
                        withinMonth && isToday
                          ? `0.0625rem solid ${theme.palette.grey['300']}`
                          : '',
                      borderRadius: withinMonth && isToday ? `50%` : '0',
                    };
                  }}
                  className="boxcenterhv"
                  marginBottom="2px"
                  marginTop="2px"
                >
                  <Box
                    tabIndex={0}
                    sx={(theme) => {
                      const date = dates[val + no];

                      const selected =
                        (selectedDate && isSameDate(selectedDate, date)) ||
                        (selectedDateRange.startDate &&
                          isSameDate(selectedDateRange.startDate, date)) ||
                        (selectedDateRange.endDate &&
                          isSameDate(selectedDateRange.endDate, date));
                      const dateNotClickable =
                        !allowDateBeforeToday &&
                        new Date(dates[val + no]) < yesterdayMidnight;
                      const withinSelectedDateRange =
                        selectedDateRange.startDate &&
                        selectedDateRange.endDate &&
                        date > selectedDateRange.startDate &&
                        date < selectedDateRange.endDate;
                      const isSameMonth =
                        currentDisplayMonth === date.getMonth();

                      return {
                        width: '100%',
                        height: '100%',
                        bgcolor: selected
                          ? theme.palette.primary.main
                          : withinSelectedDateRange
                          ? theme.palette.primary[25]
                          : 'transparent',
                        borderRadius:
                          selected || withinSelectedDateRange ? '5px' : '0',
                        border: selected
                          ? `0.0625rem solid ${theme.palette.primary.main}`
                          : '',
                        cursor: dateNotClickable ? '' : 'pointer',
                        color: dateNotClickable
                          ? theme.palette.grey['300']
                          : selected
                          ? theme.palette.primary.contrastText
                          : 'inherit',
                        fontWeight: 500,
                        fontSize: '14px',
                        visibility: isSameMonth ? 'visible' : 'hidden',
                      };
                    }}
                    className="boxcenterhv"
                    onKeyDown={(e) => {
                      spaceKeyPressed(e, () => {
                        if (
                          !allowDateBeforeToday &&
                          new Date(dates[val + no]) >= yesterdayMidnight
                        ) {
                          chooseDateHandler(dates[val + no]);
                        }
                      });
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (
                        !allowDateBeforeToday &&
                        new Date(dates[val + no]) >= yesterdayMidnight
                      ) {
                        chooseDateHandler(dates[val + no]);
                      }
                    }}
                  >
                    {month[val + no]}
                  </Box>
                </Box>
              ))}
            </Box>
          );
        })}
    </>
  );
};

const DateArrayStartEndSelection = (
  date: Date,
  toLeft: boolean,
  handler: HandleDateChange,
  selectedDate: Date | undefined,
  chooseDateHandler: ChooseDateHandler,
  selectedDateRange: DateRange,
  buttonBgColor: string,
  buttonColor: string,
  allowDateBeforeToday = false,
  showChangeYearButton = false
) => {
  const { month, dates } = getDateListOfMonth(date);
  const startIndex = month.slice(0, 7).findIndex((val) => val === 1);
  const endIndex =
    month.slice(28, month.length).findIndex((val) => val === 1) + 28;
  const today = moment();
  const yesterdayMidnight = new Date(today.year(), today.month(), today.date());
  const months = moment.months();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: toLeft ? 'row' : 'row-reverse',
          py: '0.625rem',
          px: '0.5rem',
          borderBottom: `0.0625rem solid #EBE7FC`,
        }}
      >
        <Box sx={{ flexGrow: 0, justifyContent: 'center' }}>
          {showChangeYearButton && (
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handler(DateChangeType.prevYear);
              }}
            >
              <ArrowIcon sx={{ width: '1rem', height: '1rem' }} />
            </IconButton>
          )}
          <IconButton
            sx={{ ml: 2 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handler(DateChangeType.prevMonth);
            }}
          >
            <ArrowIcon sx={{ width: '0.75rem', height: '0.75rem' }} />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ justifyContent: 'center' }} className="boxcenterhv">
          <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
            {months && months[date.getMonth()]?.toUpperCase()}{' '}
            {date.getFullYear()}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ flexGrow: 0, justifyContent: 'center' }}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handler(DateChangeType.nextMonth);
            }}
          >
            <ArrowIcon
              className="rotate180"
              sx={{ width: '0.75rem', height: '0.75rem' }}
            />
          </IconButton>
          {showChangeYearButton && (
            <IconButton
              sx={{ ml: 2 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handler(DateChangeType.nextYear);
              }}
            >
              <ArrowIcon
                className="rotate180"
                sx={{ width: '1rem', height: '1rem' }}
              />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          mt: '0.25rem',
        }}
      >
        {[0, 1, 2, 3, 4, 5, 6].map((no) => (
          <Box
            sx={{
              width: '2rem',
              height: '2rem',
              display: 'flex',
              flexDirection: 'row',
            }}
            className="boxcenterhv"
            key={no}
          >
            <Typography sx={{ fontWeight: 600, fontSize: '0.75rem' }}>
              {moment.weekdaysShort()[no]?.slice(0, 2)?.toUpperCase()}
            </Typography>
          </Box>
        ))}
      </Box>
      {[0, 1, 2, 3, 4, 5]
        .map((val) => val * 7)
        .map((val) => {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                mb: '0.5rem',
              }}
              key={val}
            >
              {[0, 1, 2, 3, 4, 5, 6].map((no) => (
                <Box
                  key={no}
                  sx={(theme) => ({
                    width: '1.875rem',
                    height: '1.875rem',
                    color:
                      val + no >= startIndex && val + no < endIndex
                        ? theme.palette.base.black
                        : theme.custom.gray,
                  })}
                  className="boxcenterhv"
                >
                  <Box
                    tabIndex={0}
                    sx={(theme) => {
                      const date = dates[val + no];

                      const selected =
                        (selectedDate && isSameDate(selectedDate, date)) ||
                        (selectedDateRange.startDate &&
                          isSameDate(selectedDateRange.startDate, date)) ||
                        (selectedDateRange.endDate &&
                          isSameDate(selectedDateRange.endDate, date));
                      const dateNotClickable =
                        !allowDateBeforeToday &&
                        new Date(dates[val + no]) < yesterdayMidnight;

                      const dateBetweenStartAndEnd =
                        selectedDateRange.startDate &&
                        selectedDateRange.endDate &&
                        date < selectedDateRange.endDate &&
                        date > selectedDateRange.startDate;

                      return {
                        width: '100%',
                        height: '100%',
                        bgcolor: selected
                          ? theme.custom.primary2
                          : dateBetweenStartAndEnd
                          ? theme.custom.primary4
                          : 'transparent',
                        borderRadius:
                          selected || dateBetweenStartAndEnd ? '0.5rem' : '0',
                        cursor: dateNotClickable ? '' : 'pointer',
                        color: selected
                          ? theme.palette.primary.contrastText
                          : dateBetweenStartAndEnd
                          ? theme.palette.primary.main
                          : dateNotClickable
                          ? theme.palette.grey['300']
                          : 'inherit',
                      };
                    }}
                    className="boxcenterhv"
                    onKeyDown={(e) => {
                      spaceKeyPressed(e, () => {
                        if (
                          !allowDateBeforeToday &&
                          new Date(dates[val + no]) >= yesterdayMidnight
                        ) {
                          chooseDateHandler(dates[val + no]);
                        } else if (allowDateBeforeToday) {
                          chooseDateHandler(dates[val + no]);
                        }
                      });
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (
                        !allowDateBeforeToday &&
                        new Date(dates[val + no]) >= yesterdayMidnight
                      ) {
                        chooseDateHandler(dates[val + no]);
                      } else if (allowDateBeforeToday) {
                        chooseDateHandler(dates[val + no]);
                      }
                    }}
                  >
                    <Typography sx={{ fontSize: '0.9375rem' }}>
                      {month[val + no]}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          );
        })}
    </>
  );
};

export type SingleDateSelectedHandler = (dateStart: Date | undefined) => void;

export type DateSelectedHandler = (
  dateStart: Date | undefined,
  dateEnd: Date | undefined
) => void;

export type DateRange = {
  startDate?: Date;
  endDate?: Date;
};

export type DateRangePickerProps = DateRange & {
  onDateSelected: DateSelectedHandler;
  error: boolean;
  onlyUpdateEndDate?: boolean;
  showHeader?: boolean;
};

type ChooseDateHandler = (date: Date) => void;

/**
 * DateRangePicker component has old UI
 */
export default function DateRangePicker({
  startDate,
  endDate,
  onDateSelected,
  error,
  showHeader = true,
}: DateRangePickerProps) {
  const theme = useTheme();
  const intl = useIntl();
  const handleChoseStartDate = (date: Date) => {
    let range = { startDate, endDate };
    if (startDate && endDate) {
      range = { startDate: date, endDate: undefined };
    } else if (
      startDate &&
      date.valueOf() > startDate.valueOf() + 24 * 3600 * 1000
    ) {
      range = { startDate, endDate: date };
    } else if (
      startDate &&
      // date is in format 2023-01-01 08:00:00
      date.valueOf() - 8 * 3600 * 1000 < startDate.valueOf()
    ) {
      range = { startDate: date, endDate: startDate };
    } else {
      range = { startDate: date, endDate: undefined };
    }
    onDateSelected(range.startDate, range.endDate);
  };
  const handleChoseEndDate = (date: Date) => {
    handleChoseStartDate(date);
  };
  const [pickingDate, setPickingDate] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const handleDateChange = (changeType: DateChangeType) => {
    switch (changeType) {
      case DateChangeType.prevYear:
        setDate(new Date(moment(date).add(-1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.prevMonth:
        setDate(new Date(moment(date).add(-1, 'months').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextYear:
        setDate(new Date(moment(date).add(1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextMonth:
        setDate(new Date(moment(date).add(1, 'months').format('YYYY-MM-DD')));
        return;
      default:
        return;
    }
  };
  const hideDatePicking = () => {
    if (pickingDate) {
      setPickingDate(false);
    }
  };
  useEffect(() => {
    window.document.removeEventListener('click', hideDatePicking);
    window.document.addEventListener('click', hideDatePicking);
  });

  const clickHandler = () => {
    setPickingDate(!pickingDate);
  };

  const DateArrayResult = useCallback(
    () =>
      DateArray(
        theme,
        date,
        true,
        handleDateChange,
        startDate,
        handleChoseStartDate,
        {
          startDate,
          endDate,
        },
        theme.palette.base.white,
        theme.palette.primary.main
      ),
    [date, startDate, endDate, handleChoseStartDate, handleDateChange]
  );

  const DateArrayResult2 = useCallback(
    () =>
      DateArray(
        theme,
        moment(date).add(1, 'M').toDate(),
        false,
        handleDateChange,
        endDate,
        handleChoseEndDate,
        { startDate, endDate },
        theme.palette.base.white,
        theme.palette.primary.main
      ),
    [date, endDate, startDate]
  );

  return (
    <Box
      sx={{ position: 'relative' }}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {showHeader && (
        <Box
          sx={(theme) => ({
            width: '100%',
            height: '3.5rem',
            display: 'flex',
            border: `0.0625rem solid ${
              pickingDate
                ? theme.palette.primary.main
                : error
                ? theme.palette.warning[500]
                : theme.custom.primary5
            }`,
            bgcolor: theme.custom.almostWhite,
            px: 3,
          })}
          className={`border-radius-6px`}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Typography
              variant="subtitle1"
              sx={{
                flexGrow: 1,
                textAlign: 'center',
                alignSelf: 'center',
                cursor: 'pointer',

                fontSize: '0.875rem',
              }}
              onClick={clickHandler}
            >
              {startDate
                ? moment(startDate).format('YYYY-MM-DD')
                : intl
                    .formatMessage(Messages.startDate)
                    .replace(':', '')
                    .toUpperCase()}
            </Typography>
            <DatePickerIcon
              sx={(theme) => ({
                flexGrow: 0,
                width: '1rem',
                height: '1rem',
                alignSelf: 'center',
                cursor: 'pointer',
                color: pickingDate ? theme.palette.primary.main : 'inherit',
              })}
              tabIndex={0}
              onKeyDown={(e) => {
                spaceKeyPressed(e, () => clickHandler());
              }}
              onClick={clickHandler}
            />
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: 'center',
                alignSelf: 'center',
                cursor: 'pointer',

                fontSize: '0.875rem',
              }}
              variant="subtitle1"
              onClick={clickHandler}
            >
              {endDate
                ? moment(endDate).format('YYYY-MM-DD')
                : intl
                    .formatMessage(Messages.endDate)
                    .replace(':', '')
                    .toUpperCase()}
            </Typography>
          </Box>
        </Box>
      )}
      {(!showHeader || !!pickingDate) && (
        <Box
          sx={(theme) => ({
            positioin: 'absolute',
            top: '-3.1rem',
            left: '0',
            height: 'auto',
            border: `1px solid ${theme.palette.neutral[700]}`,
            display: 'flex',
            borderRadius: '10px',
            paddingBottom: '16px',
          })}
        >
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {DateArrayResult()}
          </Box>
          <Box
            sx={(theme) => ({
              flexGrow: 0,
              width: '0.0625rem',
              bgcolor: theme.palette.neutral[700],
            })}
          ></Box>
          <Box sx={{ flexGrow: 1 }}>{DateArrayResult2()}</Box>
        </Box>
      )}
    </Box>
  );
}

/**
 *  single date picker  -- Old UI
 */
const SingleDateArray = (
  date: Date,
  toLeft: boolean,
  handler: HandleDateChange,
  selectedDate: Date | undefined,
  chooseDateHandler: ChooseDateHandler,
  selectedDateRange: DateRange,
  buttonBgColor: string,
  buttonColor: string,
  allowDateBeforeToday = false
) => {
  const { month, dates } = getDateListOfMonth(date);
  const startIndex = month.slice(0, 7).findIndex((val) => val === 1);
  const endIndex =
    month.slice(28, month.length).findIndex((val) => val === 1) + 28;
  const today = moment();
  const yesterdayMidnight = new Date(today.year(), today.month(), today.date());
  const months = moment.months();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: toLeft ? 'row' : 'row-reverse',
          py: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box
          sx={{ flexGrow: 0, justifyContent: 'flex-start' }}
          className="boxcenterhv"
        >
          <Button
            sx={{
              fontSize: 'h6',
              cursor: 'pointer',
              bgcolor: buttonBgColor,
              color: buttonColor,
              ':hover': {
                bgcolor: buttonBgColor,
                color: buttonColor,
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handler(
                toLeft ? DateChangeType.prevYear : DateChangeType.nextYear
              );
            }}
          >
            {toLeft ? '<<' : '>>'}
          </Button>
        </Box>
        <Box sx={{ flexGrow: 0, justifyContent: 'center' }}>
          <Button
            sx={{
              fontSize: 'h6',
              cursor: 'pointer',
              bgcolor: buttonBgColor,
              color: buttonColor,
              ':hover': {
                bgcolor: buttonBgColor,
                color: buttonColor,
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handler(
                toLeft ? DateChangeType.prevMonth : DateChangeType.nextMonth
              );
            }}
          >
            {toLeft ? '<' : '>'}
          </Button>
        </Box>
        <Box
          sx={{ flexGrow: 2, justifyContent: 'center' }}
          className="boxcenterhv"
        >
          {months && months[date.getMonth()]?.toUpperCase()}
        </Box>
        <Box
          sx={{
            flexGrow: 4,
            justifyContent: toLeft ? 'flex-start' : 'flex-end',
          }}
          className="boxcenterhv"
        >
          {date.getFullYear()}
        </Box>
        <Box sx={{ flexGrow: 0, justifyContent: 'center' }}>
          <Button
            sx={{
              fontSize: 'h6',
              cursor: 'pointer',
              bgcolor: buttonBgColor,
              color: buttonColor,
              ':hover': {
                bgcolor: buttonBgColor,
                color: buttonColor,
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handler(
                !toLeft ? DateChangeType.prevMonth : DateChangeType.nextMonth
              );
            }}
          >
            {!toLeft ? '<' : '>'}
          </Button>
        </Box>
        <Box
          sx={{ flexGrow: 0, justifyContent: 'flex-start' }}
          className="boxcenterhv"
        >
          <Button
            sx={{
              fontSize: 'h6',
              cursor: 'pointer',
              bgcolor: buttonBgColor,
              color: buttonColor,
              ':hover': {
                bgcolor: buttonBgColor,
                color: buttonColor,
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handler(
                !toLeft ? DateChangeType.prevYear : DateChangeType.nextYear
              );
            }}
          >
            {!toLeft ? '<<' : '>>'}
          </Button>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-around',
          borderBottom: `0.06125rem solid${theme.palette.grey['300']}`,
        })}
      >
        {[0, 1, 2, 3, 4, 5, 6].map((no) => (
          <Box
            sx={(theme) => ({
              width: '2rem',
              height: '2rem',
              display: 'flex',
              flexDirection: 'row',
            })}
            className="boxcenterhv"
            key={no}
          >
            {moment.weekdaysShort()[no]}
          </Box>
        ))}
      </Box>
      {[0, 1, 2, 3, 4, 5]
        .map((val) => val * 7)
        .map((val) => {
          return (
            <Box
              sx={{ display: 'flex', justifyContent: 'space-around' }}
              key={val}
            >
              {[0, 1, 2, 3, 4, 5, 6].map((no) => (
                <Box
                  key={no}
                  sx={(theme) => ({
                    width: '2rem',
                    height: '2rem',
                    color:
                      val + no >= startIndex && val + no < endIndex
                        ? theme.palette.base.black
                        : theme.palette.grey[600],
                    border: today.isSame(moment(dates[val + no]), 'day')
                      ? `0.0625rem solid ${theme.palette.grey['300']}`
                      : '',
                    borderRadius: today.isSame(moment(dates[val + no]), 'day')
                      ? `50%`
                      : '0',
                  })}
                  className="boxcenterhv"
                >
                  <Box
                    tabIndex={0}
                    sx={(theme) => {
                      const date = dates[val + no];

                      const selected =
                        (selectedDate && isSameDate(selectedDate, date)) ||
                        (toLeft &&
                          selectedDateRange.startDate &&
                          isSameDate(selectedDateRange.startDate, date)) ||
                        (!toLeft &&
                          selectedDateRange.endDate &&
                          isSameDate(selectedDateRange.endDate, date));
                      const dateNotClickable =
                        !allowDateBeforeToday &&
                        new Date(dates[val + no]) < yesterdayMidnight;

                      return {
                        width: '100%',
                        height: '100%',
                        bgcolor: selected
                          ? theme.palette.primary.main
                          : 'transparent',
                        borderRadius: selected ? '50%' : '0',
                        border: selected
                          ? `0.0625rem solid ${theme.palette.primary.main}`
                          : '',
                        cursor: dateNotClickable ? '' : 'pointer',
                        color: dateNotClickable
                          ? theme.palette.grey['300']
                          : selected
                          ? theme.palette.primary.contrastText
                          : 'inherit',
                      };
                    }}
                    className="boxcenterhv"
                    onKeyDown={(e) => {
                      spaceKeyPressed(e, () => {
                        if (
                          allowDateBeforeToday ||
                          new Date(dates[val + no]) >= yesterdayMidnight
                        ) {
                          chooseDateHandler(dates[val + no]);
                        }
                      });
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (
                        allowDateBeforeToday ||
                        new Date(dates[val + no]) >= yesterdayMidnight
                      ) {
                        chooseDateHandler(dates[val + no]);
                      }
                    }}
                  >
                    {month[val + no]}
                  </Box>
                </Box>
              ))}
            </Box>
          );
        })}
    </>
  );
};

type SingleDatePickerProp = {
  startDate: Date | undefined;
  onDateSelected: ChooseDateHandler;
};

export function SingleDatePicker({
  startDate,
  onDateSelected,
}: SingleDatePickerProp) {
  const theme = useTheme();
  const handleChoseStartDate = (date: Date) => {
    onDateSelected(date);
  };
  const [pickingDate, setPickingDate] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(startDate || new Date());
  const handleDateChange = (changeType: DateChangeType) => {
    switch (changeType) {
      case DateChangeType.prevYear:
        setDate(new Date(moment(date).add(-1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.prevMonth:
        setDate(new Date(moment(date).add(-1, 'months').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextYear:
        setDate(new Date(moment(date).add(1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextMonth:
        setDate(new Date(moment(date).add(1, 'months').format('YYYY-MM-DD')));
        return;
      default:
        return;
    }
  };
  const hideDatePicking = () => {
    if (pickingDate) {
      setPickingDate(false);
    }
  };
  useEffect(() => {
    window.document.removeEventListener('click', hideDatePicking);
    window.document.addEventListener('click', hideDatePicking);
    return () => window.document.removeEventListener('click', hideDatePicking);
  });

  const DateArrayResult = useCallback(
    () =>
      SingleDateArray(
        date,
        true,
        handleDateChange,
        startDate,
        handleChoseStartDate,
        {
          startDate,
          endDate: new Date('Fri Jul 08 2222 00:00:00 GMT+0800'),
        },
        theme.palette.base.white,
        theme.palette.primary.main,
        true
      ),
    [date, startDate]
  );

  return (
    <Box
      sx={{ position: 'relative' }}
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {pickingDate && (
        <Box
          sx={(theme) => ({
            positioin: 'absolute',
            top: '-3.1rem',
            left: '0',
            width: '100%',
            height: 'auto',
            borderTop: '0',
            display: 'flex',
          })}
        >
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {DateArrayResult()}
          </Box>
        </Box>
      )}
    </Box>
  );
}

/**
 * Date range picker within one month display
 */

export function DateRangePickerModal({
  startDate,
  endDate,
  onDateSelected,
  error,
  onlyUpdateEndDate = false,
}: DateRangePickerProps) {
  const intl = useIntl();
  const theme = useTheme();
  const globalStyles = useGlobalStyles();
  const [initDateRange, setInitDateRange] = useState<{
    startDate?: Date;
    endDate?: Date;
  }>({ startDate, endDate });
  useEffect(() => {
    setInitDateRange({ startDate, endDate });
    startDate && setDate(startDate);
  }, [startDate, endDate]);
  const [startDateSelected, setStartDateSelected] = useState(false);
  const [dateSlected, setDateSelected] = useState<Date | undefined>(undefined);
  const handleChoseStartDate = (date: Date) => {
    if (onlyUpdateEndDate) {
      if (initDateRange.startDate && date > initDateRange.startDate) {
        setDateSelected(date);
        setInitDateRange({ ...initDateRange, endDate: date });
      }
      return;
    }

    let range = {
      startDate: initDateRange.startDate,
      endDate: initDateRange.endDate,
    };
    if (!startDateSelected) {
      range = { startDate: date, endDate: undefined };
      setStartDateSelected(true);
    }
    if (startDateSelected) {
      range = {
        startDate:
          range.startDate && date < range.startDate ? date : range.startDate,
        endDate:
          range.startDate && date < range.startDate ? range.startDate : date,
      };
      setStartDateSelected(false);
    }
    setDateSelected(date);
    setInitDateRange(range);
  };
  const [pickingDate, setPickingDate] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(startDate || new Date());
  const handleDateChange = (changeType: DateChangeType) => {
    switch (changeType) {
      case DateChangeType.prevYear:
        setDate(new Date(moment(date).add(-1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.prevMonth:
        setDate(new Date(moment(date).add(-1, 'months').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextYear:
        setDate(new Date(moment(date).add(1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextMonth:
        setDate(new Date(moment(date).add(1, 'months').format('YYYY-MM-DD')));
        return;
      default:
        return;
    }
  };
  const hideDatePicking = () => {
    if (pickingDate) {
      setPickingDate(false);
      setInitDateRange({ startDate, endDate });
      startDate && setDate(startDate);
    }
  };

  const clickHandler = () => {
    setPickingDate(!pickingDate);
  };

  const DateArrayResult = useCallback(
    () =>
      DateArrayStartEndSelection(
        date,
        true,
        handleDateChange,
        dateSlected,
        handleChoseStartDate,
        { startDate: initDateRange.startDate, endDate: initDateRange.endDate },
        theme.palette.base.white,
        theme.palette.primary.main
      ),
    [initDateRange, dateSlected, date]
  );

  return (
    <>
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '3.5rem',
          display: 'flex',
          border: `0.0625rem solid ${
            pickingDate
              ? theme.palette.primary.main
              : error
              ? theme.palette.warning[500]
              : theme.custom.primary5
          }`,
          bgcolor: theme.custom.almostWhite,
          px: 3,
        })}
        className={`border-radius-6px`}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
          <Typography
            variant="subtitle1"
            sx={{
              flexGrow: 1,
              textAlign: 'left',
              alignSelf: 'center',
              cursor: 'pointer',

              fontSize: '0.875rem',
            }}
            onClick={clickHandler}
          >
            {startDate
              ? moment(startDate).format('YYYY-MM-DD')
              : intl
                  .formatMessage(Messages.startDate)
                  .replace(':', '')
                  .toUpperCase()}
          </Typography>
          <DatePickerIcon
            sx={(theme) => ({
              flexGrow: 0,
              width: '1rem',
              height: '1rem',
              alignSelf: 'center',
              cursor: 'pointer',
              color: pickingDate ? theme.palette.primary.main : 'inherit',
            })}
            tabIndex={0}
            onKeyDown={(e) => {
              spaceKeyPressed(e, () => clickHandler());
            }}
            onClick={clickHandler}
          />
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'right',
              alignSelf: 'center',
              cursor: 'pointer',

              fontSize: '0.875rem',
            }}
            variant="subtitle1"
            onClick={clickHandler}
          >
            {endDate
              ? moment(endDate).format('YYYY-MM-DD')
              : intl
                  .formatMessage(Messages.endDate)
                  .replace(':', '')
                  .toUpperCase()}
          </Typography>
        </Box>
      </Box>
      {pickingDate && (
        <Box
          sx={{ zIndex: 3000 }}
          className={`boxcenterhv ${globalStyles.screenCover}`}
        >
          <Box
            sx={{
              position: 'relative',
              width: '24.125rem',
              height: '36.3125rem',
              borderRadius: '0.5rem',
              bgcolor: theme.palette.base.white,
            }}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Box
              className="boxcenterhv"
              sx={{
                px: '1.5rem',
                py: '1rem',
                borderBottom: `0.0625rem solid ${theme.custom.primary4}`,
              }}
            >
              <Typography sx={{ fontSize: '1.125rem', fontWeight: 600 }}>
                {intl.formatMessage(Messages.chooseStartEndDate)}
              </Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              <IconButton onClick={() => hideDatePicking()}>
                <CrossIcon
                  sx={{ width: '0.875rem', height: '0.875rem', float: 'right' }}
                  className="cursorHand"
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                m: '1.5rem',
                border: `0.0625rem solid ${theme.custom.primary4}`,
                borderRadius: '0.5rem',
              }}
            >
              {DateArrayResult()}
            </Box>
            <Box
              sx={{
                m: '1.5rem',
              }}
              className="boxcenterhv"
            >
              <Stack direction={'column'} sx={{ minWidth: '20%' }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    mb: 1,
                    color: theme.custom.gray,
                  }}
                >
                  {intl.formatMessage(Messages.startDate)}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    color: endDate ? theme.custom.primary2 : 'inherit',
                  }}
                >
                  {initDateRange.startDate
                    ? formatDate(initDateRange.startDate)
                    : '-'}
                </Typography>
              </Stack>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Stack direction={'column'} sx={{ minWidth: '20%' }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    mb: 1,
                    color: theme.custom.gray,
                  }}
                >
                  {intl.formatMessage(Messages.endDate)}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    color: endDate ? theme.custom.primary2 : 'inherit',
                  }}
                >
                  {initDateRange.endDate
                    ? formatDate(initDateRange.endDate)
                    : '-'}
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                m: '1.5rem',
                mt: '0.5rem',
                pt: '1.5rem',
                borderTop: `0.0625rem solid ${theme.custom.primary4}`,
              }}
              className="boxcenterhv"
            >
              <Button
                sx={{
                  width: '8.25rem',
                  height: '2.5rem',
                }}
                className={`border-radius-8px ${globalStyles.buttonCancellation}`}
                onClick={() => {
                  setStartDateSelected(false);
                  setDateSelected(undefined);
                  setPickingDate(false);
                  hideDatePicking();
                }}
              >
                {intl.formatMessage(Messages.cancel)}
              </Button>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Button
                sx={{ width: '8.25rem', height: '2.5rem' }}
                className="border-radius-8px"
                onClick={async () => {
                  initDateRange.startDate &&
                    initDateRange.endDate &&
                    onDateSelected(
                      initDateRange.startDate,
                      initDateRange.endDate
                    );
                  hideDatePicking();
                }}
                disabled={!initDateRange.startDate || !initDateRange.endDate}
              >
                {intl.formatMessage(Messages.save)}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

/**
 * Date picker within one month display
 */

type DatePickerModalProps = {
  startDate?: Date;
  onDateSelected: SingleDateSelectedHandler;
  error?: boolean;
  alignLeft?: boolean;
  borderColor?: string;
  allowDateBeforeToday?: boolean;
};

export function DatePickerModal({
  startDate,
  onDateSelected,
  error,
  alignLeft,
  borderColor,
  allowDateBeforeToday = true,
}: DatePickerModalProps) {
  const intl = useIntl();
  const theme = useTheme();
  const globalStyles = useGlobalStyles();
  const [initDate, setInitDate] = useState(startDate);
  const handleChoseStartDate = (date: Date) => {
    setInitDate(date);
  };
  const [pickingDate, setPickingDate] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(startDate || new Date());
  const handleDateChange = (changeType: DateChangeType) => {
    switch (changeType) {
      case DateChangeType.prevYear:
        setDate(new Date(moment(date).add(-1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.prevMonth:
        setDate(new Date(moment(date).add(-1, 'months').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextYear:
        setDate(new Date(moment(date).add(1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextMonth:
        setDate(new Date(moment(date).add(1, 'months').format('YYYY-MM-DD')));
        return;
      default:
        return;
    }
  };
  const hideDatePicking = () => {
    if (pickingDate) {
      setPickingDate(false);
    }
  };

  const clickHandler = () => {
    setPickingDate(!pickingDate);
  };

  const DateArrayResult = useCallback(
    () =>
      DateArrayStartEndSelection(
        date,
        true,
        handleDateChange,
        initDate,
        handleChoseStartDate,
        { startDate: initDate, endDate: undefined },
        theme.palette.base.white,
        theme.palette.primary.main,
        allowDateBeforeToday,
        true
      ),
    [date, initDate]
  );

  return (
    <>
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '2.75rem',
          display: 'flex',
          border: `0.0625rem solid ${
            pickingDate
              ? theme.palette.primary.main
              : error
              ? theme.palette.warning[500]
              : borderColor || theme.custom.primary5
          }`,
          bgcolor: theme.custom.almostWhite,
          px: 1,
        })}
        className={`border-radius-6px`}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
          <Typography
            variant="subtitle1"
            sx={{
              flexGrow: 1,
              textAlign: alignLeft ? 'left' : 'center',
              alignSelf: 'center',
              cursor: 'pointer',

              fontSize: '0.875rem',
            }}
            onClick={clickHandler}
          >
            {startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
          </Typography>
          <DatePickerIcon
            sx={(theme) => ({
              flexGrow: 0,
              width: '1rem',
              height: '1rem',
              alignSelf: 'center',
              cursor: 'pointer',
              color: pickingDate ? theme.palette.primary.main : 'inherit',
            })}
            tabIndex={0}
            onKeyDown={(e) => {
              spaceKeyPressed(e, () => clickHandler());
            }}
            onClick={clickHandler}
          />
        </Box>
      </Box>
      {pickingDate && (
        <Box
          sx={{ zIndex: 3000 }}
          className={`boxcenterhv ${globalStyles.screenCover}`}
        >
          <Box
            sx={{
              position: 'relative',
              width: '24.125rem',
              height: '32.3125rem',
              borderRadius: '0.5rem',
              bgcolor: theme.palette.base.white,
            }}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Box
              className="boxcenterhv"
              sx={{
                px: '1.5rem',
                py: '1rem',
                borderBottom: `0.0625rem solid ${theme.custom.primary4}`,
              }}
            >
              <Typography sx={{ fontSize: '1.125rem', fontWeight: 600 }}>
                {intl.formatMessage(Messages.chooseDate)}
              </Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              <IconButton onClick={() => hideDatePicking()}>
                <CrossIcon
                  sx={{ width: '0.875rem', height: '0.875rem', float: 'right' }}
                  className="cursorHand"
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                m: '1.5rem',
                border: `0.0625rem solid ${theme.custom.primary4}`,
                borderRadius: '0.5rem',
              }}
            >
              {DateArrayResult()}
            </Box>
            <Box
              sx={{
                m: '1.5rem',
                mt: '0.5rem',
                pt: '1.5rem',
                borderTop: `0.0625rem solid ${theme.custom.primary4}`,
              }}
              className="boxcenterhv"
            >
              <Button
                sx={{
                  width: '8.25rem',
                  height: '2.5rem',
                }}
                className={`border-radius-8px ${globalStyles.buttonCancellation}`}
                onClick={() => {
                  hideDatePicking();
                }}
              >
                {intl.formatMessage(Messages.cancel)}
              </Button>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Button
                sx={{ width: '8.25rem', height: '2.5rem' }}
                className="border-radius-8px"
                onClick={async () => {
                  initDate && onDateSelected(initDate);
                  hideDatePicking();
                }}
              >
                {intl.formatMessage(Messages.save)}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

/**
 * Date range picker within one month display
 * embed UI
 */

export function DateRangePickerEmbed({
  startDate,
  endDate,
  onDateSelected,
  error,
  onError,
}: DateRangePickerProps & { onError: fnBooleanToVoid }) {
  const intl = useIntl();
  const theme = useTheme();
  const [initDateRange, setInitDateRange] = useState<{
    startDate?: Date;
    endDate?: Date;
  }>({ startDate, endDate });
  useEffect(() => {
    setInitDateRange({ startDate, endDate });
  }, [startDate, endDate]);
  const [startDateSelected, setStartDateSelected] = useState(false);
  const [dateSlected, setDateSelected] = useState<Date | undefined>(undefined);
  const handleChoseStartDate = (date: Date) => {
    if (date > new Date()) {
      onError && onError(true);
      return;
    } else {
      onError && onError(false);
    }

    let range = {
      startDate: initDateRange.startDate,
      endDate: initDateRange.endDate,
    };
    if (!startDateSelected) {
      range = { startDate: date, endDate: undefined };
      setStartDateSelected(true);
    }
    if (startDateSelected) {
      range = {
        startDate:
          range.startDate && date < range.startDate ? date : range.startDate,
        endDate:
          range.startDate && date < range.startDate ? range.startDate : date,
      };
      setStartDateSelected(false);
    }
    onDateSelected(range.startDate, range.endDate);
    setDateSelected(date);
    setInitDateRange(range);
  };
  const [date, setDate] = useState<Date>(startDate || new Date());
  const handleDateChange = (changeType: DateChangeType) => {
    switch (changeType) {
      case DateChangeType.prevYear:
        setDate(new Date(moment(date).add(-1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.prevMonth:
        setDate(new Date(moment(date).add(-1, 'months').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextYear:
        setDate(new Date(moment(date).add(1, 'years').format('YYYY-MM-DD')));
        return;
      case DateChangeType.nextMonth:
        setDate(new Date(moment(date).add(1, 'months').format('YYYY-MM-DD')));
        return;
      default:
        return;
    }
  };
  const DateArrayResult = useCallback(
    () =>
      DateArrayStartEndSelection(
        date,
        true,
        handleDateChange,
        dateSlected,
        handleChoseStartDate,
        { startDate: initDateRange.startDate, endDate: initDateRange.endDate },
        theme.palette.base.white,
        theme.palette.primary.main,
        true
      ),
    [initDateRange, dateSlected, date]
  );

  return (
    <Box>
      <Box
        sx={{
          m: '1.5rem',
          my: 0,
          border: `0.0625rem solid ${theme.custom.primary4}`,
          borderRadius: '0.5rem',
        }}
      >
        {DateArrayResult()}
      </Box>
    </Box>
  );
}
