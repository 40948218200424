import {
  Avatar,
  Box,
  capitalize,
  ClickAwayListener,
  Divider,
  IconButton,
  Link,
  OutlinedInput,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ChangeEvent, Fragment, useContext, useMemo } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  CrossIcon,
  Eye2Icon,
  LikeIcon,
  Play2Icon,
  RefreshIcon,
  TimerIcon,
} from '../../assets/imgs/icons';
import { useAIContentCategoryOptions } from '../../config/options';
import { Messages } from '../../localization/Messages';
import {
  useDeleteAIGCVideoMutation,
  usePostCreatorVideoScriptMutation,
  usePostRegenerateVideoScriptMutation,
} from '../../redux/benaApi';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoaderMessage,
} from '../../components/InfiniteScrollMessages';
import { showMessage } from '../../components/ShowMessage';
import { LoadingButton } from '@mui/lab';
import { useQueryAIGCScripts } from '../../hooks/aigc';
import { ScriptQueryInfoPanel, StoryAvatarPanel } from './utils';
import { ArrowBack, ArrowBackRounded } from '@mui/icons-material';
import { useGlobalStyles } from '../../assets/styles/style';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { TitledSelect } from '../../components/TitledSelect';
import { useIsApiLoading } from '../../hooks/account';
import LightTooltip from '../../components/LightTooltip';
import { AIGCDialogContext } from '../../context/context';
import { CreatorCardLeftPanel } from './CreatorCardLeftPanel';
import { useDispatch } from 'react-redux';
import { setSelectedCreatorId } from './AIGCSlice';
import { useNavigate } from 'react-router-dom';
import { staticURLRoot } from '../../route/urls';
import LoadingDotsSvg from '../../components/LoadingDotsSvg';

export default function AIScriptPanel() {
  const intl = useIntl();

  const [handleDelete] = useDeleteAIGCVideoMutation({});

  const [generateScript] = usePostCreatorVideoScriptMutation();
  const [error, setError] = useState<boolean | undefined>(undefined);
  const handlePostGenerateScript = async (
    params: CreatorVideoScriptRequestType
  ) => {
    setError(true);
    await MutationHandler<CreatorVideoScriptResponseType>({
      intl,
      action: async () => {
        return await generateScript(params).unwrap();
      },
      onSuccess: (data) => {
        setError(false);
        setDataArr([data, ...dataArr]);
      },
    });
  };
  const handleStoryDelete = async (id: string) => {
    const index = dataArr.findIndex((v) => v.id === id);
    const ret = await MutationHandler({
      intl,
      action: async () => {
        return await handleDelete({ id: id + '1212' }).unwrap();
      },
      onSuccess: (data) => {
        setDataArr([...dataArr.slice(0, index), ...dataArr.slice(index + 1)]);
      },
    });
    return !ret;
  };

  const { dataArr, setDataArr, queryFn, total, page } = useQueryAIGCScripts();

  const nextPage = () => {
    queryFn(page + 1);
  };

  const [regenerate] = usePostRegenerateVideoScriptMutation();
  const regenerateStory = async (id: string, type: ScriptRegenerationType) => {
    const b = (t: string) => [t, 'all'].includes(type);
    await MutationHandler({
      intl,
      action: async () => {
        return await regenerate({
          id,
          body: {
            script_recommendation: b('script'),
            caption_recommendation: b('caption'),
            trending_music_options: b('music'),
          },
        }).unwrap();
      },
      onSuccess: (data) => {
        const index = dataArr.findIndex((w) => w.id === data.id);
        if (index >= 0) {
          setDataArr([
            ...dataArr.slice(0, index),
            data,
            ...dataArr.slice(index + 1),
          ]);
        }
      },
    });
  };

  return (
    <Stack height="100%">
      <Suggest
        stories={dataArr}
        submitHandler={handlePostGenerateScript}
        error={error}
        refreshStory={(refresh) => {
          refresh && queryFn();
        }}
        onStoryDelete={handleStoryDelete}
        nextPage={nextPage}
        total={total}
        regenerateStory={regenerateStory}
      />
    </Stack>
  );
}

const initContentTypeOptions = [
  'Storytelling',
  'Lip Sync',
  'Product Tutorial',
  'Product Unboxing',
];
const TestData = {
  trending_music_options: [],
  caption_recommendation: '',
  script_recommendation: '',
  performance_forcast: { views: 0, likes: 0, comments: 0, shares: 0 },
};

type SuggestProps = {
  stories: CreatorVideoScriptResponseType[];
  submitHandler: (params: CreatorVideoScriptRequestType) => Promise<void>;
  error?: boolean;
  refreshStory: fnBooleanToVoid;
  onStoryDelete: fnAsyncStringToBoolean;
  total: number;
  nextPage: fnVoidToVoid;
  regenerateStory: (id: string, type: ScriptRegenerationType) => void;
};
function Suggest({
  stories,
  submitHandler,
  error,
  refreshStory,
  onStoryDelete,
  total,
  nextPage,
  regenerateStory,
}: SuggestProps) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contentCategoryOptions = useAIContentCategoryOptions();
  const { creator } = useContext(AIGCDialogContext);
  const creator_id = creator?.id || '';
  const picture = creator?.picture || '';
  const loading = useIsApiLoading();

  const [initState, setInitState] = useState(true);

  const [selectedContentType, setSelectedContentType] = useState('');
  const [selectedContentCategory, setSelectedContentCategory] = useState('');
  const [contentIdea, setContentIdea] = useState('');
  const stabsRef = useRef<HTMLDivElement>(null);

  const contentTypeOptions = initContentTypeOptions.map((v) => ({
    name: v,
    value: v,
  }));

  const [submitting, setSubmitting] = useState(false);
  const isAbleToSubmit = () => {
    return !!selectedContentCategory;
  };

  const generateScriptPost = async () => {
    if (!isAbleToSubmit()) return;
    setSubmitting(true);
    await submitHandler({
      content_category: selectedContentCategory,
      content_idea: contentIdea,
      content_type: selectedContentType,
      product_feature: '',
      creator_id: creator_id,
    });
    setTabSelected('createAStory');
    setSubmitting(false);
  };

  useEffect(() => {
    // handle init state
    if (error === undefined) return;
    // handle if the submission succeed in the first time
    if (initState && !error) setInitState(false);
  }, [error]);

  const contentIdeaChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setContentIdea(text);
  };

  const [tabSelected, setTabSelected] = useState<'createAStory' | 'favorites'>(
    'createAStory'
  );
  const highlight = (tab: string) => {
    return tab === tabSelected
      ? theme.palette.primary['600_100']
      : theme.palette.grey.black_25;
  };

  useEffect(() => {
    if (tabSelected === 'createAStory') {
      refreshStory(true);
    }
  }, [tabSelected]);

  useEffect(() => {
    stabsRef.current?.scrollIntoView?.();
  }, [stories]);

  const disabled = submitting || !isAbleToSubmit() || loading;

  const sortedStorie = useMemo(() => {
    return stories.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
  }, [stories]);

  const storiesPanel =
    stories.length <= 0 ? (
      <Stack
        padding="4rem"
        justifyContent={'center'}
        height="100%"
        width="100%"
      ></Stack>
    ) : (
      sortedStorie.map((story, index) => {
        const {
          caption_recommendation,
          script_recommendation,
          video_length_recommendation,
          trending_music_options,
          performance_forcast,
        } = story.output || TestData;
        const created_at = story.created_at;
        return (
          <Fragment key={story.id}>
            <ScriptQueryInfoPanel story={story} />
            <StoryAvatarPanel
              story={story}
              showRefreshButton={index === stories.length - 1}
              onStoryDelete={onStoryDelete}
              generateScriptPost={generateScriptPost}
            />
            <CreativeStoryCard
              caption_recommendation={caption_recommendation}
              script_recommendation={script_recommendation}
              created_at={created_at}
              initState={initState}
              story={story}
              animated={false}
              recommendedVideoLength={video_length_recommendation}
              trending_music_options={trending_music_options}
              performance_forcast={performance_forcast}
              showRefreshButton={index === stories.length - 1}
            />
            {index < stories.length - 1 && (
              <Divider
                sx={{
                  backgroundColor: theme.custom.primary5,
                  mt: '1.5rem',
                  mb: '1.25rem',
                }}
              />
            )}
          </Fragment>
        );
      })
    );

  const storiesPanelInfiniteScroll = (
    <Box
      id="infinite-reverse-scroll-container"
      sx={{
        height: '540px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
    >
      <InfiniteScroll
        dataLength={sortedStorie.length}
        next={nextPage}
        hasMore={total > sortedStorie.length}
        loader={<InfiniteScrollLoaderMessage />}
        endMessage={<InfiniteScrollEndMessage />}
        scrollableTarget="infinite-reverse-scroll-container"
        inverse={true}
      >
        {storiesPanel}
      </InfiniteScroll>
    </Box>
  );

  const isMobile = useBreakpointXs();
  const [showCreatorProfile, setShowCreatorProfile] = useState(false);
  const toggleCreatorProfile = () => {
    setShowCreatorProfile(!showCreatorProfile);
  };

  const { reset } = useContext(AIGCDialogContext);
  const hidePanel = () => {
    reset();
    dispatch(setSelectedCreatorId(''));
    navigate(staticURLRoot);
  };
  return (
    <Stack
      flexGrow="1"
      height="100%"
      bgcolor={theme.palette.neutral[50]}
      borderRadius={'1rem'}
      position="relative"
    >
      <Stack
        direction="row"
        spacing="1rem"
        bgcolor={theme.palette.base.white}
        padding="9px 24px"
        alignItems={'center'}
        borderRadius={isMobile ? '1rem 1rem 0rem 0rem' : '0rem 1rem 0rem 0rem'}
        borderBottom={'1px solid ' + theme.palette.neutral[600]}
        flexGrow={0}
      >
        {isMobile && (
          <IconButton onClick={hidePanel} className="button_hide_aigc_panel">
            <ArrowBackRounded />
          </IconButton>
        )}
        <Typography fontSize={'1rem'}>
          {capitalize(
            intl.formatMessage(
              tabSelected === 'createAStory'
                ? Messages.AIScriptWriter
                : Messages.favorites
            )
          )}
        </Typography>
        <Box flexGrow="1"></Box>
        <IconButton
          onClick={() => setTabSelected('createAStory')}
          disableRipple
        >
          <Typography
            color={highlight('createAStory')}
            fontSize="14px"
            fontWeight="600"
          >
            {capitalize(intl.formatMessage(Messages.createAStory))}
          </Typography>
        </IconButton>
        <IconButton onClick={() => setTabSelected('favorites')} disableRipple>
          <Typography
            color={highlight('favorites')}
            fontSize="14px"
            fontWeight="600"
          >
            {capitalize(intl.formatMessage(Messages.favorites))}
          </Typography>
        </IconButton>
        {isMobile && picture && (
          <>
            <IconButton onClick={toggleCreatorProfile}>
              <Avatar sx={{ width: 24, height: 24 }} alt={''} src={picture} />
            </IconButton>
            <Box position="relative" component={'span'} sx={{ width: '0px' }}>
              {showCreatorProfile && (
                <ClickAwayListener onClickAway={toggleCreatorProfile}>
                  <Box position="absolute" right={0} top={48}>
                    <CreatorCardLeftPanel />
                  </Box>
                </ClickAwayListener>
              )}
            </Box>
          </>
        )}
      </Stack>
      <Box
        flexGrow={0}
        sx={{ overflowY: 'auto', height: '540px' }}
        id="infinite-scroll-container"
        bgcolor={theme.palette.neutral[50]}
        padding="0px 0px 0px 16px"
      >
        {tabSelected === 'createAStory' && storiesPanelInfiniteScroll}
        {tabSelected === 'favorites' && (
          <AIGCVideosList favorite={true} onStoryDelete={onStoryDelete} 
          generateScriptPost={generateScriptPost} />
        )}
        <Box ref={stabsRef}></Box>
      </Box>
      <form>
        <Stack
          direction="column"
          spacing={'11px'}
          sx={{ mt: '0.25rem' }}
          padding="5px 24px 24px"
          bgcolor={theme.palette.neutral['50_2']}
          borderRadius="0rem 0rem 1rem 0rem"
          borderTop={'1px solid ' + theme.palette.neutral[600]}
        >
          <Stack direction="row" spacing="2rem">
            <TitledSelect
              id={'contentcategory'}
              title={intl.formatMessage(Messages.contentCategory).slice(0, -1)}
              value={selectedContentCategory}
              setValue={setSelectedContentCategory}
              options={contentCategoryOptions}
              required={true}
            />
            <TitledSelect
              id={'contenttype'}
              title={intl.formatMessage(Messages.contentType).slice(0, -1)}
              value={selectedContentType}
              setValue={setSelectedContentType}
              options={contentTypeOptions}
              clearable={true}
            />
          </Stack>
          <Stack
            direction="column"
            width="100%"
            alignItems={'center'}
            justifyContent="center"
            bgcolor={`${theme.palette.base.white_2}`}
            borderRadius="6px"
          >
            <OutlinedInput
              className={classes.textfield}
              minRows={8}
              maxRows={20}
              value={contentIdea}
              onChange={contentIdeaChangeHandler}
              fullWidth
              sx={{ fontSize: '15px' }}
              placeholder={capitalize(
                intl.formatMessage(Messages.describeYourContent)
              )}
              inputProps={{
                sx: { backgroundColor: theme.palette.base.white_2 },
              }}
              endAdornment={
                <>
                  <Stack
                    alignItems={'center'}
                    justifyContent="center"
                    height="100%"
                    direction={'row'}
                    bgcolor={`${theme.palette.base.white_2}`}
                  >
                    {contentIdea && !submitting && (
                      <IconButton
                        onClick={() => {
                          setContentIdea('');
                        }}
                        sx={{ mr: '0.5rem' }}
                      >
                        <CrossIcon
                          stroke={theme.palette.base.black}
                          sx={{
                            width: '0.5rem',
                            height: '0.5rem',
                          }}
                        />
                      </IconButton>
                    )}
                    <LoadingButton
                      disabled={disabled}
                      onClick={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        await generateScriptPost();
                      }}
                      type="submit"
                      disableRipple
                      loading={submitting}
                      sx={{
                        borderRadius: '6px',
                        height: '40px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        '& .MuiLoadingButton-loadingIndicator': {
                          marginRight: '0.5rem',
                        },
                      }}
                      className={
                        submitting ? gs.buttonGenerate : gs.buttonPrimary
                      }
                      loadingPosition="end"
                      endIcon={
                        <ArrowBack
                          sx={{
                            width: '1.125rem',
                            marginRight: '0.125rem',
                          }}
                          className="rotate180"
                        />
                      }
                    >
                      <Typography
                        fontSize={'14px'}
                        fontWeight="600"
                        color={theme.palette.base.whiteonly}
                        textTransform={'none'}
                        whiteSpace="nowrap"
                      >
                        {capitalize(intl.formatMessage(Messages.generate))}
                      </Typography>
                    </LoadingButton>
                  </Stack>
                </>
              }
              disabled={submitting}
            />
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

const TextLengthForShowMore = 300;

type CreativeStoryCardProps = {
  caption_recommendation: string;
  script_recommendation: string;
  created_at: string;
  initState: boolean;
  animated?: boolean;
  story: CreatorVideoScriptResponseType;
  recommendedVideoLength: number;
  trending_music_options: MusicType[];
  performance_forcast: PerformanceForecastType;
  showRefreshButton: boolean;
};
function CreativeStoryCard({
  caption_recommendation,
  script_recommendation,
  created_at,
  initState,
  animated = false,
  story,
  recommendedVideoLength,
  trending_music_options,
  performance_forcast,
  showRefreshButton = false,
}: CreativeStoryCardProps) {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const copyToClipboard = (str: string) => {
    navigator.clipboard.writeText(str);
    showMessage.info(intl.formatMessage(Messages.copied));
  };
  const loading = useIsApiLoading();

  const position = useRef(0);
  const [videoScript, setVideoScript] = useState('');
  const typingScript = () => {
    setTimeout(() => {
      if (!script_recommendation) return;
      let next = script_recommendation.slice(position.current).indexOf('\n\n');
      next = next < 0 || next > 30 ? 30 : next === 0 ? 2 : next;
      if (script_recommendation.length > position.current) {
        position.current += next;
        setVideoScript(script_recommendation.slice(0, position.current));
        setTimeout(() => {
          typingScript();
        }, 50);
      }
    }, 50);
  };
  useEffect(() => {
    if (animated) {
      position.current = 0;
      setVideoScript('');
      setTimeout(() => {
        !!script_recommendation && typingScript();
      });
    } else {
      setVideoScript(script_recommendation);
    }
  }, [script_recommendation]);

  // scroll to the bottom of textarea
  const scriptRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scriptRef.current) {
      scriptRef.current.scrollTop = scriptRef.current.scrollHeight;
    }
  }, [videoScript]);

  const [showmore, setShowmore] = useState(true);

  useEffect(() => {
    setShowmore(videoScript.length > TextLengthForShowMore);
  }, [videoScript]);

  const valid_performance_forcast =
    performance_forcast?.views > 0 || performance_forcast?.likes > 0;

  const forcastData = [
    [
      <Eye2Icon
        stroke={theme.palette.primary[400]}
        title={intl.formatMessage(Messages.views)}
        sx={{ width: '0.875rem', height: '0.875rem' }}
      />,
      performance_forcast?.views || 0,
      intl.formatMessage(Messages.views),
    ],
    [
      <LikeIcon
        stroke={theme.palette.primary[400]}
        title={intl.formatMessage(Messages.likes)}
        sx={{ width: '0.875rem', height: '0.875rem' }}
      />,
      performance_forcast?.likes || 0,
      intl.formatMessage(Messages.likes),
    ],
  ];
  const titles = {
    caption: intl.formatMessage(Messages.TooltipRegenerateCaption),
    script: intl.formatMessage(Messages.TooltipRegenerateScript),
    music: intl.formatMessage(Messages.TooltipRegenerateBackgroundMusic),
  };
  const [currentGenerating, setCurrentGenerating] = useState<RefreshType | ''>(
    ''
  );
  useEffect(() => {
    setCurrentGenerating('');
  }, [story]);

  const scriptsLines = videoScript
    .slice(0, showmore ? TextLengthForShowMore : Number.MAX_SAFE_INTEGER)
    .split('\n');
  const showMoreButton = (
    <Link
      href="#"
      onClick={(e) => {
        e.preventDefault();
        setShowmore(false);
      }}
      underline="none"
      display={'inline'}
    >
      <Typography
        color={theme.palette.primary['600_200']}
        component="span"
        fontWeight={400}
        fontSize="13px"
      >
        ...{intl.formatMessage(Messages.showMore)}
      </Typography>
    </Link>
  );

  const divider = (
    <Divider
      sx={{ borderBottom: '1px solid ' + theme.palette.primary['2_2'] }}
    />
  );
  const dividerFlexVertical = (
    <Divider
      sx={{ borderRight: '1px solid ' + theme.palette.primary['2_2'] }}
      flexItem
      orientation="vertical"
    />
  );

  return (
    <Box
      bgcolor={theme.palette.base.white}
      borderRadius="2px 10px 10px 10px"
      border={`1px solid ${theme.palette.neutral['25_2']}`}
      margin="-2px 24px 16px 54px"
      className="shadowXSM"
    >
      <Stack direction="column" spacing={2} className={classes.card}>
        <Stack direction="row">
          <Stack direction="column" flexGrow={1}>
            <Typography className={`${classes.time} ${classes.blue}`}>
              {capitalize(intl.formatMessage(Messages.videoCaption))}
            </Typography>
            <Box sx={{ height: '0.5rem' }}></Box>
            <Typography
              className={`boxlefthcenterv ${classes.content} ${classes.text}`}
              sx={{ mb: '0.5rem' }}
            >
              {caption_recommendation}
            </Typography>
          </Stack>
        </Stack>
        {divider}
        <Stack direction="row">
          <Stack direction="column" flexGrow={1}>
            <Typography
              className={`${classes.subTitle} boxlefthcenterv ${classes.blue}`}
            >
              {capitalize(intl.formatMessage(Messages.videoScript))}
            </Typography>
            <Box sx={{ height: '0.5rem' }}></Box>
            <Box className={`${classes.content}`} ref={scriptRef}>
              {scriptsLines.map((v, index) => (
                <Fragment key={`${index}_${v}`}>
                  <Typography
                    className={`${classes.content} ${classes.text}`}
                    position="relative"
                  >
                    {v}
                    {showmore &&
                      index + 1 === scriptsLines.length &&
                      showMoreButton}
                  </Typography>
                </Fragment>
              ))}
            </Box>
          </Stack>
        </Stack>
        {divider}
        <Stack
          direction={'row'}
          spacing="1rem"
          justifyContent={'space-between'}
        >
          {valid_performance_forcast && (
            <Stack direction={'column'} flexGrow="1">
              <Typography
                className={`${classes.subTitle} boxlefthcenterv ${classes.blue}`}
                sx={{ mb: 1 }}
              >
                {capitalize(intl.formatMessage(Messages.forecastedViews))}
              </Typography>
              <Stack direction={'column'} flexGrow="1" spacing={0.5}>
                {forcastData.map((item, index) => (
                  <span
                    key={index}
                    className={`${classes.performance} boxlefthcenterv`}
                  >
                    {item[0]} &nbsp;&nbsp;
                    <Typography component="span" className={classes.text}>
                      {intl.formatNumber(item[1] as number, {
                        notation: 'compact',
                        maximumSignificantDigits: 3,
                      })}
                      &nbsp;
                      {item[2]}
                    </Typography>
                  </span>
                ))}
              </Stack>
            </Stack>
          )}
          {valid_performance_forcast && dividerFlexVertical}
          {recommendedVideoLength > 0 && (
            <Stack direction="column" flexGrow={1}>
              <Typography
                className={`${classes.subTitle} boxlefthcenterv ${classes.blue}`}
                sx={{ mb: 1 }}
              >
                {capitalize(
                  intl.formatMessage(Messages.recommendedVideoLength)
                )}
              </Typography>
              <Typography
                className={`${classes.content} ${classes.text} boxlefthcenterv`}
              >
                <TimerIcon
                  sx={{ width: '1.25rem', height: '1.25rem', mr: '0.25rem' }}
                />
                {recommendedVideoLength} {intl.formatMessage(Messages.seconds)}
              </Typography>
            </Stack>
          )}
          {dividerFlexVertical}
          {trending_music_options && (
            <Stack direction="column" flexGrow={1}>
              <Typography
                className={`${classes.subTitle} boxlefthcenterv ${classes.blue}`}
                sx={{ mb: 1 }}
              >
                {capitalize(intl.formatMessage(Messages.backgroundMusic))}
              </Typography>
              <Stack direction={'column'} spacing={0.5}>
                {trending_music_options.map(({ name, url }) => (
                  <Stack direction={'row'} key={name + url} alignItems="center">
                    <Link href={url} target="_blank" underline="none">
                      <Typography className={`boxlefthcenterv ${classes.text}`}>
                        <Play2Icon
                          sx={{ width: '0.875rem', height: '0.875rem' }}
                        />
                        &nbsp;
                        {name}
                      </Typography>
                    </Link>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

type AIGCVideosListProps = {
  favorite: boolean;
  onStoryDelete: fnAsyncStringToBoolean;
  generateScriptPost: ()=>Promise<void>;
};
function AIGCVideosList({ favorite, onStoryDelete, generateScriptPost }: AIGCVideosListProps) {
  const intl = useIntl();
  const theme = useTheme();

  const { dataArr, setDataArr, loading, page, total, queryFn } =
    useQueryAIGCScripts(favorite);

  const _onStoryDelete = async (id: string) => {
    const ret = await onStoryDelete(id);
    if (ret) {
      const index = dataArr.findIndex((v) => v.id === id);
      if (index >= 0) {
        setDataArr([...dataArr.slice(0, index), ...dataArr.slice(index + 1)]);
      }
    }
  };
  const sortedDataArr = useMemo(() => {
    return dataArr.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
  }, [dataArr]);

  const content = (
    <Stack justifyContent={'center'}>
      {dataArr.length > 0 && (
        <Stack height="100%" width="100%">
          {sortedDataArr.map((story, index) => {
            const {
              caption_recommendation,
              script_recommendation,
              video_length_recommendation,
              trending_music_options,
              performance_forcast,
            } = story.output || TestData;
            const created_at = story.created_at;
            return (
              <Fragment key={story.id}>
                <ScriptQueryInfoPanel story={story} />
                <StoryAvatarPanel
                  story={story}
                  showRefreshButton={false}
                  onStoryDelete={_onStoryDelete}
                />
                <CreativeStoryCard
                  caption_recommendation={caption_recommendation}
                  script_recommendation={script_recommendation}
                  created_at={created_at}
                  initState={false}
                  story={story}
                  recommendedVideoLength={video_length_recommendation}
                  trending_music_options={trending_music_options}
                  performance_forcast={performance_forcast}
                  showRefreshButton={false}
                />
                {index < dataArr.length - 1 && (
                  <Divider
                    sx={{
                      backgroundColor: theme.custom.primary5,
                      mt: '1.5rem',
                      mb: '1.25rem',
                    }}
                  />
                )}
              </Fragment>
            );
          })}
        </Stack>
      )}
    </Stack>
  );

  return (
    <InfiniteScroll
      dataLength={dataArr.length}
      next={() => queryFn(page + 1)}
      hasMore={total > dataArr.length}
      loader={<InfiniteScrollLoaderMessage />}
      endMessage={<InfiniteScrollEndMessage />}
      scrollableTarget="infinite-scroll-container"
    >
      {content}
    </InfiniteScroll>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      fontSize: '22px',
      color: theme.custom.black,
    },
    title2: {
      fontWeight: 600,
      fontSize: '16px',
      color: theme.custom.black,
    },
    title3: {
      fontWeight: 600,
      fontSize: '14px',
      color: theme.custom.black,
    },
    subTitle: {
      fontWeight: 600,
      fontSize: '12px',
      color: theme.palette.grey.black_white,
    },
    section: {
      height: '3.875rem',
      backgroundColor: theme.custom.black2,
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      padding: '1rem 1.5rem',
    },
    textfield: {
      borderRadius: '0.5rem !important',
      overflow: 'hidden',
      backgroundColor: `${theme.palette.base.white_2} !important`,
      padding: '0px 4px 0px !important',
      border: '1px solid' + theme.palette.neutral[700],
    },
    content: {
      fontFamily: 'Outfit',
      fontSize: '1rem',
      fontWeight: 400,
    },
    performance: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    card: {
      padding: '1rem',
      borderRadius: '0.5rem',
    },
    panelTransparent: {
      padding: '1rem 1.5rem',
      borderRadius: '0.5rem',
      marginBottom: '1rem',
    },
    creatorMediaPhotoClass: {
      marginTop: '1.375rem',
    },
    iconSize: {
      width: '0.875rem',
      height: '0.875rem',
      marginLeft: '0.5rem',
      cursor: 'pointer',
    },
    iconSize16: {
      width: '1rem',
      height: '1rem',
      marginLeft: '1rem',
      cursor: 'pointer',
    },
    iconSizePerformance: {
      width: '1rem',
      height: '1rem',
    },
    iconSizeMusic: {
      width: '0.875rem',
      height: '0.875rem',
      marginLeft: '0.5rem',
      cursor: 'pointer',
    },
    time: {
      fontSize: '0.75rem',
      fontWeight: '400',
      color: theme.custom.gray,
    },
    videoPanel: {
      height: 'calc(100% - 60px)',
      width: '100%',
      backgroundColor: theme.custom.primary5,
      borderRadius: '0.5rem',
      marginTop: '1.25rem',
    },
    blue: {
      fontSize: '15px',
      fontWeight: 500,
      color: `${theme.palette.primary[700]} !important`,
    },
    text: {
      fontFamily: 'Outfit',
      fontSize: '13px',
      fontWeight: 400,
      color: theme.palette.grey.black_lightgrey,
    },
  })
);
