import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { memo } from "react";
import { makeStyles, createStyles } from "@mui/styles";

function BrandLabel({
  brand,
  color,
  bgColor,
  onClick,
  isGrow,
}: {
  brand: string;
  color: string;
  bgColor: string;
  onClick?: (e:any) => void;
  isGrow?: boolean;
}){
  const classes = useStyles();
  return (
      <Box className={[classes.sponsoredBrand, isGrow ? classes.isGrowsBrand : ""].join(" ")} 
      sx={{
        backgroundColor: bgColor,
        fontWeight: 500,
        color: color,
        cursor: onClick ? "pointer" : "default",
        "&:hover": {
          opacity: onClick ? 0.8 : 1
        }
      }}
      component="span"
      onClick={(e)=>onClick && onClick(e)}
    >{brand}</Box>
  );
}
const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    sponsoredBrand: {
      padding: "0.125rem 0.5rem",
      lineHeight: "1.125rem",
      borderRadius: "4px",
      fontSize: "0.75rem"
    },
    isGrowsBrand:{
      flexGrow: 1,
      textAlign: "center"
    }
  })
);

export default memo(BrandLabel);
