import React, {
  Fragment,
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert, Box, SxProps, Typography, useTheme } from '@mui/material';
import Color from 'color';
import { LinkIt, LinkItUrl } from 'react-linkify-it';
import { staticURLMyProfile } from '../route/urls';

type SeverityType = 'error' | 'warning' | 'info' | 'success';

export const SHOWMESSAGEDURATION = 10000;

export function delay(fn: fnVoidToVoid, ms?: number) {
  setTimeout(fn, ms || SHOWMESSAGEDURATION + 300);
}

// add all URLS in regexp
const URLSREGEXP = /\[URL:staticURLAccount\]/;
const URLS = [
  {
    key: `[URL:staticURLAccount]`,
    url: window.location.origin + staticURLMyProfile,
    label: 'Click here',
  },
];

export const convertToHTML = (str: string) => {
  let arr = str.split('\n');
  for (let i = arr.length - 1; i >= 1; i--) {
    if (arr[i] === '') {
      arr = arr.slice(0, i);
    } else {
      break;
    }
  }
  return (
    <>
      {arr.map((v, index) => (
        <Fragment key={`${index}${v}`}>
          {v}
          <br />
        </Fragment>
      ))}
    </>
  );
};

export const MessageRef: React.RefObject<{
  show: fnVoidToVoid;
  hide: fnVoidToVoid;
  message: React.Dispatch<React.SetStateAction<string>>;
  severity: React.Dispatch<React.SetStateAction<SeverityType>>;
}> = createRef();

const MessageSnackbar = forwardRef((_: any, ref: any) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<SeverityType>('success');
  const show = useCallback(() => setOpen(true), []);
  const hide = useCallback(() => setOpen(false), []);

  useImperativeHandle(ref, () => ({
    show,
    message: setMessage,
    hide,
    severity: setSeverity,
  }));

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      message={message}
      sx={{ position: 'fixed', zIndex: 2147481000 }}
    >
      <Alert
        severity={severity}
        sx={{ width: '100%' }}
        className="boxlefthcenterv"
        onClose={hide}
      >
        <Typography variant="subtitle2">
          <LinkIt
            component={(match: any, key: any) => {
              return (
                <a
                  href={URLS.find((w) => w.key === match)?.url || '#'}
                  key={key}
                >
                  {URLS.find((w) => w.key === match)?.label || ''}
                </a>
              );
            }}
            regex={URLSREGEXP}
          >
            {convertToHTML(message)}
          </LinkIt>
        </Typography>
      </Alert>
    </Snackbar>
  );
});

export const ShowMessageComponent = () => <MessageSnackbar ref={MessageRef} />;

let timer: NodeJS.Timeout;
function Message(
  message: string,
  severity: SeverityType = 'success',
  duration = SHOWMESSAGEDURATION
) {
  
  try {
    MessageRef.current?.hide();
    MessageRef.current?.show();
    MessageRef.current?.message(message);
    MessageRef.current?.severity(severity);
    clearTimeout(timer);
    timer = setTimeout(() => {
      MessageRef.current?.hide();
    }, duration);
  } catch {}
}

function success(message: string) {
  Message(message);
}

function error(message: string) {
  Message(message, 'error');
}

function warning(message: string) {
  Message(message, 'warning');
}

function info(message: string, duration = SHOWMESSAGEDURATION) {
  Message(message, 'info', duration);
}
export const showMessage = { error, warning, info, success };

type ShowSuccessMessageProps = { message: string; sx?: SxProps };
export function ShowSuccessMessage({
  message,
  sx = {},
}: ShowSuccessMessageProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  useEffect(() => {
    let timers = setTimeout(() => {
      setOpen(false);
    }, 6000);
    return () => {
      clearTimeout(timers);
    };
  }, [message]);

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(34);
  useLayoutEffect(() => {
    ref.current && setWidth(ref.current?.getBoundingClientRect().width);
    return () => {
      return;
    };
  }, []);

  return !open || !message ? (
    <></>
  ) : (
    <Box
      ref={ref}
      sx={{
        display: 'inline-block',
        bgcolor: Color(theme.custom.iconAccelerate).fade(0.8).toString(),
        padding: '4px 8px',
        borderRadius: '34px',
        zIndex: 2147481000,
        left: `calc(50% - ${width / 2}px)`,
        maxWidth: '16rem',
        ...sx,
      }}
    >
      <Typography
        sx={{
          color: theme.custom.iconAccelerate,
          fontWeight: 500,
        }}
      >
        <LinkItUrl>{convertToHTML(message)}</LinkItUrl>
      </Typography>
    </Box>
  );
}
