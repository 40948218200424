import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Messages } from '../../localization/Messages';
import GridSelect from '../GridSelect';
import FormattedInput from './FormattedInput';
import throttle from 'lodash/throttle';
import { THROTTLETIMER_MS } from '../../utils/common';

export function moveOptionAllToTheFirst(options: ValueLabelType[]) {
  const retOptions = [] as ValueLabelType[];
  let indexOfAllOption = -1;
  options.forEach((element, index) => {
    if (element.value === 'all') {
      indexOfAllOption = index;
    } else {
      retOptions.push(element);
    }
  });
  if (indexOfAllOption > -1) {
    retOptions.unshift(options[indexOfAllOption]);
  }
  return retOptions;
}

interface SelectCustomSliderProps {
  selectValue: string;
  selectOptions: ValueLabelType[];
  customValue: number[];
  customRange: number[];
  handleChange: (selectValue: string, customValue: number[]) => void;
  heightRem?: string;
  showCustom?: boolean;
  showPercent?: boolean;
}

const SelectCustomSlider = ({
  selectValue,
  selectOptions,
  customValue,
  customRange,
  handleChange,
  heightRem,
  showCustom = true,
  showPercent = false,
}: SelectCustomSliderProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [sliderValue, setSliderValue] = useState(customValue);
  useEffect(() => {
    setSliderValue(customValue);
  }, [customValue]);

  const handleSliderChangeNumber = (
    value: number,
    valueType: 'min' | 'max'
  ) => {
    const newValue = sliderValue.slice();
    if (valueType === 'min') {
      newValue[0] = Math.min(
        Math.min(Math.max(customRange[0], value), customRange[1]),
        newValue[1]
      );
    } else if (valueType === 'max') {
      newValue[1] = Math.max(
        Math.min(Math.max(customRange[0], value), customRange[1]),
        newValue[0]
      );
    }
    setSliderValue(newValue as number[]);
    handleChange(selectValue, newValue as number[]);

    // IMPORTANT NOTE! setForceUpdate is very important to keep state of FormattedInput
    // synchronized with the current component. otherwise, it may bring data inconsistency.
    setForceUpdate(Math.random() * 100000000);
  };
  const throttledHandleSliderChangeNumber = throttle(
    handleSliderChangeNumber,
    THROTTLETIMER_MS,
    {
      leading: true,
    }
  );

  const [forceUpdate, setForceUpdate] = useState(0);
  const newOptions = moveOptionAllToTheFirst(selectOptions).map((option) => ({
    label: option.label,
    value: option.value,
    selected: option.value === selectValue,
  }));
  showCustom &&
    newOptions.push({
      label: intl.formatMessage(Messages.Custom),
      value: 'custom',
      selected: 'custom' === selectValue,
    });

  return (
    <>
      <GridSelect
        multiple={false}
        options={newOptions}
        onSelectItemChange={(value: string) => {
          handleChange(value, sliderValue);
        }}
        heightRem={heightRem}
      />
      {showCustom && selectValue === 'custom' && (
        <>
          <Box
            sx={{
              mb: 1,
              py: 2,
              px: 0.5,
              width: '100%',
              bgcolor: theme.custom.primary5,
            }}
            className="boxcenterhv"
          >
            <FormattedInput
              forceUpdate={forceUpdate}
              initValue={sliderValue[0]}
              handleChange={(value: number) => {
                throttledHandleSliderChangeNumber(value, 'min');
              }}
            />
            <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>
              {showPercent && ' %'}
            </Typography>
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>-</Box>
            <FormattedInput
              forceUpdate={forceUpdate}
              initValue={sliderValue[1]}
              handleChange={(value: number) => {
                throttledHandleSliderChangeNumber(value, 'max');
              }}
              textAlign="end"
            />
            <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>
              {showPercent && ' %'}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default SelectCustomSlider;
