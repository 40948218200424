import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { PostDisplayDurationOptions } from '../config/options';
import { Validator } from '../../utils/common';
import { InputErrorMessage } from '../../components/InputErrorMessage';
import { SuggestIcon } from '../../assets/imgs/icons';
import { showMessage } from '../../components/ShowMessage';
import { FileUploaderSingle } from '../../components/FileUploader';

function PostDurationOptions({
  actionType,
  intl,
  triggerUserAction,
}: ActionParamsType) {
  const postDisplayDurationOptions = PostDisplayDurationOptions(intl);
  return (
    <Stack sx={{ width: '100%', mb: 2.5 }}>
      <RadioGroup
        aria-labelledby="radio-buttons-post-display-duration"
        name="radio-buttons-post-display-duration-group"
        onChange={(e, value) => {
          const select = parseInt(value);
          const found = postDisplayDurationOptions.find(
            (w) => w.value === select
          );
          triggerUserAction?.(actionType, {
            input: found?.label || '',
            select: found?.value || '',
          });
        }}
      >
        {postDisplayDurationOptions.map((v) => (
          <FormControlLabel
            key={v.value}
            value={v.value}
            control={<Radio />}
            label={<Typography sx={{ fontSize: '14px' }}>{v.label}</Typography>}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
}

function PromoteInBio({
  actionType,
  intl,
  triggerUserAction,
}: ActionParamsType) {
  return (
    <Stack sx={{ width: '100%', mb: 2.5 }}>
      <RadioGroup
        aria-labelledby="radio-buttons-right-usage"
        name="radio-buttons-right-usage-group"
        onChange={(e, value) => {
          triggerUserAction?.(actionType, {
            input:
              value === 'true'
                ? intl.formatMessage(CreatorMessages.Agree)
                : intl.formatMessage(CreatorMessages.NotAgree),
            select: value,
          });
        }}
      >
        <FormControlLabel
          value={'true'}
          control={<Radio />}
          label={
            <Typography sx={{ fontSize: '14px' }}>
              {intl.formatMessage(CreatorMessages.Agree)}
            </Typography>
          }
        />
        <FormControlLabel
          value={'false'}
          control={<Radio />}
          label={
            <Typography sx={{ fontSize: '14px' }}>
              {intl.formatMessage(CreatorMessages.NotAgree)}
            </Typography>
          }
        />
      </RadioGroup>
    </Stack>
  );
}

function RightForBrandToUse({
  actionType,
  intl,
  triggerUserAction,
}: ActionParamsType) {
  const [price] = [
    '0',
    (s: string) => {
      return;
    },
  ];
  const inputId = Math.random().toString(36).slice(0, 6);
  return (
    <Stack sx={{ width: '100%' }}>
      <RadioGroup
        aria-labelledby="radio-buttons-right-usage"
        name="radio-buttons-right-usage-group"
        onChange={(e, value) => {
          value === 'false' &&
            triggerUserAction?.(actionType, {
              input: intl.formatMessage(CreatorMessages.NotAgree),
              select: 'false',
            });
        }}
      >
        <FormControlLabel
          value={'true'}
          control={<Radio />}
          label={
            <Typography sx={{ fontSize: '14px' }}>
              {intl.formatMessage(CreatorMessages.Agree)}&nbsp;&nbsp;&nbsp;
              <Typography color="grey.400" component="span" fontSize="12px">
                {intl.formatMessage(CreatorMessages.EnterPriceHint)}
              </Typography>
            </Typography>
          }
        />
        <Box marginLeft="32px">
          <OutlinedInput
            id={inputId}
            fullWidth
            className={`border-radius-6px`}
            startAdornment={'$ '}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const value = e.currentTarget.value;
                if (!Validator.isNumber(value)) {
                  showMessage.error('Invalid number');
                } else {
                  triggerUserAction?.(actionType, {
                    input: `${intl.formatMessage(
                      CreatorMessages.Agree
                    )}, Price is $${value}`,
                    selectWithValue: {
                      select: 'true',
                      value: parseInt(value),
                    },
                  });
                }
              }
            }}
          ></OutlinedInput>
          <InputErrorMessage
            error={!Validator.isNumber(price)}
            inputValueType={'number'}
          />
        </Box>
        <FormControlLabel
          value={'false'}
          control={<Radio />}
          label={
            <Typography sx={{ fontSize: '14px' }}>
              {intl.formatMessage(CreatorMessages.NotAgree)}
            </Typography>
          }
        />
      </RadioGroup>
    </Stack>
  );
}

function MultiQuestionsToUser({
  actionType,
  triggerUserAction,
  theme,
  items,
  setItems,
  actionOptions,
}: ActionParamsType) {
  const questions = actionOptions?.map((w) => w.label) || [];
  const selected = (i: number) => items?.find((w) => w === i);
  const setItem = (i: number) => {
    const arr = items?.filter((w) => w !== i);
    if (arr?.length === items?.length) {
      arr?.push(i);
    }
    setItems?.(arr || []);
  };

  const getAllSelectedItems = () => {
    return questions
      .filter((w, index) => selected(index) || selected(index) === 0)
      .map((w) => `${questions.findIndex((v) => v === w) + 1}.  ${w}`)
      .join('\n');
  };
  return (
    <Stack sx={{ width: '100%' }}>
      {questions.map((q, index) => {
        return (
          <Box key={index}>
            <IconButton
              onClick={() => {
                setItem(index);
              }}
              disableRipple
            >
              <Typography
                color="grey.700"
                fontWeight={600}
                fontSize={'14px'}
                padding="10px 16px"
                sx={{
                  boxShadow: `0px 1px 2px rgba(25, 21, 40, 0.04)`,
                  border: `1px solid ${theme.palette.neutral[700]}`,
                  borderRadius: '8px',
                  display: 'inline-block',
                }}
              >
                {index + 1}. {q}
                {selected(index) || selected(index) === 0 ? (
                  <SuggestIcon
                    sx={{ marginLeft: '16px', width: '16px', height: '16px' }}
                  />
                ) : (
                  ''
                )}
              </Typography>
            </IconButton>
          </Box>
        );
      })}
      <Box padding={'8px'}>
        <Button
          onClick={() => {
            triggerUserAction?.(actionType, {
              input: getAllSelectedItems(),
              multiSelect:
                actionOptions && items?.map((w) => actionOptions?.[w]?.val),
            });
          }}
          sx={{ width: '240px', borderRadius: '8px' }}
          variant="contained"
          disabled={!(items && items.length > 0)}
        >
          Submit
        </Button>
      </Box>
    </Stack>
  );
}

function SingleSelectQuestionsToUser({
  actionType,
  triggerUserAction,
  theme,
  actionOptions,
}: ActionParamsType) {
  const questions = actionOptions?.map((w) => w.label) || [];
  return (
    <Stack sx={{ width: '100%' }}>
      {questions.map((q, index) => {
        return (
          <Box key={index}>
            <IconButton
              onClick={() => {
                triggerUserAction?.(actionType, {
                  input: q,
                  select: actionOptions?.[index].val,
                });
              }}
              disableRipple
            >
              <Typography
                color="grey.700"
                fontWeight={600}
                fontSize={'14px'}
                padding="10px 16px"
                sx={{
                  boxShadow: `0px 1px 2px rgba(25, 21, 40, 0.04)`,
                  border: `1px solid ${theme.palette.neutral[700]}`,
                  borderRadius: '8px',
                  display: 'inline-block',
                }}
              >
                {index + 1}. {q}
              </Typography>
            </IconButton>
          </Box>
        );
      })}
    </Stack>
  );
}

function SuggestionsToUser({
  actionType,
  triggerUserAction,
  theme,
  items,
  setItems,
  actionOptions,
  msg_data,
}: ActionParamsType) {
  const questions = msg_data?.suggestions || [];
  return (
    <Stack
      sx={{ width: '100%' }}
      direction="row"
      flexWrap={'wrap'}
      rowGap={'8px'}
      columnGap={'8px'}
    >
      {questions.map((q, index) => {
        return (
          <Box key={index} component="span">
            <IconButton
              onClick={() => {
                triggerUserAction?.(actionType, {
                  input: q,
                });
              }}
              disableRipple
            >
              <Typography
                color="grey.700"
                fontWeight={600}
                fontSize={'14px'}
                padding="10px 16px"
                sx={{
                  boxShadow: `0px 1px 2px rgba(25, 21, 40, 0.04)`,
                  border: `1px solid ${theme.palette.neutral['700_1']}`,
                  borderRadius: '8px',
                  display: 'inline-block',
                }}
                component="span"
              >
                <li
                  style={{
                    color: theme.palette.base.black,
                    textAlign: 'left',
                    listStyle: 'none',
                    display: 'inline-block',
                  }}
                >
                  {q}
                </li>
              </Typography>
            </IconButton>
          </Box>
        );
      })}
    </Stack>
  );
}

function MediaKitUpload({
  actionType,
  triggerUserAction,
  theme,
  items,
  setItems,
  actionOptions,
  msg_data,
  file,
  setFile,
  campaignId,
  appendBotMsgFromUserAction,
}: ActionParamsType) {
  const onUploadSuccess = (res: FileUploadResponseType[]) => {
    setFile?.({ ...res[0] });
  };

  const onDeleteSuccess = () => {
    setFile?.({
      id: '',
      file: '',
      media_type: '',
    });
  };

  const mediaKitUploadResponseHandler = (data: MessageResponseType) => {
    appendBotMsgFromUserAction?.(data);
  };
  return (
    <Stack
      sx={{ width: '100%' }}
      direction="row"
      flexWrap={'wrap'}
      rowGap={'8px'}
      columnGap={'8px'}
    >
      <FileUploaderSingle
        initImg={''}
        media={{
          id: 'initid',
          file: file?.file || '',
          media_type: 'mediakit',
        }}
        id={String('initid')}
        circleShape={false}
        onFilesUploadSuccess={onUploadSuccess}
        onSingleFileDeleteSuccess={onDeleteSuccess}
        imgSize={14.625}
        imgHeight={14.625}
        mediaType={'mediakit'}
        openGradientCover={false}
        isAvatar={false}
        imageRadius={'0.5rem'}
        sizeLimit={1024 * 1024 * 10}
        campaignId={campaignId}
        mediaKitUploadResponse={mediaKitUploadResponseHandler}
        applicationId={msg_data?.application_id}
      />
    </Stack>
  );
}

export const CampaignApplicationActions = {
  content_keep_duration: PostDurationOptions,
  including_promotion_in_bio: PromoteInBio,
  brand_content_rights: RightForBrandToUse,
  multiple_selection_questions: MultiQuestionsToUser,
  single_selection_questions: SingleSelectQuestionsToUser,
  suggestion: SuggestionsToUser,
  media_kit_upload: MediaKitUpload,
} as { [key: string]: (prop: ActionParamsType) => JSX.Element };

export function mapActionTypeToMessageContentType(
  type?: CreatorRoleScriptActionType
): ChatBotMessageType {
  switch (type) {
    case 'content_keep_duration':
      return 'select';
    case 'including_promotion_in_bio':
      return 'select';
    case 'brand_content_rights':
      return 'select';
    case 'multiple_selection_questions':
      return 'multi_select';
    case 'single_selection_questions':
      return 'select';
    case 'suggestion':
    case 'media_kit_upload':
      return 'text';
    default:
      return 'text';
  }
}
