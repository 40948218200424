import { IntlShape } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { Messages } from '../../localization/Messages';

export function PostDisplayDurationOptions(intl: IntlShape) {
  return [
    { label: intl.formatMessage(CreatorMessages.optionForever), value: 65535 },
    { label: intl.formatMessage(CreatorMessages.optionTwoWeeks), value: 14 },
    { label: intl.formatMessage(CreatorMessages.optionOneMonth), value: 30 },
    {
      label: intl.formatMessage(CreatorMessages.optionThreeMonths),
      value: 90,
    },
    { label: intl.formatMessage(CreatorMessages.optionSixMonths), value: 180 },
    { label: intl.formatMessage(CreatorMessages.optionOneYear), value: 365 },
  ];
}
