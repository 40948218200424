import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Messages } from '../localization/Messages';

export default function Loading() {
  const intl = useIntl();
  return (
    <Typography
      fontSize={'14px'}
      fontWeight="400"
      color="grey.50"
      textAlign="center"
    >
      {intl.formatMessage(Messages.Loading)}...
    </Typography>
  );
}
