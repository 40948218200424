import {
  Box,
  Button,
  InputBase,
  Stack,
  useTheme,
  Theme,
  Chip,
  Link,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { Messages } from '../../localization/Messages';
import { useMemo, useState } from 'react';
import { Validator, convertToFullUrl } from '../../utils/common';
import { useGlobalStyles } from '../../assets/styles/style';
import { createStyles, makeStyles } from '@mui/styles';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { CrossLineIcon } from '../../assets/imgs/icons';

type PromotedBrandsProps = {
  brands: { name: string; link: string }[];
  onDelete?: fnStringToVoid;
};
export function PromotedBrands({ brands, onDelete }: PromotedBrandsProps) {
  const intl = useIntl();
  const theme = useTheme();
  const isMobileView = useBreakpointXs();
  return brands.length > 0 ? (
    <Stack
      rowGap={isMobileView ? '1rem' : '0.5rem'}
      columnGap={isMobileView ? '1rem' : '0.5rem'}
      direction="row"
      flexWrap={'wrap'}
      marginTop="0.5rem"
      component={'span'}
    >
      {brands.map((v, index) => (
        <Chip
          label={
            <>
              <Link
                href={convertToFullUrl(v.link)}
                target="_blank"
                title={v.name}
              >
                {v.name}{' '}
              </Link>
              <Link href={convertToFullUrl(v.link)} target="_blank">
                <Typography
                  component="span"
                  color="primary.600"
                  marginLeft={'8px'}
                  fontSize="12px"
                >
                  {intl.formatMessage(Messages.View)}
                </Typography>
              </Link>
            </>
          }
          key={`${v.name}${index}}`}
          component="span"
          sx={{
            backgroundColor: theme.palette.neutral[500],
            '& .MuiChip-label': {
              color: theme.palette.neutral[950],
              fontWeight: 600,
              fontSize: '12px',
            },
            height: '22px',
          }}
          {...(onDelete && {
            deleteIcon: <CrossLineIcon sx={{ width: '8px', height: '8px' }} />,
            onDelete: () => onDelete(v.name),
          })}
        ></Chip>
      ))}
    </Stack>
  ) : (
    <>-</>
  );
}

type AddPromotedBrandProps = {
  onAdd: (name: string, link: string) => Promise<boolean>;
  placeholderName?: string;
  placeholderLink?: string;
};
export function AddPromotedBrand({
  onAdd,
  placeholderName,
  placeholderLink,
}: AddPromotedBrandProps) {
  const intl = useIntl();
  const gs = useGlobalStyles();
  const style = useStyles();
  const theme = useTheme();
  const [data, setData] = useState({ name: '', link: '' });
  const linkError = useMemo(() => {
    if(!data.link && !data.name){ return "default"; }
    const link = data.link.trim();
    if(!link){ return "enterLinkUrl"; }
    const linkName = data.name.trim();
    if(!linkName){ return "enterLinkDescription"; }
    if(!Validator.isValidUrl(link)){ return "enterValidLinkUrl"; }
    return "success";
    // return !link || !Validator.isValidUrl(link);
  }, [data.link, data.name]);
  return (
    <>
      <Box className={style.contentStyleBox} maxWidth={'460px'}>
        <Stack direction={'row'} height={'40px'}>
          <InputBase
            sx={{ flex: 1, height: '40px' }}
            placeholder={
              placeholderName || intl.formatMessage(Messages.BrandName)
            }
            inputProps={{ 'aria-label': 'brand name' }}
            value={data.name}
            onChange={(e) => setData((d) => ({ ...d, name: e.target.value }))}
          />
          <Box
            sx={{
              width: '1px',
              borderLeft: '1px solid' + theme.palette.neutral[700],
              margin: '6px 8px',
            }}
          ></Box>
          <InputBase
            sx={{ flex: 2, height: '40px' }}
            placeholder={
              placeholderLink || intl.formatMessage(Messages.LinkToTheBestVideo)
            }
            inputProps={{ 'aria-label': 'best video link' }}
            value={data.link}
            onChange={(e) => {
              const link = e.target.value;
              setData((d) => ({ ...d, link }));
            }}
          />
          <Button
            className={gs.buttonPrimary}
            onClick={async () => {
              if (linkError === "success") {
                const ret = await onAdd(data.name.trim(), data.link.trim());
                ret && setData({ name: '', link: '' });
              }
            }}
            disabled={linkError!=="success"}
            sx={{ minWidth: '90px' }}
          >
            {intl.formatMessage(Messages.Add)}
          </Button>
        </Stack>
      </Box>
      {linkError!=="success" && linkError!=="default" && (
        <Typography fontSize={'14px'} color={ theme.custom.lightRed }>
          {intl.formatMessage(Messages[linkError])}
        </Typography>
      )}
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentStyleBox: {
      border: '1px solid ' + theme.palette.neutral[700],
      borderRadius: '8px',
      paddingLeft: '14px',
      backgroundColor: theme.palette.base.white,
    },
    contentStyle: {
      height: '40px',
      '& #contentstyle': {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      '& > p': {
        top: '10px',
      },
    },
  })
);
