import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useState, useEffect, useMemo, Fragment } from 'react';
import { Messages } from '../../localization/Messages';
import { TransitionUp } from '../../components/Transitions';
import { CrossIcon } from '../../assets/imgs/icons';
import { useGlobalStyles } from '../../assets/styles/style';
import { Validator, convertToFullUrl } from '../../utils/common';

const InitLink = { description: '', link: '' };
const InitLiks = [1, 2, 3].map((w) => InitLink);

type EnterPostLinkProps = {
  initText: string;
  initOpen: boolean;
  handlePatch: (text: string) => Promise<void>;
  onCancel: fnVoidToVoid;
};

export function EnterPostLink({
  initText,
  initOpen,
  handlePatch,
  onCancel,
}: EnterPostLinkProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const [open, setOpen] = useState(initOpen);

  useEffect(() => {
    setOpen(initOpen);
  }, [initOpen]);

  const [loading, setLoading] = useState(false);

  const isValidForSave = () => {
    return links.every(
      (w) =>
        (!w.link && !w.description) ||
        (w.description && Validator.isValidUrl(w.link))
    );
  };

  const patchApplication = async (newText: string) => {
    setLoading(true);
    await handlePatch(newText);
    setLoading(false);
  };

  useEffect(() => {
    const arr = genLinks(initText);
    setLinks([...arr, ...InitLiks].slice(0, 3));
  }, [initText]);

  const [links, setLinks] = useState(InitLiks);
  const textForPatch = useMemo(
    () =>
      links
        .filter((w) => w.link && w.description)
        .map((w) => `${w.description} ${w.link}`)
        .join(';'),
    [links]
  );
  const handleChangeLink =
    (index: number, key: keyof typeof InitLink) => (e: any) => {
      const newData = links.map((link, _index) => {
        if (_index === index) {
          return { ...link, [key]: e.target.value };
        }
        return link;
      });
      setLinks([...newData]);
    };

  return (
    <>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={TransitionUp}
          sx={{
            zIndex: theme.zIndex.modal,
            '& .MuiDialog-container > .MuiPaper-root': {
              backgroundColor: theme.palette.neutral[0],
              backgroundImage: 'none !important',
            },
          }}
        >
          <DialogTitle display={'flex'}>
            <Typography fontWeight="600" fontSize="16px" marginTop="8px">
              {intl.formatMessage(Messages.SubmitPostLinks)}
            </Typography>
            <Box flexGrow={1}></Box>
            <IconButton
              onClick={() => {
                setOpen(false);
                onCancel();
              }}
            >
              <CrossIcon
                stroke={theme.palette.base.black}
                sx={{ width: '10px', height: '10px' }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack rowGap={'32px'} minWidth="360px">
              <Stack>
                <Box minHeight="8px"></Box>
                <Stack rowGap="8px">
                  {InitLiks.map((_, index) => {
                    return (
                      <Fragment key={index}>
                        <Typography>
                          {intl.formatMessage(Messages.PostedLinkNoN, {
                            n: index + 1,
                          })}
                        </Typography>
                        <TextField
                          value={links[index].description}
                          onChange={handleChangeLink(index, 'description')}
                          fullWidth
                          placeholder={intl.formatMessage(
                            Messages.PostLinkDescription
                          )}
                        ></TextField>
                        <TextField
                          value={links[index].link}
                          onChange={handleChangeLink(index, 'link')}
                          fullWidth
                          placeholder={intl.formatMessage(Messages.PostLinkUrl)}
                        ></TextField>
                      </Fragment>
                    );
                  })}
                </Stack>
              </Stack>
              <Stack rowGap={'8px'}>
                <Button
                  className={gs.buttonPrimary}
                  onClick={async () => {
                    await patchApplication(textForPatch);
                    setOpen(false);
                    onCancel();
                  }}
                  disabled={loading || !isValidForSave()}
                >
                  {intl.formatMessage(Messages.Confirm)}
                </Button>
                <Button
                  className={`border-radius-8px ${gs.buttonCancellation}`}
                  onClick={() => {
                    setOpen(false);
                    onCancel();
                  }}
                  disabled={loading}
                >
                  {intl.formatMessage(Messages.cancel)}
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

// parse text format
// "link description1 url1; link description 2 url2;"
export const genLinks = (text: string) =>
  text
    .split(';')
    .filter((w) => w)
    .map((link) => {
      let lastSpaceIndex = link.lastIndexOf(' ');
      lastSpaceIndex = lastSpaceIndex < 0 ? 100000000 : lastSpaceIndex;
      return {
        description: link.slice(0, lastSpaceIndex).trim(),
        link: convertToFullUrl(link.slice(lastSpaceIndex).trim()),
      };
    })
    .filter((w) => w.description && w.link);

// prase arry json from backend
export const partLinksString = (text: string) => {
  try {
    if (Array.isArray(text as any) && (text as any).length <= 0) {
      return [];
    }
    if (!text) return [] as PostLinkItemType[];
    const obj = JSON.parse(text) as PostLinkItemType[];
    return obj;
  } catch (e) {
    console.log('post link data format inconsistent!');
    return [] as PostLinkItemType[];
  }
};
