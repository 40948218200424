import { Fragment, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { getPlatformSvgIcon } from '../../utils/getPlatformSvgIcon';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { useIntl } from 'react-intl';
import { useGlobalStyles } from '../../assets/styles/style';
import TitledPanel from './TitledPanel';
import { LinkIt } from 'react-linkify-it';
import { RegExpURLWithoutHTTP } from '../../utils/common';
import { LinkItMatchFn } from '../../utils/linkitfn';
// import { useImage } from 'react-image';
import { Messages } from '../../localization/Messages';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { convertToHTML } from '../../components/ShowMessage';
import { PromotedBrands } from '../features/AddPromotedBrand';

type CampaignCardHorizontalProps = {
  platformIds?: SocialPlatformId[];
  launchedOn?: string;
  logo?: string;
  title?: string;
  image?: string;
  brandInfo?: string;
  pageUrl?: string;
  budget?: string;
  budget_max?: string;
  freeProduct?: boolean;
  campaignPeriod?: string;
  commission: string;
  commissionPercent: number;
  products: NameLinkArray;
};
export default function CampaignCardHorizontal({
  platformIds,
  launchedOn,
  logo,
  title,
  image,
  brandInfo,
  pageUrl,
  budget,
  budget_max,
  freeProduct,
  campaignPeriod,
  commission,
  commissionPercent,
  products,
}: CampaignCardHorizontalProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const [imgLoad, setImgLoad] = useState<boolean>(true);
  
  // const { src } = useImage({
  //   srcList: image || '',
  //   useSuspense: false,
  // });
  const isMobileView = useBreakpointXs();
  return (
    <Stack direction={isMobileView ? 'column' : 'row'}>
      <Card
        sx={{
          minWidth: '224px',
          maxWidth: isMobileView ? '100%' : '224px',
          position: 'relative',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(25, 21, 40, 0.04)',
        }}
      >
        {
          imgLoad && (
            <CardMedia
              component="img" height={isMobileView ? '343' : '224'} image={'/loading.gif'} alt=""
              sx={{ position: 'absolute', top: 0}}
            />
          )
        }
        <CardMedia
          component="img"
          height={isMobileView ? '343' : '224'}
          image={image}
          alt="campaign"
          className="img-blend-color"
          onLoad={() => setImgLoad(false)}
        />
        <Stack
          direction={'row'}
          paddingLeft={'16px'}
          paddingRight={'16px'}
          marginTop={'-32px'}
        >
          <Typography
            color={theme.palette.neutral[700]}
            fontSize={'13px'}
            fontWeight={500}
          >
            {intl.formatMessage(CreatorMessages.LaunchedOn)}
          </Typography>
          <Box flexGrow={1}></Box>
          <Typography
            color={theme.palette.neutral[700]}
            fontSize={'13px'}
            fontWeight={500}
          >
            {launchedOn}
          </Typography>
        </Stack>
        <Stack
          left={0}
          top={0}
          height={'32px'}
          position="absolute"
          borderRadius="10px 0px 10px 0px"
          bgcolor={theme.palette.base.white_3}
          alignItems={'center'}
          justifyContent={'center'}
          border={`1px solid ${theme.palette.neutral[700]}`}
          direction="row"
          columnGap="8px"
          paddingLeft="8px"
          paddingRight="8px"
        >
          {(platformIds || [])
            .map((id) => getPlatformSvgIcon(id))
            .map((svg, index) => (
              <Fragment key={index}>
                {svg?.({
                  fill: theme.palette.grey['200_white'],
                  mask: theme.palette.base['white_black'],
                  sx: { width: '16px', height: '16px' },
                }) || ''}
              </Fragment>
            ))}
        </Stack>
      </Card>
      <CardContent
        sx={{
          paddingTop: isMobileView ? '24px' : '4px',
          paddingBottom: '12px !important',
          ...(isMobileView && { paddingLeft: '0px', paddingRight: '0px' }),
        }}
      >
        <img
          src={logo || '/imgs/default_logo.png'}
          alt="logo"
          style={{ height: '24px', borderRadius: '4px' }}
        />
        <Typography color="grey.700" fontSize={'18px'} fontWeight={500}>
          {title}
        </Typography>
        <TitledPanel
          title={intl.formatMessage(CreatorMessages.BrandIntro)}
          children={
            <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
              {convertToHTML(brandInfo || '')}
            </Typography>
          }
          gap={'6px'}
          marginTop="20px"
        />
        <Stack
          direction={isMobileView ? 'column' : 'row'}
          columnGap={'16px'}
          rowGap={'16px'}
          marginTop="20px"
        >
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.BrandProductPage)}
            children={
              <Stack maxWidth={isMobileView ? '100%' : '300px'}>
                <LinkIt
                  component={(match, key) => LinkItMatchFn(match, key)}
                  regex={RegExpURLWithoutHTTP}
                >
                  <Typography
                    sx={{
                      width: '210px',
                      height: '20px',
                      maxWidth: '210px',
                    }}
                    className="ellipsis"
                    component="span"
                  >
                    {pageUrl}
                  </Typography>
                </LinkIt>
                {products.length > 0 && <PromotedBrands brands={products} />}
              </Stack>
            }
            gap={'6px'}
          />
          <Stack direction={'row'} columnGap="8px">
            {budget && !freeProduct && (
              <Stack alignItems="flex-start">
                <Typography
                  fontSize={budget_max ? '26px' : '50px'}
                  fontWeight="700"
                  color="grey.700"
                  lineHeight={'60px'}
                  marginTop="-6px"
                >
                  {budget}
                  {budget_max ? `~${budget_max}` : ''}
                </Typography>
              </Stack>
            )}
            <TitledPanel
              title={intl.formatMessage(Messages.Payout)}
              children={
                <Stack direction={'row'}>
                  <Typography
                    color="grey.400"
                    fontSize={'14px'}
                    fontWeight={400}
                  >
                    {freeProduct
                      ? intl.formatMessage(CreatorMessages.FreeProduct)
                      : intl.formatMessage(Messages.PerCreator)}
                  </Typography>
                </Stack>
              }
              gap={'6px'}
            />
          </Stack>
          <Stack direction={'row'} columnGap="8px">
            {commission === 'yes' && (
              <>
                <Stack alignItems="flex-start">
                  <Typography
                    fontSize="50px"
                    fontWeight="700"
                    color="grey.700"
                    lineHeight={'60px'}
                    marginTop="-6px"
                  >
                    {commissionPercent}%
                  </Typography>
                </Stack>
                <TitledPanel
                  title={intl.formatMessage(Messages.AffiliateRate)}
                  children={
                    <Stack direction={'row'}>
                      <Typography
                        color="grey.400"
                        fontSize={'14px'}
                        fontWeight={400}
                      >
                        {intl.formatMessage(Messages.SalesCommission)}
                      </Typography>
                    </Stack>
                  }
                  gap={'6px'}
                />
              </>
            )}
          </Stack>
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.CampaignPeriod)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {campaignPeriod}
              </Typography>
            }
            gap={'6px'}
          />
        </Stack>
      </CardContent>
    </Stack>
  );
}
