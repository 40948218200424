import { Stack, Box, Container } from '@mui/material';
import { Billing } from '../features/account/Billing';
import { UserInfo } from '../features/account/UserInfo';
import { useBreakpointXl, useBreakpointXs } from '../utils/useStyleHooks';
import { useIsRoleBrand, useIsRoleCreator } from '../hooks/account';
import { PageMobileNavigationBarAndTitleBar } from '../features/mobileNavbar/navbar';
import { BillingCreator } from '../features/account/BillingCreator';

function UserProfilePage() {
  const sizeXl = useBreakpointXl();
  const isMobileView = useBreakpointXs();
  const isBrand = useIsRoleBrand();
  const isCreator = useIsRoleCreator();

  return (
    <Box>
      <Container maxWidth="xl" sx={{ mt: { xs: 2, md: 4 } }}>
        <Stack
          spacing="2.5rem"
          sx={{ padding: isMobileView ? '0' : sizeXl ? '1rem' : '0' }}
        >
          <UserInfo />
          {isBrand && <Billing />}
          {isCreator && <BillingCreator />}
        </Stack>
        <Box height="10rem"></Box>
      </Container>
      {isCreator && <PageMobileNavigationBarAndTitleBar />}
    </Box>
  );
}

export default UserProfilePage;
