import { Stack, Typography, capitalize, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import CampaignCardHorizontal from '../components/CampaignCardHorizontal';
import CreatorRequirements from '../components/CreatorRequirements';
import TitledPanel from '../components/TitledPanel';
import HoverableImage from '../components/HoverableImage';
import FlexSpaceAroundLeftAligned from '../../components/FlexSpaceBetweenLeftAligned';
import moment from 'moment';
import { Messages } from '../../localization/Messages';
import { useGetUserProfile } from '../../hooks/account';
import { DisplayPreferencesSettingItemsInOrder } from './PreferencesSetting';

type CampaignDetailsPreviewProps = {
  campaign: CampaignResponseType;
};
export default function CampaignDetailsPreview({
  campaign,
}: CampaignDetailsPreviewProps) {
  const intl = useIntl();
  const userProfile = useGetUserProfile();

  const campaignData = campaign;

  const campaignInfo = {
    platformIds: (campaignData.platforms || []) as SocialPlatformId[],
    launchedOn: moment(campaignData.start_date).format(`M/DD/YY`),
    logo: userProfile?.avatar || '',
    title: campaignData.title,
    image: campaignData.cover_img,
    brandInfo: campaignData.brand_intro,
    pageUrl: campaignData.brand_link,
    campaignPeriod: `${moment(campaignData.start_date).format(
      `M/DD/YY`
    )} - ${moment(campaignData.end_date).format(`M/DD/YY`)}`,
    budget: `$${campaignData.budget_amount}`,
    budget_max: campaignData.budget_amount_max
      ? `$${campaignData.budget_amount_max}`
      : undefined,
    freeProduct: campaignData.budget_mode === 'product_exchange_only',
    commission: campaignData.commission,
    commissionPercent: campaignData.commission_percent,
    products: campaignData.products || [],
  };

  const tags = DisplayPreferencesSettingItemsInOrder(
    campaignData.creator_requirements
  );
  const contentDirections = campaignData.content_direction;
  const caption = campaignData.captions;
  const videoDuration = campaignData.video_duration;
  const hashtags = campaignData.hashtags.join(', ');
  const otherAccounts = campaignData.tag_accounts.join(', ');

  return (
    <Stack padding={'20px 24px'}>
      <Stack rowGap={'27px'}>
        <CampaignCardHorizontal {...campaignInfo} />
        <CreatorRequirements tags={tags} />
        <TitledPanel
          title={intl.formatMessage(CreatorMessages.ContentDirection)}
          children={
            <Typography
              sx={{ whiteSpace: 'pre-line', lineHeight: '26px' }}
              color="grey.400"
              fontSize={'14px'}
              fontWeight={400}
            >
              {contentDirections}
            </Typography>
          }
        />
        <TitledPanel
          title={intl.formatMessage(CreatorMessages.Caption)}
          children={
            <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
              {caption}
            </Typography>
          }
        />
        <Stack
          direction="row"
          columnGap={'128px'}
          rowGap={'16px'}
          flexWrap={'wrap'}
          alignItems={'flex-start'}
        >
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.VideoDurations)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {videoDuration} second{videoDuration > 1 ? 's' : ''}
              </Typography>
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Hashtags)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {hashtags || '-'}
              </Typography>
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.OtherAccounts)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {otherAccounts || '-'}
              </Typography>
            }
          />
        </Stack>
        <TitledPanel
          title={intl.formatMessage(CreatorMessages.Files)}
          children={
            <Stack
              direction="row"
              flexWrap={'wrap'}
              rowGap="10px"
              columnGap={'10px'}
            >
              {(campaign.visual_medias || []).length > 0 ? (
                <>
                  {(campaign.visual_medias || []).map((w, index) => (
                    <HoverableImage
                      fileLink={w.file}
                      fileName={w.name || ''}
                      fileType={w.content_type || ''}
                      key={`${w.id}${index}`}
                    />
                  ))}
                  <FlexSpaceAroundLeftAligned width={'224px'} />
                </>
              ) : (
                <Typography fontWeight={400} color="grey.400" fontSize={'14px'}>
                  {intl.formatMessage(Messages.NoFileFound)}
                </Typography>
              )}
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
}
