import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DynamicSearch from './DynamicSearch';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoaderMessage,
} from '../../components/InfiniteScrollMessages';

interface SearchResultsProps {
  dataLength: number;
  hasMore: boolean;
  handleInfiniteScrollNext: () => void;
  cardView: React.ReactNode;
  tableView?: React.ReactNode;
  refreshResults: () => void;
  isFetchingOrLoading: boolean;
  resultArray?: DynamicSearchResultArrayType;
  updateBatchFavorites?: (list: string[]) => void;
}

function shouldComponentUpdate(
  prevProps: SearchResultsProps,
  nextProps: SearchResultsProps
) {
  return false;
  // return (
  //   prevProps.dataLength === nextProps.dataLength &&
  //   prevProps.hasMore === nextProps.hasMore &&
  //   prevProps.remoteTotal === nextProps.remoteTotal
  // );
}

const SearchResults = React.memo(
  ({
    dataLength,
    hasMore,
    handleInfiniteScrollNext,
    cardView,
    isFetchingOrLoading,
    updateBatchFavorites
  }: SearchResultsProps) => {
    return (
      <Box>
        <Box>
          <Container maxWidth="xl">
            <DynamicSearch
              dataLength={dataLength}
              loading={isFetchingOrLoading}
              updateBatchFavorites={updateBatchFavorites}
            />
          </Container>
        </Box>

        <Box>
          <Container
            maxWidth="xl"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'stretch',
              margin: 0,
            }}
          ></Container>
        </Box>
        <Box>
          <InfiniteScroll
            dataLength={dataLength}
            next={handleInfiniteScrollNext}
            hasMore={hasMore}
            loader={
              <InfiniteScrollLoaderMessage visible={isFetchingOrLoading} />
            }
            endMessage={<InfiniteScrollEndMessage />}
            scrollableTarget="infinite-scroll-masonry"
          >
            <Box sx={{ py: 4 }}>{cardView}</Box>
          </InfiniteScroll>
        </Box>
      </Box>
    );
  },
  shouldComponentUpdate
);

export default SearchResults;
