/**
 *
 * @param width set the width exact same as the flex item
 * @returns
 */
export default function FlexSpaceAroundLeftAligned({
  width,
}: {
  width: string;
}) {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((value) => (
        <i key={value} style={{ width }}></i>
      ))}
    </>
  );
}
