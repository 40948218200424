import { Box } from '@mui/material';

type ImageFilterProps = {
  title: string;
  src: string;
  alt: string;
  className: string;
  style: React.CSSProperties;
  classWidthHeight: string;
};

export default function ImageFilter({
  title,
  src,
  alt,
  className,
  style,
  classWidthHeight,
}: ImageFilterProps) {
  return (
    <Box
      title={title}
      sx={{ overflow: 'hidden', position: 'relative', ...style }}
      className={`${classWidthHeight} `}
    >
      <Box
        className={classWidthHeight}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: '0.25',
          ...{ width: style.width, height: style.height },
        }}
        zIndex={2}
      ></Box>
      <img
        src={src}
        alt={alt}
        className={className}
        style={{ width: style.width, height: style.height }}
      />
    </Box>
  );
}
