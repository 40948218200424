import {
  Box, Stack, CardMedia, Typography, Button, Container,
  FormGroup, FormControlLabel, Checkbox
} from "@mui/material"
import {
  chromePluginURL
} from "../route/urls";
import { useIntl } from "react-intl";
import { Messages } from "../localization/Messages";
import { useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { envResolverAPIURL } from "../env";

type UninstallPluginType = "aboutExperience" | "regardingPayment" | "other"

type Feedback = {
  id: string;
  name: "uninstallPrompt7" | "uninstallPrompt8" | "uninstallPrompt9" | "uninstallPromptA" | "uninstallPromptB" | "uninstallPromptC"
  key: string;
}

const feedbackList:Feedback[] = [
  { id: "1", name: "uninstallPrompt7", key: "aboutExperience-uninstallPrompt7"},
  { id: "2", name: "uninstallPrompt8", key: "aboutExperience-uninstallPrompt8"},
  { id: "3", name: "uninstallPrompt9", key: "aboutExperience-uninstallPrompt9"},
]
const feedbackList2:Feedback[] = [
  { id: "7", name: "uninstallPromptA", key: "regardingPayment-uninstallPromptA"},
  { id: "8", name: "uninstallPromptB", key: "regardingPayment-uninstallPromptB"}
]
const feedbackList3:Feedback[] = [
  { id: "10", name: "uninstallPromptC", key: "other-uninstallPromptC"},
]

function GroupList({
  itemList, handleChange, checkList, titleName
}: {
  itemList: Feedback[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkList: string[];
  titleName: string;
}){
  const intl = useIntl();
  return (
    <Box sx={{ padding: '1rem 0 0 0' }}>
      <Typography variant="h6" sx={{
        fontWeight: '600', fontSize: "1rem", lineHeight: "2.125rem",
        color: "rgba(0,0,0,.32)", textAlign: "center"
      }}>{titleName}</Typography>
      <FormGroup sx={{gap:"0.5rem"}}>
        {
          itemList.map((item, index) => (
            <FormControlLabel
              sx={{
                ml: 0, mr: 0, padding: "5px 0", border: "1px solid #EBEAF0", borderRadius: "1rem",
                '&:hover': { bgcolor: "rgba(79,89,102,.08)" }
              }}
              key={index}
              control={
                <Checkbox size="small" checked={checkList.includes(item.key)}
                  sx={{
                    color: "#6841ea",
                    '& .MuiSvgIcon-root': { fontSize: 16 },
                    '&.Mui-checked': { color: "#6841ea" }
                  }}
                  onChange={()=>{
                    handleChange({ target: { value: item.key } } as any);
                  }}
                />
              }
              label={
                <Box color="#242424">{intl.formatMessage(Messages[item.name])}</Box>
              }
            />
          ))
        }
      </FormGroup>
    </Box>
    
  )
};

function InstallBena(){
  const intl = useIntl();
  const btnStyle = { height: '2.75rem', borderRadius: '0.75rem' };
  return (
    <Button variant="outlined" color="primary"
      sx={{ marginLeft: 'auto', ...btnStyle,
        "&:hover": { backgroundColor: 'transparent', borderColor: 'rgb(105, 76, 230)' }
      }}
      onClick={() =>{ window.open(chromePluginURL, '_blank'); }}
    >
      🚀 {intl.formatMessage(Messages.ContinueToUse)}
    </Button>
  )
}

export default function UnloadingFeedback() {
  const intl = useIntl();
  const [checkList, setCheckList] = useState<string[]>([]);
  const [mxWidth, setMxWidth] = useState('400px');
  const [stepDown, setStepDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const btnStyle = { height: '2.75rem', borderRadius: '0.75rem' };

  const updateCheckList = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if(checkList.includes(value)){
      setCheckList(checkList.filter(item => item !== value));
    }else{
      setCheckList([...checkList, value]);
    }
  }

  const submitForms = () => {
    setIsLoading(true);
    const apiUrl = envResolverAPIURL() + "plugin/uninstall"
    const auto = window.location.search.split("?key=")[1];
    const header = new Headers();
    header.append("Content-Type", "application/json");
    if(auto){
      header.append("Authorization", `Bearer ${auto}`);
    };
    let bodyContent:{
      "aboutExperience": string[];
      "regardingPayment": string[];
      "other": string[];
    } = {
      "aboutExperience": [],
      "regardingPayment": [],
      "other": []
    };
    checkList.forEach((item) => {
      const [key, value] = item.split("-");
      bodyContent[key as UninstallPluginType].push(
        Messages[ value as Feedback["name"] ].defaultMessage
      );
    });
    fetch(apiUrl, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(bodyContent)
    }).finally(() => {
      setIsLoading(false);
      setStepDown(true);
    });
  }

  const stepFeedback = (
    <Box>
      <Box sx={{ borderBottom: '0.0625rem solid #EBEAF0', position: "sticky", top:"0", bgcolor:"#FFF", zIndex: 1 }}>
        <Container maxWidth={'xl'}>
          <Stack direction="row" alignItems="center" height="5.5rem">
            <CardMedia component="img" sx={{ width: 'auto', height: '1.375rem' }} image="/logo.svg" />
            <InstallBena />
          </Stack>
        </Container>
      </Box>
      <Typography sx={{
        fontWeight: '600', fontSize: '1.5rem', lineHeight: '1.5',
        textAlign: 'center', padding: '1.125rem 0 0 0'
      }}>
        {intl.formatMessage(Messages.uninstallPrompt5)}
      </Typography>
      
      <Typography sx={{ fontWeight: '600', fontSize: '1.5rem', lineHeight: '1.5', textAlign: 'center' }}>
        🥺 {intl.formatMessage(Messages.uninstallPrompt6)}
      </Typography>
      <Stack maxWidth={mxWidth} sx={{ margin: '0 auto' }}>
        <GroupList itemList={feedbackList} checkList={checkList} titleName="About Experience"
          handleChange={(e)=> updateCheckList(e) }/>
        <GroupList itemList={feedbackList2} checkList={checkList} titleName="Regarding payment"
          handleChange={(e)=> updateCheckList(e) }/>
        <GroupList itemList={feedbackList3} checkList={checkList} titleName="other"
          handleChange={(e)=> updateCheckList(e) }/>
      </Stack>
      <Stack bgcolor="#FFF" padding="1rem 0 1.125rem 0" sx={{
        position: "sticky", bottom: "0", justifyContent: "center", alignItems: "center"
      }}>
        <LoadingButton variant="contained" color="primary" sx={{ width: '25rem', ...btnStyle }}
          disabled={checkList.length === 0} loading={ isLoading }
          onClick={submitForms}>
          {intl.formatMessage(Messages.submitUninstall)}
        </LoadingButton>
      </Stack>
    </Box>
  );

  const fontStyles = { fontSize:"24px",fontWeight: "600",lineHeight:"34px" };
  const fontStyles2 = { fontSize:"14px",fontWeight: "500",lineHeight:"22px" };

  const uninstallSuccess = useMemo(() => (
    <Box textAlign="center">
      <Box sx={{ borderBottom: '0.0625rem solid #EBEAF0', position: "sticky", top:"0", bgcolor:"#FFF", zIndex: 1 }}>
        <Container maxWidth={'xl'}>
          <Stack direction="row" alignItems="center" height="5.5rem">
            <CardMedia component="img" sx={{ width: 'auto', height: '1.375rem' }} image="/logo.svg" />
          </Stack>
        </Container>
      </Box>
      <Typography sx={fontStyles} pt="12.25rem">{intl.formatMessage(Messages.uninstallPrompt1)}</Typography>
      <Typography sx={fontStyles}>{intl.formatMessage(Messages.uninstallPrompt2)}</Typography>
      <Typography sx={fontStyles2} mt="30px">{intl.formatMessage(Messages.uninstallPrompt3)}</Typography>
      <Typography sx={fontStyles2} mb="30px">{intl.formatMessage(Messages.uninstallPrompt4)}</Typography>
      <InstallBena />
    </Box>
  ),[]);

  return stepDown ? uninstallSuccess : stepFeedback;
}