import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../../redux/store';

export interface LanguageType {
  languageIndex: number;
}

const initialState: LanguageType = {
  languageIndex: 1,
};

const slice = createSlice({
  name: 'Language',
  initialState,
  reducers: {
    setLang(state, action: PayloadAction<LanguageType>) {
      state.languageIndex = action.payload.languageIndex;
    },
  },
});

export const { setLang } = slice.actions;

export const selectLanguage = createSelector(
  [(state: RootState) => state.Language],
  (Language) => Language
);

export default slice.reducer;
