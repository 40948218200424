import { defineMessages } from 'react-intl';
export const CampaignMessages = defineMessages({
  CampaignCreation: {
    id: 'CampaignCreation',
    defaultMessage: 'Campaign Creation',
  },
  OnWhichSocialNetwork: {
    id: 'OnWhichSocialNetwork',
    defaultMessage:
      'On which social network do you want to launch your campaign?',
  },
  WhatsYourCampaignTitle: {
    id: 'WhatsYourCampaignTitle',
    defaultMessage: 'What’s your campaign title?',
  },
  WhatsYourBrandIntro: {
    id: 'WhatsYourBrandIntro',
    defaultMessage: 'What’s your brand Intro?',
  },
  WhatsYourCampaignCover: {
    id: 'WhatsYourCampaignCover',
    defaultMessage: 'What’s your campaign cover image?',
  },
  WhatsYourBrandProductPage: {
    id: 'WhatsYourBrandProductPage',
    defaultMessage: 'What’s your Brand/Product page? Please provide a link.',
  },
  SetABudegt: {
    id: 'SetABudegt',
    defaultMessage: 'Set a budget or Product Exchange only?',
  },
  DoYouOfferAffiliate: {
    id: 'DoYouOfferAffiliate',
    defaultMessage: 'Do you offer Affiliate Commission for creators?',
  },
  ProductExchangeOnly: {
    id: 'ProductExchangeOnly',
    defaultMessage: 'Product Exchange Only',
  },
  SetBudget: {
    id: 'SetBudget',
    defaultMessage: 'Set Budget per Creator',
  },
  MaximumBudget: {
    id: 'MaximumBudget',
    defaultMessage: 'Maximum Budget',
  },
  MinimumBudget: {
    id: 'MinimumBudget',
    defaultMessage: 'Fixed / Minimum Budget',
  },
  MaximumBudgetOptional: {
    id: 'MaximumBudgetOptional',
    defaultMessage: 'Maximum Budget (Optional)',
  },
  MaximumBudgetShouldNotLessEqualThanMin: {
    id: 'MaximumBudgetShouldNotLessEqualThanMin',
    defaultMessage: 'Maximum budget should not less equal than the min value.',
  },
  WhatsYourCampaignDateRange: {
    id: 'WhatsYourCampaignDateRange',
    defaultMessage:
      'What’s your campaign date range? Set the start and the end dates of the campaign.',
  },
  WhatsYourCreatorRequirements: {
    id: 'WhatsYourCreatorRequirements',
    defaultMessage: 'What’s your creator requirements? Choose a couple.',
  },
  WhatsYourDesiredContent: {
    id: 'WhatsYourDesiredContent',
    defaultMessage:
      'What content topics do you want to pursue? Edit AI suggested ones if needed.',
  },
  DoYouHaveSomeCaptions: {
    id: 'DoYouHaveSomeCaptions',
    defaultMessage: 'Do you have some captions in your mind?',
  },
  WhatsYourDesiredVideoDuration: {
    id: 'WhatsYourDesiredVideoDuration',
    defaultMessage: 'What’s your desired video duration?(in seconds)',
  },
  DoYouHaveSpecificHashtags: {
    id: 'DoYouHaveSpecificHashtags',
    defaultMessage: 'Do you have specific hashtags to use? (Separate by comma)',
  },
  ShouldCreatorsTag: {
    id: 'ShouldCreatorsTag',
    defaultMessage: 'Should creators tag other accounts?(Separate by comma)',
  },
  DoYouHaveOtherFiles: {
    id: 'DoYouHaveOtherFiles',
    defaultMessage:
      'Do you have other files to provide such as: documents, photos, videos, songs?',
  },
  DropYourFiles: {
    id: 'DropYourFiles',
    defaultMessage: 'Drop your Files Here',
  },
  BrowseFiles: {
    id: 'BrowseFiles',
    defaultMessage: 'Browse Files',
  },
  fromYourComputer: {
    id: 'fromYourComputer',
    defaultMessage: 'from your Computer',
  },
  EngagementRate: {
    id: 'EngagementRate',
    defaultMessage: 'Engagement Rate',
  },
  NumberOfFollowers: {
    id: 'NumberOfFollowers',
    defaultMessage: 'Number of Followers',
  },
  AverageViews: {
    id: 'AverageViews',
    defaultMessage: 'Average Views',
  },
  AudienceLocation: {
    id: 'AudienceLocation',
    defaultMessage: 'Audience Location',
  },
  AudienceGender: {
    id: 'AudienceGender',
    defaultMessage: 'Audience Gender',
  },
  AudienceAge: {
    id: 'AudienceAge',
    defaultMessage: 'Audience Age',
  },
  PreviewCampaign: {
    id: 'PreviewCampaign',
    defaultMessage: 'Preview Campaign',
  },
  PublishCampaign: {
    id: 'PublishCampaign',
    defaultMessage: 'Publish Campaign',
  },
  BackToEdit: {
    id: 'BackToEdit',
    defaultMessage: 'Back to Edit',
  },
  EditCampaign: {
    id: 'EditCampaign',
    defaultMessage: 'Edit Campaign',
  },
  SaveCampaign: {
    id: 'SaveCampaign',
    defaultMessage: 'Save Campaign',
  },
  ViewPublicCampaignUrl: {
    id: 'ViewPublicCampaignUrl',
    defaultMessage: 'View Public URL',
  },
  ActiveCampaignCanExtend: {
    id: 'ActiveCampaignCanExtend',
    defaultMessage: 'Active campaign can extend the end date only!',
  },
  AreYouSureToDeleteTheCampaign: {
    id: 'AreYouSureToDeleteTheCampaign',
    defaultMessage: 'Are you sure to delete this campaign?',
  },
  generateAiContentBasedOnCampaignTitleAndBrandIntro: {
    id: 'generateAiContentBasedOnCampaignTitleAndBrandIntro',
    defaultMessage: 'Generate content from campaign title and brand intro.',
  },
  MarkComplete: {
    id: 'MarkComplete',
    defaultMessage: 'Mark Complete',
  },
  AreYouSureToCompleteTheCampaign: {
    id: 'AreYouSureToCompleteTheCampaign',
    defaultMessage:
      'Are you sure to mark this campaign as complete? A completed campaign means there will be not any new cooperations from creators, and the existing cooperations will not be able to edit again. Please make sure you finished all the cooperations with creators.',
  },
  contractStatusAccepted: {
    id: 'contractStatusAccepted',
    defaultMessage: 'Accepted',
  },
  contractStatusOffered: {
    id: 'contractStatusOffered',
    defaultMessage: 'Offered',
  },
  contractStatusFinished: {
    id: 'contractStatusFinished',
    defaultMessage: 'Finished',
  },
});
