import { defineMessages } from 'react-intl';
export const CountriesMessages = defineMessages({
  // START COUNTRY MSGS
  Afghanistan: {
    id: 'Afghanistan',
    defaultMessage: 'Afghanistan',
  },
  'Åland Islands': {
    id: 'Åland Islands',
    defaultMessage: 'Åland Islands',
  },
  Albania: {
    id: 'Albania',
    defaultMessage: 'Albania',
  },
  Algeria: {
    id: 'Algeria',
    defaultMessage: 'Algeria',
  },
  'American Samoa': {
    id: 'American Samoa',
    defaultMessage: 'American Samoa',
  },
  AndorrA: {
    id: 'AndorrA',
    defaultMessage: 'AndorrA',
  },
  Angola: {
    id: 'Angola',
    defaultMessage: 'Angola',
  },
  Anguilla: {
    id: 'Anguilla',
    defaultMessage: 'Anguilla',
  },
  Antarctica: {
    id: 'Antarctica',
    defaultMessage: 'Antarctica',
  },
  'Antigua and Barbuda': {
    id: 'Antigua and Barbuda',
    defaultMessage: 'Antigua and Barbuda',
  },
  Argentina: {
    id: 'Argentina',
    defaultMessage: 'Argentina',
  },
  Armenia: {
    id: 'Armenia',
    defaultMessage: 'Armenia',
  },
  Aruba: {
    id: 'Aruba',
    defaultMessage: 'Aruba',
  },
  Australia: {
    id: 'Australia',
    defaultMessage: 'Australia',
  },
  Austria: {
    id: 'Austria',
    defaultMessage: 'Austria',
  },
  Azerbaijan: {
    id: 'Azerbaijan',
    defaultMessage: 'Azerbaijan',
  },
  Bahamas: {
    id: 'Bahamas',
    defaultMessage: 'Bahamas',
  },
  Bahrain: {
    id: 'Bahrain',
    defaultMessage: 'Bahrain',
  },
  Bangladesh: {
    id: 'Bangladesh',
    defaultMessage: 'Bangladesh',
  },
  Barbados: {
    id: 'Barbados',
    defaultMessage: 'Barbados',
  },
  Belarus: {
    id: 'Belarus',
    defaultMessage: 'Belarus',
  },
  Belgium: {
    id: 'Belgium',
    defaultMessage: 'Belgium',
  },
  Belize: {
    id: 'Belize',
    defaultMessage: 'Belize',
  },
  Benin: {
    id: 'Benin',
    defaultMessage: 'Benin',
  },
  Bermuda: {
    id: 'Bermuda',
    defaultMessage: 'Bermuda',
  },
  Bhutan: {
    id: 'Bhutan',
    defaultMessage: 'Bhutan',
  },
  Bolivia: {
    id: 'Bolivia',
    defaultMessage: 'Bolivia',
  },
  'Bosnia and Herzegovina': {
    id: 'Bosnia and Herzegovina',
    defaultMessage: 'Bosnia and Herzegovina',
  },
  Botswana: {
    id: 'Botswana',
    defaultMessage: 'Botswana',
  },
  'Bouvet Island': {
    id: 'Bouvet Island',
    defaultMessage: 'Bouvet Island',
  },
  Brazil: {
    id: 'Brazil',
    defaultMessage: 'Brazil',
  },
  'British Indian Ocean Territory': {
    id: 'British Indian Ocean Territory',
    defaultMessage: 'British Indian Ocean Territory',
  },
  'Brunei Darussalam': {
    id: 'Brunei Darussalam',
    defaultMessage: 'Brunei Darussalam',
  },
  Bulgaria: {
    id: 'Bulgaria',
    defaultMessage: 'Bulgaria',
  },
  'Burkina Faso': {
    id: 'Burkina Faso',
    defaultMessage: 'Burkina Faso',
  },
  Burundi: {
    id: 'Burundi',
    defaultMessage: 'Burundi',
  },
  Cambodia: {
    id: 'Cambodia',
    defaultMessage: 'Cambodia',
  },
  Cameroon: {
    id: 'Cameroon',
    defaultMessage: 'Cameroon',
  },
  Canada: {
    id: 'Canada',
    defaultMessage: 'Canada',
  },
  'Cape Verde': {
    id: 'Cape Verde',
    defaultMessage: 'Cape Verde',
  },
  'Cayman Islands': {
    id: 'Cayman Islands',
    defaultMessage: 'Cayman Islands',
  },
  'Central African Republic': {
    id: 'Central African Republic',
    defaultMessage: 'Central African Republic',
  },
  Chad: {
    id: 'Chad',
    defaultMessage: 'Chad',
  },
  Chile: {
    id: 'Chile',
    defaultMessage: 'Chile',
  },
  China: {
    id: 'China',
    defaultMessage: 'China',
  },
  'Christmas Island': {
    id: 'Christmas Island',
    defaultMessage: 'Christmas Island',
  },
  'Cocos (Keeling) Islands': {
    id: 'Cocos (Keeling) Islands',
    defaultMessage: 'Cocos (Keeling) Islands',
  },
  Colombia: {
    id: 'Colombia',
    defaultMessage: 'Colombia',
  },
  Comoros: {
    id: 'Comoros',
    defaultMessage: 'Comoros',
  },
  Congo: {
    id: 'Congo',
    defaultMessage: 'Congo',
  },
  'Congo, The Democratic Republic of the': {
    id: 'Congo, The Democratic Republic of the',
    defaultMessage: 'Congo, The Democratic Republic of the',
  },
  'Cook Islands': {
    id: 'Cook Islands',
    defaultMessage: 'Cook Islands',
  },
  'Costa Rica': {
    id: 'Costa Rica',
    defaultMessage: 'Costa Rica',
  },
  "Cote D'Ivoire": {
    id: "Cote D'Ivoire",
    defaultMessage: "Cote D'Ivoire",
  },
  Croatia: {
    id: 'Croatia',
    defaultMessage: 'Croatia',
  },
  Cuba: {
    id: 'Cuba',
    defaultMessage: 'Cuba',
  },
  Cyprus: {
    id: 'Cyprus',
    defaultMessage: 'Cyprus',
  },
  'Czech Republic': {
    id: 'Czech Republic',
    defaultMessage: 'Czech Republic',
  },
  Denmark: {
    id: 'Denmark',
    defaultMessage: 'Denmark',
  },
  Djibouti: {
    id: 'Djibouti',
    defaultMessage: 'Djibouti',
  },
  Dominica: {
    id: 'Dominica',
    defaultMessage: 'Dominica',
  },
  'Dominican Republic': {
    id: 'Dominican Republic',
    defaultMessage: 'Dominican Republic',
  },
  Ecuador: {
    id: 'Ecuador',
    defaultMessage: 'Ecuador',
  },
  Egypt: {
    id: 'Egypt',
    defaultMessage: 'Egypt',
  },
  'El Salvador': {
    id: 'El Salvador',
    defaultMessage: 'El Salvador',
  },
  'Equatorial Guinea': {
    id: 'Equatorial Guinea',
    defaultMessage: 'Equatorial Guinea',
  },
  Eritrea: {
    id: 'Eritrea',
    defaultMessage: 'Eritrea',
  },
  Estonia: {
    id: 'Estonia',
    defaultMessage: 'Estonia',
  },
  Ethiopia: {
    id: 'Ethiopia',
    defaultMessage: 'Ethiopia',
  },
  'Falkland Islands (Malvinas)': {
    id: 'Falkland Islands (Malvinas)',
    defaultMessage: 'Falkland Islands (Malvinas)',
  },
  'Faroe Islands': {
    id: 'Faroe Islands',
    defaultMessage: 'Faroe Islands',
  },
  Fiji: {
    id: 'Fiji',
    defaultMessage: 'Fiji',
  },
  Finland: {
    id: 'Finland',
    defaultMessage: 'Finland',
  },
  France: {
    id: 'France',
    defaultMessage: 'France',
  },
  'French Guiana': {
    id: 'French Guiana',
    defaultMessage: 'French Guiana',
  },
  'French Polynesia': {
    id: 'French Polynesia',
    defaultMessage: 'French Polynesia',
  },
  'French Southern Territories': {
    id: 'French Southern Territories',
    defaultMessage: 'French Southern Territories',
  },
  Gabon: {
    id: 'Gabon',
    defaultMessage: 'Gabon',
  },
  Gambia: {
    id: 'Gambia',
    defaultMessage: 'Gambia',
  },
  Georgia: {
    id: 'Georgia',
    defaultMessage: 'Georgia',
  },
  Germany: {
    id: 'Germany',
    defaultMessage: 'Germany',
  },
  Ghana: {
    id: 'Ghana',
    defaultMessage: 'Ghana',
  },
  Gibraltar: {
    id: 'Gibraltar',
    defaultMessage: 'Gibraltar',
  },
  Greece: {
    id: 'Greece',
    defaultMessage: 'Greece',
  },
  Greenland: {
    id: 'Greenland',
    defaultMessage: 'Greenland',
  },
  Grenada: {
    id: 'Grenada',
    defaultMessage: 'Grenada',
  },
  Guadeloupe: {
    id: 'Guadeloupe',
    defaultMessage: 'Guadeloupe',
  },
  Guam: {
    id: 'Guam',
    defaultMessage: 'Guam',
  },
  Guatemala: {
    id: 'Guatemala',
    defaultMessage: 'Guatemala',
  },
  Guernsey: {
    id: 'Guernsey',
    defaultMessage: 'Guernsey',
  },
  Guinea: {
    id: 'Guinea',
    defaultMessage: 'Guinea',
  },
  'Guinea-Bissau': {
    id: 'Guinea-Bissau',
    defaultMessage: 'Guinea-Bissau',
  },
  Guyana: {
    id: 'Guyana',
    defaultMessage: 'Guyana',
  },
  Haiti: {
    id: 'Haiti',
    defaultMessage: 'Haiti',
  },
  'Heard Island and Mcdonald Islands': {
    id: 'Heard Island and Mcdonald Islands',
    defaultMessage: 'Heard Island and Mcdonald Islands',
  },
  'Holy See (Vatican City State)': {
    id: 'Holy See (Vatican City State)',
    defaultMessage: 'Holy See (Vatican City State)',
  },
  Honduras: {
    id: 'Honduras',
    defaultMessage: 'Honduras',
  },
  Hungary: {
    id: 'Hungary',
    defaultMessage: 'Hungary',
  },
  Iceland: {
    id: 'Iceland',
    defaultMessage: 'Iceland',
  },
  India: {
    id: 'India',
    defaultMessage: 'India',
  },
  Indonesia: {
    id: 'Indonesia',
    defaultMessage: 'Indonesia',
  },
  'Iran, Islamic Republic Of': {
    id: 'Iran, Islamic Republic Of',
    defaultMessage: 'Iran, Islamic Republic Of',
  },
  Iraq: {
    id: 'Iraq',
    defaultMessage: 'Iraq',
  },
  Ireland: {
    id: 'Ireland',
    defaultMessage: 'Ireland',
  },
  'Isle of Man': {
    id: 'Isle of Man',
    defaultMessage: 'Isle of Man',
  },
  Israel: {
    id: 'Israel',
    defaultMessage: 'Israel',
  },
  Italy: {
    id: 'Italy',
    defaultMessage: 'Italy',
  },
  Jamaica: {
    id: 'Jamaica',
    defaultMessage: 'Jamaica',
  },
  Japan: {
    id: 'Japan',
    defaultMessage: 'Japan',
  },
  Jersey: {
    id: 'Jersey',
    defaultMessage: 'Jersey',
  },
  Jordan: {
    id: 'Jordan',
    defaultMessage: 'Jordan',
  },
  Kazakhstan: {
    id: 'Kazakhstan',
    defaultMessage: 'Kazakhstan',
  },
  Kenya: {
    id: 'Kenya',
    defaultMessage: 'Kenya',
  },
  Kiribati: {
    id: 'Kiribati',
    defaultMessage: 'Kiribati',
  },
  "Korea, Democratic People'S Republic of": {
    id: "Korea, Democratic People'S Republic of",
    defaultMessage: "Korea, Democratic People'S Republic of",
  },
  'Korea, Republic of': {
    id: 'Korea, Republic of',
    defaultMessage: 'Korea, Republic of',
  },
  Kuwait: {
    id: 'Kuwait',
    defaultMessage: 'Kuwait',
  },
  Kyrgyzstan: {
    id: 'Kyrgyzstan',
    defaultMessage: 'Kyrgyzstan',
  },
  "Lao People'S Democratic Republic": {
    id: "Lao People'S Democratic Republic",
    defaultMessage: "Lao People'S Democratic Republic",
  },
  Latvia: {
    id: 'Latvia',
    defaultMessage: 'Latvia',
  },
  Lebanon: {
    id: 'Lebanon',
    defaultMessage: 'Lebanon',
  },
  Lesotho: {
    id: 'Lesotho',
    defaultMessage: 'Lesotho',
  },
  Liberia: {
    id: 'Liberia',
    defaultMessage: 'Liberia',
  },
  'Libyan Arab Jamahiriya': {
    id: 'Libyan Arab Jamahiriya',
    defaultMessage: 'Libyan Arab Jamahiriya',
  },
  Liechtenstein: {
    id: 'Liechtenstein',
    defaultMessage: 'Liechtenstein',
  },
  Lithuania: {
    id: 'Lithuania',
    defaultMessage: 'Lithuania',
  },
  Luxembourg: {
    id: 'Luxembourg',
    defaultMessage: 'Luxembourg',
  },
  'Macedonia, The Former Yugoslav Republic of': {
    id: 'Macedonia, The Former Yugoslav Republic of',
    defaultMessage: 'Macedonia, The Former Yugoslav Republic of',
  },
  Madagascar: {
    id: 'Madagascar',
    defaultMessage: 'Madagascar',
  },
  Malawi: {
    id: 'Malawi',
    defaultMessage: 'Malawi',
  },
  Malaysia: {
    id: 'Malaysia',
    defaultMessage: 'Malaysia',
  },
  Maldives: {
    id: 'Maldives',
    defaultMessage: 'Maldives',
  },
  Mali: {
    id: 'Mali',
    defaultMessage: 'Mali',
  },
  Malta: {
    id: 'Malta',
    defaultMessage: 'Malta',
  },
  'Marshall Islands': {
    id: 'Marshall Islands',
    defaultMessage: 'Marshall Islands',
  },
  Martinique: {
    id: 'Martinique',
    defaultMessage: 'Martinique',
  },
  Mauritania: {
    id: 'Mauritania',
    defaultMessage: 'Mauritania',
  },
  Mauritius: {
    id: 'Mauritius',
    defaultMessage: 'Mauritius',
  },
  Mayotte: {
    id: 'Mayotte',
    defaultMessage: 'Mayotte',
  },
  Mexico: {
    id: 'Mexico',
    defaultMessage: 'Mexico',
  },
  'Micronesia, Federated States of': {
    id: 'Micronesia, Federated States of',
    defaultMessage: 'Micronesia, Federated States of',
  },
  'Moldova, Republic of': {
    id: 'Moldova, Republic of',
    defaultMessage: 'Moldova, Republic of',
  },
  Monaco: {
    id: 'Monaco',
    defaultMessage: 'Monaco',
  },
  Mongolia: {
    id: 'Mongolia',
    defaultMessage: 'Mongolia',
  },
  Montserrat: {
    id: 'Montserrat',
    defaultMessage: 'Montserrat',
  },
  Morocco: {
    id: 'Morocco',
    defaultMessage: 'Morocco',
  },
  Mozambique: {
    id: 'Mozambique',
    defaultMessage: 'Mozambique',
  },
  Myanmar: {
    id: 'Myanmar',
    defaultMessage: 'Myanmar',
  },
  Namibia: {
    id: 'Namibia',
    defaultMessage: 'Namibia',
  },
  Nauru: {
    id: 'Nauru',
    defaultMessage: 'Nauru',
  },
  Nepal: {
    id: 'Nepal',
    defaultMessage: 'Nepal',
  },
  Netherlands: {
    id: 'Netherlands',
    defaultMessage: 'Netherlands',
  },
  'Netherlands Antilles': {
    id: 'Netherlands Antilles',
    defaultMessage: 'Netherlands Antilles',
  },
  'New Caledonia': {
    id: 'New Caledonia',
    defaultMessage: 'New Caledonia',
  },
  'New Zealand': {
    id: 'New Zealand',
    defaultMessage: 'New Zealand',
  },
  Nicaragua: {
    id: 'Nicaragua',
    defaultMessage: 'Nicaragua',
  },
  Niger: {
    id: 'Niger',
    defaultMessage: 'Niger',
  },
  Nigeria: {
    id: 'Nigeria',
    defaultMessage: 'Nigeria',
  },
  Niue: {
    id: 'Niue',
    defaultMessage: 'Niue',
  },
  'Norfolk Island': {
    id: 'Norfolk Island',
    defaultMessage: 'Norfolk Island',
  },
  'Northern Mariana Islands': {
    id: 'Northern Mariana Islands',
    defaultMessage: 'Northern Mariana Islands',
  },
  Norway: {
    id: 'Norway',
    defaultMessage: 'Norway',
  },
  Oman: {
    id: 'Oman',
    defaultMessage: 'Oman',
  },
  Pakistan: {
    id: 'Pakistan',
    defaultMessage: 'Pakistan',
  },
  Palau: {
    id: 'Palau',
    defaultMessage: 'Palau',
  },
  'Palestinian Territory, Occupied': {
    id: 'Palestinian Territory, Occupied',
    defaultMessage: 'Palestinian Territory, Occupied',
  },
  Panama: {
    id: 'Panama',
    defaultMessage: 'Panama',
  },
  'Papua New Guinea': {
    id: 'Papua New Guinea',
    defaultMessage: 'Papua New Guinea',
  },
  Paraguay: {
    id: 'Paraguay',
    defaultMessage: 'Paraguay',
  },
  Peru: {
    id: 'Peru',
    defaultMessage: 'Peru',
  },
  Philippines: {
    id: 'Philippines',
    defaultMessage: 'Philippines',
  },
  Pitcairn: {
    id: 'Pitcairn',
    defaultMessage: 'Pitcairn',
  },
  Poland: {
    id: 'Poland',
    defaultMessage: 'Poland',
  },
  Portugal: {
    id: 'Portugal',
    defaultMessage: 'Portugal',
  },
  'Puerto Rico': {
    id: 'Puerto Rico',
    defaultMessage: 'Puerto Rico',
  },
  Qatar: {
    id: 'Qatar',
    defaultMessage: 'Qatar',
  },
  Reunion: {
    id: 'Reunion',
    defaultMessage: 'Reunion',
  },
  Romania: {
    id: 'Romania',
    defaultMessage: 'Romania',
  },
  Russia: {
    id: 'Russia',
    defaultMessage: 'Russia',
  },
  RWANDA: {
    id: 'RWANDA',
    defaultMessage: 'RWANDA',
  },
  'Saint Helena': {
    id: 'Saint Helena',
    defaultMessage: 'Saint Helena',
  },
  'Saint Kitts and Nevis': {
    id: 'Saint Kitts and Nevis',
    defaultMessage: 'Saint Kitts and Nevis',
  },
  'Saint Lucia': {
    id: 'Saint Lucia',
    defaultMessage: 'Saint Lucia',
  },
  'Saint Pierre and Miquelon': {
    id: 'Saint Pierre and Miquelon',
    defaultMessage: 'Saint Pierre and Miquelon',
  },
  'Saint Vincent and the Grenadines': {
    id: 'Saint Vincent and the Grenadines',
    defaultMessage: 'Saint Vincent and the Grenadines',
  },
  Samoa: {
    id: 'Samoa',
    defaultMessage: 'Samoa',
  },
  'San Marino': {
    id: 'San Marino',
    defaultMessage: 'San Marino',
  },
  'Sao Tome and Principe': {
    id: 'Sao Tome and Principe',
    defaultMessage: 'Sao Tome and Principe',
  },
  'Saudi Arabia': {
    id: 'Saudi Arabia',
    defaultMessage: 'Saudi Arabia',
  },
  Senegal: {
    id: 'Senegal',
    defaultMessage: 'Senegal',
  },
  'Serbia and Montenegro': {
    id: 'Serbia and Montenegro',
    defaultMessage: 'Serbia and Montenegro',
  },
  Seychelles: {
    id: 'Seychelles',
    defaultMessage: 'Seychelles',
  },
  'Sierra Leone': {
    id: 'Sierra Leone',
    defaultMessage: 'Sierra Leone',
  },
  Singapore: {
    id: 'Singapore',
    defaultMessage: 'Singapore',
  },
  Slovakia: {
    id: 'Slovakia',
    defaultMessage: 'Slovakia',
  },
  Slovenia: {
    id: 'Slovenia',
    defaultMessage: 'Slovenia',
  },
  'Solomon Islands': {
    id: 'Solomon Islands',
    defaultMessage: 'Solomon Islands',
  },
  Somalia: {
    id: 'Somalia',
    defaultMessage: 'Somalia',
  },
  'South Africa': {
    id: 'South Africa',
    defaultMessage: 'South Africa',
  },
  'South Georgia and the South Sandwich Islands': {
    id: 'South Georgia and the South Sandwich Islands',
    defaultMessage: 'South Georgia and the South Sandwich Islands',
  },
  Spain: {
    id: 'Spain',
    defaultMessage: 'Spain',
  },
  'Sri Lanka': {
    id: 'Sri Lanka',
    defaultMessage: 'Sri Lanka',
  },
  Sudan: {
    id: 'Sudan',
    defaultMessage: 'Sudan',
  },
  Suriname: {
    id: 'Suriname',
    defaultMessage: 'Suriname',
  },
  'Svalbard and Jan Mayen': {
    id: 'Svalbard and Jan Mayen',
    defaultMessage: 'Svalbard and Jan Mayen',
  },
  Swaziland: {
    id: 'Swaziland',
    defaultMessage: 'Swaziland',
  },
  Sweden: {
    id: 'Sweden',
    defaultMessage: 'Sweden',
  },
  Switzerland: {
    id: 'Switzerland',
    defaultMessage: 'Switzerland',
  },
  'Syrian Arab Republic': {
    id: 'Syrian Arab Republic',
    defaultMessage: 'Syrian Arab Republic',
  },
  Tajikistan: {
    id: 'Tajikistan',
    defaultMessage: 'Tajikistan',
  },
  'Tanzania, United Republic of': {
    id: 'Tanzania, United Republic of',
    defaultMessage: 'Tanzania, United Republic of',
  },
  Thailand: {
    id: 'Thailand',
    defaultMessage: 'Thailand',
  },
  SoutheastAsia: {
    id: 'SoutheastAsia',
    defaultMessage: 'Southeast Asia',
  },
  'Timor-Leste': {
    id: 'Timor-Leste',
    defaultMessage: 'Timor-Leste',
  },
  Togo: {
    id: 'Togo',
    defaultMessage: 'Togo',
  },
  Tokelau: {
    id: 'Tokelau',
    defaultMessage: 'Tokelau',
  },
  Tonga: {
    id: 'Tonga',
    defaultMessage: 'Tonga',
  },
  'Trinidad and Tobago': {
    id: 'Trinidad and Tobago',
    defaultMessage: 'Trinidad and Tobago',
  },
  Tunisia: {
    id: 'Tunisia',
    defaultMessage: 'Tunisia',
  },
  Turkey: {
    id: 'Turkey',
    defaultMessage: 'Turkey',
  },
  Turkmenistan: {
    id: 'Turkmenistan',
    defaultMessage: 'Turkmenistan',
  },
  'Turks and Caicos Islands': {
    id: 'Turks and Caicos Islands',
    defaultMessage: 'Turks and Caicos Islands',
  },
  Tuvalu: {
    id: 'Tuvalu',
    defaultMessage: 'Tuvalu',
  },
  Uganda: {
    id: 'Uganda',
    defaultMessage: 'Uganda',
  },
  Ukraine: {
    id: 'Ukraine',
    defaultMessage: 'Ukraine',
  },
  'United Arab Emirates': {
    id: 'United Arab Emirates',
    defaultMessage: 'United Arab Emirates',
  },
  'United Kingdom': {
    id: 'United Kingdom',
    defaultMessage: 'United Kingdom',
  },
  'United States': {
    id: 'United States',
    defaultMessage: 'United States',
  },
  'United States Minor Outlying Islands': {
    id: 'United States Minor Outlying Islands',
    defaultMessage: 'United States Minor Outlying Islands',
  },
  Uruguay: {
    id: 'Uruguay',
    defaultMessage: 'Uruguay',
  },
  Uzbekistan: {
    id: 'Uzbekistan',
    defaultMessage: 'Uzbekistan',
  },
  Vanuatu: {
    id: 'Vanuatu',
    defaultMessage: 'Vanuatu',
  },
  Venezuela: {
    id: 'Venezuela',
    defaultMessage: 'Venezuela',
  },
  'Viet Nam': {
    id: 'Viet Nam',
    defaultMessage: 'Viet Nam',
  },
  'Virgin Islands, British': {
    id: 'Virgin Islands, British',
    defaultMessage: 'Virgin Islands, British',
  },
  'Virgin Islands, U.S.': {
    id: 'Virgin Islands, U.S.',
    defaultMessage: 'Virgin Islands, U.S.',
  },
  'Wallis and Futuna': {
    id: 'Wallis and Futuna',
    defaultMessage: 'Wallis and Futuna',
  },
  'Western Sahara': {
    id: 'Western Sahara',
    defaultMessage: 'Western Sahara',
  },
  Yemen: {
    id: 'Yemen',
    defaultMessage: 'Yemen',
  },
  Zambia: {
    id: 'Zambia',
    defaultMessage: 'Zambia',
  },
  Zimbabwe: {
    id: 'Zimbabwe',
    defaultMessage: 'Zimbabwe',
  },
  'The Republic of Serbia': {
    id: 'The Republic of Serbia',
    defaultMessage: 'The Republic of Serbia',
  },
  // END COUNTRY MSGS
});
