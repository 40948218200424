import {
  Box,
  Button,
  capitalize,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CheckIcon,
  ScriptsIcon,
  DMIcon
} from '../../assets/imgs/icons';
import { useIntl, IntlShape } from 'react-intl';
import { Messages } from '../../localization/Messages';
import { Fragment, useEffect, useState } from 'react';
import { useGlobalStyles } from '../../assets/styles/style';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import STabs from '../../components/STabs';
import {
  useGetUserStripePlan,
  useOpenStripePortal,
  useSubscribePlan,
} from '../../hooks/stripe';
import { useLanguage } from '../../hooks/language';
import { staticURLMyProfile } from '../../route/urls';
import { StripeCardPayment } from '../../components/StripeCardPayment';
import { DateFormatOptions } from '../../utils/common';
import { useTypedSelector } from '../../redux/store';
import { selectAuth } from './AuthSlice';
import {
  useGetUserShopifyPlan,
  useIsShopifyUser,
  useShopifyCancelSubscription,
  useShopifySubscription,
} from '../../hooks/shopify';
import Confirm from '../../components/Confirm';

type TimeUnitType = 'perYear' | 'perMonth';
const PlanOptions:{
  id: string;
  name: string;
  price: string;
  unitLabel: TimeUnitType;
  unit: 'month' | 'year';
  features: {
    desc: string[][];
  };
  creditsLabel: 'creditsPerMonth' | 'creditsPerYear';
}[] = [
  {
    id: 'creators_monthly',
    name: 'Bena Plan Monthly',
    price: '19.99',
    unitLabel: 'perMonth',
    unit: 'month',
    features: {
      desc: [
        ["Plugin:","Full access to creators stats on TikTok"],
        ["Content Creation:","Access to ","100"," generations of personalized video scripts, content ideas, and hashtags."],
        ["DM AI Reply:","Generate up to with ","100"," DM reply, ensuring personalized interaction at scale."],
        ['', 'Apply to as many campaigns as you want.'],
        ['', 'Unlimited access to Bena AI generated content such as the script and email writers.'],
        ['', 'Directly communicate with brands, exchange documents, and track your campaign progress.'],
        ['', 'Priority customer support.']

      ],
    },
    creditsLabel: 'creditsPerMonth',
  },
  {
    id: 'creators_annually',
    name: 'Bena Plan Annually',
    price: '16.66',
    unitLabel: 'perYear',
    unit: 'year',
    features: {
      desc: [
        ["Plugin:","Full access to creators stats on TikTok"],
        ["Content Creation:","Access to ","1,200"," generations of personalized video scripts, content ideas, and hashtags."],
        ["DM AI Reply:","Generate up to with ","1,200"," DM reply, ensuring personalized interaction at scale."],
        ['', 'Apply to as many campaigns as you want.'],
        ['', 'Unlimited access to Bena AI generated content such as the script and email writers.'],
        ['', 'Directly communicate with brands, exchange documents, and track your campaign progress.'],
        ['', 'Priority customer support.'],
      ],
    },
    creditsLabel: 'creditsPerYear',
  },
];

function updatePlanOptions(
  details: PlanDetailsType,
  options: typeof PlanOptions
) {
  options.forEach((w) => {
    const option = details[w.id as keyof PlanDetailsType];
    if (option) {
      if(w.unit === 'year') {
        w.price = Math.floor(option.price / 12 * 100) / 100 + ""
      }else{
        w.price = `${option.price}`;
      }
    }
  });
}

function useCredits(plan: SubscriptionPlanResponseType | null, intl:IntlShape) {
  const theme = useTheme();
  let billingDemo = {DM: { quota: 0 }, email: { quota: 0 }, script: { quota: 0 }}
  const user = useTypedSelector((state) => selectAuth(state)).user;
  const billing = user?.billing || billingDemo;

  const styleIcon = { width: '32px', height: '32px', color:"transparent" }

  return [
    {
      icon: <ScriptsIcon stroke={theme.palette.base[50]} sx={styleIcon} />,
      name: intl.formatMessage(Messages.remainingScript),
      value: billing.script.quota
    },
    {
      icon: <DMIcon fill={theme.palette.base[50]} sx={{ width: '36px', height: '36px', color:"transparent" }} />,
      name: intl.formatMessage(Messages.remainingDM),
      value: billing.DM.quota
    },
  ];
}

export function BillingCreator() {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const lang = useLanguage();
  const isMobileView = useBreakpointXs();
  const isShopifyUser = useIsShopifyUser();
  const { cancelSubscription: cancelShopifySubscription, loading: cancelling } =
    useShopifyCancelSubscription();

  const displayPlans = [PlanOptions[0], PlanOptions[1]];
  const stripePlan = useGetUserStripePlan();
  const shopifyPlan = useGetUserShopifyPlan();
  const {
    currentPlanName: currentPlanNameShort,
    nextBillDueDate,
    getPlan,
    plan,
  } = isShopifyUser ? shopifyPlan : stripePlan;

  const currentPlanName =
    currentPlanNameShort +
    (plan?.billing_cycle ? '_' + (plan?.billing_cycle || '') : '');
  const isFreePlan = currentPlanName === 'free';
  const cancelledAt = plan?.subscription?.cancel_at
    ? intl.formatDate(plan.subscription.cancel_at * 1000, DateFormatOptions)
    : undefined;
  const { subscribe: _subscribe } = useSubscribePlan();
  const { openPortal: _openPortal } = useOpenStripePortal();
  const { subscribeShopify: _subscribeShopify } = useShopifySubscription();
  useEffect(() => {
    getPlan({});
  }, []);

  useEffect(() => {
    if (plan?.plan_details) {
      updatePlanOptions(plan.plan_details, PlanOptions);
    }
  }, [plan]);

  const params = {
    success_redirect_path: staticURLMyProfile,
    cancel_redirect_path: staticURLMyProfile,
    locale: lang,
  };
  const generatePlanId = (planIndex: number) => {
    return PlanOptions[planIndex]?.id || '';
  };
  const subscribe = (planIndex: number) => {
    const id = generatePlanId(planIndex);
    id &&
      _subscribe({
        ...params,
        subscription_key: id,
      });
  };
  const openPortal = () => {
    _openPortal({
      return_path: params.success_redirect_path,
      locale: params.locale,
    });
  };

  const manageSubscriptionButtonClickHandler = () => {
    isShopifyUser ? setOpen(true) : openPortal();
  };

  const subscribeShopify = (planIndex: number) => {
    const id = generatePlanId(planIndex);
    _subscribeShopify({
      subscription_key: id,
    });
  };

  const billingDetails = {
    name: capitalize(intl.formatMessage(Messages.billingDetails)),
    children: (
      <Stack rowGap={'0.25rem'}>
        {!isShopifyUser && (
          <>
            <Typography fontWeight={500} fontSize="15px" color="base.black">
              {capitalize(intl.formatMessage(Messages.paymentMethod))}
            </Typography>
            <StripeCardPayment planDetails={plan} />
          </>
        )}
        <Typography fontWeight={500} fontSize="15px" color="base.black">
          {capitalize(intl.formatMessage(Messages.billingCycle))}
        </Typography>
        <Typography fontWeight={500} color="grey.700">
          {capitalize(intl.formatMessage(Messages.renewOn))}{' '}
          {intl.formatDate(nextBillDueDate, DateFormatOptions)}
        </Typography>
        {cancelledAt && (
          <Typography
            fontWeight={500}
            fontSize="15px"
            color="danger.500"
            marginTop={'1rem'}
          >
            {capitalize(intl.formatMessage(Messages.cancelledOn))}:{' '}
            {cancelledAt}
          </Typography>
        )}
      </Stack>
    ),
  };
  const features = (plan: typeof PlanOptions[0]) => {
    const desc = plan.features.desc;
    return {
      name: capitalize(intl.formatMessage(Messages.features)),
      children: (
        <Stack rowGap={'0.25rem'}>
          {
            desc.map((textArr, index) => {
              return (
                <Box key={index} sx={{
                  pb: "1.5rem",
                  borderBottom: index === 2 ? "1px solid " + theme.palette.neutral[600] : "0",
                  "&:last-child": { padding: "0"}
                }} >
                  { textArr[0] && <Box fontWeight={700}>{textArr[0]}</Box> }
                  <Stack direction="row"
                    mt={ textArr[0] || index === 3 ? "0.75rem" : "0" }>
                    <CheckIcon stroke={theme.palette.primary.main}
                      sx={{ width: '1rem', height: '1rem', m: '0.25rem 0 0 0.25rem' }}
                    />
                    <Box ml="0.75rem" lineHeight="24px" sx={{ "& span": { fontSize: "16px" } }}>
                      <Typography component="span" fontWeight="400">{textArr[1]}</Typography>
                      <Typography component="span" fontWeight="700">{textArr[2]}</Typography>
                      <Typography component="span" fontWeight="400">{textArr[3]}</Typography>
                    </Box>
                  </Stack>
                </Box>
              )
            })
          }
        </Stack>
      ),
    }
  };
  const border = (index: number) =>
    isMobileView
      ? index === 0
        ? '0.5rem 0.5rem 0 0'
        : '0 0 0.5rem 0.5rem'
      : index === 0
      ? '0.5rem 0 0 0.5rem'
      : '0rem 0.5rem 0.5rem 0rem';
  const credits = useCredits(plan, intl);

  const subscribeButton = (index: number) => (
    <Button
      className={gs.buttonPrimary}
      onClick={() =>
        isShopifyUser ? subscribeShopify(index) : subscribe(index)
      }
    >
      {capitalize(intl.formatMessage(Messages.subscribe))}
    </Button>
  );

  const [open, setOpen] = useState(false);

  return (
    <Stack>
      <Confirm
        text={intl.formatMessage(Messages.AreYouSureToCancel)}
        handleConfirm={cancelShopifySubscription}
        open={open}
        setOpen={setOpen}
      />
      <Typography fontWeight={500} fontSize="1.5rem">
        {capitalize(intl.formatMessage(Messages.billing))}
      </Typography>
      <Typography color="grey.400">
        {capitalize(intl.formatMessage(Messages.availableCredits))}
      </Typography>
      <Box height="1rem"></Box>
      <Grid
        gridTemplateRows={isMobileView ? 'auto auto auto' : '195px auto'}
        gridTemplateColumns={isMobileView ? 'auto' : 'auto 389px'}
        gridTemplateAreas={
          isMobileView
            ? "'plans' 'credits' 'deleteAccount'"
            : "'plans credits' 'plans deleteAccount'"
        }
        display={'grid'}
        rowGap="1rem"
        columnGap={'1rem'}
      >
        <Stack
          gridArea="plans"
          direction={isMobileView ? 'column' : 'row'}
          border={'1px solid ' + theme.palette.neutral[600]}
          borderRadius="0.5rem"
          className="shadowXSM"
        >
          {displayPlans.map((plan, index) => (
            <Fragment key={`${plan.name}${index}`}>
              <Stack
                rowGap={'0.75rem'}
                padding="1.5rem 1.5rem 1rem"
                bgcolor={theme.palette.base.white}
                borderRadius={border(index)}
                overflow='hidden'
                position="relative"
                flexGrow={0}
                flexShrink={0}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '49.95%',
                    lg: '49.95%',
                    xl: '49.95%',
                  },
                }}
              >
                {plan.unit === 'year' && (
                  <Typography sx={{
                    position: 'absolute', top: '0', right: '0', padding: "4px 12px",
                    textAlign: 'center', borderRadius: '0 0 0 1rem',
                    bgcolor: theme.palette.action.bgPrimary
                  }}>
                    <Typography component="span" color="primary.main_white" fontSize='18px' fontWeight='700'>
                      -15% OFF
                    </Typography>
                  </Typography>
                ) }
                <Stack
                  direction="row"
                  marginBottom={'0.5rem'}
                  justifyContent="center"
                >
                  <Stack marginLeft="1rem" justifyContent={'center'}>
                    <Box className="boxcenterhv" marginBottom={'32px'}>
                      <Typography
                        fontWeight="700"
                        textAlign="center"
                        component="span"
                        fontSize="22px"
                        lineHeight={'32px'}
                      >
                        {capitalize(plan.name)}
                      </Typography>
                    </Box>
                    <Stack direction="row" textAlign="center" alignItems="flex-end"
                      justifyContent="center">
                      <Typography sx={{ lineHeight:"42px", color:"grey.900", fontSize:"34px", fontWeight: "700" }}
                        component="span">
                        ${plan.price}
                      </Typography>
                      <Typography sx={{ lineHeight:"30px", color:"grey.900", fontSize:"22px", fontWeight: "600" }}
                        component="span">
                        &nbsp;/&nbsp;{intl.formatMessage(Messages.perMonth)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <STabs
                  tabs={
                    plan.name === currentPlanName || plan.id === currentPlanName
                      ? [billingDetails, features(plan)]
                      : [features(plan)]
                  }
                  tabTitleFontSize="0.875rem"
                  selectedIndex={0}
                  tabTitlePaddingBottom={'0rem'}
                  tabTitlePaddingTop={'0rem'}
                />

                <Box flexGrow={1}></Box>
                {plan.name === currentPlanName ||
                plan.id === currentPlanName ? (
                  <Box
                    className={`boxcenterhv`}
                    sx={{
                      borderRadius: '8px',
                      backgroundColor: theme.palette.success[50],
                      color: theme.palette.success[600],
                    }}
                    minHeight="40px"
                  >
                    {intl.formatMessage(Messages.SelectedPlan)}
                  </Box>
                ) : isFreePlan || isShopifyUser ? (
                  subscribeButton(index)
                ) : (
                  <Box minHeight="40px"></Box>
                )}
              </Stack>
              {index < displayPlans.length - 1 && (
                <Box
                  {...(isMobileView ? { height: '1px' } : { width: '1px' })}
                  bgcolor={theme.palette.neutral[600]}
                ></Box>
              )}
            </Fragment>
          ))}
        </Stack>
        <Stack
          gridArea="credits"
          direction={'column'}
          border={'1px solid ' + theme.palette.neutral[600]}
          borderRadius="0.5rem"
          className="shadowXSM"
          bgcolor={theme.palette.base.white}
        >
          {credits.map((credit, index) => (
            <Stack
              direction="row"
              key={`${credit.name} ${index}`}
              padding="1rem"
              borderBottom={'1px solid ' + theme.palette.neutral[600]}
            >
              <Stack
                sx={{ width: '4rem', height: '4rem' }}
                justifyContent="center"
                alignItems={'center'}
                bgcolor="neutral.600"
                marginRight={'1rem'}
                borderRadius="0.5rem"
              >
                {credit.icon}
              </Stack>
              <Stack direction={'column'}>
                <Typography color="grey.50" fontWeight={500}>
                  {credit.name}
                </Typography>
                <Typography
                  color="grey.700"
                  fontWeight={600}
                  fontSize="22px"
                  lineHeight={'26px'}
                >
                  {intl.formatNumber(credit.value)}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Stack
          gridArea="deleteAccount"
          direction={'column'}
          border={'1px solid ' + theme.palette.neutral[600]}
          borderRadius="0.5rem"
          className="shadowXSM"
          bgcolor={theme.palette.base.white}
        >
          <Typography
            padding="1rem"
            borderBottom={'1px solid ' + theme.palette.neutral[600]}
            color="grey.700"
            fontWeight={500}
            fontSize="18px"
          >
            {intl.formatMessage(Messages.ManageYourSubscription)}
          </Typography>
          <Typography padding="1rem">
            {intl.formatMessage(
              isFreePlan
                ? Messages.YouDontHaveAnActive
                : isShopifyUser
                ? Messages.CancelCurrentSubscription
                : Messages.VisitThisLinkTo
            )}
          </Typography>
          <Stack
            flexGrow={1}
            justifyContent="flex-end"
            padding="1rem"
            paddingTop="0"
          >
            <Button
              className={gs.buttonPrimary}
              onClick={manageSubscriptionButtonClickHandler}
              disabled={isFreePlan || cancelling}
            >
              {intl.formatMessage(
                isShopifyUser
                  ? Messages.CancelSubscription
                  : Messages.ManageSubscription
              )}
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Stack>
  );
}
