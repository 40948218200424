import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../../redux/store';
import { NewCampaignInitData } from './NewCampaign';

type UnsavedCampaignsMapType = { [key: string]: CampaignFormDataType | null };
type CampaignFormDataType = typeof NewCampaignInitData;
export type CampaignSliceType = {
  unsavedCampaigns: UnsavedCampaignsMapType;
};

const initialState = {
  unsavedCampaigns: { unsavedNewCampaign: null } as UnsavedCampaignsMapType,
};

const slice = createSlice({
  name: 'Campaign',
  initialState,
  reducers: {
    setUnsavedCampaign(
      state: CampaignSliceType,
      // save by campaignId or unsavedNewCampaign
      action: PayloadAction<{
        campaignId: string;
        campaign: CampaignFormDataType | null;
      }>
    ) {
      state.unsavedCampaigns = {
        ...state.unsavedCampaigns,
        [action.payload.campaignId]: action.payload.campaign,
      };
    },
  },
});

export const { setUnsavedCampaign } = slice.actions;

export const selectUnsavedCampaigns = createSelector(
  [(state: RootState) => state.campaign.unsavedCampaigns],
  (state) => state
);

export default slice.reducer;
