import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLazyGetUserQuery } from '../../redux/benaApi';
import { useTypedSelector } from '../../redux/store';
import { useEffect } from 'react';
import { setLogin } from '../../features/account/AuthSlice';
import { showMessage } from '../../components/ShowMessage';
import { InfiniteScrollLoaderMessage } from '../../components/InfiniteScrollMessages';
import { useIntl } from 'react-intl';
import ErrorMessages from '../../redux/benaApiResponseCode';

type SocialAccountVerificationCallbackType =
  | 'homepage'
  | 'setting'
  | 'creative-story';
type SocialAccountVerificationCallbackProps = {
  callbackType: SocialAccountVerificationCallbackType;
};
function SocialAccountVerificationCallback({
  callbackType,
}: SocialAccountVerificationCallbackProps) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getUser, getUserResult] = useLazyGetUserQuery();
  const auth = useTypedSelector((root) => root.auth);
  const [searchParams] = useSearchParams();
  const tiktokVerified = searchParams.get('success') === 'true';
  const hasError4045 = searchParams.get('error') === '4045';
  const hasError4048 = searchParams.get('error') === '4048';
  const hasError4015 = searchParams.get('error') === '4015';
  const hasError4016 = searchParams.get('error') === '4016';
  const hasError4050 = searchParams.get('error') === '4050';

  const { campaign_id } = useParams();
  const redirect = () => {
    navigate(
      callbackType === 'homepage' ? '/?campaign_id=' + campaign_id : '/'
    );
  };
  useEffect(() => {
    if (
      getUserResult.isSuccess &&
      getUserResult.data?.status.status_code === 1000
    ) {
      dispatch(
        setLogin({
          ...auth,
          user: {
            ...auth.user,
            ...(getUserResult.data?.data || {}),
          },
        })
      );
      redirect();
    }
  }, [getUserResult]);

  useEffect(() => {
    if (tiktokVerified && auth.access) getUser({ access: auth.access });
  }, [tiktokVerified]);

  useEffect(() => {
    if (auth.access && hasError4045) {
      showMessage.error(
        'An unexpected error occurred while logging in via TikTok. You may cancelled the verification.[4045]'
      );
      redirect();
    } else if (auth.access && hasError4048) {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4048));
      redirect();
    } else if (auth.access && hasError4015) {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4015));
      redirect();
    } else if (auth.access && hasError4016) {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4016));
      redirect();
    } else if (auth.access && hasError4050) {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4050));
      redirect();
    }
  }, [hasError4045, hasError4048, hasError4015, hasError4016, hasError4050]);

  return <InfiniteScrollLoaderMessage />;
}

export function HomepageSocialAccountVerificationCallback() {
  return <SocialAccountVerificationCallback callbackType="homepage" />;
}
