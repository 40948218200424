import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../../redux/store';

export type AIGCSliceType = {
  selectedCreatorId: string;
  likeFavorite: LikeFavoritesType[];
};

const initialState: AIGCSliceType = {
  selectedCreatorId: '',
  likeFavorite: [],
};

const slice = createSlice({
  name: 'aigc',
  initialState,
  reducers: {
    setSelectedCreatorId(state: AIGCSliceType, action: PayloadAction<string>) {
      state.selectedCreatorId = action.payload;
    },
    addLikeFavorite(
      state: AIGCSliceType,
      action: PayloadAction<LikeFavoritesType>
    ) {
      const newLikeFavorites = action.payload;
      const index = state.likeFavorite.findIndex(
        (v) => v.id === newLikeFavorites.id
      );
      if (index < 0) {
        state.likeFavorite = [...state.likeFavorite, newLikeFavorites];
      } else {
        state.likeFavorite = [
          ...state.likeFavorite.slice(0, index),
          newLikeFavorites,
          ...state.likeFavorite.slice(index + 1),
        ];
      }
    },
  },
});

export const { setSelectedCreatorId, addLikeFavorite } = slice.actions;

export const selectAIGC = createSelector(
  [(state: RootState) => state.aigc],
  (state) => state
);

export default slice.reducer;
