import {
  capitalize,
  useTheme,
  Button,
  ButtonGroup,
  Box,
  Typography,
} from '@mui/material';

type PlanTypeSelectionProps = {
  options: LabelValueType[];
  selected: string;
  onChange: fnStringToVoid;
  compact?: boolean;
};
export default function PlanTypeSelection({
  options,
  selected,
  onChange,
  compact = false,
}: PlanTypeSelectionProps) {
  const theme = useTheme();
  const buttons = options.map((v) => {
    const highlight = v.value === selected;
    return (
      <Button
        key={v.value}
        sx={{
          backgroundColor: highlight ? theme.palette.base.white : 'transparent',
          border: '1px solid ' + theme.palette.neutral[highlight ? 600 : 300],
          borderRadius: '6px',
          ...(compact ? {} : { width: '110px', height: '32px' }),
          '&:hover': {
            border: '1px solid ' + theme.palette.neutral[600],
          },
          '&:hover > p, &:hover': highlight
            ? {
                backgroundColor: theme.palette.base.white,
                cursor: 'auto',
              }
            : {
                color: theme.palette.base.white,
              },
        }}
        onClick={() => !highlight && onChange(v.value)}
        disableRipple
      >
        <Typography
          color={highlight ? 'grey.700' : 'grey.25'}
          fontWeight={'600'}
          textTransform="none"
        >
          {capitalize(v.label)}
        </Typography>
      </Button>
    );
  });
  return (
    <Box className="boxcenterhv">
      <ButtonGroup
        size="small"
        aria-label="small button group"
        disableElevation
        sx={{
          borderRadius: '8px',
          backgroundColor: theme.palette.neutral[300],
          padding: compact ? '0px' : '8px',
        }}
      >
        {buttons}
      </ButtonGroup>
    </Box>
  );
}
