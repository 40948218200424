import {
  Box,
  Stack,
  useTheme,
  Typography,
  IconButton
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import {
  CrossMinIcon,
  LeftArrowIcon
} from "../../assets/imgs/icons";
import BrandLabel from "./brandLabel"
import { createStyles, makeStyles } from "@mui/styles"
import { Theme } from "@mui/material/styles";
import { useIntl } from 'react-intl';
import { Messages } from "../../localization/Messages"


function BrandLabelList({
  brandList,
  closeLabelBox,
  bottom = "0.5rem"
}: {
  brandList: string[];
  closeLabelBox: () => void;
  bottom?: string;
}){
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const [lookList, setLookList] = useState<string[]>([]);
  const pageNum = 10;
  let totalSize = Math.ceil(brandList.length / pageNum);
  const [pageSize, setPageSize] = useState(0);

  const handlePageBrand = (type:string) => {
    let size = pageSize;
    if (type === "prev" && pageSize > 1){
      setPageSize(pageSize - 1);
      size --;
    } else if (type === "next" && pageSize < totalSize) {
      setPageSize(pageSize + 1);
      size ++;
    }else return undefined;
    let start = (size - 1) * pageNum;
    let end = size * pageNum;
    setLookList(brandList.slice(start, end));
  }
  useEffect(()=>{
    handlePageBrand("next");
  },[])
  return (
    <Box sx={{
      width: "208px",
      boxShadow:"0px 1px 2px 0px #1915280A;",
      position: "absolute",
      bottom: bottom,
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: theme.palette.grey["white_600"],
      border: "1px solid" + theme.palette.neutral["700_1"],
      borderRadius: "0.5rem",
      zIndex: 1055,
      overflow: "hidden"
    }} component="span">
      <Stack direction="row" sx={{
        backgroundColor: theme.palette.neutral["50_2"],
        borderBottom: "1px solid" + theme.palette.neutral["700_1"],
        height: "27px"
      }} alignItems="center" component="span">
        <Typography sx={{
          padding: "0.5rem",
          fontSize: "0.875rem",
          fontWeight: 500,
          color: theme.palette.grey["700"]
        }} component="span">
          {intl.formatMessage(Messages.WorkedWithBrands)}
        </Typography>
        <IconButton sx={{ padding: "0.5rem", marginLeft: "auto" }}
          onClick={(e)=>{ e.stopPropagation(); e.preventDefault(); closeLabelBox(); }}>
          <CrossMinIcon stroke={theme.palette.base.black} sx={{ width: 10, height: 11 }} />
        </IconButton>
      </Stack>
      <Stack sx={{ padding: "0.5rem", minHeight: "5rem" }} alignContent="flex-start"
        direction="row" gap="8px" flexWrap="wrap" component="span">
        {
          lookList.map((brand, index) => (
            <BrandLabel key={index} brand={brand} color={theme.palette.base.black}
              bgColor={theme.palette.neutral["300"]}
              isGrow />
          ))
        }
      </Stack>
      <Stack direction="row" justifyContent="space-between" sx={{ padding: "0.5rem" }} component="span">
        <IconButton onClick={(e)=>{
          e.stopPropagation();
          e.preventDefault();
          handlePageBrand("prev");
        }} sx={{ padding: "0.5rem" }}
          className={ pageSize <= 1 ? classes.IconBtnDisable : "" }>
          <LeftArrowIcon stroke={theme.palette.base.black} sx={{ width: 12, height: 10 }} />
          <Typography className={classes.TypographyIcons} marginLeft="0.375rem" component="span">
            {intl.formatMessage(Messages.paginationBack)}
          </Typography>
        </IconButton>

        <IconButton onClick={(e)=>{
          e.stopPropagation();
          e.preventDefault();
          handlePageBrand("next");
        }} sx={{ padding: "0.5rem" }}
          className={ pageSize >= totalSize ? classes.IconBtnDisable : "" }>
          <Typography className={classes.TypographyIcons} marginRight="0.375rem" component="span">
            {intl.formatMessage(Messages.paginationNext)}
          </Typography>
          <LeftArrowIcon stroke={theme.palette.base.black} sx={{ width: 12, height: 10, transform: "rotateZ(180deg)" }} />
        </IconButton>
      </Stack>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    IconBtn:{
      cursor: "pointer",
      "&:hover": {
        opacity: 0.8
      }
    },
    IconBtnDisable: {
      cursor: "not-allowed",
      opacity: 0.5
    },
    TypographyIcons: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: theme.palette.grey["700"]
    }
  })
);
export default memo(BrandLabelList);