import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  Box,
  capitalize,
  Chip,
  Divider,
  IconButton,
  Link,
  ListItemText,
  Stack,
  Theme,
  useTheme,
} from '@mui/material';
import { Messages } from '../../localization/Messages';
import { useIntl } from 'react-intl';
import { StarRating } from '../../components/StyledRating';
import { useContext, useEffect, useState } from 'react';
import {
  HeartIcon,
  FollowerPlusIcon,
  Eye3Icon,
  AverageErIcon,
  SponsoredBrandIcon
} from '../../assets/imgs/icons';
import { ArrowBack } from '@mui/icons-material';
import { setSelectedCreatorId } from './AIGCSlice';
import { useDispatch } from 'react-redux';
import Color from 'color';
import { getUrlBySocialPlatformId, staticURLRoot } from '../../route/urls';
import { useNavigate } from 'react-router-dom';
import { calcEngagementRate } from '../../utils/common';
import { useIsApiLoading } from '../../hooks/account';
import { AIGCDialogContext } from '../../context/context';
import { useThemeMode } from '../../hooks/theme';
import LightTooltip from '../../components/LightTooltip';
import IconCounts from "../modules/iconCounts";
import BrandLabel from "../creators/brandLabel";
import BrandLabelList from "../creators/brandLabelList";
import CreatorCheckList from "../modules/creatorCheckList";

const iconSize2 = { width: '2rem', height: '2rem' };

const bgcolors = (theme: Theme) => {
  return '01234'.split('').map((v) =>
    Color(theme.custom[(`forte` + v) as keyof typeof theme.custom])
      .fade(0.675)
      .toString()
  );
};
const bgcolorsNofade = (theme: Theme) => {
  return '01234'
    .split('')
    .map((v) =>
      Color(theme.custom[(`forte` + v) as keyof typeof theme.custom]).toString()
    );
};

export function CreatorCardLeftPanel() {
  const isDarkMode = useThemeMode() === 'dark';
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadingInProgress = useIsApiLoading();
  const { creatorData, creator, favoritedCreators, notifyFavorite, prevUrl } =
    useContext(AIGCDialogContext);
  
  const data = {
    ...creator,
    ...creatorData,
    profile_type:
      creatorData?.profile_type || creator?.profile_type || 'tiktok',
    content_style: creator?.content_style || [],
    characteristics: creator?.characteristics || [],
    profile: creator?.profile || {
      followers: 0,
      engagement_rate: 0,
      average_er: 0,
      avg_views: 0,
    },
  };
  const {
    picture,
    name,
    unique_id,
    profile,
    profile_type,
    creativity_index,
    content_style,
    characteristics,
    uid,
    sponsored_brand,
    shortlist
  } = data;
  const starRating = creativity_index
    ? Math.min(Math.max(1, creativity_index), 3)
    : undefined;
  const {
    followers: _followers,
    engagement_rate: _engagementRate,
    average_er: _averageEr,
    avg_views: _avgViews,
  } = profile;
  const followers = intl.formatNumber(_followers, {
    notation: 'compact',
  });
  const engagementRate = calcEngagementRate(_averageEr || _engagementRate);
  const avg_views = intl.formatNumber(_avgViews || 0, {
    notation: 'compact',
  });
  // const sponsored_brand = ['Nike','Adidas','Puma','Reebok','under armour','new balance',
  //   'asics','converse','vans','fila','jordan','yeezy','balenciaga','gucci','louis vuitton',
  //   'prada','versace','dior','chanel','hermes','fendi','burberry','givenchy','valentino',
  //   'off-white','vetements','rick owens'];
  const brandList = sponsored_brand || [];
  const sponsoredList = brandList.slice(0,3) || [];
  const sponsoredCount = brandList.length - 3;

  const [favorited, setFavorited] = useState<number | undefined>(
    creator?.favorite ? 1 : undefined
  );
  useEffect(() => {
    const f = favoritedCreators.find((v) => v.id === creator?.id)?.favorite;
    if (f !== undefined) {
      setFavorited(f ? 1 : 0);
    }
  }, [favoritedCreators, creator]);

  const { reset } = useContext(AIGCDialogContext);
  const hidePanel = () => {
    reset();
    dispatch(setSelectedCreatorId(''));
    navigate(prevUrl || staticURLRoot);
  };

  const bgcolor = (index: number) =>
    isDarkMode
      ? Color(bgcolors(theme)[index % 5])
          .alpha(0.2)
          .toString()
      : bgcolors(theme)[index % 5];
  const color = (index: number) =>
    isDarkMode ? bgcolorsNofade(theme)[index % 5] : theme.palette.primary[950];

  const at = profile_type.toLowerCase() === 'youtube' ? '' : '@';
  const urluid =
    profile_type.toLowerCase() === 'youtube' ? uid || unique_id : unique_id;
  
  const [labelState, setLabelState] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [shortLocal, setShortLocal] = useState<string[]>([...shortlist || []]); // current user shortlist

  const card = (
    <Card
      sx={{
        flexShrink: 0,
        width: 292,
        borderRight: '1px solid ' + theme.palette.neutral[600],
        borderRadius: '16px 0px 0px 16px',
        position: 'relative',
        opacity: loadingInProgress ? 0.9 : 1,
        backgroundColor: theme.palette.base['3'],
      }}
      elevation={0}
    >
      <Stack
        direction={'row'}
        sx={{
          position: 'absolute',
          width: '100%',
          padding: '1.5rem',
          zIndex: theme.zIndex.fab,
        }}
        justifyContent="space-between"
      >
        <IconButton
          sx={{
            backgroundColor: theme.palette.base.white2white,
            borderRadius: '8px',
            flexShrink: 0,
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              '& svg': {
                color: theme.palette.base.whiteonly,
              },
            },
            width: '2.5rem',
            height: '2.5rem',
          }}
          onClick={hidePanel}
          className="button_hide_aigc_panel"
        >
          <ArrowBack
            htmlColor={theme.palette.primary.main}
            sx={{ width: '1.25rem', height: '1.25rem' }}
          />
        </IconButton>
        <IconButton
          disableRipple
          onClick={()=>{
            // handleFavorite(); // go in creatorCard
            setOpen(true);
          }}
          sx={{
            padding: '0 5px',
            '&:hover path': {
              fill: favorited ? 'white' : theme.palette.danger[400],
            },
          }}
        >
          {shortLocal.length ? (
            <HeartIcon sx={iconSize2} />
          ) : (
            <HeartIcon sx={iconSize2} fill={'white'} />
          )}
        </IconButton>
      </Stack>
      <Box sx={{ position: 'relative' }}>
        <CardMedia component="img" height="216" image={picture} alt={name} />
        <Box sx={{
          width: '100%', height: '100%', position: 'absolute', left: 0, top: 0,
          pointerEvents: 'none',
          background: "linear-gradient(180deg, rgba(22, 35, 45, 0.5) 0%, rgba(255, 255, 255, 0) 50%)",
          backgroundBlendMode: 'multiply',
        }}></Box>
      </Box>
      { open && 
        <CreatorCheckList
          uid={ uid as string }
          shortLocal={shortLocal}
          setShortLocal={setShortLocal}
          setOpen={setOpen}
          posTop='4.25rem'
          posTransform='translateX(-50%)'
        />}
      <CardContent sx={{ padding: '1.125rem 1.5rem 1.5rem' }}>
        <Stack direction="row">
          <Typography
            fontSize={'16px'}
            fontWeight={500}
            component="div"
            flexGrow={1}
          >
            {name}
          </Typography>
          <LightTooltip
            title={capitalize(intl.formatMessage(Messages.creativityScore))}
          >
            <Box component={'span'}>
              <StarRating
                value={starRating || 0}
                max={3}
                readOnly
                sx={{ paddingTop: '3px' }}
              />
            </Box>
          </LightTooltip>
        </Stack>
        <Link
          href={`${getUrlBySocialPlatformId(profile_type)}${at}${urluid}`}
          underline="none"
          onClick={(e) => {
            e.stopPropagation();
          }}
          target="_blank"
        >
          <Typography variant="body2" color="text.secondary" lineHeight="18px">
            <Typography component={'span'} color="grey.50">
              @{unique_id}
            </Typography>
          </Typography>
        </Link>
        <Divider sx={{ mt: '20px', mb: '18px' }} />
        <Stack direction="row" justifyContent={'space-between'}>
          <IconCounts icon={
            <FollowerPlusIcon sx={{width: 14, height:15 }} fill={theme.palette.base["black"]} />
          } count={followers} tips={ intl.formatMessage(Messages.followers) } />
          {
            avg_views !== "0" && <IconCounts icon={
              <Eye3Icon sx={{width: 15,height:12, fill: "none" }} stroke={theme.palette.base["black"]} />
            } count={avg_views} tips={ intl.formatMessage(Messages.avgViews) } />
          }
          <IconCounts icon={
            <AverageErIcon sx={{width: 16,height:14 }} fill={theme.palette.base["black"]} />
          } count={engagementRate} tips={intl.formatMessage(Messages.engagementRate)} />
        </Stack>
        {sponsoredList.length > 0 && <Stack sx={{ marginTop: "0.5rem", position: "relative" }}>
          <LightTooltip title={intl.formatMessage(Messages.sponsoredBrand)} >
            <Box sx={{width: '16px',height: '16px',lineHeight: '16px',textAlign: 'center',marginBottom: "2px"}} >
              <SponsoredBrandIcon sx={{width: 14,height:15, fill: "none" }} stroke={theme.palette.base["black"]} />
            </Box>
          </LightTooltip>
          <Stack direction="row" gap="10px" marginTop="5px">
            { sponsoredList.map((brand, index) =>
              <BrandLabel key={index} brand={brand} color={theme.palette.base.black} bgColor={theme.palette.neutral["300"]} />
            ) }
            { sponsoredCount > 0 &&
              <BrandLabel brand={'+ '+ sponsoredCount} color={theme.palette.neutral["200"]} 
                bgColor={theme.palette.grey["400"]} onClick={()=>setLabelState(true)} />
            }
            
          </Stack>
          {labelState && 
            <BrandLabelList
              brandList={brandList} 
              closeLabelBox={()=>setLabelState(false)}
              bottom="1.75rem"
            />}
        </Stack>}
        {content_style.length > 0 && (
          <>
            <Divider sx={{ mt: '10px', mb: '8px' }} />
            <ListItemText
              primary={
                <Typography fontSize={'16px'}>
                  {capitalize(intl.formatMessage(Messages.ContentStyles))}
                </Typography>
              }
              secondary={
                <Stack
                  rowGap="0.5rem"
                  columnGap="0.5rem"
                  direction="row"
                  flexWrap={'wrap'}
                  marginTop="0.5rem"
                  component={'span'}
                >
                  {content_style.map((v, index) => (
                    <Chip
                      label={capitalize(v)}
                      key={v}
                      component="span"
                      sx={{
                        backgroundColor: bgcolor(index),
                        '& .MuiChip-label': {
                          color: color(index),
                          fontWeight: 600,
                          fontSize: '12px',
                        },
                        height: '22px',
                      }}
                    ></Chip>
                  ))}
                </Stack>
              }
            />
          </>
        )}
        {characteristics.length > 0 && (
          <>
            <Divider sx={{ mt: '10px', mb: '8px' }} />
            <ListItemText
              primary={
                <Typography fontSize={'16px'} sx={{ marginTop: '1rem' }}>
                  {capitalize(intl.formatMessage(Messages.characteristics))}
                </Typography>
              }
              secondary={
                <Stack
                  rowGap="0.5rem"
                  columnGap="0.5rem"
                  direction="row"
                  flexWrap={'wrap'}
                  marginTop="0.5rem"
                  component={'span'}
                >
                  {characteristics.map((v, index) => (
                    <Chip
                      label={capitalize(v)}
                      key={v}
                      component="span"
                      sx={{
                        backgroundColor: bgcolor(index),
                        '& .MuiChip-label': {
                          color: color(index),
                          fontWeight: 600,
                          fontSize: '12px',
                        },
                        height: '22px',
                      }}
                    ></Chip>
                  ))}
                </Stack>
              }
            />
          </>
        )}
      </CardContent>
    </Card>
  );

  return card;
}
