import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useGlobalStyles } from '../../assets/styles/style';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import CampaignCardHorizontal from '../components/CampaignCardHorizontal';
import { useContext, useState, useEffect } from 'react';
import { BrandContext } from '../context/context';
import CreatorRequirements from '../components/CreatorRequirements';
import TitledPanel from '../components/TitledPanel';
import HoverableImage from '../components/HoverableImage';
import FlexSpaceAroundLeftAligned from '../../components/FlexSpaceBetweenLeftAligned';
import { ChatThinIcon } from '../../assets/imgs/icons';
import DialogPage from './DialogPage';
import CreatorProposalDetails from './CreatorProposalDetails';
import {
  useGetCampaignApplicationsQuery,
  useGetCampaignDetailQuery,
  useGetCampaignEmailStatisticsQuery,
} from '../../redux/benaApi';
import moment from 'moment';
import { Messages } from '../../localization/Messages';
import { DisplayPreferencesSettingItemsInOrder } from '../campaign/PreferencesSetting';
import { staticURLMessaging, staticURLTiktok } from '../../route/urls';
import { convertToHTML } from '../../components/ShowMessage';

type BrandCampaignDetailsProps = {
  campaignId: string;
  embedMode?: boolean;
};
export default function BrandCampaignDetails({
  campaignId,
  embedMode = false,
}: BrandCampaignDetailsProps) {
  const theme = useTheme();
  const gs = useGlobalStyles();
  const intl = useIntl();
  const { currentDisplayedCreatorId } = useContext(BrandContext);

  const [creatorsCount, setCreatorsCount] = useState(0);

  const { data: campaign, refetch: refetchCampaign } =
    useGetCampaignDetailQuery({
      campaign_id: campaignId,
    });

  const campaignData = campaign?.data;

  useEffect(() => {
    refetchCampaign();
  }, [refetchCampaign]);

  if (!campaignData) return null;

  const campaignInfo = {
    platformIds: (campaignData.platforms || []) as SocialPlatformId[],
    launchedOn: moment(campaignData.start_date).format(`M/DD/YY`),
    logo: campaignData.brand_logo,
    title: campaignData.title,
    image: campaignData.cover_img,
    brandInfo: campaignData.brand_intro,
    pageUrl: campaignData.brand_link,
    campaignPeriod: `${moment(campaignData.start_date).format(
      `M/DD/YY`
    )} - ${moment(campaignData.end_date).format(`M/DD/YY`)}`,
    budget: `${campaignData.budget_currency || '$'}${
      campaignData.budget_amount
    }`,
    budget_max: campaignData.budget_amount_max
      ? `$${campaignData.budget_amount_max}`
      : undefined,
    freeProduct: campaignData.budget_mode === 'product_exchange_only',
    commission: campaignData.commission,
    commissionPercent: campaignData.commission_percent,
    products: campaignData.products || [],
  };

  const tags = DisplayPreferencesSettingItemsInOrder(
    campaignData.creator_requirements
  );

  const contentDirections = campaignData.content_direction;
  const caption = campaignData.captions;
  const videoDuration = campaignData.video_duration;
  const hashtags = campaignData.hashtags.join(', ');
  const otherAccounts = campaignData.tag_accounts.join(', ');

  const showDialog = !!currentDisplayedCreatorId;

  return (
    <>
      <Stack
        border={embedMode ? '0px' : `1px solid ${theme.palette.neutral[600]}`}
        borderTop="0px"
        borderRadius={'0px 0px 8px 8px'}
        padding={embedMode ? '24px 0px' : '20px 24px'}
      >
        <Stack rowGap={'27px'}>
          <CampaignCardHorizontal {...campaignInfo} />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.EmailOutreachStats)}
            children={
              <EmailOutherList campaignId={campaignId} />
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.TalkingToNCreators, {
              n: creatorsCount,
            })}
            children={
              <CreatorsList
                campaignId={campaignId}
                setCreatorsCount={setCreatorsCount}
              />
            }
          />
          <CreatorRequirements tags={tags} />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.ContentDirection)}
            children={
              <Typography
                sx={{ whiteSpace: 'pre-line', lineHeight: '26px' }}
                color="grey.400"
                fontSize={'14px'}
                fontWeight={400}
              >
                {contentDirections}
              </Typography>
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Caption)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {convertToHTML(caption || '')}
              </Typography>
            }
          />
          <Stack
            direction="row"
            columnGap={'16px'}
            rowGap={'16px'}
            flexWrap={'wrap'}
            alignItems={'flex-start'}
          >
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.VideoDurations)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {videoDuration} second{videoDuration > 1 ? 's' : ''}
                </Typography>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.Hashtags)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {hashtags || '-'}
                </Typography>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.OtherAccounts)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {otherAccounts || '-'}
                </Typography>
              }
            />
          </Stack>
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Files)}
            children={
              <Stack
                direction="row"
                flexWrap={'wrap'}
                rowGap="10px"
                columnGap={'10px'}
              >
                {(campaignData.visual_medias || []).length > 0 ? (
                  <>
                    {(campaignData.visual_medias || []).map((w: any) => (
                      <HoverableImage
                        fileLink={w.file}
                        fileName={w.name}
                        fileType={w.content_type}
                        key={w.id}
                      />
                    ))}
                    <FlexSpaceAroundLeftAligned width={'224px'} />
                  </>
                ) : (
                  <Typography
                    fontWeight={400}
                    color="grey.400"
                    fontSize={'14px'}
                  >
                    {intl.formatMessage(Messages.NoFileFound)}
                  </Typography>
                )}
              </Stack>
            }
          />
        </Stack>
      </Stack>
      {showDialog && campaignData.id && (
        <DialogPage
          initOpen={showDialog}
          children={
            campaignId ? (
              <CreatorProposalDetails
                campaignId={campaignData.id}
                creatorId={currentDisplayedCreatorId}
              />
            ) : (
              <></>
            )
          }
        />
      )}
    </>
  );
}

function EmailOutherList({
  campaignId
}:{
  campaignId: string
}){
  const theme = useTheme();
  const inlt = useIntl();
  const { data: refetchData, refetch } = useGetCampaignEmailStatisticsQuery({ campaignId });
  const [statistics, setStatistics] = useState<EmailCampaignStatistics | null>(null);
  useEffect(()=>{
    refetch()
  },[refetch]);
  useEffect(()=>{
    if(refetchData){
      setStatistics(refetchData.data);
    }
  },[refetchData]);
  const Container = function({
    title, value, color
  }:{
    title:string, value:number | string, color:string
  }){
    return (
      <Stack direction="row" gap="15px" alignItems="center"
        border={`1px solid ${theme.palette.neutral[600]}`}
        padding="8px 16px"
        borderRadius="8px">
        <Typography fontSize="1rem" fontWeight="400" lineHeight="24px">{title}</Typography>
        <Typography fontSize="1.375rem" style={{color:color}} lineHeight="30px">{value}</Typography>
      </Stack>
    )
  }
  return (
    statistics && <Stack direction="row" flexWrap="wrap" gap="16px">
      <Container title={ inlt.formatMessage(Messages.totalEmails) }
        value={statistics.total} color={ theme.palette.primary[800] }/>
      <Container title={ inlt.formatMessage(Messages.autoSend) }
        value={statistics.auto_emails} color={ theme.palette.success[600] }/>
      <Container title={ inlt.formatMessage(Messages.emailResend) }
        value={statistics.resend_emails} color={ theme.palette.secondary[950] }/>
      <Container title={ inlt.formatMessage(Messages.emailDraft) }
        value={statistics.draft_emails} color={ theme.palette.warning[700] }/>
      <Container title={ inlt.formatMessage(Messages.shippingStatusFAILURE) }
        value={statistics.failure_emails} color={ theme.palette.danger[600] }/>
    </Stack>
  )
};

export function CreatorsList({
  campaignId,
  setCreatorsCount,
}: {
  campaignId: string;
  setCreatorsCount: fnNumberToVoid;
}) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const size = 16;
  const {
    data: applications,
    refetch: refetchApplications,
    isFetching,
    isLoading,
  } = useGetCampaignApplicationsQuery({
    campaign_id: campaignId,
    page,
    size,
  });

  useEffect(() => {
    refetchApplications();
  }, [refetchApplications]);

  const _creators = applications?.data
    ? applications?.data.items.map((x) => ({
        // TODO update from backend response
        image: x.creator?.picture || '',
        id: x.creator?.id || '',
        username: x.creator?.unique_id || '',
        displayName: x.creator?.name || '',
        followers: x.creator?.profile?.followers || 0,
        user_id: x.user_id || '',
      }))
    : [];

  const context = useContext(BrandContext);
  const viewCreator = (id: string) => {
    context.setCurrentDisplayedCreatorId(id);
  };

  const [creators, setCreators] = useState<typeof _creators>([]);
  useEffect(() => {
    const filtered = _creators.filter(
      (w) => !creators.find((x) => x.id === w.id)
    );
    setCreators((state) => [...state, ...filtered]);
    typeof applications?.data.total === 'number' &&
      setTotal(applications?.data.total);
  }, [applications]);

  useEffect(() => {
    setCreatorsCount(applications?.data.total || creators.length);
  }, [applications, creators, setCreatorsCount]);

  useEffect(() => {
    setCreators([]);
  }, [campaignId]);

  return (
    <>
      <Stack direction="row" rowGap="16px" columnGap="16px" flexWrap={'wrap'}>
        {creators.map((creator, index) => {
          const { image, username, followers, id, displayName } = creator;
          return (
            <CreatorBasicInfoCard
              key={`${index}`}
              id={id}
              viewCreator={viewCreator}
              image={image}
              username={username}
              followers={followers}
              userId={creator.user_id}
              displayName={displayName}
            />
          );
        })}
      </Stack>

      {total > creators.length && !isFetching && !isLoading && (
        <Stack justifyContent={'flex-end'} marginTop="8px">
          <Button
            onClick={() => {
              setPage((page) => page + 1);
            }}
            className={gs.buttonSelected}
            disabled={isFetching || isLoading}
          >
            {intl.formatMessage(Messages.Loadmore)}
          </Button>
        </Stack>
      )}
    </>
  );
}

type CreatorBasicInfoCardProps = {
  id: string;
  key: string;
  viewCreator: fnStringToVoid;
  image: string;
  username: string;
  followers: number;
  userId: string;
  displayName: string;
  fullWidth?: boolean;
  imgMaxWidth?: string;
  hoverable?: boolean;
};
export function CreatorBasicInfoCard({
  id,
  key,
  viewCreator,
  image,
  username,
  followers,
  userId,
  displayName,
  fullWidth = false,
  imgMaxWidth = '72px',
  hoverable = true,
}: CreatorBasicInfoCardProps) {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <IconButton
      onClick={() => viewCreator(id)}
      disableRipple
      sx={{
        padding: 0,
        width: fullWidth ? '100%' : 'auto',
      }}
      key={key}
    >
      <Stack
        direction="row"
        border={`1px solid ${theme.palette.neutral[600]}`}
        padding="16px"
        borderRadius="8px"
        minWidth={'278px'}
        width={fullWidth ? '100%' : '278px'}
        sx={{
          '&:hover': {
            border: hoverable
              ? `1px solid ${theme.palette.grey[700]}`
              : '1px solid transparent',
          },
          boxShadow: `0px 1px 2px rgba(25, 21, 40, 0.04)`,
        }}
      >
        <Card
          sx={{
            minWidth: '60px',
            maxWidth: imgMaxWidth,
            borderRadius: '6px',
            border: '0',
          }}
        >
          <CardMedia
            component="img"
            height="100%"
            image={image}
            alt="creator"
          />
        </Card>
        <CardContent
          sx={{ paddingTop: '0px', paddingBottom: '0px !important' }}
        >
          <Stack justifyContent={'space-between'} rowGap="4px">
            <Link
              href={
                staticURLTiktok +
                (username.startsWith('@') ? username : '@' + username)
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
              target="_blank"
            >
              <Typography
                color="primary.600"
                fontSize={'16px'}
                fontWeight={500}
                textAlign="left"
              >
                @{username}
                <br />
                <Typography
                  color="grey.900"
                  fontSize={'12px'}
                  fontWeight={400}
                  maxWidth={'120px'}
                  className="ellipsis"
                  component="span"
                >
                  {displayName}
                </Typography>
              </Typography>
            </Link>

            <Typography
              color="grey.700"
              fontSize={'14px'}
              fontWeight={400}
              textAlign={'left'}
            >
              {intl.formatNumber(followers, {
                notation: 'compact',
              })}
            </Typography>
            <Box flexGrow={1}></Box>
            <Link
              href={staticURLMessaging + '?id=' + userId}
              onClick={(e) => {
                e.stopPropagation();
              }}
              target="_blank"
              className="boxlefthcenterv"
            >
              <ChatThinIcon sx={{ width: '18px', height: '18px' }} />
            </Link>
          </Stack>
        </CardContent>
      </Stack>
    </IconButton>
  );
}
