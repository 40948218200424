import { useIntl } from 'react-intl';
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { useGlobalStyles } from '../../assets/styles/style';
import { UserAccountMessages } from './Messages';
import {
  MAXEMAILADDRESSLENGTH,
  numberFilter,
  ValidateRules,
  VERIFICATIONCODECOUNTDOWNTIMER,
} from '../../utils/common';
import {
  useLazyValidateEmailVerificationQuery,
  useSendEmailVerificationCodeMutation,
} from '../../redux/benaApi';
import SendCodeButton from '../../components/SendCodeButton';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../../redux/benaApiResultHandler';
import LogoAndLanguage from './LogoAndLanguage';
import { useTypedSelector } from '../../redux/store';
import { selectAuth } from './AuthSlice';
import GoBack from './GoBack';
import { useLanguage } from '../../hooks/language';

type ForgotPasswordProps = {
  nextStep: (data: ResetPasswordRequestParams) => void;
  forgotPasswordData: ForgotPasswordType | null;
  setTemporaryForgotPasswordData: (data: ForgotPasswordType) => void;
};

export default function ForgotPassword({
  nextStep,
  forgotPasswordData,
  setTemporaryForgotPasswordData,
}: ForgotPasswordProps) {
  const theme = useTheme();
  const intl = useIntl();
  const globalStyles = useGlobalStyles();
  const email =
    useTypedSelector((state) => selectAuth(state)).user?.email || '';

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputDataType,
    key: string
  ) => {
    let str = event.target.value.slice(0, data.maxLength);
    if (data.inputValueType === 'verificationCode') {
      str = numberFilter(str);
    }
    setFormData({
      ...formData,
      [key]: {
        ...data,
        content: str,
        length: event.target.value.length,
        error: !ValidateRules.validInputString(
          str,
          data.maxLength,
          data.inputValueType
        ),
      },
    });
    setTemporaryForgotPasswordData({
      emailAddress: formData.emailAddress,
      verificationCode: { ...formData.verificationCode, content: '' },
    });
  };

  const [formData, setFormData] = useState<ForgotPasswordType>(
    forgotPasswordData || {
      emailAddress: {
        content: email,
        maxLength: MAXEMAILADDRESSLENGTH,
        error: false,
        inputValueType: 'email',
      },
      verificationCode: {
        content: '',
        maxLength: 6,
        error: false,
        inputValueType: 'verificationCode',
      },
    }
  );

  const refEmailAddress = useRef<HTMLElement>(null);
  const refVerificationCode = useRef<HTMLElement>(null);
  useEffect(() => {
    refEmailAddress.current?.focus();
  }, []);

  const inputList = [
    {
      item: formData.emailAddress,
      message: UserAccountMessages.emailAddress,
      name: 'emailAddress',
      refEl: refEmailAddress,
      placeHolder: UserAccountMessages.enterEmailAddress,
      children: <Box sx={{ height: '0rem' }}></Box>,
    },
    {
      item: formData.verificationCode,
      message: UserAccountMessages.verificationCode,
      name: 'verificationCode',
      refEl: refVerificationCode,
      placeHolder: UserAccountMessages.enter6DigitalCode,
      children: (
        <Box sx={{ mb: '0.875rem' }}>
          <Typography
            variant="subtitle1"
            fontFamily="Outfit"
            sx={{
              color: theme.custom.gray,
              position: 'relative',
            }}
            component="span"
          >
            {intl.formatMessage(UserAccountMessages.didntGetACode)}
            <Typography
              sx={{ mx: '0.25rem', color: theme.custom.gray }}
              fontFamily="Outfit"
              component="span"
            >
              {intl.formatMessage(UserAccountMessages.checkSpanFolder)}
            </Typography>
            <Typography
              sx={{ mx: '0.25rem', color: theme.custom.gray }}
              fontFamily="Outfit"
              component="span"
            >
              {intl.formatMessage(UserAccountMessages.orRetry)}
            </Typography>
            <Typography sx={{ color: theme.custom.gray }} component="span">
              {intl.formatMessage(UserAccountMessages.sendCode)}
            </Typography>
            {'.'}
          </Typography>
        </Box>
      ),
    },
  ] as Array<FormTextInputType>;

  const isValidForSave = function (
    inputs: typeof inputList,
    saveFormData = true
  ): boolean {
    for (let i = 0; i < inputs.length; i++) {
      const { item: input, refEl: ref, name: key } = inputs[i];
      if (
        !ValidateRules.validInputString(
          input.content,
          input.maxLength,
          input.inputValueType
        )
      ) {
        if (saveFormData) {
          setFormData({
            ...formData,
            [key]: { ...input, error: true },
          });
          ref.current?.focus();
        }
        return false;
      }
    }

    return saveFormData ? true : !submitting;
  };
  const [submitting, setSubmitting] = useState(false);

  const [sendEmailVerificationCode, sendCodeResult] =
    useSendEmailVerificationCodeMutation();
  const lang = useLanguage();

  const [emailExist, setEmailExist] = useState(false);
  // if email exists after get code verification, stop countdown
  useEffect(() => {
    if (emailExist) {
      setCountDown(0);
      setSubmitting(false);
    }
  }, [emailExist]);

  const handleGetVerificationCode = async () => {
    if (isValidForSave(inputList.slice(0, 1))) {
      setSubmitting(true);
      setEmailExist(false);
      await MutationHandler(
        {
          intl,
          action: async () =>
            await sendEmailVerificationCode({
              email: formData.emailAddress.content,
              language: lang,
              existing_user: true,
            }).unwrap(),
          onException: (statusCode) => {
            statusCode === 4009 && setEmailExist(true);
          },
        },
        UserAccountMessages.showMessageSendCodeSuccess
      );
      setCountDown(VERIFICATIONCODECOUNTDOWNTIMER);
    }
  };

  const [countDown, setCountDown] = useState<number>(0);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countDown > 0) {
      timer = setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [countDown]);

  const [triggerVerifyCode, result] = useLazyValidateEmailVerificationQuery({});
  useEffect(() => {
    LazyQueryResultHandler<ValidateEmailVerificationResponseType>({
      intl,
      result: result as QueryResultType<ValidateEmailVerificationResponseType>,
      onSuccess: (data) => {
        nextStep({
          email: formData.emailAddress.content,
          token: data.token,
          new_password: '',
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]); // only result change here

  const handleContinue = async () => {
    if (isValidForSave(inputList)) {
      setSubmitting(true);
      await triggerVerifyCode({
        email: formData.emailAddress.content,
        code: formData.verificationCode.content
      });
      setSubmitting(false);
    }
  };

  // wait 10 seconds after click send verification code
  useEffect(() => {
    countDown <= 50 && setSubmitting(false);
  }, [countDown, setSubmitting]);

  return (
    <Box className="form-width">
      <LogoAndLanguage />
      <GoBack />
      <Typography fontSize="1.5rem" fontWeight="700">
        {intl.formatMessage(UserAccountMessages.forgotPassword)}
      </Typography>
      <Typography fontSize="0.875rem" fontFamily="Outfit" sx={{ my: '1rem' }}>
        {intl.formatMessage(UserAccountMessages.forgotPasswordHint)}
      </Typography>
      <form>
        {inputList.map((data) => {
          const { item, message, name, refEl, placeHolder, children } = data;
          return (
            <Box
              key={message.id}
              className={'boxcenterhv'}
              sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <Typography
                sx={{
                  mb: 1.75,
                  fontSize: '0.625rem',
                  fontWeight: '600',
                }}
                component="div"
                className={'boxcenterhv'}
              >
                {intl.formatMessage(message).toUpperCase()}
                <Typography
                  sx={{
                    color: theme.palette.danger[400],
                    ml: '0.25rem',
                    fontSize: '0.75rem',
                    alignSelf: 'stretch',
                  }}
                >
                  *
                </Typography>
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  placeholder={intl.formatMessage(placeHolder)}
                  inputRef={refEl}
                  sx={{
                    mb: children ? 2 : 3,
                    ...(name === 'verificationCode'
                      ? {
                          '&>input': {
                            letterSpacing: '0.25rem',
                            fontFamily: 'Outfit',
                            fontSize: '0.875rem',
                            fontWeight: 'bold',
                          },
                        }
                      : {}),
                  }}
                  className={`border-radius-6px input-size`}
                  id={message.id}
                  value={item.content}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleTextInputChange(event, item, name);
                  }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={(theme) => ({ fontSize: theme.typography.caption })}
                    >
                      {name === 'emailAddress' ? (
                        <SendCodeButton
                          countDown={countDown}
                          onClick={() =>
                            !submitting && handleGetVerificationCode()
                          }
                          message={UserAccountMessages.sendCode}
                          countDownMessage={UserAccountMessages.resendCodeIn}
                          timeUnit={UserAccountMessages.textSecondInAbbr}
                          isSending={sendCodeResult.isLoading}
                        />
                      ) : (
                        /* `${item.content.length} / ${item.maxLength}` */ ''
                      )}
                    </InputAdornment>
                  }
                  error={item.error}
                />
                {children}
              </FormControl>
            </Box>
          );
        })}
        <Box>
          <Button
            type="submit"
            className={`border-radius-6px button-size`}
            onClick={(e) => {
              e.preventDefault();
              handleContinue();
            }}
            disabled={!isValidForSave(inputList, false)}
            sx={{ mt: '1.5rem' }}
            variant="contained"
          >
            {intl.formatMessage(UserAccountMessages.Next)}
          </Button>
        </Box>
      </form>
    </Box>
  );
}
