import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  capitalize,
  Chip,
  Dialog,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useState, useEffect, useRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DeleteIcon } from '../../assets/imgs/icons';
import { useGlobalStyles } from '../../assets/styles/style';
import InputRequired from '../../components/InputRequired';
import { showMessage } from '../../components/ShowMessage';
import { TransitionUp } from '../../components/Transitions';
import {
  useContentStylesOptions,
  useCharacteristicsOptions,
} from '../../config/options';
import { useFilterCountriesByName } from '../../hooks/countries';
import { Messages } from '../../localization/Messages';
import {
  useAdminDeleteCreatorMutation,
  useAdminPatchCreatorMutation,
} from '../../redux/benaAdminApi';
import { useLazyGetCreatorQuery } from '../../redux/benaApi';
import { useTypedSelector } from '../../redux/store';
import { getCreatorFilterOptions } from '../search/CreatorsFilter';
import { adminSetSelectedCreator, selectAdmin } from './AdminSlice';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { ModeratedItemsContext } from '../../context/context';
import BenaCheckbox from '../../components/BenaCheckbox';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../../redux/benaApiResultHandler';

const moderatedIcon = (
  <PublishedWithChangesIcon
    sx={{ width: '1rem', height: '1rem' }}
    color="warning"
  />
);

type CreatorModerationProps = {
  creator: CreatorData;
};
export default function CreatorModeration({ creator }: CreatorModerationProps) {
  const intl = useIntl();
  const gs = useGlobalStyles();
  const dispatch = useDispatch();
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(adminSetSelectedCreator(creator));
  };
  const { is_moderated } = creator;
  const { items, deleted, notifyDelete } = useContext(ModeratedItemsContext);

  const [deleteCreator] = useAdminDeleteCreatorMutation({});
  const deleteHandler = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    const confirmed = window.confirm(
      'Please make sure you really want to delete?'
    );
    if (confirmed) {
      const id = creator.id;
      // handle delete creator
      await MutationHandler({
        intl,
        action: async () => {
          return await deleteCreator({ id }).unwrap();
        },
        onSuccess: () => {
          notifyDelete?.(id);
        },
      });
    }
  };
  const isCreatorDeleted = deleted[creator.id];

  return (
    <Stack
      alignItems={'center'}
      justifyContent="center"
      padding="0.25rem"
      direction="row"
      spacing="0.5rem"
    >
      {isCreatorDeleted && (
        <Typography color="danger.400" padding="0.5rem" sx={{ cursor: 'auto' }}>
          Deleted
        </Typography>
      )}
      {!isCreatorDeleted && (
        <>
          <Button className={gs.buttonPrimary} onClick={clickHandler}>
            Moderation
            {(is_moderated || items?.[creator.id]) && (
              <>&nbsp;&nbsp;{moderatedIcon}</>
            )}
          </Button>
          <Button className={gs.buttonDeletion} onClick={deleteHandler}>
            Delete
          </Button>
        </>
      )}
    </Stack>
  );
}

const extractDataFromResponse = (res: CreatorDataDetails) => {
  const {
    name,
    gender,
    age,
    description,
    geo,
    keywords: _keywords,
    topics,
    creativity_index,
    content_style,
    characteristics,
    is_moderated,
  } = res;
  // backend returns types string|string[], add update here;
  // better backend returns only string[]
  const keywords =
    typeof _keywords === 'string'
      ? (_keywords as string).split(',')
      : _keywords;
  return {
    name,
    gender,
    age,
    description,
    geo,
    keywords,
    topics,
    creativity_index,
    content_style,
    characteristics,
    is_moderated,
  };
};

const initCreatorData = {
  name: '',
  gender: '',
  age: null as number | null,
  description: '',
  geo: '',
  keywords: [] as string[],
  topics: [] as string[],
  creativity_index: null as number | null,
  content_style: [] as string[],
  characteristics: [] as string[],
  is_moderated: true,
};

export function ModerationDialog() {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const dispatch = useDispatch();
  const { setItem } = useContext(ModeratedItemsContext);

  // creator data from redux
  const selectedCreator = useTypedSelector((state) =>
    selectAdmin(state)
  ).selectedCreator;

  // dialog visibility control
  const [open, setOpen] = useState(!!selectedCreator);
  const handleClose = (reason: string) => {
    if (!reason) {
      setOpen(false);
      dispatch(adminSetSelectedCreator(null));
    }
  };
  useEffect(() => setOpen(true), [selectedCreator]);

  // creator data update
  const [data, setData] = useState<typeof initCreatorData>(initCreatorData);
  useEffect(() => {
    if (selectedCreator) {
      const newData = { ...initCreatorData };
      setData(newData);
    }
  }, [selectedCreator]);
  // refrech creator data for update
  const [getCreatorDetails, result] = useLazyGetCreatorQuery({});
  useEffect(() => {
    if (selectedCreator?.id) {
      setTimeout(async () => {
        getCreatorDetails({
          id: selectedCreator?.id,
        });
      });
    }
  }, [selectedCreator]);

  useEffect(() => {
    LazyQueryResultHandler<CreatorDataDetails>({
      intl,
      result: result as QueryResultType<CreatorDataDetails>,
      onSuccess: (resdata) => {
        setData(extractDataFromResponse(resdata));
      },
    });
  }, [result]);

  const handleChange = (
    key: string,
    value: string | string[] | number | null
  ) => {
    const n = parseInt(value?.toString() || '');
    const v =
      key === 'age' || key === 'creativity_index' ? (isNaN(n) ? '' : n) : value;
    setData({ ...data, [key]: v });
  };

  // options
  const { creatorsGenderOptions } = getCreatorFilterOptions(intl);
  const filterCountriesFn = useFilterCountriesByName();
  const filteredCountries = filterCountriesFn('');
  const contentStylesOptions = useContentStylesOptions();
  const characteristicsOptions = useCharacteristicsOptions();

  const contentStylesValueOptions = contentStylesOptions.map((v) => v.value);
  const characteristicsValueOptions = characteristicsOptions.map(
    (v) => v.value
  );

  // patch creator data
  const validForPatch = () => {
    return !!(data.geo && data.name);
  };

  const [patch, patchResult] = useAdminPatchCreatorMutation({});
  const patching = patchResult.isLoading;
  const handlePatch = async () => {
    const id = selectedCreator?.id;
    if (!id) return;
    const ret = await patch({
      id,
      // set is_moderated to be true all the time
      body: { ...data, is_moderated: true },
    }).unwrap();
    if (ret?.status?.status_code === 1000) {
      showMessage.info('Updated successfully');
      // update creator data
      setData(extractDataFromResponse(ret.data));
      setItem(selectedCreator?.id);
      handleClose('');
    } else {
      showMessage.warning(ret?.status?.message);
    }
  };

  // ui elements data
  const topicsRef = useRef<HTMLInputElement>(null);
  const contentStylesRef = useRef<HTMLInputElement>(null);
  const characteristicsRef = useRef<HTMLInputElement>(null);
  const keywordsRef = useRef<HTMLInputElement>(null);
  const inputValuesRef = useRef({
    topics: '',
    keywords: '',
    content_style: '',
    characteristics: '',
  });
  const onKeyPress = (
    e: React.KeyboardEvent,
    key: keyof typeof inputValuesRef.current
  ) => {
    if (e.key === 'Enter') {
      const d = data[key];
      const v = typeof d === 'string' ? (d as string).split(',') : d.slice();
      const newValue = inputValuesRef.current[key];
      if (!v.find((w) => w === newValue)) {
        handleChange(key, [...v, newValue]);
      }
      (e.currentTarget as HTMLInputElement).value = '';
    }
  };
  const clearInputValues = (key: keyof typeof inputValuesRef.current) => {
    handleChange(key, []);
    if (key === 'topics' && topicsRef.current) {
      topicsRef.current.value = '';
    }
    if (key === 'keywords' && keywordsRef.current) {
      keywordsRef.current.value = '';
    }
    if (key === 'content_style' && contentStylesRef.current) {
      contentStylesRef.current.value = '';
    }
    if (key === 'characteristics' && characteristicsRef.current) {
      characteristicsRef.current.value = '';
    }
  };

  const {
    name,
    gender,
    age,
    description,
    geo,
    keywords,
    topics,
    content_style,
    characteristics,
    creativity_index: cinidex,
    is_moderated,
  } = data;

  const creativity_index =
    !cinidex || cinidex > 3 || cinidex < 1 ? '' : cinidex;

  return selectedCreator ? (
    <Dialog
      open={open}
      onClose={(e, reason) => handleClose(reason)}
      TransitionComponent={TransitionUp}
      sx={{ zIndex: theme.zIndex.modal }}
      maxWidth={'lg'}
    >
      <Stack padding="2rem" columnGap={'2rem'} rowGap="1rem">
        <Stack
          direction="row"
          bgcolor={theme.palette.primary[400]}
          padding="1rem"
          borderRadius={'8px'}
          columnGap="2rem"
          minWidth={'900px'}
        >
          <img
            src={selectedCreator.picture}
            className="avatar"
            style={{
              width: '110px',
              height: '108px',
              flexShrink: 0,
              borderRadius: '8px',
            }}
            alt="avatar"
          />
          <Stack>
            <Typography variant="h5">{selectedCreator.name}</Typography>
            <Typography color="grey.50">
              @{selectedCreator.unique_id}
            </Typography>
          </Stack>
        </Stack>
        <Stack minWidth="400px" rowGap={'0.5rem'}>
          <Typography>
            {capitalize('name')}
            <InputRequired />
          </Typography>
          <TextField
            value={name || ''}
            onChange={(e) => handleChange('name', e.target.value)}
          ></TextField>
          <Typography>{capitalize('gender')}</Typography>
          <FormControl fullWidth>
            <Select
              id="gender"
              value={gender}
              onChange={(e) => {
                handleChange('gender', e.target.value);
              }}
            >
              {creatorsGenderOptions.map((v) => (
                <MenuItem value={v.value} key={v.value}>
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography>{capitalize('age')}</Typography>
          <TextField
            value={age || ''}
            onChange={(e) => handleChange('age', e.target.value)}
          ></TextField>
          <Typography>{capitalize('creativity index (1-3)')}</Typography>
          <FormControl fullWidth>
            <Select
              id="creativity_index"
              value={creativity_index}
              onChange={(e) => {
                handleChange('creativity_index', e.target.value);
              }}
            >
              {['1', '2', '3'].map((v) => (
                <MenuItem value={v} key={v}>
                  {v}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography>{capitalize('description')}</Typography>
          <TextField
            value={description || ''}
            onChange={(e) => handleChange('description', e.target.value)}
          ></TextField>
          <Typography>
            {capitalize('geo')} <InputRequired />
          </Typography>
          <Autocomplete
            disableCloseOnSelect
            id={'geo'}
            options={filteredCountries.concat([''])}
            onChange={(e, newValue) => handleChange('geo', newValue)}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography variant="caption">{option}</Typography>
              </li>
            )}
            value={geo || ''}
            renderInput={(params) => (
              <TextField
                label=""
                {...params}
                placeholder={capitalize(intl.formatMessage(Messages.choose))}
                sx={{
                  '& input': {
                    padding: '0 !important',
                    margin: '0 !important',
                    paddingLeft: 16,
                  },
                }}
              />
            )}
            fullWidth
          />

          <Typography>
            {capitalize(intl.formatMessage(Messages.contentStyles))}
          </Typography>
          <Autocomplete
            disableCloseOnSelect
            id={'contentStyle'}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 'auto',
                minHeight: '3rem',
              },
            }}
            multiple
            options={contentStylesValueOptions.concat([''])}
            getOptionDisabled={(option) => option === ''}
            onChange={(e, newValue) =>
              handleChange(
                'content_style',
                Array.isArray(newValue) ? newValue : [newValue]
              )
            }
            renderOption={(props, option, { selected }) =>
              option && (
                <MenuItem {...props}>
                  <BenaCheckbox checked={selected} />
                  <Typography variant="caption">
                    {contentStylesOptions.find((w) => w.value === option)
                      ?.label || ''}
                  </Typography>
                </MenuItem>
              )
            }
            value={content_style}
            renderInput={(params) => (
              <TextField
                label=""
                {...params}
                placeholder={capitalize(intl.formatMessage(Messages.choose))}
                sx={{
                  '& input': {
                    padding: '0 !important',
                    margin: '0 !important',
                    paddingLeft: 16,
                  },
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  sx={{ pr: 1 }}
                  label={
                    contentStylesOptions.find((w) => w.value === option)
                      ?.label || ''
                  }
                  {...getTagProps({ index })}
                  deleteIcon={
                    <DeleteIcon
                      stroke={theme.palette.primary[600]}
                      sx={{ width: '0.5rem', height: '0.5rem' }}
                    />
                  }
                />
              ))
            }
            fullWidth
          />

          <Typography>
            {capitalize(intl.formatMessage(Messages.characteristics))}
          </Typography>
          <Autocomplete
            disableCloseOnSelect
            id={'characteristics'}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 'auto',
                minHeight: '3rem',
              },
            }}
            multiple
            options={characteristicsValueOptions.concat([''])}
            getOptionDisabled={(option) => option === ''}
            onChange={(e, newValue) =>
              handleChange(
                'characteristics',
                Array.isArray(newValue) ? newValue : [newValue]
              )
            }
            renderOption={(props, option, { selected }) =>
              option && (
                <MenuItem {...props}>
                  <BenaCheckbox checked={selected} />
                  <Typography variant="caption">
                    {characteristicsOptions.find((w) => w.value === option)
                      ?.label || ''}
                  </Typography>
                </MenuItem>
              )
            }
            value={characteristics}
            renderInput={(params) => (
              <TextField
                label=""
                {...params}
                placeholder={capitalize(intl.formatMessage(Messages.choose))}
                sx={{
                  '& input': {
                    padding: '0 !important',
                    margin: '0 !important',
                    paddingLeft: 16,
                  },
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  sx={{ pr: 1 }}
                  label={
                    characteristicsOptions.find((w) => w.value === option)
                      ?.label || ''
                  }
                  {...getTagProps({ index })}
                  deleteIcon={
                    <DeleteIcon
                      stroke={theme.palette.primary[600]}
                      sx={{ width: '0.5rem', height: '0.5rem' }}
                    />
                  }
                />
              ))
            }
            fullWidth
          />
          <Stack direction={'column'} minWidth="25%">
            <Typography>
              {capitalize(intl.formatMessage(Messages.topics))}
            </Typography>
            <Stack
              direction="row"
              flexWrap={'wrap'}
              alignItems="center"
              rowGap="0.25rem"
              columnGap={'0.5rem'}
              border={'1px solid ' + theme.palette.neutral['50_2']}
              padding={'0rem 0.5rem'}
              borderRadius="0.5rem"
              sx={{ backgroundColor: theme.palette.neutral['50_2'] }}
            >
              {topics.map((value, index) => (
                <Chip
                  key={value + '' + index}
                  label={value}
                  sx={{ pr: 1 }}
                  deleteIcon={
                    <DeleteIcon
                      stroke={theme.palette.primary[600]}
                      sx={{ width: '0.5rem', height: '0.5rem' }}
                    />
                  }
                  onDelete={() => {
                    const newTopics = topics.slice();
                    newTopics.splice(index, 1);
                    handleChange('topics', [...newTopics]);
                  }}
                />
              ))}
              <Input
                disableUnderline
                sx={{ width: '10rem' }}
                placeholder={capitalize(
                  intl.formatMessage(Messages.typeAndPressEnter)
                )}
                onKeyUp={(e) => onKeyPress(e, 'topics')}
                onChange={(e) =>
                  (inputValuesRef.current['topics'] = e.target.value)
                }
                inputRef={topicsRef}
                fullWidth
                title={capitalize(
                  intl.formatMessage(Messages.typeAndPressEnter)
                )}
              ></Input>
              <Box flexGrow={1}></Box>
              <IconButton
                disableRipple
                onClick={() => clearInputValues('topics')}
              >
                <Typography color="danger.400" sx={{ fontSize: '0.875rem' }}>
                  {capitalize(intl.formatMessage(Messages.clear))}
                </Typography>
              </IconButton>
            </Stack>
          </Stack>
          <Stack direction={'column'} minWidth="25%">
            <Typography>
              {capitalize(intl.formatMessage(Messages.keywords))}
            </Typography>
            <Stack
              direction="row"
              flexWrap={'wrap'}
              alignItems="center"
              rowGap="0.25rem"
              columnGap={'0.5rem'}
              border={'1px solid ' + theme.palette.neutral['50_2']}
              padding={'0rem 0.5rem'}
              borderRadius="0.5rem"
              sx={{ backgroundColor: theme.palette.neutral['50_2'] }}
            >
              {keywords.map((value, index) => (
                <Chip
                  key={value + '' + index}
                  label={value}
                  sx={{ pr: 1 }}
                  deleteIcon={
                    <DeleteIcon
                      stroke={theme.palette.primary[600]}
                      sx={{ width: '0.5rem', height: '0.5rem' }}
                    />
                  }
                  onDelete={() => {
                    const newKeywords = keywords.slice();
                    newKeywords.splice(index, 1);
                    handleChange('keywords', [...newKeywords]);
                  }}
                />
              ))}
              <Input
                disableUnderline
                sx={{ width: '10rem' }}
                placeholder={capitalize(
                  intl.formatMessage(Messages.typeAndPressEnter)
                )}
                onKeyUp={(e) => onKeyPress(e, 'keywords')}
                onChange={(e) =>
                  (inputValuesRef.current['keywords'] = e.target.value)
                }
                inputRef={keywordsRef}
                fullWidth
                title={capitalize(
                  intl.formatMessage(Messages.typeAndPressEnter)
                )}
              ></Input>
              <Box flexGrow={1}></Box>
              <IconButton
                disableRipple
                onClick={() => clearInputValues('keywords')}
              >
                <Typography color="danger.400" sx={{ fontSize: '0.875rem' }}>
                  {capitalize(intl.formatMessage(Messages.clear))}
                </Typography>
              </IconButton>
            </Stack>
          </Stack>
          <Box height="3rem"></Box>
          <LoadingButton
            className={gs.buttonPrimary}
            onClick={handlePatch}
            disabled={patching || !validForPatch()}
            loading={patching}
          >
            {intl.formatMessage(Messages.save)}&nbsp;&nbsp;
            {is_moderated && moderatedIcon}
          </LoadingButton>
          <LoadingButton
            className={gs.buttonSelected}
            onClick={() => handleClose('')}
            disabled={patching}
            loading={patching}
          >
            {intl.formatMessage(Messages.close)}
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  ) : (
    <></>
  );
}
