import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { useTheme } from './assets/styles/theme';
import './assets/styles/main.css';
import { IntlProvider } from 'react-intl';
import i18n from './localization/i18n';
import { useLanguage } from './hooks/language';
import MainRouter from './route/MainRouter';
import ignoreConsole from './debug/console';
import { ShowMessageComponent } from './components/ShowMessage';
import { BenaLoadingInProgress } from './components/BenaLoadingInProgress';
import { Box } from '@mui/material';
import { RCStoredCount } from './features/rocketchat/RCUnreadMesssagesCount';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RocketchatRealTimeAgent from './features/rocketchat/RocketchatRealTimeAgent';
import { IMContext } from './context/context';

function App() {
  const lang = useLanguage();
  const theme = useTheme();
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IntlProvider
        messages={i18n.getMessages(lang)}
        key={lang}
        locale={lang}
        defaultLocale={i18n.getDefaultLocale()}
      >
        <GoogleOAuthProvider clientId="676781562441-khp1bkcdo47oo6u7dlfml1qu3jldqcpf.apps.googleusercontent.com">
          <IMContext.Provider
            value={{
              unreadCount: unreadMessageCount,
              setUnreadCount: setUnreadMessageCount,
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.neutral[0],
                minHeight: '100vh',
              }}
            >
              <BenaLoadingInProgress />
              <MainRouter />
              <ShowMessageComponent />
              <RCStoredCount
                setMessageCount={() => {
                  return;
                }}
              />
            </Box>
          </IMContext.Provider>
        </GoogleOAuthProvider>
        <RocketchatRealTimeAgent />
      </IntlProvider>
    </ThemeProvider>
  );
}

function Root() {
  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <App />
        </PersistGate>
      </ReduxProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));
ignoreConsole();