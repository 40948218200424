import { useEffect, useState } from 'react';
import {
  useGetCampaignsQuery,
  useLazyGetCampaignDetailQuery,
} from '../../redux/benaApi';
import { LazyQueryResultHandler } from '../../redux/benaApiResultHandler';
import { useIntl } from 'react-intl';

export function useGetCampaignDatail() {
  const intl = useIntl();
  const [campaign, setCampaign] = useState<CampaignResponseType | null>(null);
  const [fetchCampaign, fetchCampaignResult] = useLazyGetCampaignDetailQuery(
    {}
  );
  const fetch = (id: string) => {
    id && fetchCampaign({ campaign_id: id });
  };
  useEffect(() => {
    if (fetchCampaignResult.isSuccess) {
      LazyQueryResultHandler<CampaignResponseType>({
        intl,
        result: fetchCampaignResult as QueryResultType<CampaignResponseType>,
        onSuccess: (resdata) => {
          setCampaign(resdata);
        },
      });
    }
  }, [fetchCampaignResult]);

  return { campaign, fetch, setCampaign };
}

export function useGetCampaigns(randomId = '') {
  const {
    data: _campaigns,
    refetch,
    isFetching,
    isLoading,
  } = useGetCampaignsQuery({
    page: 1,
    // TODO update size in case one brand has over 100 campaigns
    size: 100,
    seed: randomId,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const campaigns = _campaigns?.data.items || [];
  const loading = isFetching || isLoading;
  return { campaigns, refetch, loading };
}
