import { Box, capitalize } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Messages } from '../../localization/Messages';
import { CreatorCountryCode, getCountryNames } from '../../utils/countries';
import ChecklistSelect from '../../components/ChecklistSelect';
import SelectCustomPercent, {
  CustomCategoryItemPercent,
  DefaultMaleFemalePercent,
} from '../../components/inputs/SelectCustomPercent';
import SelectCustomSlider from '../../components/inputs/SelectCustomSlider';
import {
  CreatorsFilterT,
  initCreatorFilter,
} from '../../features/search/CreatorsFilter';
import { useDynamicSearchStyles } from '../../features/search/DynamicSearch';
import { getCreatorFilterOptions } from '../../features/search/CreatorsFilter';
import { CollapablePanelDropdown } from '../../components/CollapablePanel';

type PreferencesSettingProps = {
  initStrArray: string[];
  notifySettingChange: (
    stringArray: string[],
    creatorRequirements: RequirementsType
  ) => void;
};

const PreferencesSetting = ({
  initStrArray,
  notifySettingChange,
}: PreferencesSettingProps) => {
  const classes = useDynamicSearchStyles();
  const intl = useIntl();
  const countryNames = getCountryNames(intl);
  const creatorFilterOptions = getCreatorFilterOptions(intl);
  const [creatorFilter, setCreatorFilter] = useState<typeof initCreatorFilter>({
    ...initCreatorFilter,
  });
  const getNewStrArray = () => {
    const keys = [
      'creatorFollowers',
      'avgContentViews',
      'engagement',
      'audienceAge',
      'audienceGender',
      'audienceLocations',
      'creatorLocations',
      'creatorsGender',
      'hasTiktokShop',
    ] as (keyof typeof creatorFilter)[];

    const newStrArray = keys.map((key) => {
      const custom = creatorFilter[
        `_${key}Custom` as keyof typeof creatorFilter
      ] as [a: number, b: number];
      const customAge = creatorFilter[
        `_${key}Custom` as keyof typeof creatorFilter
      ] as { item: string; percent: number };
      const value = Array.isArray(creatorFilter[key])
        ? (creatorFilter[key] as Array<string>).join(',')
        : (key === 'creatorsGender' || key === 'audienceGender') &&
          creatorFilter[key] !== 'all'
        ? customAge && `${customAge.item}>${customAge.percent}`
        : creatorFilter[key] === 'custom'
        ? custom && custom.join('-')
        : String(creatorFilter[key]);
      return value === '' || value === 'all' ? '' : `${key}:${value}`;
    });
    return newStrArray.filter((v) => !!v);
  };

  // convert data to new creator_requirements data format
  const getNewStrObjectKeyValueSeparated = () => {
    const MAXAge = 200;
    const obj = {} as RequirementsType;

    let value = creatorFilter['creatorFollowers'];
    if (!['all', 'custom'].includes(value)) {
      const arr = value.split('-').map((v) => Number(v) || 0);
      obj['followers_min'] = arr[0];
      obj['followers_max'] = arr[1];
    } else if ('custom' === value) {
      const custom = creatorFilter[`_creatorFollowersCustom`] as [
        a: number,
        b: number
      ];
      obj['followers_min'] = custom[0];
      obj['followers_max'] = custom[1];
    }
    value = creatorFilter['avgContentViews'];
    if (!['all', 'custom'].includes(value)) {
      const arr = value.split('-').map((v) => Number(v) || 0);
      obj['average_view_min'] = arr[0];
      obj['average_view_max'] = arr[1];
    } else if ('custom' === value) {
      const custom = creatorFilter[`_avgContentViewsCustom`] as [
        a: number,
        b: number
      ];
      obj['average_view_min'] = custom[0];
      obj['average_view_max'] = custom[1];
    }

    value = creatorFilter['engagement'];
    if (!['all', 'custom'].includes(value)) {
      const arr = value.split('-').map((v) => Number(v) || 0);
      obj['engagement_min'] = arr[0];
      obj['engagement_max'] = arr[1];
    } else if ('custom' === value) {
      const custom = creatorFilter[`_engagementCustom`] as [
        a: number,
        b: number
      ];
      obj['engagement_min'] = custom[0];
      obj['engagement_max'] = custom[1];
    }

    value = creatorFilter['audienceAge'];
    if (!['all', 'custom'].includes(value)) {
      const arr = value.split('_').map((v) => Number(v) || 0);
      obj['audience_age_min'] = arr[0] === 18 ? 0 : arr[0] === 55 ? 55 : arr[0];
      obj['audience_age_max'] =
        arr[0] === 18 ? 18 : arr[0] === 55 ? MAXAge : arr[1];
    } else if ('custom' === value) {
      const custom = creatorFilter[`_audienceAgeCustom`] as [
        a: number,
        b: number
      ];
      obj['audience_age_min'] = custom[0];
      obj['audience_age_max'] = custom[1];
    }

    value = creatorFilter['audienceGender'];
    if (!['all', 'custom'].includes(value)) {
      if (value === 'male') obj['audience_gender_male_min'] = 50;
      if (value === 'female') obj['audience_gender_female_min'] = 50;
    } else if ('custom' === value) {
      const custom = creatorFilter[`_audienceGenderCustom`];
      if (custom.item === 'male')
        obj['audience_gender_male_min'] = custom.percent;
      if (custom.item === 'female')
        obj['audience_gender_female_min'] = custom.percent;
    }

    value = creatorFilter['creatorsGender'];
    if (!['all', 'custom'].includes(value)) {
      if (value === 'male') obj['creators_gender_male_min'] = 50;
      if (value === 'female') obj['creators_gender_female_min'] = 50;
    } else if ('custom' === value) {
      const custom = creatorFilter[`_creatorsGenderCustom`];
      if (custom.item === 'male')
        obj['creators_gender_male_min'] = custom.percent;
      if (custom.item === 'female')
        obj['creators_gender_female_min'] = custom.percent;
    }

    obj['audience_locations'] = creatorFilter['audienceLocations'];
    obj['creator_locations'] = creatorFilter['creatorLocations'];

    return obj;
  };

  useEffect(() => {
    const newStrArray = getNewStrArray();
    if (
      initStrArray.length === newStrArray.length &&
      initStrArray.length > 0 &&
      initStrArray.every((v) => newStrArray.findIndex((w) => w === v) > -1)
    ) {
      return;
    }
    const filters = {
      ...creatorFilter,
      _audienceGenderCustom: creatorFilter._audienceGenderCustom,
    };
    initStrArray.forEach((str) => {
      const index = str.indexOf(':');
      const key = str.slice(0, index) as keyof typeof creatorFilter;
      const value = str.slice(index + 1);
      const valueGender = value
        .replace('male>50', 'male')
        .replace('female>50', 'female');
      const prop = creatorFilter[key];
      const options =
        creatorFilterOptions[
          `${key}Options` as keyof typeof creatorFilterOptions
        ];
      if (prop && Array.isArray(options)) {
        if (
          (options as { value: string }[]).findIndex(
            (v) => v.value === value || v.value === valueGender
          ) > -1
        ) {
          // option value
          if (typeof filters[key] === 'string') {
            if (['female', 'male'].includes(valueGender)) {
              (filters[key] as string) = valueGender;
              const [gender, percent] = value.split('>');
              if (gender && Number(percent)) {
                (filters[('_' + key + 'Custom') as keyof CreatorsFilterT] as {
                  item: string;
                  percent: number;
                }) = {
                  item: gender,
                  percent: Number(percent),
                };
              }
            } else {
              (filters[key] as string) = value;
            }
          }
        } else {
          // custom value
          (filters[key] as string) = 'custom';
          if (key === 'audienceGender') {
            const [gender, percent] = value.split('>');
            if (gender && Number(percent)) {
              filters['_audienceGenderCustom'] = {
                item: gender,
                percent: Number(percent),
              };
            }
          } else if (key === 'creatorsGender') {
            const [gender, percent] = value.split('>');
            if (gender && Number(percent)) {
              filters['_creatorsGenderCustom'] = {
                item: gender,
                percent: Number(percent),
              };
            }
          } else {
            const [num1, num2] = value.split('-').map((v) => Number(v));
            if (num1 && num2) {
              (filters[`_${key}Custom` as keyof typeof creatorFilter] as [
                number,
                number
              ]) = [num1, num2];
            }
          }
        }
      }
      if (key === 'audienceLocations') {
        filters.audienceLocations = value.split(',');
      }
    });
    setCreatorFilter({ ...creatorFilter, ...filters });
  }, [initStrArray]);

  useEffect(() => {
    const newStrArray = getNewStrArray();
    const creatorRequirements = getNewStrObjectKeyValueSeparated();

    if (
      initStrArray.length !== newStrArray.length ||
      (initStrArray.length > 0 &&
        initStrArray.some((v) => newStrArray.findIndex((w) => w === v) <= -1))
    ) {
      notifySettingChange(newStrArray, creatorRequirements);
      return;
    }
  }, [creatorFilter]);

  const onSettingChange = (changes: Partial<CreatorsFilterT>) => {
    setCreatorFilter({ ...creatorFilter, ...changes });
  };

  const handleMultiSelectChangeV2 = (field: string) => (value: string) => {
    onSettingChange({
      [field]:
        typeof value === 'string'
          ? value === ''
            ? []
            : value.split(',')
          : value,
    });
  };

  const mapValueToLabel = (
    options: { value: string; label: string }[],
    value: string,
    defaultLabel: string,
    customValues: number[] = [],
    unit = ''
  ) => {
    if (value === 'all') {
      return defaultLabel;
    }
    if (value === 'custom') {
      if (customValues.length !== 2) return 'custom';
      return `${intl.formatNumber(customValues[0], {
        notation: 'compact',
      })}${unit}-${intl.formatNumber(customValues[1], {
        notation: 'compact',
      })}${unit}`;
    }
    const index = options.findIndex((op) => op.value === value);
    if (index >= 0) {
      return options[index].label;
    }
    return defaultLabel;
  };
  const mapValueToLabelPercent = (
    options: { value: string; label: string }[],
    value: string,
    defaultLabel: string,
    customValues: CustomCategoryItemPercent,
    op = '>',
    percent = '%'
  ) => {
    if (value === 'all') {
      return defaultLabel;
    }
    if (value === 'custom') {
      return `${customValues.item} ${op} ${customValues.percent}${percent}`;
    }
    const index = options.findIndex((op) => op.value === value);
    if (index >= 0) {
      return options[index].label;
    }
    return defaultLabel;
  };

  const initFilterState = {
    audienceAge: false,
    audienceGender: false,
    avgContentViews: false,
    creatorLocations: false,
    creatorsGender: false,
    hasTiktokShop: false,
  };
  const [showFilterState, setShowFilterState] = useState(initFilterState);

  const deleteFilter = (key: keyof typeof initFilterState) => {
    onSettingChange({ ...creatorFilter, [key]: initCreatorFilter[key] });
    setShowFilterState({ ...showFilterState, [key]: false });
  };

  const filterNotInDefaultValue = (key: keyof typeof initFilterState) => {
    return creatorFilter[key].toString() !== initCreatorFilter[key].toString();
  };

  const mapCountryCodeToName = (code: string): string => {
    return countryNames[code as keyof typeof countryNames] || '';
  };
  // add state to collapse CollapablePanel if click non-custom value
  const [collapableState, setCollapableState] = useState<
    Partial<{ [key in keyof typeof creatorFilter]: boolean }>
  >({});

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        rowGap: '16px',
        columnGap: '16px',
      }}
      marginTop={'1rem'}
    >
      <CollapablePanelDropdown
        width="20.625rem"
        message={mapValueToLabel(
          creatorFilterOptions.creatorFollowersOptions,
          creatorFilter.creatorFollowers,
          intl.formatMessage(Messages.creatorFollowers),
          creatorFilter._creatorFollowersCustom
        )}
        fontClassName={classes.filterLabelIcon14px}
        title={intl.formatMessage(Messages.creatorFollowers)}
        isDefaultValue={
          !filterNotInDefaultValue(
            'creatorFollowers' as keyof typeof initFilterState
          )
        }
        defaultCollapsed={collapableState['creatorFollowers']}
      >
        <SelectCustomSlider
          selectValue={creatorFilter.creatorFollowers}
          customValue={creatorFilter._creatorFollowersCustom}
          handleChange={(selectValue, customValue) => {
            onSettingChange({
              creatorFollowers: selectValue,
              _creatorFollowersCustom: customValue,
            });
            setCollapableState({
              ...collapableState,
              creatorFollowers: selectValue !== 'custom',
            });
          }}
          customRange={creatorFilterOptions.creatorFollowersRange}
          selectOptions={creatorFilterOptions.creatorFollowersOptions}
        />
      </CollapablePanelDropdown>
      <CollapablePanelDropdown
        width="20.625rem"
        message={mapValueToLabel(
          creatorFilterOptions.avgContentViewsOptions,
          creatorFilter.avgContentViews,
          intl.formatMessage(Messages.avgContentViews),
          creatorFilter._avgContentViewsCustom
        )}
        fontClassName={classes.filterLabelIcon14px}
        onClearFilter={() => deleteFilter('avgContentViews')}
        title={intl.formatMessage(Messages.avgContentViews)}
        isDefaultValue={
          !filterNotInDefaultValue(
            'avgContentViews' as keyof typeof initFilterState
          )
        }
        defaultCollapsed={collapableState['avgContentViews']}
      >
        <SelectCustomSlider
          selectValue={creatorFilter.avgContentViews}
          customValue={creatorFilter._avgContentViewsCustom}
          handleChange={(selectValue, customValue) => {
            onSettingChange({
              avgContentViews: selectValue,
              _avgContentViewsCustom: customValue,
            });
            setCollapableState({
              ...collapableState,
              avgContentViews: selectValue !== 'custom',
            });
          }}
          customRange={creatorFilterOptions.avgContentViewsRange}
          selectOptions={creatorFilterOptions.avgContentViewsOptions}
        />
      </CollapablePanelDropdown>

      <CollapablePanelDropdown
        width="20.625rem"
        message={mapValueToLabel(
          creatorFilterOptions.engagementOptions,
          creatorFilter.engagement,
          intl.formatMessage(Messages.contentEngagementRate),
          creatorFilter._engagementCustom,
          '%'
        )}
        fontClassName={classes.filterLabelIcon14px}
        title={intl.formatMessage(Messages.contentEngagementRate)}
        isDefaultValue={
          !filterNotInDefaultValue('engagement' as keyof typeof initFilterState)
        }
        defaultCollapsed={collapableState['engagement']}
      >
        <SelectCustomSlider
          selectValue={creatorFilter.engagement}
          customValue={creatorFilter._engagementCustom}
          handleChange={(selectValue, customValue) => {
            onSettingChange({
              engagement: selectValue,
              _engagementCustom: customValue,
            });

            setCollapableState({
              ...collapableState,
              engagement: selectValue !== 'custom',
            });
          }}
          customRange={creatorFilterOptions.engagementRange}
          selectOptions={creatorFilterOptions.engagementOptions}
        />
      </CollapablePanelDropdown>
      <CollapablePanelDropdown
        width="20.625rem"
        message={
          creatorFilter.creatorLocations.length <= 0
            ? intl.formatMessage(Messages.creatorLocations)
            : creatorFilter.creatorLocations.length > 2
            ? creatorFilter.creatorLocations
                .slice(0, 2)
                .map((code) => mapCountryCodeToName(code))
                .concat(['...'])
                .join(',')
            : creatorFilter.creatorLocations
                .slice(0, 2)
                .map((code) => mapCountryCodeToName(code))
                .join(',')
        }
        fontClassName={classes.filterLabelIcon14px}
        sx={{ m: 0, p: 0 }}
        title={intl.formatMessage(Messages.creatorLocations)}
        isDefaultValue={
          !filterNotInDefaultValue(
            'creatorLocations' as keyof typeof initFilterState
          )
        }
        valueLength={30}
      >
        <ChecklistSelect
          sx={{ pl: 3 }}
          data={CreatorCountryCode.map((item) => ({
            id: item,
            name: countryNames[item as keyof typeof countryNames] || '',
            checked:
              creatorFilter.creatorLocations.findIndex((v) => v === item) >= 0,
          }))}
          onChange={(value) => {
            const newValue = creatorFilter.creatorLocations.slice();
            const id = value.id;
            const index = newValue.findIndex((item) => item === id);
            if (index < 0) {
              newValue.push(id);
            } else {
              newValue.splice(index, 1);
            }
            handleMultiSelectChangeV2('creatorLocations')(
              newValue.length > 1
                ? newValue.join(',')
                : newValue.length === 1
                ? newValue[0]
                : ''
            );
          }}
        />
      </CollapablePanelDropdown>
      {/* <CollapablePanelDropdown
        width="20.625rem"
        message={mapValueToLabel(
          creatorFilterOptions.audienceAgeOptions,
          creatorFilter.audienceAge,
          intl.formatMessage(Messages.followersAge),
          creatorFilter._audienceAgeCustom
        )}
        fontClassName={classes.filterLabelIcon14px}
        onClearFilter={() => deleteFilter('audienceAge')}
        title={intl.formatMessage(Messages.followersAge)}
        isDefaultValue={
          !filterNotInDefaultValue(
            'audienceAge' as keyof typeof initFilterState
          )
        }
        defaultCollapsed={collapableState['audienceAge']}
      >
        <SelectCustomSlider
          selectValue={creatorFilter.audienceAge}
          customValue={creatorFilter._audienceAgeCustom}
          handleChange={(selectValue, customValue) => {
            onSettingChange({
              audienceAge: selectValue,
              _audienceAgeCustom: customValue,
            });
            setCollapableState({
              ...collapableState,
              audienceAge: selectValue !== 'custom',
            });
          }}
          customRange={creatorFilterOptions.audienceAgeRange}
          selectOptions={creatorFilterOptions.audienceAgeOptions}
          showCustom={false}
        />
      </CollapablePanelDropdown> */}

      {/* <CollapablePanelDropdown
        width="20.625rem"
        message={mapValueToLabelPercent(
          creatorFilterOptions.audienceGenderOptions,
          creatorFilter.audienceGender,
          intl.formatMessage(Messages.followersGender),
          {
            ...creatorFilter._audienceGenderCustom,
            item: creatorFilterOptions.audienceGenderOptions.filter(
              (v) => v.value === creatorFilter._audienceGenderCustom.item
            )[0].label,
          }
        )}
        fontClassName={classes.filterLabelIcon14px}
        onClearFilter={() => deleteFilter('audienceGender')}
        title={intl.formatMessage(Messages.followersGender)}
        isDefaultValue={
          !filterNotInDefaultValue(
            'audienceGender' as keyof typeof initFilterState
          )
        }
      >
        <SelectCustomPercent
          selectValue={creatorFilter.audienceGender}
          customValue={creatorFilter._audienceGenderCustom}
          handleChange={(selectValue, customValue) =>
            onSettingChange({
              audienceGender: selectValue,
              _audienceGenderCustom: DefaultMaleFemalePercent(
                selectValue,
                customValue
              ),
            })
          }
          selectOptions={creatorFilterOptions.audienceGenderOptions}
        />
      </CollapablePanelDropdown> */}
      <CollapablePanelDropdown
        width="20.625rem"
        message={mapValueToLabel(
          creatorFilterOptions.hasTiktokShopOptions,
          creatorFilter.hasTiktokShop,
          intl.formatMessage(Messages.HasTikTokShop)
        )}
        fontClassName={classes.filterLabelIcon14px}
        onClearFilter={() => deleteFilter('hasTiktokShop')}
        title={intl.formatMessage(Messages.HasTikTokShop)}
        isDefaultValue={
          !filterNotInDefaultValue(
            'hasTiktokShop' as keyof typeof initFilterState
          )
        }
        defaultCollapsed={collapableState['hasTiktokShop']}
      >
        <SelectCustomSlider
          selectValue={creatorFilter.hasTiktokShop}
          customValue={[]}
          handleChange={(selectValue) => {
            onSettingChange({
              hasTiktokShop: selectValue,
            });
            setCollapableState({
              ...collapableState,
              hasTiktokShop: selectValue !== 'custom',
            });
          }}
          customRange={creatorFilterOptions.avgContentViewsRange}
          selectOptions={creatorFilterOptions.hasTiktokShopOptions}
          showCustom={false}
        />
      </CollapablePanelDropdown>
    </Box>
  );
};

export default PreferencesSetting;

// this object is for mapping filter field name to label
// it will show on the campaign card panel
export const PreferencesSettingFilterNames = [
  { id: 'creatorFollowers', name: 'Creator Followers' },
  { id: 'avgContentViews', name: 'Content Average Views' },
  { id: 'engagement', name: 'Content Engagement Rate' },
  { id: 'followersAge', name: "Followers' Age" },
  { id: 'followersGender', name: "Followers' Gender(%)" },
  { id: 'audienceGender', name: "Followers' Gender(%)" },
  { id: 'followersLocations', name: 'Followers Locations' },
  { id: 'audienceAge', name: "Followers' Age" },
  { id: 'audienceLocations', name: "Followers' Locations" },
  { id: 'creatorLocations', name: "Creator's Locations" },
  { id: 'creatorsGender', name: "Creators' Gender(%)" },
  { id: 'hasTiktokShop', name: 'Creator has TikTok shop' },
];

const keys = [
  'creatorFollowers',
  'audienceAge',
  'avgContentViews',
  'creatorLocations',
  'engagement',
  'creatorsGender',
  'audienceGender',
  'hasTiktokShop',
];
export function DisplayPreferencesSettingItemsInOrder(creator_requirements: {
  [key: string]: string;
}) {
  const tags = keys
    .map((w) =>
      creator_requirements[w]
        ? `${PreferencesSettingFilterNames.find((a) => a.id === w)?.name}: ${
            creator_requirements[w]
          }`
        : ''
    )
    .filter((w) => w)
    .concat(
      (
        Object.keys(creator_requirements)
          .filter((w) => !keys.find((v) => v === w))
          .map(
            (w) =>
              `${
                PreferencesSettingFilterNames.find((a) => a.id === w)?.name
              }: ${creator_requirements[w]}`
          ) || []
      ).map((w) => capitalize(w))
    );
  console.log(creator_requirements, tags);
  return tags;
}
