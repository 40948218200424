import { useContext, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AdminContext } from '../context/AdminContextWrapper';
import {
  useLazyAdminApplicationsChatHistoryQuery,
  useLazyAdminConversationChatHistoryQuery,
} from '../../redux/benaAdminApi';
import { convertToReadableMessage, genMsg } from '../../creator/hooks/aigc';
import { LazyQueryResultHandler } from '../../redux/benaApiResultHandler';

export function useAdminQueryConversationHistoryMessages(isCampaign: boolean) {
  const intl = useIntl();
  const { adminConversationId } = useContext(AdminContext);
  const [dataArr, setDataArr] = useState<CreatorRoleScriptItemType[]>([]);
  const [originDataArr, setOriginDataArr] = useState<any[]>([]);
  const [queryHistory, queryHistoryResult] =
    useLazyAdminConversationChatHistoryQuery({});

  const [queryApplicationHistory, queryApplicationHistoryResult] =
    useLazyAdminApplicationsChatHistoryQuery({});

  useEffect(() => {
    if (isCampaign) {
      adminConversationId &&
        queryApplicationHistory({
          application_id: adminConversationId,
        });
    } else {
      adminConversationId &&
        queryHistory({ conversation_id: adminConversationId });
    }
  }, [adminConversationId, isCampaign]);

  useEffect(() => {
    if (queryHistoryResult.isSuccess) {
      LazyQueryResultHandler({
        intl,
        result: queryHistoryResult,
        onSuccess: (data) => {
          const arr =
            data.messages?.map((w) =>
              genMsg({
                reqDate: new Date().toLocaleDateString(),
                type: w.sender ? 'user' : 'robot',
                text: convertToReadableMessage(w.msg_text),
                action: w.msg_type,
                images: [],
                actionOptions: undefined,
                conversation_id: w.msg_id,
                msg_data: {
                  ...w.msg_data,
                  suggestions: w.msg_data?.suggestions || [],
                  links: w.msg_data?.links || [],
                },
              })
            ) || [];
          setDataArr(arr.reverse());
          setOriginDataArr(data.messages || []);
        },
      });
    }
  }, [queryHistoryResult]);

  useEffect(() => {
    if (queryApplicationHistoryResult.isSuccess) {
      LazyQueryResultHandler({
        intl,
        result: queryApplicationHistoryResult,
        onSuccess: (data) => {
          const arr =
            data.messages?.map((w) =>
              genMsg({
                reqDate: new Date().toLocaleDateString(),
                type: w.sender ? 'user' : 'robot',
                text: convertToReadableMessage(w.msg_text),
                action: w.msg_type,
                images: [],
                actionOptions: undefined,
                conversation_id: w.msg_id,
                msg_data: {
                  ...w.msg_data,
                  suggestions: w.msg_data?.suggestions || [],
                  links: w.msg_data?.links || [],
                },
              })
            ) || [];
          setDataArr(arr.reverse());
          setOriginDataArr(data.messages || []);
        },
      });
    }
  }, [queryApplicationHistoryResult]);

  return { historyMessages: dataArr, originDataArr };
}
