import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  useDeleteShopifyCancelSubscriptionMutation,
  useLazyGetShopifyPlanQuery,
  usePostShopifySubscribeMutation,
} from '../redux/benaApi';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../redux/benaApiResultHandler';
import { useTypedSelector } from '../redux/store';
import { selectAuth } from '../features/account/AuthSlice';
import { showMessage } from '../components/ShowMessage';
import { Messages } from '../localization/Messages';

export function useShopifySubscription() {
  const intl = useIntl();
  const [open, result] = usePostShopifySubscribeMutation({});

  const subscribeShopify = async (data: { subscription_key: string }) => {
    const dataConvert = {
      ...data,
    };
    await MutationHandler({
      intl,
      action: async () => {
        return await open(dataConvert).unwrap();
      },
    });
  };
  useEffect(() => {
    if (result.isSuccess) {
      // result.data.data is the redirect url
      window.location.href = result.data.data;
    }
  }, [result]);
  return { subscribeShopify };
}

export function useShopifyCancelSubscription() {
  const intl = useIntl();
  const [cancel, result] = useDeleteShopifyCancelSubscriptionMutation({});

  const cancelSubscription = async () => {
    await MutationHandler({
      intl,
      action: async () => {
        return await cancel({}).unwrap();
      },
      onSuccess: () => {
        showMessage.success(
          intl.formatMessage(Messages.CancelSubscriptionSucceed)
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
    });
  };

  const loading = result.isLoading;

  return { cancelSubscription, loading };
}

export function useGetUserShopifyPlan() {
  const intl = useIntl();
  const [plan, setPlan] = useState<SubscriptionPlanResponseType | null>(null);
  const [getPlan, result] = useLazyGetShopifyPlanQuery({});
  useEffect(() => {
    LazyQueryResultHandler<SubscriptionPlanResponseType>({
      intl,
      result: result as QueryResultType<SubscriptionPlanResponseType>,
      onSuccess: (resdata) => {
        setPlan(resdata || null);
      },
    });
  }, [result]);

  const daysDue = plan?.subscription?.days_until_due;
  const daysDueSeconds =
    typeof daysDue === 'number'
      ? Math.floor(new Date().valueOf() / 1000) + daysDue * 24 * 60 * 60
      : null;
  const periodEnd =
    new Date(plan?.subscription?.current_period_end || 0).valueOf() / 1000;
  const nextBillDueDate = (daysDueSeconds || periodEnd || 0) * 1000;

  const defaultPaymentMethod = plan?.subscription?.default_payment_method;

  return {
    getPlan,
    currentPlanName: plan?.plan,
    nextBillDueDate,
    plan,
    defaultPaymentMethod,
  };
}

export function useIsShopifyUser() {
  const isShopifyUser = !!useTypedSelector((state) => selectAuth(state)).user
    ?.shopify_user;
  return isShopifyUser;
}
