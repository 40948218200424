export const staticURLRoot = '/';
export const staticURLBena2 = '/';
export const staticURLLogin = '/account/login';
export const staticURLAccount = '/account';
export const staticURLMyProfile = '/myprofile';
export const staticURLCreatorJobs = '/jobs';
export const staticURLCreatorAIGC = '/creators/';
export const staticURLForgotPassword = '/account/forgotPassword';
export const staticURLResetPassword = '/account/resetPassword';
export const staticURLRegisterBrand = '/account/registerBrand';
export const staticURLChangeEmail = '/account/changeemail';
export const staticURLRegisterCreatorProfile =
  '/account/registerCreatorProfile';
export const staticURLNewCampaign = '/campaigns/new';
export const staticURLCampaignsPage = '/campaigns';
export const staticURLCampaignPublicUrl = '/public_campaign';
export const staticURLCampaignPublicUrlNoApply = '/public_campaign_noapply';
export const staticURLCampaignPaymentMilestone = '/paymentmilestone';
export const staticURLMessaging = '/messages/';
export const staticURLTiktok = 'https://www.tiktok.com/';
export const staticURLYoutube = 'https://www.youtube.com/channel/';
export const staticURLWWWBenaLive = 'https://www.bena.live';
export const staticURLShortList = 'creator-shortlist?page=1&size=1000';
export const chromePluginURL = 'https://chrome.google.com/webstore/detail/knplglbllakldejdoelfkihhkchenmke';

export function getUrlBySocialPlatformId(id: SocialPlatformId) {
  switch (id.toLowerCase()) {
    case 'tiktok':
      return staticURLTiktok;
    case 'youtube':
      return staticURLYoutube;
    default:
      return '';
  }
}
