import { useEffect } from 'react';
import { useLazyGetRocketchatUserTokenQuery } from '../../redux/benaApi';
import { LazyQueryResultHandler } from '../../redux/benaApiResultHandler';
import { useIntl } from 'react-intl';
import { getChatSiteUrl } from '../../env';
import { useTypedSelector } from '../../redux/store';
import { selectAuth } from '../account/AuthSlice';

/**
 * iframe login page for rocket chat
 */
export default function RocketChatLogin() {
  const intl = useIntl();
  const access = useTypedSelector((state) => selectAuth(state)).access;
  const [query, result] = useLazyGetRocketchatUserTokenQuery({});
  useEffect(() => {
    console.log('RocketChatLogin access:', access);
    if (access) query({});
  }, [access, query]);
  useEffect(() => {
    LazyQueryResultHandler({
      intl,
      result: result as QueryResultType<GetRocketchatUserTokenResponseType>,
      onSuccess: (resdata) => {
        window.parent.postMessage(
          {
            event: 'login-with-token',
            loginToken: resdata.token,
            token: resdata.token,
          },
          getChatSiteUrl()
        );
      },
    });
  }, [result]);

  return <></>;
}
