import { Box, Typography } from '@mui/material';

type BenaVideoPlayerProps = {
  src: string;
  mediaType: string;
  width: string;
  title?: string;
};

export function BenaVideoPlayer({
  src,
  mediaType,
  width,
  title,
}: BenaVideoPlayerProps) {
  return (
    <Box className="boxcenterhv" sx={{ width: '100%', height: '100%' }}>
      <video width={width} height={width} controls title={title || ''}>
        <source src={src} type={mediaType}></source>
        <Typography sx={{ fontSize: '0.625rem' }}>
          Sorry, your browser doesn't support embedded videos, but don't worry,
          you can download {src} it and watch it with your favorite video
          player!
        </Typography>
      </video>
    </Box>
  );
}
