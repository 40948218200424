import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useTypedSelector } from '../../redux/store';
import CreatorsFilter, {
  CreatorsFilterT,
  initCreatorFilter,
} from './CreatorsFilter';
import {
  SearchType,
  selectDynamicSearchSetting,
  setPlatform,
  updateCreatorsFilter,
  updateDynamicSearchSetting,
  selectCreatorQuery,
} from './dynamicSearchSlice';
import Color from 'color';
import {
  EraserIcon,
  PhotoAvatarIcon,
  Star1Icon,
} from '../../assets/imgs/icons';
import { Messages } from '../../localization/Messages';
import { capitalize } from 'lodash';
import ClearSearchTextButton from '../../components/ClearSearchTextButton';
import PlatformSelectionButton from '../../components/PlatformSelectionButton';
import SuggestList from './SuggestList';
import TuneIcon from '@mui/icons-material/Tune';
import { useThrottle } from '../../hooks/throttle';
import { useSearchCreatorWithAt } from '../../hooks/creators';
import CreatorCheckList from '../modules/creatorCheckList';
import { selectAuth } from '../account/AuthSlice';

interface DynamicSearchProps {
  loading: boolean;
  updateBatchFavorites?: (list: string[]) => void;
  dataLength: number;
}

function DynamicSearch({
  loading,
  updateBatchFavorites,
  dataLength,
}: DynamicSearchProps) {
  const classes = useDynamicSearchStyles();
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const initSearchTerms = useTypedSelector((state) =>
    selectDynamicSearchSetting(state, 'searchTerms')
  );
  const user = useTypedSelector((state) => selectAuth(state)).user;
  
  const [allButton, setAllButton] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const queryParams = useTypedSelector(selectCreatorQuery);
  useEffect(()=>{
    let txter = decodeURIComponent(queryParams).split('&');
    if(!user?.plan_credits){ return; }
    if(
      txter[1] &&
      txter[1] !== 'filters={}'&&
      dataLength > 0 &&
      !loading
    ){
      setAllButton(true);
    }else if(allButton){
      setAllButton(false);
    }
  },[queryParams, dataLength, loading]);

  const searchPlatform = useTypedSelector((state) =>
    selectDynamicSearchSetting(state, 'platform')
  ) as PlatformType;
  const [searchType, setSearchType] = useState<SearchType>('creators');
  const [searchTerms, setSearchTerms] = useState(initSearchTerms);
  useEffect(() => setSearchTerms(initSearchTerms), [initSearchTerms]);

  const isSearchForSuggest = (str: string) => str.startsWith('@');

  const throttledFn = useThrottle(() => {
    onSearchSubmit();
  }, 1000);

  useEffect(() => {
    if (!isSearchForSuggest(searchTerms)) throttledFn();
  }, [searchTerms]);

  const onSearchSubmit = (seed?: string) => {
    dispatch(
      updateDynamicSearchSetting({
        setting: 'searchTerms',
        newValue: searchTerms,
        ...(seed ? { seed } : {}),
      })
    );
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchSubmit();
    }
  };

  const onCreatorsFilterSettingChange = (changes: Partial<CreatorsFilterT>) => {
    dispatch(updateCreatorsFilter(changes));
  };

  const ClearAllElement = (
    <IconButton
      disableRipple
      onClick={(e) => {
        if (!e.currentTarget.disabled) {
          searchType === 'creators' &&
            onCreatorsFilterSettingChange(initCreatorFilter);
        }
      }}
      className={`border-radius-8px`}
    >
      <Typography
        fontSize={'0.875rem'}
        marginRight={1}
        fontWeight="500"
        color={theme.palette.danger[400]}
      >
        {intl.formatMessage(Messages.clearAll)}
      </Typography>
      <EraserIcon
        stroke={theme.palette.danger[500]}
        fill={'transparent'}
        sx={{ width: '0.875rem', height: '0.875rem' }}
      />
    </IconButton>
  );

  const searchInputPlaceholder = intl.formatMessage(
    searchType === 'creators'
      ? Messages.searchCreatorsWith
      : Messages.searchByName
  );

  const { data: suggestedItems, search } = useSearchCreatorWithAt();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTypingSearchTerms(true);
    setAdvancedSearch(false);
    const text = e.target.value;
    setSearchTerms(text);
    if (isSearchForSuggest(text)) {
      // search name or unique_id
      search(text.slice(1));
    } else {
      // general search
      if (!e.target.value)
        dispatch(
          updateDynamicSearchSetting({
            setting: 'searchTerms',
            newValue: e.target.value,
          })
        );
    }
  };
  const clearSearch = () => {
    const empty = '';
    setSearchTerms(empty);
    dispatch(
      updateDynamicSearchSetting({
        setting: 'searchTerms',
        newValue: empty,
      })
    );
  };

  const handlePlatformChange = (platform: string) => {
    dispatch(setPlatform(platform as PlatformType));
  };

  //  tracking the search term typing action, focused for true, blurred for false
  const [typingSearchTerms, setTypingSearchTerms] = useState(false);

  const [advancedSearch, setAdvancedSearch] = useState(false);
  // manually control whether to display the search list
  const [manual, setManual] = useState(false);
  const inputBash = useRef<HTMLInputElement>(null);
  
  return (
    <Stack>
      <Box
        className={`border-radius-16px ${classes.searchBox}`}
        position="relative"
      >
        <Stack
          direction="row"
          width={'100%'}
          className={`border-radius-16px ${classes.inputPanel} shadowXSM`}
          border={'1px solid ' + theme.palette.neutral[600]}
        >
          <InputBase
            className={`border-radius-8px ${classes.filterLabelColor}`}
            placeholder={capitalize(searchInputPlaceholder)}
            value={searchTerms}
            onKeyDown={onKeyPress}
            onChange={handleSearch}
            inputRef={inputBash}
            startAdornment={
              <>
                <PlatformSelectionButton
                  onChange={handlePlatformChange}
                  platform={searchPlatform}
                />
                <Divider orientation="vertical" flexItem light sx={{ mx: 2 }} />
                <Star1Icon
                  sx={{ paddingRight: 1 }}
                  stroke={
                    searchTerms
                      ? theme.palette.base.black
                      : theme.palette.grey[50]
                  }
                />
              </>
            }
            endAdornment={
              <>
                <ClearSearchTextButton
                  clickHandler={clearSearch}
                  hide={!searchTerms}
                />
                <IconButton
                  onClick={() => setAdvancedSearch(!advancedSearch)}
                  sx={{ mr: '0.5rem' }}
                >
                  <TuneIcon
                    sx={{
                      color: advancedSearch
                        ? theme.palette.danger[400]
                        : theme.palette.base.black,
                    }}
                  />
                </IconButton>
              </>
            }
            fullWidth
            onBlur={() => {
              setTimeout(() => setTypingSearchTerms(false), 200);
            }}
            onFocus={() => {setManual(false); setTypingSearchTerms(true)}}
            sx={{
              height: '2.5rem',
              fontWeight: '500 !important',
              color: `${theme.palette.grey[950]} !important`,
            }}
          />
          <Button
            onClick={() =>
              onSearchSubmit(Math.random().toString(36).slice(2, 6))
            }
            color="primary"
            variant="contained"
            aria-label="search"
            className={classes.searchButton}
          >
            {intl.formatMessage(Messages.search)}
          </Button>
        </Stack>
        { allButton && !loading &&
          <Button color="primary" variant="contained"
            sx={{ width: '7.5rem', height: '2.5rem', borderRadius: '0.875rem', margin: '1rem 0.5rem 0 0'}}
            onClick={() => setOpen(true)}
            >
              {/*  */}
            {intl.formatMessage(Messages.AddToList)}
          </Button>
        }
        { open && 
          <CreatorCheckList shortLocal={[]} setShortLocal={()=>{}}
            setOpen={setOpen} uid="all" queryParams={queryParams}
            updateBatchFavorites={updateBatchFavorites}
            posTop="9.5rem" posLeft="auto" posTransform='translateY(0)' posRight='-1rem'
          />
        }

        { (typingSearchTerms && searchTerms && !advancedSearch && isSearchForSuggest(searchTerms) ) || manual 
          ? (
            <Paper
              elevation={0}
              className={classes.filters}
              sx={{
                zIndex: theme.zIndex.modal,
              }}
            >
              {!loading && (
                <SuggestList
                  keyword={searchTerms.slice(1)}
                  resultArray={suggestedItems}
                  setManual={setManual}
                  inputBash={inputBash}
                />
              )}
              {loading && (
                <Typography textAlign={'center'} padding="2rem">
                  {capitalize(intl.formatMessage(Messages.loading))}
                </Typography>
              )}
              <Stack
                direction="row"
                spacing={'1rem'}
                height="4.5rem"
                sx={{
                  backgroundColor: theme.palette.primary['2_1'],
                  padding: '1rem',
                }}
                alignItems="center"
                className={`border-radius-16px-bottom`}
              >
                <PhotoAvatarIcon sx={{ width: '2.5rem', height: '2.5rem' }} />
                <IconButton
                  disableRipple
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setAdvancedSearch(!advancedSearch);
                  }}
                >
                  <Typography flexGrow={1}>
                    {capitalize(
                      intl.formatMessage(Messages.advancedCreatorDiscovery)
                    )}
                  </Typography>
                </IconButton>
              </Stack>
            </Paper>
          ):<></>}
        {advancedSearch && (
          <ClickAwayListener
            onClickAway={(e) => {
              setAdvancedSearch(false);
            }}
          >
            <Paper
              elevation={0}
              className={classes.filters}
              sx={{
                zIndex: theme.zIndex.modal,
              }}
            >
              <Box sx={{ padding: '0.75rem 1rem' }}>
                <CreatorsFilter />
              </Box>
              <Stack
                direction="row"
                spacing={'1rem'}
                height="4.5rem"
                className={`border-radius-16px-bottom`}
                sx={{
                  backgroundColor: theme.palette.primary['2'],
                  padding: '1rem',
                }}
                alignItems="center"
              >
                <PhotoAvatarIcon sx={{ width: '2.5rem', height: '2.5rem' }} />
                <>
                  <Typography flexGrow={1}>
                    {capitalize(intl.formatMessage(Messages.creatorSearch))}
                  </Typography>
                  {ClearAllElement}
                </>
              </Stack>
            </Paper>
          </ClickAwayListener>
        )}
      </Box>
    </Stack>
  );
}

export const useDynamicSearchStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterLabelColor: {
      color: `${Color(theme.palette.base.black)
        .fade(0.2)
        .toString()} !important`,
    },
    filterLabelIcon: {
      marginRight: '0.5rem !important',
      fontSize: '15px',
      fontWeight: '400',
      color: theme.palette.base.black,
    },
    filterLabelIcon14px: {
      marginRight: '0.5rem !important',
      fontSize: '13px',
      fontWeight: '400',
      color: theme.palette.base.black,
    },
    filterLabel: {
      width: '100%',
      display: 'flex',
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
    menuList: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
    clear: {
      marginLeft: 1,
      paddingLeft: 1.5,
      height: '2rem',
      borderRadius: '0',
      alignSelf: 'stretch',
    },
    searchBox: {
      // height: '7.5rem',
      backgroundColor: `${theme.palette.neutral[50]} !important`,
      padding: '1.875rem 1.5rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: "flex-end",
      marginTop: '3rem',
      marginBottom: '1.5rem',
    },
    inputPanel: {
      padding: '0.5rem',
      backgroundColor: theme.palette.base.white,
    },
    searchButton: {
      width: '7.5rem',
      height: '2.5rem',
      borderRadius: '0.875rem !important',
      flexShrink: 0,
    },
    filters: {
      position: 'absolute',
      top: '6rem',
      left: '1.5rem',
      right: '1.5rem',
      minHeight: '1rem',
      borderRadius: '1rem !important',
      border: '1px solid ' + theme.palette.neutral[700],
      backgroundColor: `${theme.palette.base[3]} !important`,
    },
  })
);

export default DynamicSearch;
