import { createContext } from 'react';

export const ModeratedItemsContext = createContext({
  items: {} as ModeratedItemsType,
  deleted: {} as ModeratedItemsType,
  setItem: (id: string) => {
    return;
  },
  notifyDelete: (id: string) => {
    return;
  },
});

export const AIGCDialogContext = createContext({
  // for the difference data response on homepage and aigc page
  // we keep two types of data in context
  creator: null as CreatorDataDetails | null | undefined,
  creatorData: null as CreatorData | null | undefined,
  reset: () => {
    return;
  },
  favoritedCreators: [] as FavoritedCreator[],
  notifyFavorite: (favorite: FavoritedCreator) => {
    return;
  },
  setPrevUrl: (url: string) => {
    return;
  },
  prevUrl: '',
  notifyCreator: (creator: CreatorData) => {
    return;
  },
});

export const IMContext = createContext({
  unreadCount: 0,
  setUnreadCount: (num: number) => {
    return;
  },
});
