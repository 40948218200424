import { useTheme } from '@mui/material';
import {
  TiktokColorIcon,
  YoutubeColorIcon,
  TiktokGreyIcon,
} from '../assets/imgs/icons';

const IconSize = { width: '0.75rem', height: '0.75rem' };
export const PlatformsIcon = {
  youtube: <YoutubeColorIcon sx={IconSize} />,
  tiktok: <TiktokColorIcon sx={IconSize} />,
};

export function usePlatformsIconGrey() {
  const theme = useTheme();
  const color1 = theme.palette.grey[50];
  const color2 = theme.palette.base[2];
  return {
    youtube: <YoutubeColorIcon sx={IconSize} stroke={color1} fill={color2} />,
    tiktok: <TiktokGreyIcon sx={IconSize} fill={color1} />,
  };
}
