import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const BenaIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  backgroundColor: theme.palette.base.white,
  border: `0.0625rem solid ${theme.custom.primary3}`,
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BenaCheckedIcon = styled(BenaIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: '0px',

  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    content: '""',
    backgroundImage: `url(/imgs/checked16.svg)`,
  },
}));

export default function BenaCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      color="default"
      checkedIcon={<BenaCheckedIcon />}
      icon={<BenaIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}
