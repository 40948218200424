import {
  Box,
  Button,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  capitalize,
  useTheme,
} from '@mui/material';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useGlobalStyles } from '../../assets/styles/style';
import { ArrowBackRounded } from '@mui/icons-material';
import { Messages } from '../../localization/Messages';
import { useIntl } from 'react-intl';
import { CampaignMessages } from '../../localization/CampaignMessages';
import TitledPanel from '../../creator/campaign/utils';
import { InputErrorMessage } from '../../components/InputErrorMessage';
import { ValidateRules } from '../../utils/common';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../../redux/benaApiResultHandler';
import {
  useDeleteJobContractMutation,
  useLazyGetJobContractQuery,
  usePatchJobContractMutation,
  usePostJobContractMutation,
} from '../../redux/benaApi';
import { useHiredSteps } from './config';
import { showMessage } from '../../components/ShowMessage';
import LoadingCover from '../../components/LoadingCover';

const InitOfferData = {
  budget_mode: 'product_exchange_only' as BudgetModeType | string,
  budget_amount: {
    content: '',
    maxLength: 500,
    error: false,
    inputValueType: 'number',
  },
  commission_percent: {
    content: '',
    maxLength: 5,
    error: false,
    inputValueType: 'fractionalNumber0To100',
  },
  commission: 'no' as 'yes' | 'no' | string,
  steps: {
    product_selection: true,
    prepayment: undefined,
    shipping_status: true,
    production: true,
    draft_submission: true,
    post_link: true,
    payment: undefined,
    review: true,
  } as {
    product_selection: boolean | undefined;
    prepayment: boolean | undefined;
    shipping_status: boolean | undefined;
    production: boolean | undefined;
    draft_submission: boolean | undefined;
    post_link: boolean | undefined;
    payment: boolean | undefined;
    review: boolean | undefined;
  },
};

type HireCreatorProps = {
  creatorId: string;
  campaignId: string;
  applicationId: string;
  onClose: fnVoidToVoid;
  jobcontractId?: string;
};
export default function HireCreator({
  campaignId,
  creatorId,
  applicationId,
  onClose,
  jobcontractId,
}: HireCreatorProps) {
  const theme = useTheme();
  const intl = useIntl();
  const gs = useGlobalStyles();
  const StepsConfiguration = useHiredSteps();
  const [getJobContract, getJobContractResult] = useLazyGetJobContractQuery();
  const [contract, setContract] = useState<CampaignJobContractItemType | null>(
    null
  );

  const jobContractFinished = ['finished'].includes(`${contract?.status}`);

  const refBudgetAmount = useRef<HTMLElement>(null);
  const refAffiliateCommissionAmount = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (jobcontractId) {
      getJobContract({ contract_id: jobcontractId });
    } else {
      setFormData(InitOfferData);
    }
  }, [jobcontractId]);

  useEffect(() => {
    if (getJobContractResult.isSuccess) {
      LazyQueryResultHandler({
        intl,
        result: getJobContractResult,
        onSuccess: (data) => {
          const newData = { ...formData };
          newData.budget_amount.content = `${data.budget_amount}`;
          newData.budget_mode = data.budget_mode;
          newData.commission = data.commission ? 'yes' : 'no';
          newData.commission_percent.content = `${data.commission_percent}`;
          newData.steps.product_selection =
            data.product_selection === '' ? true : undefined;
          newData.steps.prepayment = data.prepayment === '' ? true : undefined;
          newData.steps.shipping_status =
            data.shipping_status === '' ? true : undefined;
          newData.steps.production = data.production === '' ? true : undefined;
          newData.steps.post_link = data.post_link === '' ? true : undefined;
          newData.steps.payment = data.payment === '' ? true : undefined;
          newData.steps.review = data.review === '' ? true : undefined;

          setFormData(newData);
          setContract(data);
        },
      });
    }
  }, [getJobContractResult]);

  const [formData, setFormData] = useState(InitOfferData);
  const inputList = [
    {
      item: formData.budget_amount,
      message: CampaignMessages.SetBudget,
      name: 'budget_amount',
      refEl: refBudgetAmount,
      placeHolder: '',
      required: true,
    },
    {
      item: formData.commission_percent,
      message: Messages.yes,
      name: 'commission_percent',
      refEl: refAffiliateCommissionAmount,
      placeHolder: '0-100',
      required: false,
    },
  ] as Array<
    FormInputType & {
      multiple?: boolean;
      messageTip?: MessageType;
      type?: string;
      required?: boolean;
    }
  >;

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputDataType,
    key: string
  ) => {
    const str = event.target.value.slice(0, data.maxLength);
    setFormData((state) => ({
      ...state,
      [key]: {
        ...data,
        content: str,
        length: event.target.value.length,
        error:
          key === 'campaignBudget' && str === ''
            ? true
            : !ValidateRules.validInputString(
                str,
                data.maxLength,
                data.inputValueType
              ),
      },
      ...(key === 'campaignBudget' && str === '0' ? { negotiable: true } : {}),
    }));
  };

  const renderInputs = (
    inputs: typeof inputList,
    hideTitle = false,
    startAdornment = '',
    onFocus = () => {
      return;
    },
    actionElement = <></>,
    rows = 3,
    endAdornment = <></>,
    fullWidth = true
  ) => {
    return inputs.map((data) => {
      const { item, message, name, refEl, multiple, required } = data;
      return (
        <Box key={message.id}>
          <TitledPanel
            title={hideTitle ? '' : intl.formatMessage(message)}
            required={required}
            children={
              <>
                <OutlinedInput
                  fullWidth={fullWidth}
                  className={`border-radius-6px`}
                  ref={refEl}
                  id={message.id}
                  value={item.content}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleTextInputChange(event, item, name);
                  }}
                  error={item.error}
                  multiline={!!multiple}
                  rows={multiple ? rows : 1}
                  sx={{
                    height: 'auto',
                    bgcolor: theme.palette.base.white,
                    border: `1px solid ${theme.palette.neutral[700]}`,
                    boxShadow: '0px 1px 2px rgba(25, 21, 40, 0.04)',
                    paddingRight: '0px',
                    '& > textarea': {
                      padding: '8px 6px',
                    },
                    '& > input': {
                      padding: '8px 6px',
                    },
                  }}
                  startAdornment={startAdornment}
                  endAdornment={endAdornment}
                  onFocus={onFocus}
                  type={
                    ['number', 'fractionalNumber0To100'].includes(
                      item.inputValueType
                    )
                      ? 'number'
                      : 'text'
                  }
                />
                <InputErrorMessage
                  error={item.error}
                  inputValueType={item.inputValueType}
                />
              </>
            }
            actionElement={actionElement}
          />
        </Box>
      );
    });
  };

  const genStep = useCallback(
    (key: keyof typeof formData.steps) => {
      return (
        <SelectableButton
          disabled={jobContractFinished}
          selected={!!formData.steps[key]}
          onSelection={() => {
            setFormData((state) => ({
              ...state,
              steps: {
                ...state.steps,
                [key]: state.steps[key] ? undefined : true,
              },
            }));
          }}
          text={key
            .split('_')
            .map((w) => capitalize(w))
            .join(' ')}
          allowSelect={!!StepsConfiguration[key]?.config?.allowSelect}
        />
      );
    },
    [formData]
  );

  const steps = (
    <>
      <Box height="11px"></Box>
      <Stack
        direction="row"
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        alignItems="center"
        rowGap="16px"
      >
        {[
          'product_selection',
          'prepayment',
          'shipping_status',
          'production',
          'draft_submission',
          'post_link',
          'payment',
          'review',
        ].map((w) => (
          <Fragment key={w}>
            {genStep(w as keyof typeof formData.steps)}
            {w !== 'review' && (
              <ArrowBackRounded
                htmlColor={theme.palette.base.black}
                sx={{ width: '18px', height: '18px' }}
                className="rotate180"
              />
            )}
          </Fragment>
        ))}
      </Stack>
    </>
  );

  const [submitting, setSubmitting] = useState(false);
  const isValidForSave = function (
    inputs: typeof inputList,
    saveFormData = true
  ): boolean {
    if (
      (formData.commission === 'yes' && !formData.commission_percent.content) ||
      (formData.budget_mode === 'set_price' && !formData.budget_amount.content)
    ) {
      return false;
    }
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].required === false) continue;

      const { item: input, refEl: ref, name: key, required } = inputs[i];
      if (
        !required ||
        (key === 'budget_amount' && formData.budget_mode === 'product_exchange_only')
      ) {
        continue;
      }
      if (
        !ValidateRules.validInputString(
          input.content,
          input.maxLength,
          input.inputValueType
        )
      ) {
        if (saveFormData) {
          setFormData({
            ...formData,
            [key]: { ...input, error: true },
          });
          ref.current?.focus();
        }
        return false;
      }
    }

    return saveFormData ? true : !submitting;
  };

  const [postJobContract] = usePostJobContractMutation();
  const handlePostJobContract = async () => {
    if (isValidForSave(inputList)) {
      setSubmitting(true);
      await MutationHandler({
        intl,
        action: async () => {
          return await postJobContract({
            campaign_id: campaignId,
            creator_id: creatorId,
            campaign_application_id: applicationId,
            commission: formData.commission === 'yes' ? true : false,
            commission_percent:
              formData.commission === 'yes'
                ? parseFloat(formData.commission_percent.content)
                : undefined,
            budget_mode: formData.budget_mode,
            budget_amount:
              formData.budget_mode === 'set_price'
                ? parseFloat(formData.budget_amount.content)
                : undefined,
            product_selection: formData.steps['product_selection'] ? '' : null,
            prepayment: formData.steps['prepayment'] ? '' : null,
            shipping_status: formData.steps['shipping_status'] ? '' : null,
            production: formData.steps['production'] ? '' : null,
            // by default, no need to pass this step
            // draft_submission: formData.steps['draft_submission']
            //   ? ''
            //   : null,
            post_link: formData.steps['post_link'] ? '' : null,
            payment: formData.steps['payment'] ? '' : null,
            review: formData.steps['review'] ? '' : null,
            status: 'offered',
          }).unwrap();
        },
        onSuccess: () => {
          onClose();
        },
      });
      setSubmitting(false);
    }
  };

  const [deleteJobContract] = useDeleteJobContractMutation({});
  const handleDeleteJobContract = async () => {
    if (jobcontractId) {
      setSubmitting(true);
      await MutationHandler({
        intl,
        action: async () => {
          return await deleteJobContract({
            contract_id: jobcontractId,
            body: {},
          }).unwrap();
        },
        onSuccess: () => {
          showMessage.success(intl.formatMessage(Messages.SuccessfullyDeleted));
          onClose();
        },
      });
      setSubmitting(false);
    }
  };

  const [patchJobContract] = usePatchJobContractMutation({});
  const handlePatchJobContract = async (resendOffer = false) => {
    if (jobcontractId) {
      setSubmitting(true);
      await MutationHandler({
        intl,
        action: async () => {
          return await patchJobContract({
            contract_id: jobcontractId,
            body: {
              commission: formData.commission === 'yes' ? true : false,
              commission_percent:
                formData.commission === 'yes'
                  ? parseFloat(formData.commission_percent.content)
                  : undefined,
              budget_mode: formData.budget_mode,
              budget_amount:
                formData.budget_mode === 'set_price'
                  ? parseFloat(formData.budget_amount.content)
                  : undefined,
              product_selection: formData.steps['product_selection']
                ? ''
                : null,
              prepayment: formData.steps['prepayment'] ? '' : null,
              shipping_status: formData.steps['shipping_status'] ? '' : null,
              production: formData.steps['production'] ? '' : null,
              // by default, no need to pass this step
              // draft_submission: formData.steps['draft_submission']
              //   ? ''
              //   : null,
              post_link: formData.steps['post_link'] ? '' : null,
              payment: formData.steps['payment'] ? '' : null,
              review: formData.steps['review'] ? '' : null,
              ...(resendOffer ? { status: 'offered' } : {}),
            },
          }).unwrap();
        },
        onSuccess: () => {
          showMessage.success(intl.formatMessage(Messages.successfullySaved));
        },
      });
      setSubmitting(false);
    }
  };

  return (
    <Box position="relative">
      <LoadingCover
        loading={
          getJobContractResult.isLoading || getJobContractResult.isFetching
        }
      />
      <Stack
        marginTop={'48px'}
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderRadius={'8px 8px 0px 0px'}
        padding={'12px 20px'}
      >
        <Stack direction="row" alignItems={'center'} columnGap="8px">
          <Button
            sx={{
              height: '32px',
              fontWeight: '600',
              fontSize: '14px',
              padding: '8px',
            }}
            variant="text"
            className={gs.buttonOutlinedBlack}
            onClick={onClose}
          >
            <ArrowBackRounded
              htmlColor={theme.palette.base.black}
              sx={{ width: '22px', height: '22px', marginLeft: '-8px' }}
            />
            &nbsp;&nbsp;
            {intl.formatMessage(Messages.Back)}
          </Button>
          <Box flexGrow={1}></Box>
          {!contract && (
            <Button
              className={gs.buttonPrimary}
              variant="contained"
              onClick={() => {
                handlePostJobContract();
              }}
            >
              {intl.formatMessage(Messages.SendOffer)}
            </Button>
          )}
          {['drafted', 'offered'].includes(`${contract?.status}`) && (
            <>
              <Button
                className={gs.buttonSelected}
                variant="contained"
                onClick={() => {
                  handlePatchJobContract();
                }}
              >
                {capitalize(intl.formatMessage(Messages.save))}
              </Button>
              <Button
                className={gs.buttonDeletion}
                variant="contained"
                onClick={() => {
                  handleDeleteJobContract();
                }}
              >
                {capitalize(intl.formatMessage(Messages.DeleteOffer))}
              </Button>
            </>
          )}
          {['rejected'].includes(`${contract?.status}`) && (
            <>
              <Button
                className={gs.buttonSelected}
                variant="contained"
                onClick={() => {
                  handlePatchJobContract(true);
                }}
              >
                {capitalize(intl.formatMessage(Messages.ResendOffer))}
              </Button>
            </>
          )}
          {jobContractFinished && (
            <>
              <Typography color="success.600">
                {intl.formatMessage(Messages.jobContractStatusFinisheds)}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
      <Stack
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderTop="0px"
        borderRadius={'0px 0px 8px 8px'}
        padding={'20px 24px'}
      >
        <form>
          <TitledPanel
            title={intl.formatMessage(CampaignMessages.SetABudegt)}
            required={true}
            children={
              <>
                <RadioGroup
                  aria-labelledby="budget type"
                  name="radio-buttons-group"
                  value={formData.budget_mode}
                  onChange={(event, value) => {
                    setFormData((state) => ({
                      ...state,
                      budget_mode: value,
                    }));
                  }}
                >
                  <FormControlLabel
                    value={'product_exchange_only'}
                    control={<Radio disabled={jobContractFinished} />}
                    label={
                      <Typography>
                        {intl.formatMessage(
                          CampaignMessages.ProductExchangeOnly
                        )}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={'set_price'}
                    control={<Radio disabled={jobContractFinished} />}
                    label={intl.formatMessage(CampaignMessages.SetBudget)}
                    sx={{
                      '& .title': {
                        lineHeight: '42px',
                      },
                    }}
                  />
                </RadioGroup>
                {formData.budget_mode === 'set_price' &&
                  renderInputs(inputList.slice(0, 1), true, '$', () => {
                    setFormData((state) => ({
                      ...state,
                      budget_mode: 'set_price',
                    }));
                  })}
              </>
            }
          />
          <Box height="16px"></Box>
          <TitledPanel
            title={intl.formatMessage(CampaignMessages.DoYouOfferAffiliate)}
            required={false}
            children={
              <>
                <RadioGroup
                  aria-labelledby="budget type"
                  name="radio-buttons-group-commission"
                  value={formData.commission}
                  onChange={(event, value) => {
                    setFormData((state) => ({
                      ...state,
                      commission: value,
                    }));
                  }}
                >
                  <FormControlLabel
                    value={'no'}
                    control={<Radio disabled={jobContractFinished} />}
                    label={
                      <Typography>
                        {capitalize(intl.formatMessage(Messages.no))}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value={'yes'}
                    control={<Radio disabled={jobContractFinished} />}
                    label={capitalize(intl.formatMessage(Messages.yes))}
                    sx={{
                      '& .title': {
                        lineHeight: '42px',
                      },
                    }}
                  />
                </RadioGroup>
                {formData.commission === 'yes' &&
                  renderInputs(
                    inputList.slice(1, 2),
                    true,
                    '',
                    () => {
                      setFormData((state) => ({
                        ...state,
                        commission: 'yes',
                      }));
                    },
                    <></>,
                    3,
                    <Typography component="span" marginRight="8px">
                      %
                    </Typography>,
                    false
                  )}
              </>
            }
          />
          <Box height="27px"></Box>
          <TitledPanel
            title={intl.formatMessage(
              Messages.CollabrationProcessConfiguration
            )}
            required={true}
            children={steps}
          />
          <Box height="64px"></Box>
        </form>
      </Stack>
    </Box>
  );
}

type SelectableButtonProps = {
  disabled: boolean;
  selected: boolean;
  onSelection: fnBooleanToVoid;
  text: string;
  allowSelect?: boolean;
};
function SelectableButton({
  disabled,
  selected,
  onSelection,
  text,
  allowSelect = true,
}: SelectableButtonProps) {
  const theme = useTheme();
  const bgcolor = useMemo(() => {
    return disabled
      ? theme.palette.action.disabled
      : selected
      ? theme.palette.primary.main
      : theme.palette.neutral[300];
  }, [disabled, selected, theme]);
  const color = useMemo(() => {
    return selected ? theme.palette.base.white : theme.palette.grey[25];
  }, [selected, theme]);
  const sx = useMemo(
    () => ({
      bgcolor,
      color,
      height: '28px',
      padding: '4px 12px',
      borderRadius: '4px',
      '&:hover': {
        color: selected ? theme.palette.base.white : theme.palette.primary.main,
        bgcolor: !selected
          ? theme.palette.primary[25]
          : theme.palette.primary.main,
      },
    }),
    [bgcolor, color, selected, theme.palette.base.white, theme.palette.primary]
  );

  return (
    <Button
      sx={sx}
      onClick={() => allowSelect && onSelection(!selected)}
      disabled={disabled}
      variant="text"
    >
      {text}
    </Button>
  );
}
