import * as echarts from 'echarts/core';
import { ReactElement, useEffect, useRef } from 'react';
import { CanvasRenderer } from 'echarts/renderers';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import {
  BarChart,
  PieChart,
  MapChart,
  LineChart,
  ScatterChart,
} from 'echarts/charts';
import {
  TitleComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  GeoComponent,
  VisualMapComponent,
  DataZoomComponent,
} from 'echarts/components';
import { NoInfoToShowWithSadFace } from '../NoInfoToShow';

export type ChartProps = {
  key: string;
  option: Record<string, unknown> | null;
  style?: { width: string; height: string };
  classes?: string;
  render?: (instance: any) => void;
  loading?: boolean;
  geoData?: any;
  isMap?: boolean;
  onClickHandler?: (params: any) => void;
};

function Chart({
  loading,
  geoData,
  isMap = false,
  onClickHandler,
  ...props
}: ChartProps): ReactElement {
  const domNode = useRef<any>();
  const echartInstance = useRef<any>();

  useEffect(() => {
    echarts.use([
      TitleComponent,
      GridComponent,
      LegendComponent,
      TooltipComponent,
      GeoComponent,
      VisualMapComponent,
      DataZoomComponent,
      BarChart,
      PieChart,
      MapChart,
      LineChart,
      ScatterChart,
      LabelLayout,
      UniversalTransition,
      CanvasRenderer,
    ]);
    echartInstance.current =
      echarts.getInstanceByDom(domNode.current) ||
      echarts.init(domNode.current, undefined);
    const resizeCallback = () => echartInstance.current.resize();
    window.removeEventListener('resize', resizeCallback);
    window.addEventListener('resize', resizeCallback);
    window.removeEventListener('load', resizeCallback);
    window.addEventListener('load', resizeCallback);

    // add legend item click event handler, for example editing the value
    onClickHandler && echartInstance.current.on('click', onClickHandler);

    return () => {
      echarts.dispose(echartInstance.current);
      window.removeEventListener('resize', resizeCallback);
      window.removeEventListener('load', resizeCallback);
    };
  }, [domNode, onClickHandler, props.option]);

  useEffect(() => {
    if (!isMap && props.option && echartInstance.current) {
      echartInstance.current.hideLoading();
      echartInstance.current.setOption(props.option);
    }
    props.render && props.render(echartInstance.current);
  });

  useEffect(() => {
    loading === true && echartInstance.current?.showLoading();
    loading === false && echartInstance.current?.hideLoading();
    if (geoData) {
      echarts.registerMap('world', geoData);
      echartInstance.current?.hideLoading();
      echartInstance.current?.setOption(props.option);
    }
  }, [loading, geoData, props.option]);

  const dataProp = (props?.option as { series: { data: unknown }[] })
    ?.series?.[0]?.data;
  const isNullData = !(dataProp as Array<unknown>)?.[0];
  const noDataToShow = isNullData && (
    <NoInfoToShowWithSadFace classes={props.classes} />
  );

  return (
    <>
      {noDataToShow}
      <div
        ref={domNode}
        style={{
          ...(props.style || {
            width: '100%',
            height: '100%',
          }),
          display: isNullData ? 'none' : 'block',
        }}
        className={props.classes}
      ></div>
    </>
  );
}

export default Chart;
