import { capitalize } from '@mui/material';
import Color from 'color';
import * as echarts from 'echarts/core';
import { NumberInKMG } from '../../utils/common';
import { useChartCommonConfig } from './common';
import { CountryCode, CountryCodeName } from '../../utils/countries';

function Max(arr: number[], max = true) {
  if (arr.length <= 0) return 0;
  let ret = arr[0];
  for (let i = 0; i < arr.length; i++) {
    ret = max ? Math.max(arr[i], ret) : Math.min(arr[i], ret);
  }
  return ret;
}

// echart options configuration reference as below:
// https://echarts.apache.org/examples/zh/editor.html?c=area-pieces&lang=ts

export function GrowthLineChartOptions(
  data: DateValueArray,
  color = '#9B5DE6'
) {
  const config = useChartCommonConfig();
  return {
    legend: {
      show: false,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.map((v) => v[0]),
      axisLabel: {
        showMaxLabel: true,
        ...config.axisTextStyleBlack,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '5%'],
      scale: true,
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: true,
        formatter: NumberInKMG,
        ...config.axisTextStyleLightGrayPoppins,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    grid: {
      left: 50,
      top: 10,
      right: 40,
      bottom: 32,
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      textStyle: {
        ...config.tooltipFontStyle,
      },
    },
    series: [
      {
        type: 'line',
        showSymbol: data.length <= 2,
        label: {
          show: data.length <= 2,
          position: 'top',
          ...config.tooltipFontStyle,
          borderColor: 'inherit',
        },
        smooth: 0.3,
        lineStyle: {
          width: 1,
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color,
            },
            {
              offset: 1,
              color: Color(color).fade(1).toString(),
            },
          ]),
        },
        data,
      },
    ],
  };
}

/**
 * example:
 * EstimatedGrowthLineChartOptions(
    [['ss1', 12],['ss2', 42],['ss7', undefined]],
    [['ss1', undefined],['ss2', undefined],['ss6', 24]]
   )}
 */
export function EstimatedGrowthLineChartOptions(
  data1: DateValueUndefinedArray,
  data2: DateValueUndefinedArray,
  color1 = '#9B5DE6',
  color2 = '#3DCDAE'
) {
  return {
    legend: {
      show: false,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data1.map((v) => v[0]),
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '5%'],
      scale: true,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    grid: {
      left: 32,
      top: 10,
      right: 10,
      bottom: 32,
    },
    series: [
      {
        type: 'line',
        smooth: 0.2,
        symbol: 'none',
        lineStyle: {
          width: 1,
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: color1,
            },
            {
              offset: 1,
              color: Color(color1).fade(1).toString(),
            },
          ]),
        },
        data: data1,
      },
      {
        type: 'line',
        smooth: 0.2,
        symbol: 'none',
        lineStyle: {
          width: 1,
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: color2,
            },
            {
              offset: 1,
              color: Color(color2).fade(1).toString(),
            },
          ]),
        },
        data: data2,
      },
    ],
  };
}

/**
 * example:
 * EstimatedGrowthLineChartOptions(
    [['ss1', 12],['ss2', 42],['ss7', undefined]],
    [['ss1', undefined],['ss2', undefined],['ss6', 24]]
   )}
 */
export function NumberAndPercentLineChartOptions(
  data1: DateValueUndefinedArray,
  data2: DateValueUndefinedArray,
  color1 = '#694CE6',
  color2 = '#B0FF92'
) {
  const config = useChartCommonConfig();
  const data2Value = data2.map((w) => w[1] || 0);
  return {
    legend: {
      show: false,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data1.map((v) => v[0]),
      axisLine: {
        show: false,
        onZero: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: true,
        ...config.axisTextStyleLightGrayPoppins,
      },
    },
    yAxis: [
      {
        name: 'Avg. Content Views',
        type: 'value',
        boundaryGap: [0, '5%'],
        scale: true,
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          formatter: NumberInKMG,
          ...config.axisTextStyleLightGrayPoppins,
        },
      },
      {
        name: 'Engagement Rate (%)',
        type: 'value',
        scale: true,
        axisLabel: {
          show: true,
          ...config.axisTextStyleLightGrayPoppins,
          formatter: (w: string) => `${w}%`,
        },
        splitLine: {
          show: false,
        },
        min: Max(data2Value, false),
        max: Max(data2Value),
      },
    ],
    grid: {
      left: 64,
      top: 10,
      right: 64,
      bottom: 32,
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      textStyle: {
        ...config.tooltipFontStyle,
      },
      valueFormatter: (value: number) => {
        return Math.floor(value * 100) / 100;
      },
    },
    series: [
      {
        name: 'Avg. Content Views',
        type: 'line',
        smooth: 0.2,
        symbol: 'none',
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: color1,
            },
            {
              offset: 0.5,
              color: Color(color1).fade(0.8).toString(),
            },
            {
              offset: 1,
              color: Color(color1).fade(1).toString(),
            },
          ]),
        },
        data: data1.map((w) => w[1]),
      },
      {
        yAxisIndex: '1',
        name: 'Engagement Rate (%)',
        type: 'line',
        smooth: 0.2,
        symbol: 'none',
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: color2,
            },
            {
              offset: 0.5,
              color: Color(color2).fade(0.8).toString(),
            },
            {
              offset: 1,
              color: Color(color2).fade(1).toString(),
            },
          ]),
        },
        data: data2.map((w) => w[1]),
      },
    ],
  };
}

export function PieChartOptions(data: NameValueArray, showLegend = true) {
  const config = useChartCommonConfig();
  return {
    animation: false,
    color: config.pieChart,
    legend: {
      show: showLegend,
      selectedMode: false,
      top: '3%',
      orient: 'vertical',
      left: '60%',
      itemWidth: 12,
      itemHeight: 12,
      borderRadius: [2, 2, 2, 2],
      formatter: function (name: string) {
        const value = data.find((w) => w.name === name)?.value;
        return capitalize(value ? name + ` (${value}%)` : name);
      },
      textStyle: {
        color: '#292636',
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'Outfit',
      },
    },
    series: [
      {
        center: ['35%', '50%'],
        name: 'data',
        type: 'pie',
        radius: ['60%', '95%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };
}

export function PieChartRaceOptions(data: NameValueArray, showLegend = true) {
  const config = useChartCommonConfig();
  return {
    animation: false,
    color: config.colorRace,
    legend: {
      show: showLegend,
      selectedMode: false,
      top: '3%',
      orient: 'vertical',
      left: '60%',
      itemWidth: 12,
      itemHeight: 12,
      borderRadius: [2, 2, 2, 2],
      formatter: function (name: string) {
        const value = data.find((w) => w.name === name)?.value;
        return capitalize(value ? name + ` (${value}%)` : name);
      },
      textStyle: {
        color: '#292636',
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'Outfit',
      },
    },
    series: [
      {
        center: ['35%', '50%'],
        name: 'data',
        type: 'pie',
        radius: ['60%', '95%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };
}

export function LocationOptions(data: LocationType, showVisualMap = true) {
  const config = useChartCommonConfig();
  const sortedArray = data.top_countries;
  return {
    title: {
      text: '',
      textStyle: {
        fontSize: '1rem',
        fontWeight: '600',
      },
    },
    visualMap: {
      show: showVisualMap,
      type: 'piecewise',
      itemWidth: 9,
      itemHeight: 9,
      right: 0,
      top: 4,
      selectedMode: false,
      pieces: data.top_countries.map((d, index) => {
        return {
          // use index to separate the value if d.value has the same value
          min: d.value + index / 100 - 0.001,
          max: d.value + index / 100 + 0.001,
          label: `${CountryCodeName[d.country_code as CountryCode]?.slice(
            0,
            15
          )} (${d.value}%)`,
          color: config.geoChart[index % config.color.length],
        };
      }),
      left: 'right',
      min: sortedArray.length > 0 ? sortedArray[0].value : 0,
      max:
        sortedArray.length > 0 ? sortedArray[sortedArray.length - 1].value : 0,
      textStyle: {
        color: '#292636',
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'Outfit',
      },
    },
    tooltip: {
      trigger: 'item',
      showDelay: 0,
      transitionDuration: 0.2,
      valueFormatter: (v: number) => Math.floor(v),
    },
    series: [
      {
        left: 20,
        top: 12,
        itemStyle: { areaColor: '#D6D5D8', borderColor: '#FDFDFF' },
        name: 'Top Countries',
        emphasis: {
          disabled: true,
        },
        type: 'map',
        map: 'world',
        roam: true,
        aspectScale: 0.85,
        data: data.top_countries.map((d, index) => {
          const name = CountryCodeName[d.country_code as CountryCode];
          return {
            name: name,
            value: d.value + index / 100,
          };
        }),
      },
    ],
  };
}
