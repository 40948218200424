import { Box, Button, Typography, Theme } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useIntl } from 'react-intl';

import { createStyles, makeStyles } from '@mui/styles';
import { UserAccountMessages } from './Messages';

import { GoogleMonoIcon } from '../../assets/imgs/icons';
import { useGlobalStyles } from '../../assets/styles/style';
import { envResolverAPIURL } from '../../env';

type GoogleSSOButtonProps = {
  isLogin: boolean; // login | signup
  width: string;
};
export function GoogleSSOButton({ isLogin, width }: GoogleSSOButtonProps) {
  const intl = useIntl();
  const classes = useStyles();
  const gc = useGlobalStyles();
  return (
    <Box className={`border-radius-6px boxcenterhv ${classes.container}`}>
      <Button
        className={`border-radius-6px button-size ${classes.button}`}
        sx={{
          position: 'relative',
          textTransform: 'none',
        }}
      >
        <Box className="boxcenterhv" sx={{ width }}>
          <GoogleMonoIcon className={classes.icon} sx={{ mr: 1 }} />
          <Typography
            className={`${classes.caption}`}
            sx={{ fontSize: '16px !important' }}
          >
            {intl.formatMessage(
              isLogin
                ? UserAccountMessages.continueWithGoogle
                : UserAccountMessages.signupWithGoogle
            )}
          </Typography>
        </Box>
      </Button>
      <Box className={`boxcenterhv ${classes.buttonGoogle}`}>
        <GoogleLogin
          ux_mode="redirect"
          login_uri={`${envResolverAPIURL()}auth/sso/google`}
          onSuccess={(credentialResponse: CredentialResponse) => {
            window.location.href = `${envResolverAPIURL()}auth/sso?platform=google&token=${
              credentialResponse.credential
            }`;
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: '#ffffff',
      backgroundColor: theme.custom.google,
      ':hover': {
        color: '#ffffff',
        backgroundColor: theme.custom.google,
      },
      position: 'relative',
    },
    button: {
      color: '#ffffff',
      backgroundColor: theme.custom.google,
      ':hover': {
        color: '#ffffff',
        backgroundColor: theme.custom.google,
      },
    },
    buttonGoogle: {
      opacity: 0,
      zIndex: 1,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
    icon: { width: '0.75rem', height: '0.75rem' },
    caption: {
      marginLeft: '0rem',
      textAlign: 'left',
      fontSize: '15px',
      fontWeight: 600,
      color: theme.palette.base.white,
    },
  })
);
