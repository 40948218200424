import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { IconButton, Typography, Box, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { UserAccountMessages } from './Messages';

type GoBackProps = {
  handleClick?: fnVoidToVoid;
  mb?: number;
  mt?: number;
};
export default function GoBack({ handleClick, mt, mb }: GoBackProps) {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Box className="boxlefthcenterv" sx={{ mt: mt || 2.5, mb: mb || 2.5 }}>
      <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          borderRadius: '0rem',
          padding: 0,
        }}
        onClick={() => {
          handleClick && handleClick();
          navigate(-1);
        }}
        disableRipple
      >
        <ArrowBackIosNewIcon
          sx={{ fontSize: '0.75rem' }}
          htmlColor={theme.palette.base.black}
        />
        <Typography variant="subtitle1" sx={{ ml: 0.5 }}>
          {intl.formatMessage(UserAccountMessages.Back).toUpperCase()}
        </Typography>
      </IconButton>
    </Box>
  );
}
