
import {
  deployedEnv
} from "../../env";

type GetEmaliUnseenCountType = (count: number) => void;

export function getEmaliUnseenCount(callback:GetEmaliUnseenCountType){
  const env = deployedEnv();
  // if(env === "local") return callback(0);
  fetch("/components/api/get-left-navigation").then((res) => {
    if(res.status === 200) return res.json();
    return callback(0);
  }).then((data) => {
    console.log(data);
    if(data.length && Array.isArray(data)){
      callback(data[0].unseen);
    }
  });
  const eventSource = new EventSource("/components/api/events");
  eventSource.onmessage = function(event) {
    console.log(event);
    let data = JSON.parse(event.data);
    if(data && data.command === "COUNTERS"){
      callback(data.unseen);
    };
  };
  
}