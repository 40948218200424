import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  capitalize,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useContext, useState, useEffect } from 'react';
import { ApplicationContext } from '../context/context';
import { useAuthUserId } from '../../hooks/account';
import { Messages } from '../../localization/Messages';
import { TransitionUp } from '../../components/Transitions';
import { CrossIcon } from '../../assets/imgs/icons';
import { useGlobalStyles } from '../../assets/styles/style';
import {
  usePatchCampaignApplicationMutation,
  usePatchUserMutation,
} from '../../redux/benaApi';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { showMessage } from '../../components/ShowMessage';
import { useBreakpointXs } from '../../utils/useStyleHooks';

type PatchApplicationEditButtonProps = {
  fieldName: keyof Omit<
    PatchCampaignApplicationRequestType,
    'application_id' | 'status'
  >;
  initText: string;
};

export function PatchApplicationEditButton({
  fieldName,
  initText,
}: PatchApplicationEditButtonProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const isMobileView = useBreakpointXs();
  const context = useContext(ApplicationContext);
  const userId = useAuthUserId();
  const isCurrentUser = context.applicationCreatorId === userId;
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  useEffect(() => {
    setText(initText);
  }, [initText]);

  const [patch] = usePatchCampaignApplicationMutation({});
  const isValidForSave = () => {
    return !!text;
  };

  const [patchPhone] = usePatchUserMutation();
  const patchUser = async (newText = '') => {
    if (!fieldName || !isValidForSave()) return;
    setLoading(true);
    await MutationHandler({
      intl,
      action: async () => {
        return await patchPhone({
          [fieldName]: newText || text,
        }).unwrap();
      },
      onSuccess: () => {
        showMessage.success(intl.formatMessage(Messages.SuccessfullySaved));
        setOpen(false);
        context.notifyRefresh?.();
      },
    });
    setLoading(false);
  };

  const patchApplication = async (newText = '') => {
    if (!fieldName || !isValidForSave()) return;
    setLoading(true);

    if (fieldName === 'phone') {
      await patchUser(newText);
    } else {
      await MutationHandler({
        intl,
        action: async () => {
          return await patch({
            application_id: context.applicationId,
            [fieldName]: newText || text,
          }).unwrap();
        },
        onSuccess: () => {
          showMessage.success(intl.formatMessage(Messages.SuccessfullySaved));
          setOpen(false);
          context.notifyRefresh?.();
        },
      });
    }
    setLoading(false);
  };

  const rows = initText?.length > 60 ? 12 : 4;

  return isCurrentUser ? (
    <>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
        size="small"
        disableRipple
      >
        <Typography fontSize={'14px'} fontWeight="600" color="primary.main">
          {intl.formatMessage(Messages.Edit)}
        </Typography>
      </IconButton>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={TransitionUp}
          sx={{
            zIndex: theme.zIndex.modal,
            '& .MuiDialog-container > .MuiPaper-root': {
              backgroundColor: theme.palette.neutral[0],
              backgroundImage: 'none !important',
            },
            '& .MuiDialog-paper': {
              ...(isMobileView && { marginLeft: '16px', marginRight: '16px' }),
            },
          }}
        >
          <DialogTitle display={'flex'}>
            <Typography fontWeight="600" fontSize="16px" marginTop="8px">
              {intl.formatMessage(Messages.EditData)}
            </Typography>
            <Box flexGrow={1}></Box>
            <IconButton onClick={() => setOpen(false)}>
              <CrossIcon
                stroke={theme.palette.base.black}
                sx={{ width: '10px', height: '10px' }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {[
              'message_to_brand',
              'price',
              'turnaround_time_days',
              'post_display_duration',
              'proposal',
              'right_usage',
              'phone',
              'media_kit_link',
            ].includes(fieldName) ? (
              <Stack rowGap={'32px'} minWidth="300px">
                <textarea
                  rows={rows}
                  style={{
                    border: '1px solid ' + theme.palette.neutral[700],
                    fontSize: '14px',
                    fontFamily: 'Outfit',
                    padding: '4px',
                    backgroundColor: theme.palette.base.white,
                    color: theme.palette.base.dark2white,
                  }}
                  defaultValue={text}
                  autoFocus
                  onChange={(e) => setText(e.target.value)}
                  placeholder={fieldName === 'phone' ? '+11234567890' : ''}
                ></textarea>
                <Stack rowGap={'8px'}>
                  <Button
                    className={gs.buttonPrimary}
                    onClick={() => patchApplication()}
                    disabled={loading}
                  >
                    {intl.formatMessage(Messages.Confirm)}
                  </Button>
                  <Button
                    className={`border-radius-8px ${gs.buttonCancellation}`}
                    onClick={() => setOpen(false)}
                    disabled={loading}
                  >
                    {intl.formatMessage(Messages.cancel)}
                  </Button>
                </Stack>
              </Stack>
            ) : (
              <></>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  ) : (
    <></>
  );
}
