import {
  Box,
  Link,
  IconButton,
  Typography,
  useTheme,
  Theme,
} from '@mui/material';
import {
  DeleteIcon,
  DownloadIcon,
  FileUploadIcon,
  FileIcon,
} from '../assets/imgs/icons';
import { createStyles, makeStyles } from '@mui/styles';
import { ellipsisLongWords } from '../utils/common';
import { isMimePhoto, isMimeVideoOrAudio } from '../utils/mimetype';
import { BenaVideoPlayer } from './BenaVideoPlayer';
import { Messages } from '../localization/Messages';
import { useIntl } from 'react-intl';
import Color from 'color';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState } from 'react';
import { NOOP } from '../redux/benaApiResultHandler';

type UrlThumbnailProps = {
  url: string;
  onDelete?: fnVoidToVoid;
  name?: string;
  // TODO currently not distinguish file types by MIME-TYPE
  contentType?: string;
  isCustomDownload?: boolean;
};
export function UrlThumbnail({
  url,
  onDelete,
  name,
  contentType,
  isCustomDownload
}: UrlThumbnailProps) {
  const intl = useIntl();
  const theme = useTheme();
  const style = useUrlThumbnailStyles();
  const [status, setStatus] = useState<LoadingType>('init');
  const processHandler = (type: LoadingType) => setStatus(type);
  return (
    <Box
      className={`border-radius-8px ${style.uploadBoxWithouSize}`}
      sx={{
        background: `${
          isMimePhoto(contentType || '') ? `url(${url})` : ''
        } no-repeat center top`,
        backgroundSize: 'contain',
      }}
      width="100%"
      height="100%"
    >
      {onDelete && (
        <Box sx={{ width: '100%', pl: 1, pt: 1 }}>
          <IconButton onClick={() => onDelete && onDelete()}>
            <DeleteIcon
              viewBox="0 0 16 16"
              fill={theme.palette.primary.main}
              sx={{ width: '0.75rem', height: '0.75rem' }}
            />
          </IconButton>
        </Box>
      )}
      <Box sx={{ width: '100%', height: '60%' }} className="boxcenterhv">
        <Link
          href={url}
          target="_blank"
          rel="noreferrer"
          style={{ height: '100%', width: '100%' }}
          className="boxcenterhv"
          onClick={(e) => {
            e.preventDefault();
            isCustomDownload ? 
              hrefToDownload(url, name) : 
              status !== 'downloading' && downloadFile(url, name, processHandler);
          }}
          download={name}
        >
          {isMimePhoto(contentType || '') ? (
            <></>
          ) : isMimeVideoOrAudio(contentType || '') ? (
            <BenaVideoPlayer
              src={url}
              mediaType={contentType || ''}
              width={`100%`}
            />
          ) : (
            <FileUploadIcon sx={{ width: '1.8125rem', height: 'auto' }} />
          )}
        </Link>
      </Box>
      <Box
        sx={{
          p: 1,
          height: '40%',
          width: '100%',
          alignItems: 'space-between',
          flexDirection: 'column',
        }}
        className="boxcenterhv"
      >
        <Typography
          textAlign={'center'}
          sx={{
            px: 1,
            bgcolor: Color(theme.palette.base.white).fade(0.3).toString(),
          }}
        >
          {ellipsisLongWords(name || '')}
        </Typography>
        <Link
          href={url}
          target="_blank"
          rel="noreferrer"
          download={name || ''}
          onClick={(e) => {
            e.preventDefault();
            isCustomDownload ? 
              hrefToDownload(url, name) : 
              status !== 'downloading' && downloadFile(url, name, processHandler);
          }}
          title={name}
        >
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
              textAlign: 'center',
              bgcolor: Color(theme.palette.base.white).fade(0.2).toString(),
              width: '100%',
              px: 1,
              color: theme.palette.primary.main,
            }}
            className="boxcenterhv"
          >
            {intl.formatMessage(Messages.download)}
            {(status === 'init' || status === 'finished') && (
              <DownloadIcon sx={{ width: '0.875rem', height: '1rem', ml: 1 }} />
            )}
            {status === 'downloading' && (
              <CircularProgress color="primary" size={16} sx={{ ml: 1 }} />
            )}
            {status === 'error' && (
              <Box title={intl.formatMessage(Messages.Fail)} component="span">
                <ErrorOutlineIcon
                  color="warning"
                  sx={{ width: '1rem', height: '1rem', ml: 1 }}
                />
              </Box>
            )}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

export function UrlThumbnailCompact({ url, name }: UrlThumbnailProps) {
  const intl = useIntl();
  const theme = useTheme();
  const style = useUrlThumbnailStyles();
  const [status, setStatus] = useState<LoadingType>('init');
  const processHandler = (type: LoadingType) => setStatus(type);
  return (
    <Box className={`border-radius-8px ${style.uploadBoxCompact}`}>
      <Box
        sx={{
          height: '40%',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
        className="boxlefthcenterv"
      >
        <FileIcon fill={theme.custom.primary2} />
        <Typography
          sx={{
            px: 1,
            bgcolor: Color(theme.palette.base.white).fade(0.3).toString(),
            flexGrow: 1,
            fontWeight: '500',
          }}
        >
          {ellipsisLongWords(name || '')}
        </Typography>
        <Link
          href={url}
          target="_blank"
          rel="noreferrer"
          download={name || ''}
          onClick={(e) => {
            e.preventDefault();
            status !== 'downloading' && downloadFile(url, name, processHandler);
          }}
          title={name}
        >
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
              textAlign: 'center',
              bgcolor: Color(theme.palette.base.white).fade(0.2).toString(),
              width: '100%',
              px: 1,
              color: theme.palette.primary.main,
            }}
            className="boxcenterhv"
          >
            {(status === 'init' || status === 'finished') && (
              <DownloadIcon sx={{ ml: 1 }} />
            )}
            {status === 'downloading' && (
              <CircularProgress color="primary" size={16} sx={{ ml: 1 }} />
            )}
            {status === 'error' && (
              <Box title={intl.formatMessage(Messages.Fail)} component="span">
                <ErrorOutlineIcon
                  color="warning"
                  sx={{ width: '1rem', height: '1rem', ml: 1 }}
                />
              </Box>
            )}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

export function UrlThumbnailDownloadIcon({ url, name }: UrlThumbnailProps) {
  const intl = useIntl();
  const [status, setStatus] = useState<LoadingType>('init');
  const processHandler = (type: LoadingType) => setStatus(type);
  return (
    <Link
      href={url}
      target="_blank"
      rel="noreferrer"
      download={name || ''}
      onClick={(e) => {
        e.preventDefault();
        status !== 'downloading' && downloadFile(url, name, processHandler);
      }}
      title={name}
      className="boxcenterhv"
    >
      {(status === 'init' || status === 'finished') && (
        <DownloadIcon sx={{ ml: 1, width: '16px', height: '16px' }} />
      )}
      {status === 'downloading' && (
        <CircularProgress color="primary" size={16} sx={{ ml: 1 }} />
      )}
      {status === 'error' && (
        <Box title={intl.formatMessage(Messages.Fail)} component="span">
          <ErrorOutlineIcon
            color="warning"
            sx={{ width: '1rem', height: '1rem', ml: 1 }}
          />
        </Box>
      )}
    </Link>
  );
}

export const useUrlThumbnailStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadBox: {
      width: '8.625rem !important',
      height: '10.25rem !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      flexDirection: 'column',
      border: `0.0625rem solid ${theme.custom.primary4} !important`,
      backgroundColor: theme.custom.almostWhite,
      minWidth: '9.25rem',
    },
    uploadBoxWithouSize: {
      display: 'flex !important',
      justifyContent: 'center !important',
      alignItems: 'center !important',
      flexDirection: 'column',
      border: `0.0625rem solid ${theme.custom.primary5} !important`,
    },
    uploadBoxCompact: {
      background: theme.palette.base.white,
      borderRadius: '0.5rem',
      padding: '0rem 0.5rem',
      height: '3rem',
      bgcolor: theme.custom.primary4,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: '13.45rem',
    },
  })
);

function download(href: string, filename = '') {
  const a = document.createElement('a');
  a.download = filename;
  a.href = href;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadFile(
  _url: string,
  filename = '',
  progressHandler: ProgressHandler = NOOP
) {
  // #ticket2431 chrome browser not able to download file
  // add timestamp to bypass the browser cache
  const url = `${_url}${
    (_url || '').includes('?') ? '&' : '?'
  }timestamp=${new Date()}`;

  progressHandler('downloading');
  fetch(url, {
    headers: new Headers({
      Origin: window.location.origin,
    }),
  })
    .then((res) => res.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      download(blobUrl, filename);
      window.URL.revokeObjectURL(blobUrl);
      progressHandler('finished');
    })
    .catch(() => {
      progressHandler('error');
    });
}

export function hrefToDownload(url:string, name?: string){
  const a = document.createElement('a');
  a.href = url;
  a.download = url;
  a.name = name || "";
  a.click();
  a.remove();
}
