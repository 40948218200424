import moment from 'moment';
import { useCallback } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Messages } from '../localization/Messages';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';

export const SECRETCODE = 'WEBENA';
export const SINGLELINEMAXCHAR = 80;
export const MAXEMAILADDRESSLENGTH = 128;
export const VERIFICATIONCODECOUNTDOWNTIMER = 60;
export const USDOLLARSIGN = '$';
export const THROTTLETIMER_MS = 400;

export const DaysInOneWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as Array<keyof WeekDaysAndPercentage>;

export const TwentyfourHours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export function round(num: number, precision: number) {
  return (
    Math.round(parseFloat(`${+num}e${precision}`)) / Math.pow(10, precision)
  );
}

export function encodeObjectToURLSearchParams(obj: {
  [key: string]: string | number | boolean;
}) {
  const keys = Object.keys(obj);
  return keys
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(obj[key].toString())}`
    )
    .join('&');
}

export function replaceURLSearchParam(
  qs: string,
  query: string,
  value: string
) {
  if (!query) return qs;

  const iQ = qs.indexOf(`${query}=`);
  if (iQ >= 0) {
    const s = qs.slice(iQ);
    let iAnd = s.indexOf('&');
    iAnd = iAnd >= 0 ? iAnd : qs.length;
    return (
      qs.slice(0, iQ) +
      (['&', '?'].includes(qs[iQ - 1] || '') ? '' : '&') +
      qs.slice(iQ, iQ + query.length + 1) +
      value +
      qs.slice(iQ + iAnd)
    );
  } else {
    return (
      (qs.indexOf('?') < 0 ? '?' : '') +
      qs +
      (qs === '' ? '' : '&') +
      query +
      '=' +
      value
    );
  }
}
// remove specific value from obj and return a new object
export function skipObjectValue(
  obj: { [key: string]: string | number | boolean },
  skip: string | number | boolean
) {
  const keys = Object.keys(obj);
  const retObj = {} as any;
  keys.forEach((w) => {
    if (obj[w] !== skip) {
      retObj[w] = obj[w];
    }
  });
  return retObj;
}

export const numberFilter = (str: string) => {
  let ret = '';
  for (let i = 0; i < str.length; i++) {
    if (Validator.isBlacklisted(str[i], Numbers)) {
      ret += str[i];
    }
  }
  return ret;
};

export function keyPressed(
  event: React.KeyboardEvent<HTMLElement | SVGSVGElement>,
  fnCallback: fnVoidToVoid,
  key: string,
  ctrl = false
) {
  if (event.key === key && (ctrl ? event.ctrlKey : true)) {
    event.preventDefault();
    event.stopPropagation();
    fnCallback();
  }
}
export function spaceKeyPressed(
  event: React.KeyboardEvent<HTMLElement | SVGSVGElement>,
  fnCallback: fnVoidToVoid
) {
  keyPressed(event, fnCallback, ' ');
}
export function enterKeyPressed(
  event: React.KeyboardEvent<HTMLElement | SVGSVGElement>,
  fnCallback: fnVoidToVoid
) {
  keyPressed(event, fnCallback, 'Enter');
}
export function ctrlEnterKeyPressed(
  event: React.KeyboardEvent<HTMLElement | SVGSVGElement>,
  fnCallback: fnVoidToVoid
) {
  keyPressed(event, fnCallback, 'Enter', true);
}
export function arrowUpKeyPressed(
  event: React.KeyboardEvent<HTMLElement | SVGSVGElement>,
  fnCallback: fnVoidToVoid
) {
  keyPressed(event, fnCallback, 'ArrowUp');
}
export function arrowDownKeyPressed(
  event: React.KeyboardEvent<HTMLElement | SVGSVGElement>,
  fnCallback: fnVoidToVoid
) {
  keyPressed(event, fnCallback, 'ArrowDown');
}
export function escKeyPressed(
  event: React.KeyboardEvent<HTMLElement | SVGSVGElement>,
  fnCallback: fnVoidToVoid
) {
  keyPressed(event, fnCallback, 'Escape');
}

const DangerousChars = `<>&'"\\`;
const Numbers = '0123456789';

export const Validator = {
  isBlacklisted: (str: string, chars: string = DangerousChars) => {
    if (!str) return false;
    for (let i = str.length - 1; i >= 0; i--) {
      if (chars.indexOf(str[i]) >= 0) {
        return true;
      }
    }
    return false;
  },
  isValidEmail: (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  isValidUrl: (url: string) => {
    const pattern = new RegExp(
      `(https?:\\/\\/)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()!@:%_\\+.~#?&\\/\\/=]*)`,
      'i'
    );
    return pattern.test(url);
  },
  isAllInChars: (str: string, chars: string) => {
    for (let i = str.length - 1; i >= 0; i--) {
      if (chars.indexOf(str[i]) < 0) {
        return false;
      }
    }
    return true;
  },
  isDateInTheFuture: (date: string) => {
    return (
      moment(Number(date) || date) >
      moment(new Date().getMilliseconds() - 1000 * 60 * 60 * 24)
    );
  },
  isMobile: (str: string) => {
    return Validator.isAllInChars(str, Numbers);
  },
  isNumber: (str: string) => {
    return /^(0|[1-9][0-9]*)$/gi.test(str);
  },
  isfractionalNumber2digits: (str: string) => {
    return /^\d{1,19}$|(?=^.{1,19}$)^\d+\.\d{0,2}$/gi.test(str);
  },
  isfractionalNumber0To100: (str: string) => {
    if (Validator.isfractionalNumber2digits(str)) {
      const n = parseFloat(str);
      return n >= 0 && n <= 100;
    }
    return false;
  },
};

export const ValidateRules = {
  validInputString: (
    str: string,
    maxLen: number,
    valueType: InputValueType
  ) => {
    if (str === undefined || str === null) return false;

    const validateItems = valueType.split('|');
    return validateItems
      .map((item) => {
        const ret =
          item === 'empty'
            ? str.length === 0
            : item === 'anytext'
            ? true
            : item === 'password'
            ? str.length >= 8
            : item === 'anythingexceptempty'
            ? str.length > 0
            : item === 'url'
            ? Validator.isValidUrl(str)
            : !Validator.isBlacklisted(str) &&
              str.length <= maxLen &&
              str.length > 0 &&
              (item === 'email'
                ? Validator.isValidEmail(str)
                : item === 'verificationCode'
                ? str.length === 6 && Validator.isAllInChars(str, Numbers)
                : item === 'mobile'
                ? Validator.isMobile(str)
                : item === 'fractionalNumber2digits'
                ? Validator.isfractionalNumber2digits(str)
                : item === 'fractionalNumber0To100'
                ? Validator.isfractionalNumber0To100(str)
                : item === 'number'
                ? Validator.isNumber(str)
                : true);
        return ret;
      })
      .some((v) => v);
  },

  samePasswords: (pw1: string, pw2: string) => {
    return pw1 === pw2;
  },

  validDateRange: (startDate: string, endDate: string, allowPastDate = false) =>
    (allowPastDate
      ? startDate !== '' && endDate !== ''
      : Validator.isDateInTheFuture(startDate) &&
        Validator.isDateInTheFuture(endDate)) && endDate >= startDate,

  valueInRange: (
    value: number,
    min: number,
    max: number,
    noMaxLimit = false
  ) => {
    const allowedMax = 100000000;
    const maxValue = Math.min(max, allowedMax);
    return noMaxLimit
      ? value >= min && value <= allowedMax
      : value >= min && value <= maxValue;
  },
};

// hide or show html body scrolbar when open modal box
export function hideHtmlBodyScrollbar() {
  window.document.body.style.overflow = 'hidden';
  window.document.body.style.paddingRight = `${BrowserScrollBarWidth()}px`;
}

export function showHtmlBodyScrollbar() {
  window.document.body.style.overflow = '';
  window.document.body.style.paddingRight = '';
}

function BrowserScrollBarWidth() {
  const outer = document.createElement('div');
  outer.style.overflow = 'scroll';
  outer.style.height = '200px';
  outer.style.width = '100px';
  document.body.appendChild(outer);
  const widthNoScroll = outer.offsetWidth;
  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);
  const widthWithScroll = inner.offsetWidth;
  const scrollBarWidth = widthNoScroll - widthWithScroll;
  document.body.removeChild(outer);
  return scrollBarWidth;
}

export function formatDate(date: string | Date) {
  try {
    const d =
      typeof date === 'string' ? new Date(date.replace(/\s/, 'T')) : date;
    return `${d.getFullYear()}/${String(d.getMonth() + 1).padStart(
      2,
      '0'
    )}/${String(d.getDate()).padStart(2, '0')}`;
  } catch (err) {
    return '';
  }
}

export function formatDateHMS(date: string | Date) {
  try {
    const d =
      typeof date === 'string' ? new Date(date.replace(/\s/, 'T')) : date;
    return `${d.getFullYear()}/${String(d.getMonth() + 1).padStart(
      2,
      '0'
    )}/${String(d.getDate()).padStart(2, '0')} ${String(d.getHours()).padStart(
      2,
      '0'
    )}:${String(d.getMinutes()).padStart(2, '0')}:${String(
      d.getSeconds()
    ).padStart(2, '0')}`;
  } catch (err) {
    return '';
  }
}

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const firstLetterUppercase = (str: string) => {
  if (!str) return str;
  return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
};

export function ellipsisLongWords(
  text: string,
  length = 20,
  ellipsisMiddle = true
) {
  const newText =
    text.length > length
      ? ellipsisMiddle
        ? text.slice(0, Math.floor(length / 2)) +
          '...' +
          text.slice(-Math.floor(length / 2))
        : text.slice(0, length) + '...'
      : text;
  return newText;
}

export function UpcaseStringWords(str: string) {
  return str
    ? str
        .split(' ')
        .map((v) => `${v.slice(0, 1).toUpperCase()}${v.slice(1).toLowerCase()}`)
        .join(' ')
    : str;
}

export function UppercaseStringFirstLetter(str: string) {
  return str
    ? `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`
    : str;
}

export const RegExpURL = /(http:\/\/|https:\/\/)((:|@|\w|=|\?|%|\.|\/|&|-)+)/g;
export const RegExpURLWithoutHTTP = new RegExp(
  `(https?:\\/\\/)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()!@:%_\\+.~#?&\\/\\/=]*)`,
  'i'
);

export function formatDateTimeForPost(
  datetime: string | undefined,
  intl: IntlShape
) {
  return datetime
    ? moment(datetime).format(
        `MMMM DD,YYYY [${intl.formatMessage(Messages.at)}] hh:mm`
      )
    : '';
}
export function formatDateTimeForPostCompact(
  datetime: string | undefined,
  intl: IntlShape
) {
  return datetime ? moment(datetime).format(`YYYY-MM-DD hh:mm`) : '';
}

export function convertToFullUrl(url: string) {
  return url.match(/^http[s]*:\/\/(\s|\S)*/) ? url : `http://${url}`;
}

export function mapObjectKeyValuessToArray(obj: { [key: string]: number }) {
  const keys = Object.keys(obj || {});
  return keys.map((key) => [key, obj[key]]);
}

export function compactValueRangeDescription(
  valueRangeText: string,
  intl: IntlShape,
  unit: string
) {
  const arr = valueRangeText.split(/[_]/);
  return `${unit}${intl.formatNumber(Number(arr[0] || '') || 0, {
    notation: 'compact',
  })}${arr[1] ? ' - ' : valueRangeText.includes('+') ? '+' : ''}${
    arr[1]
      ? `${unit}${intl.formatNumber(Number(arr[1] || '') || 0, {
          notation: 'compact',
        })}`
      : '+'
  }`;
}

export function genCountdownStr(count: number, intl: IntlShape) {
  const time = moment.duration(count, 'seconds');
  const d = Math.floor(count / 60 / 60 / 24);
  const h = time.hours();
  const m = time.minutes();
  const s = time.seconds();
  return `${d}${intl
    .formatMessage(Messages.days)
    .slice(0, 1)
    .toUpperCase()} ${moment({
    h,
    m,
    s,
  }).format('HH:mm:ss')}`;
}

export function genDurationLength(count: number) {
  const s = count % 60;
  const m = (count - s) / 60;
  return `${String(m).padStart(2, '0')}:${String(Math.floor(s)).padStart(
    2,
    '0'
  )}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebouncedFn(fn: any, delay = 3000) {
  const debouncedFn = useCallback(
    debounce(fn, 3000, {
      leading: true,
      trailing: false,
      maxWait: 4000,
    }),
    []
  );
  return debouncedFn;
}

export function BrowserWindowInnerWidth() {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
}

export function SignOfNumber(num: number) {
  return num > 0 ? '+' : num < 0 ? '-' : '';
}

export function RandomId() {
  return Math.random().toString(36).slice(0, 5);
}

export function NumberInKMG(value: number) {
  return Math.abs(value / 1000000000) >= 1
    ? Math.floor((value / 1000000000) * 1000) / 1000 + 'G'
    : Math.abs(value / 1000000) >= 1
    ? Math.floor((value / 1000000) * 1000) / 1000 + 'M'
    : Math.abs(value / 1000) >= 1
    ? Math.floor((value / 1000) * 1000) / 1000 + 'K'
    : value;
}

export function pastDaysToNow(date?: string, label = '') {
  if (!date) return '';

  try {
    let d = new Date().valueOf() - new Date(date).valueOf();
    d = d <= 0 ? 0 : Math.ceil(d / (24 * 60 * 60 * 1000));
    return `${d} ${label}`;
  } catch (error) {
    console.log(error);
    return '';
  }
}

export function bytesCount(str: string) {
  let cnt = 0;
  for (let i = 0; i < str.length; i++) {
    const c = str.charAt(i);
    // eslint-disable-next-line no-control-regex
    if (/^[\u0000-\u00ff]$/.test(c)) {
      cnt++;
    } else {
      cnt += 2;
    }
  }
  return cnt;
}

export function byteSlice(str: string, len: number) {
  let cnt = 0,
    pos = 0;
  for (let i = 0; i < str.length; i++) {
    const c = str.charAt(i);
    // eslint-disable-next-line no-control-regex
    if (/^[\u0000-\u00ff]$/.test(c)) {
      cnt++;
    } else {
      cnt += 2;
    }
    pos++;

    if (cnt >= len) {
      return str.slice(0, pos);
    }
  }
  return str;
}

export function secondsToHMS(seconds: number) {
  return (
    `${Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0')}:`.replace('00:', '') +
    `${Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, '0')}:${Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0')}`
  );
}

export function addAtToUniqueId(uniqueId: string) {
  return `@${uniqueId.replace(/^@/, '')}`;
}

export function midnightOfYesterday() {
  const date = new Date().valueOf();
  return date - (date % (24 * 60 * 60 * 1000));
}

export function valueOfNDaysBefore(days: number) {
  return midnightOfYesterday() - (days - 1) * 24 * 60 * 60 * 1000;
}

export function formatDateYYYYMMDD(
  value: string | Date,
  padding = true,
  delimiter = '/'
) {
  const date = new Date(value);
  const delimit = padding ? ` ${delimiter} ` : delimiter;
  return `${date.getFullYear()}${delimit}${
    date.getMonth() + 1
  }${delimit}${date.getDate()}`;
}

export function plusSignForNumberOverLimit(
  startingPrice: number,
  maximum = 2000
) {
  return startingPrice > maximum ? `+` : '';
}

export function numberThreshold(startingPrice: number, maximum = 2000) {
  return Math.min(startingPrice, maximum);
}

export function formatDayHour(date: number) {
  const t = moment(date);
  return `${t.format('LT')?.replace(/:[\d]+\s/, '')} on ${t.format('dddd')}`;
}

export function dedupArray<T>(arr: T[], newArr: T[], key: keyof T) {
  const arrMerged = [...(arr || []), ...(newArr || [])];
  const set = new Set(arrMerged.map((v) => v[key]));
  return Array.from(set)
    .map((v) => arrMerged.find((w) => v === w[key]))
    .filter((v) => v) as T[];
}

export function calcLogBase(arr: number[]) {
  if (arr.length <= 0) return 1;
  return Math.max(...arr) > 1e4 ? 10 : 1;
}

const byTime = [
  365 * 24 * 60 * 60 * 1000,
  24 * 60 * 60 * 1000,
  60 * 60 * 1000,
  60 * 1000,
  1000,
];
const unit = ['year', 'day', 'hour', 'minute', 'second'];
export function timePastFormat(atime: Date) {
  let ct = new Date().getTime() - atime.getTime();
  if (ct < 60000) {
    return 'just now';
  }

  const sb = [];
  for (let i = 0; i < byTime.length; i++) {
    if (ct < byTime[i]) {
      continue;
    }
    const temp = Math.floor(ct / byTime[i]);
    ct = ct % byTime[i];
    if (temp > 0) {
      sb.push(temp + ' ' + unit[i] + (temp > 1 ? 's' : ''));
    }
    if (sb.length >= 1) {
      break;
    }
  }
  return sb.join('') + ' ago';
}

export function useTimePastFormatIntl() {
  const intl = useIntl();
  return function (atime: Date) {
    const str = timePastFormat(atime);
    try {
      const num = parseInt(str) || '';
      let unit = str;
      if (num) {
        unit = str.replace(num + ' ', '');
      }
      const trans = intl.formatMessage(Messages[unit as keyof typeof Messages]);
      return `${num}${
        ['just', 'ago'].find((w) => trans.indexOf(w) >= 0) ? ' ' : ''
      }${trans}`;
    } catch (err) {
      return str;
    }
  };
}

export function abbreviateLink(link: string, len = 30) {
  if (link.length < len) {
    return link;
  } else {
    return (
      link.slice(0, Math.floor(len / 2)) +
      '...' +
      link.slice(-Math.floor(len / 2))
    );
  }
}

export function useStabsFindTabIndexByQueryStringTab(options: string[]) {
  const tabName = new URLSearchParams(window.location.search).get('tab');
  if (tabName) {
    return options.findIndex((w) => w === tabName);
  } else {
    return -1;
  }
}

export function useUpdateURLQueryString(key: string) {
  const navigate = useNavigate();
  return (value: string) => {
    if (key) {
      const qsList = Array.from(
        new URLSearchParams(window.location.search).entries()
      );
      const hasIndex = qsList.findIndex((v) => v[0] === 'tab');
      if (hasIndex >= 0) {
        qsList[hasIndex][1] = value;
      } else {
        qsList.push([key, value]);
      }
      const newSearch = qsList.map((v) => `${v[0]}=${v[1]}`).join('&');
      navigate(window.location.pathname + '?' + newSearch);
    }
  };
}

export function hideScrollbar() {
  window.document.body.style.overflow = 'hidden';
  window.document.body.style.paddingRight = `${BrowserScrollBarWidth()}px`;
}

export function showScrollbar() {
  window.document.body.style.overflow = '';
  window.document.body.style.paddingRight = '';
}

export function convertLabelValueTypeArrayToObject(
  arr: LabelValueType[],
  parse: (s: string) => BasicValueType = (v) => v
) {
  const obj = {} as { [key: string]: BasicValueType };
  arr.forEach((v) => {
    obj[v.label] = parse(v.value);
  });
  return obj;
}

export function calcEngagementRate(rate: number) {
  return Math.round(rate * 1000) / 10 + '%';
}

export const DateFormatOptions = {
  year: 'numeric' as 'numeric' | '2-digit' | undefined,
  month: 'short' as 'numeric' | '2-digit' | undefined,
  day: 'numeric' as 'numeric' | '2-digit' | undefined,
};

export function replaceCurrentUrl(title: string, url: string) {
  window.history.replaceState({ additionalInformation: title }, title, url);
}

export function dateToISOString(date: Date, gap = 0) {
  return new Date(
    moment(date).startOf('day').hours(0).valueOf() + gap
  ).toISOString();
}

export function deleteCookie(name: string) {
  document.cookie =
    `${name}=; Max-Age=0; path=/; domain=` +
    (window.location.host || window.location.hostname);
}
