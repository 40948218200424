import { useDispatch } from 'react-redux';
import PlanTypeSelection from '../../components/PlanTypeSelection';
import { useLanguage } from '../../hooks/language';
import { countryLanguages } from './countries';
import { setLang } from './LanguageSlice';

type LanguageSwitchProps = {};
export default function LanguageSwitch({}: LanguageSwitchProps) {
  const dispatch = useDispatch();
  const lang = useLanguage();
  const list = countryLanguages.filter((w) => w.code !== 'RU')

  return (
    <PlanTypeSelection
      options={list.map((v) => ({
        value: v.lang,
        label: v.langName,
      }))}
      selected={lang}
      onChange={(selectedLang) => {
        let index = countryLanguages.findIndex((w) => w.lang === selectedLang);
        index = Math.max(0, index);
        dispatch(setLang({ languageIndex: index }));
      }}
      compact={true}
    />
  );
}
