import { useIntl } from 'react-intl';
import { OptionsMessages } from '../localization/OptionsMessages';
import { Messages } from '../localization/Messages';

export function useAIContentCategoryOptions() {
  return [
    { value: 'Activewear', name: 'Activewear' },
    { value: 'Cosmetics', name: 'Cosmetics' },
    { value: 'Electric Bike', name: 'Electric Bike' },
    { value: 'Electronics', name: 'Electronics' },
    { value: 'Furniture', name: 'Furniture' },
    { value: 'Hats', name: 'Hats' },
    { value: 'Jewelry', name: 'Jewelry' },
    { value: 'Nutrition Supplement', name: 'Nutrition Supplement' },
    { value: 'Pants', name: 'Pants' },
    { value: 'Shoe', name: 'Shoe' },
    { value: 'Swimwear', name: 'Swimwear' },
    { value: 'Toys', name: 'Toys' },
    { value: 'Wig', name: 'Wig' },
  ];
}

export function useContentStylesOptions() {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(OptionsMessages.ContentConsistency),
      value: 'content consistency',
    },
    {
      label: intl.formatMessage(OptionsMessages.EmotionalResonance),
      value: 'emotional resonance',
    },
    {
      label: intl.formatMessage(OptionsMessages.MultisceneSwitching),
      value: 'multi-scene switching',
    },
    {
      label: intl.formatMessage(OptionsMessages.TopProfilesMentioning),
      value: 'top profiles mentioning',
    },
    {
      label: intl.formatMessage(OptionsMessages.TrendyHashtags),
      value: 'trendy hashtags',
    },
    {
      label: intl.formatMessage(OptionsMessages.PopularMusic),
      value: 'popular music',
    },
    {
      label: intl.formatMessage(OptionsMessages.MultirolePlay),
      value: 'multi-role play',
    },
    {
      label: intl.formatMessage(OptionsMessages.MulticameraShooting),
      value: 'multi-camera shooting',
    },
    {
      label: intl.formatMessage(OptionsMessages.ShootingSkills),
      value: 'shooting skills',
    },
    {
      label: intl.formatMessage(OptionsMessages.HotTopic),
      value: 'hot topic',
    },
    {
      label: intl.formatMessage(OptionsMessages.ShortSeries),
      value: 'short series',
    },
    {
      label: intl.formatMessage(OptionsMessages.Confrontation),
      value: 'confrontation',
    },
    {
      label: intl.formatMessage(OptionsMessages.ChallengeSticker),
      value: 'challenge sticker',
    },
    {
      label: intl.formatMessage(OptionsMessages.Explanation),
      value: 'explanation',
    },
    {
      label: intl.formatMessage(OptionsMessages.RichEmotionality),
      value: 'rich emotionality',
    },
    {
      label: intl.formatMessage(OptionsMessages.DailyRoutine),
      value: 'daily routine',
    },
    {
      label: intl.formatMessage(OptionsMessages.FunnySubtitles),
      value: 'funny subtitles',
    },
    {
      label: intl.formatMessage(OptionsMessages.MultipleAngles),
      value: 'multiple angles',
    },
    {
      label: intl.formatMessage(OptionsMessages.ExperienceSharing),
      value: 'experience sharing',
    },
    {
      label: intl.formatMessage(OptionsMessages.FollowersInteraction),
      value: 'followers interaction',
    },
    {
      label: intl.formatMessage(OptionsMessages.RichExpressions),
      value: 'rich expressions',
    },
    {
      label: intl.formatMessage(OptionsMessages.LipSync),
      value: 'lip sync',
    },
    {
      label: intl.formatMessage(OptionsMessages.Greenscreen),
      value: 'greenscreen',
    },
    {
      label: intl.formatMessage(OptionsMessages.Filter),
      value: 'filter',
    },
    {
      label: intl.formatMessage(OptionsMessages.SeveralActors),
      value: 'several actors',
    },
    {
      label: intl.formatMessage(OptionsMessages.FabulousMakeup),
      value: 'fabulous makeup',
    },
    {
      label: intl.formatMessage(OptionsMessages.Transformation),
      value: 'transformation',
    },
    {
      label: intl.formatMessage(OptionsMessages.Cosplay),
      value: 'cosplay',
    },
    {
      label: intl.formatMessage(OptionsMessages.TryOnAndDisplay),
      value: 'try on and display',
    },
    {
      label: intl.formatMessage(OptionsMessages.FaceCloseup),
      value: 'face close-up',
    },
    {
      label: intl.formatMessage(OptionsMessages.Storytelling),
      value: 'storytelling',
    },
    {
      label: intl.formatMessage(OptionsMessages.Comedy),
      value: 'comedy',
    },
    {
      label: intl.formatMessage(OptionsMessages.Drama),
      value: 'drama',
    },
    {
      label: intl.formatMessage(OptionsMessages.Dance),
      value: 'dance',
    },
    {
      label: intl.formatMessage(OptionsMessages.InspiredTutorial),
      value: 'inspired tutorial',
    },
    {
      label: intl.formatMessage(OptionsMessages.TheClearTopic),
      value: 'the clear topic',
    },
    {
      label: intl.formatMessage(OptionsMessages.Crazy),
      value: 'crazy',
    },
    {
      label: intl.formatMessage(OptionsMessages.EditingSkill),
      value: 'editing skill',
    },
    {
      label: intl.formatMessage(OptionsMessages.Narrative),
      value: 'narrative',
    },
    {
      label: intl.formatMessage(OptionsMessages.Vlogging),
      value: 'vlogging',
    },
    {
      label: intl.formatMessage(OptionsMessages.Expressions),
      value: 'expressions',
    },
    {
      label: intl.formatMessage(OptionsMessages.InteractionsWithKids),
      value: 'interactions with kids',
    },
    {
      label: intl.formatMessage(OptionsMessages.InteractionsWithPets),
      value: 'interactions with pets',
    },
    {
      label: intl.formatMessage(OptionsMessages.Challenge),
      value: 'challenge',
    },
    {
      label: intl.formatMessage(OptionsMessages.ShootingAndEditing),
      value: 'shooting and editing',
    },
    {
      label: intl.formatMessage(OptionsMessages.Tutorial),
      value: 'tutorial',
    },
    {
      label: intl.formatMessage(OptionsMessages.Series),
      value: 'series',
    },
    {
      label: intl.formatMessage(OptionsMessages.Destressing),
      value: 'destressing',
    },
    {
      label: intl.formatMessage(OptionsMessages.Duet),
      value: 'duet',
    },
    {
      label: intl.formatMessage(OptionsMessages.Activewear),
      value: 'activewear',
    },
    {
      label: intl.formatMessage(OptionsMessages.Unboxing),
      value: 'unboxing',
    },
    {
      label: intl.formatMessage(OptionsMessages.DIY),
      value: 'diy',
    },
    {
      label: intl.formatMessage(OptionsMessages.Painting),
      value: 'painting',
    },
    {
      label: intl.formatMessage(OptionsMessages.Singer),
      value: 'singer',
    },
  ];
}

export function useCharacteristicsOptions() {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(OptionsMessages.BeautyCreator),
      value: 'beauty creator',
    },
    {
      label: intl.formatMessage(OptionsMessages.Seeding),
      value: 'seeding',
    },
    {
      label: intl.formatMessage(OptionsMessages.Sponsorship),
      value: 'sponsorship',
    },
    {
      label: intl.formatMessage(OptionsMessages.DailyRoutine),
      value: 'daily routine',
    },
    {
      label: intl.formatMessage(OptionsMessages.UGCCreator),
      value: 'UGC creator',
    },
    {
      label: intl.formatMessage(OptionsMessages.Celebrity),
      value: 'celebrity',
    },
    {
      label: intl.formatMessage(OptionsMessages.FashionCreator),
      value: 'fashion creator',
    },
    {
      label: intl.formatMessage(OptionsMessages.Model),
      value: 'model',
    },
    {
      label: intl.formatMessage(OptionsMessages.ImitationMakeup),
      value: 'imitation makeup',
    },
    {
      label: intl.formatMessage(OptionsMessages.FemaleEntrepreneur),
      value: 'female entrepreneur',
    },
    {
      label: intl.formatMessage(OptionsMessages.SkinAndHairCare),
      value: 'skin and hair care',
    },
    {
      label: intl.formatMessage(OptionsMessages.ExperienceSharing),
      value: 'experience sharing',
    },
    {
      label: intl.formatMessage(OptionsMessages.LGBT),
      value: 'LGBT',
    },
    {
      label: intl.formatMessage(OptionsMessages.Lifestyle),
      value: 'Lifestyle',
    },
    {
      label: intl.formatMessage(OptionsMessages.ASMR),
      value: 'ASMR',
    },
    {
      label: intl.formatMessage(OptionsMessages.Plussize),
      value: 'plussize',
    },
    {
      label: intl.formatMessage(OptionsMessages.Hairstylist),
      value: 'hairstylist',
    },
    {
      label: intl.formatMessage(OptionsMessages.Sing),
      value: 'sing',
    },
    {
      label: intl.formatMessage(OptionsMessages.Gym),
      value: 'gym',
    },
    {
      label: intl.formatMessage(OptionsMessages.Cooking),
      value: 'cooking',
    },
    {
      label: intl.formatMessage(OptionsMessages.Shoes),
      value: 'shoes',
    },
    {
      label: intl.formatMessage(OptionsMessages.Couples),
      value: 'couples',
    },
    {
      label: intl.formatMessage(OptionsMessages.Family),
      value: 'family',
    },
    {
      label: intl.formatMessage(OptionsMessages.Musician),
      value: 'musician',
    },
    {
      label: intl.formatMessage(OptionsMessages.Couple),
      value: 'couple',
    },
    {
      label: intl.formatMessage(OptionsMessages.Gamer),
      value: 'gamer',
    },
    {
      label: intl.formatMessage(OptionsMessages.Athlete),
      value: 'athlete',
    },
  ];
}

export function usePostDisplayDurationOptions() {
  const intl = useIntl();
  return [
    { label: intl.formatMessage(Messages.optionForever), value: 65535 },
    { label: intl.formatMessage(Messages.optionTwoWeeks), value: 14 },
    { label: intl.formatMessage(Messages.optionOneMonth), value: 30 },
    {
      label: intl.formatMessage(Messages.optionThreeMonths),
      value: 120,
    },
    { label: intl.formatMessage(Messages.optionSixMonths), value: 240 },
    { label: intl.formatMessage(Messages.optionOneYear), value: 365 },
  ];
}

export function useShowOnlyOptions() {
  return [10, 20, 30, 50];
}

export function usePatchJobContractShippingStatusOptions() {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(Messages.shippingStatusPRE_TRANSIT),
      value: 'PRE_TRANSIT',
    },
    {
      label: intl.formatMessage(Messages.shippingStatusTRANSIT),
      value: 'TRANSIT',
    },
    {
      label: intl.formatMessage(Messages.EnterTrackingNumber),
      value: 'trackingno',
    },
    {
      label: intl.formatMessage(Messages.shippingStatusDELIVERED),
      value: 'DELIVERED',
    },
    {
      label: intl.formatMessage(Messages.shippingStatusRETURNED),
      value: 'RETURNED',
    },
    {
      label: intl.formatMessage(Messages.shippingStatusFAILURE),
      value: 'FAILURE',
    },
    {
      label: intl.formatMessage(Messages.shippingStatusUNKNOWN),
      value: 'UNKNOWN',
    },
    {
      label: intl.formatMessage(Messages.Done),
      value: 'done',
    },
  ] as { label: string; value: JobContractShippingStatusType }[];
}

export function useJobContractStatusOptions() {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(Messages.jobContractStatusDrafted),
      value: 'drafted',
    },
    {
      label: intl.formatMessage(Messages.jobContractStatusOffered),
      value: 'offered',
    },
    {
      label: intl.formatMessage(Messages.jobContractStatusAccepted),
      value: 'accepted',
    },
    {
      label: intl.formatMessage(Messages.jobContractStatusRejected),
      value: 'rejected',
    },
    {
      label: intl.formatMessage(Messages.jobContractStatusFinisheds),
      value: 'finished',
    },
  ] as { label: string; value: JobContractStatusType }[];
}

export function useShippingSupplierOptions() {
  return [
    { label: 'Canada Post', value: 'canada_post' },
    { label: 'Deutsche Post', value: 'deutsche_post' },
    { label: 'DHL Express', value: 'dhl_express' },
    { label: 'FedEx', value: 'fedex' },
    { label: 'Royal Mail', value: 'royal_mail' },
    { label: 'UPS', value: 'ups' },
    { label: 'USPS', value: 'usps' },
  ];
}
