import { useTheme, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

type InputErrorMessageProps = {
  error: boolean;
  inputValueType: InputValueType;
};

export function InputErrorMessage({
  error,
  inputValueType,
}: InputErrorMessageProps) {
  const theme = useTheme();
  const intl = useIntl();
  return error ? (
    <Typography sx={{ color: theme.custom.lightRed, my: 0.5 }}>
      {intl.formatMessage({ id: `ValidationError.${inputValueType}` })}
    </Typography>
  ) : (
    <></>
  );
}

type InputErrorCustomMessageProps = {
  error: boolean;
  errorMessage: string;
};

export function InputErrorCustomMessage({
  error,
  errorMessage,
}: InputErrorCustomMessageProps) {
  const theme = useTheme();
  return error ? (
    <Typography sx={{ color: theme.custom.lightRed, my: 0.5 }}>
      {errorMessage}
    </Typography>
  ) : (
    <></>
  );
}
