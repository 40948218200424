import { useIntl } from 'react-intl';
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { ValidateRules } from '../../utils/common';
import GoBack from './GoBack';
import { useResetPasswordMutation } from '../../redux/benaApi';
import { useNavigate } from 'react-router-dom';
import { staticURLLogin } from '../../route/urls';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { delay } from '../../components/ShowMessage';
import { useGlobalStyles } from '../../assets/styles/style';
import LogoAndLanguage from './LogoAndLanguage';
import { UserAccountMessages } from './Messages';

export default function ResetPassword() {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  const globalStyles = useGlobalStyles();

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputDataType,
    key: string
  ) => {
    const str = event.target.value.slice(0, data.maxLength);
    setFormData({
      ...formData,
      // cancel the red error hightlight when input change
      createPassword: { ...formData.createPassword, error: false },
      repeatPassword: { ...formData.repeatPassword, error: false },
      [key]: {
        ...data,
        content: str,
        length: event.target.value.length,
        error: !ValidateRules.validInputString(
          str,
          data.maxLength,
          data.inputValueType
        ),
      },
    });
  };

  const [formData, setFormData] = useState<AccountPasswordType>({
    createPassword: {
      content: '',
      maxLength: 80,
      error: false,
      showPassword: false,
      inputValueType: 'password',
    },
    repeatPassword: {
      content: '',
      maxLength: 80,
      error: false,
      showPassword: false,
      inputValueType: 'password',
    },
  });

  const togglePasswordVisibility = (
    key: string,
    data: PasswordInputDataType
  ) => {
    setFormData({
      ...formData,
      createPassword: {
        ...formData.createPassword,
        showPassword: !data.showPassword,
      },
      repeatPassword: {
        ...formData.repeatPassword,
        showPassword: !data.showPassword,
      },
    });
  };

  const refCreatePassword = useRef<HTMLElement>(null);
  const refRepeatPassword = useRef<HTMLElement>(null);
  useEffect(() => {
    refCreatePassword.current?.focus();
  }, []);

  const inputList = [
    {
      item: formData.createPassword,
      message: UserAccountMessages.newPassword,
      name: 'createPassword',
      refEl: refCreatePassword,
      placeHolder: UserAccountMessages.enterAPassword,
    },
    {
      item: formData.repeatPassword,
      message: UserAccountMessages.repeatPassword,
      name: 'repeatPassword',
      refEl: refRepeatPassword,
      placeHolder: UserAccountMessages.repeatAPassword,
    },
  ] as Array<FormInputType>;

  const checkSamePassword = (saveFormData = true) => {
    if (
      !ValidateRules.samePasswords(
        formData.createPassword.content,
        formData.repeatPassword.content
      )
    ) {
      saveFormData &&
        setFormData({
          ...formData,
          createPassword: { ...formData.createPassword, error: true },
          repeatPassword: { ...formData.repeatPassword, error: true },
        });
      return false;
    }
    return true;
  };

  const isValidForSave = function (saveFormData = true): boolean {
    for (let i = 0; i < inputList.length; i++) {
      const { item: input, refEl: ref, name: key } = inputList[i];
      if (
        !ValidateRules.validInputString(
          input.content,
          input.maxLength,
          input.inputValueType
        )
      ) {
        if (saveFormData) {
          setFormData({
            ...formData,
            [key]: { ...input, error: true },
          });
          ref.current?.focus();
        }
        return false;
      }
    }
    if (!checkSamePassword(saveFormData)) {
      saveFormData && refCreatePassword.current?.focus();
      return false;
    }

    return saveFormData ? true : !submitting;
  };
  const [submitting, setSubmitting] = useState(false);

  const [resetPassword] = useResetPasswordMutation();
  const urlParams = new URLSearchParams(window.location.search);

  const handleContinue = async () => {
    if (isValidForSave()) {
      setSubmitting(true);
      const params: ResetPasswordRequestParams = {
        email: urlParams.get('email') || '',
        new_password: formData.createPassword.content,
        token: urlParams.get('token') || '',
      };
      (await MutationHandler(
        {
          intl,
          action: async () => await resetPassword(params).unwrap(),
          onSuccess: () => {
            delay(() => {
              navigate(
                `${staticURLLogin}?reset_password_status=${encodeURI(
                  intl.formatMessage(UserAccountMessages.success)
                )}`
              );
            }, 2000);
          },
        },
        UserAccountMessages.resetPasswordSucceed
      )) && setSubmitting(false);
    }
  };

  return (
    <Box>
      <LogoAndLanguage />
      <GoBack />
      <Box className="form-width">
        <Typography fontWeight="bold" fontSize="1.5rem" sx={{ mb: '1rem' }}>
          {intl.formatMessage(UserAccountMessages.resetPassword)}
        </Typography>
        <form>
          {inputList.map((data) => {
            const { item, message, name, refEl, placeHolder } = data;
            return (
              <Box
                key={message.id}
                className={'boxcenterhv'}
                sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 1.75,
                    color: theme.palette.grey[800],
                    fontSize: '0.625rem',
                    fontWeight: 600,
                  }}
                  component="div"
                  className={'boxcenterhv'}
                >
                  {intl.formatMessage(message).toUpperCase()}
                  <Typography
                    sx={{
                      color: theme.palette.danger[400],
                      ml: '0.25rem',
                      fontSize: '0.75rem',
                      alignSelf: 'stretch',
                    }}
                  >
                    *
                  </Typography>
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    fullWidth
                    placeholder={intl.formatMessage(placeHolder)}
                    inputRef={refEl}
                    type={item.showPassword ? 'text' : 'password'}
                    className={`border-radius-6px input-size`}
                    id={message.id}
                    value={item.content}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleTextInputChange(event, item, name);
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={(theme) => ({
                          fontSize: theme.typography.caption,
                        })}
                      >
                        <IconButton
                          disableRipple
                          onClick={() => togglePasswordVisibility(name, item)}
                        >
                          {item.showPassword ? (
                            <Typography
                              variant="subtitle1"
                              sx={{ color: theme.palette.primary.main }}
                            >
                              {intl.formatMessage(UserAccountMessages.hide)}
                            </Typography>
                          ) : (
                            <Typography
                              variant="subtitle1"
                              sx={{ color: theme.palette.primary.main }}
                            >
                              {intl.formatMessage(UserAccountMessages.show)}
                            </Typography>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={item.error}
                  />
                </FormControl>
              </Box>
            );
          })}
          <Box sx={{ mb: 4.5 }}>
            {(!ValidateRules.samePasswords(
              formData.createPassword.content,
              formData.repeatPassword.content
            ) ||
              formData.createPassword.content.length < 8) && (
              <Box
                color={theme.palette.danger[400]}
                sx={{
                  fontSize: '0.75rem',
                }}
                className="boxlefthcenterv"
              >
                <img
                  src={'/imgs/passswordsMismatch.svg'}
                  style={{ marginRight: theme.spacing(2) }}
                  alt="alert"
                />
                {intl.formatMessage(UserAccountMessages.passwordDonotMatch)}
                {' / '}
                {intl.formatMessage(UserAccountMessages.passwordSettingRules)}
              </Box>
            )}
          </Box>
          <Box>
            <Button
              type="submit"
              variant="contained"
              className={`border-radius-6px button-size`}
              onClick={(e) => {
                e.preventDefault();
                handleContinue();
              }}
              disabled={!isValidForSave(false)}
            >
              {intl.formatMessage(UserAccountMessages.resetPassword)}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
