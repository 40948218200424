import { Fragment, ReactNode, useContext } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { getPlatformSvgIcon } from '../../utils/getPlatformSvgIcon';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { useIntl } from 'react-intl';
import { useGlobalStyles } from '../../assets/styles/style';
import { CreatorContext } from '../context/context';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import LightTooltip from '../../components/LightTooltip';
import { staticURLCampaignPublicUrlNoApply } from '../../route/urls';

type CampaignCardProps = {
  campaignId: string;
  platformIds: SocialPlatformId[];
  launchedOn: string;
  logo: string;
  title: string;
  image: string;
  actionButton?: ReactNode;
  label?: string;
  labelSx?: { bgcolor?: string };
  labelTooltip?: ReactNode;
  cardClickable?: boolean;
};
export default function CampaignCard({
  campaignId,
  platformIds,
  launchedOn,
  logo,
  title,
  image,
  actionButton,
  label,
  labelSx,
  labelTooltip,
  cardClickable = true,
}: CampaignCardProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const { setCurrentDisplayedCampaignId } = useContext(CreatorContext);
  const isMobile = useBreakpointXs();
  return (
    <Card
      sx={{
        maxWidth: 390 + (isMobile ? -32 : 0),
        minWidth: 390 + (isMobile ? -32 : 0),
        position: 'relative',
        borderRadius: '10px',
        boxShadow: '0px 1px 2px rgba(25, 21, 40, 0.04)',
        border: '1px solid ' + theme.palette.neutral[600],
        '&:hover': {
          border: `1px solid ${theme.palette.grey[700]}`,
        },
        cursor: cardClickable ? 'pointer' : 'arrow',
        bgcolor: theme.palette.grey['white_600'],
      }}
      onClick={() => {
        cardClickable && setCurrentDisplayedCampaignId(campaignId);
      }}
    >
      <Box
        sx={{
          '&:hover > .hoverCover': {
            visibility: 'visible',
          },
        }}
      >
        <CardMedia
          component="img"
          height="209"
          image={image}
          alt="campaign"
          className="img-blend-color"
        />
        <Button
          sx={{
            opacity: 0.7,
            visibility: 'hidden',
            left: 0,
            top: 0,
            width: '100%',
            height: '209px',
            position: 'absolute',
            borderRadius: '10px 10px 0px 0px',
            bgcolor: theme.palette.primary.main,
          }}
          className="hoverCover"
        ></Button>
        <Stack
          sx={{
            visibility: 'hidden',
            left: 0,
            top: 0,
            width: '100%',
            height: '209px',
            position: 'absolute',
            borderRadius: '10px 10px 0px 0px',
          }}
          className="hoverCover"
          justifyContent={'center'}
          alignItems={'center'}
        >
          {actionButton}
          {/* <Button
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '152px',
              height: '40px',
              boxShadow: '0px 1px 2px rgba(25, 21, 40, 0.04)',
              borderRadius: '8px',
            }}
            className={gs.buttonWhite}
            variant="contained"
            onClick={() => {
              setCurrentDisplayedCampaignId(campaignId);
            }}
          >
            <Typography color="primary.500">
              {intl.formatMessage(CreatorMessages.ViewDetails)}
            </Typography>
          </Button> */}
        </Stack>
      </Box>
      <Stack
        direction={'row'}
        paddingLeft={'16px'}
        paddingRight={'16px'}
        marginTop={'-32px'}
      >
        <Typography
          color={theme.palette.neutral[700]}
          fontSize={'13px'}
          fontWeight={500}
          sx={{ textShadow: '1px 1px 1px black' }}
        >
          {intl.formatMessage(CreatorMessages.LaunchedOn)}
        </Typography>
        <Box flexGrow={1}></Box>
        <Typography
          color={theme.palette.neutral[700]}
          fontSize={'13px'}
          fontWeight={500}
          sx={{ textShadow: '1px 1px 1px black' }}
        >
          {launchedOn}
        </Typography>
      </Stack>
      <CardContent sx={{ marginTop: '12px' }}>
        <img
          src={logo}
          alt="logo"
          style={{ height: '24px', borderRadius: '4px' }}
        />
        <Divider
          sx={{
            borderColor: theme.palette.neutral['600_1'],
            marginTop: '4px',
            marginBottom: '6px',
          }}
        />
        {cardClickable ? (
          <Typography color="grey.700" fontSize={'15px'} fontWeight={500}>
            {title}
          </Typography>
        ) : (
          <Link
            target="_blank"
            href={`${staticURLCampaignPublicUrlNoApply}?campaign_id=${campaignId}`}
          >
            <Typography color="grey.700" fontSize={'15px'} fontWeight={500}>
              {title}
            </Typography>
          </Link>
        )}
      </CardContent>
      <Stack
        left={0}
        top={0}
        height={'32px'}
        position="absolute"
        borderRadius="10px 0px 10px 0px"
        bgcolor={theme.palette.base.white_3}
        alignItems={'center'}
        justifyContent={'center'}
        border={`1px solid ${theme.palette.neutral[700]}`}
        direction="row"
        columnGap="8px"
        paddingRight={'8px'}
        paddingLeft={'8px'}
      >
        {platformIds
          .map((id) => getPlatformSvgIcon(id))
          .map((svg, index) => (
            <Fragment key={index}>
              {svg?.({
                fill: theme.palette.grey['200_white'],
                mask: theme.palette.base['white_black'],
                sx: { width: '16px', height: '16px' },
              }) || ''}
            </Fragment>
          ))}
      </Stack>
      {label && labelSx && (
        <Stack
          right={0}
          top={0}
          height={'32px'}
          position="absolute"
          borderRadius="0x 10px 0px 0px"
          bgcolor={labelSx.bgcolor || theme.palette.base.white}
          alignItems={'center'}
          justifyContent={'center'}
          direction="row"
          columnGap="8px"
          paddingRight={'8px'}
          paddingLeft={'8px'}
        >
          {labelTooltip ? (
            <LightTooltip title={labelTooltip}>
              <Typography color="base.white" fontWeight="500" fontSize="14px">
                {label}
              </Typography>
            </LightTooltip>
          ) : (
            <Typography color="base.white" fontWeight="500" fontSize="14px">
              {label}
            </Typography>
          )}
        </Stack>
      )}
    </Card>
  );
}
