import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    enterTouchDelay={0}
    arrow={true}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary[400],
    color: theme.palette.base.whiteonly,
    borderRadius: '8px',
    fontSize: '0.875rem',
    fontFamily: 'Outfit',
    fontWeight: 400,
    maxWidth: 200,
    border: '1px solid ' + theme.palette.primary['400_2'],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary['400_2'],
  },
}));

export const LightTooltipWide = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      enterTouchDelay={0}
      arrow={true}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary[400],
    color: theme.palette.base.whiteonly,
    borderRadius: '8px',
    fontSize: '0.875rem',
    fontFamily: 'Outfit',
    fontWeight: 400,
    maxWidth: 400,
    border: '1px solid ' + theme.palette.primary['400_2'],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary['400_2'],
  },
}));

export const LightTooltipExWide = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow={true}
      classes={{ popper: className }}
      enterTouchDelay={0}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary[400],
    width: '2rem',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary[400],
    color: theme.palette.base.whiteonly,
    borderRadius: '8px',
    fontSize: '0.875rem',
    fontFamily: 'Outfit',
    fontWeight: 400,
    maxWidth: 800,
    border: '1px solid ' + theme.palette.primary['400_2'],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary['400_2'],
  },
}));

export default LightTooltip;
