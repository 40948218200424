import { Box, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

type TitledPanelProps = {
  title: string;
  children: ReactElement;
  gap?: string;
  marginTop?: string;
  required?: boolean;
  actionElement?: ReactElement;
};
export default function TitledPanel({
  title,
  children,
  gap,
  marginTop,
  required,
  actionElement = <></>,
}: TitledPanelProps) {
  return (
    <Stack marginTop={marginTop || 'auto'}>
      <Stack direction="row" flexWrap={'wrap'} alignItems="center">
        <Typography
          fontWeight={500}
          fontSize={'14px'}
          color="grey.700"
          className="title"
        >
          {title}
          {title && required && <FieldRequired />}
        </Typography>
        <Box flexGrow={1}></Box>
        {actionElement}
      </Stack>
      <Box marginTop={gap || '6px'}>{children}</Box>
    </Stack>
  );
}

export function FieldRequired() {
  return (
    <Typography color="danger.500" sx={{ ml: '16px' }} component="span">
      *
    </Typography>
  );
}
