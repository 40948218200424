import { Box, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

type TitledPanelProps = {
  title: string;
  children: ReactElement;
  gap?: string;
  marginTop?: string;
  action?: ReactElement;
};
export default function TitledPanel({
  title,
  children,
  gap,
  marginTop,
  action,
}: TitledPanelProps) {
  return (
    <Stack marginTop={marginTop || '0px'} alignItems={'flex-start'}>
      <Stack direction="row" columnGap="4px" alignItems="center">
        <Typography fontWeight={500} fontSize={'14px'} color="grey.700">
          {title}
        </Typography>
        {action}
      </Stack>
      <Box marginTop={gap || '12px'} width={'100%'}>
        {children}
      </Box>
    </Stack>
  );
}
