import { CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Account from '../pages/Account';
import AIGC from '../pages/AIGC';
import Homepage from '../pages/Homepage';
import PageLayoutWrapper, {
  HomePageLayoutWrapper,
} from '../pages/PageLayoutWrapper';
import UserProfile from '../pages/UserProfile';
import ProtectedRoute from './ProtectedRoute';
import ErrorMsg from '../features/errormsg/ErrorMsg';
// import BenaPage from '../pages/BenaPage';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import CreatorHomepage from '../creator/pages/CreatorHomepage';
import BrandCampaignsPage from '../creator/pages/BrandCampaignsPage';
import CreatorsPage from '../pages/creatorsPage';
import CreatorsUserList from '../components/myCreators/creatorUserList';
import {
  useIsAdminUser,
  useIsRoleBrand,
  useIsRoleCreator,
} from '../hooks/account';
import NewCampaignPage from '../creator/pages/NewCampaignPage';
import MessagePage from '../pages/MessagePage';
import Authorization from '../pages/authorization';
import UnloadingFeedback from '../pages/unloadingFeedback';
import RocketChatLogin from '../features/rocketchat/RocketChatLogin';
import UploadFormCreator from '../components/myCreators/uploadCreator';
import { PublicCampaigndetailsPage } from '../creator/pages/PublicCampaignDetailsPage';
import ApplicationViewPage from '../creator/pages/ApplicationViewPage';
import { HomepageSocialAccountVerificationCallback } from '../creator/pages/SocialAccountVerificationCallback';
import JobsPage from '../creator/pages/JobsPage';
import React from 'react';

const BenaPageNew = React.lazy(() => import('../pages/BenaPageNew'));
const PaymentProtection = React.lazy(
  () => import('../features/account/PaymentProtection')
);
const AdminPage = React.lazy(() => import('../admin/Admin'));
const CookieNotice = React.lazy(
  () => import('../features/account/CookieNotice')
);
const Page404 = React.lazy(() => import('../pages/404'));
const IconsPage = React.lazy(() => import('../pages/IconsPage'));

const PrivacyPolicy = React.lazy(
  () => import('../features/account/PrivacyPolicy')
);
const TermsOfService = React.lazy(
  () => import('../features/account/TermsOfService')
);

export default function MainRouter() {
  const auth = useSelector((state: RootState) => state.auth);
  const isCreator = useIsRoleCreator();
  const isBrand = useIsRoleBrand();
  const isAdmin = useIsAdminUser();

  return (
    <Router>
      <Routes>
        <Route
          path="/creators/:id"
          element={
            <ProtectedRoute
              path="/creators/:id"
              element={
                <PageLayoutWrapper showHeader={true}>
                  <AIGC />
                </PageLayoutWrapper>
              }
            />
          }
        />
        {!auth.user && (
          <>
            <Route path="/"
              element={
                <Suspense fallback={<CircularProgress size={8} />}>
                  <HomePageLayoutWrapper
                    showHeader={false}
                    showMobileViewPadding={false}
                    fullWidth={true}
                  >
                    <BenaPageNew entranceType="creators" />
                  </HomePageLayoutWrapper>
                </Suspense>
              }
            />
            <Route path="/brands"
              element={
                <Suspense fallback={<CircularProgress size={8} />}>
                  <HomePageLayoutWrapper
                    showHeader={false}
                    showMobileViewPadding={false}
                    fullWidth={true}
                  >
                    <BenaPageNew entranceType="brands" />
                  </HomePageLayoutWrapper>
                </Suspense>
              }
            />
          </>
        )}
        {isBrand && (
          <Route
            path="/"
            element={
              <ProtectedRoute
                path="/"
                element={
                  <PageLayoutWrapper>
                    <Homepage />
                  </PageLayoutWrapper>
                }
              />
            }
          />
        )}
        <Route
          path="/account/*"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <Account />
            </Suspense>
          }
        />
        <Route
          path="/myprofile"
          element={
            <ProtectedRoute
              path="/myprofile"
              element={
                <Suspense fallback={<CircularProgress size={8} />}>
                  <PageLayoutWrapper showHeader={true} isPullable={true}>
                    <UserProfile />
                  </PageLayoutWrapper>
                </Suspense>
              }
            />
          }
        />
        {isCreator && (
          <>
            <Route
              path="/"
              element={
                <ProtectedRoute
                  path="/"
                  element={
                    <Suspense fallback={<CircularProgress size={8} />}>
                      <PageLayoutWrapper showHeader={true} isPullable={true}>
                        <CreatorHomepage />
                      </PageLayoutWrapper>
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/application-preview/:id"
              element={
                <ProtectedRoute
                  path="/application-preview/:id"
                  element={
                    <Suspense fallback={<CircularProgress size={8} />}>
                      <ApplicationViewPage />
                    </Suspense>
                  }
                />
              }
            />{' '}
            <Route
              path="/sso_verify_callback_home_page/:campaign_id"
              element={
                <ProtectedRoute
                  path="/sso_verify_callback_home_page/:campaign_id"
                  element={
                    <Suspense fallback={<CircularProgress size={8} />}>
                      <HomepageSocialAccountVerificationCallback />
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/jobs"
              element={
                <ProtectedRoute
                  path="/jobs"
                  element={
                    <Suspense fallback={<CircularProgress size={8} />}>
                      <PageLayoutWrapper showHeader={true} isPullable={true}>
                        <JobsPage />
                      </PageLayoutWrapper>
                    </Suspense>
                  }
                />
              }
            />
          </>
        )}
        {isBrand && (
          <>
            <Route
              path="/campaigns"
              element={
                <ProtectedRoute
                  path="/campaigns"
                  element={
                    <Suspense fallback={<CircularProgress size={8} />}>
                      <PageLayoutWrapper showHeader={true}>
                        <BrandCampaignsPage />
                      </PageLayoutWrapper>
                    </Suspense>
                  }
                />
              }
            />
            <Route
              path="/campaigns/new"
              element={
                <ProtectedRoute
                  path="/campaigns/new"
                  element={
                    <Suspense fallback={<CircularProgress size={8} />}>
                      <PageLayoutWrapper showHeader={true}>
                        <NewCampaignPage />
                      </PageLayoutWrapper>
                    </Suspense>
                  }
                />
              }
            />
          </>
        )}
        {isBrand && (
          <>
            <Route
              path="/my-creators"
              element={
                <ProtectedRoute
                  path="/my-creators"
                  element={
                    <PageLayoutWrapper showHeader={true}>
                      <CreatorsPage />
                    </PageLayoutWrapper>
                  }
                />
              }
            />
            <Route path="/my-creators/userlist"
              element={
                <ProtectedRoute path="/my-creators/userlist"
                  element={
                    <PageLayoutWrapper showHeader={true}>
                      <CreatorsUserList />
                    </PageLayoutWrapper>
                  }
                />
              }
            />
            <Route path="/my-creators/upload-creator"
              element={
                <ProtectedRoute path="/my-creators/upload-creator"
                  element={
                    <PageLayoutWrapper showHeader={true}>
                      <UploadFormCreator />
                    </PageLayoutWrapper>
                  }
                />
              }
            />
          </>
        )}
        <Route
          path="/messages"
          element={
            <ProtectedRoute
              path="/messages"
              element={
                <Suspense fallback={<CircularProgress size={8} />}>
                  <PageLayoutWrapper
                    showHeader={true}
                    showMobileViewPadding={false}
                    isPullable={true}
                  >
                    <MessagePage />
                  </PageLayoutWrapper>
                </Suspense>
              }
            />
          }
        />
        <Route
          path="/public_campaign"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <PageLayoutWrapper showHeader={true}>
                <PublicCampaigndetailsPage />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        <Route
          path="/public_campaign_noapply"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <PageLayoutWrapper showHeader={true}>
                <PublicCampaigndetailsPage showActionButtons={false} />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        <Route
          path="/rocketchat/login"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <RocketChatLogin />
            </Suspense>
          }
        />
        <Route
          path="/privacy"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/terms"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <TermsOfService />
            </Suspense>
          }
        />
        <Route
          path="/privacy_h"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <PageLayoutWrapper>
                <PrivacyPolicy />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        <Route
          path="/terms_h"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <PageLayoutWrapper>
                <TermsOfService />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        <Route
          path="/paymentprotection"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <PageLayoutWrapper>
                <PaymentProtection />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        <Route
          path="/cookienotice"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <PageLayoutWrapper>
                <CookieNotice />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        {isAdmin && (
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute
                path="/admin"
                element={
                  <Suspense fallback={<CircularProgress size={8} />}>
                    <AdminPage />
                  </Suspense>
                }
              />
            }
          />
        )}
        <Route
          path="/icons"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <IconsPage />
            </Suspense>
          }
        />
        <Route
          path="/authorization"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <Authorization />
            </Suspense>
          }
        />
        <Route
          path="/unloading-feedback"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <UnloadingFeedback />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<CircularProgress size={8} />}>
              <Page404 status={404} />
            </Suspense>
          }
        />
      </Routes>
      <ErrorMsg />
    </Router>
  );
}
