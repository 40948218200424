import {
  Box,
  Fade,
  IconButton,
  MenuItem,
  SxProps,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { escKeyPressed } from '../utils/common';
import { useGlobalStyles } from '../assets/styles/style';
import {
  selectExpandedDropdownFilter,
  setExpandedDropdownFilter,
} from '../features/search/dynamicSearchSlice';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../redux/store';
import { DeleteIcon, DropdownIcon } from '../assets/imgs/icons';

type CollapsiblePanelProps = {
  message: string;
  children: React.ReactNode;
  fontClassName: string;
  sx?: SxProps;
  containerPy?: string;
  height?: string;
  width?: string;
  clearable?: boolean;
  onClearFilter?: fnVoidToVoid;
  title?: string;
  isDefaultValue?: boolean;
  defaultCollapsed?: boolean;
  valueFollowTitle?: boolean;
  listItemCount?: number;
  collapseEnabled?: boolean;
  fontWeight?: string;
  actionButtons?: React.ReactNode;
  stickTitleOnTop?: boolean;
  showBorder?: boolean;
  onTitleClicked?: fnVoidToVoid;
  // not set margin/padding on left side
  noLeftSpacing?: boolean;
  showBorderTitle?: boolean;
  valueLength?: number;
  titleIcon?: React.ReactNode;
  highlightName?: React.ReactNode;
  showRequired?: boolean;
};

export default function CollapablePanel({
  message,
  children,
  fontClassName,
  sx = {},
  defaultCollapsed = false,
  title,
  listItemCount,
  collapseEnabled = true,
  height = '2rem',
  actionButtons,
  stickTitleOnTop = false,
  showBorder = true,
  onTitleClicked,
  noLeftSpacing = false,
  showBorderTitle = true,
  titleIcon,
  highlightName,
  showRequired = false,
}: CollapsiblePanelProps) {
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  return (
    <Box
      sx={{
        borderBottom: showBorder
          ? `0.0625rem solid ${theme.custom.primary4}`
          : '0rem',
        bgcolor: theme.palette.base.white,
      }}
    >
      <Box
        sx={{
          zIndex: 2,
          height,
          px: noLeftSpacing ? '0rem' : { md: '1.5rem', xs: '1rem' },
          borderBottom: showBorderTitle
            ? collapsed
              ? `0.0625rem solid transparent`
              : `0.0625rem solid ${theme.custom.primary4}`
            : '0',
          bgcolor: theme.palette.base.white,
        }}
        className={`boxcenterhv ${collapseEnabled ? 'cursorHand' : ''} ${
          stickTitleOnTop ? 'scroll-sticky-top' : ''
        }`}
        onClick={() => {
          onTitleClicked && onTitleClicked();
          collapseEnabled && setCollapsed(!collapsed);
        }}
      >
        {titleIcon}
        {highlightName}
        <Typography className={fontClassName} sx={{ mr: '0.5rem' }}>
          {message}
          {showRequired && (
            <span
              style={{ color: theme.custom.lightRed, marginLeft: '0.25rem' }}
            >
              *
            </span>
          )}
        </Typography>
        {title && (
          <Typography
            className={fontClassName}
            sx={{ color: theme.palette.primary.main, mr: '0.5rem' }}
          >
            {title}
          </Typography>
        )}
        {!!listItemCount && (
          <Typography className={fontClassName} sx={{ mr: '0.5rem' }}>
            {`(${listItemCount})`}
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }}></Box>
        {collapseEnabled && (
          <IconButton onClick={() => setCollapsed(!collapsed)}>
            <DropdownIcon
              sx={{ width: '0.875rem', height: '0.6125rem' }}
              className={`cursorHand ${collapsed ? 'rotate180' : ''}`}
            />
          </IconButton>
        )}
        {actionButtons || ''}
      </Box>
      {!collapsed && (
        <Box
          sx={{
            margin: noLeftSpacing
              ? { xs: '0rem 0rem 1rem 0rem', md: '0rem 0rem 1.5rem 0rem' }
              : { xs: '1rem', md: '1.5rem' },
            ...sx,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export function CollapablePanelDropdown({
  message,
  children,
  fontClassName,
  sx = {},
  height = '2.875rem',
  width = '15.125rem',
  clearable = false,
  onClearFilter,
  title,
  isDefaultValue,
  defaultCollapsed,
  valueFollowTitle = false,
  valueLength = 10,
}: CollapsiblePanelProps) {
  const uniqueId = `${message}${title}`;
  const theme = useTheme();
  const globalStyles = useGlobalStyles();
  const [collapsed, _setCollapsed] = useState(true);
  const dispatch = useDispatch();
  const setCollapsed = (collapsed: boolean) => {
    _setCollapsed(collapsed);
    dispatch(setExpandedDropdownFilter(uniqueId));
  };

  const expendedDropdownFilter = useTypedSelector(selectExpandedDropdownFilter);
  useEffect(() => {
    if (expendedDropdownFilter !== uniqueId) _setCollapsed(true);
  }, [expendedDropdownFilter]);

  useEffect(() => {
    const clickToCollpase = () => {
      setCollapsed(true);
    };
    window.addEventListener('click', clickToCollpase);
    return () => {
      window.removeEventListener('click', clickToCollpase);
    };
  }, []);

  // this useeffect is used to collapse dropdown menu when
  // clicking item which is not custom
  useEffect(() => {
    if (undefined !== defaultCollapsed) {
      !collapsed && setCollapsed(defaultCollapsed);
    }
  }, [defaultCollapsed, children]);
  return (
    <Box
      sx={{ position: 'relative', width: { xs: '100%', md: 'inherit' } }}
      onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
        escKeyPressed(event, () => {
          setCollapsed(true);
          event.stopPropagation();
        });
      }}
      id={title}
      onClick={(e) => {
        // stop propagation, so we can click the elements on
        // the children elements
        e.stopPropagation();
      }}
    >
      <Box
        className={`${
          collapsed ? 'border-radius-8px' : 'border-radius-8px-top'
        }`}
        sx={{
          border: `0.0625rem solid ${theme.custom.primary4}`,
          minWidth: width,
        }}
      >
        <MenuItem
          sx={{
            height,
            minHeight: height,
            px: 2,
            bgcolor: isDefaultValue
              ? theme.palette.base.white
              : theme.custom.primary4,
          }}
          className="boxcenterhv cursorHand border-radius-7px"
          onClick={(e) => {
            setCollapsed(!collapsed);
            e.stopPropagation();
          }}
          id={title}
        >
          {valueFollowTitle ? (
            <>
              <Typography
                className={`${fontClassName} ${globalStyles.font12px500Black}`}
              >
                {message === title ? '' : title}
              </Typography>
              <Typography
                className={`${fontClassName} ${globalStyles.font12px600Primary2}`}
                sx={{
                  color:
                    message === title
                      ? theme.custom.gray
                      : `${theme.custom.black} !important`,
                }}
              >
                {message}
              </Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
            </>
          ) : (
            <>
              <Typography
                className={`${fontClassName} ${globalStyles.font12px500Black}`}
                sx={{
                  color:
                    message === title
                      ? theme.custom.gray
                      : `${theme.custom.black} !important`,
                }}
              >
                {message === title
                  ? message
                  : message.substring(0, valueLength)}
              </Typography>
              <Typography
                className={`${fontClassName} ${globalStyles.font12px500Black}`}
              >
                {message === title ? '' : title}
              </Typography>
              {!clearable && <Box sx={{ flexGrow: 1 }}></Box>}
            </>
          )}

          {!clearable && (
            <IconButton
              onClick={() => setCollapsed(!collapsed)}
              sx={{ marginTop: '0.125rem' }}
            >
              <DropdownIcon
                sx={{ width: '0.875rem', height: '0.6125rem' }}
                className={`cursorHand ${collapsed ? 'rotate180' : ''}`}
                fill={'transparent'}
              />
            </IconButton>
          )}
          {clearable && <Box sx={{ flexGrow: 1, minWidth: '0.5rem' }}></Box>}
          {clearable && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onClearFilter && onClearFilter();
              }}
            >
              <DeleteIcon
                viewBox="0 0 16 16"
                fill={theme.palette.primary.main}
                sx={{ width: '0.625rem', height: '0.625rem' }}
              />
            </IconButton>
          )}
        </MenuItem>
      </Box>
      {!collapsed && (
        <Fade in={!collapsed}>
          <Box
            sx={{
              p: 1,
              width: '100%',
              ...sx,
              position: 'absolute',
              left: 0,
              top: `${parseFloat(height) + 0.06125}rem`,
              zIndex: 1003,
              border: `0.0625rem solid ${theme.custom.primary4}`,
              bgcolor: theme.palette.base.white,
            }}
            className="border-radius-8px-bottom tagBoxShadowRightBottom"
          >
            {children}
          </Box>
        </Fade>
      )}
    </Box>
  );
}
