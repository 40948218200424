import { envResolverAPIURL } from '../env';

type OnProgressFunctionType = (progress: {
  state: string;
  percentage: number;
}) => void;
/*
  This function is designed to replace the reductoolkit fetch function.
  It has progress report functionality.
*/
function upload<T = FileUploadResponseType>(
  pathName: string,
  formData: FormData,
  onProgress: OnProgressFunctionType,
  accessToken: string,
  method = 'POST'
) {
  return new Promise<APIResponse<T>>((resolve, reject) => {
    const req = new XMLHttpRequest();

    req.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        onProgress({
          state: 'pending',
          percentage: (event.loaded / event.total) * 100,
        });
      }
    });

    req.onreadystatechange = () => {
      if (req.readyState === XMLHttpRequest.DONE) {
        // TODO status always return 0 in case of CORS
        const status = req.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          onProgress({ state: 'done', percentage: 100 });
          try {
            resolve(JSON.parse(req.response));
          } catch (e) {
            reject('Invalid response data');
          }
        } else {
          onProgress({ state: 'error', percentage: 0 });
          switch (status) {
            case 404:
              reject('Resource not found');
              return;
            case 413:
              reject('File too large');
              return;
            default:
              reject(req.response);
          }
        }
      }
    };

    req.upload.addEventListener('load', (event) => {
      onProgress({ state: 'done', percentage: 99 });
    });

    req.upload.addEventListener('error', (event) => {
      onProgress({ state: 'error', percentage: 0 });
      reject(req.response);
    });

    req.open(method || 'POST', `${envResolverAPIURL()}${pathName}`);
    req.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    req.send(formData);
  });
}

export function xmlHttpFileUploadCampaignCover(
  formData: FormData,
  onProgress: OnProgressFunctionType,
  accessToken: string
) {
  const pathName = `campaigns/media`;
  return upload(pathName, formData, onProgress, accessToken);
}

export function xmlHttpFileUploadCampaignAttachment(
  campaignId: string,
  formData: FormData,
  onProgress: OnProgressFunctionType,
  accessToken: string
) {
  const pathName = `campaigns/${campaignId}/attachment`;
  return upload(pathName, formData, onProgress, accessToken);
}

export function xmlHttpFileUploadCampaignMilestoneAttachment(
  milestoneId: string,
  formData: FormData,
  onProgress: OnProgressFunctionType,
  accessToken: string
) {
  const pathName = `milestones/${milestoneId}/milestone-attachments`;
  return upload(pathName, formData, onProgress, accessToken);
}

export function xmlHttpFileUploadJobContractFile(
  jobContractId: string,
  formData: FormData,
  onProgress: OnProgressFunctionType,
  accessToken: string
) {
  const pathName = `job-contracts/${jobContractId}/files`;
  return upload(pathName, formData, onProgress, accessToken);
}

export function xmlHttpFileUploadMediaKit(
  applicationId: string,
  formData: FormData,
  onProgress: OnProgressFunctionType,
  accessToken: string
) {
  const pathName = `upload/application-media-kit/${applicationId}`;
  return upload<MessageResponseType>(
    pathName,
    formData,
    onProgress,
    accessToken
  );
}

export function xmlHttpJobContractFileUpload(
  jobContractId: string,
  formData: FormData,
  onProgress: OnProgressFunctionType,
  accessToken: string
) {
  const pathName = `job-contract/${jobContractId}/files`;
  return upload(pathName, formData, onProgress, accessToken);
}

export function xmlHttpJobContractFilePatch(
  jobContractId: string,
  fileId: string,
  formData: FormData,
  onProgress: OnProgressFunctionType,
  accessToken: string
) {
  const pathName = `job-contract/${jobContractId}/files?file_id=${fileId}`;
  return upload(pathName, formData, onProgress, accessToken, 'PATCH');
}
