import { Collapse, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type CollapsibleAlertPanelProps = {
  message: string;
  open: boolean;
  handleClose: fnBooleanToVoid;
};

export default function CollapsibleAlertPanel({
  message,
  open,
  handleClose,
}: CollapsibleAlertPanelProps) {
  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              handleClose(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {message}
      </Alert>
    </Collapse>
  );
}
