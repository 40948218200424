import { Box, Stack, Typography, useTheme } from '@mui/material';
import { PlusCircleIcon } from '../assets/imgs/icons';
import { useIntl } from 'react-intl';
import { CampaignMessages } from '../localization/CampaignMessages';

type BenaFileUploadButtonProps = {
  width?: string;
  height?: string;
  label?: string;
  className?: string;
  showLabelBrowserFile?: boolean;
};

export function BenaFileUploadButton({
  width = '10.25rem',
  height = '10.25rem',
  label = '',
  className = '',
  showLabelBrowserFile = true,
}: BenaFileUploadButtonProps) {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <Box
      sx={{
        width: { xs: width, md: width },
        height: height || width,
        borderRadius: '0.5rem',
        flexDirection: 'column',
        p: 0.5,
      }}
      className={`boxcenterhv ${className}`}
    >
      <PlusCircleIcon
        sx={{ width: '42px', height: '42px' }}
        stroke={theme.palette.grey[700]}
      />
      <Typography
        textAlign={'center'}
        sx={{ color: theme.palette.grey[700] }}
        fontWeight={500}
        fontSize="20px"
        marginTop="14px"
      >
        {label || intl.formatMessage(CampaignMessages.DropYourFiles)}
      </Typography>
      {showLabelBrowserFile && (
        <Stack direction="row" marginTop="4px">
          <Typography color="primary.main" fontWeight={500} fontSize="14px">
            {intl.formatMessage(CampaignMessages.BrowseFiles)}
          </Typography>{' '}
          &nbsp;
          <Typography color="grey.700" fontWeight={500} fontSize="14px">
            {intl.formatMessage(CampaignMessages.fromYourComputer)}
          </Typography>
        </Stack>
      )}
    </Box>
  );
}
