import { Messages } from '../../localization/Messages';
import { useIntl } from 'react-intl';
import { capitalize } from '@mui/material/utils';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { useIsRoleBrand, useIsRoleCreator } from '../../hooks/account';

export function useMenus() {
  const intl = useIntl();
  const isMobileView = useBreakpointXs();
  const isBrand = useIsRoleBrand();
  const isCreator = useIsRoleCreator();

  const menusForBrand = isBrand
    ? [
        {
          name: capitalize(intl.formatMessage(Messages.home)),
          route: '/',
        },
        {
          name: capitalize(intl.formatMessage(Messages.Campaigns)),
          route: '/campaigns',
        },
        {
          name: capitalize(intl.formatMessage(Messages.MyCreators)),
          route: '/my-creators',
        },
      ]
    : [];

  const menusForCreator = isCreator
    ? [
        {
          name: capitalize(intl.formatMessage(Messages.home)),
          route: '/',
        },
        {
          name: capitalize(intl.formatMessage(Messages.Jobs)),
          route: '/jobs',
        },
      ]
    : [];

  const menus = [
    ...menusForBrand,
    ...menusForCreator,
    // {
    //   name: capitalize(intl.formatMessage(Messages.resources)),
    //   route: '/',
    //   newFeature: true,
    // },
    // {
    //   name: capitalize(intl.formatMessage(Messages.contacts)),
    //   route: '/',
    //   newFeature: true,
    // },
  ];
  return menus.slice(0, isMobileView ? 0 : 10000);
}
