import { Box, Typography, Divider } from '@mui/material';
import GoBack from './GoBack';
import { useIntl } from 'react-intl';
import { UserAccountMessages } from './Messages';

export default function TermsOfService() {
  const intl = useIntl();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
      }}
    >
      <GoBack />
      <Typography sx={{ mt: 2, mb: 1, fontSize: '1.5rem' }}>
        {intl.formatMessage(UserAccountMessages.BenaTermsOfService)}
      </Typography>
      <Divider sx={{ width: '100%' }} />
      <Box
        sx={{
          p: 1,
          pl: 0,
          mt: 2,
          flexGrow: 1,
        }}
      >
        <h3 className="block__title">(Last updated On March 30, 2024)</h3>
        <div />

        <div />
        <h3 className="block__title">1. Acceptance of Terms</h3>
        <p className="block__text">{`This website is operated by Bena. Throughout the site, the terms “we”, “us” and “our” refer to
	Bena. Bena offers this website, including all information, tools and services available from this site to you, the
	user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.`}</p>
        <p className="block__text">{`By visiting our site (Site) and/ or signing into our application, you engage in our “Service”
	and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional
	terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all
	users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or
	contributors of content.`}</p>
        <p className="block__text">{`YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE SITE OR SERVICES, YOU ARE INDICATING
	THAT YOU HAVE READ, AND THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS.`}</p>
        <p className="block__text">{``}</p>

        <div />
        <h3 className="block__title">2. Modification of Terms</h3>
        <p className="block__text">{`We reserve the right to update, change or replace any part of these Terms of Service by posting
	updates and/or changes to our website. If we modify these Terms, we will post the modification on the Site. We will
	also update the "Last Updated Date" at the top of these Terms. Modifications to these Terms will automatically take
	effect upon posting. It is your responsibility to check this page periodically for changes. Your continued use of or
	access to the website following the posting of any changes constitutes acceptance of those changes.`}</p>

        <div />
        <h3 className="block__title">3. Eligibility</h3>
        <p className="block__text">{`By agreeing to these Terms of Service, you represent that you are at least the age of majority
	in your state or province of residence, or that you are the age of majority in your country, state or province of
	residence and you have given us your consent to allow any of your minor dependents to use this site. By accessing or
	using the Site you represent and warrant that you are not legally prohibited from accessing the Site or using the
	Services under the laws of the country in which you access or use the Site.`}</p>

        <div />
        <h3 className="block__title">4. About the Site</h3>
        <p className="block__text">{`The Site allows a User to access information and the Services and provides opportunities for a
	User to request information, apply for an account, signup for notifications, apply to a project, or engage with Bena
	through provided communication options. You agree to provide accurate, current and complete information in all
	communications with Bena and in all other use of the Site or Services.`}</p>
        <p className="block__text">{`THE SITE AND SERVICES ARE INTENDED TO BE USED TO FACILITATE BRANDS AND CREATORS TO CONNECT AND
	ENTER INTO AGREEMENTS WITH EACH OTHER. BENA CANNOT AND DOES NOT CONTROL THE CONTENT POSTED BY USERS AND IS NOT
	RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY RELATED TO ANY AND ALL SUCH USER CONTENT, INCLUDING COMPLIANCE
	WITH APPLICABLE LAWS, RULES, AND GUIDES (SUCH AS FTC GUIDES CONCERNING SPONSORED ENDORSEMENTS).`}</p>

        <div />
        <h3 className="block__title">5. User Affirmations, Conduct and Use</h3>
        <p className="block__text">{` - You are solely responsible for compliance with any and all laws, rules, regulations, or
	obligations that may apply to your use of the Site.`}</p>
        <p className="block__text">{` - Nothing that you upload, publish, represent, warrant or transmit using the Site, will
	infringe, misappropriate or violate a third party's patent, copyright, trademark, trade secret, moral rights or other
	proprietary or intellectual property rights, or rights of publicity or privacy, or result in the violation of any
	applicable law or regulation.`}</p>
        <p className="block__text">{` - You will not use manual or automated software, devices, scripts, redirects, robots, other
	means or processes to access, "scrape", "crawl" or "spider" any web pages or other services contained in the Services.`}</p>
        <p className="block__text">{` - You will not use the Site for any commercial or other purposes that are not expressly
	permitted by these Terms.`}</p>
        <p className="block__text">{` - You will not copy, store or otherwise access any information contained on the Site for
	purposes not expressly permitted by these Terms.`}</p>
        <p className="block__text">{` - You will not interfere with or damage the Site, including, without limitation, through the
	use of viruses, cancel bots, Trojan horses, harmful code, flood pings, denial-of-service attacks, packet or IP
	spoofing, forged routing or electronic mail address information or similar methods or technology.`}</p>
        <p className="block__text">{` - You will not impersonate any person or entity or falsify or otherwise misrepresent yourself
	or your affiliation with any person or entity.`}</p>
        <p className="block__text">{` - You will not systematically retrieve data or other content from our Services to create or
	compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory or
	the like, whether by manual methods, through the use of bots, crawlers, or spiders, or otherwise.`}</p>
        <p className="block__text">{` - You will not access, tamper with, or use non-public areas of the Site, Bena's computer
	systems, or any third-party provider system.`}</p>
        <p className="block__text">{` - You will not attempt to probe, scan, or test the vulnerability of any Bena system or network
	or breach any security or authentication measures.`}</p>
        <p className="block__text">{` - You will not avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent
	any technological measure implemented by Bena or any of Bena's providers or any other third party (including other
	Users) to protect the Site.`}</p>
        <p className="block__text">{` - You will not advocate, encourage, or assist any third party in doing any of the foregoing.`}</p>
        <p className="block__text">{` - You will not reproduce, duplicate, copy, sell, resell or exploit any portion of the Service,
	use of the Service, or access to the Service or any contact on the website through which the service is provided,
	without express written permission by us.`}</p>
        <p className="block__text">{`Bena will have the right to investigate and prosecute violations of any of the above to the
	fullest extent of the law. Bena may involve and cooperate with law enforcement authorities in prosecuting Users who
	violate these Terms.`}</p>
        <p className="block__text">{`You acknowledge that Bena has no obligation to monitor your access to or use of the Site, but
	has the right to do so for the purpose of operating the Site, to ensure your compliance with these Terms, or to comply
	with applicable law or the order or requirement of a court, administrative agency or another governmental body.`}</p>
        <p className="block__text">{`A breach or violation of any of the Terms will result in an immediate termination of your
	Services.`}</p>

        <div />
        <h3 className="block__title">
          6. Accuracy, Completeness And Timeliness Of Information
        </h3>
        <p className="block__text">{`We are not responsible if information made available on this site is not accurate, complete or
	current. The material on this site is provided for general information only and should not be relied upon or used as
	the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of
	information. Any reliance on the material on this site is at your own risk.`}</p>
        <p className="block__text">{`This site may contain certain historical information. Historical information, necessarily, is
	not current and is provided for your reference only. We reserve the right to modify the contents of this site at any
	time, but we have no obligation to update any information on our site. You agree that it is your responsibility to
	monitor changes to our site.`}</p>

        <div />
        <h3 className="block__title">
          7. Business Continuity and Disaster Recovery
        </h3>
        <p className="block__text">{`Bena is committed to providing continuous and uninterrupted service to all its customers. Bena
	has the capability to operate its critical business functions during emergency events. We have established a disaster
	recovery and business continuity plans. We consistently backup customers data. All backups are encrypted and retained
	for 60 days.`}</p>
        <p className="block__text">{`Our Disaster Recovery Plan is tested at least twice a year to assess its effectiveness and to
	keep the teams aligned with their responsibilities in case of a service interruption.`}</p>

        <div />
        <h3 className="block__title">8. Optional Tools</h3>
        <p className="block__text">{`We may provide you with access to third-party tools over which we neither monitor nor have any
	control nor input.`}</p>
        <p className="block__text">{`You acknowledge and agree that we provide access to such tools ”as is” and “as available”
	without any warranties, representations or conditions of any kind and without any endorsement. We shall have no
	liability whatsoever arising from or relating to your use of optional third-party tools.`}</p>
        <p className="block__text">{`Any use by you of optional tools offered through the site is entirely at your own risk and
	discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by
	the relevant third-party provider(s).`}</p>
        <p className="block__text">{`We may also, in the future, offer new services and/or features through the website (including,
	the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of
	Service.`}</p>
        <p className="block__text">{`Bena may enable you to link your User Account with a valid account on a third party social
	networking, email or content service such as TikTok, Facebook, Instagram, YouTube, or Twitter, (such service, a
	“Third-Party Service” and each such account, a “Third-Party Account”) by allowing Bena to access your Third-Party
	Account, as is permitted under the applicable terms and conditions that govern your use of each Third-Party Account.`}</p>
        <p className="block__text">{`You represent that you are entitled to disclose your Third-Party Account login information to
	Bena and/or grant Bena access to your Third-Party Account (including, but not limited to, for use for the purposes
	described herein) without breach by you of any of the terms and conditions that govern your use of the applicable
	Third-Party Account and without obligating Bena to pay any fees or making Bena subject to any usage limitations
	imposed by such third-party service providers.`}</p>

        <div />
        <h3 className="block__title">9. Third-Party Links</h3>
        <p className="block__text">{`Certain content, products and services available via our Service may include materials from
	third-parties. `}</p>
        <p className="block__text">{`Third-party links on this site may direct you to third-party websites that are not affiliated
	with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not
	have any liability or responsibility for any third-party materials or websites, or for any other materials, products,
	or services of third-parties.`}</p>
        <p className="block__text">{`We are not liable for any harm or damages related to the purchase or use of goods, services,
	resources, content, or any other transactions made in connection with any third-party websites. Please review
	carefully the third-party’s policies and practices and make sure you understand them before you engage in any
	transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the
	third-party.`}</p>

        <div />
        <h3 className="block__title">
          10. User Comments, Feedback And Other Submission
        </h3>
        <p className="block__text">{`If, at our request, you send certain specific submissions (for example contest entries) or
	without a request from us, you send creative ideas, suggestions, proposals, plans, or other materials, whether online,
	by email, by postal mail, or otherwise (collectively, ‘comments’), you agree that we may, at any time, without
	restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward
	to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for
	any comments; or (3) to respond to any comments.`}</p>
        <p className="block__text">{`We may, but have no obligation to, monitor, edit or remove content that we determine in our sole
	discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise
	objectionable or violates any party’s intellectual property or these Terms of Service.`}</p>
        <p className="block__text">{`You agree that your comments will not violate any right of any third-party, including copyright,
	trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not
	contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware
	that could in any way affect the operation of the Service or any related website. You may not use a false e-mail
	address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any
	comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and
	assume no liability for any comments posted by you or any third-party.`}</p>

        <div />
        <h3 className="block__title">11. Personal Information</h3>
        <p className="block__text">
          {`Your submission of personal information through our forms or our application is governed by our
	Privacy Policy. To view our Privacy Policy, please go to: `}
          <a href="/privacy_h">Bena Privacy Policy</a>
        </p>

        <div />
        <h3 className="block__title">
          12. Disclaimer Of Warranties; Limitation Of Liability
        </h3>
        <p className="block__text">{`We do not guarantee, represent or warrant that your use of our service will be uninterrupted,
	timely, secure or error-free.`}</p>
        <p className="block__text">{`We do not warrant that the results that may be obtained from the use of the service will be
	accurate or reliable.`}</p>
        <p className="block__text">{`You agree that from time to time we may remove the service for indefinite periods of time or
	cancel the service at any time, without notice to you.`}</p>
        <p className="block__text">{`You expressly agree that your use of, or inability to use, the service is at your sole risk. The
	service and all products and services delivered to you through the service are (except as expressly stated by us)
	provided ‘as is’ and ‘as available’ for your use, without any representation, warranties or conditions of any kind,
	either express or implied, including all implied warranties or conditions of merchantability, merchantable quality,
	fitness for a particular purpose, durability, title, and non-infringement.`}</p>
        <p className="block__text">{`In no case shall Bena, our directors, officers, employees, affiliates, agents, contractors,
	interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect,
	incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost
	revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort
	(including negligence), strict liability or otherwise, arising from your use of any of the service or any products
	procured using the service, or for any other claim related in any way to your use of the service or any product,
	including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a
	result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the
	service, even if advised of their possibility.`}</p>
        <p className="block__text">{`Because some states or jurisdictions do not allow the exclusion or the limitation of liability
	for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the
	maximum extent permitted by law.`}</p>

        <div />
        <h3 className="block__title">13. Severability</h3>
        <p className="block__text">{`In the event that any provision of these Terms of Service is determined to be unlawful, void or
	unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and
	the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not
	affect the validity and enforceability of any other remaining provisions.`}</p>

        <div />
        <h3 className="block__title">14. Indemnification</h3>
        <p className="block__text">{`You agree to indemnify, defend and hold harmless Bena and our parent, subsidiaries, affiliates,
	partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns
	and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to
	or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your
	violation of any law or the rights of a third-party.`}</p>

        <div />
        <h3 className="block__title">15. Termination</h3>
        <p className="block__text">{`The obligations and liabilities of the parties incurred prior to the termination date shall
	survive the termination of this agreement for all purposes. `}</p>
        <p className="block__text">{`These Terms of Service are effective unless and until terminated by either you or us. You may
	terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you
	cease using our site.`}</p>
        <p className="block__text">{`If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or
	provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will
	remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access
	to our Services (or any part thereof).`}</p>
        <p className="block__text">{`We may, in our discretion and without liability to you, with or without cause, with or without
	prior notice, and at any time terminate these Terms or your access to our Site.`}</p>

        <div />
        <h3 className="block__title">16. Entire Agreement</h3>
        <p className="block__text">{`The failure of us to exercise or enforce any right or provision of these Terms of Service shall
	not constitute a waiver of such right or provision.`}</p>
        <p className="block__text">{`These Terms of Service and any policies or operating rules posted by us on this site or in
	respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of
	the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or
	written, between you and us (including, but not limited to, any prior versions of the Terms of Service). `}</p>
        <p className="block__text">{`Any ambiguities in the interpretation of these Terms of Service shall not be construed against
	the drafting party.`}</p>

        <div />
        <h3 className="block__title">17. Governing Law</h3>
        <p className="block__text">{`These Terms of Service and any separate agreements whereby we provide you Services shall be
	governed by and construed in accordance with the laws of the State of Washington, USA.`}</p>

        <div />
        <div />
        <p className="block__text">{`Disclaimer: The original, legally binding version of this document is written in English. If it
	is translated into other languages by non-native English-speakers or by software, there may be discrepancies between
	the English version and the translated version. If so, the English version supersedes the translated version.`}</p>
        <div />
      </Box>
      <Box sx={{ minHeight: '35%', flexGrow: 1 }}></Box>
    </Box>
  );
}
