/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { degubLogToDocument } from './debug';
import { useApiErrorHandler } from '../redux/responseErrorHandler';

const calculateMaxPages = (total: number, limit: number) => {
  return Math.ceil(total / limit);
};

export interface IListQueryResponse {
  data: {
    items: any[];
    total: number;
    page: number;
    size: number;
  };
}

const useInfiniteScroll = <T>(
  useGetDataListQuery: UseQuery<any>,
  { limit = 10, ...queryParameters },
  dedupFn?: (a: T, arr: T[]) => boolean
) => {
  const [localPage, setLocalPage] = useState(1);
  const [combinedData, setCombinedData] = useState<T[]>([]);
  const queryResponse = useGetDataListQuery({
    page: localPage,
    limit,
    ...queryParameters,
  });
  const {
    items: fetchData = [],
    page: remotePage = 1,
    total: remoteTotal = 0,
    size: remoteSize = 10,
  } = (queryResponse?.data as IListQueryResponse)?.data || {};
  const fetchDataPamar = JSON.parse(JSON.stringify(fetchData)) as T[];
  
  const error = queryResponse?.error;
  useApiErrorHandler(error);

  useEffect(() => {
    if (queryResponse.isSuccess) {
      if (localPage === 1) setCombinedData(fetchDataPamar);
      else if (localPage === remotePage) {
        // TODO remove me. debug whether the data from backend is duplicated in pagination
        const ids = (combinedData as CreatorData[]).map((v) => v.id);
        const idsNew = (fetchDataPamar as CreatorData[]).map((v) => v.id);
        const debugInfo = `#page=${localPage}#duplicated ids=${JSON.stringify(
          idsNew.filter((v) => ids.includes(v))
        )},existdatalen=${ids.length},newdatalen=${idsNew.length};`;
        degubLogToDocument(debugInfo);

        setCombinedData((previousData) => [
          ...previousData,
          ...(dedupFn
            ? fetchDataPamar.filter((item) => !dedupFn(item, previousData))
            : fetchDataPamar),
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const maxPages = useMemo<number>(() => {
    return calculateMaxPages(remoteTotal, remoteSize);
  }, [remoteTotal, remoteSize]);

  const hasMore = localPage < maxPages;

  const refresh = useCallback(() => {
    setLocalPage(1);
  }, []);

  const readMore = useCallback(() => {
    if (hasMore && localPage === remotePage) {
      setLocalPage((page) => page + 1);
    }
  }, [hasMore, localPage, remotePage]);

  return {
    combinedData,
    setCombinedData,
    localPage,
    remoteTotal,
    hasMore,
    readMore,
    refresh,
    isLoading: queryResponse?.isLoading,
    isFetching: queryResponse?.isFetching,
    error,
  };
};

export default useInfiniteScroll;
