import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Messages } from '../../localization/Messages';
import { useTypedSelector } from '../../redux/store';
import { selectAuth, setThemeMode } from '../account/AuthSlice';
import { ArrowRightIcon, ThemeIcon } from '../../assets/imgs/icons';

export default function ThemeModeMenuItem() {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const themeMode = useTypedSelector((state) => selectAuth(state)).themeMode;

  const handleClick = () => {
    const mode =
      themeMode === 'dark'
        ? 'default'
        : themeMode === 'light'
        ? 'dark'
        : 'light';
    dispatch(setThemeMode(mode));
  };
  const lightMode = themeMode === 'light';
  const darkMode = themeMode === 'dark';
  let followOS = themeMode === 'default';
  // in case there are no themeMode assigned. it happens during the local test
  if (!lightMode && !darkMode && !followOS) {
    followOS = true;
  }
  const title = intl.formatMessage(Messages.ClickToSwitchTheme);
  const arrow = (
    <ArrowRightIcon
      stroke={theme.palette.grey.black_white}
      sx={{ width: '1rem', height: '1rem' }}
    />
  );
  const size = { width: '1.25rem', height: '1.25rem' };
  return (
    <MenuItem onClick={handleClick} title={title}>
      <ListItemIcon>
        <ThemeIcon stroke={theme.palette.grey.black_white} sx={size} />
      </ListItemIcon>
      <ListItemText>
        {lightMode && (
          <Stack component="span" alignItems={'center'} direction="row">
            {intl.formatMessage(Messages.White)}&nbsp;&nbsp;
            {arrow}
            &nbsp;&nbsp;
            <Typography color={theme.palette.grey[25]}>
              {intl.formatMessage(Messages.Dark)}
            </Typography>
            &nbsp;&nbsp;
          </Stack>
        )}
        {darkMode && (
          <Stack component="span" alignItems={'center'} direction="row">
            {intl.formatMessage(Messages.Dark)}&nbsp;&nbsp;{arrow} &nbsp;&nbsp;
            <Typography color={theme.palette.grey[25]}>
              {intl.formatMessage(Messages.Default)}&nbsp;&nbsp;
            </Typography>
          </Stack>
        )}
        {followOS && (
          <Stack component="span" alignItems={'center'} direction="row">
            {intl.formatMessage(Messages.Default)}&nbsp;&nbsp;
            {arrow}
            &nbsp;&nbsp;
            <Typography color={theme.palette.grey[25]}>
              {intl.formatMessage(Messages.White)}&nbsp;&nbsp;
            </Typography>
          </Stack>
        )}
      </ListItemText>
    </MenuItem>
  );
}
