import { Theme } from '@mui/material';
import {
  FacebookIcon,
  GoogleIcon,
  InstagramIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../assets/imgs/icons';

export const getPlatformSvgIcon = (platform: SocialPlatformId) => {
  if (platform === 'tiktok') return TiktokIcon;
  if (platform === 'instagram') return InstagramIcon;
  if (platform === 'twitter') return TwitterIcon;
  if (platform === 'facebook') return FacebookIcon;
  if (platform === 'youtube') return YoutubeIcon;
  if (platform === 'google') return GoogleIcon;
  return () => {
    return <></>;
  };
};

export function getSelectableSvgIcon(
  theme: Theme,
  name: SocialPlatformId,
  selected = false,
  width = '2.5rem'
) {
  const svg = getPlatformSvgIcon(name);
  return svg ? (
    selected ? (
      svg({
        fill: theme.palette.primary.main,
        mask: theme.palette.base.white,
        sx: { width: width || '2.5rem', height: width || '2.5rem' },
      })
    ) : (
      svg({
        fill: theme.palette.grey['200'],
        mask: theme.palette.base.white,
        sx: { width: width || '2.5rem', height: width || '2.5rem' },
      })
    )
  ) : (
    <></>
  );
}

export const SocialPlatforms = [
  { component: TiktokIcon, id: 'tiktok' },
  // { component: TwitterIcon, id: 'twitter' },
  // { component: YoutubeIcon, id: 'youtube' },
  // { component: InstagramIcon, id: 'instagram' },
  // { component: FacebookIcon, id: 'facebook' },
  // { component: GoogleIcon, id: 'google' },
];
