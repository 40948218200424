import { Box, InputBase, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import BenaCheckbox from '../components/BenaCheckbox';
import { Messages } from '../localization/Messages';
import { useCallback, useMemo, useState } from 'react';
import { useGlobalStyles } from '../assets/styles/style';

type ListInfoProps = {
  name: string;
  checkState?: ListCheckItemType;
  onChange: fnVoidToVoid;
};

function ListInfo({ name, checkState, onChange }: ListInfoProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        height: '2rem',
        ':hover': { bgcolor: theme.custom.primary5 },
      }}
      className="border-radius-8px boxlefthcenterv"
      onClick={onChange}
    >
      {checkState && (
        <BenaCheckbox
          checked={checkState.checked}
          onChange={onChange}
          name={`${checkState.name}${Math.random().toString(36).slice(2, 10)}`}
        />
      )}
      <Typography fontSize={'12px'} fontWeight={500} color="base.black">
        {name}
      </Typography>
      <Box sx={{ flexGrow: 1 }}></Box>
    </Box>
  );
}

type ListCheckItemType = { id: string; name: string; checked: boolean };
type ChecklistSelectProps = {
  data: ListCheckItemType[];
  onChange: (value: ListCheckItemType) => void;
  sx?: { pl?: number };
};

export default function ChecklistSelect({
  data,
  onChange,
  sx = {},
}: ChecklistSelectProps) {
  const theme = useTheme();
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');
  const filteredListData = useMemo(
    () =>
      data.filter((item) =>
        searchText ? item.name.toLowerCase().includes(searchText) : true
      ),
    [data, searchText]
  );
  const selectedItemsInfo = useCallback(() => {
    return data
      .filter((item) => item.checked)
      .map((item) => item.name)
      .join(',');
  }, [data]);

  const renderList = useCallback(() => {
    return filteredListData.map((item, keyIndex) => {
      return (
        <Box sx={{ mb: 0.5 }} key={`${keyIndex}-${item.name}`}>
          <ListInfo
            name={item.name}
            checkState={item}
            onChange={() => onChange(item)}
          />
        </Box>
      );
    });
  }, [filteredListData, onChange]);

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: theme.palette.base.white,
      }}
      className="border-radius-8px-top"
    >
      <Box
        sx={{
          borderBottom: `0.0625rem solid ${theme.custom.primary4}`,
          height: '2.5rem',
        }}
        className="boxcenterhv"
      >
        <InputBase
          placeholder={intl.formatMessage(Messages.Search)}
          value={searchText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
          }}
          sx={{
            width: '100%',
            fontSize: '0.75rem !important',
            border: 0,
            pr: 1,
            ...sx,
            '&>input': {
              fontSize: '0.75rem',
              color: theme.custom.black,
            },
          }}
          title={selectedItemsInfo()}
        />
      </Box>

      <Box
        sx={{
          overflowY: filteredListData.length > 3 ? 'scroll' : '',
          maxHeight: '13.5rem',
          minHeight: '13.5rem',
          ...sx,
        }}
      >
        <Box>{renderList()}</Box>
      </Box>
    </Box>
  );
}
