import {useIntl} from "react-intl";
import {
  Stack,
  Typography,
  Box,
  Container
} from "@mui/material"
import {Messages} from "../localization/Messages";
import { useBreakpointXl, useBreakpointXs } from "../utils/useStyleHooks";
import { useIsRoleBrand } from "../hooks/account";

import MyCreatorsList from "../components/myCreators/myCreatorsList";

export default function CreatorsPage() {
  const intl = useIntl();
  const sizeXl = useBreakpointXl();
  const isMobileView = useBreakpointXs();
  const isBrand = useIsRoleBrand();
  return (
    <Box>
      <Container maxWidth="xl" sx={{ mt: { xs: 2, md: 4 } }}>
        <Stack
          spacing="2.5rem"
          sx={{ padding: isMobileView ? '0' : sizeXl ? '1rem' : '0' }}
        >
          <Stack>
            <Typography fontWeight={500} fontSize="1.5rem">
              {intl.formatMessage(Messages.MyCreators)}
            </Typography>
          </Stack>
        </Stack>

        {isBrand && <MyCreatorsList /> }

        <Box height="10rem"></Box>
      </Container>
    </Box>
  );
}