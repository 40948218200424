import { Box } from '@mui/material';
import STabs from '../../components/STabs';
import { useIntl } from 'react-intl';
import { useState, useEffect, ReactElement } from 'react';
import {
  useLazyGetCampaignApplicationProposalsDeclinedQuery,
  useLazyGetCampaignApplicationsAndInvitationsQuery,
  useLazyGetCampaignJobContractsQuery,
  useLazyGetCampaignQuery,
} from '../../redux/benaApi';
import { LazyQueryResultHandler } from '../../redux/benaApiResultHandler';
import CampaignProgressTableView from './CampaignProgressTableView';
import CampaignIncomingProposalsTableView from './CampaignIncomingProposalsTableView';
import {
  replaceURLSearchParam,
  useStabsFindTabIndexByQueryStringTab,
} from '../../utils/common';
import { Messages } from '../../localization/Messages';
import { staticURLCampaignsPage } from '../../route/urls';
import { useNavigate } from 'react-router-dom';

const APIQUERYPAGESIZE = 10;

type CampaignSOPProps = {
  campaignId: string;
  campaignDetails: ReactElement;
};
export default function CampaignSOP({
  campaignId,
  campaignDetails,
}: CampaignSOPProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [campaignData, setCampaignData] = useState<
    CampaignResponseType | undefined
  >(undefined);
  const [getCampaign, result] = useLazyGetCampaignQuery();
  useEffect(() => {
    LazyQueryResultHandler<CampaignResponseType>({
      intl,
      result: result as QueryResultType<CampaignResponseType>,
      onSuccess: (data) => {
        setCampaignData(data);
      },
    });
  }, [result, campaignId, intl]);

  useEffect(() => {
    if (campaignId) {
      // reset data before switch campaign
      setCampaignData(undefined);
      setProgressCreators([]);
      setCampaignApplicatons([]);
      setCampaignApplicatonsDeclined([]);
      getCampaign({ id: campaignId });
      queryCampaignApplicationsPage(1);
      queryJobContractsPage(1);
      queryCampaignApplicationsDeclinedPage(1);
    }
  }, [campaignId, getCampaign]);

  // campaign progress
  const [progressCreators, setProgressCreators] = useState<
    CampaignJobContractItemType[]
  >([]);
  const [progressCreatorsTotal, setProgressCreatorsTotal] = useState(0);
  const [queryJobContracts, queryJobContractsResult] =
    useLazyGetCampaignJobContractsQuery({});
  const queryJobContractsPage = (
    page: number,
    size = APIQUERYPAGESIZE,
    qs = ''
  ) => {
    queryJobContracts({
      page,
      size,
      campaign_id: campaignId,
      filters: qs,
    });
  };
  useEffect(() => {
    LazyQueryResultHandler<GetCampaignJobContractsReponseType>({
      intl,
      result:
        queryJobContractsResult as QueryResultType<GetCampaignJobContractsReponseType>,
      onSuccess: (data) => {
        setProgressCreators([...data.items]);
        setProgressCreatorsTotal(data.total);
      },
    });
  }, [queryJobContractsResult]);

  const progressLoading =
    queryJobContractsResult.isFetching || queryJobContractsResult.isLoading;

  // campaign applications
  const [campaignApplicatons, setCampaignApplicatons] = useState<
    CampaignApplicationItemType[]
  >([]);
  const [campaignApplicatonsTotal, setCampaignApplicatonsTotal] = useState(0);
  const [queryCampaignApplicatioins, queryCampaignApplicatioinsResult] =
    useLazyGetCampaignApplicationsAndInvitationsQuery({});
  const queryCampaignApplicationsPage = (
    page: number,
    size = APIQUERYPAGESIZE,
    qs = ''
  ) => {
    queryCampaignApplicatioins({
      page,
      size,
      campaign_id: campaignId,
      filters: qs,
    });
  };
  useEffect(() => {
    LazyQueryResultHandler<GetCampaignApplicationsReponseType>({
      intl,
      result:
        queryCampaignApplicatioinsResult as QueryResultType<GetCampaignApplicationsReponseType>,
      onSuccess: (data) => {
        setCampaignApplicatons([...data.items]);
        setCampaignApplicatonsTotal(data.total);
      },
      onException: () => {
        setCampaignApplicatons([]);
        setCampaignApplicatonsTotal(0);
      },
    });
  }, [queryCampaignApplicatioinsResult]);

  const campaignApplicationsLoading =
    queryCampaignApplicatioinsResult.isFetching ||
    queryCampaignApplicatioinsResult.isLoading;

  // declined campaign applications
  const [queryDeclinedProposals, queryDeclinedProposalsResult] =
    useLazyGetCampaignApplicationProposalsDeclinedQuery();
  const [campaignApplicatonsDeclined, setCampaignApplicatonsDeclined] =
    useState<CampaignApplicationItemType[]>([]);

  const [
    campaignApplicatonsDeclinedTotal,
    setCampaignApplicatonsDeclinedTotal,
  ] = useState(0);
  const queryCampaignApplicationsDeclinedPage = (
    page: number,
    size = APIQUERYPAGESIZE,
    qs = ''
  ) => {
    queryDeclinedProposals({
      page,
      size,
      campaign_id: campaignId,
      status: 'rejected',
    });
  };
  useEffect(() => {
    LazyQueryResultHandler<GetCampaignApplicationsReponseType>({
      intl,
      result:
        queryDeclinedProposalsResult as QueryResultType<GetCampaignApplicationsReponseType>,
      onSuccess: (data) => {
        setCampaignApplicatonsDeclined([...data.items]);
        setCampaignApplicatonsDeclinedTotal(data.total);
      },
      onException: () => {
        setCampaignApplicatonsDeclined([]);
        setCampaignApplicatonsDeclinedTotal(0);
      },
    });
  }, [queryDeclinedProposalsResult]);

  const campaignApplicationsDeclinedLoading =
    queryDeclinedProposalsResult.isFetching ||
    queryDeclinedProposalsResult.isLoading;

  // add auto navigate to tabs
  const tabsTitleName = ['hired', 'proposals', 'campaign', 'declined'];
  const tabIndexFromURL = useStabsFindTabIndexByQueryStringTab(tabsTitleName);
  useEffect(() => {
    tabIndexFromURL >= 0 && setSelectedTabIndex(tabIndexFromURL);
  }, [tabIndexFromURL]);

  return (
    <Box sx={{ width: '100%' }}>
      <STabs
        tabs={[
          {
            name: `${intl.formatMessage(Messages.Hired)} (${
              progressLoading ? '...' : `${progressCreatorsTotal}`
            })`,
            children: (
              <CampaignProgressTableView
                data={progressCreators}
                total={progressCreatorsTotal}
                loadPage={(page, size, queryParams) => {
                  queryJobContractsPage(page, size, queryParams);
                }}
                loading={progressLoading}
                isActiveCampaign={campaignData?.status !== 'active'}
              />
            ),
          },
          {
            name: `${intl.formatMessage(Messages.Proposals)} (${
              campaignApplicationsLoading ? '...' : campaignApplicatonsTotal
            })`,
            children: (
              <CampaignIncomingProposalsTableView
                data={campaignApplicatons}
                total={campaignApplicatonsTotal}
                loadPage={(page, size, queryParams) => {
                  queryCampaignApplicationsPage(page, size, queryParams);
                }}
                loading={campaignApplicationsLoading}
                campaignId={campaignId}
                isActiveCampaign={campaignData?.status === 'active'}
              />
            ),
          },
          {
            name: `${intl.formatMessage(Messages.Campaign)}`,
            children: campaignDetails,
          },
          {
            name: `${intl.formatMessage(Messages.DeclinedProposals)} (${
              campaignApplicationsDeclinedLoading
                ? '...'
                : campaignApplicatonsDeclinedTotal
            })`,
            children: (
              <CampaignIncomingProposalsTableView
                data={campaignApplicatonsDeclined}
                total={campaignApplicatonsDeclinedTotal}
                loadPage={(page, size, queryParams) => {
                  queryCampaignApplicationsDeclinedPage(
                    page,
                    size,
                    queryParams
                  );
                }}
                loading={campaignApplicationsDeclinedLoading}
                campaignId={campaignId}
                isActiveCampaign={true}
              />
            ),
          },
        ]}
        selectedIndex={selectedTabIndex}
        onTabChange={(value) => {
          if (selectedTabIndex !== value) {
            setSelectedTabIndex(value);
            navigate(
              `${staticURLCampaignsPage}${replaceURLSearchParam(
                window.location.search,
                'tab',
                tabsTitleName[value]
              )}`
            );
          }
        }}
        tabTitleFontSize="0.875rem"
        spaceBetween={false}
      />
    </Box>
  );
}
