import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { staticURLLogin, staticURLRoot } from '../route/urls';
import { useSearchParams } from 'react-router-dom';

const ProtectedRoute = (props: RouteProps) => {
  const [searchParams] = useSearchParams();
  const auth = useSelector((state: RootState) => state.auth);

  let component: React.ReactNode = props.element;
  const redirect = `?redirect=${encodeURIComponent(
    `${window.location.pathname}?${searchParams}` || '/'
  )}`;

  if (auth.user) {
    if (props.path === staticURLLogin) {
      component = <Navigate to={staticURLRoot} />;
    }
  } else if (!auth.user) {
    component = <Navigate to={`${staticURLLogin}${redirect}`} />;
  }

  return <>{component}</>;
};

export default ProtectedRoute;
