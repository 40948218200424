import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { UserAccountMessages } from './Messages';
import { useNavigate } from 'react-router-dom';
import { spaceKeyPressed } from '../../utils/common';
import { staticURLLogin } from '../../route/urls';
import LogoAndLanguage from './LogoAndLanguage';
import { envResolverAPIURL } from '../../env';

type JoinUsProps = {
  registerCreator: fnVoidToVoid;
  registerBrand: fnVoidToVoid;
};

export default function JoinUs({
  registerCreator,
  registerBrand,
}: JoinUsProps) {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();

  const responsiveValues = {
    [theme.breakpoints.down('md')]: {
      width: '341px',
      height: '241px',
    },
    [theme.breakpoints.up('md')]: {
      width: '204px',
      height: '176px',
    },
  };
  return (
    <Box>
      <LogoAndLanguage />
      <Box sx={{ mt: 6.75 }}>
        <Typography sx={{ fontSize: '1.5rem' }} fontWeight="bold">
          {intl.formatMessage(UserAccountMessages.joinUs)}
        </Typography>
        <Box
          className={'boxcenterhv'}
          sx={{ justifyContent: 'flex-start', mt: '0.5rem' }}
        >
          <Typography variant="subtitle1">
            {intl.formatMessage(UserAccountMessages.alreadyHaveAnAccount)}
          </Typography>
          <Typography
            color={theme.palette.primary.main}
            variant="subtitle1"
            sx={{ ml: 1, cursor: 'pointer' }}
            onClick={() => navigate(staticURLLogin)}
          >
            {intl.formatMessage(UserAccountMessages.SignIn)}
          </Typography>
        </Box>
        <Box
          className="boxcenterhv"
          sx={{
            justifyContent: 'space-between',
            mt: '2rem',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
            },
          }}
        >
          <Box
            className={`border-radius-4px`}
            sx={{
              bgcolor: theme.palette.base.white,
              border: `0.0625rem solid ${theme.palette.base.white}`,
              ':hover': {
                border: `0.0625rem solid ${theme.palette.primary.main}`,
                bgcolor: theme.custom.primaryMainO6,
              },
              cursor: 'pointer',
              position: 'relative',
            }}
            onClick={() => {
              registerBrand();
            }}
            tabIndex={0}
            onKeyDown={(e) => {
              spaceKeyPressed(e, () => {
                registerBrand();
              });
            }}
          >
            <Box
              sx={{
                background: `url(/imgs/account/brand.png)`,
                backgroundSize: 'cover',
                ...responsiveValues,
              }}
              className="border-radius-4px"
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: '0',
                height: '4rem',
                width: '100%',
              }}
              className="boxcenterhv"
            >
              <Typography
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontSize: '1.125rem',
                  fontWeight: 600,
                }}
              >
                {intl.formatMessage(UserAccountMessages.brand)}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              minWidth: '1.5rem',
              [theme.breakpoints.down('md')]: {
                height: '1rem',
              },
            }}
          ></Box>
          <Box
            className={`border-radius-4px boxcenterhv`}
            sx={{
              border: `0.0625rem solid ${theme.palette.base.white}`,
              ':hover': {
                border: `0.0625rem solid ${theme.palette.primary.main}`,
                bgcolor: theme.custom.primaryMainO6,
              },
              cursor: 'pointer',
              position: 'relative',
            }}
            onClick={() => registerCreator()}
            tabIndex={0}
            onKeyDown={(e) => {
              spaceKeyPressed(e, () => {
                registerCreator();
              });
            }}
          >
            <Box
              sx={{
                background: `url(/imgs/account/creator.png)`,
                backgroundSize: 'cover',
                ...responsiveValues,
              }}
              className="border-radius-4px"
            />
            <Box
              sx={{ position: 'absolute', bottom: '0', height: '4rem' }}
              className="boxcenterhv"
            >
              <Typography
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontSize: '1.125rem',
                  fontWeight: 600,
                }}
              >
                {intl.formatMessage(UserAccountMessages.creator)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
        </Box>
      </Box>
    </Box>
  );
}
