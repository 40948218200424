import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { TooltipRenderProps } from 'react-joyride';
import {
  ArrowDownThinIcon,
  CheckIcon,
  FastforwardIcon,
} from '../../assets/imgs/icons';
import { JoyrideMessages } from '../../localization/JoyrideMessagess';

export function useJoyrideBrandHomepageSteps() {
  const intl = useIntl();
  return [
    {
      target: '.step_create_new_campaign',
      title: intl.formatMessage(JoyrideMessages.ProductTour),
      content: intl.formatMessage(JoyrideMessages.HomepageStep0),
      disableBeacon: true,
    },
    {
      target: '.step_shortlists_panel',
      title: intl.formatMessage(JoyrideMessages.ProductTour),
      content: intl.formatMessage(JoyrideMessages.HomepageStep6),
      disableBeacon: true,
    },
    {
      target: '.step_email_script_check',
      title: intl.formatMessage(JoyrideMessages.ProductTour),
      content: intl.formatMessage(JoyrideMessages.HomepageStep7),
      disableBeacon: true,
    },
    {
      target: '.step_generate_script',
      title: intl.formatMessage(JoyrideMessages.ProductTour),
      content: intl.formatMessage(JoyrideMessages.HomepageStep2),
      disableBeacon: true,
    },
  ];
}

export function JoyrideTooltip({
  continuous,
  index,
  isLastStep,
  step,
  backProps,
  primaryProps,
  skipProps,
  closeProps,
  tooltipProps,
}: TooltipRenderProps) {
  const intl = useIntl();
  const theme = useTheme();
  const stepApplyCampaign = step.title === 'Apply to a Campaign';
  const stepCreatorCard = step.title === 'Look at creator’s profile';
  const stepManageCampaign = step.title === 'Manage campaigns';
  const JoyrideBrandHomepageSteps = useJoyrideBrandHomepageSteps();
  const FilteredJoyrideSteps = JoyrideBrandHomepageSteps;

  const styleNext = {
    color: theme.palette.primary['600_300'],
    fontSize: '0.875rem',
    fontWeight: 600,
    className: 'boxlefthcenterv',
  };
  return (
    <Box
      {...tooltipProps}
      sx={{
        width: stepApplyCampaign ? { xs: '16.5rem', md: '30rem' } : '20rem',
        height: stepApplyCampaign
          ? '13rem'
          : stepCreatorCard || stepManageCampaign
          ? '11rem'
          : '9rem',
        // border: `0.0625rem solid ${theme.custom.primary2}`,
        borderRadius: '0.5rem',
        bgcolor: theme.palette.base.white,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {step.title && (
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '0.875rem',
            mx: '1rem',
            my: '0.75rem',
          }}
        >
          {step.title}
        </Typography>
      )}
      {step.content && (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.8125rem',
            mx: '1rem',
            mb: '0.75rem',
            color: theme.palette.grey[700],
          }}
        >
          {step.content}
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }}></Box>
      <Box
        sx={{
          p: '0.75rem',
          borderTop: `0.0625rem solid ${theme.custom.primary4}`,
        }}
        className="boxcenterhv"
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '0.875rem',
          }}
          color={theme.palette.grey[25]}
        >
          {/* make sure each step's title is different */}
          {index + 1}/
          {FilteredJoyrideSteps.find((v) => v.title === step.title)
            ? FilteredJoyrideSteps.length
            : JoyrideBrandHomepageSteps.find((v) => v.title === step.title)
            ? JoyrideBrandHomepageSteps.length
            : 0}
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        {!isLastStep ? (
          <IconButton
            disableRipple
            {...skipProps}
            sx={{
              height: '1.375rem',
              p: '0.5rem',
              color: theme.custom.primary3,
            }}
          >
            <Typography
              className="boxlefthcenterv"
              color={theme.palette.grey[25]}
              fontSize="0.875rem"
              fontWeight={600}
            >
              {intl.formatMessage(JoyrideMessages.SkipTour)} &nbsp;
              <FastforwardIcon sx={{ width: '0.75rem', height: '0.75rem' }} />
            </Typography>
          </IconButton>
        ) : (
          <IconButton
            disableRipple
            {...primaryProps}
            sx={{
              height: '1.375rem',
              p: '0.5rem',
              color: theme.custom.primary3,
            }}
            onClick={() => {
              // IMPORTANT! pls. use the exact same name
              // for the retart tour button on Joyride page
              window.document
                .getElementById('ReactJoyrideResetButton')
                ?.click();
            }}
          >
            <Typography {...styleNext}>
              {intl.formatMessage(JoyrideMessages.RestartTour)}
            </Typography>
          </IconButton>
        )}
        {!isLastStep && (
          <IconButton
            disableRipple
            sx={{ height: '1.375rem', color: theme.palette.primary.main }}
            {...primaryProps}
          >
            <Typography {...styleNext}>
              {intl.formatMessage(JoyrideMessages.NextTip)}&nbsp;
              <ArrowDownThinIcon
                sx={{ width: '0.75rem', height: '0.75rem' }}
                className="rotate270"
                stroke={theme.palette.primary['600_300']}
              />
            </Typography>
          </IconButton>
        )}
        {isLastStep && (
          <IconButton
            disableRipple
            {...closeProps}
            sx={{ height: '1.375rem', color: theme.palette.primary.main }}
          >
            <Typography {...styleNext}>
              {intl.formatMessage(JoyrideMessages.EndTour)}&nbsp;
              <CheckIcon
                sx={{ width: '0.75rem', height: '0.75rem' }}
                stroke={theme.palette.primary['600_300']}
              />
            </Typography>
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
