import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons/faPencil';

type WritingAnimationIconProps = {
  height?: string;
};
export function WritingAnimationIcon({ height }: WritingAnimationIconProps) {
  return (
    <div className="writing-icon" style={{ height: height || 'auto' }}>
      <FontAwesomeIcon icon={faPencil} className="fa" />
    </div>
  );
}
