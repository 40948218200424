import { ArrowBackRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useGlobalStyles } from '../../assets/styles/style';
import { useIntl } from 'react-intl';
import TitledPanel from './utils';
import { CampaignMessages } from '../../localization/CampaignMessages';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { ValidateRules, dateToISOString } from '../../utils/common';
import {
  InputErrorCustomMessage,
  InputErrorMessage,
} from '../../components/InputErrorMessage';
import { useIcons } from '../../components/PlatformSelectionButton';
import FileUploader, {
  FileUploaderSingle,
} from '../../components/FileUploader';
import DateRangePicker from '../../components/DateRangePicker';
import moment from 'moment';
import PreferencesSetting from './PreferencesSetting';
import {
  usePostAIGCCampaignContentIdeaMutation,
  usePatchCampaignMutation,
  usePostCampaignMutation,
} from '../../redux/benaApi';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { staticURLCampaignsPage } from '../../route/urls';
import { showMessage } from '../../components/ShowMessage';
import { Messages } from '../../localization/Messages';
import CampaignDetailsPreview from './CampaignPreview';
import { useGetCampaignDatail } from '../hooks/campaign';
import { useBillingType } from "../../hooks/account";
import { WritingAnimationIcon } from '../../components/FAAnimation';
import { useDispatch } from 'react-redux';
import { selectUnsavedCampaigns, setUnsavedCampaign } from './CampaignSlice';
import { useTypedSelector } from '../../redux/store';
import { capitalize } from '@mui/material';
import { AddPromotedBrand, PromotedBrands } from '../features/AddPromotedBrand';
import LightTooltip from "../../components/LightTooltip";

export const NewCampaignInitData = {
  title: {
    content: '',
    maxLength: 500,
    error: false,
    inputValueType: 'anythingexceptempty',
  },
  brand_intro: {
    content: '',
    maxLength: 5000,
    error: false,
    inputValueType: 'anythingexceptempty',
    multiple: true,
  },
  cover: { file: '' } as FileUploadResponseType,
  brand_link: {
    content: '',
    maxLength: 500,
    error: false,
    inputValueType: 'url',
  },
  budget_mode: 'product_exchange_only' as BudgetModeType | string,
  budget_amount: {
    content: '0',
    maxLength: 500,
    error: false,
    inputValueType: 'number',
  },
  budget_amount_max: {
    content: '',
    maxLength: 500,
    error: false,
    inputValueType: 'number|empty',
  },
  commission: 'no' as 'yes' | 'no' | string,
  commission_percent: {
    content: '',
    maxLength: 5,
    error: false,
    inputValueType: 'fractionalNumber0To100',
  },
  campaignPeriod: {
    start_date: '',
    end_date: '',
  },
  requirements: [] as string[],
  creator_requirements: {
    followers_min: 0,
    followers_max: 0,
    average_view_min: 0,
    average_view_max: 0,
    engagement_min: 0,
    engagement_max: 0,
    audience_age_min: 0,
    audience_age_max: 0,
    audience_gender_male_min: 0,
    audience_gender_female_min: 0,
    audience_locations: [] as string[],
  },
  content_direction: {
    content: '',
    maxLength: 5000,
    error: false,
    inputValueType: 'anythingexceptempty',
  },
  captions: {
    content: '',
    maxLength: 5000,
    error: false,
    inputValueType: 'anythingexceptempty',
  },
  video_duration: {
    content: '',
    maxLength: 50,
    error: false,
    inputValueType: 'number',
  },
  hashtags: {
    content: '',
    maxLength: 500,
    error: false,
    inputValueType: 'anythingexceptempty',
  },
  tag_accounts: {
    content: '',
    maxLength: 500,
    error: false,
    inputValueType: 'anythingexceptempty',
  },
  visual_medias: [] as Array<FileUploadResponseType>,
  platforms: [] as string[],
  products: [] as NameLinkArray,
};

export function convertCampaignResponseTypeToFormInputData(
  data: CampaignResponseType,
  formData: typeof NewCampaignInitData
) {
  const newFormData = { ...JSON.parse(JSON.stringify(formData)) };
  newFormData.brand_intro.content = data.brand_intro;
  newFormData.brand_link.content = data.brand_link;
  newFormData.budget_amount.content = `${data.budget_amount || '0'}`;
  newFormData.budget_amount_max.content = `${data.budget_amount_max || ''}`;
  newFormData.budget_mode = data.budget_mode;
  newFormData.commission_percent.content = `${data.commission_percent || ''}`;
  newFormData.commission = data.commission || 'no';
  newFormData.campaignPeriod.start_date = data.start_date || '';
  newFormData.campaignPeriod.end_date = data.end_date || '';
  newFormData.captions.content = data.captions;
  newFormData.content_direction.content = data.content_direction;
  newFormData.hashtags.content = data.hashtags.join(',');
  newFormData.platforms = [...data.platforms];
  newFormData.tag_accounts.content = data.tag_accounts.join(',');
  newFormData.title.content = data.title;
  newFormData.video_duration.content = `${data.video_duration}`;
  if (newFormData.cover) {
    newFormData.cover.file = data.cover_img;
  }
  newFormData.visual_medias = data.visual_medias;
  newFormData.products = data.products;

  //update requirements
  const {
    audienceAge,
    audienceGender,
    audienceLocations,
    avgContentViews,
    creatorFollowers,
    engagement,
    hasTiktokShop,
  } = data.creator_requirements;
  const requirements = [];
  if (audienceAge?.includes('-')) {
    newFormData.creator_requirements.audience_age_min = parseInt(
      audienceAge.split('-')[0] || '0'
    );
    newFormData.creator_requirements.audience_age_max = parseInt(
      audienceAge.split('-')[1] || '0'
    );
    requirements.push(`audienceAge:${audienceAge}`);
  }

  if (avgContentViews?.includes('-')) {
    newFormData.creator_requirements.average_view_min = parseInt(
      avgContentViews.split('-')[0] || '0'
    );
    newFormData.creator_requirements.average_view_max = parseInt(
      avgContentViews.split('-')[1] || '0'
    );
    requirements.push(`avgContentViews:${avgContentViews}`);
  }

  if (creatorFollowers?.includes('-')) {
    newFormData.creator_requirements.followers_min = parseInt(
      creatorFollowers.split('-')[0] || '0'
    );
    newFormData.creator_requirements.followers_max = parseInt(
      creatorFollowers.split('-')[1] || '0'
    );
    requirements.push(`creatorFollowers:${creatorFollowers}`);
  }

  if (engagement?.includes('-')) {
    newFormData.creator_requirements.engagement_min = parseFloat(
      engagement.split('-')[0] || '0'
    );
    newFormData.creator_requirements.engagement_max = parseFloat(
      engagement.split('-')[1] || '0'
    );
    requirements.push(`engagement:${engagement}`);
  }

  newFormData.creator_requirements.audience_locations =
    audienceLocations?.split(',') || [];
  audienceLocations &&
    requirements.push(`audienceLocations:${audienceLocations}`);
  ['yes', 'no'].includes(hasTiktokShop) &&
    requirements.push(`hasTiktokShop:${hasTiktokShop}`);
  if (audienceGender?.includes('>')) {
    const [gender, percent] = audienceGender.split('>');
    if (gender === 'female') {
      newFormData.creator_requirements.audience_gender_female_min =
        parseInt(percent);
    } else if (gender === 'male') {
      newFormData.creator_requirements.audience_gender_male_min =
        parseInt(percent);
    }
    requirements.push(`audienceGender:${audienceGender}`);
  }
  newFormData.requirements = requirements;

  return newFormData;
}

export default function NewCampaign() {
  const theme = useTheme();
  const gs = useGlobalStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(NewCampaignInitData);
  const isBilling = useBillingType();

  // get auto saved campaigns
  const unsavedCampaigns = useTypedSelector((state) =>
    selectUnsavedCampaigns(state)
  );
  // save unsaved data to redux
  useEffect(() => {
    if (JSON.stringify(formData) !== JSON.stringify(NewCampaignInitData)) {
      dispatch(
        setUnsavedCampaign({
          campaignId: existingCampaign?.id || 'unsavedNewCampaign',
          campaign: { ...formData },
        })
      );
    } else {
      resetUnsavedCampaign();
    }
  }, [formData]);
  // reset to null each time it saved to backend
  const resetUnsavedCampaign = () => {
    dispatch(
      setUnsavedCampaign({
        campaignId: 'unsavedNewCampaign',
        campaign: null,
      })
    );
  };

  const refTitle = useRef<HTMLElement>(null);
  const refBrandIntro = useRef<HTMLElement>(null);
  const refBrandLink = useRef<HTMLElement>(null);
  const refBudgetAmount = useRef<HTMLElement>(null);
  const refBudgetAmountMax = useRef<HTMLElement>(null);
  const refContentDirection = useRef<HTMLElement>(null);
  const refCaptions = useRef<HTMLElement>(null);
  const refVideoDuration = useRef<HTMLElement>(null);
  const refHashtags = useRef<HTMLElement>(null);
  const refTagAccounts = useRef<HTMLElement>(null);
  const refCampaignDateRange = useRef<HTMLDivElement>(null);
  const refAffiliateCommissionAmount = useRef<HTMLDivElement>(null);

  const inputList = [
    {
      item: formData.title,
      message: CampaignMessages.WhatsYourCampaignTitle,
      name: 'title',
      refEl: refTitle,
      placeHolder: '',
      required: true,
    },
    {
      item: formData.brand_intro,
      message: CampaignMessages.WhatsYourBrandIntro,
      name: 'brand_intro',
      refEl: refBrandIntro,
      placeHolder: '',
      multiple: true,
      required: true,
    },
    {
      item: formData.brand_link,
      message: CampaignMessages.WhatsYourBrandProductPage,
      name: 'brand_link',
      refEl: refBrandLink,
      placeHolder: '',
      required: true,
    },
    {
      item: formData.budget_amount,
      message: CampaignMessages.SetBudget,
      name: 'budget_amount',
      refEl: refBudgetAmount,
      placeHolder: intl.formatMessage(CampaignMessages.MinimumBudget),
      required: true,
    },
    {
      item: formData.content_direction,
      message: CampaignMessages.WhatsYourDesiredContent,
      name: 'content_direction',
      refEl: refContentDirection,
      placeHolder: '',
      multiple: true,
      required: true,
    },
    {
      item: formData.captions,
      message: CampaignMessages.DoYouHaveSomeCaptions,
      name: 'captions',
      refEl: refCaptions,
      placeHolder: '',
      multiple: true,
      required: false,
    },
    {
      item: formData.video_duration,
      message: CampaignMessages.WhatsYourDesiredVideoDuration,
      name: 'video_duration',
      refEl: refVideoDuration,
      placeHolder: '',
      required: true,
    },
    {
      item: formData.hashtags,
      message: CampaignMessages.DoYouHaveSpecificHashtags,
      name: 'hashtags',
      refEl: refHashtags,
      placeHolder: '',
      required: false,
    },
    {
      item: formData.tag_accounts,
      message: CampaignMessages.ShouldCreatorsTag,
      name: 'tag_accounts',
      refEl: refTagAccounts,
      placeHolder: '',
      required: false,
    },
    {
      item: formData.commission_percent,
      message: Messages.yes,
      name: 'commission_percent',
      refEl: refAffiliateCommissionAmount,
      placeHolder: '0-100',
      required: false,
    },
    {
      item: formData.budget_amount_max,
      message: CampaignMessages.MaximumBudget,
      name: 'budget_amount_max',
      refEl: refBudgetAmountMax,
      placeHolder: intl.formatMessage(CampaignMessages.MaximumBudgetOptional),
      required: false,
    },
  ] as Array<
    FormInputType & {
      multiple?: boolean;
      messageTip?: MessageType;
      type?: string;
      required?: boolean;
      placeHolder?: string;
    }
  >;

  const campaignIdFromUrl = new URLSearchParams(window.location.search).get(
    'campaign_id'
  );

  // load unsaved campaign data
  useEffect(() => {
    const data = unsavedCampaigns.unsavedNewCampaign;
    if (!campaignIdFromUrl && data) {
      if (
        window.confirm(
          intl.formatMessage(Messages.ReloadUnsavedNewCampaignData)
        )
      ) {
        setFormData((state) => ({ ...state, ...data }));
      } else {
        resetUnsavedCampaign();
      }
    }
  }, [campaignIdFromUrl]);

  const {
    campaign: existingCampaign,
    fetch,
    setCampaign,
  } = useGetCampaignDatail();
  useEffect(() => {
    campaignIdFromUrl && fetch(campaignIdFromUrl);
  }, [campaignIdFromUrl]);
  useEffect(() => {
    // update inputList from campaign data
    if (existingCampaign) {
      const newData = convertCampaignResponseTypeToFormInputData(
        existingCampaign,
        formData
      );
      setFormData(newData);
    }
  }, [existingCampaign]);

  const validDateRange = () => {
    const { start_date, end_date } = formData.campaignPeriod;
    if (!ValidateRules.validDateRange(start_date, end_date)) {
      refCampaignDateRange.current?.focus();
      return false;
    }
    return true;
  };

  const isCampaignBudgetMaxError = (error: boolean, name: string) => {
    if (!error && name === 'budget_amount_max') {
      try {
        const budgetMin = parseInt(formData.budget_amount.content);
        const budgetMax = parseInt(formData.budget_amount_max.content);
        return budgetMax <= budgetMin;
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    return false;
  };

  const isValidForSave = function (
    inputs: typeof inputList,
    saveFormData = true
  ): boolean {
    if (
      formData.platforms.length <= 0 ||
      !formData.cover?.file ||
      (formData.commission === 'yes' && !formData.commission_percent.content) ||
      (formData.budget_mode === 'set_price' &&
        !formData.budget_amount.content) ||
      !formData.campaignPeriod.start_date ||
      !formData.campaignPeriod.end_date
    ) {
      return false;
    }
    if (
      isCampaignBudgetMaxError(
        formData.budget_amount_max.error,
        'budget_amount_max'
      )
    ) {
      return false;
    }
    for (let i = 0; i < inputs.length; i++) {
      const { item: input, refEl: ref, name: key, required } = inputs[i];
      if (input.error) return false;
      if (
        !required ||
        (key === 'budget_amount' && formData.budget_mode === 'product_exchange_only')
      ) {
        continue;
      }
      if (
        !ValidateRules.validInputString(
          input.content,
          input.maxLength,
          input.inputValueType
        )
      ) {
        if (saveFormData) {
          setFormData((state) => ({
            ...state,
            [key]: { ...input, error: true },
          }));
          ref.current?.focus();
        }
        return false;
      }
    }

    return true;
  };

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputDataType,
    key: string
  ) => {
    const str = event.target.value.slice(0, data.maxLength);
    setFormData((state) => ({
      ...state,
      [key]: {
        ...data,
        content: str,
        length: event.target.value.length,
        error:
          key === 'campaignBudget' && str === ''
            ? true
            : !ValidateRules.validInputString(
                str,
                data.maxLength,
                data.inputValueType
              ),
      },
      ...(key === 'campaignBudget' && str === '0' ? { negotiable: true } : {}),
    }));
  };

  const Icons = useIcons();
  const handleSelect = (platform: PlatformType) => {
    const selected = formData.platforms.find((w) => w === platform);
    const platforms = selected
      ? formData.platforms.filter((w) => w !== platform)
      : [...formData.platforms, platform];
    setFormData((state) => ({
      ...state,
      platforms,
    }));
  };

  const PlatformsList = (
    <Stack direction={'row'} columnGap={'16px'}>
      {Object.keys(Icons)
        .slice(0, 2)
        .map((key) => {
          const v = key as PlatformType;
          const selected = formData.platforms.find((w) => w === v);
          return (
            <MenuItem
              onClick={() => handleSelect(v)}
              key={v}
              disableRipple
              disableTouchRipple
              sx={{
                border: `1px solid ${theme.palette.neutral[700]} !important`,
                borderRadius: '8px',
                bgcolor: selected ? theme.palette.primary.main : 'transparent',
                '&:hover': {
                  bgcolor: selected
                    ? `${theme.palette.primary.main}  !important`
                    : 'transparent !important',
                },
              }}
            >
              {Icons[v][selected ? 'white' : 'color']}
            </MenuItem>
          );
        })}
    </Stack>
  );

  const renderInputs = (
    inputs: typeof inputList,
    hideTitle = false,
    startAdornment = '',
    onFocus = () => {
      return;
    },
    actionElement = <></>,
    rows = 3,
    endAdornment = <></>,
    fullWidth = true
  ) => {
    return inputs.map((data) => {
      const { item, message, name, refEl, multiple, required, placeHolder } =
        data;
      
      const isMaxBudgetError = isCampaignBudgetMaxError(item.error, name);
      return (
        <Box key={message.id}>
          <TitledPanel
            title={hideTitle ? '' : intl.formatMessage(message)}
            required={required}
            children={
              <>
                <OutlinedInput
                  fullWidth={fullWidth}
                  className={`border-radius-6px`}
                  ref={refEl}
                  id={message.id}
                  value={item.content}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleTextInputChange(event, item, name);
                  }}
                  error={item.error}
                  multiline={!!multiple}
                  rows={multiple ? rows : 1}
                  sx={{
                    height: 'auto',
                    bgcolor: theme.palette.base.white,
                    border: `1px solid ${theme.palette.neutral[700]}`,
                    boxShadow: '0px 1px 2px rgba(25, 21, 40, 0.04)',
                    paddingRight: '0px',
                    '& > textarea': {
                      padding: '8px 6px',
                    },
                    '& > input': {
                      padding: '8px 6px',
                    },
                  }}
                  startAdornment={startAdornment}
                  endAdornment={endAdornment}
                  onFocus={onFocus}
                  type={
                    [
                      'number',
                      'fractionalNumber0To100',
                      'number|empty',
                    ].includes(item.inputValueType)
                      ? 'number'
                      : 'text'
                  }
                  placeholder={placeHolder}
                />
                <InputErrorMessage
                  error={item.error}
                  inputValueType={item.inputValueType}
                />
                <InputErrorCustomMessage
                  error={isMaxBudgetError}
                  errorMessage={intl.formatMessage(
                    CampaignMessages.MaximumBudgetShouldNotLessEqualThanMin
                  )}
                />
              </>
            }
            actionElement={actionElement}
          />
        </Box>
      );
    });
  };

  const dateSelectedHandler = (
    startDate: DateOrUndefined,
    endDate: DateOrUndefined
  ) => {
    if (['active', 'completed'].includes(existingCampaign?.status || '')) {
      if (
        startDate &&
        moment(startDate).valueOf() > Date.now() &&
        moment(startDate).valueOf() >
          moment(formData.campaignPeriod.start_date).valueOf() +
            24 * 3600 * 1000
      ) {
        setFormData((state) => ({
          ...state,
          campaignPeriod: {
            start_date: state.campaignPeriod.start_date,
            end_date: dateToISOString(startDate, 24 * 60 * 60 * 1000 - 1000),
          },
        }));
      }
    } else {
      setFormData((state) => ({
        ...state,
        campaignPeriod: {
          start_date: startDate ? dateToISOString(startDate) : '',
          end_date: endDate
            ? dateToISOString(endDate, 24 * 60 * 60 * 1000 - 1000)
            : '',
        },
      }));
    }
  };

  const onCoverUploadSuccess = (res: FileUploadResponseType[]) => {
    setFormData((state) => ({ ...state, cover: res[0] }));
  };

  const onCoverDeleteSuccess = () => {
    setFormData((state) => ({
      ...state,
      cover: { id: '', file: '', media_type: '' } as FileUploadResponseType,
    }));
  };

  const onAttachmentFilesUploadSuccess = (resArr: FileUploadResponseType[]) => {
    const newResources = formData.visual_medias.slice();
    resArr.forEach((res) => {
      if (newResources.findIndex((item) => item.id === res.id) < 0) {
        newResources.push(res);
      }
    });
    setFormData((state) => ({
      ...state,
      visual_medias: newResources,
    }));
  };
  const onAttachmentSingleFileDeleteSuccess = (id: string) => {
    const newResources = formData.visual_medias.slice();
    const index = newResources
      .map((item) => item.id)
      .findIndex((item) => item === id);
    newResources.splice(index, 1);
    setFormData((state) => ({
      ...state,
      visual_medias: newResources,
    }));
  };

  const [createNewCampaign] = usePostCampaignMutation({});
  const [patchExistingCampaign] = usePatchCampaignMutation({});
  const [submitting, setSubmitting] = useState(false);
  const getCampaignData = (publish: boolean, preview = false) => {
    const {
      title,
      brand_intro,
      brand_link,
      budget_mode,
      budget_amount,
      budget_amount_max,
      campaignPeriod,
      content_direction,
      captions,
      video_duration,
      hashtags,
      tag_accounts,
      platforms,
      cover,
      visual_medias,
      requirements,
      commission,
      commission_percent,
      products,
    } = formData;

    const requirementsObj = {} as { [key: string]: string };
    requirements.forEach((w) => {
      const [key, val] = w.split(':');
      requirementsObj[key] = val;
    });

    const data = {
      title: title.content,
      brand_intro: brand_intro.content,
      brand_link: brand_link.content,
      budget_mode: budget_mode as BudgetModeType,
      budget_amount: parseInt(budget_amount.content),
      budget_amount_max: budget_amount_max.content
        ? parseInt(budget_amount_max.content)
        : null,
      start_date: campaignPeriod.start_date || null,
      end_date: campaignPeriod.end_date || null,
      creator_requirements: requirementsObj,
      content_direction: content_direction.content,
      captions: captions.content,
      video_duration: parseInt(video_duration.content),
      hashtags: hashtags.content
        .split(',')
        .map((w) => w.trim())
        .filter((w) => w.trim() !== ''),
      tag_accounts: tag_accounts.content
        .split(',')
        .map((w) => w.trim())
        .filter((w) => w.trim() !== ''),
      // attention: campaign in active status can't set status to active again
      ...(!existingCampaign || existingCampaign?.status === 'drafted'
        ? { status: publish ? 'active' : 'drafted' }
        : {}),
      platforms: platforms,
      cover_image: cover?.id || '',
      visual_medias: visual_medias,
      commission,
      commission_percent: parseFloat(commission_percent?.content),
      products,
    };
    return preview
      ? { ...data, id: 'none', cover_img: formData.cover?.file, brand_logo: '' }
      : data;
  };

  const createCampaign = async (redirect: boolean, publish: boolean) => {
    // allow to save campaign all the time
    if (publish && !isValidForSave(inputList, false)) return;

    const _data = getCampaignData(publish);
    const data = {
      ..._data,
      visual_medias: _data.visual_medias.map((w) => w.id),
      budget_amount:
        _data.budget_mode === 'product_exchange_only' ? null : _data.budget_amount,
      budget_amount_max:
        _data.budget_mode === 'product_exchange_only' ? null : _data.budget_amount_max,
    };
    await MutationHandler({
      intl,
      action: async () => {
        return await createNewCampaign(data).unwrap();
      },
      onSuccess: (resdata) => {
        showMessage.success(intl.formatMessage(Messages.SuccessfullySaved));
        resetUnsavedCampaign();
        if (redirect) {
          setTimeout(() => {
            navigate(staticURLCampaignsPage);
          }, 2000);
        } else {
          setCampaign(resdata);
        }
      },
    });
  };

  const patchCampaign = async (redirect: boolean, publish: boolean) => {
    if (!isValidForSave(inputList, false) || !existingCampaign) return;
    
    const _data = getCampaignData(publish);
    const data = {
      ..._data,
      visual_medias: _data.visual_medias.map((w) => w.id),
      budget_amount:
        _data.budget_mode === 'product_exchange_only' ? null : _data.budget_amount,
      budget_amount_max:
        _data.budget_mode === 'product_exchange_only' ? null : _data.budget_amount_max,
    };
    await MutationHandler({
      intl,
      action: async () => {
        return await patchExistingCampaign({
          campaign_id: existingCampaign.id,
          body: data,
        }).unwrap();
      },
      onSuccess: (resdata) => {
        showMessage.success(intl.formatMessage(Messages.SuccessfullySaved));
        if (redirect) {
          setTimeout(() => {
            navigate(staticURLCampaignsPage);
          }, 2000);
        } else {
          setCampaign(resdata);
        }
      },
    });
  };

  const handleCreateOrPostCampaign = async (
    redirect: boolean,
    publish: boolean
  ) => {
    setSubmitting(true);
    existingCampaign
      ? await patchCampaign(redirect, publish)
      : await createCampaign(redirect, publish);
    setSubmitting(false);
  };

  const [preview, setPreview] = useState(false);

  // get aigc campaign content idea
  const [postAIGCCampaignContentIdea] = usePostAIGCCampaignContentIdeaMutation(
    {}
  );
  const [loading, setLoading] = useState(false);

  const handleGetAIGCCampaignContentIdea = async () => {
    setLoading(true);
    await MutationHandler({
      intl,
      action: async () => {
        return await postAIGCCampaignContentIdea({
          title: formData.title.content,
          brand_intro: formData.brand_intro.content,
        }).unwrap();
      },
      onSuccess: (data) => {
        setFormData((state) => ({
          ...state,
          content_direction: {
            ...state.content_direction,
            content: data || '',
          },
        }));
      },
    });
    setLoading(false);
  };
  useEffect(() => {
    if (
      existingCampaign?.status === 'drafted' &&
      !formData.content_direction.content
    ) {
      handleGetAIGCCampaignContentIdea();
    }
  }, []);

  const generateButton = (
    <Button
      className={`border-radius-8px ${gs.buttonPrimary}`}
      sx={{
        height: '2rem',
        width: '6rem',
        '&:disabled': !loading
          ? {}
          : {
              backgroundColor: theme.palette.primary.main,
              color: `${theme.palette.primary.contrastText}`,
            },
      }}
      onClick={handleGetAIGCCampaignContentIdea}
      title={intl.formatMessage(
        CampaignMessages.generateAiContentBasedOnCampaignTitleAndBrandIntro
      )}
    >
      {!loading && intl.formatMessage(Messages.generate)}
      {loading && (
        <Box sx={{ pb: '0.375rem' }} component="span">
          <WritingAnimationIcon height="1.75rem" />
        </Box>
      )}
    </Button>
  );

  return (
    <>
      <Stack
        marginTop={'32px'}
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderRadius={'8px 8px 0px 0px'}
        padding={'12px 20px'}
      >
        <Stack direction="row" alignItems={'center'}>
          <Button
            sx={{
              height: '32px',
              fontWeight: '600',
              fontSize: '14px',
              padding: '8px',
            }}
            variant="text"
            className={gs.buttonOutlinedBlack}
            onClick={() => {
              navigate(-1);
            }}
            disabled={preview}
          >
            <ArrowBackRounded
              htmlColor={theme.palette.base.black}
              sx={{ width: '22px', height: '22px', marginLeft: '-8px' }}
            />
            &nbsp;&nbsp;
            {intl.formatMessage(CampaignMessages.CampaignCreation)}
          </Button>
          <Box flexGrow={1}></Box>
        </Stack>
      </Stack>
      <form>
        <Stack
          border={`1px solid ${theme.palette.neutral[600]}`}
          borderTop="0px"
          borderRadius={'0px 0px 8px 8px'}
          padding={'20px 24px'}
          display={preview ? 'none' : 'flex'}
        >
          <Stack rowGap={'27px'}>
            <TitledPanel
              title={intl.formatMessage(CampaignMessages.OnWhichSocialNetwork)}
              children={PlatformsList}
              required={true}
            />
            {renderInputs(inputList.slice(0, 2))}
            <TitledPanel
              title={intl.formatMessage(
                CampaignMessages.WhatsYourCampaignCover
              )}
              required={true}
              children={
                <FileUploaderSingle
                  initImg={formData.cover?.file || ''}
                  media={{
                    id: 'initid',
                    file: formData.cover?.file || '',
                    media_type: 'cover',
                  }}
                  id={String('initid')}
                  circleShape={false}
                  onFilesUploadSuccess={onCoverUploadSuccess}
                  onSingleFileDeleteSuccess={onCoverDeleteSuccess}
                  imgSize={14.625}
                  imgHeight={14.625}
                  mediaType={'cover'}
                  openGradientCover={false}
                  isAvatar={false}
                  imageRadius={'0.5rem'}
                  sizeLimit={1024 * 1024 * 10}
                />
              }
            />
            {renderInputs(inputList.slice(2, 3))}
            <TitledPanel
              title={intl.formatMessage(Messages.MoreProductLinks)}
              children={
                <>
                  <AddPromotedBrand
                    onAdd={async (name, link) => {
                      let products = (formData.products || []).slice();
                      products = products.filter((w) => w.name !== name);
                      products.push({ name, link });
                      setFormData((state) => ({
                        ...state,
                        products,
                      }));
                      return Promise.resolve(true);
                    }}
                    placeholderLink={intl.formatMessage(Messages.LinkURL)}
                    placeholderName={intl.formatMessage(
                      Messages.LinkDescription
                    )}
                  />
                  <Box sx={{ minHeight: '8px' }}></Box>
                  {(formData.products || []).length > 0 && (
                    <PromotedBrands
                      brands={formData.products}
                      onDelete={(name) => {
                        let products = formData.products.slice();
                        products = products.filter((w) => w.name !== name);
                        setFormData((state) => ({
                          ...state,
                          products,
                        }));
                      }}
                    />
                  )}
                </>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CampaignMessages.SetABudegt)}
              required={true}
              children={
                <>
                  <RadioGroup
                    aria-labelledby="budget type"
                    name="radio-buttons-group"
                    value={formData.budget_mode}
                    onChange={(event, value) => {
                      setFormData((state) => ({
                        ...state,
                        budget_mode: value,
                      }));
                    }}
                  >
                    <FormControlLabel
                      value={'product_exchange_only'}
                      control={<Radio />}
                      label={
                        <Typography>
                          {intl.formatMessage(
                            CampaignMessages.ProductExchangeOnly
                          )}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={'set_price'}
                      control={<Radio />}
                      label={intl.formatMessage(CampaignMessages.SetBudget)}
                      sx={{
                        '& .title': {
                          lineHeight: '42px',
                        },
                      }}
                    />
                  </RadioGroup>
                  {formData.budget_mode === 'set_price' && (
                    <Stack
                      direction="row"
                      columnGap={'16px'}
                      alignItems={'flex-start'}
                    >
                      {renderInputs(inputList.slice(3, 4), true, '$', () => {
                        setFormData((state) => ({
                          ...state,
                          budget_mode: 'set_price',
                        }));
                      })}
                      <Typography fontWeight="600" marginTop="12px">
                        -
                      </Typography>
                      {renderInputs(
                        [inputList.find((w) => w.name === 'budget_amount_max')],
                        true,
                        '$',
                        () => {
                          setFormData((state) => ({
                            ...state,
                            budget_mode: 'set_price',
                          }));
                        }
                      )}
                    </Stack>
                  )}
                </>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CampaignMessages.DoYouOfferAffiliate)}
              required={false}
              children={
                <>
                  <RadioGroup
                    aria-labelledby="budget type"
                    name="radio-buttons-group-commission"
                    value={formData.commission}
                    onChange={(event, value) => {
                      setFormData((state) => ({
                        ...state,
                        commission: value,
                      }));
                    }}
                  >
                    <FormControlLabel
                      value={'no'}
                      control={<Radio />}
                      label={
                        <Typography>
                          {capitalize(intl.formatMessage(Messages.no))}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={'yes'}
                      control={<Radio />}
                      label={capitalize(intl.formatMessage(Messages.yes))}
                      sx={{
                        '& .title': {
                          lineHeight: '42px',
                        },
                      }}
                    />
                  </RadioGroup>
                  {formData.commission === 'yes' &&
                    renderInputs(
                      inputList.slice(9, 10),
                      true,
                      '',
                      () => {
                        setFormData((state) => ({
                          ...state,
                          commission: 'yes',
                        }));
                      },
                      <></>,
                      3,
                      <Typography component="span" marginRight="8px">
                        %
                      </Typography>,
                      false
                    )}
                </>
              }
            />
            <TitledPanel
              title={intl.formatMessage(
                CampaignMessages.WhatsYourCampaignDateRange
              )}
              required={true}
              children={
                <DateRangePicker
                  onDateSelected={dateSelectedHandler}
                  startDate={
                    formData.campaignPeriod.start_date
                      ? moment(
                          Number(formData.campaignPeriod.start_date) ||
                            formData.campaignPeriod.start_date
                        ).toDate()
                      : undefined
                  }
                  endDate={
                    formData.campaignPeriod.end_date
                      ? moment(
                          Number(formData.campaignPeriod.end_date) ||
                            formData.campaignPeriod.end_date
                        ).toDate()
                      : undefined
                  }
                  error={!validDateRange()}
                  showHeader={false}
                />
              }
            />
            <TitledPanel
              title={intl.formatMessage(
                CampaignMessages.WhatsYourCreatorRequirements
              )}
              children={
                <PreferencesSetting
                  initStrArray={formData.requirements}
                  notifySettingChange={(
                    strArray: string[],
                    creatorRequirements
                  ) => {
                    setFormData((state) => ({
                      ...state,
                      requirements: strArray,
                      creator_requirements:
                        creatorRequirements as typeof state.creator_requirements,
                    }));
                  }}
                />
              }
            />
            {renderInputs(
              inputList.slice(4, 5),
              false,
              '',
              () => {
                return;
              },
              generateButton,
              4
            )}
            {renderInputs(
              inputList.slice(5, 9),
              false,
              '',
              () => {
                return;
              },
              <></>,
              2
            )}
            <TitledPanel
              title={intl.formatMessage(CampaignMessages.DoYouHaveOtherFiles)}
              children={
                <FileUploader
                  mediaType="attachment"
                  onFilesUploadSuccess={onAttachmentFilesUploadSuccess}
                  onSingleFileDeleteSuccess={
                    onAttachmentSingleFileDeleteSuccess
                  }
                  medias={formData.visual_medias}
                  sizeLimit={1024 * 1024 * 100}
                />
              }
            />
          </Stack>
          <Stack
            direction="row"
            columnGap="2rem"
            rowGap="1rem"
            sx={{
              mt: 6,
            }}
          >
            <Button
              variant="outlined"
              className={`border-radius-6px button-size ${gs.buttonOutlined}`}
              onClick={(e) => {
                e.preventDefault();
                handleCreateOrPostCampaign(false, false);
                
              }}
              disabled={ !isValidForSave(inputList, false) || submitting }
            >
              {intl.formatMessage(CampaignMessages.SaveCampaign)}
            </Button>
            <Button
              type="submit"
              variant="contained"
              className={`border-radius-6px button-size`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPreview(true);
              }}
              disabled={!isValidForSave(inputList, false) || submitting}
            >
              {intl.formatMessage(CampaignMessages.PreviewCampaign)}
            </Button>
          </Stack>
        </Stack>
      </form>
      <Stack
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderTop="0px"
        borderRadius={'0px 0px 8px 8px'}
        padding={'20px 24px'}
        display={!preview ? 'none' : 'flex'}
      >
        <CampaignDetailsPreview
          campaign={getCampaignData(false, true) as CampaignResponseType}
        />
        <Stack
          direction="row"
          columnGap="2rem"
          rowGap="1rem"
          sx={{
            mt: 6,
          }}
        >
          <Button
            variant="outlined"
            className={`border-radius-6px button-size ${gs.buttonOutlined}`}
            onClick={(e) => {
              setPreview(false);
            }}
            disabled={submitting}
          >
            {intl.formatMessage(CampaignMessages.BackToEdit)}
          </Button>
          <Button variant="contained" className={`border-radius-6px button-size`}
            onClick={(e) => {
              e.preventDefault();
              handleCreateOrPostCampaign(true, true);
            }}
            disabled={!isValidForSave(inputList, false) || submitting || isBilling === "free"}
          >
            { isBilling === "free" ?
              intl.formatMessage(Messages.upgradeSubscription) :
              intl.formatMessage(CampaignMessages.PublishCampaign)
            }
          </Button>
          
        </Stack>
      </Stack>
    </>
  );
}
