import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  useLazyGetAIGCTipsQuery,
  useLazyGetCreatorQuery,
  useLazyGetCreatorsQuery,
  useLazyGetCreatorInvitationsQuery,
} from '../redux/benaApi';
import { LazyQueryResultHandler } from '../redux/benaApiResultHandler';
import { useLanguage } from './language';

export function useGetCreatorData(id?: string) {
  const intl = useIntl();
  const [query, queryResult] = useLazyGetCreatorQuery({});
  
  const [creator, setCreator] = useState<CreatorDataDetails | null>(null);
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    LazyQueryResultHandler<CreatorDataDetails>({
      intl,
      result: queryResult as QueryResultType<CreatorDataDetails>,
      onSuccess: (resdata) => {
        setCreator(resdata);
      },
    });
  }, [queryResult]);
  const refresh = () => {
    if (id) query({ id });
  };
  return { creator, setCreator, refresh };
}

export function useGetAIGCTips() {
  const intl = useIntl();
  const [tips, setTips] = useState<string[]>([]);
  const [query, result] = useLazyGetAIGCTipsQuery({});
  const lang = useLanguage();
  useEffect(() => {
    query({ language: lang });
  }, []);

  useEffect(() => {
    LazyQueryResultHandler<string[]>({
      intl,
      result: result as QueryResultType<string[]>,
      onSuccess: (resdata) => {
        setTips(resdata);
      },
    });
  }, [result]);

  return { tips };
}

export function useSearchCreatorWithAt() {
  const intl = useIntl();
  const [query, queryResult] = useLazyGetCreatorsQuery({});
  const [listData, setListData] = useState<CreatorData[]>([]);

  useEffect(() => {
    if (queryResult.isSuccess) {
      LazyQueryResultHandler<CreatorsResponseType>({
        intl,
        result: queryResult as QueryResultType<CreatorsResponseType>,
        onSuccess: (resdata) => {
          setListData([...resdata.items]);
        },
      });
    }
  }, [queryResult]);

  const search = (str: string) => {
    query({
      queryParams: `&filters=${encodeURIComponent(
        JSON.stringify({ search: str })
      )}`,
      page: 1,
      limit: 10,
    });
  };
  return { data: listData, search };
}

export function useGetCreatorInvitations(id: string) {
  const intl = useIntl();
  const [query, queryResult] = useLazyGetCreatorInvitationsQuery({});
  const [invitations, setInvitations] =
    useState<GetCreatorInvitationsResponseType | null>(null);
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    LazyQueryResultHandler<GetCreatorInvitationsResponseType>({
      intl,
      result: queryResult as QueryResultType<GetCreatorInvitationsResponseType>,
      onSuccess: (resdata) => {
        setInvitations(resdata);
      },
    });
  }, [queryResult]);
  const refetch = async () => {
    if (id) await query({ creator_id: id });
  };
  return { invitations, refetch };
}
