import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { spaceKeyPressed } from '../utils/common';
type SendCodeButtonProps = {
  onClick: fnVoidToVoid;
  countDown: number;
  message: MessageType;
  countDownMessage: MessageType;
  timeUnit: MessageType;
  isSending: boolean;
};

export default function SendCodeButton({
  onClick,
  countDown,
  message,
  countDownMessage,
  timeUnit,
  isSending,
}: SendCodeButtonProps) {
  const intl = useIntl();
  const disabled = isSending || countDown > 0;
  return (
    <Typography
      tabIndex={0}
      onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
        spaceKeyPressed(event, () => {
          event.stopPropagation();
          !disabled && onClick();
        });
      }}
      onClick={
        disabled
          ? () => {
              return;
            }
          : onClick
      }
      className={disabled ? '' : 'cursorHand'}
      sx={(theme) => ({
        color: disabled ? theme.palette.grey[25] : theme.custom.primary2,
        fontSize: '0.875rem',
        fontWeight: 600,
      })}
    >
      {countDown > 0
        ? `${intl.formatMessage(
            countDownMessage
          )} ${countDown}${intl.formatMessage(timeUnit)}`
        : intl.formatMessage(message)}
    </Typography>
  );
}
