import { Stack, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FavoriteWithBorderIcon } from '../../assets/imgs/icons';

type InfoCardProps = {
  text: string;
  iconColor: string;
  iconRectColor: string;
  bgcolor: string;
};
export function InfoCard({
  text,
  iconColor,
  iconRectColor,
  bgcolor,
}: InfoCardProps) {
  const theme = useTheme();
  return text ? (
    <Stack
      direction="row"
      sx={{ maxWidth: 254, minWidth: 168, backgroundColor: bgcolor }}
      borderRadius="8px"
      alignItems={'flex-start'}
      justifyContent="space-between"
      padding={'1rem'}
      spacing={'0.5rem'}
    >
      <Typography color={theme.palette.primary[950]}>{text}</Typography>
      <FavoriteWithBorderIcon
        stroke={iconColor}
        rectStroke={iconRectColor}
        sx={{ width: '46px', height: '46px' }}
      />
    </Stack>
  ) : (
    <Stack sx={{ maxWidth: 345, height: '1px' }} />
  );
}
