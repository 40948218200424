import { Box, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import { ItemSelectedIcon } from '../assets/imgs/icons';

type OptionType = {
  value: string;
  label: string;
  selected: boolean;
};

type GridSelectProps = {
  options: OptionType[];
  onSelectItemChange: fnStringToVoid;
  multiple: boolean;
  heightRem?: string;
};

export default function GridSelect({
  options,
  onSelectItemChange,
  multiple = false,
  heightRem,
}: GridSelectProps) {
  const theme = useTheme();
  const length = options.length;
  const remaining = options.length % 3;
  const borderRadiusWidth = useCallback(
    (index: number) => {
      const radius = (tl: boolean, tr: boolean, br: boolean, bl: boolean) =>
        `${tl ? '0.5' : '0'}rem ${tr ? '0.5' : '0'}rem ${br ? '0.5' : '0'}rem ${
          bl ? '0.5' : '0'
        }rem`;
      const getRadius = (
        index: number,
        itl: number,
        itr: number,
        ibr: number,
        ibl: number,
        width1: string,
        width2: string
      ) =>
        index === itl
          ? { radius: radius(true, false, false, false), width: width1 }
          : index === itr
          ? { radius: radius(false, true, false, false), width: width1 }
          : index === ibl
          ? { radius: radius(false, false, true, false), width: width2 }
          : index === ibr
          ? { radius: radius(false, false, false, true), width: width2 }
          : { radius: '0', width: '33.33%' };

      if (length === 1)
        return {
          radius: radius(true, true, true, true),
          width: '100%',
        };
      if (length === 2) {
        return index === 0
          ? { radius: radius(true, false, false, true), width: '50%' }
          : { radius: radius(false, true, true, false), width: '50%' };
      }
      if (length === 3) {
        return index === 0
          ? { radius: radius(true, false, false, true), width: '33.33%' }
          : index === 2
          ? { radius: radius(false, true, true, false), width: '33.33%' }
          : { radius: '0', width: '33.3333%' };
      }
      if (length === 5) {
        return index === 0
          ? { radius: radius(true, false, false, false), width: '50%' }
          : index === 1
          ? { radius: radius(false, true, false, false), width: '50%' }
          : index === 4
          ? { radius: radius(false, false, true, true), width: '100%' }
          : { radius: '0', width: '50%' };
      }
      if (length === 6) {
        return index === 0
          ? { radius: radius(true, false, false, false), width: '50%' }
          : index === 1
          ? { radius: radius(false, true, false, false), width: '50%' }
          : index === 4
          ? { radius: radius(false, false, false, true), width: '50%' }
          : index === 5
          ? { radius: radius(false, false, true, false), width: '50%' }
          : { radius: '0', width: '50%' };
      }
      if (length === 8) {
        return index === 0
          ? { radius: radius(true, false, false, false), width: '33.33%' }
          : index === 2
          ? { radius: radius(false, true, false, false), width: '33.33%' }
          : index === 6
          ? { radius: radius(false, false, false, true), width: '50%' }
          : index === 7
          ? { radius: radius(false, false, true, false), width: '50%' }
          : { radius: '0', width: '33.33%' };
      }
      const first = 0;
      const last = length - 1;
      return getRadius(
        index,
        first,
        remaining === 1 ? first + 1 : first + 2,
        remaining === 1 ? last - 1 : last - 2,
        last,
        remaining === 1 ? '50%' : '33.33%',
        remaining === 1 ? '50%' : '33.33%'
      );
    },
    [remaining, length]
  );
  return (
    <Box
      sx={{
        width: '100%',
        border: `0rem solid ${theme.custom.primary4}`,
        borderRadius: '0.5rem 0.5rem 0.5rem 0.5rem',
        flexWrap: 'wrap',
      }}
      className="boxcenterhv"
    >
      {options.map((option, index) => {
        const { radius, width } = borderRadiusWidth(index);
        return (
          <MenuItem
            key={option.value}
            tabIndex={0}
            onClick={() =>
              !multiple
                ? onSelectItemChange(option.value)
                : onSelectItemChange(
                    options
                      .map((o) =>
                        o.selected
                          ? o.value === option.value
                            ? ''
                            : o.value
                          : o.value === option.value
                          ? o.value
                          : ''
                      )
                      .filter((s) => s !== '')
                      .join(',')
                  )
            }
            sx={{
              border: `0.0625rem solid ${theme.custom.primary4}`,
              borderRadius: radius,
              width: { width },
              height: heightRem || '2.5rem',
              minHeight: heightRem || '2.5rem',
              bgcolor: option.selected ? theme.custom.primary5 : 'inherit',
              color: option.selected ? theme.custom.primary2 : 'inherit',
              px: '0.5rem !important',
            }}
            className="boxcenterhv cursorHand"
          >
            <Typography
              component="span"
              sx={{ fontSize: '0.75rem', textAlign: 'center', width: '100%' }}
              fontWeight={500}
            >
              {option.label}
            </Typography>
            {option.selected && (
              <>
                <Box sx={{ flexGrow: 1 }}></Box>
                <ItemSelectedIcon
                  sx={{ width: '0.75rem', height: '0.75rem' }}
                />
              </>
            )}
          </MenuItem>
        );
      })}
    </Box>
  );
}
