import { ArrowBackRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useGlobalStyles } from '../../assets/styles/style';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import CampaignCardHorizontal from '../components/CampaignCardHorizontal';
import { useContext, useEffect, useState } from 'react';
import { CreatorContext } from '../context/context';
import CreatorRequirements from '../components/CreatorRequirements';
import TitledPanel from '../components/TitledPanel';
import HoverableImage from '../components/HoverableImage';
import FlexSpaceAroundLeftAligned from '../../components/FlexSpaceBetweenLeftAligned';
import { useGetCampaignDetailQuery } from '../../redux/benaApi';
import moment from 'moment';
import { Messages } from '../../localization/Messages';
import { DisplayPreferencesSettingItemsInOrder } from '../campaign/PreferencesSetting';
import ApplicationViewPage from './ApplicationViewPage';
import {
  useSocialAccountVerification,
  useUserTiktokVerified,
} from '../../hooks/account';
import { useBreakpointXs } from '../../utils/useStyleHooks';

type CreatorCampaignDetailsProps = {
  campaignId: string;
  notAllowViewApplication?: boolean;
};
export default function CreatorCampaignDetails({
  campaignId,
  notAllowViewApplication = false,
}: CreatorCampaignDetailsProps) {
  const theme = useTheme();
  const gs = useGlobalStyles();
  const intl = useIntl();
  const isMobileView = useBreakpointXs();

  const context = useContext(CreatorContext);
  const [open, setOpen] = useState(false);
  const { data: campaign, refetch } = useGetCampaignDetailQuery({
    campaign_id: context.currentDisplayedCampaignId,
  });
  const campaignData = campaign?.data;
  const tiktokVerified = useUserTiktokVerified();
  const ssoVerify = useSocialAccountVerification();
  const ssoVerifyTiktok = () => {
    // redirect to creator homepage
    const url = '/sso_verify_callback_home_page/' + campaignId;
    ssoVerify('tiktok', url);
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const hidePanel = () => {
    context.setCurrentDisplayedCampaignId('');
  };

  if (!campaignData) return null;
  const applicationId = campaignData.application?.id || '';

  const campaignInfo = {
    platformIds: (campaignData.platforms || []) as SocialPlatformId[],
    launchedOn: moment(campaignData.start_date).format(`M/DD/YY`),
    logo: campaignData.brand_logo,
    title: campaignData.title,
    image: campaignData.cover_img,
    brandInfo: campaignData.brand_intro,
    pageUrl: campaignData.brand_link,
    campaignPeriod: `${moment(campaignData.start_date).format(
      `M/DD/YY`
    )} - ${moment(campaignData.end_date).format(`M/DD/YY`)}`,
    budget: `$${campaignData.budget_amount}`,
    budget_max: campaignData.budget_amount_max
      ? `$${campaignData.budget_amount_max}`
      : undefined,
    freeProduct: campaignData.budget_mode === 'product_exchange_only',
    commission: campaignData.commission,
    commissionPercent: campaignData.commission_percent,
    products: campaignData.products || [],
  };

  const tags = DisplayPreferencesSettingItemsInOrder(
    campaignData.creator_requirements
  );
  const contentDirections = campaignData.content_direction;
  const caption = campaignData.captions;
  const videoDuration = campaignData.video_duration;
  const hashtags = campaignData.hashtags.join(', ');
  const otherAccounts = campaignData.tag_accounts.join(', ');

  const applyCampaign = () => {
    hidePanel();
    setTimeout(() => {
      // TODO how to clear campaignId in context?
      context.setCampaignIdForScriptTypeCampaign(campaignId);
      context.setCurrentDisplayedCampaignId('');
      context.setCurrentScriptTitle('campaign');
    }, 100);
  };

  const applied = !!campaignData.application;

  const applyButton = (height = '') => (
    <Button
      sx={{
        minWidth: '144px',
        height: height || '32px',
        fontWeight: '600',
        fontSize: '14px',
        padding: '8px',
      }}
      variant="contained"
      className={gs.buttonPrimary}
      onClick={
        applied && !notAllowViewApplication
          ? () => {
              setOpen(true);
            }
          : tiktokVerified
          ? applyCampaign
          : ssoVerifyTiktok
      }
    >
      {intl.formatMessage(
        applied && !notAllowViewApplication
          ? CreatorMessages.ViewApplication
          : tiktokVerified
          ? CreatorMessages.ApplyToCampaign
          : CreatorMessages.LinkTiktokToApply
      )}
    </Button>
  );

  return (
    <>
      <Stack
        marginTop={isMobileView ? '0px' : '48px'}
        border={
          isMobileView ? '0px' : `1px solid ${theme.palette.neutral[600]}`
        }
        borderRadius={'8px 8px 0px 0px'}
        padding={'12px 20px'}
      >
        <Stack direction="row" alignItems={'center'}>
          <IconButton onClick={hidePanel} disableRipple>
            <ArrowBackRounded
              htmlColor={theme.palette.base.black}
              sx={{ width: '22px', height: '22px' }}
            />
            <Typography fontSize={16} fontWeight={500} color="grey.700">
              {intl.formatMessage(CreatorMessages.GoBack)}
            </Typography>
          </IconButton>
          <Box flexGrow={1}></Box>
          {applyButton()}
        </Stack>
      </Stack>
      <Stack
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderTop="0px"
        borderRadius={'0px 0px 8px 8px'}
        padding={isMobileView ? '16px' : '20px 24px'}
      >
        <Stack rowGap={'27px'}>
          <CampaignCardHorizontal {...campaignInfo} />
          <CreatorRequirements tags={tags} />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.ContentDirection)}
            children={
              <Typography
                sx={{ whiteSpace: 'pre-line', lineHeight: '26px' }}
                color="grey.400"
                fontSize={'14px'}
                fontWeight={400}
              >
                {contentDirections}
              </Typography>
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Caption)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {caption}
              </Typography>
            }
          />
          <Stack
            direction="row"
            columnGap={'128px'}
            rowGap={'16px'}
            flexWrap={'wrap'}
            alignItems={'flex-start'}
          >
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.VideoDurations)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {videoDuration} second{videoDuration > 1 ? 's' : ''}
                </Typography>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.Hashtags)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {hashtags || '-'}
                </Typography>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.OtherAccounts)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {otherAccounts || '-'}
                </Typography>
              }
            />
          </Stack>
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Files)}
            children={
              <Stack
                direction="row"
                flexWrap={'wrap'}
                rowGap="16px"
                columnGap={'16px'}
              >
                {(campaignData.visual_medias || []).length > 0 ? (
                  <>
                    {(campaignData.visual_medias || []).map((w: any) => (
                      <HoverableImage
                        fileLink={w.file}
                        fileName={w.name}
                        fileType={w.content_type}
                        key={w.id}
                        width={isMobileView ? '163px' : '224px'}
                        height={isMobileView ? '163px' : '224px'}
                      />
                    ))}
                    <FlexSpaceAroundLeftAligned
                      width={isMobileView ? '163px' : '224px'}
                    />
                  </>
                ) : (
                  <Typography
                    fontWeight={400}
                    color="grey.400"
                    fontSize={'14px'}
                  >
                    {intl.formatMessage(Messages.NoFileFound)}
                  </Typography>
                )}
              </Stack>
            }
          />
        </Stack>
        <Box height="16px"></Box>
        {isMobileView && applyButton('48px')}
      </Stack>
      <Box height="2rem"></Box>
      {open && applicationId && (
        <ApplicationViewPage
          show={!!open}
          hideDialog={(submitted: boolean) => {
            setOpen(false);
            submitted && refetch();
          }}
          applicationId={applicationId}
        />
      )}
    </>
  );
}
