import {
  Box,
  Link,
  Pagination,
  Stack,
  Typography,
  capitalize,
  useTheme,
} from '@mui/material';
import STabs from '../../components/STabs';
import { useIntl } from 'react-intl';
import { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import {
  LazyQueryResultHandler,
  MutationHandler,
} from '../../redux/benaApiResultHandler';
import {
  replaceURLSearchParam,
  useStabsFindTabIndexByQueryStringTab,
} from '../../utils/common';
import { Messages } from '../../localization/Messages';
import CampaignCard from '../components/CampaignCard';
import moment from 'moment';
import FlexSpaceAroundLeftAligned from '../../components/FlexSpaceBetweenLeftAligned';
import { UploadCampaignWork } from '../components/UploadCampaignWork';
import {
  EnterPostLink,
  genLinks,
  partLinksString,
} from '../../features/sop/EnterPostLink';
import {
  useLazyGetCreatorJobContractsQuery,
  usePatchCampaignInvitationMutation,
  usePatchJobContractMutation,
} from '../../redux/benaApi';
import DialogPage from './DialogPage';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import CreatorCampaignOfferDetails from './CreatorCampaignOfferDetails';
import ApplicationViewPage from './ApplicationViewPage';
import { BenaRefreshPanel } from '../../components/BenaPagination';
import { staticURLCreatorJobs } from '../../route/urls';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CreatorMessages } from '../../localization/CreatorMessages';
import CreatorCampaignDetails from './CreatorCampaignDetails';
import CreatorContextWrapper from '../context/creatorContextWrapper';
import { CreatorContext } from '../context/context';
import CreatorAIScriptPanel from './CreatorAIScriptPanel';
import { useGlobalStyles } from '../../assets/styles/style';
import { ReviewCreator } from '../../features/sop/ReviewCreator';
import LoadingCover from '../../components/LoadingCover';
import Confirm from '../../components/Confirm';
import { showMessage } from '../../components/ShowMessage';
import { PageMobileNavigationBarAndTitleBar } from '../../features/mobileNavbar/navbar';

const APIQUERYPAGESIZE = 20;

function JobsPageWrapperChild() {
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const gs = useGlobalStyles();
  const creatorContext = useContext(CreatorContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const isMobileView = useBreakpointXs();

  // hired
  const [jobContracts, setJobContracts] = useState<
    CreatorJobContractItemType[]
  >([]);
  const [hiredTotal, setHiredTotal] = useState(0);
  const [hiredPage, setHiredPage] = useState(1);
  const [queryJobContracts, queryJobContractsResult] =
    useLazyGetCreatorJobContractsQuery({});
  const queryHiredPage = (page: number, size = APIQUERYPAGESIZE) => {
    queryJobContracts({
      page,
      size,
      tab: 'hired',
    });
  };
  useEffect(() => {
    queryHiredPage(hiredPage, APIQUERYPAGESIZE);
  }, []);
  useEffect(() => {
    LazyQueryResultHandler<GetCreatorJobContractsResponseType>({
      intl,
      result:
        queryJobContractsResult as QueryResultType<GetCreatorJobContractsResponseType>,
      onSuccess: (data) => {
        // TODO add pagination
        setJobContracts([...data.items]);
        setHiredTotal(data.total);
        setHiredPage(data.page);
      },
    });
  }, [queryJobContractsResult]);

  const hiredLoading =
    queryJobContractsResult.isFetching || queryJobContractsResult.isLoading;

  // proposals
  const [proposals, setProposals] = useState<CreatorJobContractItemType[]>([]);
  const [proposalTotal, setProposalTotal] = useState(0);
  const [proposalPage, setProposalPage] = useState(1);
  const [queryCampaignProposals, queryCampaignProposalsResult] =
    useLazyGetCreatorJobContractsQuery({});
  const queryApplicationsPage = (page: number, size = APIQUERYPAGESIZE) => {
    queryCampaignProposals({
      page,
      size,
      tab: 'proposals',
    });
  };

  useEffect(() => {
    queryApplicationsPage(proposalPage, APIQUERYPAGESIZE);
  }, []);
  useEffect(() => {
    LazyQueryResultHandler<GetCreatorJobContractsResponseType>({
      intl,
      result:
        queryCampaignProposalsResult as QueryResultType<GetCreatorJobContractsResponseType>,
      onSuccess: (data) => {
        setProposals([...data.items]);
        setProposalTotal(data.total);
        setProposalPage(data.page);
      },
    });
  }, [queryCampaignProposalsResult]);

  const proposalLoading =
    queryCampaignProposalsResult.isFetching ||
    queryCampaignProposalsResult.isLoading;

  const noDataToShow = useMemo(
    () => (
      <Stack
        className="boxcenterhv"
        minHeight={'784px'}
        width="100%"
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderRadius={'10px'}
        rowGap="18px"
      >
        <Box padding="48px" borderRadius={'50%'} bgcolor={'base.white2white'}>
          <img
            src="/imgs/no-campaign-found.svg"
            alt="no campaign"
            width="276px"
            height="258px"
          />
        </Box>
        <Typography
          fontSize={'22px'}
          fontWeight="500"
          color={theme.palette.grey[700]}
          textAlign={'center'}
        >
          {intl.formatMessage(Messages.SeemsLikeItDoesntHave)}
        </Typography>
      </Stack>
    ),
    [intl, theme.palette.grey, theme.palette.neutral]
  );

  // add auto navigate to tabs
  const tabsTitleName = ['hired', 'proposals'];
  const tabIndexFromURL = useStabsFindTabIndexByQueryStringTab(tabsTitleName);
  useEffect(() => {
    tabIndexFromURL >= 0 && setSelectedTabIndex(tabIndexFromURL);
  }, [tabIndexFromURL]);

  // calculate actionButton
  const actionButton = useCallback(
    (
      id: string,
      _drafts: JobContractAttachmentType[],
      status?: JobContractStatusType,
      requirePostLinks?: boolean
    ) => {
      const accepted = status === 'accepted';
      const finished = status === 'finished';
      const drafts = _drafts || [];
      const nodrafts = drafts.length <= 0;
      const draftsApproved =
        drafts.length > 0 && drafts.every((w) => w.status === 'accepted');
      const submittedButNoApproval =
        drafts.length > 0 &&
        drafts.every((w) => !['accepted', 'rejected'].includes(w.status || ''));
      const requireResubmit =
        drafts.length > 0 &&
        drafts.some((w) => ['rejected'].includes(w.status || ''));
      return accepted && nodrafts
        ? {
            action: (
              <ActionButton
                clickHandler={() => {
                  setContractId(id);
                }}
                title={intl.formatMessage(Messages.StartMakingTheContent)}
              />
            ),
            label: intl.formatMessage(Messages.StartTheWork),
            labelSx: {
              bgcolor: theme.palette.primary[500],
            },
          }
        : submittedButNoApproval && accepted
        ? {
            action: (
              <ActionButton
                clickHandler={() => {
                  setContractId(id);
                }}
                title={intl.formatMessage(Messages.CheckSubmittedDraft)}
              />
            ),
            label: intl.formatMessage(Messages.Submitted),
            labelSx: {
              bgcolor: theme.palette.secondary[700],
            },
          }
        : requireResubmit && accepted
        ? {
            action: (
              <ActionButton
                clickHandler={() => {
                  setContractId(id);
                }}
                title={intl.formatMessage(Messages.ResubmitTheDraft)}
              />
            ),
            label: intl.formatMessage(Messages.DraftRejected),
            labelSx: {
              bgcolor: theme.palette.danger[600],
            },
          }
        : draftsApproved && accepted && requirePostLinks
        ? {
            action: (
              <ActionButton
                clickHandler={() => {
                  setPostlinkContractId(id);
                }}
                title={intl.formatMessage(Messages.SubmitPostLinks)}
              />
            ),
            label: intl.formatMessage(Messages.DraftApproved),
            labelSx: {
              bgcolor: theme.palette.success[600],
            },
          }
        : finished
        ? {
            action: (
              <ActionButton
                clickHandler={() => {
                  setReviewContractId(id);
                }}
                title={intl.formatMessage(Messages.CheckReview)}
              />
            ),
            label: intl.formatMessage(Messages.jobContractStatusFinisheds),
            labelSx: {
              bgcolor: theme.palette.grey[700],
            },
          }
        : {
            action: undefined,
          };
    },
    []
  );

  const hiredList = (
    <Stack
      direction="row"
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      rowGap="24px"
      position="relative"
    >
      <LoadingCover loading={hiredLoading || proposalLoading} />
      {jobContracts
        .filter((w) => w.job_contract)
        .map((item) => {
          const { id, start_date, title, cover_img, brand_logo, platforms } =
            item.campaign || {};
          const {
            drafts,
            id: jobcontractId,
            post_link,
          } = item.job_contract || {
            drafts: [],
          };
          const { status } = item.job_contract || {};
          // if not skip require post links
          const requirePostLinks = post_link !== null;
          const action = actionButton(
            jobcontractId || '',
            drafts,
            status,
            requirePostLinks
          );
          return (
            <CampaignCard
              campaignId={id}
              platformIds={platforms as SocialPlatformId[]}
              launchedOn={moment(start_date).format(`M/DD/YY`)}
              title={title}
              logo={brand_logo || '/imgs/default_logo.png'}
              image={cover_img || ''}
              key={id}
              actionButton={action.action}
              label={action.label}
              labelSx={action.labelSx}
              cardClickable={!action.action}
            />
          );
        })}
      {jobContracts.length <= 0 ? (
        noDataToShow
      ) : (
        <FlexSpaceAroundLeftAligned width={'390px'} />
      )}
    </Stack>
  );

  const proposalList = (
    <Stack
      direction="row"
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      rowGap="24px"
      position="relative"
    >
      <LoadingCover loading={hiredLoading || proposalLoading} />
      {proposals.map((item, index) => {
        const { id, start_date, title, cover_img, brand_logo, platforms } =
          item.campaign;
        const { status } = item.job_contract || {};
        const { status: invitationStatus } = item.invitation || {};
        const appId = item.application?.id || '';
        const applicationStatus = ['applied', 'accepted', 'rejected'].includes(
          item.application?.status || ''
        )
          ? item.application?.status !== 'applied' && status === 'rejected'
            ? 'offer rejected'
            : item.application?.status === 'applied' && status === 'rejected'
            ? 're-applied'
            : item.application?.status
          : !appId && invitationStatus === 'invited'
          ? 'invited'
          : !appId && invitationStatus === 'rejected'
          ? 'invitation rejected'
          : 'drafted';
        const action =
          item.type === 'job_contract' && status === 'offered'
            ? {
                title: intl.formatMessage(Messages.ViewOffer),
                action: () => {
                  // view offer
                  setOfferCampaignId(item.campaign.id);
                },
                label: intl.formatMessage(Messages.ViewOffer),
                labelSx: { bgcolor: theme.palette.success[600] },
              }
            : !appId && invitationStatus === 'invited'
            ? {
                title: intl.formatMessage(CreatorMessages.ApplyToCampaign),
                action: () => {
                  item.campaign?.id &&
                    creatorContext.setCurrentDisplayedCampaignId(
                      item.campaign?.id
                    );
                },
                title2: intl.formatMessage(Messages.reject),
                action2: () => {
                  setInvitationId(item.invitation?.id || '');
                },
                label: applicationStatus,
                labelSx: { bgcolor: theme.palette.success[600] },
              }
            : !appId && invitationStatus === 'rejected'
            ? {
                title: '',
                action: () => {
                  return;
                },
                label: applicationStatus,
                labelSx: { bgcolor: theme.palette.danger[600] },
              }
            : {
                title:
                  applicationStatus === 'offer rejected'
                    ? ''
                    : intl.formatMessage(Messages.View),
                action: () => {
                  if (
                    ['applied', 'accepted', 'rejected'].includes(
                      applicationStatus || ''
                    ) ||
                    ['rejected'].includes(status || '')
                  ) {
                    if (appId) {
                      setCurrentDisplayApplicationId(appId);
                    }
                  } else {
                    creatorContext.setCampaignIdForScriptTypeCampaign(id);
                    creatorContext.setCurrentScriptTitle('campaign');
                    creatorContext.setCurrentDisplayedCampaignId('');
                  }
                },
                label: applicationStatus,
                labelSx: {
                  bgcolor:
                    applicationStatus === 'accepted'
                      ? theme.palette.success[600]
                      : applicationStatus === 'rejected' ||
                        status === 'rejected'
                      ? theme.palette.danger[600]
                      : theme.palette.primary[600],
                },
              };
        return (
          <CampaignCard
            campaignId={id}
            platformIds={platforms as SocialPlatformId[]}
            launchedOn={moment(start_date).format(`M/DD/YY`)}
            title={title}
            logo={brand_logo || '/imgs/default_logo.png'}
            image={cover_img || ''}
            key={id}
            actionButton={
              action.title ? (
                <ActionButton
                  clickHandler={action.action}
                  title={action.title}
                  clickHandler2={action.action2}
                  title2={action.title2}
                />
              ) : (
                <></>
              )
            }
            label={capitalize(action.label || '')}
            labelSx={action.labelSx}
            labelTooltip={
              item.application?.status === 'rejected'
                ? item.application?.withdraw_reason || ''
                : ''
            }
            cardClickable={!action.title}
          />
        );
      })}
      {proposals.length <= 0 ? (
        noDataToShow
      ) : (
        <FlexSpaceAroundLeftAligned width={'390px'} />
      )}
    </Stack>
  );

  const [contractId, setContractId] = useState('');

  // patch jobcontract post link
  const [patchJobcontract] = usePatchJobContractMutation();
  const [postlinkContractId, setPostlinkContractId] = useState('');
  const initPostlink =
    partLinksString(
      jobContracts.find((w) => w.job_contract?.id === postlinkContractId)
        ?.job_contract?.post_link || ''
    )
      .map((w) => `${w.description} ${w.link}`)
      .join(';') || '';

  // show campaign offer
  const [offerCampaignId, setOfferCampaignId] = useState('');

  // show application
  const [currentDisplayApplicationId, setCurrentDisplayApplicationId] =
    useState('');

  // view job offer from url search
  const [searchParams] = useSearchParams();
  const campaignIdFromUrl = searchParams.get('campaign_id');
  useEffect(() => {
    if (
      campaignIdFromUrl &&
      proposals.find((w) => w.campaign?.id === campaignIdFromUrl)?.job_contract
    ) {
      setOfferCampaignId(campaignIdFromUrl);
    }
  }, [campaignIdFromUrl, proposals]);

  // creator review
  const [reviewContractId, setReviewContractId] = useState('');
  const reviewData = jobContracts?.find(
    (w) => w.job_contract?.id === reviewContractId
  )?.job_contract?.review;

  // reject invitation
  const [patchInvitation] = usePatchCampaignInvitationMutation();
  const [invitationId, setInvitationId] = useState('');
  const handlePatchInvitation = async () => {
    let ret = false;
    await MutationHandler({
      intl,
      action: async () => {
        return await patchInvitation({
          invitation_id: invitationId,
          body: { status: 'rejected' },
        }).unwrap();
      },
      onSuccess: () => {
        ret = true;
        showMessage.success(intl.formatMessage(Messages.SuccessfullyRejected));
      },
    });
    return ret;
  };

  return (
    <Box sx={{ maxWidth: '100%', marginTop: '40px' }}>
      <Typography
        fontWeight="500"
        fontSize="24px"
        color="grey.700"
        marginBottom="11px"
      >
        {intl.formatMessage(Messages.YourJobs)}
      </Typography>
      <STabs
        tabs={[
          {
            name: `${intl.formatMessage(Messages.Hired)} (${
              hiredLoading ? '...' : `${hiredTotal}`
            })`,
            children: (
              <Box>
                <BenaRefreshPanel onRefresh={() => queryHiredPage(1)} />
                {hiredList}
                {hiredTotal > APIQUERYPAGESIZE && (
                  <Stack justifyContent={'center'}>
                    <Pagination
                      count={Math.ceil(hiredTotal / APIQUERYPAGESIZE)}
                      page={hiredPage}
                      siblingCount={0}
                      onChange={(_, page) => {
                        queryHiredPage(page);
                      }}
                    />
                  </Stack>
                )}
              </Box>
            ),
          },
          {
            name: `${intl.formatMessage(Messages.Proposals)} (${
              proposalLoading ? '...' : proposalTotal
            })`,
            children: (
              <Box>
                <BenaRefreshPanel onRefresh={() => queryApplicationsPage(1)} />
                {proposalList}
                {proposalTotal > APIQUERYPAGESIZE && (
                  <Stack
                    justifyContent={'center'}
                    sx={{ width: '100%', padding: '12px 24px' }}
                    direction="row"
                  >
                    <Pagination
                      count={Math.ceil(proposalTotal / APIQUERYPAGESIZE)}
                      page={proposalPage}
                      siblingCount={0}
                      onChange={(_, page) => {
                        queryApplicationsPage(page);
                      }}
                    />
                  </Stack>
                )}
              </Box>
            ),
          },
        ]}
        selectedIndex={selectedTabIndex}
        onTabChange={(value) => {
          if (selectedTabIndex !== value) {
            setSelectedTabIndex(value);
            navigate(
              `${staticURLCreatorJobs}${replaceURLSearchParam(
                window.location.search,
                'tab',
                tabsTitleName[value]
              )}`
            );
          }
        }}
        tabTitleFontSize="0.875rem"
        spaceBetween={false}
      />
      <UploadCampaignWork
        jobContractId={contractId}
        open={!!contractId}
        setClose={() => {
          setContractId('');
          queryHiredPage(hiredPage, APIQUERYPAGESIZE);
        }}
      />
      <EnterPostLink
        initText={initPostlink}
        initOpen={!!postlinkContractId}
        handlePatch={async (text: string) => {
          await patchJobcontract({
            contract_id: postlinkContractId,
            body: {
              post_link: genLinks(text),
            },
          });
        }}
        onCancel={() => {
          setPostlinkContractId('');
          queryHiredPage(proposalPage, APIQUERYPAGESIZE);
        }}
      />
      {!!offerCampaignId && (
        <DialogPage
          initOpen={!!offerCampaignId}
          children={
            <CreatorCampaignOfferDetails
              campaignId={offerCampaignId}
              jobContract={
                proposals.find((w) => w.campaign.id === offerCampaignId)
                  ?.job_contract
              }
              onClose={() => {
                setOfferCampaignId('');
                queryApplicationsPage(proposalPage, APIQUERYPAGESIZE);
                campaignIdFromUrl &&
                  navigate(
                    window.location.pathname +
                      replaceURLSearchParam(
                        window.location.search,
                        'campaign_id',
                        ''
                      )
                  );
              }}
              onAcceptOfferSucceed={() => {
                setOfferCampaignId('');
                queryHiredPage(hiredPage, APIQUERYPAGESIZE);
                navigate(
                  window.location.pathname +
                    replaceURLSearchParam(
                      replaceURLSearchParam(
                        window.location.search,
                        'tab',
                        'hired'
                      ),
                      'campaign_id',
                      ''
                    )
                );
              }}
            />
          }
          showHeader={!isMobileView}
          showMobileViewPadding={false}
        />
      )}
      {currentDisplayApplicationId && (
        <ApplicationViewPage
          show={!!currentDisplayApplicationId}
          hideDialog={() => {
            setCurrentDisplayApplicationId('');
            queryApplicationsPage(proposalPage, APIQUERYPAGESIZE);
          }}
          applicationId={currentDisplayApplicationId}
        />
      )}
      <ViewToApplyCampaign />
      <ReviewCreator
        initReview={
          typeof reviewData === 'object' && reviewData
            ? (reviewData as ReviewType)
            : undefined
        }
        initOpen={!!reviewContractId}
        onCancel={() => setReviewContractId('')}
        viewMode={true}
      />
      <Confirm
        text={intl.formatMessage(Messages.AreYouSureToRejectTheInvitation)}
        handleConfirm={async () => {
          const ret = await handlePatchInvitation();
          if (ret) {
            queryApplicationsPage(proposalPage, APIQUERYPAGESIZE);
          }
        }}
        open={!!invitationId}
        setOpen={() => {
          setInvitationId('');
        }}
      />
    </Box>
  );
}

type ActionButtonProps = {
  clickHandler: fnVoidToVoid;
  title: string;
  clickHandler2?: fnVoidToVoid;
  title2?: string;
};
function ActionButton({
  clickHandler,
  title,
  clickHandler2,
  title2,
}: ActionButtonProps) {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          bgcolor: theme.palette.base.white,
          borderRadius: '8px',
          height: '40px',
          minWidth: '150px',
          color: theme.palette.primary.main_white,
          fontSize: '14px',
          fontWeight: '600',
          paddingX: '8px',
          cursor: 'pointer',
        }}
      >
        <Link
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            clickHandler();
          }}
          sx={{
            color: theme.palette.primary.main_white,
            fontSize: '14px',
            fontWeight: '600',
            width: '100%',
            height: '100%',
          }}
          className={'boxcenterhv'}
          underline="none"
        >
          {title}
        </Link>
      </Box>
      <Box sx={{ minHeight: '4px' }}></Box>
      {title2 && clickHandler2 && (
        <Box
          sx={{
            bgcolor: theme.palette.base.white,
            borderRadius: '8px',
            height: '40px',
            minWidth: '150px',
            color: theme.palette.primary.main_white,
            fontSize: '14px',
            fontWeight: '600',
            paddingX: '8px',
            cursor: 'pointer',
          }}
        >
          <Link
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clickHandler2?.();
            }}
            sx={{
              color: theme.palette.primary.main_white,
              fontSize: '14px',
              fontWeight: '600',
              width: '100%',
              height: '100%',
            }}
            className={'boxcenterhv'}
            underline="none"
          >
            {title2}
          </Link>
        </Box>
      )}
    </>
  );
}

function ViewToApplyCampaign() {
  const isMobileView = useBreakpointXs();
  const context = useContext(CreatorContext);
  const campaignId = context.currentDisplayedCampaignId;
  const scriptType = context.currentScriptType;
  const showDialog = !!scriptType || !!campaignId;
  return showDialog ? (
    <DialogPage
      initOpen={showDialog}
      children={
        campaignId ? (
          <CreatorCampaignDetails campaignId={campaignId} />
        ) : scriptType ? (
          <CreatorAIScriptPanel scriptType={scriptType} />
        ) : (
          <></>
        )
      }
      showHeader={!isMobileView}
      showMobileViewPadding={false}
    />
  ) : (
    <></>
  );
}

export default function JobsPage() {
  return (
    <>
      <CreatorContextWrapper>
        <JobsPageWrapperChild />
      </CreatorContextWrapper>
      <PageMobileNavigationBarAndTitleBar />
    </>
  );
}
