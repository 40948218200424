import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { Messages } from '../../localization/Messages';
import { TransitionUp } from '../../components/Transitions';
import { CrossIcon } from '../../assets/imgs/icons';
import { useGlobalStyles } from '../../assets/styles/style';

type DeclineApplicationConfirmationProps = {
  initText: string;
  initOpen: boolean;
  handlePatch: (text: string) => Promise<void>;
  isValidForSave: (text: string) => boolean;
  onCancel: fnVoidToVoid;
};

export function DeclineApplicationConfirmation({
  initText,
  initOpen,
  handlePatch,
  isValidForSave,
  onCancel,
}: DeclineApplicationConfirmationProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const [open, setOpen] = useState(initOpen);

  useEffect(() => {
    setOpen(initOpen);
  }, [initOpen]);

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  useEffect(() => {
    setText(initText);
  }, [initText]);

  const patchApplication = async (newText: string) => {
    setLoading(true);
    await handlePatch(newText);
    setLoading(false);
  };

  return (
    <>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={TransitionUp}
          sx={{
            zIndex: theme.zIndex.modal,
            '& .MuiDialog-container > .MuiPaper-root': {
              backgroundColor: theme.palette.neutral[0],
              backgroundImage: 'none !important',
            },
          }}
        >
          <DialogTitle display={'flex'}>
            <Typography fontWeight="600" fontSize="16px" marginTop="8px">
              {intl.formatMessage(Messages.Confirm)}
            </Typography>
            <Box flexGrow={1}></Box>
            <IconButton
              onClick={() => {
                setOpen(false);
                onCancel();
              }}
            >
              <CrossIcon
                stroke={theme.palette.base.black}
                sx={{ width: '10px', height: '10px' }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack rowGap={'32px'} minWidth="360px">
              <Stack>
                <Typography fontSize="13px" color="netrual.600">
                  {intl.formatMessage(Messages.ReasonOfReject)}
                </Typography>
                <Box minHeight="8px"></Box>
                <Stack rowGap="8px">
                  <textarea
                    style={{
                      border: '1px solid ' + theme.palette.neutral[700],
                      fontSize: '14px',
                    }}
                    rows={6}
                    defaultValue={text}
                    autoFocus
                    onChange={(e) => setText(e.target.value)}
                  ></textarea>
                </Stack>
              </Stack>
              <Stack rowGap={'8px'}>
                <Button
                  className={gs.buttonDeletion}
                  onClick={() => patchApplication(text)}
                  disabled={loading}
                >
                  {intl.formatMessage(Messages.reject)}
                </Button>
                <Button
                  className={`border-radius-8px ${gs.buttonCancellation}`}
                  onClick={() => {
                    setOpen(false);
                    onCancel();
                  }}
                  disabled={loading}
                >
                  {intl.formatMessage(Messages.cancel)}
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
