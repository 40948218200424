import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { useIntl } from 'react-intl';
import TitledPanel from './TitledPanel';
import { PatchApplicationEditButton } from '../pages/PatchApplicationEditButton';
import { useBreakpointXs } from '../../utils/useStyleHooks';

type CreatorCardHorizontalProps = {
  title: string;
  image: string;
  messageToBrand: string;
  budget: string;
  username: string;
  followers: number;
  engagement_rate: number;
  right_to_use: string;
  post_display_duration: string;
  turnaround_time_days: string;
};
export default function CreatorCardHorizontal({
  title,
  image,
  messageToBrand,
  budget,
  username,
  followers,
  engagement_rate,
  right_to_use,
  post_display_duration,
  turnaround_time_days,
}: CreatorCardHorizontalProps) {
  const intl = useIntl();
  const theme = useTheme();
  const isMobileView = useBreakpointXs();
  return (
    <Stack direction={isMobileView ? 'column' : 'row'}>
      <Card
        sx={{
          minWidth: isMobileView ? '100%' : '224px',
          maxWidth: isMobileView ? '100%' : '224px',
          position: 'relative',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(25, 21, 40, 0.04)',
        }}
      >
        <CardMedia
          component="img"
          height={isMobileView ? '100%' : '224'}
          image={image}
          alt="campaign"
        />
      </Card>
      <CardContent
        sx={{
          paddingTop: isMobileView ? '16px' : '4px',
          paddingBottom: '12px !important',
          ...(isMobileView && { paddingLeft: '0px', paddingRight: '0px' }),
        }}
      >
        <Stack direction="row" columnGap="16px" flexWrap={'wrap'}>
          <Typography color="grey.700" fontSize={'18px'} fontWeight={500}>
            {title}
            <br />
            <Typography
              color="grey.50"
              fontSize={'14px'}
              fontWeight={400}
              component="span"
            >
              @{username}
            </Typography>
          </Typography>
          <Box
            sx={{
              width: '1px',
              minHeight: '40px',
              bgcolor: theme.palette.neutral[600],
            }}
          ></Box>
          <Typography
            color="grey.700"
            fontSize={'14px'}
            fontWeight={500}
            marginTop="4px"
          >
            {intl.formatMessage(CreatorMessages.Followers)}
            <br />
            <Typography
              color="grey.50"
              fontSize={'14px'}
              fontWeight={400}
              marginTop={'6px'}
              component="span"
            >
              {intl.formatNumber(followers, {
                notation: 'compact',
                maximumSignificantDigits: 3,
              })}
            </Typography>
          </Typography>
          <Typography
            color="grey.700"
            fontSize={'14px'}
            fontWeight={500}
            marginTop="4px"
          >
            {intl.formatMessage(CreatorMessages.Engagement)}
            <br />
            <Typography
              color="grey.50"
              fontSize={'14px'}
              fontWeight={400}
              marginTop={'6px'}
              component="span"
            >
              {Math.round(engagement_rate * 100)}%
            </Typography>
          </Typography>
        </Stack>
        <TitledPanel
          title={intl.formatMessage(CreatorMessages.MessagesToBrand)}
          children={
            <Typography
              sx={{ whiteSpace: 'pre-line' }}
              color="grey.400"
              fontSize={'14px'}
              fontWeight={400}
            >
              {messageToBrand}
            </Typography>
          }
          gap={'6px'}
          marginTop="20px"
          action={
            <PatchApplicationEditButton
              fieldName="message_to_brand"
              initText={messageToBrand}
            />
          }
        />
        <Stack
          direction={isMobileView ? 'column' : 'row'}
          columnGap={'16px'}
          rowGap={'16px'}
          marginTop="20px"
        >
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Price)}
            children={
              <Typography
                sx={{
                  maxWidth: '300px',
                  height: '20px',
                }}
                className="ellipsis"
                title={budget}
              >
                {budget}
              </Typography>
            }
            gap={'6px'}
            action={
              <PatchApplicationEditButton fieldName="price" initText={budget} />
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.DateToDeliver)}
            children={
              <Stack direction={'row'}>
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {turnaround_time_days.trim() || '-'}
                </Typography>
              </Stack>
            }
            gap={'6px'}
            action={
              <PatchApplicationEditButton
                fieldName="turnaround_time_days"
                initText={turnaround_time_days}
              />
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.HowLongToKeep)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {post_display_duration}
              </Typography>
            }
            gap={'6px'}
            action={
              <PatchApplicationEditButton
                fieldName="post_display_duration"
                initText={post_display_duration}
              />
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.CanBrandHas)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {right_to_use}
              </Typography>
            }
            gap={'6px'}
            action={
              <PatchApplicationEditButton
                fieldName="right_usage"
                initText={right_to_use}
              />
            }
          />
        </Stack>
      </CardContent>
    </Stack>
  );
}
