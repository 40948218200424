import { Box, Container } from '@mui/material';
import NewCampaign from '../campaign/NewCampaign';

function NewCampaignPage() {
  return (
    <Box>
      <Container maxWidth="xl" sx={{ mt: { xs: 2, md: 4 } }}>
        <NewCampaign />
        <Box height="10rem"></Box>
      </Container>
    </Box>
  );
}

export default NewCampaignPage;
