import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { Messages } from '../../localization/Messages';
import { TransitionUp } from '../../components/Transitions';
import { CrossIcon } from '../../assets/imgs/icons';
import { useGlobalStyles } from '../../assets/styles/style';
import { useShippingSupplierOptions } from '../../config/options';
import SortbySelect from '../../components/inputs/SortbySelect';

type EnterTrackingNumberProps = {
  initSupplier: string;
  initText: string;
  initOpen: boolean;
  handlePatch: (text: string, supplier: string) => Promise<boolean>;
  isValidForSave: (text: string) => boolean;
  onCancel: fnVoidToVoid;
};

export function EnterTrackingNumber({
  initSupplier,
  initText,
  initOpen,
  handlePatch,
  isValidForSave,
  onCancel,
}: EnterTrackingNumberProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const [open, setOpen] = useState(initOpen);
  const suppliers = useShippingSupplierOptions();

  useEffect(() => {
    setOpen(initOpen);
  }, [initOpen]);

  const [loading, setLoading] = useState(false);
  const [supplier, setSupplier] = useState('');
  const [text, setText] = useState('');
  useEffect(() => {
    setText(initText);
  }, [initText]);
  useEffect(() => {
    setSupplier(initSupplier);
  }, [initSupplier]);

  const patchApplication = async (newText: string, supplier: string) => {
    setLoading(true);
    const ret = await handlePatch(newText, supplier);
    setLoading(false);
    if (ret) {
      onCancel();
    }
  };

  return (
    <>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={TransitionUp}
          sx={{
            zIndex: theme.zIndex.modal,
            '& .MuiDialog-container > .MuiPaper-root': {
              backgroundColor: theme.palette.neutral[0],
              backgroundImage: 'none !important',
            },
          }}
        >
          <DialogTitle display={'flex'}>
            <Typography fontWeight="600" fontSize="16px" marginTop="8px">
              {intl.formatMessage(Messages.EnterTrackingNumber)}
            </Typography>
            <Box flexGrow={1}></Box>
            <IconButton
              onClick={() => {
                setOpen(false);
                onCancel();
              }}
            >
              <CrossIcon
                stroke={theme.palette.base.black}
                sx={{ width: '10px', height: '10px' }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack rowGap={'32px'} minWidth="360px">
              <Stack>
                <Typography fontSize="13px" color="netrual.600">
                  {intl.formatMessage(Messages.ChooseSupplier)}
                </Typography>
                <Box minHeight="8px"></Box>
                <SortbySelect
                  label={''}
                  data={{
                    options: suppliers.map((v) => ({ name: v.label })),
                    selected: suppliers.findIndex((w) => w.value === supplier),
                  }}
                  onMenuItemChange={(name, index) => {
                    const newSupplier = suppliers[index].value;
                    setSupplier(newSupplier);
                  }}
                  deletable={false}
                  resetFn={() => {
                    setSupplier('');
                  }}
                  selectHeight={'2.5rem'}
                  fullWidth={true}
                  noBorder={true}
                />
                <Box minHeight="8px"></Box>
                <Typography fontSize="13px" color="netrual.600">
                  {intl.formatMessage(Messages.EnterTrackingNoIf)}
                </Typography>
                <Box minHeight="8px"></Box>
                <input
                  style={{
                    border: isValidForSave(text)
                      ? '1px solid transparent'
                      : '1px solid' + theme.palette.danger[500],
                    fontSize: '14px',
                    fontFamily: 'Outfit',
                    height: '48px',
                  }}
                  defaultValue={text}
                  autoFocus
                  onChange={(e) => setText(e.target.value)}
                ></input>
              </Stack>
              <Stack rowGap={'8px'}>
                <Button
                  className={gs.buttonPrimary}
                  onClick={() => patchApplication(text, supplier)}
                  disabled={loading || !isValidForSave(text)}
                >
                  {intl.formatMessage(Messages.Confirm)}
                </Button>
                <Button
                  className={`border-radius-8px ${gs.buttonCancellation}`}
                  onClick={() => {
                    setOpen(false);
                    onCancel();
                  }}
                  disabled={loading}
                >
                  {intl.formatMessage(Messages.cancel)}
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
