import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../../redux/store';
import {
  convertLabelValueTypeArrayToObject,
  RandomId,
} from '../../utils/common';
import { CreatorsFilterT, initCreatorFilter } from './CreatorsFilter';

const initialState: DynamicSearchSettings = {
  platform: 'all',
  searchTerms: '',
  sortType: '',
  showFirst: 'followers',
  creatorsFilter: initCreatorFilter,
  expandedDropdownFilter: '',
  filterType: '',
  // add randomId to avoid the issue that RTK query result cache may bring
  // the gcp image url token expiration problem
  seed: RandomId(),
};

const dynamicSearchSlice = createSlice({
  name: 'dynamicSearch',
  initialState,
  reducers: {
    updateDynamicSearchSetting(
      state,
      action: PayloadAction<{
        setting: DynamicSearchSetting;
        newValue: string;
        seed?: string;
      }>
    ) {
      state[action.payload.setting] = action.payload.newValue;
      state['seed'] = action.payload.seed || state['seed'];
    },
    updateCreatorsFilter(
      state,
      action: PayloadAction<Partial<CreatorsFilterT>>
    ) {
      state.creatorsFilter = { ...state.creatorsFilter, ...action.payload };
    },
    setExpandedDropdownFilter(state, action: PayloadAction<string>) {
      state.expandedDropdownFilter = action.payload;
    },
    setFilterType(state, action: PayloadAction<FilterType>) {
      state.filterType = action.payload;
    },
    setPlatform(state, action: PayloadAction<PlatformType>) {
      state.platform = action.payload;
    },
  },
});

export const {
  updateCreatorsFilter,
  updateDynamicSearchSetting,
  setExpandedDropdownFilter,
  setFilterType,
  setPlatform,
} = dynamicSearchSlice.actions;

export const selectCreatorsFilter = createSelector(
  [(state: RootState) => state.dynamicSearch.creatorsFilter],
  (creatorsFilter) => creatorsFilter
);

export const selectDynamicSearchSetting = createSelector(
  [
    (state: RootState, setting: DynamicSearchSetting) =>
      state.dynamicSearch[setting],
  ],
  (searchSetting) => searchSetting
);

export const selectSortType = createSelector(
  [(state: RootState) => state.dynamicSearch.showFirst],
  (showFirst) => showFirst
);

export const selectShowFirst = createSelector(
  [(state: RootState) => state.dynamicSearch.sortType],
  (sortType) => sortType
);

export type SearchType =
  | 'creators'
  | 'contents'
  | 'products'
  | 'campaigns'
  | 'NOTSET';
export const selectCreatorQuery = createSelector(
  [
    (state: RootState) => state.dynamicSearch.creatorsFilter,
    (state: RootState) => state.dynamicSearch.searchTerms,
    (state: RootState) => state.dynamicSearch.sortType,
    (state: RootState) => state.dynamicSearch.platform,
    (state: RootState) => state.dynamicSearch.seed,
  ],
  (creatorsFilter, searchTerms, sortType, platform, seed) => {
    const {
      audienceLocations,
      commenterLocations,
      creatorLocations,
      engagement,
      creatorType,
      contentCategory,
      avgContentViews,
      creatorFollowers,
      creatorsGender,
      creatorAge,
      creatorRace,
      hasEmail,
      lastPost,
      topics,
      content,
      favorited,
      ismoderated,
      creatorHasEmail,
      _engagementCustom,
      _avgContentViewsCustom,
      _creatorFollowersCustom,
      __audienceAgeMultiple,
      __audienceGenderMultiple,
    } = creatorsFilter;
    const params = {
      ...(searchTerms !== '' && { general_search: searchTerms }),
      ...(audienceLocations.filter((v) => v).length > 0 && {
        audience_geo_countries: audienceLocations.filter((v) => v),
      }),
      ...(commenterLocations.length > 0 && {
        commenterLocations: commenterLocations,
      }),
      ...(creatorLocations.filter((v) => v).length > 0 && {
        geo: creatorLocations.filter((v) => v),
      }),
      ...(engagement === 'all'
        ? {}
        : {
            engagement_rate:
              engagement === 'custom'
                ? _engagementCustom.join('-')
                : engagement,
          }),
      ...(creatorType.length > 0 && { creatorType: creatorType }),
      ...(contentCategory.length > 0 && { contentCategory: contentCategory }),
      ...(__audienceGenderMultiple.length > 0 && {
        audience_genders: convertLabelValueTypeArrayToObject(
          __audienceGenderMultiple,
          parseFloat
        ),
      }),
      ...(avgContentViews !== 'all' && {
        avg_views:
          avgContentViews === 'custom'
            ? _avgContentViewsCustom.join('-')
            : avgContentViews,
      }),
      ...(creatorFollowers !== 'all' && {
        followers:
          creatorFollowers === 'custom'
            ? _creatorFollowersCustom.join('-')
            : creatorFollowers,
      }),
      ...(creatorAge !== 'all' && {
        creatorAge,
      }),
      ...(creatorRace !== 'all' && {
        creatorRace,
      }),
      ...(__audienceAgeMultiple.length > 0 && {
        audience_ages: convertLabelValueTypeArrayToObject(
          __audienceAgeMultiple,
          parseFloat
        ),
      }),
      ...(creatorsGender !== 'all' && {
        gender: creatorsGender,
      }),
      ...(hasEmail !== 'all' && {
        hasEmail: hasEmail === 'true' ? true : false,
      }),
      ...(lastPost !== 'all' && {
        posted_in_recent_days: parseInt(lastPost),
      }),
      ...(topics.length > 0 && {
        topics: topics,
      }),
      ...(content.length > 0 && {
        keywords: content,
      }),
      ...(platform !== 'all' && {
        profile_type: platform,
      }),
      ...(['true', 'false'].includes(favorited) && {
        favorite: favorited === 'true' ? true : false,
      }),
      ...(['true', 'false'].includes(ismoderated) && {
        is_moderated: ismoderated === 'true' ? true : false,
      }),
      ...(['true', 'false'].includes(creatorHasEmail) && {
        creator_has_email: creatorHasEmail === 'true' ? true : false,
      }),
    };
    return `${
      sortType ? `&sort_type=${sortType}` : ''
    }&filters=${encodeURIComponent(JSON.stringify(params))}&seed=${seed}`;
  }
);

export const selectExpandedDropdownFilter = createSelector(
  [(state: RootState) => state.dynamicSearch.expandedDropdownFilter],
  (filterName) => filterName
);

export const selectFilterType = createSelector(
  [(state: RootState) => state.dynamicSearch.filterType],
  (filterType) => filterType
);

type DynamicSearchSetting =
  | 'searchTerms'
  | 'sortType'
  | 'showFirst'
  | 'platform';
type FilterType = 'creator' | 'product' | 'content' | '';

export interface DynamicSearchSettings {
  platform: string;
  searchTerms: string;
  sortType: string;
  showFirst: string;
  creatorsFilter: CreatorsFilterT;
  // this is to record the current droped down filter
  // so we can close other dropped down filters
  expandedDropdownFilter: string;
  filterType: FilterType;
  // add a seed if need to retrigger the query with same filters
  seed: string;
}

export default dynamicSearchSlice.reducer;
