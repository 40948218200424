import { useIntl } from 'react-intl';
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { useGlobalStyles } from '../../assets/styles/style';
import { Messages } from '../../localization/Messages';
import {
  MAXEMAILADDRESSLENGTH,
  ValidateRules,
  VERIFICATIONCODECOUNTDOWNTIMER,
} from '../../utils/common';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { useTypedSelector } from '../../redux/store';
import { selectAuth, setUser } from './AuthSlice';
import { useDispatch } from 'react-redux';
import {
  usePatchEmailMutation,
  useSendEmailVerificationCodeMutation,
} from '../../redux/benaApi';
import { showMessage } from '../../components/ShowMessage';
import SendCodeButton from '../../components/SendCodeButton';
import LightTooltip from '../../components/LightTooltip';
import { useLanguage } from '../../hooks/language';
import { staticURLRoot } from '../../route/urls';
import { useNavigate } from 'react-router-dom';
import LogoAndLanguage from './LogoAndLanguage';
import BenaStepper from '../../components/BenaStepper';
import { useIsRoleCreator } from '../../hooks/account';

type UserEmailType = {
  id: number;
  email: InputDataType;
  verificationCode: InputDataType;
};

type CreatorUpdateEmailProps = {
  handleNext: fnVoidToVoid;
};

function CreatorUpdateEmail({ handleNext }: CreatorUpdateEmailProps) {
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const globalStyles = useGlobalStyles();
  const navigate = useNavigate();
  const user = useTypedSelector((state) => selectAuth(state)).user;

  const [showtTip, setShowTip] = useState(false);

  const initUserData = (email?: string) =>
    ({
      id: 0,
      email: {
        content: email || '',
        maxLength: MAXEMAILADDRESSLENGTH,
        error: false,
        inputValueType: 'email',
      },
      verificationCode: {
        content: '',
        maxLength: 6,
        error: false,
        inputValueType: 'verificationCode',
      },
    } as UserEmailType);

  useEffect(() => {
    user && setFormData(initUserData(user.email || ''));
  }, [user]);

  const [formData, setFormData] = useState<UserEmailType>(initUserData());
  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputDataType,
    key: string
  ) => {
    const str = event.target.value.slice(0, data.maxLength);
    setFormData({
      ...formData,
      [key]: {
        ...data,
        content: str,
        length: event.target.value.length,
        error: !ValidateRules.validInputString(
          str,
          data.maxLength,
          data.inputValueType
        ),
      },
    });
  };

  const refEmailAddress = useRef<HTMLElement>(null);
  const refVerificationCode = useRef<HTMLElement>(null);
  useEffect(() => {
    refEmailAddress.current?.focus();
  }, []);

  const inputList = [
    {
      item: formData.email,
      message: Messages.emailAddress,
      name: 'email',
      refEl: refEmailAddress,
      placeHolder: Messages.enterEmailAddress,
    },
    {
      item: formData.verificationCode,
      message: Messages.verificationCode,
      name: 'verificationCode',
      refEl: refVerificationCode,
      placeHolder: Messages.enter6DigitalCode,
      children: (
        <Box sx={{ mt: 1, mb: 3 }}>
          <LightTooltip title={intl.formatMessage(Messages.checkSpanFolder)}>
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.grey[800],
                position: 'relative',
              }}
              component="span"
              onMouseEnter={() => setShowTip(true)}
              onMouseLeave={() => setShowTip(false)}
            >
              {intl.formatMessage(Messages.didntGetACode)}
            </Typography>
          </LightTooltip>
        </Box>
      ),
    },
  ] as Array<
    FormInputType & {
      startAdornment?: React.ReactElement;
      required?: boolean;
      disabled?: boolean;
    }
  >;

  const isValidForSave = function (
    inputs: typeof inputList,
    saveFormData = true
  ): boolean {
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].required === false) continue;

      const { item: input, refEl: ref, name: key } = inputs[i];
      if (
        !ValidateRules.validInputString(
          input.content,
          input.maxLength,
          input.inputValueType
        )
      ) {
        if (saveFormData) {
          setFormData({
            ...formData,
            [key]: { ...input, error: true },
          });
          ref.current?.focus();
        }
        return false;
      }
    }

    return saveFormData ? true : !submitting;
  };
  const [submitting, setSubmitting] = useState(false);

  const [sendEmailVerificationCode, sendCodeResult] =
    useSendEmailVerificationCodeMutation();
  const lang = useLanguage();

  const [emailExist, setEmailExist] = useState(false);
  // if email exists after get code verification, stop countdown
  useEffect(() => {
    if (emailExist) {
      setCountDown(0);
      setSubmitting(false);
    }
  }, [emailExist]);

  const handleGetVerificationCode = async () => {
    if (isValidForSave(inputList.slice(0, 1))) {
      setSubmitting(true);
      setEmailExist(false);
      await MutationHandler(
        {
          intl,
          action: async () =>
            await sendEmailVerificationCode({
              email: formData.email.content,
              language: lang,
              existing_user: false,
            }).unwrap(),
          onException: (statusCode) => {
            statusCode === 4009 && setEmailExist(true);
          },
        },
        Messages.showMessageSendCodeSuccess
      );
      setCountDown(VERIFICATIONCODECOUNTDOWNTIMER);
    }
  };

  const [patchEmail] = usePatchEmailMutation();
  const handleContinue = async () => {
    if (isValidForSave(inputList)) {
      setSubmitting(true);
      const ret = await MutationHandler({
        intl,
        action: async () => {
          return await patchEmail({
            email: formData.email.content,
            code: formData.verificationCode.content,
          }).unwrap();
        },
        onSuccess: (data) => {
          dispatch(setUser(data));
          showMessage.success(intl.formatMessage(Messages.successfullySaved));
          handleNext();
        },
      });
      if (ret) {
        setSubmitting(false);
        return false;
      }
      return true;
    }
    setSubmitting(false);
    return false;
  };

  const [countDown, setCountDown] = useState<number>(0);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countDown > 0) {
      timer = setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [countDown]);

  // wait 10 seconds after click send verification code
  useEffect(() => {
    countDown <= 50 && setSubmitting(false);
  }, [countDown, setSubmitting]);

  return (
    <Box sx={{ ml: { xs: 0 }, pt: 1 }}>
      <Box className="form-width">
        <form>
          {inputList.map((data) => {
            const {
              item,
              message,
              name,
              refEl,
              placeHolder,
              children,
              startAdornment,
              required,
              disabled,
            } = data;
            return (
              <Box
                key={message.id}
                className={'boxcenterhv'}
                sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Typography
                  variant="caption"
                  sx={{ mb: 1.75, color: theme.palette.grey[800] }}
                  component="div"
                  className={'boxcenterhv'}
                >
                  {intl.formatMessage(message)}
                  {required && (
                    <Box className={'boxcenterhv'}>
                      <FiberManualRecordIcon
                        htmlColor={theme.palette.danger[500]}
                        sx={{ fontSize: '0.5rem', ml: 1 }}
                      />
                    </Box>
                  )}
                </Typography>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <OutlinedInput
                    fullWidth
                    disabled={disabled}
                    placeholder={intl.formatMessage(placeHolder)}
                    inputRef={refEl}
                    sx={{
                      mb: children ? 2 : 3,
                      ...(name === 'verificationCode'
                        ? {
                            '&>input': {
                              letterSpacing: '0.25rem',

                              fontSize: '0.875rem',
                              fontWeight: 'bold',
                            },
                          }
                        : {}),
                    }}
                    className={`border-radius-6px input-size `}
                    id={message.id}
                    value={item.content}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleTextInputChange(event, item, name);
                    }}
                    endAdornment={
                      !disabled && (
                        <InputAdornment position="end">
                          {children ? (
                            <SendCodeButton
                              countDown={countDown}
                              onClick={() =>
                                !submitting && handleGetVerificationCode()
                              }
                              message={Messages.sendCode}
                              countDownMessage={Messages.resendCodeIn}
                              timeUnit={Messages.textSecondInAbbr}
                              isSending={sendCodeResult.isLoading}
                            />
                          ) : (
                            <Typography>
                              {`${item.content.length} / ${item.maxLength}`}
                            </Typography>
                          )}
                        </InputAdornment>
                      )
                    }
                    error={item.error}
                    startAdornment={startAdornment}
                  />
                  {children}
                </FormControl>
              </Box>
            );
          })}
          <Box>
            <Button
              type="submit"
              className={`border-radius-6px button-size ${globalStyles.buttonPrimary}`}
              onClick={(e) => {
                e.preventDefault();
                handleContinue();
              }}
              disabled={!isValidForSave(inputList, false) || submitting}
            >
              {intl.formatMessage(Messages.Next)}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

type RegisterSuccessProps = {
  handleBack: fnVoidToVoid;
  handleNext: fnVoidToVoid;
};

export function RegisterSuccess({
  handleBack,
  handleNext,
}: RegisterSuccessProps) {
  const intl = useIntl();
  const theme = useTheme();
  const globalStyles = useGlobalStyles();

  return (
    <Box sx={{ width: '100%' }}>
      <Box className="form-width">
        <Box sx={{ mb: '0.5rem' }}>
          <Typography sx={{ fontSize: '1.5rem' }} fontWeight="bold">
            {intl.formatMessage(Messages.registerSuccess)}
          </Typography>
        </Box>
        <Box sx={{ mb: '3rem' }}>
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 400,
              color: theme.custom.gray,
            }}
          >
            {intl.formatMessage(Messages.registerSuccessTip)}
          </Typography>
        </Box>
        <form>
          <Box className="boxcenterhv" sx={{ mt: '2.5rem', width: '100%' }}>
            <Button
              type="submit"
              className={`border-radius-6px button-size ${globalStyles.buttonCancellation}`}
              onClick={(e) => {
                e.preventDefault();
                handleNext();
              }}
              sx={{ width: '100%' }}
            >
              {intl.formatMessage(Messages.startCollaboration)}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

type RegisterCreatorProfileProps = {
  nofityLastStep?: fnVoidToVoid;
  notifyCurrentStep?: fnNumberToVoid;
};
export default function RegisterCreatorProfile({
  nofityLastStep,
  notifyCurrentStep,
}: RegisterCreatorProfileProps) {
  const navigate = useNavigate();
  const [currentStep, _setCurrentStep] = useState(0);
  const setCurrentStep = (step: number) => {
    notifyCurrentStep && notifyCurrentStep(step);
    _setCurrentStep(step);
  };

  const step1 = <CreatorUpdateEmail handleNext={() => setCurrentStep(1)} />;
  const step2 = (
    <RegisterSuccess
      handleBack={() => {
        return;
      }}
      handleNext={() => {
        navigate(staticURLRoot);
      }}
    />
  );

  const hasEmail = !!useTypedSelector((state) => selectAuth(state)).user?.email;
  const isCreator = useIsRoleCreator();
  useEffect(() => {
    if (!isCreator || hasEmail) {
      navigate(staticURLRoot);
    }
  }, []);

  const steps = [step1, step2];
  const TotalSteps = 2;

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <LogoAndLanguage />
      <Box sx={{ mt: 6.75 }} className="form-width">
        <BenaStepper
          currentStep={Math.min(1, currentStep)}
          additionalStep={currentStep === TotalSteps}
          totalSteps={TotalSteps}
          onStepClick={() => {
            return;
          }}
        />
        <Box sx={{ width: '100%', my: '3rem' }}>{steps[currentStep]}</Box>
      </Box>
    </Box>
  );
}
