const elId = '____internal_debug_info____';

export function degubLogToDocument(log: string) {
  try {
    let el = document.getElementById(elId);
    if (!el) {
      el = document.createElement('div');
      el.id = elId;
      el.style.display = 'none';
      document.body.appendChild(el);
    }
    el.innerText = el.innerText + log;
  } catch (e) {
    console.log(e, log);
  }
}
