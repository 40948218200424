import {
  Box,
  Chip,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputBase,
  Link,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { getPlatformIcon } from '../../utils/utils';
import { Messages } from '../../localization/Messages';
import { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import Color from 'color';
import { useThemeMode } from '../../hooks/theme';
import Chart from '../../components/charts/chart';
import {
  LocationOptions,
  NumberAndPercentLineChartOptions,
  PieChartOptions,
  PieChartRaceOptions,
} from '../../components/charts/ChartOptions';
import { enterKeyPressed, formatDate } from '../../utils/common';
import {
  useGetApplicationQuery,
  usePatchCreatorsMediaKitMutation,
} from '../../redux/benaApi';
import { ApplicationContext } from '../context/context';
import { CountryCodeName, useCountryNameCodeMap } from '../../utils/countries';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { TitledSelect } from '../../components/TitledSelect';
import { createStyles, makeStyles } from '@mui/styles';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { CheckIcon, CrossLineIcon } from '../../assets/imgs/icons';
import { useGlobalStyles } from '../../assets/styles/style';
import { useAuthUserId, useIsRoleBrand } from '../../hooks/account';
// import BubbleChart from 'react-tooltip-bubble-chart';
import { useChartCommonConfig } from '../../components/charts/common';
import { useContentStylesOptions } from '../../config/options';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { PatchApplicationEditButton } from './PatchApplicationEditButton';
import LightTooltip from '../../components/LightTooltip';
import { staticURLChangeEmail } from '../../route/urls';
import { AddPromotedBrand, PromotedBrands } from '../features/AddPromotedBrand';

function NumberOrNull(v?: number | null) {
  return typeof v === 'number' ? v : null;
}

function mapStatisticsItemTypeToStatisticsItemProps(
  title: string,
  v?: StatisticsItemType,
  isPercent?: boolean
) {
  let levelText = '';
  switch (v?.level) {
    case 1:
      levelText = 'fair';
      break;
    case 2:
      levelText = 'average';
      break;
    case 3:
      levelText = 'good';
      break;
    case 4:
      levelText = 'excellent';
      break;
  }
  return {
    title: title,
    value: NumberOrNull(v?.value),
    increasePercent: NumberOrNull(v?.increase_percent),
    pastDays: NumberOrNull(v?.past_days),
    level: levelText,
    isPercent: !!isPercent,
  };
}

type CreatorMediaKitProps = { applicationId?: string };
export function CreatorMediaKit({ applicationId }: CreatorMediaKitProps) {
  const intl = useIntl();
  const theme = useTheme();
  const userId = useAuthUserId();
  const context = useContext(ApplicationContext);
  const isMobileView = useBreakpointXs();
  const countriesCodes = useCountryNameCodeMap();
  const chartConfig = useChartCommonConfig();
  const [_patchMediaKit] = usePatchCreatorsMediaKitMutation();
  const { data, refetch } = useGetApplicationQuery({
    application_id: applicationId || context.applicationId,
  });
  const applicationData = data?.data;

  const editMode = applicationData?.user_id === userId;

  const {
    picture,
    name,
    geo: location,
    email,
    phone,
    platforms,
    content_style,
  } = applicationData?.creator || {
    picture: '',
    name: '',
    location: '',
    email: '',
    phone: '',
    platforms: [] as PlatformItemType[],
    profile: {},
    content_style: [],
  };

  const {
    followers,
    likes,
    engagement_rate,
    avg_views,
    avg_likes,
    avg_comments,
    avg_shares,
    videos_180_days,
  } = applicationData?.creator?.statistics || {};
  const StatItems: StatisticsItemProps[] = useMemo(
    () => [
      mapStatisticsItemTypeToStatisticsItemProps('Followers', followers),
      mapStatisticsItemTypeToStatisticsItemProps('Likes', likes),
      mapStatisticsItemTypeToStatisticsItemProps(
        'Engagement rate',
        engagement_rate,
        true
      ),
      mapStatisticsItemTypeToStatisticsItemProps(
        'Average post views',
        avg_views
      ),
      mapStatisticsItemTypeToStatisticsItemProps(
        'Average post comments',
        avg_comments
      ),
      mapStatisticsItemTypeToStatisticsItemProps(
        'Average post likes',
        avg_likes
      ),
      mapStatisticsItemTypeToStatisticsItemProps(
        'Average post shares',
        avg_shares
      ),
      mapStatisticsItemTypeToStatisticsItemProps(
        'Videos in past 180 days',
        videos_180_days
      ),
    ],
    [
      avg_comments,
      avg_likes,
      avg_shares,
      avg_views,
      engagement_rate,
      followers,
      likes,
      videos_180_days,
    ]
  );
  const brands = applicationData?.creator?.statistics?.promoted_brands || [];
  const contentStyles = content_style || [];

  const chartData1 = useMemo(
    () =>
      (applicationData?.creator?.statistics?.chart_avg_content_view || []).map(
        (v) =>
          [formatDate(new Date(v.date * 1000) || ''), v.value] as [
            string,
            number
          ]
      ),
    [applicationData?.creator?.statistics?.chart_avg_content_view]
  );
  const chartData2 = useMemo(
    () =>
      (applicationData?.creator?.statistics?.chart_engagement_rate || []).map(
        (v) =>
          [
            formatDate(new Date(v.date * 1000) || ''),
            (Math.floor(1000 * v.value) / 1000) * 100,
          ] as [string, number]
      ),
    [applicationData?.creator?.statistics?.chart_engagement_rate]
  );

  const genderData = (
    applicationData?.creator?.statistics?.chart_gender || []
  ).map((w) => ({
    name: w.name,
    value: Math.floor(w.value * 100),
  }));

  const raceData = useMemo(
    () =>
      (applicationData?.creator?.statistics?.chart_race || [])
        .map((w) => ({
          label: w.label
            .split(/[\s_-]/)
            .map((w) => capitalize(w))
            .join(' '),
          value: Math.floor(w.value * 100),
          key: w.label,
        }))
        .sort((a, b) => (a.value > b.value ? -1 : 1)),
    [applicationData?.creator?.statistics?.chart_race]
  );

  const top_countries = useMemo(
    () =>
      (applicationData?.creator?.statistics?.chart_top_countries || [])
        .map((w) => ({
          name: w.country_name,
          country_code: countriesCodes[w.country_name],
          value: Math.floor(w.value * 100),
        }))
        .sort((a, b) => (a.value > b.value ? -1 : 1)),
    [applicationData]
  );

  const chartWidthNarrow = { width: '1068px', height: '160px' };
  const tiltCSS = useMemo(
    () => ({
      fontSize: '18px',
      fontWeight: '500',
      color: 'grey.700',
      marginTop: '8px',
    }),
    []
  );

  const patchMediaKit = async (body: any) => {
    let ret = false;
    await MutationHandler({
      intl,
      action: async () => {
        return await _patchMediaKit(body).unwrap();
      },
      onSuccess: () => {
        refetch();
        ret = true;
      },
    });
    return ret;
  };
  const patchContentStyle = (value: string, remove?: boolean) => {
    let data = contentStyles.filter((w) => w !== value);
    data = remove ? data : data.concat(value);
    patchMediaKit({ content_style: data });
  };

  const patchBrands = async (name: string, link: string, remove?: boolean) => {
    let data = brands.filter((w) => w.name !== name);
    data = remove ? data : data.concat({ name, link });
    return patchMediaKit({ promoted_brands: data });
  };

  const geo_countries = useMemo(() => {
    const list =
      applicationData?.creator?.statistics?.chart_top_countries || [];
    const ret = {} as { [key: string]: number };
    list.forEach((v) => (ret[v.country_name] = v.value));
    return ret;
  }, [applicationData]);

  const race = useMemo(() => {
    const list = applicationData?.creator?.statistics?.chart_race || [];
    const ret = {} as { [key: string]: number };
    list.forEach((v) => (ret[v.label] = v.value));
    return ret;
  }, [applicationData?.creator?.statistics?.chart_race]);

  const genders = useMemo(() => {
    const list = applicationData?.creator?.statistics?.chart_gender || [];
    const ret = {} as { [key: string]: number };
    list.forEach((v) => (ret[v.name] = v.value));
    return ret;
  }, [applicationData?.creator?.statistics?.chart_gender]);

  const handlePatchGender = async (field: string, text: string) => {
    const body = { genders: { ...genders } };
    body.genders[field] = parseFloat(text) / 100;
    const ret = await patchMediaKit(body);
    if (ret) {
      refetch();
    }
    return ret;
  };

  const handlePatchGeo = async (field: string, text: string) => {
    const countryName = CountryCodeName[field] || field;
    const body = { geo_countries: { ...geo_countries } };
    body.geo_countries[countryName] = parseFloat(text) / 100;
    const ret = await patchMediaKit(body);
    if (ret) {
      refetch();
    }
    return ret;
  };

  const handlePatchRace = async (field: string, text: string) => {
    const body = { race: { ...race } };
    body.race[field] = parseFloat(text) / 100;
    const ret = await patchMediaKit(body);
    if (ret) {
      refetch();
    }
    return ret;
  };

  const isValidChartDataForSave = (text: string) => {
    try {
      const val = parseFloat(text);
      return val <= 100 && val >= 0;
    } catch (e) {
      return false;
    }
  };

  return (
    <Stack
      direction="column"
      padding={isMobileView ? '0px' : '16px'}
      border={isMobileView ? '0px' : `1px solid ${theme.palette.neutral[600]}`}
      bgcolor={isMobileView ? 'transparent' : theme.palette.neutral[25]}
      rowGap="16px"
      borderRadius="8px"
      width="100%"
    >
      <Stack
        direction={isMobileView ? 'column' : 'row'}
        columnGap={'24px'}
        rowGap={'16px'}
      >
        <img
          src={picture}
          style={{
            width: isMobileView ? '343px' : '224px',
            height: isMobileView ? '343px' : '224px',
            borderRadius: '10px',
          }}
          alt="avatar"
        />
        <Stack>
          <Typography fontSize={'18px'} fontWeight="500" color="grey.700">
            {name?.[0] === '@' ? name : '@' + name}
          </Typography>
          <Typography
            fontSize={'14px'}
            fontWeight="400"
            color="base.black"
            marginTop="5px"
          >
            <img
              src="/imgs/location-black.svg"
              style={{ width: '10px', height: '12px' }}
              alt="location"
            />{' '}
            {location}
          </Typography>
          <Stack
            direction="row"
            flexWrap={'wrap'}
            rowGap="24px"
            columnGap="24px"
            marginTop="10px"
          >
            {(platforms || []).map((w) => (
              <SocialPlatformBox socialPlatform={w} key={w.platform} />
            ))}
          </Stack>
          <Stack
            direction="row"
            flexWrap={'wrap'}
            rowGap="24px"
            columnGap="24px"
            marginTop="16px"
          >
            {editMode && (
              <Stack direction="row">
                <PersonalInfo text={email || '-'} img="/imgs/email-black.svg" />
                <Link
                  underline="none"
                  href={staticURLChangeEmail}
                  target="_blank"
                  className="boxcenterhv"
                >
                  <IconButton size="small" disableRipple>
                    <Typography
                      fontSize={'14px'}
                      fontWeight="600"
                      color="primary.main"
                    >
                      {intl.formatMessage(Messages.Edit)}
                    </Typography>
                  </IconButton>
                </Link>
              </Stack>
            )}
            {!editMode && !!email && (
              <PersonalInfo text={email || '-'} img="/imgs/email-black.svg" />
            )}
            {editMode && (
              <Stack direction="row">
                <PersonalInfo text={phone || '-'} img="imgs/tel-black.svg" />
                <PatchApplicationEditButton
                  fieldName="phone"
                  initText={phone}
                />
              </Stack>
            )}
            {!editMode && !!phone && (
              <PersonalInfo text={phone || '-'} img="imgs/tel-black.svg" />
            )}
          </Stack>
        </Stack>
      </Stack>
      <Typography {...tiltCSS}>
        {intl.formatMessage(Messages.CreatorStatistics)}
      </Typography>
      <Stack
        direction={'row'}
        columnGap={'24px'}
        rowGap={'12px'}
        flexWrap={'wrap'}
      >
        {StatItems.filter(
          (w) => typeof w.value === 'number' && w.value !== 0
        ).map((w, index) => (
          <StatisticsItem {...w} key={index} />
        ))}
      </Stack>
      <Typography {...tiltCSS}>
        {intl.formatMessage(Messages.AvgContentViewsAndEngagement)}
      </Typography>
      <div style={{ width: '100%' }}>
        <Chart
          key="viewsAvgPerPost"
          option={NumberAndPercentLineChartOptions(chartData1, chartData2)}
          style={
            isMobileView ? { width: '100%', height: '168px' } : chartWidthNarrow
          }
        />
      </div>
      <Typography {...tiltCSS}>
        {intl.formatMessage(Messages.FollowerDemographics)}
      </Typography>
      <Stack direction="row" columnGap="32px" rowGap="32px" flexWrap="wrap">
        <Box position="relative" sx={{ width: '280px', height: '140px' }}>
          <Chart
            key="genderRatio"
            option={PieChartOptions(genderData, false)}
            style={{
              width: '280px',
              height: '140px',
            }}
          />
          <Box position="absolute" right="20px" top="0px" zIndex={1}>
            <CustomizeLegend
              colors={chartConfig.pieChart}
              items={genderData.map((w) => ({
                key: w.name,
                label: capitalize(w.name),
                value: w.value,
              }))}
              onUpdate={handlePatchGender}
              validateFn={isValidChartDataForSave}
              allowEdit={editMode}
            />
          </Box>
        </Box>
        <RatioScatterChart
          data={raceData}
          pieCharSize={{
            width: 321,
            height: 140,
          }}
          onUpdate={handlePatchRace}
          isValidChartDataForSave={isValidChartDataForSave}
          allowEdit={editMode}
        />
        <Box
          position="relative"
          sx={{
            width: isMobileView ? 'calc(100% - 32px)' : '400px',
            height: isMobileView ? '180px' : '140px',
          }}
        >
          {top_countries.length > 0 && (
            <LocationChart
              top_countries={top_countries.filter((w) => w.value > 0)}
              chartSize={{
                width: isMobileView ? 'calc(100% - 32px)' : '400px',
                height: '140px',
              }}
            />
          )}
          <Box position="absolute" right="0px" top="0px">
            <CustomizeLegend
              colors={chartConfig.geoChart}
              items={top_countries.slice(0, 10).map((w) => ({
                key: w.country_code,
                label: w.name,
                value: w.value,
              }))}
              onUpdate={handlePatchGeo}
              validateFn={isValidChartDataForSave}
              allowEdit={editMode}
            />
          </Box>
        </Box>
      </Stack>
      {!editMode && top_countries.length > 8 && <Box height="16px"></Box>}
      <>
        {((!editMode && contentStyles.length > 0) || editMode) && (
          <Typography {...tiltCSS}>
            {intl.formatMessage(Messages.ContentStyle)}
          </Typography>
        )}
        {editMode && (
          <PickContentStyle
            setSelectedContentType={(value) => {
              patchContentStyle(value);
            }}
            selectedValues={contentStyles}
          />
        )}
        {contentStyles.length > 0 && (
          <Stack direction="row" columnGap={'24px'}>
            <ContentStyles
              contentStyles={contentStyles}
              onDelete={
                editMode
                  ? (value) => {
                      patchContentStyle(value, true);
                    }
                  : undefined
              }
            />
          </Stack>
        )}
      </>
      {
        <>
          {((!editMode && brands.length > 0) || editMode) && (
            <Typography {...tiltCSS}>
              {intl.formatMessage(Messages.PromotedBrands)}
            </Typography>
          )}
          {editMode && (
            <AddPromotedBrand
              onAdd={async (name, link) => {
                return await patchBrands(name, link);
              }}
            />
          )}
          {brands.length > 0 && (
            <PromotedBrands
              brands={brands}
              onDelete={
                editMode
                  ? (name) => {
                      patchBrands(name, '', true);
                    }
                  : undefined
              }
            />
          )}
        </>
      }
    </Stack>
  );
}

type SocialPlatformBoxProps = {
  socialPlatform: PlatformItemType;
};
function SocialPlatformBox({ socialPlatform }: SocialPlatformBoxProps) {
  const intl = useIntl();
  const theme = useTheme();
  const { id, platform, followers } = socialPlatform;
  const img = getPlatformIcon(platform);
  return (
    <Box
      padding="8px 24px 8px"
      sx={{
        backgroundImage: `url(${img})`,
        backgroundSize: '12px 12px',
        backgroundPosition: '8px 13px',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.neutral[400],
        borderRadius: '8px',
      }}
    >
      <Typography fontSize={'14px'} fontWeight="400" color="grey.700">
        {id}
      </Typography>
      <Typography fontSize={'14px'} fontWeight="400" color="grey.50">
        {intl.formatNumber(followers, { notation: 'compact' })}{' '}
        {intl.formatMessage(Messages.followers)}
      </Typography>
    </Box>
  );
}

type PersonalInfoProps = {
  text: string;
  img: string;
};
function PersonalInfo({ text, img }: PersonalInfoProps) {
  const theme = useTheme();
  return (
    <Box
      padding="8px 24px 8px"
      sx={{
        backgroundImage: `url(${img})`,
        backgroundSize: '12px 12px',
        backgroundPosition: '8px 13px',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.neutral[400],
        borderRadius: '8px',
      }}
    >
      <Typography fontSize={'14px'} fontWeight="400" color="grey.700">
        {text}
      </Typography>
    </Box>
  );
}

type StatisticsItemProps = {
  title: string;
  value: number | null;
  increasePercent: number | null;
  pastDays: number | null;
  level: string;
  isPercent: boolean;
};
function StatisticsItem({
  title,
  value,
  increasePercent,
  pastDays,
  level,
  isPercent,
}: StatisticsItemProps) {
  const theme = useTheme();
  const intl = useIntl();
  const isBrand = useIsRoleBrand();
  const colors = useMemo(
    () => ({
      fair: theme.palette.neutral[800],
      average: theme.palette.warning[700],
      good: theme.palette.primary[700],
      excellent: theme.palette.success[800],
      bgfair: theme.palette.neutral[25],
      bgaverage: theme.palette.warning[100],
      bggood: theme.palette.primary[100],
      bgexcellent: theme.palette.success[100],
    }),
    []
  );
  const level_bgcolor =
    colors[('bg' + level) as keyof typeof colors] || 'rgba(0,0,0,0)';
  const level_fontcolor =
    colors[level as keyof typeof colors] || 'rgba(0,0,0,0)';
  return (
    <Stack
      direction="column"
      padding="4px 8px"
      bgcolor={theme.palette.neutral[200]}
      rowGap="2px"
    >
      <Typography fontSize={'16px'} fontWeight="500" color="grey.100">
        {title}
      </Typography>
      <Stack direction={'row'} columnGap="4px" alignItems={'center'}>
        <Typography fontSize={'20px'} fontWeight="500" color="grey.500_700">
          {value &&
            (isPercent
              ? intl.formatNumber(value, {
                  style: 'percent',
                  maximumFractionDigits: 2,
                })
              : intl.formatNumber(value, { notation: 'compact' }))}
        </Typography>{' '}
        {isBrand && (
          <Typography
            fontSize={'13px'}
            fontWeight="500"
            bgcolor={level_bgcolor}
            borderRadius="4px"
            color={level_fontcolor}
            paddingLeft="4px"
            paddingRight="4px"
            height="20px"
          >
            <span
              style={{
                display: 'inline-block',
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: level_fontcolor,
              }}
            ></span>{' '}
            {capitalize(level)}
          </Typography>
        )}
      </Stack>
      {typeof increasePercent === 'number' && pastDays && (
        <Stack direction={'row'} columnGap="8px">
          <Typography
            fontSize={'13px'}
            fontWeight="500"
            color={increasePercent > 0 ? 'success.500' : 'warning.700'}
          >
            {increasePercent > 0 ? '+' : ''}
            {intl.formatNumber(increasePercent, {
              style: 'percent',
              maximumFractionDigits: 2,
            })}
          </Typography>{' '}
          <Typography fontSize={'13px'} fontWeight="400" color="neutral.800">
            past {pastDays} day{pastDays > 1 ? 's' : ''}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

const bgcolors = (theme: Theme) => {
  return '01234'
    .split('')
    .map((v) =>
      Color(theme.custom[(`forte` + v) as keyof typeof theme.custom]).toString()
    );
};
const bgcolorsNofade = (theme: Theme) => {
  return '01234'
    .split('')
    .map((v) =>
      Color(theme.custom[(`forte` + v) as keyof typeof theme.custom]).toString()
    );
};
type ContentStylesProps = {
  contentStyles: string[];
  onDelete?: fnStringToVoid;
};
function ContentStyles({ contentStyles, onDelete }: ContentStylesProps) {
  const theme = useTheme();
  const isMobileView = useBreakpointXs();
  const isDarkMode = useThemeMode() === 'dark';
  const bgcolor = (index: number) =>
    isDarkMode
      ? Color(bgcolors(theme)[index % 5])
          .alpha(0.2)
          .toString()
      : bgcolors(theme)[index % 5];
  const color = (index: number) =>
    isDarkMode ? bgcolorsNofade(theme)[index % 5] : theme.palette.primary[950];
  const stylesOptions = useContentStylesOptions();
  return contentStyles.length > 0 ? (
    <Stack
      rowGap={isMobileView ? '1rem' : '0.5rem'}
      columnGap={isMobileView ? '1rem' : '0.5rem'}
      direction="row"
      flexWrap={'wrap'}
      marginTop="0.5rem"
      component={'span'}
    >
      {contentStyles.map((v, index) => (
        <Chip
          label={capitalize(
            stylesOptions.find((w) => w.value === v)?.label || v
          )}
          key={`${v}${index}`}
          component="span"
          sx={{
            backgroundColor: bgcolor(index),
            '& .MuiChip-label': {
              color: color(index),
              fontWeight: 600,
              fontSize: '12px',
            },
            height: '22px',
          }}
          {...(onDelete && {
            deleteIcon: <CrossLineIcon sx={{ width: '8px', height: '8px' }} />,
            onDelete: () => onDelete(v),
          })}
        ></Chip>
      ))}
    </Stack>
  ) : (
    <>-</>
  );
}

type RatioScatterChartProps = {
  data: LabelKeyValueArray;
  pieCharSize: { width: number; height: number };
  onUpdate: (field: string, text: string) => Promise<boolean>;
  isValidChartDataForSave: (text: string) => boolean;
  allowEdit: boolean;
};

function RatioScatterChart({
  data,
  pieCharSize,
  onUpdate,
  isValidChartDataForSave,
  allowEdit,
}: RatioScatterChartProps) {
  const colors = useChartCommonConfig().colorRace;
  const isMobileView = useBreakpointXs();
  // const bubbleData = data.map((w, index) => ({
  //   fillColor: colors[index % colors.length],
  //   borderColor: 'white',
  //   id: index,
  //   name: `${w.value}%`,
  //   size: w.value,
  // }));
  const chartData = useMemo(
    () => data.map((w) => ({ value: w.value, name: w.label })),
    [data]
  );
  const ret = (
    <Box
      sx={{
        '& svg': { overflow: 'visible', scale: isMobileView ? '1' : '1.625' },
        '& svg tspan': {
          fontWeight: '700',
        },
      }}
      position="relative"
    >
      {
        //TODO bubblechart can not display in brand role
        /*<BubbleChart
        bubblesData={bubbleData}
        width={pieCharSize.width}
        height={pieCharSize.height}
        textFillColor="white"
        backgroundColor="transparent"
        minValue={0}
        maxValue={100}
        move={true}
      /> */
      }
      <Chart
        key="RaceRatio"
        option={PieChartRaceOptions(chartData, false)}
        style={{
          width: '320px',
          height: '140px',
        }}
      />
      <Box
        position="absolute"
        right="0px"
        top="0px"
        sx={{
          '& svg': { scale: '1 !important' },
        }}
      >
        <CustomizeLegend
          colors={colors}
          items={data}
          onUpdate={onUpdate}
          validateFn={isValidChartDataForSave}
          allowEdit={allowEdit}
        />
      </Box>
    </Box>
  );
  return ret;
}

type LocationChartProps = {
  top_countries: LocationCountryItem[];
  chartSize: { width: string; height: string };
  onClickHandler?: (params: any) => void;
};
function LocationChart({
  top_countries,
  chartSize,
  onClickHandler,
}: LocationChartProps) {
  const [geoData, setGeoData] = useState<any>(null);
  useEffect(() => {
    fetch('/world.json')
      .then((res) => res.json())
      .then((res) => {
        setGeoData(res);
      });
  }, []);
  return (
    <Suspense fallback={<CircularProgress size={8} />}>
      <Chart
        key="Location"
        option={LocationOptions(
          {
            top_countries: top_countries.slice(0, 6),
          },
          false
        )}
        style={chartSize}
        loading={geoData ? false : true}
        geoData={geoData}
        isMap={true}
        onClickHandler={onClickHandler}
      />
    </Suspense>
  );
}

type PickContentStyleProps = {
  setSelectedContentType: fnStringToVoid;
  selectedValues: string[];
};
function PickContentStyle({
  setSelectedContentType,
  selectedValues,
}: PickContentStyleProps) {
  const intl = useIntl();
  const style = useStyles();
  const _stylesOptions = useContentStylesOptions();
  const options = useMemo(
    () =>
      _stylesOptions
        .map((w) => ({ name: w.label, value: w.value }))
        .filter((w) => !selectedValues.find((x) => w.value === x)),
    [selectedValues]
  );
  return (
    <Stack>
      <Typography
        fontSize="14px"
        color="grey.700"
        fontWeight="500"
        marginBottom="8px"
      >
        {intl.formatMessage(CreatorMessages.PickYourDesiredContentStyle)}
      </Typography>
      <Stack direction={'row'}>
        <Box className={style.contentStyleBox}>
          <TitledSelect
            id={'contentstyle'}
            title={intl.formatMessage(Messages.ContentStyles)}
            value={''}
            setValue={setSelectedContentType}
            options={options}
            clearable={false}
            className={style.contentStyle}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

type CustomizeLegendProps = {
  items: LabelKeyValueArray;
  colors: string[];
  onUpdate: (field: string, text: string) => Promise<boolean>;
  validateFn: (text: string) => boolean;
  allowEdit: boolean;
};
function CustomizeLegend({
  items,
  colors,
  onUpdate,
  validateFn,
  allowEdit,
}: CustomizeLegendProps) {
  const theme = useTheme();
  const intl = useIntl();
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({ value: '', key: '' });
  const handleUpdate = async () => {
    if (validateFn?.(editData.value)) {
      (await onUpdate(editData.key, editData.value)) && setEditMode(false);
    }
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        setEditMode(false);
      }}
    >
      <Stack rowGap={'4px'}>
        {items.map((w, index) => (
          <Stack direction="row" alignItems={'center'} key={`${index}${w.key}`}>
            <Box
              sx={{
                width: '10px',
                height: '10px',
                backgroundColor: colors[index],
              }}
              marginRight={'6px'}
              borderRadius={'3px'}
            ></Box>
            <Box position="relative">
              <Typography
                color="grey.400"
                fontSize="13px"
                fontWeight={600}
                onClick={() => {
                  setEditMode(true);
                  setEditData({ key: w.key, value: w.value.toString() });
                }}
              >
                {allowEdit ? (
                  <LightTooltip
                    title={intl.formatMessage(Messages.ClickTheNumbersToEdit)}
                    placement="top"
                  >
                    <span
                      style={{
                        backgroundColor: allowEdit
                          ? theme.palette.primary['50_black']
                          : 'transparent',
                      }}
                    >
                      {w.value.toFixed(0)}%
                    </span>
                  </LightTooltip>
                ) : (
                  <span
                    style={{
                      backgroundColor: allowEdit
                        ? theme.palette.primary['50_black']
                        : 'transparent',
                    }}
                  >
                    {w.value.toFixed(0)}%
                  </span>
                )}{' '}
                {w.label}
              </Typography>
              {allowEdit && editMode && editData.key === w.key && (
                <Stack
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: -1,
                    backgroundColor: 'white',
                    padding: '0px 2px',
                  }}
                  direction="row"
                >
                  <InputBase
                    value={editData.value}
                    sx={{
                      width: '48px',
                      height: '20px',
                      fontSize: '13px',
                      fontWeight: '600',
                      color: 'black',
                    }}
                    autoFocus
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setEditData((state) => ({
                        ...state,
                        value: e.target.value,
                      }));
                    }}
                    onKeyUp={(e) =>
                      enterKeyPressed(e, () => {
                        handleUpdate();
                      })
                    }
                    type="number"
                  ></InputBase>
                  <IconButton
                    sx={{ width: '20px', height: '20px' }}
                    disableRipple
                    onClick={handleUpdate}
                  >
                    <CheckIcon sx={{ width: '8px', height: '8px' }} />
                  </IconButton>
                </Stack>
              )}
            </Box>
          </Stack>
        ))}
      </Stack>
    </ClickAwayListener>
  );
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentStyleBox: {
      border: '1px solid ' + theme.palette.neutral[700],
      borderRadius: '8px',
      paddingLeft: '14px',
      backgroundColor: theme.palette.base.white,
    },
    contentStyle: {
      height: '40px',
      '& #contentstyle': {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      '& > p': {
        top: '10px',
      },
    },
  })
);
