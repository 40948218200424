import { Theme, useTheme } from '@mui/material/styles';

export function useChartCommonConfigV2(theme: Theme) {
  return {
    axisTextStyleGray: {
      margin: 16,
      color: theme.custom.gray,
      fontSize: '14px',
      fontFamily: 'ProximasNova',
    },
    axisTextStyleBlack: {
      margin: 16,
      color: theme.custom.black,
      fontSize: '18px',
      fontFamily: 'ProximaNova',
    },
    color: [
      theme.custom.charts1,
      theme.custom.charts2,
      theme.custom.charts3,
      theme.custom.charts4,
      theme.custom.charts5,
      theme.custom.charts9,
    ],
    colorPerformance: [
      theme.custom.charts6,
      theme.custom.charts7,
      theme.custom.charts8,
      theme.custom.charts9,
      theme.custom.charts10,
    ],
    color341: [
      theme.custom.charts3,
      theme.custom.charts4,
      theme.custom.charts1,
    ],
    colorRace: [
      theme.palette.primary.main,
      theme.palette.danger[400],
      theme.palette.success[500],
      theme.palette.grey[950],
      theme.custom.charts1,
      theme.custom.charts2,
      theme.custom.charts3,
      theme.custom.charts4,
      theme.custom.charts5,
      theme.custom.charts6,
      theme.custom.charts7,
      theme.custom.charts8,
    ],
  };
}

export function useChartCommonConfig() {
  const theme = useTheme();
  return {
    axisTextStyleGray: {
      margin: 16,
      color: theme.palette.grey[50],
      fontSize: '13px',
    },
    axisTextStyleBlack: {
      margin: 16,
      color: theme.custom.black,
      fontSize: '13px',
    },
    axisTextStyleLightGrayPoppins: {
      margin: 16,
      color: theme.palette.grey[50],
      fontWeight: 400,
      fontSize: '13px',
    },
    color: [
      theme.custom.charts1,
      theme.custom.charts2,
      theme.custom.charts3,
      theme.custom.charts4,
      theme.custom.charts5,
      theme.custom.charts9,
    ],
    colorPerformance: [
      theme.custom.charts6,
      theme.custom.charts7,
      theme.custom.charts8,
      theme.custom.charts9,
      theme.custom.charts10,
    ],
    color341: [
      theme.custom.charts3,
      theme.custom.charts4,
      theme.custom.charts1,
    ],
    gray42135: [
      theme.custom.charts4,
      theme.custom.charts2,
      theme.custom.charts1,
      theme.custom.charts3,
      theme.custom.charts5,
      theme.custom.gray,
    ],
    gray24135: [
      theme.custom.charts2,
      theme.custom.charts4,
      theme.custom.charts1,
      theme.custom.charts3,
      theme.custom.charts5,
      theme.custom.gray,
    ],
    tooltipFontStyle: {
      color: 'inherit',
      fontWeight: 500,
      fontSize: 12,
    },
    pieChart: [
      theme.palette.danger[300],
      theme.palette.primary[300],
      theme.palette.neutral[700],
    ],
    geoChart: [
      theme.palette.primary.main,
      theme.palette.danger[400],
      theme.palette.success[500],
      theme.palette.warning[500],
      theme.palette.grey[950],
      theme.custom.charts1,
      theme.custom.charts2,
      theme.custom.charts3,
      theme.custom.charts4,
      theme.custom.charts5,
      theme.custom.charts6,
      theme.custom.charts7,
      theme.custom.charts8,
    ],
    colorRace: [
      theme.palette.primary.main,
      theme.palette.danger[400],
      theme.palette.success[500],
      theme.palette.grey[950],
      theme.custom.charts1,
      theme.custom.charts2,
      theme.custom.charts3,
      theme.custom.charts4,
      theme.custom.charts5,
      theme.custom.charts6,
      theme.custom.charts7,
      theme.custom.charts8,
    ],
  };
}
