import { useEffect } from 'react';
import { getChatSiteWebSocketUrl } from '../../env';
import { useIntl } from 'react-intl';
import { useTypedSelector } from '../../redux/store';
import { selectAuth } from '../account/AuthSlice';
import { useLazyGetRocketchatUserTokenQuery } from '../../redux/benaApi';
import { LazyQueryResultHandler } from '../../redux/benaApiResultHandler';
import { useAuthUserId } from '../../hooks/account';
import { WINDOW_MESSAGE_RCIM_NEW_MESSAGE_NEW } from './RCUnreadMesssagesCount';

type NotifyUserEventType =
  | 'notification'
  | 'subscriptions-changed'
  | 'rooms-changed';
type RocketChatRealtimeAPIDataType = {
  msg: string; // 'connected' | 'ping' | 'result' | 'changed'
  id: string;
  session?: string;
  result: {
    id: string;
    token?: string;
    tokenExpires?: {
      $date: number;
    };
    type: string;
  };
};
type RocketChatRealtimeAPIMessageResponseType = {
  data: string;
};

class RCRealtimeAPIParams {
  private _id: number; // random message id
  private get id() {
    return `${++this._id}`;
  }
  userId: string; // userId in rocketchat
  token: string;
  tokenRefreshTimer: NodeJS.Timer | null;
  connected: boolean;
  constructor(token: string) {
    this._id = 0;
    this.token = token;
    this.tokenRefreshTimer = null;
    this.connected = false;
    this.userId = '';
  }

  genLogin() {
    return JSON.stringify({
      msg: 'method',
      method: 'login',
      id: this.id,
      params: [{ resume: this.token }],
    });
  }
  genNotifyUser(notifyType: NotifyUserEventType = 'notification') {
    return JSON.stringify({
      msg: 'sub',
      id: this.id,
      name: 'stream-notify-user',
      params: [`${this.userId}/${notifyType}`, false],
    });
  }
  genUnsub() {
    return JSON.stringify({
      msg: 'unsub',
      id: this.id,
    });
  }
  genConnect() {
    return JSON.stringify({
      msg: 'connect',
      version: '1',
      support: ['1', 'pre1', 'pre2'],
    });
  }
  genPong() {
    return JSON.stringify({
      msg: 'pong',
    });
  }
}

function notify() {
  window.postMessage(
    { type: WINDOW_MESSAGE_RCIM_NEW_MESSAGE_NEW },
    window.location.origin
  );
}

function MessageResponseHandler(
  event: RocketChatRealtimeAPIMessageResponseType
) {
  const { msg, result } = JSON.parse(
    event?.data || '{}'
  ) as RocketChatRealtimeAPIDataType;
  const { socket, params } = socketWrapper;
  if (socket && params) {
    switch (msg) {
      case 'ping':
        socket.send(params.genPong());
        return;
      case 'result':
        if (result?.type === 'resume' && result?.token) {
          params.userId = result.id;
          socket.send(params.genNotifyUser());
          socket.send(params.genNotifyUser('subscriptions-changed'));
          socket.send(params.genNotifyUser('rooms-changed'));
        }
        return;
      case 'changed':
        // handle new message notification
        notify();
        return;
      case 'connected':
        params.connected = true;
        socket.send(params.genLogin());
        notify();
        return;
      case 'disconnected':
        params.connected = false;
        socket.send(params.genConnect());
        return;
      default:
        return;
    }
  }
}

function connect() {
  if (socketWrapper.params) {
    console.log('connect to server', socketWrapper);
    socketWrapper.socket?.send(socketWrapper.params.genConnect());
  }
}

function onClose() {
  console.log('server close connection', socketWrapper);
  clearSocketWrapper();
}

function onError(event: any) {
  console.log('socket error', event);
}

function clearSocketWrapper() {
  const { socket, params } = socketWrapper;
  if (socket && params) {
    params.tokenRefreshTimer && clearTimeout(params.tokenRefreshTimer);
  }
  socketWrapper.socket = null;
  socketWrapper.params = null;
}

const socketWrapper = {
  socket: null as WebSocket | null,
  params: null as RCRealtimeAPIParams | null,
};
function createSocket() {
  if (!socketWrapper.socket) {
    socketWrapper.socket = new WebSocket(getChatSiteWebSocketUrl());
    socketWrapper.socket.onmessage = MessageResponseHandler;
    socketWrapper.socket.onopen = connect;
    socketWrapper.socket.onclose = onClose;
    socketWrapper.socket.onerror = onError;
  }
}

createSocket();

export default function RocketchatRealTimeAgent() {
  const intl = useIntl();
  const access = useTypedSelector((state) => selectAuth(state)).access;
  const userId = useAuthUserId();

  useEffect(() => {
    if (userId) {
      document.cookie = 'user_id=' + userId + '; path=/';
    }
    return () => {
      clearSocketWrapper();
    };
  }, [userId]);

  const [query, result] = useLazyGetRocketchatUserTokenQuery({});
  useEffect(() => {
    if (access) query({});
  }, [access, query]);

  useEffect(() => {
    LazyQueryResultHandler({
      intl,
      result: result as QueryResultType<GetRocketchatUserTokenResponseType>,
      onSuccess: (resdata) => {
        const { token } = resdata;
        if (token && userId) {
          const params = new RCRealtimeAPIParams(token);
          socketWrapper.params = params;
          if (!socketWrapper.socket) {
            createSocket();
          }
          try {
            connect();
          } catch (e) {
            return;
          }
        }
      },
    });
  }, [result]);

  return <></>;
}
