import { useIntl } from 'react-intl';
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
  capitalize,
} from '@mui/material';
import { UserAccountMessages } from './Messages';
import { Messages as globalMessages } from '../../localization/Messages';
import {
  MAXEMAILADDRESSLENGTH,
  ValidateRules,
  VERIFICATIONCODECOUNTDOWNTIMER,
} from '../../utils/common';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import { useTypedSelector } from '../../redux/store';
import { selectAuth, setUser } from './AuthSlice';
import { useDispatch } from 'react-redux';
import {
  usePatchEmailMutation,
  useSendEmailVerificationCodeMutation,
} from '../../redux/benaApi';
import { showMessage } from '../../components/ShowMessage';
import SendCodeButton from '../../components/SendCodeButton';
import { useLanguage } from '../../hooks/language';
import GoBack from './GoBack';
import LogoAndLanguage from './LogoAndLanguage';

type UserEmailType = {
  id: number;
  email: InputDataType;
  verificationCode: InputDataType;
};

type ChangeEmailProps = {
  id?: number;
};

export default function ChangeEmail({ id }: ChangeEmailProps) {
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useTypedSelector((state) => selectAuth(state)).user;

  const initUserData = (email?: string) =>
    ({
      id: 0,
      email: {
        content: email || '',
        maxLength: MAXEMAILADDRESSLENGTH,
        error: false,
        inputValueType: 'email',
      },
      verificationCode: {
        content: '',
        maxLength: 6,
        error: false,
        inputValueType: 'verificationCode',
      },
    } as UserEmailType);

  useEffect(() => {
    user && setFormData(initUserData(user.email || ''));
  }, [user]);

  const [formData, setFormData] = useState<UserEmailType>(initUserData());
  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputDataType,
    key: string
  ) => {
    const str = event.target.value.slice(0, data.maxLength);
    setFormData({
      ...formData,
      [key]: {
        ...data,
        content: str,
        length: event.target.value.length,
        error: !ValidateRules.validInputString(
          str,
          data.maxLength,
          data.inputValueType
        ),
      },
    });
  };

  const refEmailAddress = useRef<HTMLElement>(null);
  const refVerificationCode = useRef<HTMLElement>(null);
  useEffect(() => {
    refEmailAddress.current?.focus();
  }, []);

  const inputList = [
    {
      item: formData.email,
      message: UserAccountMessages.emailAddress,
      name: 'email',
      refEl: refEmailAddress,
      placeHolder: UserAccountMessages.enterEmailAddress,
    },
    {
      item: formData.verificationCode,
      message: UserAccountMessages.verificationCode,
      name: 'verificationCode',
      refEl: refVerificationCode,
      placeHolder: UserAccountMessages.enter6DigitalCode,
      children: (
        <Box sx={{ mt: 1, mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{
              color: theme.custom.gray,
              position: 'relative',
            }}
            component="span"
          >
            {intl.formatMessage(UserAccountMessages.didntGetACode)}{' '}
            {intl.formatMessage(UserAccountMessages.checkSpanFolder)}
          </Typography>
        </Box>
      ),
    },
  ] as Array<
    FormInputType & {
      startAdornment?: React.ReactElement;
      required?: boolean;
      disabled?: boolean;
    }
  >;

  const isValidForSave = function (
    inputs: typeof inputList,
    saveFormData = true
  ): boolean {
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].required === false) continue;

      const { item: input, refEl: ref, name: key } = inputs[i];
      if (
        !ValidateRules.validInputString(
          input.content,
          input.maxLength,
          input.inputValueType
        )
      ) {
        if (saveFormData) {
          setFormData({
            ...formData,
            [key]: { ...input, error: true },
          });
          ref.current?.focus();
        }
        return false;
      }
    }

    return saveFormData ? true : !submitting;
  };
  const [submitting, setSubmitting] = useState(false);

  const [sendEmailVerificationCode, sendCodeResult] =
    useSendEmailVerificationCodeMutation();
  const lang = useLanguage();

  const [emailExist, setEmailExist] = useState(false);
  // if email exists after get code verification, stop countdown
  useEffect(() => {
    if (emailExist) {
      setCountDown(0);
      setSubmitting(false);
    }
  }, [emailExist]);

  const handleGetVerificationCode = async () => {
    if (isValidForSave(inputList.slice(0, 1))) {
      setSubmitting(true);
      setEmailExist(false);
      await MutationHandler(
        {
          intl,
          action: async () =>
            await sendEmailVerificationCode({
              email: formData.email.content,
              language: lang,
              existing_user: false,
            }).unwrap(),
          onException: (statusCode) => {
            statusCode === 4009 && setEmailExist(true);
          },
        },
        globalMessages.showMessageSendCodeSuccess
      );
      setCountDown(VERIFICATIONCODECOUNTDOWNTIMER);
    }
  };

  const [patchEmail] = usePatchEmailMutation();
  const handleContinue = async () => {
    if (isValidForSave(inputList)) {
      setSubmitting(true);
      const ret = await MutationHandler({
        intl,
        action: async () => {
          return await patchEmail({
            email: formData.email.content,
            code: formData.verificationCode.content,
          }).unwrap();
        },
        onSuccess: (data) => {
          dispatch(setUser(data));
          showMessage.success(
            intl.formatMessage(globalMessages.SuccessfullySaved)
          );
        },
      });
      if (ret) {
        setSubmitting(false);
        return false;
      }
      return true;
    }
    setSubmitting(false);
    return false;
  };

  const [countDown, setCountDown] = useState<number>(0);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countDown > 0) {
      timer = setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [countDown]);

  // wait 10 seconds after click send verification code
  useEffect(() => {
    countDown <= 50 && setSubmitting(false);
  }, [countDown, setSubmitting]);

  return (
    <Box sx={{ pt: 1 }}>
      <LogoAndLanguage />
      <GoBack />
      <Box sx={{ mt: '1rem' }} className="form-width">
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, mb: '1rem' }}>
          {intl.formatMessage(UserAccountMessages.changeEmail)}
        </Typography>
        <Box className="form-width">
          <form>
            {inputList.map((data) => {
              const {
                item,
                message,
                name,
                refEl,
                placeHolder,
                children,
                startAdornment,
                required,
                disabled,
              } = data;
              return (
                <Box
                  key={message.id}
                  className={'boxcenterhv'}
                  sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      mb: 1.75,
                      color: theme.palette.grey[800],
                      fontSize: '0.625rem',
                      fontWeight: 600,
                    }}
                    component="div"
                    className={'boxcenterhv'}
                  >
                    {intl.formatMessage(message).toUpperCase()}
                    {required && (
                      <Box className={'boxcenterhv'}>
                        <FiberManualRecordIcon
                          htmlColor={theme.palette.danger[500]}
                          sx={{ fontSize: '0.5rem', ml: 1 }}
                        />
                      </Box>
                    )}
                  </Typography>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      disabled={disabled}
                      placeholder={intl.formatMessage(placeHolder)}
                      inputRef={refEl}
                      sx={{
                        mb: children ? 2 : 3,
                        ...(name === 'verificationCode'
                          ? {
                              '&>input': {
                                letterSpacing: '0.25rem',
                                fontSize: '0.875rem',
                                fontWeight: 'bold',
                              },
                            }
                          : {}),
                      }}
                      className={`border-radius-6px input-size `}
                      id={message.id}
                      value={item.content}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleTextInputChange(event, item, name);
                      }}
                      endAdornment={
                        !disabled && (
                          <InputAdornment position="end">
                            {children ? (
                              <SendCodeButton
                                countDown={countDown}
                                onClick={() =>
                                  !submitting && handleGetVerificationCode()
                                }
                                message={UserAccountMessages.sendCode}
                                countDownMessage={
                                  UserAccountMessages.resendCodeIn
                                }
                                timeUnit={UserAccountMessages.textSecondInAbbr}
                                isSending={sendCodeResult.isLoading}
                              />
                            ) : (
                              <Typography>
                                {`${item.content.length} / ${item.maxLength}`}
                              </Typography>
                            )}
                          </InputAdornment>
                        )
                      }
                      error={item.error}
                      startAdornment={startAdornment}
                    />
                    {children}
                  </FormControl>
                </Box>
              );
            })}
            <Box>
              <Button
                type="submit"
                variant="contained"
                className={`border-radius-6px button-size`}
                onClick={(e) => {
                  e.preventDefault();
                  handleContinue();
                }}
                disabled={!isValidForSave(inputList, false) || submitting}
              >
                {capitalize(intl.formatMessage(globalMessages.change))}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
