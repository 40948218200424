import {
  Box, Stack, CardMedia, Typography, Button
} from "@mui/material"
import { useIntl } from "react-intl";
import { Messages } from "../localization/Messages";
import { useEffect, useState } from "react";
import {
  useTypedSelector
} from "../redux/store";
import { useNavigate } from "react-router-dom";
import {
  useLogout
} from "../hooks/account";
import { useLazyGetUserQuery } from "../redux/benaApi";

export default function Authorization() {
  // params is ?state= &code=
  const navigate = useNavigate();
  const intl = useIntl();
  const [getUser, getUserResult] = useLazyGetUserQuery();
    
  const [logoutHandler] = useLogout();
  const [user, setUser] = useState<UserResponseType | null>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state');
  const redirect_uri = urlParams.get('redirect_uri');
  const auth = useTypedSelector(state => state.auth);
  useEffect(()=>{
    if(redirect_uri){
      sessionStorage.setItem("plugin:redirect", decodeURIComponent(redirect_uri));
    }else if(state){
      localStorage.setItem("plugin:state", state);
    }
    if(!auth.access){
      navigate("/account/login");
    }else if(auth.user){
      setUser(auth.user);
    }
    getUser({});
  },[]);

  const redirectInfo = ()=>{
    localStorage.removeItem('plugin:state');
    sessionStorage.removeItem('plugin:redirect');
    if(state){
      window.opener.postMessage( { type: 'login', data: auth }, "*" );
      window.close();
    }else if(window.opener){
      window.postMessage( { type: 'bena-login', data: auth }, "*" );
      window.close();
    }else{
      window.postMessage( { type: 'bena-login', data: auth }, "*" );
      setTimeout(() => { window.location.href = redirect_uri || "/"; }, 200);
    }
  };

  const styleTripe = {fontSize: "14px", lineHeight: "24px"};
  return (
    <Stack sx={{
      margin: "auto", width: "504px", padding: "24px 32px", position: "fixed",
      top: "50%", left: "50%", transform: "translate(-50%, -50%)",
      boxShadow: "0 12px 24px -4px rgba(145,158,171,.22),0 0 2px 0 rgba(145,158,171,.3)",
      borderRadius: "20px" 
    }}>
      <Stack alignItems="center" mb="2rem">
        <CardMedia component="img" width="120" image="/logo.svg" alt="logo"
          sx={{width: "120px"}} />
      </Stack>
      {
        user ? (
          <>
            <Typography>{intl.formatMessage(Messages.currentUser)}:</Typography>
            <Stack direction="row" sx={{
              padding: "1rem", borderRadius: "10px", border: "1px solid #e0e0e0",
              mt: "1rem", gap: "1rem", alignItems: "center"
            }}>
              <CardMedia component="img" width="40" image={ (user.avatar as string | undefined) || "/imgs/user.svg"} alt="avatar"
                sx={{width: "40px", height:"40px", borderRadius: "50%", border: "1px solid #e0e0e0"}}
                onError={(event)=>{
                  event.currentTarget.src = "/imgs/user.svg";
                }} />
              <Stack >
                <Typography sx={styleTripe} fontWeight="500" color="#1f1f1f">{user.name}</Typography>
                <Typography sx={styleTripe} fontWeight="400" color="#444746">{user.email}</Typography>
              </Stack>
              <Box flexGrow="1"></Box>
              <Button variant="contained" color="primary" sx={{
                padding: "0.5rem 1rem", fontSize: "14px",borderRadius: "10px"
              }} onClick={redirectInfo}>
                {intl.formatMessage(Messages.logInPlugin)}
              </Button>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center"
              padding="1rem 0">
              <Box sx={{width:"200px",height:"1px",bgcolor:"#ebeef2"}}></Box>
              <Typography color="#444746">or</Typography>
              <Box sx={{width:"200px",height:"1px",bgcolor:"#ebeef2"}}></Box>
            </Stack>
            <Stack>
              <Button variant="outlined" color="primary"
                sx={{
                  padding: "0.5rem 1rem", fontSize: "14px",borderRadius: "6px",
                  "&:hover": { bgcolor: "#f3f5f7" }
                }}
                onClick={()=>{
                  logoutHandler("/account/login")
                }}>{intl.formatMessage(Messages.switchAccounts)}</Button>
              <Button variant="outlined" color="primary"
                sx={{
                  padding: "0.5rem 1rem", fontSize: "14px",borderRadius: "6px", mt: "1rem",
                  "&:hover": { bgcolor: "#f3f5f7" }
                }}
                onClick={()=>{
                  logoutHandler("/account/registerBrand")
                }}>{intl.formatMessage(Messages.singUp)}</Button>
            </Stack>
          </>
        ) : (
          <></>
        )
      }
      
      
    </Stack>
  );
}