import { ArrowBackRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useGlobalStyles } from '../../assets/styles/style';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import { Fragment, useContext, useEffect } from 'react';
import { ApplicationContext, BrandContext } from '../context/context';
import TitledPanel from '../components/TitledPanel';
import CreatorCardHorizontal from '../components/CreatorCardHorizontal';
import {
  useGetCampaignCreatorApplicationQuery,
  useGetCampaignDetailQuery,
} from '../../redux/benaApi';
import { staticURLMessaging } from '../../route/urls';
import moment from 'moment';
import { Validator } from '../../utils/common';
import { CreatorMediaKit } from './CreatorProposalDetailsUtils';
import { useAuthUserId } from '../../hooks/account';
import { PatchApplicationEditButton } from './PatchApplicationEditButton';
import { isAttachment, isPdfFile } from '../../utils/mimetype';
import PdfPreview from '../../components/PdfPreview';
import { LinkItUrl } from 'react-linkify-it';
import { Messages } from '../../localization/Messages';

type CreatorProposalDetailsProps = {
  campaignId: string;
  creatorId: string;
};
export default function CreatorProposalDetails({
  campaignId,
  creatorId,
}: CreatorProposalDetailsProps) {
  const theme = useTheme();
  const gs = useGlobalStyles();
  const intl = useIntl();
  const userId = useAuthUserId();
  const context = useContext(BrandContext);
  const hidePanel = () => {
    context.setCurrentDisplayedCreatorId('');
  };

  const { data: campaign, refetch: refetchCampaign } =
    useGetCampaignDetailQuery({
      campaign_id: campaignId,
    });
  const campaignData = campaign?.data;

  const { data: application, refetch: refetchApplication } =
    useGetCampaignCreatorApplicationQuery({
      campaign_id: campaignId,
      creator_id: creatorId,
    });
  const applicationData = application?.data;

  useEffect(() => {
    refetchCampaign();
    refetchApplication();
  }, [refetchCampaign, refetchApplication]);

  if (!campaignData || !applicationData) return null;

  const campaignInfo = {
    platformId: campaignData.platforms?.[0] || '',
    launchedOn: moment(campaignData.start_date).format('YYYY-MM-DD'),
    title: applicationData.creator?.name || '-',
    image: applicationData.creator?.picture || '-',
    messageToBrand: applicationData.message_to_brand || '-',
    budget: Validator.isNumber(applicationData.price)
      ? '$' + applicationData.price
      : applicationData.price,
    freeProduct: campaignData.budget_mode === 'product_exchange_only',
    username: applicationData.creator?.unique_id,
    followers: applicationData.creator?.profile?.followers || 0,
    engagement_rate: applicationData.creator?.profile?.engagement_rate || 0,
    right_to_use: applicationData.right_usage || '-',
    post_display_duration: applicationData.post_display_duration || '-',
    turnaround_time_days: applicationData.turnaround_time_days || '-',
  };

  const contentDirections = applicationData.proposal || '-';
  const notifyRefresh = () => {
    refetchApplication();
  };

  // if undefined, show media kit
  const showMediaKit = !!applicationData.generate_media_kit;

  const editMode = applicationData?.user_id === userId;

  return (
    <ApplicationContext.Provider
      value={{
        userId: userId || '',
        campaignId,
        applicationId: applicationData?.id || '',
        applicationCreatorId: applicationData?.user_id || '',
        notifyRefresh,
      }}
    >
      <Stack
        marginTop={'48px'}
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderRadius={'8px 8px 0px 0px'}
        padding={'12px 20px'}
      >
        <Stack direction="row" alignItems={'center'}>
          <IconButton onClick={hidePanel} disableRipple>
            <ArrowBackRounded
              htmlColor={theme.palette.base.black}
              sx={{ width: '22px', height: '22px' }}
            />
            <Typography fontSize={16} fontWeight={500} color="grey.700">
              {intl.formatMessage(CreatorMessages.GoBack)}
            </Typography>
          </IconButton>
          <Box flexGrow={1}></Box>
          <Link href={`${staticURLMessaging}?id=${creatorId}`}>
            <Button
              sx={{
                width: '144px',
                height: '32px',
                fontWeight: '600',
                fontSize: '14px',
                padding: '8px',
              }}
              variant="contained"
              className={gs.buttonPrimary}
            >
              {intl.formatMessage(CreatorMessages.GotoMessage)}
            </Button>
          </Link>
        </Stack>
      </Stack>
      <Stack
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderTop="0px"
        borderRadius={'0px 0px 8px 8px'}
        padding={'20px 24px'}
      >
        <Stack rowGap={'27px'}>
          <CreatorCardHorizontal {...campaignInfo} />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.ContentIdea)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {contentDirections}
              </Typography>
            }
            action={
              <PatchApplicationEditButton
                fieldName="proposal"
                initText={contentDirections}
              />
            }
          />
          {((applicationData.custom_media_kit || []).length > 0 ||
            showMediaKit ||
            applicationData.media_kit_link) && (
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.MediaKit)}
              children={
                <Stack
                  direction="row"
                  flexWrap={'wrap'}
                  rowGap="16px"
                  columnGap="16px"
                >
                  {(applicationData.custom_media_kit || []).map(
                    (media, index) => {
                      const attachment = isAttachment(media.name);
                      const isPdf = isPdfFile(media.name);
                      if (isPdf && media?.file) {
                        return (
                          <Fragment key={index}>
                            <PdfPreview file={media?.file} />
                          </Fragment>
                        );
                      }
                      return (
                        <img
                          key={index}
                          src={!attachment ? media?.file : '/file-icon.svg'}
                          alt={media.name || 'midia kit'}
                          title={media.name || 'midia kit'}
                          style={{
                            cursor: 'pointer',
                            ...(attachment
                              ? { width: '40px' }
                              : { maxWidth: '49%', minWidth: '200px' }),
                          }}
                        />
                      );
                    }
                  )}
                  {showMediaKit && <CreatorMediaKit />}
                  {(applicationData.custom_media_kit || []).length <= 0 &&
                    !showMediaKit &&
                    applicationData.media_kit_link && (
                      <Stack
                        direction="row"
                        flexWrap={'wrap'}
                        alignItems={'center'}
                      >
                        <LinkItUrl>
                          <Typography
                            color="grey.400"
                            fontSize={'14px'}
                            fontWeight={400}
                          >
                            <Typography
                              sx={{ mr: '8px' }}
                              fontSize="13px"
                              component="span"
                            >
                              {intl.formatMessage(Messages.MediaKitLink)}
                            </Typography>
                            {applicationData.media_kit_link}
                          </Typography>
                        </LinkItUrl>
                        {editMode && (
                          <PatchApplicationEditButton
                            fieldName="media_kit_link"
                            initText={applicationData.media_kit_link}
                          />
                        )}
                      </Stack>
                    )}
                </Stack>
              }
            />
          )}
        </Stack>
      </Stack>
      <Box height="10rem"></Box>
    </ApplicationContext.Provider>
  );
}
