import { SxProps, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { SadFaceIcon } from '../assets/imgs/icons';
import { Messages } from '../localization/Messages';

type NoInfoToShowProps = {
  sx?: SxProps;
};
export function NoInfoToShow({ sx }: NoInfoToShowProps) {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <Typography
      sx={{
        fontSize: '0.875rem',
        fontWeight: 400,
        fontFamily: 'ProximaNova',
        color: theme.custom.lightRed,
        ...(sx || {}),
      }}
    >
      {intl.formatMessage(Messages.noInfoToShow)}
    </Typography>
  );
}

type NoInfoToShowWithEmojiProps = {
  classes?: string;
};
export function NoInfoToShowWithSadFace({
  classes,
}: NoInfoToShowWithEmojiProps) {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <div
      style={{
        width: '100%',
        height: '7rem',
        flexDirection: 'column',
      }}
      className={`${classes} boxcenterhv`}
    >
      <SadFaceIcon
        sx={{ width: '1.5rem', height: '1.5rem', marginBottom: '1.25rem' }}
        stroke={theme.custom.gray}
        fill={theme.custom.almostWhite}
      />
      <Typography
        sx={{ fontSize: '0.875rem', fontWeight: 600, color: theme.custom.gray }}
      >
        {intl.formatMessage(Messages.dataIsNotAvailableAtThisTime)}
      </Typography>
    </div>
  );
}
