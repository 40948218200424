import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography,
  capitalize,
  useTheme,
} from '@mui/material';
import { useGlobalStyles } from '../../assets/styles/style';
import { useIntl } from 'react-intl';
import { CreatorMessages } from '../../localization/CreatorMessages';
import CampaignCardHorizontal from '../components/CampaignCardHorizontal';
import { useEffect } from 'react';
import CreatorRequirements from '../components/CreatorRequirements';
import TitledPanel from '../components/TitledPanel';
import HoverableImage from '../components/HoverableImage';
import FlexSpaceAroundLeftAligned from '../../components/FlexSpaceBetweenLeftAligned';
import moment from 'moment';
import { Messages } from '../../localization/Messages';
import { useGetCampaignDatail } from '../hooks/campaign';
import {
  useAuthUserId,
  useIsRoleCreator,
  useSocialAccountVerification,
  useUserTiktokVerified,
} from '../../hooks/account';
import { useNavigate } from 'react-router-dom';
import { staticURLLogin, staticURLRoot } from '../../route/urls';
import { DisplayPreferencesSettingItemsInOrder } from '../campaign/PreferencesSetting';

type PublicCampaigndetailsPageProps = {
  showActionButtons?: boolean;
};
export function PublicCampaigndetailsPage({
  showActionButtons = true,
}: PublicCampaigndetailsPageProps) {
  const theme = useTheme();
  const gs = useGlobalStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const isCreator = useIsRoleCreator();
  const userId = useAuthUserId();

  const campaignIdFromUrl = new URLSearchParams(window.location.search).get(
    'campaign_id'
  );

  const tiktokVerified = useUserTiktokVerified();
  const ssoVerify = useSocialAccountVerification();
  const ssoVerifyTiktok = () => {
    // redirect to creator homepage
    const url = '/sso_verify_callback_home_page/' + campaignIdFromUrl;
    ssoVerify('tiktok', url);
  };

  const { campaign: campaignData, fetch } = useGetCampaignDatail();
  useEffect(() => {
    campaignIdFromUrl && fetch(campaignIdFromUrl);
  }, [campaignIdFromUrl]);

  if (!campaignData)
    return (
      <Stack
        className="boxcenterhv"
        minHeight="400px"
        border={`1px solid ${theme.palette.neutral[600]}`}
        marginTop="48px"
      >
        <Typography fontSize={'16px'} color="neutral.950">
          {intl.formatMessage(CreatorMessages.NoCampaignFound)}
        </Typography>
      </Stack>
    );

  const campaignInfo = {
    platformIds: (campaignData.platforms || []) as SocialPlatformId[],
    launchedOn: moment(campaignData.start_date).format(`M/DD/YY`),
    logo: campaignData.brand_logo,
    title: campaignData.title,
    image: campaignData.cover_img,
    brandInfo: campaignData.brand_intro,
    pageUrl: campaignData.brand_link,
    campaignPeriod: `${moment(campaignData.start_date).format(
      `M/DD/YY`
    )} - ${moment(campaignData.end_date).format(`M/DD/YY`)}`,
    budget: `$${campaignData.budget_amount}`,
    budget_max: campaignData.budget_amount_max
      ? `$${campaignData.budget_amount_max}`
      : undefined,
    freeProduct: campaignData.budget_mode === 'product_exchange_only',
    commission: campaignData.commission,
    commissionPercent: campaignData.commission_percent,
    products: campaignData.products || [],
  };

  const tags = DisplayPreferencesSettingItemsInOrder(
    campaignData.creator_requirements
  );
  const contentDirections = campaignData.content_direction;
  const caption = campaignData.captions;
  const videoDuration = campaignData.video_duration;
  const hashtags = campaignData.hashtags.join(', ');
  const otherAccounts = campaignData.tag_accounts.join(', ');

  const applyCampaign = () => {
    navigate(`${staticURLRoot}?applied_campaign_id=${campaignIdFromUrl}`);
  };

  const applyNotAllowed =
    ['completed', 'drafted'].includes(campaignData.status) ||
    // not yet started
    new Date(campaignData.start_date).valueOf() > Date.now() ||
    // only for display campaign info
    !showActionButtons;

  const campaignApplied =
    !!campaignData.application?.id &&
    campaignData.application?.status !== 'created';

  return (
    <Container maxWidth="xl" sx={{ mt: '2.5rem' }}>
      <Stack
        marginTop={'48px'}
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderRadius={'8px 8px 0px 0px'}
        padding={'12px 20px'}
      >
        <Stack direction="row" alignItems={'center'}>
          <Box flexGrow={1}></Box>
          {userId && campaignApplied && (
            <Typography textAlign={'right'} color="primary">
              {intl.formatMessage(CreatorMessages.YouHaveAppliedThisCampaign)}
            </Typography>
          )}
          {!campaignApplied && !applyNotAllowed && (
            <Button
              sx={{
                minWidth: '144px',
                height: '32px',
                fontWeight: '600',
                fontSize: '14px',
                padding: '8px',
                visibility: isCreator ? 'visible' : 'hidden',
              }}
              variant="contained"
              className={gs.buttonPrimary}
              onClick={tiktokVerified ? applyCampaign : ssoVerifyTiktok}
            >
              {intl.formatMessage(
                tiktokVerified
                  ? CreatorMessages.ApplyToCampaign
                  : CreatorMessages.LinkTiktokToApply
              )}
            </Button>
          )}
          {!campaignApplied && applyNotAllowed && (
            <Typography color="grey.700">
              {capitalize(campaignData.status)}
            </Typography>
          )}
          {!userId && (
            <Link
              href={`${staticURLLogin}?redirect=${encodeURIComponent(
                window.location.pathname + window.location.search
              )}`}
            >
              <Button
                sx={{
                  minWidth: '144px',
                  height: '32px',
                  fontWeight: '600',
                  fontSize: '14px',
                  padding: '8px',
                }}
                variant="contained"
                className={gs.buttonPrimary}
              >
                {intl.formatMessage(CreatorMessages.LoginToApplyToCampaign)}
              </Button>
            </Link>
          )}
        </Stack>
      </Stack>
      <Stack
        border={`1px solid ${theme.palette.neutral[600]}`}
        borderTop="0px"
        borderRadius={'0px 0px 8px 8px'}
        padding={'20px 24px'}
      >
        <Stack rowGap={'27px'}>
          <CampaignCardHorizontal {...campaignInfo} />
          <CreatorRequirements tags={tags} />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.ContentDirection)}
            children={
              <Typography
                sx={{ whiteSpace: 'pre-line', lineHeight: '26px' }}
                color="grey.400"
                fontSize={'14px'}
                fontWeight={400}
              >
                {contentDirections}
              </Typography>
            }
          />
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Caption)}
            children={
              <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                {caption}
              </Typography>
            }
          />
          <Stack
            direction="row"
            columnGap={'128px'}
            rowGap={'16px'}
            flexWrap={'wrap'}
            alignItems={'flex-start'}
          >
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.VideoDurations)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {videoDuration} second{videoDuration > 1 ? 's' : ''}
                </Typography>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.Hashtags)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {hashtags || '-'}
                </Typography>
              }
            />
            <TitledPanel
              title={intl.formatMessage(CreatorMessages.OtherAccounts)}
              children={
                <Typography color="grey.400" fontSize={'14px'} fontWeight={400}>
                  {otherAccounts || '-'}
                </Typography>
              }
            />
          </Stack>
          <TitledPanel
            title={intl.formatMessage(CreatorMessages.Files)}
            children={
              <Stack
                direction="row"
                flexWrap={'wrap'}
                rowGap="10px"
                columnGap={'10px'}
              >
                {(campaignData.visual_medias || []).length > 0 ? (
                  <>
                    {(campaignData.visual_medias || []).map((w: any) => (
                      <HoverableImage
                        fileLink={w.file}
                        fileName={w.name}
                        fileType={w.content_type}
                        key={w.id}
                      />
                    ))}
                    <FlexSpaceAroundLeftAligned width={'224px'} />
                  </>
                ) : (
                  <Typography
                    fontWeight={400}
                    color="grey.400"
                    fontSize={'14px'}
                  >
                    {intl.formatMessage(Messages.NoFileFound)}
                  </Typography>
                )}
              </Stack>
            }
          />
        </Stack>
      </Stack>
      <Box sx={{ height: '3rem' }}></Box>
    </Container>
  );
}
