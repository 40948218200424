import { useTheme } from '@mui/material';
import { useMemo } from 'react';

export function useStepColors() {
  const theme = useTheme();
  return useMemo(
    () => ({
      action: {
        bgcolor: theme.palette.primary.main,
        color: theme.palette.base.white,
      },
      status: {
        bgcolor: theme.palette.primary[25],
        color: theme.palette.primary.main,
      },
      disabled: {
        bgcolor: theme.palette.neutral[300],
        color: theme.palette.grey[700],
      },
      done: {
        bgcolor: theme.palette.success[50],
        color: theme.palette.success[700],
      },
      notyet: {
        bgcolor: theme.palette.warning[50],
        color: theme.palette.warning[600],
      },
      reject: {
        bgcolor: theme.palette.danger[100],
        color: theme.palette.danger[700],
      },
      reviewdone: {
        bgcolor: theme.palette.success[700],
        color: theme.palette.base.white,
      },
    }),
    [theme]
  );
}

export function useHiredSteps() {
  const colors = useStepColors();
  return useMemo(
    () =>
      ({
        product_selection: {
          state: {
            enter: {
              name: 'Enter',
              ...colors.status,
            },
            done: {
              name: 'Done',
              ...colors.done,
            },
          },
          config: { allowSelect: true },
        },
        prepayment: {
          state: {
            enter: {
              name: 'Enter',
              ...colors.status,
            },
            paid: {
              name: 'Paid',
              ...colors.done,
            },
          },
          config: { allowSelect: true },
        },
        shipping_status: {
          state: {
            select_state: {
              name: 'Select state',
              ...colors.status,
            },
            default: {
              ...colors.done,
            },
          },
          config: { allowSelect: true },
        },
        production: {
          state: {
            select_state: {
              name: 'Select state',
              ...colors.status,
            },
            personal_issues: {
              name: 'Personal issues',
              ...colors.notyet,
            },
            not_yet: {
              name: 'Not Yet',
              ...colors.notyet,
            },
            shooting: {
              name: 'Shooting',
              ...colors.reviewdone,
            },
            done: {
              name: 'Done',
              ...colors.done,
            },
          },
          config: { allowSelect: true },
        },
        draft_submission: {
          state: {
            select_state: {
              name: 'Select state',
              ...colors.status,
            },
            pending_approval: {
              name: 'Pending approval',
              ...colors.action,
            },
            no_draft_yet: {
              name: 'No draft yet',
              ...colors.notyet,
            },
            rejected: {
              name: 'Rejected',
              ...colors.reject,
            },
            approved: {
              name: 'Approved',
              ...colors.reviewdone,
            },
          },
          config: { allowSelect: false },
        },
        post_link: {
          state: {
            check: {
              name: 'Check',
              ...colors.action,
            },
            done: {
              name: 'Done',
              ...colors.reviewdone,
            },
          },
          config: { allowSelect: true },
        },
        payment: {
          state: {
            enter: {
              name: 'Enter',
              ...colors.action,
            },
            paid: {
              name: 'Paid',
              ...colors.done,
            },
          },
          config: { allowSelect: true },
        },
        review: {
          state: {
            give: {
              name: 'Give',
              ...colors.action,
            },
            done: {
              name: 'Done',
              ...colors.reviewdone,
            },
          },
          config: { allowSelect: false },
        },
      } as {
        [key: string]: {
          state: { [key: string]: StateItemType | any };
          config: { allowSelect: boolean };
        };
      }),
    [colors]
  );
}

type StateItemType = {
  name: string;
  bgcolor: string;
  color: string;
};
