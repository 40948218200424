import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Rating,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { Messages } from '../../localization/Messages';
import { TransitionUp } from '../../components/Transitions';
import { CrossIcon } from '../../assets/imgs/icons';
import { useGlobalStyles } from '../../assets/styles/style';
import BenaRating from '../../components/BenaRating';

type ReviewCreatorProps = {
  initReview?: ReviewType;
  initOpen: boolean;
  handlePatch?: (data: ReviewType) => Promise<boolean>;
  onCancel: fnVoidToVoid;
  viewMode?: boolean;
};

export function ReviewCreator({
  initReview,
  initOpen,
  handlePatch,
  onCancel,
  viewMode = false,
}: ReviewCreatorProps) {
  const intl = useIntl();
  const theme = useTheme();
  const gs = useGlobalStyles();
  const [open, setOpen] = useState(initOpen);

  useEffect(() => {
    setOpen(initOpen);
  }, [initOpen]);

  const [loading, setLoading] = useState(false);
  const [reviewData, setReviewData] = useState<ReviewType>({
    cooperation: 5,
    quality_of_work: 5,
    adherence_to_schedule: 5,
    experience: '',
  });
  useEffect(() => {
    initReview && setReviewData(initReview);
  }, [initReview]);

  const patchApplication = async (data: ReviewType) => {
    if (!handlePatch) return;
    setLoading(true);
    const ret = await handlePatch(data);
    setLoading(false);
    if (ret) {
      onCancel();
    }
  };

  const handleChangeReviewData = (
    key: keyof ReviewType,
    value: number | string
  ) => {
    setReviewData((state) => ({
      ...state,
      [key]: value,
    }));
  };

  return (
    <>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={TransitionUp}
          sx={{
            zIndex: theme.zIndex.modal,
            '& .MuiDialog-container > .MuiPaper-root': {
              backgroundColor: theme.palette.neutral[0],
              backgroundImage: 'none !important',
            },
          }}
        >
          <DialogTitle display={'flex'}>
            <Typography fontWeight="600" fontSize="16px" marginTop="8px">
              {intl.formatMessage(Messages.ReviewThisCreator)}
            </Typography>
            <Box flexGrow={1}></Box>
            <IconButton
              onClick={() => {
                setOpen(false);
                onCancel();
              }}
            >
              <CrossIcon
                stroke={theme.palette.base.black}
                sx={{ width: '10px', height: '10px' }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack rowGap={'32px'} minWidth="360px">
              <Divider
                sx={{
                  borderColor: theme.palette.neutral[600],
                  marginTop: '8px',
                }}
              />
              <Stack rowGap="16px">
                <Stack direction={'row'} columnGap="16px" alignItems={'center'}>
                  <BenaRating
                    name="cooperation"
                    defaultValue={reviewData.cooperation}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'number')
                        handleChangeReviewData('cooperation', newValue);
                    }}
                    readOnly={viewMode}
                  />
                  <Typography fontSize="16px" color="grey.700" fontWeight="500">
                    {intl.formatMessage(Messages.Cooperation)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} columnGap="16px" alignItems={'center'}>
                  <BenaRating
                    name="quality_of_work"
                    defaultValue={reviewData.quality_of_work}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'number')
                        handleChangeReviewData('quality_of_work', newValue);
                    }}
                    readOnly={viewMode}
                  />
                  <Typography fontSize="16px" color="grey.700" fontWeight="500">
                    {intl.formatMessage(Messages.QualityOfWork)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} columnGap="16px" alignItems={'center'}>
                  <BenaRating
                    name="adherence_to_schedule"
                    defaultValue={reviewData.adherence_to_schedule}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'number')
                        handleChangeReviewData(
                          'adherence_to_schedule',
                          newValue
                        );
                    }}
                    readOnly={viewMode}
                  />
                  <Typography fontSize="16px" color="grey.700" fontWeight="500">
                    {intl.formatMessage(Messages.AdherenceToSchedule)}
                  </Typography>
                </Stack>
                <Typography fontSize="14px" color="grey.700" fontWeight="500">
                  {intl.formatMessage(Messages.ShareYourExperience)}
                </Typography>
                <textarea
                  style={{
                    border: '1px solid' + theme.palette.neutral[600],
                    fontSize: '14px',
                    fontFamily: 'Outfit',
                    height: '110px',
                  }}
                  defaultValue={reviewData.experience}
                  autoFocus
                  onChange={(e) =>
                    handleChangeReviewData('experience', e.target.value)
                  }
                  rows={3}
                  readOnly={viewMode}
                ></textarea>
              </Stack>
              <Divider
                sx={{
                  borderColor: theme.palette.neutral[600],
                  marginTop: '8px',
                }}
              />
              {!viewMode && (
                <Stack
                  columnGap={'16px'}
                  direction={'row'}
                  sx={{ '&>*': { flexGrow: 1 } }}
                >
                  <Button
                    className={`border-radius-8px ${gs.buttonCancellation}`}
                    onClick={() => {
                      setOpen(false);
                      onCancel();
                    }}
                    disabled={loading}
                  >
                    {intl.formatMessage(Messages.cancel)}
                  </Button>
                  <Button
                    className={gs.buttonPrimary}
                    onClick={() => patchApplication(reviewData)}
                    disabled={loading}
                  >
                    {intl.formatMessage(Messages.Confirm)}
                  </Button>
                </Stack>
              )}
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
