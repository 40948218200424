import { useIntl } from 'react-intl';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import {
  Box,
  Button,
  Divider,
  Typography,
  OutlinedInput,
  InputAdornment,
  Alert,
  Link,
  capitalize,
} from '@mui/material';
import { useGlobalStyles } from '../../assets/styles/style';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserAccountMessages } from './Messages';
import { MAXEMAILADDRESSLENGTH, ValidateRules } from '../../utils/common';
import {
  staticURLAccount,
  staticURLForgotPassword,
  staticURLRoot,
} from '../../route/urls';
import { useLazyGetUserQuery, useLoginMutation } from '../../redux/benaApi';
import { useAppDispatch, useTypedSelector } from '../../redux/store';
import { setLogin } from './AuthSlice';
import { MutationHandler } from '../../redux/benaApiResultHandler';
import LogoAndLanguage from './LogoAndLanguage';
import { showMessage, ShowSuccessMessage } from '../../components/ShowMessage';
import ErrorMessages from '../../redux/benaApiResponseCode';
import { envResolverAPIURL } from '../../env';
import tiktokSvg from '../../assets/imgs/tiktok-fixed.svg';
import { Messages } from '../../localization/Messages';
import { wkwebviewLogin } from '../../redux/wkwebviewBridge';
import AppleSSOButton from './AppleSSO';
import { GoogleSSOButton } from './GoogleSSOButton';

export default function Login() {
  const theme = useTheme();
  const intl = useIntl();
  const globalStyles = useGlobalStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getUser, getUserResult] = useLazyGetUserQuery();
  const [searchParams] = useSearchParams();
  const accessParam = searchParams.get('access');
  const refreshParam = searchParams.get('refresh');
  const accessParamExpires = searchParams.get('access_expires') || '0';
  const refreshParamExpires = searchParams.get('refresh_expires') || '0';
  const auth = useTypedSelector((root) => root.auth);

  useEffect(() => {
    const logout_status_code = searchParams.get('logout_session_limit');
    if (logout_status_code === 'true') {
      showMessage.warning(
        intl.formatMessage(UserAccountMessages.forceLogoutBySessionLimit)
      );
    }
  }, []);

  // if user already login, redirect to root page
  useEffect(() => {
    if (
      auth.user?.id &&
      // prevent login loop because of fetch token failed??
      (auth.refresh_token_expires_at || 0) * 1000 > Date.now()
    ) {
      wkwebviewLogin(auth.access || '', auth.user?.id);
      navigate(staticURLRoot);
    }
  }, []);

  const handleLoginSuccess = useCallback(
    (data: LoginResponseType) => {
      setTimeout(async () => {
        dispatch(setLogin(data));
        let localState = localStorage.getItem('plugin:state');
        let localRedirect = sessionStorage.getItem('plugin:redirect');
        if (localState && window.opener) {
          localStorage.removeItem('plugin:state');
          window.opener.postMessage( { type: 'login', data }, "*" );
          window.close();
        }else if (localRedirect) {
          sessionStorage.removeItem('plugin:redirect');
          window.postMessage( { type: 'bena-login', data }, "*" );
          if(window.opener){
            window.close();
          }else{
            setTimeout(() => { window.location.href = localRedirect as string; }, 200);
          }
        }else{
          const qs = new URLSearchParams(window.location.search);
          navigate(qs.get('redirect') || staticURLRoot, { replace: true });
        }
      });
    },
    [dispatch, navigate]
  );

  // get user data if access token exists in url params
  useEffect(() => {
    if (accessParam) getUser({ access: accessParam });
  }, [accessParam, getUser]);

  // if access token in searchParams successfully retrieves user then auto-login
  useEffect(() => {
    if (
      getUserResult.isSuccess &&
      getUserResult.data?.status?.status_code === 1000 &&
      accessParam &&
      refreshParam
    ) {
      handleLoginSuccess({
        user: getUserResult.data.data,
        refresh: refreshParam,
        access: accessParam,
        refresh_token_expires_at: parseInt(refreshParamExpires) || 0,
        access_token_expires_at: parseInt(accessParamExpires) || 0,
      });
      const userid = getUserResult.data.data?.id;
      wkwebviewLogin(accessParam || '', userid);
    }
  }, [
    getUserResult,
    accessParam,
    refreshParam,
    handleLoginSuccess,
    refreshParamExpires,
    accessParamExpires,
  ]);

  // if tiktok user has no video post, it will trigger the error
  // and redirect user to login page
  const errorParam = searchParams.get('error');
  useEffect(() => {
    if (errorParam === '4045') {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4045));
    } else if (errorParam === '4050') {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4050));
    } else if (errorParam === '4062') {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4062));
    } else if (errorParam === '4015') {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4015));
    } else if (errorParam === '4016') {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4016));
    } else if (errorParam === '4048') {
      showMessage.error(intl.formatMessage(ErrorMessages.responseCode4048));
    }
  }, [errorParam]);

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputDataType,
    key: string
  ) => {
    const str = event.target.value.slice(0, data.maxLength);
    setFormData({
      ...formData,
      [key]: {
        ...data,
        content: str,
        length: event.target.value.length,
        error: !ValidateRules.validInputString(
          str,
          data.maxLength,
          data.inputValueType
        ),
      },
    });
  };

  const [formData, setFormData] = useState<LoginType>({
    email: {
      content: '',
      maxLength: MAXEMAILADDRESSLENGTH,
      error: false,
      inputValueType: 'email',
    },
    password: {
      content: '',
      maxLength: 80,
      error: false,
      showPassword: false,
      inputValueType: 'anythingexceptempty',
    },
  });

  const togglePasswordVisibility = (
    key: string,
    data: PasswordInputDataType
  ) => {
    setFormData({
      ...formData,
      [key]: {
        ...data,
        showPassword: !data.showPassword,
      },
    });
  };

  const refEmail = useRef<HTMLElement>(null);
  const refPassword = useRef<HTMLElement>(null);
  useEffect(() => {
    refEmail.current?.focus();
  }, []);

  const inputList = [
    {
      item: formData.email,
      message: UserAccountMessages.emailAddress,
      name: 'email',
      refEl: refEmail,
      placeHolder: UserAccountMessages.enterEmailAddress,
      children: (
        <>
          {/* {`${formData.email.content.length} / ${formData.email.maxLength}`} */}
        </>
      ),
      showPasswordInType: 'text',
    },
    {
      item: formData.password,
      message: UserAccountMessages.password,
      name: 'password',
      refEl: refPassword,
      placeHolder: UserAccountMessages.enterAPassword,
      children: (
        <Box
          onClick={() =>
            togglePasswordVisibility('password', formData.password)
          }
          className="cursorHand"
        >
          {formData.password.showPassword ? (
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.primary.main }}
            >
              {intl.formatMessage(UserAccountMessages.hide)}
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.primary.main }}
            >
              {intl.formatMessage(UserAccountMessages.show)}
            </Typography>
          )}
        </Box>
      ),
      showPasswordInType: formData.password.showPassword ? 'text' : 'password',
    },
  ] as Array<FormInputTypeMix>;

  const isValidForSave = function (saveFormData = true): boolean {
    for (let i = 0; i < inputList.length; i++) {
      const { item: input, refEl: ref, name: key } = inputList[i];

      if (
        !ValidateRules.validInputString(
          input.content,
          input.maxLength,
          input.inputValueType
        )
      ) {
        if (saveFormData) {
          setFormData({
            ...formData,
            [key]: { ...input, error: true },
          });
          ref.current?.focus();
        }
        return false;
      }
    }

    return saveFormData ? true : !submitting;
  };

  const [login] = useLoginMutation();
  const [submitting, setSubmitting] = useState(false);

  const handleContinue = async () => {
    if (isValidForSave()) {
      setSubmitting(true);
      (await MutationHandler({
        intl,
        action: async () =>
          await login({
            email: formData.email.content,
            password: formData.password.content,
          }).unwrap(),
        onSuccess: (data) => {
          handleLoginSuccess(data);
          wkwebviewLogin(data.access || '', data.user?.id);
        },
      })) && setSubmitting(false);
    }
  };

  const message =
    new URLSearchParams(window.location.search)?.get('reset_password_status') ||
    '';

  return (
    <Box sx={{ position: 'relative' }}>
      <LogoAndLanguage />
      <ShowSuccessMessage
        message={message.toUpperCase()}
        sx={{ position: 'absolute', top: '4rem' }}
      />
      {errorParam === '4045' && (
        <>
          <Box sx={{ height: '1rem' }}></Box>
          <Alert variant="filled" severity="error">
            {errorParam === '4045'
              ? intl.formatMessage(ErrorMessages.responseCode4045)
              : ''}
          </Alert>
        </>
      )}

      <Box sx={{ mt: '2.5rem' }} className="form-width">
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
          {intl.formatMessage(UserAccountMessages.loginText)}
        </Typography>
        <Box
          className={'boxcenterhv'}
          sx={{ justifyContent: 'flex-start', mt: '0.5rem', mb: 3 }}
        >
          <Typography variant="subtitle1">
            {intl.formatMessage(UserAccountMessages.dontHaveAnAccount)}
          </Typography>
          <Link href={staticURLAccount} underline={'none'}>
            <Typography
              color={theme.palette.primary.main}
              variant="subtitle1"
              sx={{ ml: 1 }}
              className={'hand'}
            >
              {capitalize(intl.formatMessage(Messages.singUp))}
            </Typography>
          </Link>
        </Box>
        <form>
          {inputList.map((data) => {
            const {
              item,
              message,
              name,
              refEl,
              placeHolder,
              children,
              showPasswordInType,
            } = data;
            return (
              <Box
                key={message.id}
                className={'boxcenterhv'}
                sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <Box className="boxcenterhv" sx={{ width: '100%', mb: 1.75 }}>
                  <Typography
                    sx={{
                      fontSize: '0.625rem',
                      fontWeight: '600',
                    }}
                    className={'boxcenterhv'}
                  >
                    {intl.formatMessage(message).toUpperCase()}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>
                  {name === 'password' && (
                    <Link href={staticURLForgotPassword} underline="none">
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: 600,
                        }}
                      >
                        {intl.formatMessage(UserAccountMessages.forgotPassword)}
                      </Typography>
                    </Link>
                  )}
                </Box>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    placeholder={intl.formatMessage(placeHolder)}
                    inputRef={refEl}
                    type={showPasswordInType}
                    className={`border-radius-6px input-size`}
                    id={message.id}
                    value={item.content}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleTextInputChange(event, item, name);
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={(theme) => ({ fontSize: theme.typography.caption })}
                      >
                        {children}
                      </InputAdornment>
                    }
                    error={item.error}
                    fullWidth
                  />
                </FormControl>
              </Box>
            );
          })}
          <Box>
            <Button
              type="submit"
              sx={{
                mt: 2,
              }}
              variant="contained"
              className={`border-radius-6px button-size`}
              onClick={(e) => {
                e.preventDefault();
                handleContinue();
              }}
              disabled={!isValidForSave(false)}
            >
              {intl.formatMessage(UserAccountMessages.loginText)}
            </Button>
          </Box>
        </form>
        <Box sx={{ height: '32px', marginY: '16px' }} className="boxcenterhv">
          <Divider
            sx={{
              borderColor: theme.palette.neutral[600],
              my: '1rem',
              width: '100%',
            }}
          >
            <Typography fontSize="12px" fontWeight="500">
              {intl.formatMessage(Messages.CreatorTryLoginWith)}
            </Typography>
          </Divider>
        </Box>
        <Button
          sx={{
            mb: 2,
            color: theme.palette.base.white2white,
            bgcolor: theme.custom.black,
            ':hover': {
              color: theme.palette.base.white2white,
              bgcolor: theme.custom.black,
            },
            textTransform: 'none',
          }}
          className={`border-radius-6px button-size`}
          onClick={() => {
            window.location.href = `${envResolverAPIURL()}auth/sso?platform=tiktok&role=creator`;
          }}
        >
          <Box
            className="boxcenterhv"
            sx={{ width: '12rem', fontSize: '16px' }}
          >
            <img
              src={tiktokSvg}
              alt="tiktok"
              style={{
                width: '14px',
                height: '14px',
                marginRight: '0.5rem',
              }}
            />
            {intl.formatMessage(Messages.continueWithTiktok)}
          </Box>
        </Button>
        <GoogleSSOButton isLogin={true} width={'12rem'} />
        <Box marginBottom="16px"></Box>
        <AppleSSOButton />
      </Box>
    </Box>
  );
}
