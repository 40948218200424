import { IconButton, Theme, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ScrollToTopIcon } from '../assets/imgs/icons';
import { createStyles, makeStyles } from '@mui/styles';

export default function ScrollToTopButton() {
  const theme = useTheme();
  const classes = useStyles();
  const [position, setPosition] = useState(0);
  const scrollHandler = () => {
    setPosition(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return position > 800 ? (
    <IconButton
      onClick={handleScrollToTop}
      sx={{ zIndex: 1, position: 'fixed' }}
      className={classes.scrollToTopButton}
    >
      <ScrollToTopIcon fill={theme.palette.base.main2white} />
    </IconButton>
  ) : (
    <></>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollToTopButton: {
      position: 'fixed',
      right: `calc(50% - ${
        Math.min(theme.breakpoints.values.xl, window.outerWidth - 156) / 2 + 48
      }px)`,
      bottom: '32px',
      backgroundColor: theme.palette.base.white2main,
      borderRadius: '50%',
    },
  })
);
