import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../../redux/store';
import { staticURLShortList } from '../../route/urls';
import { envResolverAPIURL } from '../../env';

// This store stores a shortlist "My Creators"

export type CreatorSliceType = {
  shortList: { id: string; name: string }[];
};

type ShortListApiType = {
  status: { status_code: number; message: string; stacktrace: string };
  data: shortListResponseType;
};

const initialState: CreatorSliceType = {
  shortList:[]
};



export const getShortList = createAsyncThunk(
  'getShortList',
  async (_,{ getState } ) => {
    const auth = (getState() as RootState).auth;
    const response = await fetch(
      envResolverAPIURL() + staticURLShortList,
      { method: 'GET', headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.access}`
      } }
    );
    return response.json();
  }
);

export const createShort = createAsyncThunk(
  'createShort',
  async (name: string, { getState }) => {
    const auth = (getState() as RootState).auth;
    const response = await fetch(
      envResolverAPIURL() + staticURLShortList,
      { method: 'POST', headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.access}`
      }, body: JSON.stringify({ name }) }
    );
    return response.json();
  }
);

const slice = createSlice({
  name: 'shortList',
  initialState,
  reducers: {
    setSelectedShortList(state: CreatorSliceType, action: PayloadAction<{ id: string; name: string }[]>) {
      state.shortList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getShortList.fulfilled, (
      state: CreatorSliceType,
      action: PayloadAction<ShortListApiType>
    ) => {
      const arr = action.payload.data.items.map((item) => {
        return { id: item.id, name: item.name };
      });
      state.shortList = arr;
    })
    .addCase(getShortList.rejected, (state, action) => {
      console.log(action);
    })
    .addCase(createShort.fulfilled, (state, action) => {
      
      const item = action.payload.data;
      if(!item.id) return;
      state.shortList = [{ id: item.id, name: item.name }, ...state.shortList];
    })
    .addCase(createShort.rejected, (state, action) => {
      console.log(action.payload);
    });

  }
});

export const { setSelectedShortList } = slice.actions;

export const selectShortList = createSelector(
  [(state: RootState) => state.shortList],
  (state) => state
);

export default slice.reducer;
