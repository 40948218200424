import { defineMessages, IntlShape } from 'react-intl';
export const ApiResponseCodePrex = 'responseCode';
/**
 * error message format
 *  - prefix: responseCode
 *  - server error code: 4xxx
 *  - UI error code: 8xxx
 */
const ErrorMessages = defineMessages({
  responseCode1000: {
    id: 'responseCode1000',
    defaultMessage: 'Success',
  },
  responseCode4000: {
    id: 'responseCode4000',
    defaultMessage: 'Bad Request',
  },
  responseCode4001: {
    id: 'responseCode4001',
    defaultMessage: 'Unknown Error',
  },
  responseCode4002: {
    id: 'responseCode4002',
    defaultMessage: 'Unauthorized',
  },
  responseCode4003: {
    id: 'responseCode4003',
    defaultMessage: 'Internal Server Error',
  },
  responseCode4004: {
    id: 'responseCode4004',
    defaultMessage: 'Not Found',
  },
  responseCode4005: {
    id: 'responseCode4005',
    defaultMessage: 'Invalid Payload',
  },
  responseCode4006: {
    id: 'responseCode4006',
    defaultMessage: 'Authentication Failed',
  },
  responseCode4007: {
    id: 'responseCode4007',
    defaultMessage: 'Verification Failed! Invalid or expired code',
  },
  responseCode4008: {
    id: 'responseCode4008',
    defaultMessage: 'Invalid Email',
  },
  responseCode4009: {
    id: 'responseCode4009',
    defaultMessage: 'Email Exists',
  },
  responseCode4010: {
    id: 'responseCode4010',
    defaultMessage: 'Wrong Password',
  },
  responseCode4011: {
    id: 'responseCode4011',
    defaultMessage: 'User Not Found',
  },
  responseCode4012: {
    id: 'responseCode4012',
    defaultMessage: 'Email Not Found',
  },
  responseCode4013: {
    id: 'responseCode4013',
    defaultMessage: 'Duplicate Found',
  },
  responseCode4014: {
    id: 'responseCode4014',
    defaultMessage: 'Re-Submission is Not Allowed',
  },
  responseCode4015: {
    id: 'responseCode4015',
    defaultMessage:
      'Bena can not retrieve your public video data. Please make sure you have public video posted and switched on "Edit access" -> "Read your public videos on TikTok".',
  },
  responseCode4016: {
    id: 'responseCode4016',
    defaultMessage:
      'Reading your public videos on TikTok is required in order to register via TikTok, please grant this access and try again.',
  },
  responseCode4017: {
    id: 'responseCode4017',
    defaultMessage:
      'The phone number your provided is not valid for the country code you have selected.',
  },
  responseCode4018: {
    id: 'responseCode4018',
    defaultMessage: 'Maximum 5 files allowed',
  },
  responseCode8001: {
    id: 'responseCode8001',
    defaultMessage:
      'An invitation already exists between the creator and campaign',
  },
  responseCode4038: {
    id: 'responseCode4038',
    defaultMessage:
      'Access token was blacklisted due to reaching their session limit.',
  },
  responseCode4039: {
    id: 'responseCode4039',
    defaultMessage:
      'Your last payment is incomplete. Check your subscription status.',
  },
  responseCode4040: {
    id: 'responseCode4040',
    defaultMessage:
      'Your last payment is incomplete and expired. Check your subscription status.',
  },
  responseCode4041: {
    id: 'responseCode4041',
    defaultMessage: 'no message alert for trialing status',
  },
  responseCode4042: {
    id: 'responseCode4042',
    defaultMessage:
      "error returned if the brand user's subscription has the 'past_due' status",
  },
  responseCode4043: {
    id: 'responseCode4043',
    defaultMessage:
      "error returned if the brand user's subscription has the 'canceled' status",
  },
  responseCode4044: {
    id: 'responseCode4044',
    defaultMessage:
      'An unexpected error occurred while logging in via TikTok. Please try again or contact support@bena.live for help.',
  },
  responseCode4045: {
    id: 'responseCode4045',
    defaultMessage:
      'An unexpected error occurred while logging in via TikTok. Please try again or contact support@bena.live for help.',
  },
  responseCode4048: {
    id: 'responseCode4048',
    defaultMessage:
      'It seems like you have already used your TikTok account login directly before, please logout first and choose "Sign in with TikTok" to proceed, rather than email login.',
  },
  responseCode4050: {
    id: 'responseCode4050',
    defaultMessage:
      'The registration failed! Unable to retrieve the user id after multiple retries.',
  },
  responseCode4052: {
    id: 'responseCode4052',
    defaultMessage:
      'Ratelimit exceeded, please wait a minute before generating new content.',
  },
  responseCode4053: {
    id: 'responseCode4053',
    defaultMessage: 'Your credits has run out.',
  },
  responseCode4054: {
    id: 'responseCode4054',
    defaultMessage: 'Invalid email, please use a company email address.',
  },
  responseCode4060: {
    id: 'responseCode4060',
    defaultMessage:
      'You are running out of credits. Please subscribe to a paid plan to continue or buy extra credits if subscribed.',
  },
  responseCode4061: {
    id: 'responseCode4061',
    defaultMessage: `You have reached the search limit.\nUpgrade the account to continue. [URL:staticURLAccount]`,
  },
  responseCode4062: {
    id: 'responseCode4062',
    defaultMessage:
      'An unexpected error occurred while logging in. Please try again by navigating back to your Shopify store to login via the Bena app, or contact support@bena.live for help.',
  },
  // UI error code
  uiErrorCode8001: {
    id: 'uiErrorCode8001',
    defaultMessage: 'Network Error!',
  },
});

export const SubscriptionStripeErrorCode = [] as number[];
export const SubscriptionLimitErrorCodeList = [] as number[];

export const SubscriptionLimitErrorCode = [
  ...SubscriptionLimitErrorCodeList,
  ...SubscriptionStripeErrorCode,
];

export function getSubscriptionLimitDescription(
  errorCode: number,
  intl: IntlShape
) {
  return {}[errorCode] || '';
}

export function getSubscriptionStripeErrorDescription(
  errorCode: number,
  intl: IntlShape
) {
  return {}[errorCode] || '';
}

export const UIErrorCode = [8001];

export function getUIErrorDescription(errorCode: number, intl: IntlShape) {
  return (
    {
      8001: intl.formatMessage(ErrorMessages.uiErrorCode8001),
    }[errorCode] || ''
  );
}

export default ErrorMessages;
