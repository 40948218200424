import { useEffect, useState } from 'react';
import { BenaBadge } from '../../components/BenaBadge';
import { EmailRedirectionIcon } from '../../assets/imgs/icons';
import { IconButton, useTheme, Box } from '@mui/material';
import { getEmaliUnseenCount } from "../modules/customAPICalls";
import { useLazyGetEmailUrlQuery } from "../../redux/benaApi";

export default function EmailUnseenCountIcon({
  disabled = false
}:{
  disabled?: boolean
}) {
  const theme = useTheme();
  const [emailUnseenCount, setEmailUnseenCount] = useState<number>(0);
  const [query, refetch] = useLazyGetEmailUrlQuery();
  useEffect(()=>{
    query({}).then((res) => {
      if(!res.data) return;
      document.cookie = "webmail="+res.data?.data.cookie.webmail+"; path=/";
      getEmaliUnseenCount((count) => {
        setEmailUnseenCount(count);
      });
    });
    
  },[]);
  return (
    <Box component="span" position={'relative'}>
      <BenaBadge sx={{textAlign: 'center'}}
        badgeContent={emailUnseenCount > 99 ? '99+' : emailUnseenCount}
      >
        <IconButton sx={{ p: 1 }} disabled={disabled}>
          <EmailRedirectionIcon sx={{ width: '22px', height: '18px', color:"transparent" }}
            stroke={theme.palette.base.main2white} />
        </IconButton>
      </BenaBadge>
    </Box>
    
  )
}