import {
  Avatar,
  Box,
  capitalize,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { Fragment, RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { PlatformsIcon } from '../../config/platforms';
import { AIGCDialogContext } from '../../context/context';
import { Messages } from '../../localization/Messages';
import { calcEngagementRate } from '../../utils/common';
import { setSelectedCreatorId } from '../aigc/AIGCSlice';
import BrandLabel from "../creators/brandLabel"
import BrandLabelList from "../creators/brandLabelList"
import CreatorCheckList from "../modules/creatorCheckList"

function HilightKeyword(keyword: string, str: string,color: string) {
  if (!keyword) return str;
  const arr = str.toLowerCase().split(keyword.toLowerCase());
  const keywordEl = (
    <Typography component="span" color={color}>
      {keyword}
    </Typography>
  );
  return (
    <>
      {arr.map((w, index) => (
        <Fragment key={index + w}>
          <Typography component="span">{w}</Typography>
          {index < arr.length - 1 && keywordEl}
        </Fragment>
      ))}
    </>
  );
}

type SuggestListProps = {
  keyword: string;
  resultArray?: DynamicSearchResultArrayType;
  setManual: (state: boolean) => void;
  inputBash: RefObject<HTMLInputElement>;
};
export default function SuggestList({
  keyword,
  resultArray,
  setManual,
  inputBash
}: SuggestListProps) {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const border = { borderBottom: '1px solid ' + theme.palette.neutral[700] };
  const { notifyCreator } = useContext(AIGCDialogContext);
  // dispatch creator to aigcslice to show creator aigc dialog
  const showAIGC = (creator: CreatorData) => {
    notifyCreator(creator);
    dispatch(setSelectedCreatorId(creator.id));
  };
  const arr = resultArray || [];
  const [shortListOnlone,setShortListOnlone] = useState<string[][]>([]);
  const [labelState, setLabelState] = useState<number>(-1);
  const [shortLocal, setShortLocal] = useState<string[]>([]);
  const [open, setOpenC] = useState<number>(-1);
  useEffect(() => {
    const shortArr = arr.map((creator) => {
      return creator.shortlist || [];
    });
    setShortListOnlone([...shortArr]);
  }, [arr]);
  const ret = arr.slice(0, 10).map((creator, index) => {
    const isFirst = index === 0;
    const isLast = arr.length === index + 1;
    const { id, name, picture, unique_id, profile_type, profile, sponsored_brand} = creator;
    const {
      followers: _followers,
      engagement_rate: _engagementRate,
      engagements: _engagements,
      average_er: _averageEr,
      avg_views: _avgViews,
    } = profile;
    const followers = intl.formatNumber(_followers, {
      notation: 'compact',
    });
    const engagementRate = calcEngagementRate(_averageEr || _engagementRate);
    const engagements = intl.formatNumber(_engagements, {
      notation: 'compact',
    });
    const avg_views = intl.formatNumber(_avgViews || 0, {
      notation: 'compact',
    });
    // const sponsored_brand = ['Nike','Adidas','Puma','Reebok','under armour','new balance',
    //   'asics','converse','vans','fila','jordan','yeezy','balenciaga'];
    const brandList = sponsored_brand || [];
    const sponsoredList = brandList.slice(0,3) || [];
    const sponsoredCount = brandList.length - 3;
    return (
      <ListItem
        alignItems="flex-start"
        sx={{
          cursor: 'pointer',
          ...(isLast ? {} : border),
          '&:hover': { backgroundColor: theme.palette.neutral['200_2'] },
          borderRadius: `${isFirst ? '1' : '0'}rem ${
            isFirst ? '1' : '0'
          }rem 0rem 0rem`,
          backgroundColor: theme.palette.base[3],
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          showAIGC(creator);
        }}
        key={id}
      >
        <ListItemAvatar>
          <Avatar alt={name} src={picture} />
        </ListItemAvatar>
        <ListItemText
          primary={HilightKeyword(keyword, name, theme.palette.warning['500'])}
          secondary={
            <>
              <Stack direction={'row'} alignItems="center" flexWrap={'wrap'} spacing="0.25rem" component="span">
                {Object.keys(PlatformsIcon).find(
                  (w) => w === profile_type.toLowerCase()
                ) &&
                  PlatformsIcon[
                    profile_type.toLowerCase() as keyof typeof PlatformsIcon
                  ]}
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  color="grey.50"
                >
                  @{HilightKeyword(keyword, unique_id, theme.palette.warning['500'])}
                </Typography>
              </Stack>
            </>
          }
          sx={{ width: '12rem' }}
        />
        <ListItemText
          primary={capitalize(intl.formatMessage(Messages.followers))}
          secondary={
            <>
              <Typography sx={{ display: 'inline' }} component="span" color="grey.50">
                {followers}
              </Typography>
            </>
          }
          sx={{ minWidth: '6.5rem' }}
        />
        <ListItemText
          primary={capitalize(intl.formatMessage(Messages.avgViews))}
          secondary={
            <>
              <Typography sx={{ display: 'inline' }} component="span" color="grey.50" >
                {avg_views}
              </Typography>
            </>
          }
          sx={{ minWidth: '6.5rem' }}
        />
        <ListItemText
          primary={capitalize(intl.formatMessage(Messages.engagement))}
          secondary={
            <>
              <Typography sx={{ display: 'inline' }} component="span" color="grey.50" >
                {engagements} ({engagementRate})
              </Typography>
            </>
          }
          sx={{ minWidth: '6.5rem' }}
        />
        <ListItemText
          primary={capitalize(intl.formatMessage(Messages.PromotedBrands))}
          secondary={
            <>
              <Typography sx={{ position: "relative", marginTop:"2px" }} component="span">
                <Stack direction="row" gap="10px" component="span">
                  { sponsoredList.map((brand, index) =>
                    <BrandLabel key={index} brand={brand} color={theme.palette.base.black} bgColor={theme.palette.neutral["300"]} />
                  ) }
                  {sponsoredCount > 0 && 
                    <BrandLabel
                      brand={'+ '+ sponsoredCount}
                      color={theme.palette.neutral["200"]} 
                      bgColor={theme.palette.grey["400"]}
                      onClick={(e)=>{
                        e.stopPropagation();
                        e.preventDefault();
                        setManual(true);
                        setLabelState(index);
                      }} />
                  }
                </Stack>
                {labelState === index && 
                  <BrandLabelList
                    brandList={brandList} 
                    closeLabelBox={()=>{
                      setLabelState(-1);
                      // setManual(false);
                      inputBash.current?.focus();
                    }}
                    bottom="1.75rem"
                  />}
              </Typography>
            </>
          }
          sx={{ minWidth: '6.5rem' }}
        />
        <Box sx={{ flexGrow: 10 }} ></Box>
        
        <Typography sx={{
          lineHeight:'42px', margin:"6px 0",position: "relative"
        }} >
          <Typography component="span" color={theme.palette.primary['main_white']}
            sx={{fontSize: "14px", fontWeight: "600"}}
            onClick={(e)=>{
            e.stopPropagation();
            e.preventDefault();
            setShortLocal([ ...shortListOnlone[index] ])
            setManual(true);
            setOpenC(index);
          }}>
            {intl.formatMessage(Messages.AddToList)}
          </Typography>
          
          { open === index && 
            <CreatorCheckList
              uid={ creator.uid as string }
              shortLocal={shortLocal}
              setShortLocal={(list)=>{
                let arrs = [...shortListOnlone];
                arrs[index] = list;
                setShortListOnlone(arrs);
              }}
              setOpen={()=>{
                setOpenC(-1);
                // setManual(false);
                inputBash.current?.focus();
              }}
              posLeft='auto'
              posRight='0'
              />}
        </Typography>
      </ListItem>
    );
  });

  return (
    <List
      sx={{
        width: '100%',
        backgroundColor: theme.palette.primary['2'],
        border: 0,
        borderRadius: '1rem !important',
        '&>li': {
          borderLeft: `0.0625rem solid transparent`,
          borderRight: `0.0625rem solid transparent`,
          borderBottom: `0.0625rem solid ${theme.palette.neutral['600_1']}`,
        },
        '&>li:last-of-type': {
          borderBottom: `0.0625rem solid transparent`,
          borderBottomLeftRadius: '0.625rem',
          borderBottomRightRadius: '0.625rem',
          borderTop: '0 !important',
        },
        '&>li:first-of-type': {
          borderTop: `0.0625rem solid transparent`,
          borderTopLeftRadius: '0.625rem',
          borderTopRightRadius: '0.625rem',
        },
      }}
      disablePadding
    >
      {arr.length > 0 ? (
        ret
      ) : (
        <Typography textAlign={'center'} padding="2rem">
          {capitalize(intl.formatMessage(Messages.NoDataToShow))}
        </Typography>
      )}
    </List>
  );
}
