import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Divider,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalStyles } from '../../assets/styles/style';
import { useIntl } from 'react-intl';
import { CheckThinIcon, CrossIcon, File3Icon } from '../../assets/imgs/icons';
import {
  CreatorJobContractPatchFile,
  CreatorJobContractUploadWorks,
} from '../../components/FileUploader';
import { TransitionUp } from '../../components/Transitions';
import { Messages } from '../../localization/Messages';
import { useGetJobContract } from '../../hooks/jobcontract';
import Loading from '../../components/Loading';
import { useIsRoleBrand, useIsRoleCreator } from '../../hooks/account';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { UrlThumbnailDownloadIcon } from '../../components/UrlThumbnail';
import LoadingButton from '@mui/lab/LoadingButton';
import LightTooltip from '../../components/LightTooltip';
import { usePatchJobContractDraftMutation } from '../../redux/benaApi';
import { MutationHandler } from '../../redux/benaApiResultHandler';

type UploadCampaignWorkProps = {
  jobContractId: string;
  open: boolean;
  setClose: fnVoidToVoid;
};
// for creator upload work, and for brand to review work
export function UploadCampaignWork({
  jobContractId,
  open,
  setClose,
}: UploadCampaignWorkProps) {
  const theme = useTheme();
  const intl = useIntl();
  const gc = useGlobalStyles();
  const isMobile = useBreakpointXs();
  const isBrand = useIsRoleBrand();
  const isCreator = useIsRoleCreator();

  const {
    refetch: refetchJobcontractData,
    data: jobContractData,
    loading,
  } = useGetJobContract(jobContractId);
  useEffect(() => {
    refetchJobcontractData(jobContractId);
  }, [jobContractId]);

  const filteredDrafts = jobContractData?.drafts;

  const [comments, setComments] = useState<{ [key: string]: string }>({});
  const [uploadings, setUploadings] = useState<{
    [key: string]: {
      progress: number;
      triggerUpload: number;
      triggerReload: number;
    };
  }>({});
  useEffect(() => {
    if (Array.isArray(filteredDrafts)) {
      const newComments = { ...comments };
      filteredDrafts.forEach((w) => {
        newComments[w.id] = w.comment;
      });
      setComments(newComments);

      const obj = { ...uploadings };
      filteredDrafts.forEach((w) => {
        obj[w.id] = { progress: 0, triggerUpload: 0, triggerReload: 0 };
      });
      setUploadings(obj);
    }
  }, [filteredDrafts]);

  const statusText = useCallback(
    (status: string | null) =>
      status === 'rejected'
        ? intl.formatMessage(Messages.Rejected)
        : status === 'accepted'
        ? intl.formatMessage(Messages.Approved)
        : [null, undefined, ''].includes(status)
        ? intl.formatMessage(Messages.PendingApproval)
        : '',
    []
  );
  const bgcolors = useMemo(
    () => ({
      rejected: theme.palette.danger[700],
      accepted: theme.palette.success[700],
      default: theme.palette.primary.main,
    }),
    [theme.palette.danger, theme.palette.primary.main, theme.palette.success]
  );

  const [patchDraft, patchDraftResult] = usePatchJobContractDraftMutation();
  const patching = patchDraftResult.isLoading;
  const jobContractFinished = jobContractData?.status === 'finished';

  const handlePatchDraft = (
    draft_id: string,
    status?: string | null,
    comment?: string
  ) => {
    if (jobContractFinished) return;

    MutationHandler({
      intl,
      action: async () => {
        return await patchDraft({
          draft_id,
          body: {
            status,
            comment,
          },
        }).unwrap();
      },
      onSuccess: () => {
        refetchJobcontractData(jobContractId);
      },
    });
  };

  const filesApprovalStatus = (filteredDrafts || []).map((w) => (
    <Stack
      key={w.id}
      padding="16px"
      border={`1px solid ${theme.palette.neutral[700]}`}
      borderRadius="10px"
      rowGap="16px"
    >
      <Stack direction="row" columnGap="16px">
        <Box
          sx={{
            backgroundColor: theme.palette.primary[25],
            width: '40px',
            height: '40px',
            borderRadius: '5px',
            border: `1px solid ${theme.palette.primary[200]}`,
          }}
          className="boxcenterhv"
        >
          <File3Icon />
        </Box>
        <Stack flexGrow="1">
          <Stack direction="row" alignItems="center">
            <Typography
              color="grey.700"
              fontSize="14px"
              fontWeight="500"
              className="ellipsis boxlefthcenterv"
              maxWidth="160px"
              title={w.file?.name || ''}
            >
              {w.file?.name}
            </Typography>
            <UrlThumbnailDownloadIcon
              url={w.file?.file || ''}
              name={w.file?.name || ''}
            />
          </Stack>
          <Typography color="grey.50" fontSize="14px" fontWeight="400">
            {Math.ceil(w.file?.size || 0)}KB
          </Typography>
        </Stack>
        {isCreator && (
          <Stack direction="row" columnGap="4px" alignItems={'center'}>
            {
              <Typography
                color="base.white"
                fontSize="13px"
                fontWeight="500"
                sx={{
                  backgroundColor:
                    bgcolors[w.status as keyof typeof bgcolors] ||
                    bgcolors.default,
                }}
                borderRadius={'4px'}
                component="span"
                padding="2px 4px"
              >
                {statusText(w.status)}
              </Typography>
            }
            {w.status === 'rejected' && (
              <>
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    setUploadings((state) => ({
                      ...state,
                      [w.file?.id]: {
                        ...state[w.file?.id],
                        triggerUpload: Math.random() * 1000,
                      },
                    }));
                  }}
                  sx={{
                    padding: '2px 2px',
                    lineHeight: '19px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: '400',
                  }}
                >
                  Resubmit
                  {uploadings[w.file?.id]?.progress > 0
                    ? `(${Math.ceil(uploadings[w.file?.id]?.progress || 0)}%)`
                    : ''}
                </LoadingButton>
                <CreatorJobContractPatchFile
                  onFilesUploadSuccess={() => {
                    refetchJobcontractData(jobContractId);
                  }}
                  jobContractId={jobContractId}
                  fileId={w.file?.id}
                  triggerUpload={uploadings[w.file?.id]?.triggerUpload || 0}
                  triggerReload={uploadings[w.file?.id]?.triggerReload || 0}
                  onProgress={(progress) => {
                    setUploadings((state) => ({
                      ...state,
                      [w.file?.id]: {
                        ...state[w.file?.id],
                        progress,
                        triggerUpload: 0,
                        triggerReload: 0,
                      },
                    }));
                  }}
                />
              </>
            )}
          </Stack>
        )}
        {isBrand && (
          <Stack direction="row" columnGap="4px" alignItems={'center'}>
            {['accepted', 'rejected'].includes(w.status || '') && (
              <Stack>
                <Typography
                  color="base.white"
                  fontSize="13px"
                  fontWeight="500"
                  sx={{
                    backgroundColor:
                      bgcolors[w.status as keyof typeof bgcolors] ||
                      bgcolors.default,
                  }}
                  borderRadius={'4px'}
                  component="span"
                  padding="2px 4px"
                >
                  {statusText(w.status)}
                </Typography>
                {!jobContractFinished && (
                  <IconButton
                    onClick={() => {
                      handlePatchDraft(w.id, null, '');
                    }}
                    disabled={patching}
                    disableRipple
                  >
                    <Typography
                      fontSize={'14px'}
                      fontWeight="600"
                      color="primary.main"
                    >
                      {intl.formatMessage(Messages.CancelAndQuestionMark)}
                    </Typography>
                  </IconButton>
                )}
              </Stack>
            )}
            {['', null, undefined].includes(w.status) && !jobContractFinished && (
              <>
                <Stack direction="row" columnGap="8px">
                  <LightTooltip
                    title={intl.formatMessage(Messages.ApproveFile)}
                  >
                    <IconButton
                      onClick={() => {
                        handlePatchDraft(w.id, 'accepted', comments[w.id]);
                      }}
                      disableRipple
                      disabled={patching || jobContractFinished}
                    >
                      <CheckThinIcon sx={{ width: '18px', height: '13px' }} />
                    </IconButton>
                  </LightTooltip>
                  <LightTooltip title={intl.formatMessage(Messages.RejectFile)}>
                    <IconButton
                      onClick={() => {
                        handlePatchDraft(w.id, 'rejected', comments[w.id]);
                      }}
                      disableRipple
                      disabled={patching || jobContractFinished}
                    >
                      <CrossIcon
                        stroke={theme.custom.lightRed}
                        sx={{ width: '13px', height: '13px' }}
                      />
                    </IconButton>
                  </LightTooltip>
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Stack>
      {isBrand && (
        <Stack rowGap="8px">
          <Typography color="grey.700" fontSize={'14px'} fontWeight="500">
            {intl.formatMessage(Messages.PleaseExplainWhat)}
          </Typography>
          <textarea
            rows={3}
            style={{
              border: '1px solid ' + theme.palette.neutral[700],
              fontSize: '14px',
              fontFamily: 'Outfit',
              padding: '4px',
              width: '100%',
            }}
            value={comments[w.id]}
            onChange={(e) =>
              setComments((state) => ({ ...state, [w.id]: e.target.value }))
            }
            disabled={patching || jobContractFinished}
          ></textarea>
        </Stack>
      )}
      {isCreator && !!w.comment && (
        <Stack rowGap="8px">
          <Typography color="grey.700" fontSize={'14px'} fontWeight="500">
            {intl.formatMessage(Messages.BrandsComplaint)}
          </Typography>
          <Typography color="grey.700" fontSize={'14px'} fontWeight="400">
            {w.comment}
          </Typography>
        </Stack>
      )}
    </Stack>
  ));
  const noDraftForCheck = (filteredDrafts || []).length <= 0 && (
    <Typography textAlign={'center'}>
      {intl.formatMessage(Messages.NoDraftFound)}
    </Typography>
  );

  const onFilesUploadSuccess = (resArr: FileUploadResponseType[]) => {
    const newResources = uploadedMedisa.slice();
    resArr.forEach((res) => {
      if (newResources.findIndex((item) => item.id === res.id) < 0) {
        newResources.push(res);
      }
    });
    setUploadedMedisa(newResources);
    refetchJobcontractData(jobContractId);
    return;
  };

  const onSingleFileDeleteSuccess = (id: string) => {
    const newResources = uploadedMedisa.slice();
    const index = newResources
      .map((item) => item.id)
      .findIndex((item) => item === id);
    newResources.splice(index, 1);
    setUploadedMedisa(newResources);
    refetchJobcontractData(jobContractId);
    return;
  };
  const [uploadedMedisa, setUploadedMedisa] = useState<
    FileUploadResponseType[]
  >([]);

  useEffect(() => {
    setUploadedMedisa([]);
  }, [jobContractId]);

  const fileUpload = (
    <Stack justifyContent={'center'}>
      <Typography color="grey.700" fontSize={'14px'} fontWeight="500">
        {intl.formatMessage(Messages.DraftAsAFile)}
      </Typography>
      <Stack sx={{ py: 3 }} alignItems={'center'} justifyContent={'center'}>
        <CreatorJobContractUploadWorks
          jobContractId={jobContractId}
          cols={4}
          mediaType="attachment"
          onFilesUploadSuccess={onFilesUploadSuccess}
          onSingleFileDeleteSuccess={onSingleFileDeleteSuccess}
          medias={uploadedMedisa}
          triggerUpload={0}
          isJobContractCompleted={false}
        />
      </Stack>
      <Box sx={{ flexGrow: 1 }}></Box>
      <Box
        sx={{
          px: '1.625rem',
          width: '100%',
          my: '1.6rem',
          pt: '1.5rem',
        }}
        className="boxcenterhv"
      ></Box>
    </Stack>
  );

  const title = isBrand
    ? intl.formatMessage(Messages.CheckSubmittedDraft)
    : intl.formatMessage(Messages.SubmitWorks);
  const divider = (mb = '') => (
    <Divider
      sx={{
        borderColor: theme.palette.neutral[600],
        marginTop: '8px',
        marginBottom: mb || '24px',
      }}
    />
  );
  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionUp}
      sx={{ zIndex: theme.zIndex.modal }}
      maxWidth={'lg'}
      disableEscapeKeyDown
      fullScreen={isMobile}
    >
      <DialogTitle display={'flex'}>
        <Typography fontWeight="600" fontSize="16px" marginTop="8px">
          {title}
        </Typography>
        <Box flexGrow={1}></Box>
        <IconButton onClick={setClose}>
          <CrossIcon
            stroke={theme.palette.base.black}
            sx={{ width: '10px', height: '10px' }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflowX: 'hidden' }}>
        <Stack rowGap="16px" minWidth={`${412 - (isMobile ? 48 : 0)}px`}>
          {isBrand && (
            <>
              {divider('8px')}
              <Typography fontSize="14px" fontWeight="400" color="grey.200">
                {intl.formatMessage(Messages.DraftsApprovalNoteForBrand)}
              </Typography>
            </>
          )}
          {divider('8px')}
          {loading ? (
            <Loading />
          ) : (
            <>
              {filesApprovalStatus}
              {noDraftForCheck}
            </>
          )}
          {isCreator && divider('8px')}
          {isCreator && fileUpload}
          {divider()}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '2px 24px 24px 24px' }}>
        <Button
          onClick={setClose}
          className={gc.buttonOutlinedGrey}
          sx={{ width: '100%', height: '40px' }}
          variant="outlined"
        >
          {intl.formatMessage(Messages.Close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
