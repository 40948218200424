// The layout of the first version
import { useIntl } from 'react-intl';
import {
  useTheme,
  Stack
} from '@mui/material';
import { useBreakpointXs } from '../../utils/useStyleHooks';
import { Messages } from '../../localization/Messages';
import { useState } from 'react';
import Masonry from '@mui/lab/Masonry';
import { CreatorCard } from '../../features/creators/CreatorCard';
import { InfoCard } from '../../features/creators/InfoCard';
import ModalAddToCampaigns from '../../features/creators/ModalAddToCampaigns';
import { useGetAIGCTips } from '../../hooks/creators';

type MansonryViewProps = {
  creators: CreatorData[];
  total: number;
  loading: boolean;
  scrollElId?: string;
  deleteCreateData?: ({}:SubassemblyCallbackType) => void;
};
export default function MansonryView({
  creators,
  loading,
  scrollElId = 'infinite-scroll-masonry',
  deleteCreateData
}: MansonryViewProps) {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useBreakpointXs();
  const [creatorForCampaign, setCreatorForCampaign] =
    useState<CreatorData | null>(null);
  const { tips } = useGetAIGCTips();

  const view = (
    <Masonry columns={isMobile ? 3 : 5} spacing={2.5}>
      {creators.slice(0, 1).map((creator) => (
        <CreatorCard
          key={creator.unique_id}
          creator={creator}
          deleteCreateData={ deleteCreateData }
          handleAddToCampaign={setCreatorForCampaign}
        />
      ))}
      <InfoCard
        text={tips[0] || intl.formatMessage(Messages.EnhancedYourRelationship)}
        iconColor={theme.palette.primary['400_1']}
        iconRectColor={theme.palette.primary['400_1']}
        bgcolor={theme.palette.primary[200]}
      />
      {creators.slice(1, 2).map((creator) => (
        <CreatorCard
          key={creator.unique_id}
          creator={creator}
          deleteCreateData={ deleteCreateData }
          handleAddToCampaign={setCreatorForCampaign}
        />
      ))}
      <InfoCard
        text={tips[1] || intl.formatMessage(Messages.TakeAPieceOf)}
        iconColor={theme.palette.secondary[700]}
        iconRectColor={theme.palette.secondary[600]}
        bgcolor={theme.palette.secondary[700]}
      />
      {creators.slice(2).map((creator, index) => (
        <CreatorCard
          key={creator.unique_id + index}
          creator={creator}
          deleteCreateData={ deleteCreateData }
          handleAddToCampaign={setCreatorForCampaign}
        />
      ))}
    </Masonry>
  );

  const showNoData = (
    <Stack direction="row" justifyContent="center" padding={'1rem'}>
      {intl.formatMessage(Messages.NoDataToShow)}
    </Stack>
  );

  return creators.length > 0 ? (
    <Stack
      alignItems="center"
      sx={{
        overflowAnchor: 'none',
      }}
      id={scrollElId}
    >
      {view}
      {creatorForCampaign && (
        <ModalAddToCampaigns
          onClose={() => setCreatorForCampaign(null)}
          open={!!creatorForCampaign}
          creator={creatorForCampaign}
        />
      )}
    </Stack>
  ) : loading ? (
    showNoData
  ) : (
    <></>
  );
}
