import { IconButton, useTheme } from '@mui/material';
import { CrossLineIcon } from '../assets/imgs/icons';

type ClearSearchTextButtonProps = {
  clickHandler: fnVoidToVoid;
  hide?: boolean;
};
export default function ClearSearchTextButton({
  clickHandler,
  hide,
}: ClearSearchTextButtonProps) {
  const theme = useTheme();
  return hide ? (
    <></>
  ) : (
    <IconButton onClick={clickHandler}>
      <CrossLineIcon
        stroke={theme.palette.grey[50]}
        sx={{ width: '1rem', height: '1rem' }}
      />
    </IconButton>
  );
}
