export const isMimePhoto = (contentType: string) =>
  ['image'].some((v) => contentType.startsWith(v));

export const isMimeVideoOrAudio = (contentType: string) =>
  ['video', 'audio'].some((v) => contentType.startsWith(v));

export function isAttachment(name: string) {
  const types = ['.rar', '.zip', '.pdf', '.doc', 'docx'];
  return types.some((w) => name.endsWith(w));
}

export function isPdfFile(name: string) {
  return name.endsWith('.pdf');
}
