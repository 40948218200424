import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { getChatSiteUrl } from '../../env';
import { useLazyGetRocketchatRoomIdQuery } from '../../redux/benaApi';
import { LazyQueryResultHandler } from '../../redux/benaApiResultHandler';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { staticURLMessaging } from '../../route/urls';
import { useAuthUserId, useAuthToken } from '../../hooks/account';

export default function RocketChat() {
  const intl = useIntl();
  const navigate = useNavigate();
  const iframeEl = useRef<HTMLIFrameElement>(null);
  const _initUrl = getChatSiteUrl();
  // add randomId to remove iframe cache, it may solve the problem that rocketchat not trigger iframe login
  const [url, setUrl] = useState(
    _initUrl + '?rdno=' + Math.random().toString(36).slice(2, 10)
  );
  const userId = useAuthUserId();
  const accessToken = useAuthToken();

  // query roomid if with querystring id
  const [query, result] = useLazyGetRocketchatRoomIdQuery({});
  const id = new URLSearchParams(window.location.search).get('id');
  useEffect(() => {
    document.cookie = 'user_id=' + userId + '; path=/';
    // add token cookie for rocket api auto login
    // document.cookie = 'access_token=' + accessToken + '; path=/';
    navigate(`${staticURLMessaging}${id ? `?id=` + id : ''}`);
  }, [url, id, navigate, userId, accessToken]);

  useEffect(() => {
    // remove rocketchat cookies and localstorage data
    document.cookie =
      'rc_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'rc_uid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    localStorage.removeItem('Meteor.loginToken:/:/rchat');
    localStorage.removeItem('Meteor.userId:/:/rchat');
    localStorage.removeItem('Meteor.loginTokenExpires:/:/rchat');
  }, [userId, accessToken]);

  useEffect(() => {
    if (id) {
      query({ to_account: id });
    }
  }, [id]);

  useEffect(() => {
    if (result.isSuccess) {
      LazyQueryResultHandler<GetRocketchatRoomIdResponseType>({
        intl,
        result: result as QueryResultType<GetRocketchatRoomIdResponseType>,
        onSuccess: (resdata) => {
          setUrl(`${_initUrl}/direct/${resdata.rocket_chat_room_id}`);
        },
      });
    }
  }, [result]);

  return (
    <Box width="100%" height="calc(100% - 16px)">
      <iframe
        ref={iframeEl}
        src={url}
        title="Bean IM"
        height="100%"
        width="100%"
        style={{ border: 0 }}
      ></iframe>
    </Box>
  );
}
