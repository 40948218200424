import { ReactNode, createContext, useState } from 'react';

export const AdminContext = createContext({
  // set to true to display readonly chat messages and not allow to send data
  readMode: false,
  // set conversation id for load conversation history messages
  adminConversationId: '',
  setAdminConversationId: (id: string) => {
    return;
  },
});

type AdminContextWrapperProps = {
  children: ReactNode;
};
export default function AdminContextWrapper({
  children,
}: AdminContextWrapperProps) {
  const [adminConversationId, setAdminConversationId] = useState('');

  return (
    <AdminContext.Provider
      value={{
        readMode: true,
        adminConversationId,
        setAdminConversationId,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}
