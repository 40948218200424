import { createContext } from 'react';

export const CreatorContext = createContext({
  currentDisplayedCampaignId: '',
  setCurrentDisplayedCampaignId: (id: string) => {
    return;
  },
  currentScriptType: '' as CreatorScriptType,
  setCurrentScriptTitle: (scriptType: CreatorScriptType) => {
    return;
  },
  userActionData: {} as ActionDataType,
  resetUserActionData: () => {
    return;
  },
  triggerUserAction: (type: CreatorRoleScriptActionType, data: any) => {
    return;
  },
  campaignIdForScriptTypeCampaign: '',
  setCampaignIdForScriptTypeCampaign: (id: string) => {
    return;
  },
});

export const BrandContext = createContext({
  currentDisplayedCreatorId: '',
  setCurrentDisplayedCreatorId: (id: string) => {
    return;
  },
  handlePrevNextCampaign: (prev: boolean) => {
    return;
  },
});

export const CreatorAIScriptPanelContext = createContext({
  appendBotMsgFromUserAction: (w: MessageResponseType) => {
    return;
  },
});

export const ApplicationContext = createContext({
  userId: '',
  applicationCreatorId: '',
  campaignId: '',
  applicationId: '',
  notifyRefresh: () => {
    return;
  },
});

export const DefaultUserActionData = {
  type: '',
  data: undefined,
} as ActionDataType;
