import {
  Box,
  Button,
  CardMedia,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { BenaVideoPlayer } from '../../components/BenaVideoPlayer';
import { downloadFile } from '../../components/UrlThumbnail';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { isMimeVideoOrAudio } from '../../utils/mimetype';

type HoverableImageProps = {
  fileLink: string;
  fileName: string;
  fileType: string;
  width?: string;
  height?: string;
};

export default function HoverableImage({
  fileLink,
  fileName,
  fileType: _fileType,
  width = '224px',
  height = '224px',
}: HoverableImageProps) {
  const theme = useTheme();
  const fileType = _fileType.split('/')[0] || _fileType;
  const isPlayable = isMimeVideoOrAudio(fileType || '');
  const isPreviewable = fileType === 'image' || isPlayable;

  const [status, setStatus] = useState<LoadingType>('init');
  const processHandler = (type: LoadingType) => setStatus(type);

  return (
    <Box
      sx={{
        '&:hover > .hoverCover': {
          visibility: isPlayable ? 'hidden' : 'visible',
        },
        position: 'relative',
      }}
      width={width}
      height={height}
    >
      {fileType === 'image' && (
        <CardMedia
          component="img"
          height={height}
          width={width}
          image={fileLink}
          alt="image"
          sx={{ borderRadius: '10px' }}
        />
      )}
      {isPlayable && (
        <BenaVideoPlayer
          src={fileLink}
          mediaType={_fileType || ''}
          width={`100%`}
        />
      )}
      <Button
        sx={{
          opacity: 0.5,
          visibility: 'hidden',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
          borderRadius: '10px 10px 10px 10px',
          bgcolor: theme.palette.grey[500],
        }}
        className="hoverCover"
      ></Button>
      {!isPreviewable && (
        <Stack
          sx={{
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderRadius: '10px 10px 10px 10px',
            bgcolor: theme.palette.primary[25],
          }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '152px',
              height: '40px',
              borderRadius: '8px',
            }}
            className="ellipsis"
          >
            {status === 'downloading' ? (
              <Box display="inline-block" width="20px">
                <CircularProgress color="primary" size={16} sx={{ ml: 1 }} />
              </Box>
            ) : (
              <Typography
                color="primary.950"
                fontSize="14px"
                fontWeight="600"
                whiteSpace={'pre-wrap'}
                textAlign="center"
              >
                {fileName}
              </Typography>
            )}
          </Box>
        </Stack>
      )}
      <Stack
        sx={{
          visibility: 'hidden',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
          borderRadius: '10px 10px 10px 10px',
        }}
        className="hoverCover"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Link
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '152px',
            height: '40px',
            borderRadius: '8px',
          }}
          href={fileLink}
          onClick={(e) => {
            e.preventDefault();
            status !== 'downloading' &&
              downloadFile(fileLink, fileName, processHandler);
          }}
          className="ellipsis"
        >
          <Typography
            color={isPreviewable ? 'base.white' : 'primary.600'}
            fontSize="14px"
            fontWeight="600"
          >
            {status === 'downloading' ? '' : fileName}
          </Typography>
        </Link>
      </Stack>
    </Box>
  );
}
