import { styled } from '@mui/material/styles';
import BadgeUnstyled, { badgeUnstyledClasses } from '@mui/base/BadgeUnstyled';

export const BenaBadge = styled(BadgeUnstyled)(({ theme }) => ({
  [`& .${badgeUnstyledClasses.badge}`]: {
    position: 'absolute',
    zIndex: 'auto',
    minWidth: '1.25rem',
    height: '1.25rem',
    padding: '0 0.375rem',
    borderRadius: '0.625rem',
    right: "0.5rem",
    top: "0.1rem",
    fontSize: '0.875rem',
    color: theme.palette.base.white,
    backgroundColor: theme.custom.primary2,
    transform: 'translate(50%, -50%)',
    transformOrigin: '100% 0',
  },
  [`& .${badgeUnstyledClasses.invisible}`]: {
    display: 'none',
  },
}));
